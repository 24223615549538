import { useTheme } from '@mui/material/styles'
import { Button, FieldGroup } from '@/ui'

export default function DangerousButton(props: {
  onClick: () => void
  btnText: string
  title: string
  subTitle: string
  color?: 'primary' | 'error'
}) {
  const theme = useTheme()
  let color = props.color == 'error' ? theme.palette.error.main : theme.palette.primary.main
  return (
    <div
      className="flex w-full items-center gap-4 rounded-2xl1 p-6"
      style={{
        boxShadow: `0px 0px 4px 1px ${color}`,
      }}
    >
      <FieldGroup label={props.title}>
        <div className="text-text-tertiary">{props.subTitle}</div>
      </FieldGroup>
      <Button variant={props.color} style={{ marginLeft: 'auto', borderColor: 'transparent' }} onClick={props.onClick}>
        {props.btnText}
      </Button>
    </div>
  )
}
