import { createElement, useCallback, useEffect, useRef } from 'react'
import { AghanimIcon, Button, Plus, cn } from '@dashboard/ui'
import { Sidebar, SidebarButton, SidebarGroup } from '@/ui'
import { PageSidebarItem, PageSidebarLink } from '@/layouts/pages/components/PageSidebarLink'
import { useBanner } from '@/libs'

interface PageSidebarProps {
  name: string
  items: PageSidebarItem[]
  add?: {
    type?: 'button' | 'nested'
    text: string
    open: () => void
    disabled?: boolean
    icon?: AghanimIcon
  }
}

export const PageSidebar = ({ name, items, add }: PageSidebarProps) => {
  const scrollRef = useRef<HTMLDivElement>(null)
  const { bannerMode } = useBanner()

  const handleWindowScroll = useCallback(() => {
    if (!scrollRef.current) {
      return
    }
    const clientRect = scrollRef.current.getBoundingClientRect()
    scrollRef.current.style.maxHeight = `calc(100vh - ${clientRect.top}px - 0.75rem)`
  }, [scrollRef])

  useEffect(() => {
    window.addEventListener('scroll', handleWindowScroll)
    return () => {
      window.removeEventListener('scroll', handleWindowScroll)
    }
  }, [handleWindowScroll])

  return (
    <Sidebar
      ref={scrollRef}
      className={cn(
        "sticky left-0 top-3 max-h-screen overflow-y-auto transition-[top] group-[&[data-modal-rush='true']]/main-outlet:top-1",
        bannerMode ? 'top-[theme(height.banner)]' : 'top-3',
      )}
    >
      <SidebarGroup>
        {items.map(item => (
          <PageSidebarLink key={item.to} name={name} item={item} />
        ))}
        {add &&
          (add.type === 'nested' ? (
            <SidebarButton icon={add.icon} onClick={add.open}>
              {add.text}
            </SidebarButton>
          ) : (
            <SidebarGroup className="sticky bottom-0 left-0 mt-3 bg-fg-primary">
              <div className="pointer-events-none absolute bottom-7 left-0 h-4 w-full bg-gradient-to-t from-fg-primary to-transparent" />
              <Button
                data-testid={`${name}/sidebar/add`}
                className="w-full"
                variant="outline"
                color="secondary"
                type="button"
                size="sm"
                disabled={!!add.disabled}
                onClick={add.open}
              >
                {add.icon ? createElement(add.icon, { size: 16 }) : <Plus size={16} />}
                <span>{add.text}</span>
              </Button>
            </SidebarGroup>
          ))}
      </SidebarGroup>
    </Sidebar>
  )
}
