import { queryOptions } from '@tanstack/react-query'
import { dashboardClient } from '@/api'

export const loyaltyTiersQueryOptions = (companyID: string, gameID: string, settingsID: string) =>
  queryOptions({
    enabled: !!settingsID,
    queryKey: ['loyalty-tiers', companyID, gameID, settingsID],
    queryFn: () => dashboardClient.v1.getLoyaltyTiers(companyID, gameID, settingsID).then(r => r.data),
    staleTime: 60000,
  })
