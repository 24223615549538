import i18next from 'i18next'
import PageBlock from '../../game-items/components/PageBlock'
import { useParams } from 'react-router-dom'

import { useInfiniteQuery } from '@tanstack/react-query'
import { dashboardClient } from '@/api'
import { TableRowSkeleton, TableZeroState } from '@/ui'
import { WebhookLogsInspector } from '@/layouts/webhooks'

const LOAD_LIMIT = 50

export const UserWebhookLog = (props: { userId: string }) => {
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }

  const fetchPage = async (pageParam: number) => {
    const { data } = await dashboardClient.v1.getWebhookLogByUser(companyId, gameId, props.userId, {
      limit: LOAD_LIMIT,
      offset: pageParam * LOAD_LIMIT,
    })
    return data
  }

  const result = useInfiniteQuery({
    queryKey: [gameId, props.userId],
    initialPageParam: 0,
    queryFn: ({ pageParam = 0 }) => fetchPage(pageParam),
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.length === 0 || lastPage.length < LOAD_LIMIT) {
        return undefined
      }
      return pages.length
    },
  })

  const lastPage = result.data?.pages[result.data.pages.length - 1] || []
  const hasNext = lastPage.length > 0 && lastPage.length === LOAD_LIMIT
  const showZeroState = !result.isLoading && result.data?.pages.flat().length == 0
  return (
    <PageBlock title={i18next.t('player.webhook-log')}>
      {result.isLoading ? (
        <TableRowSkeleton rowCount={6} columnCount={3} />
      ) : (
        !showZeroState && (
          <WebhookLogsInspector
            onLoadMoreClick={hasNext ? () => result.fetchNextPage() : undefined}
            items={result.data?.pages.flat() || []}
            className="h-[630px] rounded-xl border border-border-secondary px-2"
          />
        )
      )}
      {showZeroState && (
        <TableZeroState
          className="mt-4 h-[300px]"
          title={i18next.t('player.webhook-log.no-logs')}
          message={i18next.t('player.webhook-log.no-logs.desc')}
        />
      )}
    </PageBlock>
  )
}
