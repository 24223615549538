import { Button, TableZeroState } from '@/ui'
import { useTranslation } from 'react-i18next'
import { DrawerSidebar } from '@/components/ui/DrawerSidebar'
import { DailyRewardsSettingsForm } from '@/layouts/engagement/widgets/DailyRewardsSettingsForm'
import { useState } from 'react'

interface DailyRewardsZeroStateProps {
  interactive?: boolean
}

export const DailyRewardsZeroState = ({ interactive = false }: DailyRewardsZeroStateProps) => {
  const { t } = useTranslation()
  const [isCreate, setIsCreate] = useState<boolean>(false)

  return (
    <div className="size-full">
      {interactive && (
        <DrawerSidebar open={isCreate} width="720px" position="right">
          <DailyRewardsSettingsForm settings={{}} onClose={() => setIsCreate(false)} />
        </DrawerSidebar>
      )}

      <TableZeroState
        className="h-full"
        title={t('daily-rewards.empty.title')}
        message={t('daily-rewards.empty.message')}
        buttons={
          interactive && (
            <Button variant="primary" onClick={() => setIsCreate(true)}>
              {t('daily-rewards.empty.button')}
            </Button>
          )
        }
      />
    </div>
  )
}
