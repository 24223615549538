import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { LocaleRead } from '@/api/dashboard'
import { ModalBaseProps, ModalPresetConfirm } from '@dashboard/ui'
import { LOCALIZATION_PATH, useGenerateCompanyPath } from '@/libs'
import { localeDeleteMutation } from '../api'

interface L10nLocaleModalDeleteProps extends ModalBaseProps {
  locale: LocaleRead
}

export const L10nLocaleModalDelete = ({ locale, ...rest }: L10nLocaleModalDeleteProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { generatePath } = useGenerateCompanyPath()
  const { mutateAsync } = localeDeleteMutation()

  const onConfirm = async () => {
    await mutateAsync({ localeId: locale.id })
    navigate(generatePath(LOCALIZATION_PATH))
  }

  return (
    <ModalPresetConfirm
      {...rest}
      title={{
        children: t('localization.locale.delete-modal.title'),
        subtitle: t('localization.locale.delete-modal.text'),
      }}
      cancel={{
        label: t('Cancel'),
      }}
      submit={{
        callback: onConfirm,
        label: t('localization.locale.delete-modal.confirm'),
      }}
    />
  )
}
