import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { Checkbox } from '@dashboard/ui'
import { AccountDetails, AccountRead, BusinessOwnerDetails } from '@/api/dashboard'
import { Alert, Button, Callout, DrawerContent, DrawerTopbar, FieldGroup, Input, SelectCountry } from '@/ui'
import { InfoCircle } from '@/icons'

interface VerifyBusinessOwnerForm {
  accountDetails?: AccountRead
  data?: BusinessOwnerDetails
  dataKey?: number | null
  onSubmit: (data: AccountDetails) => Promise<void>
  onCancel: () => void
}

export const VerifyBusinessOwnerForm = ({
  accountDetails,
  data,
  dataKey,
  onSubmit,
  onCancel,
}: VerifyBusinessOwnerForm) => {
  const { t } = useTranslation()
  const {
    formState: { errors, isSubmitting },
    watch,
    register,
    control,
    handleSubmit,
  } = useForm<BusinessOwnerDetails & { no_ubo: boolean }>({
    defaultValues: {
      no_ubo: accountDetails?.details.have_no_ubo,
      entity_legal_name: '',
      country: undefined,
      ...data,
    },
  })

  const noUBO = watch('no_ubo')

  const onHandleSubmit = handleSubmit(data => {
    return onSubmit({
      business_owners: data.no_ubo
        ? []
        : [
            ...(accountDetails?.details.business_owners || []).filter((_, index) => index !== dataKey),
            { entity_legal_name: data.entity_legal_name, country: data.country },
          ],
      have_no_ubo: data.no_ubo,
    })
  })

  return (
    <form className="flex h-full flex-col overflow-y-auto" onSubmit={onHandleSubmit}>
      <DrawerTopbar>
        <h2 className="mr-auto text-title-t5">{t('get-started.verify.steps.business-owner.form.title')}</h2>
        <div className="flex gap-3">
          <Button variant="outline" type="button" onClick={onCancel}>
            {t('Cancel')}
          </Button>
          <Button variant="primary" type="submit" isLoading={isSubmitting}>
            {t('Save2')}
          </Button>
        </div>
      </DrawerTopbar>

      <DrawerContent className="flex h-full flex-col overflow-y-auto">
        <div className="mb-6">
          <p className="text-paragraph-md text-text-secondary">
            {t('get-started.verify.steps.business-owner.form.desc')}
          </p>
        </div>

        {!accountDetails?.details.business_owners?.length && (
          <div className="mb-6">
            <Checkbox {...register('no_ubo')}>{t('get-started.verify.steps.business-owner.no-owner')}</Checkbox>
          </div>
        )}

        {!noUBO && (
          <div>
            <div className="mb-4">
              <FieldGroup
                label={t('get-started.verify.steps.business-owner.form.name.label')}
                size="sm"
                required={true}
              >
                <Input
                  {...register('entity_legal_name', {
                    required: t('validation.required'),
                    minLength: {
                      message: t('validation.minLength', {
                        field: t('get-started.verify.steps.business-owner.form.name.label'),
                        value: 3,
                      }),
                      value: 3,
                    },
                    maxLength: {
                      message: t('validation.maxLength', {
                        field: t('get-started.verify.steps.business-owner.form.name.label'),
                        value: 50,
                      }),
                      value: 50,
                    },
                  })}
                  autoFocus={true}
                  errors={errors}
                  placeholder={t('get-started.verify.steps.business-owner.form.name.placeholder')}
                />
              </FieldGroup>
              <Alert>{t('get-started.verify.steps.business-owner.form.name.caption')}</Alert>
            </div>

            <div className="mb-4">
              <FieldGroup label={t('get-started.verify.steps.business-owner.form.country')} size="sm" required={true}>
                <Controller
                  name="country"
                  control={control}
                  rules={{ required: t('validation.required') }}
                  render={({ field }) => <SelectCountry {...field} errors={errors} />}
                />
              </FieldGroup>
            </div>

            <Callout icon={InfoCircle}>
              <p>{t('get-started.verify.steps.business-owner.form.callout')}</p>
            </Callout>
          </div>
        )}
      </DrawerContent>
    </form>
  )
}
