import { forwardRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { SelectFilter, SelectFilterProps } from '@dashboard/ui'
import { COUNTRY_BY_REGIONS } from './data'

export const SelectRegion = forwardRef<HTMLDivElement, Omit<SelectFilterProps, 'children' | 'items' | 'label'>>(
  ({ ...rest }) => {
    const { t } = useTranslation()

    const regions = useMemo(() => {
      return [
        {
          children: t('game-settings.ps-table.regions.global'),
          value: 'Global',
        },
        ...Object.keys(COUNTRY_BY_REGIONS)
          .sort()
          .map(item => ({
            children: t(`game-settings.ps-table.regions.${item}`),
            value: item,
          })),
      ]
    }, [t])

    return (
      <SelectFilter {...rest} items={regions}>
        {t('game-settings.ps-table.region')}
      </SelectFilter>
    )
  },
)
