import { TableZeroState } from '@/ui'
import { Button } from '@dashboard/ui'
import { CloseOutline } from '@/icons'
import { useCurrentUser } from '@/api/useCurrentUser'
import { useTranslation } from 'react-i18next'

interface TableZeroStateFilterProps {
  items: object[]
  page: number
  hasFilter: boolean
  createClick: () => void
  clearFilterClick: () => void
  notFoundMessage?: string
  zeroStateMessage?: string
  zeroStateSubMessage?: string
  addButtonText?: string
}

export const hasZeroState = (items: object[], page: number, hasFilter: boolean) => {
  if (items.length > 0) {
    return false
  }

  if (hasFilter) {
    return true
  }

  if (items.length === 0 && page === 1) {
    return true
  }
}

export const TableZeroStateFilter = ({
  createClick,
  items,
  hasFilter,
  page,
  clearFilterClick,
  notFoundMessage,
  zeroStateMessage,
  zeroStateSubMessage,
  addButtonText,
}: TableZeroStateFilterProps) => {
  const { canEdit } = useCurrentUser()
  const { t } = useTranslation()

  if (hasFilter) {
    return (
      <TableZeroState
        className="mt-4"
        title={t('nothing-found')}
        message={notFoundMessage}
        buttons={
          <Button variant="outline" color="secondary" size="sm" onClick={clearFilterClick}>
            <CloseOutline size={16} />
            <span>{t('clear-filters')}</span>
          </Button>
        }
      />
    )
  }

  if (items.length === 0 && page === 1) {
    return (
      <TableZeroState
        title={zeroStateMessage}
        message={zeroStateSubMessage}
        buttons={
          canEdit && (
            <Button color="primary" size="sm" onClick={createClick}>
              {addButtonText}
            </Button>
          )
        }
      />
    )
  }

  return null
}
