import {
  APP_CONNECT_PATH,
  COMPANY_PAYMENT_METHODS,
  COMPANY_SETTINGS_PATH,
  DOCUMENT_PATH,
  ENGAGEMENT_CREATORS_PATH,
  ENGAGEMENT_DAILY_REWARDS_PATH,
  ENGAGEMENT_LOYALTY_PATH,
  ENGAGEMENT_PATH,
  EXPERTS_PATH,
  FEATURES_PATH,
  GAME_ATTRS_PATH,
  GAME_PATH,
  GAME_PLAYERS_PATH,
  GAME_SEGMENTS_PATH,
  GAME_SETTINGS,
  GAME_WEBHOOKS_PATH,
  HUB_ACHIEVEMENTS_PATH,
  HUB_LEADERBOARD_PATH,
  HUB_NEWS_PATH,
  HUB_PAGES_PATH,
  HUB_PATH,
  HUB_SETTINGS_PATH,
  HUB_THEMES_PATH,
  LIVEOPS_PATH,
  LIVEOPS_PATH_TABLE,
  LOCALIZATION_PATH,
  PAYOUTS_PATH,
  SKU,
  SKU_BUNDLE_ITEMS_PATH,
  SKU_CODES_PATH,
  SKU_ITEMS_PATH,
  STORE_PATH,
  TEAM_PATH,
  TRANSACTIONS_PATH,
} from '@/libs'
import {
  AppConnect,
  Cog,
  Documents,
  Engagement,
  Experts,
  Game,
  HubBuilder,
  LiveOps,
  Localization,
  Payouts,
  Sku,
  Transactions,
} from './icons'
import { TFunction } from 'i18next'
import { CustomerCompany, FeatureFlags } from '@/api/dashboard'
import { canEdit, isAdmin } from '@/security'
import { FunctionComponent, ReactNode } from 'react'
import { SidebarLinkIconProps } from './types'
import { Team } from './icons/Team'
import { isSuperAdmin } from '@/util'
import { SidebarLinkProps } from './SidebarLink'
import { IconLoader } from '@/icons'
import { PaymentMethod } from './icons/PaymentMethod'

export enum PermissionType {
  admin,
  user,
  editor,
}

export interface AppMenuItem {
  to?: string
  drill?: boolean
  icon?: FunctionComponent<SidebarLinkIconProps>
  hideArrow?: boolean
  text: string
  items?: AppMenuItem[]
  permission: PermissionType
  externalLink?: SidebarLinkProps['externalLink']
  className?: string
  disabled?: boolean
  secondaryAction?: ReactNode
}

function filterItems(customer: CustomerCompany, items: AppMenuItem[]) {
  items = items
    .filter(it => !!it)
    .filter(item => {
      if (item.items) {
        item.items = filterItems(customer, item.items)
        if (!item.items.length) {
          return false
        }
      }

      switch (item.permission) {
        case PermissionType.admin:
          return isAdmin(customer)
        case PermissionType.editor:
          return canEdit(customer)
        default:
          return true
      }
    })

  return items
}

export function getPrimaryItems(
  customer: CustomerCompany,
  generatePath: (path: string) => string,
  t: TFunction<'translation', undefined>,
  featureFlags: FeatureFlags,
): AppMenuItem[] {
  const items = [
    {
      to: generatePath(LIVEOPS_PATH),
      icon: LiveOps,
      text: t('sidebar.liveops-builder'),
      permission: PermissionType.user,
      items: [
        {
          to: generatePath(LIVEOPS_PATH_TABLE),
          text: t('sidebar.campaigns'),
          permission: PermissionType.user,
        },
        {
          to: generatePath(GAME_SEGMENTS_PATH),
          text: t('sidebar.segments'),
          permission: PermissionType.user,
        },
      ],
    },
    {
      to: generatePath(ENGAGEMENT_PATH),
      icon: Engagement,
      text: t('sidebar.engagement'),
      permission: PermissionType.user,
      items: getEngagementItems(generatePath, t, featureFlags),
    },
    {
      to: generatePath(SKU),
      icon: Sku,
      text: t('sidebar.sku-management'),
      permission: PermissionType.user,
      items: [
        {
          to: generatePath(STORE_PATH),
          text: t('sidebar.store'),
          permission: PermissionType.user,
        },
        {
          to: generatePath(SKU_ITEMS_PATH),
          text: t('sidebar.items'),
          permission: PermissionType.user,
        },
        featureFlags.bundle_enabled && {
          to: generatePath(SKU_BUNDLE_ITEMS_PATH),
          text: t('sidebar.bundles'),
          permission: PermissionType.user,
        },
        {
          to: generatePath(SKU_CODES_PATH),
          text: t('sidebar.codes'),
          permission: PermissionType.user,
        },
      ],
    },
    {
      to: generatePath(LOCALIZATION_PATH),
      icon: Localization,
      text: t('sidebar.localization'),
      permission: PermissionType.user,
    },
    {
      to: generatePath(TRANSACTIONS_PATH),
      icon: Transactions,
      text: t('sidebar.transactions'),
      permission: PermissionType.user,
    },

    {
      to: generatePath(GAME_PATH),
      icon: Game,
      text: t('sidebar.game'),
      permission: PermissionType.user,
      items: [
        {
          to: generatePath(GAME_PLAYERS_PATH),
          text: t('sidebar.players'),
          permission: PermissionType.user,
        },
        {
          to: generatePath(GAME_ATTRS_PATH),
          text: t('sidebar.players.attributes'),
          permission: PermissionType.user,
        },
        {
          to: generatePath(GAME_WEBHOOKS_PATH),
          text: t('sidebar.game.webhooks'),
          permission: PermissionType.user,
        },
        {
          to: generatePath(GAME_SETTINGS),
          text: t('sidebar.settings'),
          permission: PermissionType.user,
        },
      ],
    },
  ]

  return filterItems(customer, items.filter(it => !!it) as AppMenuItem[])
}

const getEngagementItems = (
  generatePath: (path: string) => string,
  t: TFunction<'translation', undefined>,
  featureFlags: FeatureFlags,
): AppMenuItem[] => {
  const items: AppMenuItem[] = []

  featureFlags.daily_rewards_enabled &&
    items.push({
      to: generatePath(ENGAGEMENT_DAILY_REWARDS_PATH),
      text: t('sidebar.daily-rewards'),
      permission: PermissionType.user,
    })

  featureFlags.loyalty_program_enabled &&
    items.push({
      to: generatePath(ENGAGEMENT_LOYALTY_PATH),
      text: t('sidebar.loyalty'),
      permission: PermissionType.user,
    })

  featureFlags.creator_program_enabled &&
    items.push({
      to: generatePath(ENGAGEMENT_CREATORS_PATH),
      text: t('sidebar.creators'),
      permission: PermissionType.user,
    })

  return items
}

export function getHubItems(
  customer: CustomerCompany,
  generatePath: (path: string) => string,
  t: TFunction<'translation', undefined>,
  website: string | undefined,
  isOnboarding: boolean = false,
): AppMenuItem[] {
  const items = [
    {
      to: isOnboarding ? '' : generatePath(HUB_PATH),
      icon: HubBuilder,
      disabled: isOnboarding,
      text: t('sidebar.hub-builder'),
      permission: PermissionType.user,
      drill: true,
      hideArrow: isOnboarding,
      externalLink: website
        ? {
            tooltip: t(
              isOnboarding ? 'sidebar.hub.website-tooltip.onboarding-progress' : 'sidebar.hub.website-tooltip',
            ),
            href: isOnboarding ? null : website,
            icon: isOnboarding ? <IconLoader style={{ width: '20px', height: '20px', color: '#94A3B8' }} /> : undefined,
          }
        : undefined,
      items: isOnboarding
        ? undefined
        : [
            {
              to: isOnboarding ? '' : generatePath(HUB_THEMES_PATH),
              text: t('sidebar.hub-themes'),
              permission: PermissionType.user,
            },
            {
              to: generatePath(HUB_NEWS_PATH),
              text: t('sidebar.news'),
              permission: PermissionType.user,
            },
            {
              to: generatePath(HUB_LEADERBOARD_PATH),
              text: t('sidebar.leaderboard'),
              permission: PermissionType.user,
            },
            {
              to: generatePath(HUB_ACHIEVEMENTS_PATH),
              text: t('sidebar.achievement'),
              permission: PermissionType.user,
            },
            {
              to: generatePath(HUB_PAGES_PATH),
              text: t('sidebar.pages'),
              permission: PermissionType.user,
            },
            {
              to: generatePath(HUB_SETTINGS_PATH),
              text: t('sidebar.hub-settings'),
              permission: PermissionType.user,
            },
          ],
    },
  ]
  return filterItems(customer, items)
}

export function getFooterItems(
  customer: CustomerCompany,
  generatePath: (path: string) => string,
  t: TFunction<'translation', undefined>,
): AppMenuItem[] {
  const items = [
    {
      to: generatePath(APP_CONNECT_PATH),
      icon: AppConnect,
      text: t('sidebar.connect'),
      permission: PermissionType.admin,
    },
    {
      to: generatePath(EXPERTS_PATH),
      icon: Experts,
      text: t('sidebar.experts'),
      permission: PermissionType.admin,
    },
  ]
  return filterItems(customer, items)
}

export function getCompanyItems(
  customer: CustomerCompany,
  generatePath: (path: string) => string,
  t: TFunction<'translation', undefined>,
): AppMenuItem[] {
  let items = [
    {
      to: generatePath(DOCUMENT_PATH),
      icon: Documents,
      text: t('sidebar.documents'),
      permission: PermissionType.admin,
    },
    {
      to: generatePath(PAYOUTS_PATH),
      icon: Payouts,
      text: t('sidebar.payouts'),
      permission: PermissionType.admin,
    },
    {
      to: generatePath(COMPANY_PAYMENT_METHODS),
      icon: PaymentMethod,
      text: t('sidebar.payment-methods'),
      permission: PermissionType.admin,
    },
    {
      to: generatePath(TEAM_PATH),
      icon: Team,
      text: t('sidebar.team'),
      permission: PermissionType.admin,
    },
    {
      to: generatePath(COMPANY_SETTINGS_PATH),
      icon: Cog,
      text: t('sidebar.company-settings'),
      permission: PermissionType.admin,
    },
  ]

  let filtered = filterItems(customer, items) as AppMenuItem[]

  if (isSuperAdmin(customer)) {
    filtered.unshift({
      to: generatePath(FEATURES_PATH),
      icon: Cog,
      text: 'Admin: Features',
    } as AppMenuItem)
  }

  return filtered
}
