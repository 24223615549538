import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Checkbox, Input, Spinner } from '@dashboard/ui'
import { ToastSeverity, useToast } from '@/ui'
import { useForm } from 'react-hook-form'
import { creatorCreateTermsMutation } from '@/layouts/creator/api'
import { useNavigate, useParams } from 'react-router-dom'
import { getErrorText } from '@/api'
import { useCreatorTerm } from '@/layouts/creator/hooks'
import { CreatorTermsCreate } from '@/api/dashboard'

export const CreatorAcceptTermsPage = () => {
  const { t } = useTranslation()
  const { token } = useParams() as { token: string }
  const navigate = useNavigate()
  const [confirmed, setConfirmed] = useState<boolean>(false)
  const showToast = useToast()

  const { accepted, isLoading, isError } = useCreatorTerm(token)

  useEffect(() => {
    isError && navigate(`/creator/${token}/error`)
    accepted && navigate(`/creator/${token}/terms/accepted`)
  }, [isError, accepted, navigate, token])

  const { mutateAsync: accept } = creatorCreateTermsMutation(token)

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<CreatorTermsCreate>()

  const handleAccept = handleSubmit(async data => {
    try {
      await accept(data)
    } catch (e) {
      showToast({ message: getErrorText(e), severity: ToastSeverity.error })
    }
  })

  return (
    <div
      style={{
        width: '690px',
        boxShadow: '0px 24px 48px -12px #1018282E',
        background: 'linear-gradient(273.02deg, rgba(255, 255, 255, 0) 5.02%, #FFFFFF 94.98%)',
        zIndex: 1,
      }}
      className="flex flex-col items-center justify-center gap-5 rounded-[36px] bg-fg-primary p-12"
    >
      {isLoading || accepted ? (
        <Spinner size={48} />
      ) : (
        <>
          <span className="text-center text-[36px] font-semibold" style={{ lineHeight: '43px' }}>
            {t('creator.terms.title')}
          </span>

          <div
            className="text-[16px] text-text-secondary"
            dangerouslySetInnerHTML={{ __html: t('creator.terms.text') }}
          />

          <form onSubmit={handleAccept} className="flex flex-col items-center justify-center gap-5">
            <div className="w-full">
              <Input
                {...register('legal_name', { required: t('validation.required'), setValueAs: v => v.trim() })}
                placeholder={t('creator.terms.legal-name')}
              />
            </div>

            <Checkbox disabled={isSubmitting} checked={confirmed} onChange={() => setConfirmed(!confirmed)}>
              {t('creator.terms.confirm')}
            </Checkbox>

            <Button className="w-1/2" color="primary" type="submit" disabled={!confirmed} loading={isSubmitting}>
              {t('creator.terms.accept')}
            </Button>
          </form>
        </>
      )}
    </div>
  )
}
