import { useMutation, useQueryClient } from '@tanstack/react-query'
import { dashboardClient } from '@/api'
import { PluginInstanceCreate } from '@/api/dashboard'

interface PluginInstanceMutate {
  create: PluginInstanceCreate
}

export const usePluginInstallMutate = (companyId: string, gameId: string, slug: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ create }: PluginInstanceMutate) => dashboardClient.v1.installPlugin(companyId, gameId, slug, create),
    onSuccess: data => {
      queryClient.setQueryData(['plugins_instance', gameId, slug], data.data)
      queryClient.invalidateQueries({ queryKey: ['plugins_instance', gameId, slug] })
    },
  })
}
