import { Controller, useFormContext } from 'react-hook-form'
import { Block } from '@/api/dashboard'
import { FieldGroup, FieldValidationMessage, ImageUploader } from '@/ui'

import { useTranslation } from 'react-i18next'
import { ErrorMessage } from '@hookform/error-message'
import { S3Bucket } from '@/types'
import { FormErrorMessage, Input } from '@dashboard/ui'

const ImageBannerEditor = () => {
  const { t } = useTranslation()

  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<Block>()

  return (
    <div>
      <FieldGroup label={t('hub-editor.image-banner.image-url')}>
        <Controller
          name="image_url"
          control={control}
          rules={{ required: t('validation.required') }}
          render={({ field }) => (
            <ImageUploader
              {...field}
              accept={{
                'image/jpeg': ['.jpeg', '.jpg'],
                'image/png': [],
                'image/webp': [],
                'image/gif': [],
              }}
              bucket={S3Bucket.hub}
            />
          )}
        />
        <ErrorMessage
          name="image_url"
          errors={errors}
          render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
        />
      </FieldGroup>

      <FieldGroup label={t('hub-editor.image-banner.link')}>
        <Input placeholder={t('hub-editor.image-banner.link-placeholder')} {...register('link')} />
        <ErrorMessage
          name="link"
          errors={errors}
          render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
        />
      </FieldGroup>
    </div>
  )
}

export default ImageBannerEditor
