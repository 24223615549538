import { NewsRead, NewsSortField, SortOrder } from '@/api/dashboard'
import { useParams } from 'react-router-dom'
import { Button, FieldGroup, SelectMultipleOption, SelectOption, TableZeroState } from '@/ui'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { dashboardClient } from '@/api'
import { useInfiniteQuery } from '@tanstack/react-query'
import { ArrowDown, InputSearch, Select } from '@dashboard/ui'
import { useDebounce } from '@/hooks'
import { CloseOutline } from '@/icons'

// @ts-ignore
interface SelectSkuProps extends SelectProps {
  items?: SelectOption[]
  label?: string
  value: string | undefined | null
  onChange: (value: SelectOption['value'] | SelectMultipleOption['value'][]) => void
}

const LOAD_LIMIT = 10

const fetchPage = async (companyId: string, gameId: string, pageParam: number, search_string: string) => {
  const { data } = await dashboardClient.v1.getNewsList(companyId, gameId, {
    limit: LOAD_LIMIT,
    offset: pageParam * LOAD_LIMIT,
    search_string: search_string,
    sort_order: SortOrder.Desc,
    sort_field: NewsSortField.Datetime,
  })
  return data
}

export const SelectNews = (props: SelectSkuProps) => {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const { t } = useTranslation()
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)
  const [additionalItems, setAdditionalItems] = useState<NewsRead[]>([])

  const result = useInfiniteQuery({
    queryKey: [gameId, 'infinity-news', debouncedSearch],
    initialPageParam: 0,
    queryFn: ({ pageParam = 0 }) => fetchPage(companyId, gameId, pageParam, debouncedSearch),
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.length === 0 || lastPage.length < LOAD_LIMIT) {
        return undefined
      }
      return pages.length
    },
  })

  const loadValueItems = async () => {
    if (!props.value) {
      return
    }

    const ids: string[] = Array.isArray(props.value) ? (props.value as string[]) : [props.value as string]
    if (!ids.length) {
      return
    }

    const allItems = result.data?.pages.flat() || []

    const notLoadedItemsIds = ids.filter(
      id => !additionalItems.find(it => it.id == id) && !allItems.find(it => it.id == id),
    )

    if (!notLoadedItemsIds.length) {
      return
    }

    const { data } = await dashboardClient.v1.getNewsList(companyId, gameId, {
      ids: notLoadedItemsIds.join(','),
    })
    setAdditionalItems(data)
  }

  useEffect(() => {
    loadValueItems()
  }, [props.value])

  useEffect(() => {
    result.refetch()
  }, [debouncedSearch])

  let visibleItems = result.data?.pages.flat() || []

  if (additionalItems.length) {
    for (let additionalItem of additionalItems) {
      if (!visibleItems.find(it => it.id === additionalItem.id)) {
        visibleItems.push(additionalItem)
      }
    }
  }

  let options = visibleItems.map(it => {
    return {
      value: it.id as string,
      children: it.title || it.description,
    }
  })

  const lastPage = result.data?.pages[result.data.pages.length - 1] || []
  const hasNext = lastPage.length > 0 && lastPage.length === LOAD_LIMIT

  const loadMore = (
    <Button
      size="sm"
      variant="text"
      onClick={e => {
        if (hasNext) {
          result.fetchNextPage()
        }
        e.stopPropagation()
        e.preventDefault()
      }}
      className="mt-2 w-full"
    >
      <ArrowDown />
      {t('player.webhook-log.load-more')}
    </Button>
  )

  let content = (
    <Select
      value={props.value}
      onChange={v => {
        props.onChange(v as string)
      }}
      renderMenuFooter={() => {
        return hasNext
          ? loadMore
          : options.length === 0 && !result.isLoading && search && (
              <div className="mt-2 h-[200px]">
                <TableZeroState
                  title={t('sku.search.zero.title')}
                  message={t('sku.search.zero.text')}
                  onClick={e => e.stopPropagation()}
                  buttons={
                    <Button
                      variant="outline"
                      onClick={e => {
                        e.stopPropagation()
                        setSearch('')
                      }}
                    >
                      <CloseOutline size={14} />
                      <span>{t('clear-filters')}</span>
                    </Button>
                  }
                />
              </div>
            )
      }}
      header={
        <div className="border-b border-border-secondary p-3">
          <InputSearch
            value={search}
            onFocus={e => {
              e.stopPropagation()
            }}
            onMouseDown={e => {
              e.stopPropagation()
            }}
            onChange={e => {
              setSearch(e.target.value)
            }}
            placeholder={t('search')}
          />
        </div>
      }
      items={options}
    />
  )

  if (!props.label) {
    return content
  }

  return <FieldGroup label={props.label}>{content}</FieldGroup>
}
