import { HTMLAttributes, ReactNode, forwardRef } from 'react'
import styled from '@emotion/styled'

interface MenuProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode
}

const StyledMenu = styled.div`
  background-color: ${p => p.theme.palette.background.fgPrimary};
  border: 1px solid ${p => p.theme.palette.grey.borderPrimary};
  border-radius: 6px;
  padding: 4px;
  width: 100%;
  // TODO: define max width
  max-width: 320px;
  max-height: inherit;
  // TODO: use custom scroll container @ds.pankov
  overflow-y: auto;
  box-shadow:
    0 8px 8px -4px rgba(16, 24, 40, 0.03),
    0 20px 24px -4px rgba(16, 24, 40, 0.08);
`

/**
 * @deprecated please use `Menu` from `@dashboard/ui` instead
 */
export const Menu = forwardRef<HTMLDivElement, MenuProps>(function Menu({ children, ...rest }, ref) {
  return (
    <StyledMenu {...rest} ref={ref}>
      {children}
    </StyledMenu>
  )
})
