import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import { postLogin } from '../api/postLogin'
import { useAuthToken } from '@/auth/useAuthToken'

const Callback = () => {
  const navigate = useNavigate()
  const { authDone, isLoading, user, targetUrl } = useAuthToken()

  const load = async () => {
    postLogin(user?.name, user?.picture)

    navigate(targetUrl || '/')
  }

  useEffect(() => {
    if (authDone) {
      load()
    }
  }, [authDone])

  if (isLoading) {
    return (
      <Box
        sx={{
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  return <></>
}

export default Callback
