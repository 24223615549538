import { ReactNode } from 'react'

interface MenuGroupProps {
  children: ReactNode
  label?: ReactNode
}

export const MenuGroup = ({ children, label }: MenuGroupProps) => (
  <div className="group/menu-group">
    <div className="group-first:/menu-group:mt-0 my-1 flex items-center gap-3">
      {label && <div className="shrink-0 pl-3 text-caption-xs uppercase text-text-quarterary-hover">{label}</div>}
      <div className="h-px w-full bg-border-secondary" />
    </div>
    {children}
  </div>
)
