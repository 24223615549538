import { FieldInfo, KeyValue } from '../types'

import i18next from 'i18next'
import Box from '@mui/material/Box'
import { HUB_EDITOR_LEFT_SIDE_WIDTH, SURFACE_SURFACE_BG_STROKE } from '../Settings'
import ListItemSwitch from '../components/ListItemSwitch'
import { Button, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { EditIcon, SectionIcon, SettingsIcon } from '../icons/Icons'
import { Theme, useTheme } from '@mui/material/styles'
import NavItem from '../components/NavItem'
import { useParams } from 'react-router-dom'
import { SxProps } from '@mui/system/styleFunctionSx'
import { useEffect, useMemo, useState } from 'react'
import { PageLayoutType, PageRead, SectionType, WebsiteRead, WebsiteSection } from '../api/dashboard'
import ChangeSectionDialog from './dialogs/ChangeSectionDialog'
import { ColorPicker, FieldGroup, SelectOption, TableRowEditButton } from '@/ui'
import { Trash } from '@/icons'
import { usePagesQuery } from '@/layouts/pages/api/usePagesQuery'

import { ReactSortable } from 'react-sortablejs'
import { Skeleton } from '@dashboard/ui'
import { uuid4 } from '@/util'

const commonStyles = {
  borderValue: `1px solid ${SURFACE_SURFACE_BG_STROKE}`,
}

type EditTab = 'sections' | 'apps' | 'settings'

function getNavItems(): {
  title: string
  icon: React.ReactNode
  href: string
  value: EditTab
}[] {
  return [
    {
      title: '',
      icon: <SettingsIcon />,
      href: '',
      value: 'settings',
    },
    {
      title: '',
      icon: <SectionIcon />,
      href: '',
      value: 'sections',
    },
    // {
    //   title: '',
    //   icon: <PuzzleIcon />,
    //   href: ``,
    //   value: 'apps',
    // },
  ]
}

function SectionEditor(props: {
  webSite: WebsiteRead
  setWebSite: (webSite: WebsiteRead) => void
  sendIframeMessage: (data: { menuSections?: WebsiteSection[] }) => void
}) {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const { webSite, setWebSite } = props

  const { data: pages = [], isLoading } = usePagesQuery(companyId, gameId, {
    limit: 100,
    ignore_parent_id: true,
    types: [PageLayoutType.Article, PageLayoutType.Collection, PageLayoutType.Content, PageLayoutType.Blocks],
  })
  const sectionsList = useMemo(
    () =>
      webSite?.config?.sections?.filter(
        it =>
          (Object.values(SectionType).includes(it.name) && it.name !== SectionType.Page) ||
          (it.object_id && pages.find(p => p.id === it.object_id)),
      ),
    [webSite?.config, pages],
  )
  const [isEditing, setIsEditing] = useState(false)
  const [sectionToEdit, setSectionToEdit] = useState<WebsiteSection>()
  const theme = useTheme()

  const [pagesAvailable, setPagesAvailable] = useState<PageRead[]>([])

  useEffect(() => {
    // remove already added pages
    const filtered = pages.filter(p => !sectionsList?.find(s => s.object_id === p.id || s.name === p.slug))
    setPagesAvailable(filtered)
  }, [pages, sectionsList])

  const getSectionName = (section_id: string) => {
    return pages.find(p => p.id === section_id)?.title as SectionType
  }

  const getPageBySection = (section_id?: string) => {
    if (!section_id) {
      return
    }
    return pages.find(p => p.id === section_id)
  }
  const isSectionVisible = (s: WebsiteSection) => {
    let sections = sectionsList
    if (!sections) {
      return true
    }

    let section = s.object_id
      ? sections.find(it => it.object_id === s.object_id)
      : sections.find(it => it.name == s.name)
    if (!section) {
      return false
    }

    return section.visible
  }
  const onSectionVisibleChange = (s: WebsiteSection, value: boolean) => {
    let sections = sectionsList || []
    let section = s.object_id
      ? sections.find(it => it.object_id == s.object_id)
      : sections.find(it => it.name == s.name)

    if (!section) {
      section = { name: s.name, visible: value }
      sections.push(section)
    } else {
      section.visible = value
    }

    setWebSite({
      ...webSite,
      config: {
        ...webSite.config,
        sections: sections,
      },
    })
  }

  const handleChange = (section?: WebsiteSection) => {
    if (sectionToEdit && section) {
      const sections = sectionsList || []
      const sectionIndex = sectionToEdit.object_id
        ? sections.findIndex(it => it.object_id == sectionToEdit.object_id)
        : sections.findIndex(it => it.name == section.name)

      if (sectionIndex > -1) {
        sections[sectionIndex] = section
        setWebSite({
          ...webSite,
          config: {
            ...webSite.config,
            sections: sections,
          },
        })
      }
    } else if (section) {
      setWebSite({
        ...webSite,
        config: {
          ...webSite.config,
          sections: [...(webSite.config?.sections || []), section],
        },
      })
    }
    setIsEditing(false)
    setSectionToEdit(undefined)
  }

  const handleDelete = (s: WebsiteSection) => {
    const sections = sectionsList || []
    const section = s.object_id
      ? sections.findIndex(it => it.object_id == s.object_id)
      : sections.findIndex(it => it.name == s.name)

    if (section > -1) {
      sections.splice(section, 1)
      setWebSite({
        ...webSite,
        config: {
          ...webSite.config,
          sections: sections,
        },
      })
    }
  }

  const handleMove = (s: WebsiteSection, direction: number) => {
    const sections = sectionsList || []
    const section = s.object_id
      ? sections.findIndex(it => it.object_id == s.object_id)
      : sections.findIndex(it => it.name == s.name)
    if (section > -1) {
      sections.splice(section + direction, 0, sections.splice(section, 1)[0])
      setWebSite({
        ...webSite,
        config: {
          ...webSite.config,
          sections: sections,
        },
      })
    }
  }

  useEffect(() => {
    handleMenuChange()
  }, [sectionsList])

  const handleMenuChange = () => {
    const hubSections = (sectionsList?.map(it => ({
      type: it.name,
      visible: it.visible,
      name: !it.object_id ? i18next.t(`section.${it.name}`) : getSectionName(it.object_id) || it.name,
      icon: it.icon,
      slug: getPageBySection(it.object_id)?.slug,
    })) || []) as WebsiteSection[]

    // send new sections to hub
    props.sendIframeMessage({
      menuSections: hubSections,
    })
  }

  const changePageSectionOptions = useMemo(() => {
    const availablePages = pagesAvailable
    if (!sectionToEdit) {
      return availablePages
    }
    const currentPage = getPageBySection(sectionToEdit.object_id)
    if (currentPage) {
      availablePages.push(currentPage)
    }
    return availablePages
  }, [pagesAvailable, sectionToEdit])

  if (isLoading) {
    return (
      <div className="flex flex-col gap-3 p-3">
        {new Array(10).fill(0).map(() => (
          <Skeleton key={uuid4()} className="h-8 w-full" />
        ))}
      </div>
    )
  }
  return (
    <Box flexGrow={1} sx={{ borderRight: commonStyles.borderValue }} borderRadius={0}>
      <Typography
        variant={'title1' as never}
        fontWeight="600"
        sx={{
          padding: theme.spacing(1.75),
          borderBottom: commonStyles.borderValue,
        }}
      >
        {i18next.t('hub-settings.sections')}
      </Typography>
      <List>
        <ReactSortable
          animation={200}
          delay={2}
          filter=".non-drag"
          list={sectionsList?.map(it => ({ ...it, id: it.name })) || []}
          setList={v => {
            if (webSite.config?.sections?.length !== v.length) {
              // this is not a drag and drop
              return
            }
            setWebSite({
              ...webSite,
              config: {
                ...webSite.config,
                sections: v,
              },
            })
          }}
        >
          {sectionsList?.map(it => {
            const actions = [] as SelectOption[]
            if (it.object_id) {
              actions.push({
                children: i18next.t('edit'),
                value: 'edit',
                icon: <EditIcon />,
              })
              actions.push({
                value: 'delete',
                children: <span className="text-text-error-primary"> {i18next.t('delete')} </span>,
                icon: <Trash className="text-text-error-primary" />,
              })
            }
            return (
              <ListItem
                className="cursor-grab hover:text-black/50"
                disablePadding
                secondaryAction={
                  actions.length > 0 && (
                    <TableRowEditButton
                      onChange={v => {
                        switch (v) {
                          case 'move-up':
                            handleMove(it, -1)
                            break
                          case 'move-down':
                            handleMove(it, +1)
                            break
                          case 'edit':
                            setSectionToEdit(it)
                            setIsEditing(true)
                            break
                          case 'delete':
                            handleDelete(it)
                            break
                        }
                      }}
                      options={actions}
                    />
                  )
                }
              >
                <ListItemIcon>
                  <ListItemSwitch
                    sx={{ pr: 0 }}
                    label=""
                    checked={isSectionVisible(it)}
                    key={it.name}
                    onChange={(value: boolean) => onSectionVisibleChange(it, value)}
                  />
                </ListItemIcon>
                <ListItemText sx={{ maxWidth: '100px', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                  {!it.object_id ? i18next.t(`section.${it.name}`) : getSectionName(it.object_id) || it.name}
                </ListItemText>
              </ListItem>
            )
          })}
        </ReactSortable>
      </List>

      <Box sx={{ m: theme.spacing(1) }}>
        <Button fullWidth variant="contained" size="small" onClick={() => setIsEditing(true)}>
          {i18next.t('hub-settings.sections.add')}
        </Button>
      </Box>
      {isEditing && (
        <ChangeSectionDialog section={sectionToEdit} available={changePageSectionOptions} onClose={handleChange} />
      )}
    </Box>
  )
}

// const configFields = [{
//     i18: 'website.border_radius',
//     property: 'border_radius',
//     type: 'text'
// }, {
//     i18: 'website.font_size',
//     property: 'font_size',
//     type: 'text'
// }, {
//     i18: 'website.font_weight',
//     property: 'font_weight',
//     type: 'text'
// }, {
//     i18: 'website.base_padding',
//     property: 'base_padding',
//     type: 'text'
// }, {
//     i18: 'website.right_aside_width',
//     property: 'right_aside_width',
//     type: 'text'
// }, {
//     i18: 'website.left_aside_width',
//     property: 'left_aside_width',
//     type: 'text'
// }] as FieldInfo[]

const colorFields = [
  {
    i18: 'website.accent_color',
    property: 'accent_color',
    type: 'text',
  },
  {
    i18: 'website.text_color',
    property: 'text_color',
    type: 'text',
  },
  {
    i18: 'website.base_bg_color',
    property: 'base_bg_color',
    type: 'text',
  },
] as FieldInfo[]

const advancedColorFields = [
  {
    i18: 'dark_bg_color',
    property: 'dark_bg_color',
    type: 'text',
  },
  {
    i18: 'featured_bg_color',
    property: 'featured_bg_color',
    type: 'text',
  },
  {
    i18: 'card_bg_color',
    property: 'card_bg_color',
    type: 'text',
  },
  {
    i18: 'card_image_bg_color',
    property: 'card_image_bg_color',
    type: 'text',
  },
  {
    i18: 'ui_elements_color',
    property: 'ui_elements_color',
    type: 'text',
  },
  {
    i18: 'text_muted',
    property: 'text_muted',
    type: 'text',
  },
  {
    i18: 'left_menu_item_color',
    property: 'left_menu_item_color',
    type: 'text',
  },
  {
    i18: 'login_button_bg',
    property: 'login_button_bg',
    type: 'text',
  },
  {
    i18: 'login_button_color',
    property: 'login_button_color',
    type: 'text',
  },
  {
    i18: 'social_link_bg',
    property: 'social_link_bg_color',
    type: 'text',
  },
  {
    i18: 'social_link_color',
    property: 'social_link_color',
    type: 'text',
  },
  {
    i18: 'offer_label_bg',
    property: 'offer_label_bg_color',
    type: 'text',
  },
  {
    i18: 'offer_label_color',
    property: 'offer_label_color',
    type: 'text',
  },
  {
    i18: 'discount_label_bg_color',
    property: 'discount_label_bg_color',
    type: 'text',
  },
  {
    i18: 'discount_label_text_color',
    property: 'discount_label_text_color',
    type: 'text',
  },
  {
    i18: 'bonus_label_bg_color',
    property: 'bonus_label_bg_color',
    type: 'text',
  },
  {
    i18: 'bonus_label_text_color',
    property: 'bonus_label_text_color',
    type: 'text',
  },
  {
    i18: 'left_label_bg_color',
    property: 'left_label_bg_color',
    type: 'text',
  },
  {
    i18: 'left_label_text_color',
    property: 'left_label_text_color',
    type: 'text',
  },
  {
    i18: 'buy_button_bg_color',
    property: 'buy_button_bg_color',
    type: 'text',
  },
  {
    i18: 'buy_button_color',
    property: 'buy_button_color',
    type: 'text',
  },
  {
    i18: 'reward_button_bg',
    property: 'reward_button_bg',
    type: 'text',
  },
  {
    i18: 'reward_button_color',
    property: 'reward_button_color',
    type: 'text',
  },
  {
    i18: 'free_item_button_bg',
    property: 'free_item_button_bg',
    type: 'text',
  },
  {
    i18: 'free_item_button_color',
    property: 'free_item_button_color',
    type: 'text',
  },
  {
    i18: 'outline_button_color',
    property: 'outline_button_color',
    type: 'text',
  },
  {
    i18: 'custom_badge_bg_color',
    property: 'custom_badge_bg_color',
    type: 'text',
  },
  {
    i18: 'custom_badge_color',
    property: 'custom_badge_color',
    type: 'text',
  },
  {
    i18: 'free_item_custom_badge_bg_color',
    property: 'free_item_custom_badge_bg_color',
    type: 'text',
  },
  {
    i18: 'free_item_custom_badge_color',
    property: 'free_item_custom_badge_color',
    type: 'text',
  },
  {
    i18: 'item_timer_bg_color',
    property: 'item_timer_bg_color',
    type: 'text',
  },
  {
    i18: 'item_timer_color',
    property: 'item_timer_color',
    type: 'text',
  },
  {
    i18: 'maintenance_panel_bg_color',
    property: 'maintenance_panel_bg_color',
    type: 'text',
  },
  {
    i18: 'maintenance_panel_text_color',
    property: 'maintenance_panel_text_color',
    type: 'text',
  },
  {
    i18: 'bundle_list_bg_color',
    property: 'bundle_list_bg_color',
    type: 'text',
  },
  {
    i18: 'news_title_color',
    property: 'news_title_color',
    type: 'text',
  },
  {
    i18: 'news_date_color',
    property: 'news_date_color',
    type: 'text',
  },
  {
    i18: 'news_active_tab_button_bg_color',
    property: 'news_active_tab_button_bg_color',
    type: 'text',
  },
] as FieldInfo[]

const SettingsEditor = (editorProps: { webSite: WebsiteRead; setWebSite: (webSite: WebsiteRead) => void }) => {
  const { webSite, setWebSite } = editorProps
  const theme = useTheme()
  // const renderConfigFields = (fields: FieldInfo[]) => {
  //     return <>
  //         {fields.map((it, idx) => <StyledTextField
  //             label={i18next.t(it.i18)}
  //             type={it.type}
  //             key={it.property}
  //             value={(webSite as unknown as KeyValue)[it.property]}
  //             onChange={(e) => setWebSite({
  //                 ...webSite,
  //                 config: {
  //                     ...webSite.config,
  //                     [it.property]: e.target.value
  //                 }
  //             })}
  //             fullWidth={it.fullWidth !== false}
  //             margin={idx > 0}
  //         />)}
  //     </>
  // }

  const defaultColorValues = {
    accent_color: '#d1b46b',
    text_color: '#ffffff',
    base_bg_color: '#151514',
    free_item_button_bg: '#8fa249',
    reward_button_bg: '#9e68e3',
    maintenance_panel_bg_color: '#ff0000',
  } as KeyValue

  const getColorValue = (propName: string): string => {
    let v = (webSite.config as unknown as KeyValue)[propName] as string
    if (v) {
      return v
    }
    return (defaultColorValues[propName] as string) || ''
  }

  function renderColorFields(fields: FieldInfo[]) {
    return (
      <>
        {fields.map(it => (
          <FieldGroup label={i18next.t(it.i18)}>
            <ColorPicker
              key={it.property}
              name={it.property}
              value={getColorValue(it.property)}
              onChange={e =>
                setWebSite({
                  ...webSite,
                  config: {
                    ...webSite.config,
                    [it.property]: e.target.value,
                  },
                })
              }
            />
          </FieldGroup>
        ))}
      </>
    )
  }

  //let v = colorFields.map((it) => getColorValue(it.property)).join(',');
  return (
    <Box flexGrow={1} sx={{ borderRight: commonStyles.borderValue }} borderRadius={0} p={theme.spacing(1)}>
      {renderColorFields(colorFields)}

      {/*<StyledTextField*/}
      {/*    margin*/}
      {/*    label={"Colors"}*/}
      {/*    type={"text"}*/}
      {/*    value={v}*/}
      {/*/>*/}

      <Box sx={{ marginTop: '24px' }}>{i18next.t('website.advanced')}</Box>
      {renderColorFields(advancedColorFields)}
    </Box>
  )
}

export function LeftSideBar(props: {
  webSite: WebsiteRead
  setWebSite: (webSite: WebsiteRead) => void
  baseUrl: string
  sx?: SxProps<Theme>
  sendIframeMessage: (data: { menuSections?: WebsiteSection[] }) => void
}) {
  const { webSite, setWebSite } = props
  const theme = useTheme()

  const [activeTab, setActiveTab] = useState<EditTab>('settings')

  return (
    <Box
      display="flex"
      flexDirection="row"
      sx={{
        width: HUB_EDITOR_LEFT_SIDE_WIDTH,
        minWidth: HUB_EDITOR_LEFT_SIDE_WIDTH,
        ...props.sx,
      }}
    >
      <List
        sx={{
          width: theme.spacing(7),
          borderRight: commonStyles.borderValue,
          padding: theme.spacing(0.5),
        }}
      >
        {getNavItems().map((it, idx) => (
          <NavItem
            showText={false}
            href={it.href}
            icon={it.icon}
            key={idx}
            onClick={() => setActiveTab(it.value)}
            selected={it.value == activeTab}
          />
        ))}
      </List>

      <Box sx={{ overflow: 'auto', height: '100%', width: '100%' }}>
        {activeTab == 'sections' && (
          <SectionEditor webSite={webSite} setWebSite={setWebSite} sendIframeMessage={props.sendIframeMessage} />
        )}
        {activeTab == 'settings' && <SettingsEditor webSite={webSite} setWebSite={setWebSite} />}
      </Box>
    </Box>
  )
}
