import { QueryClient } from '@tanstack/react-query'
import { JobRead } from '@/api/dashboard'
import { JobContext } from '@/types'

export const updateJob = (queryClient: QueryClient, gameId: string, ctx: JobContext) => {
  queryClient.setQueryData(['jobs', gameId], (prev: JobRead[]) => {
    if (prev) {
      return prev.map(j =>
        j.id === ctx.job_id
          ? {
              ...j,
              progress_done: ctx.done,
              progress_total: ctx.total,
              progress_name: ctx.progress_name,
              result_error: ctx.error_text,
            }
          : j,
      )
    }
    return prev
  })
}
