import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const GamePad: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 17 17" ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.41666 11.8416C1.41666 13.1301 2.17559 13.9584 3.29591 13.9584C3.98256 13.9584 4.45237 13.6766 4.87056 13.1129L5.74823 11.9394C5.8773 11.7726 6.01669 11.6921 6.16641 11.6921H10.8336C10.9833 11.6921 11.1227 11.7726 11.2518 11.9394L12.1294 13.1129C12.5476 13.6766 13.0174 13.9584 13.7041 13.9584C14.8244 13.9584 15.5833 13.1301 15.5833 11.8416C15.5833 11.2894 15.4697 10.6567 15.2787 9.94918C14.9793 8.83327 14.4578 7.31471 13.957 6.14127C13.5389 5.15766 13.3169 4.69749 12.274 4.43289C11.3447 4.19706 10.0643 4.04175 8.49999 4.04175C6.93567 4.04175 5.6553 4.19706 4.73116 4.43289C3.68312 4.69749 3.46112 5.15766 3.04293 6.14127C2.54214 7.31471 2.0207 8.83327 1.72126 9.94918C1.53024 10.6567 1.41666 11.2894 1.41666 11.8416ZM5.20561 8.48732C4.95935 8.48732 4.81531 8.32955 4.81531 8.07317C4.81531 7.82172 4.95935 7.66394 5.20561 7.66394H6.00017V6.8455C6.00017 6.58912 6.13956 6.43135 6.37653 6.43135C6.60886 6.43135 6.74825 6.58912 6.74825 6.8455V7.66394H7.49634C7.76119 7.66394 7.91917 7.82172 7.91917 8.07317C7.91917 8.32955 7.76119 8.48732 7.49634 8.48732H6.74825V9.3107C6.74825 9.56708 6.60886 9.72485 6.37653 9.72485C6.13956 9.72485 6.00017 9.56708 6.00017 9.3107V8.48732H5.20561ZM12.0034 7.31882C12.0034 7.71325 11.7014 8.02879 11.3343 8.02879C10.9673 8.02879 10.6606 7.71325 10.6606 7.31882C10.6606 6.92439 10.9673 6.60884 11.3343 6.60884C11.7014 6.60884 12.0034 6.92439 12.0034 7.31882ZM10.6606 8.75356C10.6606 9.14799 10.3632 9.46354 9.99151 9.46354C9.62444 9.46354 9.32241 9.14799 9.32241 8.75356C9.32241 8.36406 9.62444 8.04359 9.99151 8.04359C10.3632 8.04359 10.6606 8.36406 10.6606 8.75356Z"
        fill="currentColor"
      />
    </IconBase>
  );
});
