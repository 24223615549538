import { useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { Button, cn, useModal } from '@dashboard/ui'
import { Sidebar, SidebarGroup } from '@/ui'
import { Plus } from '@/icons'
import { useBanner, useGenerateCompanyPath } from '@/libs'
import { localesQueryOptions } from '../api'
import { LocaleSidebarLink } from '../components'
import { L10nLocaleModalAdd } from './L10nLocaleModalAdd'
import { JobFunctionName, Locale } from '@/api/dashboard'
import { useJobListener } from '@/providers/SocketProvider/useJobListener'
import { useCurrentUser } from '@/api/useCurrentUser'

export const L10nSidebar = () => {
  const scrollRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()
  const { bannerMode } = useBanner()
  const { canEdit } = useCurrentUser()
  const { companyId, gameId } = useGenerateCompanyPath()
  const { data: locales = [], isLoading } = useQuery(localesQueryOptions(companyId, gameId))

  const openLocalModalAdd = useModal(props => <L10nLocaleModalAdd {...props} />)
  const { progressJobs } = useJobListener([JobFunctionName.TranslateTranslateAll, JobFunctionName.TranslateSyncAll])

  const handleWindowScroll = useCallback(() => {
    if (!scrollRef.current) {
      return
    }
    const clientRect = scrollRef.current.getBoundingClientRect()
    scrollRef.current.style.maxHeight = `calc(100vh - ${clientRect.top}px - 0.75rem)`
  }, [scrollRef])

  useEffect(() => {
    window.addEventListener('scroll', handleWindowScroll)
    return () => {
      window.removeEventListener('scroll', handleWindowScroll)
    }
  }, [handleWindowScroll])

  const getJob = (locale: Locale) => {
    return progressJobs?.find(it => it.function_args.locale == locale)
  }

  return (
    <Sidebar
      ref={scrollRef}
      className={cn(
        "sticky left-0 top-3 max-h-screen overflow-y-auto transition-[top] group-[&[data-modal-rush='true']]/main-outlet:top-1",
        bannerMode ? 'top-[calc(theme(spacing.3)+theme(height.banner))]' : 'top-3',
      )}
    >
      <SidebarGroup>
        {locales
          .sort((a, b) => a.locale.localeCompare(b.locale))
          .map(locale => (
            <LocaleSidebarLink
              key={`locale-sidebar-link-${locale.locale}`}
              loading={!!getJob(locale.locale)}
              locale={locale}
            />
          ))}
      </SidebarGroup>

      {canEdit && !isLoading && (
        <SidebarGroup className="sticky bottom-0 left-0 bg-fg-primary">
          <div className="pointer-events-none absolute bottom-8 left-0 h-4 w-full bg-gradient-to-t from-fg-primary to-transparent" />
          <Button
            className="w-full"
            variant="outline"
            color="secondary"
            size="sm"
            data-testid="l10n/sidebar/add"
            onClick={() => openLocalModalAdd()}
          >
            <Plus size={16} />
            <span>{t('localization.locale.add')}</span>
          </Button>
        </SidebarGroup>
      )}
    </Sidebar>
  )
}
