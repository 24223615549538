import { Skeleton } from '@dashboard/ui'
import { TableCell, TableRow } from './index'

export const TableRowSkeleton = (props: { rowCount: number; columnCount: number }) => {
  return new Array(props.rowCount).fill('').map((_, idx) => (
    <TableRow key={idx}>
      {new Array(props.columnCount).fill('').map((_, idx) => (
        <TableCell key={idx}>
          <Skeleton className="h-6" />
        </TableCell>
      ))}
    </TableRow>
  ))
}
