import { useCallback, useEffect } from 'react'
import { $getRoot, $insertNodes, COMMAND_PRIORITY_CRITICAL, KEY_ENTER_COMMAND } from 'lexical'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $generateNodesFromDOM } from '@lexical/html'
import { mergeRegister } from '@lexical/utils'

interface HtmlValuePluginProps {
  value: string
}

export const HtmlValuePlugin = ({ value }: HtmlValuePluginProps) => {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    return mergeRegister(
      /**
       * Prevents the default behavior of the Enter key when the Alt key is pressed.
       */
      editor.registerCommand<KeyboardEvent | null>(
        KEY_ENTER_COMMAND,
        e => {
          if (e?.code === 'Enter' && e.altKey) {
            e?.preventDefault()
            return true
          }
          return false
        },
        COMMAND_PRIORITY_CRITICAL,
      ),
    )
  }, [editor])

  const updateEditorContent = useCallback(() => {
    setTimeout(() => {
      editor.update(() => {
        const parser = new DOMParser()
        const dom = parser.parseFromString(value, 'text/html')
        const nodes = $generateNodesFromDOM(editor, dom)
        $getRoot().clear()
        $getRoot().select()
        $insertNodes(nodes)
      })
    })
  }, [editor, value])

  useEffect(() => {
    updateEditorContent()
  }, [updateEditorContent])

  return null
}
