import { useTranslation } from 'react-i18next'
import { ModalBaseProps, ModalPresetConfirm } from '@dashboard/ui'
import { JobFunctionName, LocaleRead } from '@/api/dashboard'
import { useGenerateCompanyPath } from '@/libs'
import { useJobCreateMutate } from '@/providers/api/useJobCreateMutate'

interface L10nLocaleModalPublishProps extends ModalBaseProps {
  locale: LocaleRead
}

export const L10nLocaleModalPublish = ({ locale, ...rest }: L10nLocaleModalPublishProps) => {
  const { t } = useTranslation()
  const { companyId, gameId } = useGenerateCompanyPath()
  const { mutateAsync: createMutateAsync } = useJobCreateMutate(companyId, gameId)

  const onConfirm = async () => {
    await createMutateAsync({
      create: {
        function: {
          name: JobFunctionName.TranslateSyncAll,
          args: {
            locale: locale.locale,
            locale_id: locale.id,
          },
        },
      },
    })
    rest.onClose?.()
  }

  return (
    <ModalPresetConfirm
      {...rest}
      title={{
        children: t('localization.locale.publish-modal.title'),
        subtitle: t('localization.locale.publish-modal.text'),
      }}
      cancel={{
        label: t('Cancel'),
      }}
      submit={{
        callback: onConfirm,
        color: 'primary',
        label: t('localization.locale.publish-modal.confirm'),
      }}
    />
  )
}
