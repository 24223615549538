import { ReactNode } from 'react';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { cn } from '../../libs';

interface ModalContentProps {
  /**
   * Content of the modal.
   */
  children: ReactNode;

  className?: string;
}

/**
 * Limits content in height and adds a custom scrollbar.
 */
export const ModalContent = ({ children, className }: ModalContentProps) => (
  <OverlayScrollbarsComponent
    className={cn('relative flex max-h-[calc(80vh-theme(spacing.8))] flex-col px-6', className)}
    data-id="custom-scroll"
    defer={true}
  >
    <div>{children}</div>
    <div
      className={cn(
        'pointer-events-none sticky bottom-0 left-0 right-0 mt-[-4px] h-[calc(18px+4px)]',
        'bg-gradient-to-b from-transparent to-fg-primary',
      )}
    />
  </OverlayScrollbarsComponent>
);
