import { SegmentGroupRead, SegmentGroupType } from '@/api/dashboard'
import { FieldGroup } from '@/ui'
import { Controller, useForm } from 'react-hook-form'
import { dashboardClient } from '@/api'
import { useParams } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'

import { useCurrentUser } from '@/api/useCurrentUser'
import {
  Button,
  Checkbox,
  Input,
  Modal,
  ModalContent,
  ModalFooter,
  ModalProps,
  ModalTitle,
  Select,
  formatHookFormErrors,
} from '@dashboard/ui'
import { useTranslation } from 'react-i18next'
import { MAX_NAME_LENGTH } from '@/Settings'

export interface EditSegmentGroupDialogProps extends ModalProps {
  item: SegmentGroupRead
  onAdded?: (item: SegmentGroupRead) => void
}

export default function EditSegmentGroupModal(props: EditSegmentGroupDialogProps) {
  const { ...rest } = props
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const { isSuperAdmin } = useCurrentUser()
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const {
    register,
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<SegmentGroupRead>({
    mode: 'onChange',
    values: { ...props.item },
  })

  const onSaveClick = async (data: SegmentGroupRead) => {
    let addedItem: SegmentGroupRead | null = null
    if (data.id) {
      await dashboardClient.v1.updateSegmentGroup(companyId, gameId, data.id, data)
    } else {
      let { data: created } = await dashboardClient.v1.createSegmentGroup(companyId, gameId, data)
      addedItem = created
    }
    queryClient.invalidateQueries({ queryKey: ['segment_groups', gameId] })
    if (addedItem) {
      props.onAdded?.(addedItem)
    }
    props.onClose?.()
  }

  const type = watch('type')

  return (
    <Modal {...rest}>
      <form onSubmit={handleSubmit(onSaveClick)}>
        <ModalContent>
          <ModalTitle>{t(props.item.id ? 'segments.group.edit' : 'segments.group.add')}</ModalTitle>
          <FieldGroup label={t('segment.name')}>
            <Input
              autoFocus
              {...register('name', {
                required: t('validation.required'),
                maxLength: {
                  message: t('validation.maxLength', {
                    field: t('segment.name'),
                    value: MAX_NAME_LENGTH,
                  }),
                  value: MAX_NAME_LENGTH,
                },
                setValueAs: v => v?.trim(),
              })}
              {...formatHookFormErrors(errors, 'name')}
            />
          </FieldGroup>

          <FieldGroup label={t('segments.group.type')}>
            <Controller
              control={control}
              name="type"
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  {...field}
                  items={[
                    {
                      value: SegmentGroupType.Static,
                      children: t('segments.group.type.static'),
                      caption: t('segments.group.type.static.desc'),
                    },
                    {
                      value: SegmentGroupType.Dynamic,
                      children: t('segments.group.type.dynamic'),
                      caption: t('segments.group.type.dynamic.desc'),
                    },
                  ]}
                />
              )}
            />
          </FieldGroup>

          {type == SegmentGroupType.Dynamic && (
            <div className="flex flex-col gap-4">
              <Checkbox {...register('enabled')}>{t('campaign.editor.campaign-published')}</Checkbox>
              {isSuperAdmin && <Checkbox {...register('is_template')}>{t('segments.editor.is_template')}</Checkbox>}
            </div>
          )}
        </ModalContent>

        <ModalFooter>
          <Button variant="outline" color="secondary" onClick={props.onClose} type="button">
            {t('Cancel')}
          </Button>
          <Button type={'submit'}>{t(props.item.id ? 'Save2' : 'Add')}</Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}
