import { NavLink, NavLinkProps } from 'react-router-dom'
import { ReactNode, createElement } from 'react'
import { Skeleton, cn } from '@dashboard/ui'
import { AghanimIcon } from '@/icons'
import { isAghanimIcon } from '@/libs'

interface SidebarLinkProps extends NavLinkProps {
  children: ReactNode
  onNavigate?: () => void
  icon?: AghanimIcon | ReactNode
  extra?: ReactNode
}

export const SidebarLink = ({ children, onNavigate, icon, extra, ...rest }: SidebarLinkProps) => {
  return (
    <div className="group/sidebar-link relative mb-1 overflow-hidden rounded-md last:mb-0">
      <NavLink
        {...rest}
        className={cn(
          'relative flex min-h-8 items-center gap-2 px-2 py-1 text-text-secondary transition-all',
          'group-hover/sidebar-link:bg-fg-primary-hover',
          'group-hover/sidebar-link:text-text-secondary-hover',
          'group-has-[[data-open="true"]]/sidebar-link:bg-fg-primary-hover',
          'group-has-[[data-open="true"]]/sidebar-link:text-text-secondary-hover',
          'active:bg-fg-primary-alt',
          'active:text-text-primary',
          '[&.active]:bg-fg-primary-alt',
        )}
        role="option"
        aria-controls="product_form"
        onClick={onNavigate}
      >
        {icon && (
          <div className="flex size-6 shrink-0 items-center justify-center">
            {/*  @ts-ignore */}
            {isAghanimIcon(icon) ? createElement(icon, { size: 16 }) : icon}
          </div>
        )}
        <div className="w-full truncate text-caption-md">{children}</div>
      </NavLink>
      {extra && (
        <div
          className={cn(
            'absolute right-0 top-0 flex h-full items-center gap-1 pr-2 transition-all',
            'invisible opacity-0',
            'group-hover/sidebar-link:visible',
            'group-hover/sidebar-link:opacity-100',
            'has-[[data-open="true"]]:visible',
            'has-[[data-open="true"]]:opacity-100',
          )}
        >
          <div
            className={cn(
              'absolute right-0 top-0 h-full w-24',
              'bg-gradient-to-r from-transparent via-fg-primary-alt to-fg-primary-alt',
              'pointer-events-none',
            )}
          />
          <div className="relative z-10">{extra}</div>
        </div>
      )}
    </div>
  )
}

export const SidebarLinkSkeleton = () => {
  return (
    <div className="mb-1 h-8 w-full last:mb-0">
      <Skeleton className="size-full" />
    </div>
  )
}
