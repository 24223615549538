import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const H3: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 24 24" ref={ref}>
      <path d="M3 19V5H5.63121V10.9268H12.3617V5H15V19H12.3617V13.0527H5.63121V19H3Z" fill="currentColor" />
      <path
        d="M18.4917 19C18.0139 19 17.5892 18.9179 17.2176 18.7538C16.8483 18.5897 16.5563 18.3612 16.3417 18.0684C16.1272 17.7757 16.0133 17.4374 16 17.0537H17.2475C17.2586 17.2378 17.3194 17.3986 17.43 17.5361C17.5406 17.6714 17.6877 17.7768 17.8713 17.8522C18.0548 17.9276 18.2605 17.9653 18.4884 17.9653C18.7317 17.9653 18.9473 17.9232 19.1354 17.8389C19.3234 17.7524 19.4705 17.6326 19.5766 17.4796C19.6828 17.3265 19.7348 17.1502 19.7326 16.9506C19.7348 16.7443 19.6817 16.5624 19.5733 16.4049C19.4649 16.2475 19.3079 16.1244 19.1022 16.0356C18.8987 15.9469 18.6532 15.9026 18.3656 15.9026H17.7651V14.951H18.3656C18.6023 14.951 18.8091 14.91 18.986 14.8279C19.1652 14.7459 19.3057 14.6305 19.4074 14.4819C19.5092 14.3311 19.5589 14.157 19.5567 13.9596C19.5589 13.7666 19.5158 13.5992 19.4273 13.4572C19.3411 13.3131 19.2183 13.201 19.059 13.1212C18.902 13.0413 18.7173 13.0014 18.505 13.0014C18.297 13.0014 18.1046 13.0391 17.9277 13.1145C17.7507 13.19 17.608 13.2975 17.4997 13.4373C17.3913 13.5748 17.3338 13.7389 17.3271 13.9297H16.1427C16.1515 13.5482 16.261 13.2132 16.4711 12.9249C16.6835 12.6343 16.9666 12.4081 17.3205 12.2462C17.6744 12.0821 18.0714 12 18.5116 12C18.965 12 19.3588 12.0854 19.6927 12.2562C20.029 12.4247 20.2888 12.6521 20.4724 12.9382C20.656 13.2243 20.7478 13.5404 20.7478 13.8864C20.75 14.2701 20.6372 14.5917 20.4094 14.8512C20.1838 15.1107 19.8874 15.2804 19.5202 15.3603V15.4135C19.998 15.48 20.3641 15.6575 20.6184 15.9458C20.875 16.2319 21.0022 16.5879 21 17.0138C21 17.3953 20.8916 17.7369 20.6748 18.0385C20.4603 18.3379 20.1639 18.573 19.7856 18.7438C19.4096 18.9146 18.9783 19 18.4917 19Z"
        fill="currentColor"
      />
    </IconBase>
  );
});
