import { useTranslation } from 'react-i18next'
import { ModalClose, ToastSeverity, useToast } from '@/ui'
import { useForm } from 'react-hook-form'
import {
  Button,
  FormErrorMessage,
  Input,
  Modal,
  ModalContent,
  ModalFooter,
  ModalProps,
  ModalTitle,
  formatHookFormErrors,
} from '@dashboard/ui'
import { getPercentFrom, urlRegexp } from '@/libs'
import { StepProgressBar } from '@/layouts/get-started/components'
import { dashboardClient, getErrorText } from '@/api'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'

import { useGameSettingsQuery } from '@/api/useGameSettingsQuery'
import { JobFunctionName } from '@/api/dashboard'

import { AnimatePresence } from 'framer-motion'
import { useJobCreateMutate } from '@/providers/api/useJobCreateMutate'
import { useJobListener } from '@/providers/SocketProvider/useJobListener'

interface ImportSpreadSheetModalProps extends ModalProps {}

interface ImportFormData {
  url: string
}

export const ImportSpreadSheetModal = ({ ...rest }: ImportSpreadSheetModalProps) => {
  const { t } = useTranslation()
  const showToast = useToast()

  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const [isCancelling, setIsCancelling] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const { mutateAsync: createMutateAsync } = useJobCreateMutate(companyId, gameId)
  const { data: settings } = useGameSettingsQuery(companyId, gameId)
  const { progressJobs, isLoading: isJobsLoading, refetch } = useJobListener([JobFunctionName.StoreImport])
  const progress = progressJobs[0]
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<ImportFormData>({
    values: {
      url: settings?.last_import_store_url || progress?.function_args?.spreadsheet_url || '',
    },
  })

  useEffect(() => {
    if (!progress) {
      return
    }
    if (progress.progress_total > 0 && progress.progress_done === progress.progress_total) {
      showToast({ message: t('import.done'), severity: ToastSeverity.success })
    } else if (progress.result_error) {
      setError(progress.result_error)
    }
  }, [progress])

  const onSubmit = async (data: ImportFormData) => {
    try {
      setError(null)

      await createMutateAsync({
        create: {
          function: {
            name: JobFunctionName.StoreImport,
            args: {
              spreadsheet_url: data.url,
            },
          },
        },
      })
    } catch (e) {
      setError(getErrorText(e))
    }
  }

  const onCancelClick = async () => {
    if (!progress?.id) {
      return
    }
    try {
      setError(null)
      setIsCancelling(true)
      await dashboardClient.v1.cancelJob(companyId, gameId, progress.id)
      await refetch()
      showToast({ message: t('import.cancel.done'), severity: ToastSeverity.warning })
    } catch (e) {
      setError(getErrorText(e))
    } finally {
      setIsCancelling(false)
    }
  }

  const isProcess = isCancelling || isSubmitting || !!progress || isJobsLoading

  return (
    <Modal {...rest} className="max-w-[600px]">
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalContent>
          <ModalTitle>
            <div className="flex items-center">
              {t('import.import-spreadsheet')}
              {rest.onClose && <ModalClose onClick={rest.onClose} />}
            </div>
          </ModalTitle>
          <div className="rounded-xl bg-fg-secondary p-3">
            <Input
              {...register('url', {
                required: t('validation.required'),
                validate: v => {
                  if (!v) {
                    return t('validation.required')
                  }
                  if (!v.startsWith('https://docs.google.com/spreadsheets')) {
                    return t('import.validation.url')
                  }
                  return true
                },
                pattern: { value: urlRegexp, message: t('validation.invalid_url') },
              })}
              {...formatHookFormErrors(errors, 'url')}
              autoFocus
              disabled={isProcess}
              placeholder={t('Paste a link')}
            />

            <AnimatePresence>
              {progress && (
                <div className="mt-2 flex flex-col items-end text-caption-sm font-normal text-text-secondary">
                  {progress.progress_name ? t(`import.progress.${progress.progress_name}`) : ''}{' '}
                  {progress.progress_done}%
                  <StepProgressBar
                    value={getPercentFrom(progress.progress_done, progress.progress_total || 100)}
                    size="md"
                  />
                </div>
              )}
            </AnimatePresence>
            <AnimatePresence>{error && <FormErrorMessage>{error}</FormErrorMessage>}</AnimatePresence>
          </div>
        </ModalContent>

        <ModalFooter>
          <Button
            className="mr-auto"
            color="primary"
            variant="outline"
            type="button"
            size="sm"
            loading={isCancelling}
            disabled={!progress}
            onClick={onCancelClick}
          >
            {t('Cancel')}
          </Button>
          <Button type="submit" color="primary" size="sm" loading={!!progress || isSubmitting} disabled={isProcess}>
            {t('import.import')}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}
