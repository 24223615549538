import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '@/icons'

export const EditGlobalBonusOutline: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 24 24" ref={ref}>
      <path
        d="M15.0615 4.60897C13.5997 4.00347 11.9911 3.84504 10.4393 4.15372C8.88743 4.4624 7.46197 5.22433 6.34315 6.34315C5.22433 7.46197 4.4624 8.88743 4.15372 10.4393C3.84504 11.9911 4.00347 13.5997 4.60897 15.0615C5.21447 16.5233 6.23985 17.7727 7.55544 18.6518C8.73215 19.438 10.0938 19.8964 11.5003 19.9844C11.7759 20.0016 12 20.2239 12 20.5V21.5C12 21.7761 11.776 22.0013 11.5002 21.9875C9.6978 21.8973 7.94996 21.3207 6.4443 20.3147C4.79981 19.2159 3.51809 17.6541 2.76121 15.8268C2.00433 13.9996 1.8063 11.9889 2.19215 10.0491C2.578 8.10929 3.53041 6.32746 4.92894 4.92894C6.32746 3.53041 8.10929 2.578 10.0491 2.19215C11.9889 1.8063 13.9996 2.00433 15.8268 2.76121C17.6541 3.51809 19.2159 4.79981 20.3147 6.4443C21.3207 7.94996 21.8973 9.6978 21.9875 11.5002C22.0013 11.776 21.7761 12 21.5 12H20.5C20.2239 12 20.0016 11.7759 19.9844 11.5003C19.8964 10.0938 19.438 8.73215 18.6518 7.55544C17.7727 6.23985 16.5233 5.21447 15.0615 4.60897Z"
        fill="currentColor"
      />
      <path
        d="M8.37842 16.1679C8.14444 16.8601 8.76499 17.2977 9.34485 16.8601L11.9898 14.9262L14.6449 16.8601C15.2146 17.2977 15.8352 16.8702 15.6012 16.1679L14.5636 13.0433L17.2289 11.1298C17.7477 10.7532 17.6256 9.96947 16.8728 9.97965L13.587 10.0102L12.5798 6.86514C12.3662 6.19339 11.6134 6.19339 11.3998 6.86514L10.4028 10.0102L7.11697 9.97965C6.37435 9.96947 6.22176 10.7532 6.75075 11.1298L9.42623 13.0433L8.37842 16.1679Z"
        fill="currentColor"
      />
      <path
        d="M21.5777 15.0928C21.1206 14.6357 20.3795 14.6357 19.9224 15.0928C19.398 15.6172 18.8738 16.1417 18.3497 16.6662C17.2966 17.7199 16.2436 18.7736 15.1892 19.8259C15.0812 19.9337 14.986 20.0288 14.9158 20.1433C14.8541 20.244 14.8087 20.3537 14.7811 20.4685C14.7498 20.5992 14.7499 20.7337 14.75 20.8863C14.7502 21.0352 14.7501 21.1841 14.7501 21.3331C14.7501 21.4039 14.75 21.4747 14.75 21.5455C14.75 21.7526 14.9179 21.9205 15.125 21.9205C15.3448 21.9205 15.5645 21.9203 15.7842 21.9205C15.9368 21.9207 16.0714 21.9208 16.202 21.8894C16.3168 21.8619 16.4265 21.8164 16.5272 21.7547C16.6418 21.6845 16.7368 21.5893 16.8446 21.4813C17.8972 20.4267 18.9512 19.3734 20.0051 18.3201C20.5294 17.7962 21.0536 17.2723 21.5777 16.7482C22.0348 16.2911 22.0348 15.5499 21.5777 15.0928Z"
        fill="currentColor"
      />
      <path
        d="M18.5 21.1705C18.2929 21.1705 18.125 21.3384 18.125 21.5455C18.125 21.7526 18.2929 21.9205 18.5 21.9205H21.875C22.0821 21.9205 22.25 21.7526 22.25 21.5455C22.25 21.3384 22.0821 21.1705 21.875 21.1705H18.5Z"
        fill="currentColor"
      />
    </IconBase>
  )
})
