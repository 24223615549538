import { FunctionComponent, createElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ImageUsageContext, ItemImageCreate, ItemImageRead, ItemRead } from '../../../api/dashboard'
import { Button } from '@/ui'
import { PreviewFeaturedBanner } from './PreviewFeaturedBanner'
import { PreviewReceipt } from './PreviewReceipt'
import { PreviewStoreCard } from './PreviewStoreCard'

interface MediaContentPreviewProps {
  sku: ItemRead
  image: ItemImageRead | ItemImageCreate
  onClickEdit: (itemImage: ItemImageRead | ItemImageCreate) => void
}

const mapPreviewCard = new Map<
  ImageUsageContext,
  FunctionComponent<{ sku: ItemRead; image: ItemImageRead | ItemImageCreate }>
>([
  [ImageUsageContext.Store, PreviewStoreCard],
  [ImageUsageContext.FeaturedBannerStore, PreviewFeaturedBanner],
  [ImageUsageContext.FeaturedBannerMain, PreviewFeaturedBanner],
  [ImageUsageContext.PaymentReceipt, PreviewReceipt],
])

const mapRecommendedSize = new Map<ImageUsageContext, [width: number, height: number]>([
  [ImageUsageContext.Store, [1080, 1080]],
  [ImageUsageContext.FeaturedBannerStore, [512, 512]],
  [ImageUsageContext.FeaturedBannerMain, [1024, 768]],
  [ImageUsageContext.PaymentReceipt, [1920, 1080]],
])

export const MediaContentPreview = ({ sku, image, onClickEdit }: MediaContentPreviewProps) => {
  const { t } = useTranslation()

  const previewCard = useMemo(() => mapPreviewCard.get(image.usage_context as ImageUsageContext), [image.usage_context])

  const recommendedSize = useMemo(
    () => mapRecommendedSize.get(image.usage_context as ImageUsageContext),
    [image.usage_context],
  )

  return (
    <div className="-mr-6 grid grid-cols-[200px_auto] gap-6 border-b border-border-secondary p-6 pl-0">
      <div className="flex flex-col gap-3">
        <div>
          <div className="mb-3 text-t6 text-text-secondary">
            {t(`sku.item.media-content.${image.usage_context}.label`)}
          </div>
          <div className="mb-3 text-sm text-text-secondary">
            {t(`sku.item.media-content.${image.usage_context}.desc`)}
          </div>
        </div>
        <div className="mt-auto">
          <Button type="button" onClick={() => onClickEdit(image)}>
            {t('sku.edit-image')}
          </Button>
          {recommendedSize?.length && (
            <div className="mt-1.5 text-[10px] leading-none text-text-quarterary-hover">
              {t('sku.item.media-content.recommend-size', { width: recommendedSize[0], height: recommendedSize[1] })}
            </div>
          )}
        </div>
      </div>
      <div className="flex items-center justify-center overflow-hidden rounded-md bg-[#09090B] p-6">
        {previewCard && createElement(previewCard, { sku, image })}
      </div>
    </div>
  )
}
