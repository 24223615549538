import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { InputSearch, SearchFilterGeneric, SelectFilter, SelectFilterItem } from '@dashboard/ui'
import { SendStatus, WebhookEventTrigger, WebhookRedacted } from '@/api/dashboard'
import { SelectFilterDate } from '@/components'
import { WebhookSearchFilter } from '../types'

interface WebhookSearchFilterToolbarProps {
  webhook: WebhookRedacted
  filter: SearchFilterGeneric<WebhookSearchFilter>
}

export const WebhookSearchFilterToolbar = ({ webhook, filter }: WebhookSearchFilterToolbarProps) => {
  const { t } = useTranslation()

  const triggersItems: SelectFilterItem[] = useMemo(() => {
    return Object.values(WebhookEventTrigger)
      .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
      .map(it => ({
        children: it,
        value: it,
      }))
  }, [])

  const eventTypeItems: SelectFilterItem[] = useMemo(() => {
    return webhook.events.sort().map(it => ({
      children: it,
      value: it,
    }))
  }, [webhook.events])

  const statusItems: SelectFilterItem[] = [
    { children: t('webhook.filters.success'), value: SendStatus.Success },
    { children: t('webhook.filters.failed'), value: SendStatus.Error },
  ]

  return (
    <div className="flex items-center gap-2">
      <InputSearch {...filter.registerInput('search_string')} placeholder={t('search')} />
      <div className="flex gap-2">
        {!!webhook.events.length && (
          <SelectFilter {...filter.registerSelect('event_type')} items={eventTypeItems}>
            {t('webhook.filters.event-type')}
          </SelectFilter>
        )}
        <SelectFilter {...filter.registerSelect('status')} items={statusItems}>
          {t('webhook.filters.status')}
        </SelectFilter>
        <SelectFilter {...filter.registerSelect('trigger')} items={triggersItems}>
          {t('webhook.filters.trigger')}
        </SelectFilter>
        <SelectFilterDate {...filter.registerSelect('dateFilter')} />
      </div>
    </div>
  )
}
