import { HTMLAttributes, MouseEvent, ReactNode, forwardRef, useCallback, useId, useMemo } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { MOTION_ANIMATION_SPEED_MD, cn, getMotionProps } from '../../libs';
import { ChevronDown, FilterLines, XClose } from '../../icons';
import { Popper } from '../Popper';
import { MenuItemBaseProps } from '../Menu';
import { SelectFilterInnerButton } from './SelectFilterInnerButton';
import { SelectFilterMenu } from './SelectFilterMenu';

export type SelectFilterValue = string | number | null | undefined;

export interface SelectFilterItem extends MenuItemBaseProps {
  value: SelectFilterValue;
}

export interface SelectFilterProps extends Omit<HTMLAttributes<HTMLLabelElement>, 'children' | 'label' | 'onChange'> {
  /**
   * Label for the select filter.
   */
  children: ReactNode;
  /**
   * Items for the select filter.
   */
  items: SelectFilterItem[];
  /**
   * Value for the select filter.
   */
  value: SelectFilterValue;
  /**
   * Callback when the select filter value changes.
   * @param value
   */
  onChange: (value: SelectFilterValue) => void;
}

const valueWrapperMotionProps = getMotionProps({
  initial: { x: -1, width: 0, opacity: 0 },
  animate: { x: 0, width: 'auto', opacity: 1 },
  exit: { x: 0, width: 0, opacity: 1 },
});

const valueInnerMotionProps = getMotionProps(
  {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  },
  { delay: MOTION_ANIMATION_SPEED_MD / 2 },
);

export const SelectFilter = forwardRef<HTMLLabelElement, SelectFilterProps>(
  ({ children, items, value, onChange, ...rest }, ref) => {
    const id = useId();

    const initialFocus = useMemo(() => {
      return Math.max(
        items.findIndex(item => item.value === value),
        0,
      );
    }, [items, value]);

    const onClickLabel = useCallback(
      (e: MouseEvent) => {
        if (!value) return;
        e.stopPropagation();
        onChange(null);
      },
      [onChange, value],
    );

    return (
      <Popper
        popper={props => <SelectFilterMenu {...props} items={items} onChange={onChange} />}
        params={{ placement: 'bottom-end' }}
        focusManager={{ initialFocus }}
      >
        <label
          {...rest}
          ref={ref}
          className={cn(
            'inline-flex h-8 items-stretch rounded-md border border-dashed border-border-primary bg-fg-primary transition-all',
            'pointer-events-none',
            value
              ? 'border-solid shadow-xs'
              : [
                  'text-text-tertiary',
                  'hover:border-border-primary-hover hover:bg-fg-primary-hover hover:text-text-secondary',
                ],
            rest.className,
          )}
        >
          <div className="flex h-full items-center truncate p-[2px]">
            <SelectFilterInnerButton variant={value ? 'clear' : 'empty'} onClick={onClickLabel}>
              {value ? <XClose className="shrink-0" size={16} /> : <FilterLines className="shrink-0" size={16} />}
              <span>{children}</span>
            </SelectFilterInnerButton>
          </div>

          <AnimatePresence initial={false}>
            {value && (
              <motion.div {...valueWrapperMotionProps} key={`value-${id}-${value}`}>
                <motion.div
                  {...valueInnerMotionProps}
                  className="flex h-full items-center truncate border-l border-border-secondary p-[2px]"
                >
                  <SelectFilterInnerButton variant="value">
                    <span>{items.find(item => item.value === value)?.children}</span>
                    <ChevronDown className="shrink-0" strokeWidth={2.5} size={16} />
                  </SelectFilterInnerButton>
                </motion.div>
              </motion.div>
            )}
          </AnimatePresence>
        </label>
      </Popper>
    );
  },
);
