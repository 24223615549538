import { AttrType, CustomAttribute } from '@/api/dashboard'
import { FieldGroup } from '@/ui'
import { Controller, useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import {
  Button,
  FormErrorMessage,
  Input,
  Modal,
  ModalContent,
  ModalFooter,
  ModalProps,
  ModalTitle,
  Select,
  formatHookFormErrors,
} from '@dashboard/ui'
import { useTranslation } from 'react-i18next'

interface EditGameAttrDialogProps extends ModalProps {
  item: CustomAttribute
  isNew: boolean
  onSave: (item: CustomAttribute) => void
}

export default function EditGameAttrModal(props: EditGameAttrDialogProps) {
  const { ...rest } = props
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<CustomAttribute>({
    mode: 'onChange',
    values: { ...props.item },
  })

  const onSaveClick = (item: CustomAttribute) => {
    props.onSave(item)
    props.onClose?.()
  }

  return (
    <Modal {...rest}>
      <ModalContent>
        <ModalTitle>{t(props.isNew ? 'player.attr-table.add-attr' : 'player.attr-table.edit-attr')}</ModalTitle>

        <FieldGroup label={t('player.attr-table.name')}>
          <Input
            {...register('name', { required: t('validation.required') })}
            autoFocus
            disabled={!props.isNew}
            {...formatHookFormErrors(errors, 'name')}
          />
        </FieldGroup>

        <FieldGroup label={t('player.attr-table.type')}>
          <Controller
            control={control}
            name="type"
            render={({ field: { value, onChange } }) => (
              <Select
                onChange={v => {
                  onChange(v)
                }}
                value={value as AttrType}
                items={Object.values(AttrType).map(it => ({ value: it, children: it }))}
              />
            )}
          />
          <ErrorMessage
            name="type"
            errors={errors}
            render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
          />
        </FieldGroup>
      </ModalContent>

      <ModalFooter>
        <Button size="sm" onClick={props.onClose} variant="outline" color="secondary">
          {t('Cancel')}
        </Button>
        <Button size="sm" onClick={handleSubmit(onSaveClick)}>
          {t('Save2')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
