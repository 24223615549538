import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import {
  Button,
  FormErrorMessage,
  Input,
  Modal,
  ModalContent,
  ModalFooter,
  ModalProps,
  ModalTitle,
  Select,
  Textarea,
  formatHookFormErrors,
} from '@dashboard/ui'
import { FieldGroup, ImageUploader } from '@/ui'
import { useItemPropertyCreateMutate } from '@/layouts/sku-settings/api/useItemPropertyCreateMutate'
import { ItemPropertyRead } from '@/api/dashboard'
import { useItemPropertyUpdateMutate } from '@/layouts/sku-settings/api/useItemPropertyUpdateMutate'
import { getErrorText } from '@/api'
import { MAX_DESCRIPTION_LENGTH, MAX_NAME_LENGTH } from '@/Settings'
import { NumberIcon } from '@/layouts/sku-settings/icons/NumberIcon'
import { TextIcon } from '@/layouts/sku-settings/icons/TextIcon'
import { Range } from '@/layouts/sku-settings/icons/Range'

interface EditItemCategoryDialogProps extends ModalProps {
  item: ItemPropertyRead
  created?: (item: ItemPropertyRead) => void
}

export const EditItemPropertyModal = ({ item, created, ...rest }: EditItemCategoryDialogProps) => {
  const { t } = useTranslation()
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const [errorMessage, setErrorMessage] = useState<string>('')
  const { mutateAsync: createMutateAsync } = useItemPropertyCreateMutate(companyId, gameId)
  const { mutateAsync: updateMutateAsync } = useItemPropertyUpdateMutate(companyId, gameId)

  const {
    formState: { isSubmitting, errors },
    register,
    control,
    handleSubmit,
    watch,
    setError,
  } = useForm<ItemPropertyRead>({
    values: { ...item },
  })

  const [type, min, max] = watch(['config.property_type', 'config.min', 'config.max'])

  const onSubmit = handleSubmit(async (data: ItemPropertyRead) => {
    if (type == 'range' && min! >= max) {
      setError('config.min', { message: t('validation.min-max') })
      return
    }

    try {
      if (item.id) {
        await updateMutateAsync({
          id: item.id,
          update: data,
        })
      } else {
        let { data: newCat } = await createMutateAsync({
          create: data,
        })

        created?.(newCat)
      }
    } catch (e) {
      setErrorMessage(getErrorText(e))
      return
    }

    rest.onClose?.()
  })

  return (
    <Modal {...rest}>
      <form onSubmit={onSubmit}>
        <ModalContent>
          <ModalTitle>{t(item.id ? 'item-props.edit' : 'item-props.create')}</ModalTitle>

          <FieldGroup label={t('item-props.name')}>
            <Input
              {...register('name', {
                required: t('validation.required'),
                setValueAs: value => value?.trim(),
              })}
              {...formatHookFormErrors(errors, 'name')}
              autoFocus={true}
              maxLength={MAX_NAME_LENGTH}
            />
          </FieldGroup>

          <FieldGroup label={t('item-props.description')}>
            <Textarea
              {...register('description', {
                setValueAs: value => value?.trim(),
              })}
              {...formatHookFormErrors(errors, 'description')}
              maxLength={MAX_DESCRIPTION_LENGTH}
              rows={3}
            />
          </FieldGroup>

          <FieldGroup label={t('item-props.type')}>
            <Controller
              control={control}
              name="config.property_type"
              rules={{ required: t('validation.required') }}
              render={({ field }) => (
                <Select
                  {...field}
                  {...formatHookFormErrors(errors, 'config.property_type')}
                  items={[
                    { icon: NumberIcon, children: t(`item-props.type.number`), value: 'number' },
                    { icon: TextIcon, children: t(`item-props.type.string`), value: 'string' },
                    { icon: Range, children: t(`item-props.type.range`), value: 'range' },
                  ]}
                />
              )}
            />
          </FieldGroup>

          {type === 'range' && (
            <div className="flex justify-between gap-2">
              <FieldGroup label={t('item-props.type.range.min')} className="w-1/2">
                <Input
                  {...register('config.min', {
                    required: t('validation.required'),
                    setValueAs: value => {
                      return parseInt(value)
                    },
                  })}
                  {...formatHookFormErrors(errors, 'config.min')}
                  type="number"
                />
              </FieldGroup>

              <FieldGroup label={t('item-props.type.range.max')} className="w-1/2">
                <Input
                  {...register('config.max', {
                    required: t('validation.required'),
                    setValueAs: value => {
                      return parseInt(value)
                    },
                  })}
                  {...formatHookFormErrors(errors, 'config.max')}
                  type="number"
                />
              </FieldGroup>
            </div>
          )}

          <FieldGroup label={t('item-props.image_url')}>
            <Controller
              control={control}
              name="icon_url"
              render={({ field }) => (
                <ImageUploader
                  accept={{
                    'image/jpeg': ['.jpeg', '.jpg'],
                    'image/png': [],
                    'image/webp': [],
                  }}
                  {...field}
                />
              )}
            />
            <ErrorMessage
              name="icon_url"
              errors={errors}
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </FieldGroup>

          {errorMessage && <FormErrorMessage> {errorMessage} </FormErrorMessage>}
        </ModalContent>
        <ModalFooter>
          <Button variant="outline" color="secondary" size="sm" onClick={rest.onClose}>
            {t('Cancel')}
          </Button>
          <Button type="submit" loading={isSubmitting} size="sm">
            {t(item.id ? 'Save2' : 'Add')}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}
