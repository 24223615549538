import { cn } from '@/libs'
import { AghanimIcon, InfoCircle } from '@/icons'
import { useMemo } from 'react'

type Type = 'info'

interface AlertMessageProps {
  type?: Type
  message: string
}

const colorMap: Map<AlertMessageProps['type'], string> = new Map([['info', 'text-text-tertiary']])
const iconMap: Map<AlertMessageProps['type'], AghanimIcon> = new Map([['info', InfoCircle]])

export const AlertMessage = ({ type = 'info', message }: AlertMessageProps) => {
  const { color, Icon } = useMemo(
    () => ({
      color: colorMap.get(type) || 'text-text-tertiary',
      Icon: iconMap.get(type) || InfoCircle,
    }),
    [type],
  )

  return (
    <div className="inline-flex gap-2">
      {type && <Icon className={cn('m-px size-3.5 shrink-0 grow-0 basis-auto', color)} />}
      <span className={cn('text-caption-md font-normal', color)}>{message}</span>
    </div>
  )
}
