import { dashboardClient } from './index'
import { setUserInSentry } from '../libs/sentry'
import { goToLogout } from '../util'

interface Posthog {
  identify: (id: string) => void
}

export async function postLogin(userName?: string, avatarUrl?: string) {
  dashboardClient.v1.postLogin()

  if (userName || avatarUrl) {
    dashboardClient.v1.updateProfile({
      name: userName,
      avatar_url: avatarUrl,
    })
  }

  identifyPosthogUser()
}

function doIdentifyPosthogUser() {
  dashboardClient.v1
    .getMyProfile()
    .then(response => {
      let user = response.data
      if (user) {
        let posthog = (window as any).posthog
        if (posthog) {
          ;(posthog as Posthog).identify(user.id)
        }

        setUserInSentry(user.id, user.name || user.email.split('@')[0], user.email)
      }
    })
    .catch(e => {
      if (e.status == 401) {
        goToLogout()
      }
    })
}

export function identifyPosthogUser() {
  if (window.location.href.startsWith('http://localhost')) {
    setTimeout(doIdentifyPosthogUser, 2000)
  } else doIdentifyPosthogUser()
}
