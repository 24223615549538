import { forwardRef, useCallback } from 'react';
import { Menu, MenuItem } from '../Menu';
import { PopperMenuProps } from '../Popper';
import { SelectFilterItem, SelectFilterProps } from './SelectFilter';

export interface SelectFilterMenuProps extends PopperMenuProps, Pick<SelectFilterProps, 'items' | 'onChange'> {}

export const SelectFilterMenu = forwardRef<HTMLDivElement, SelectFilterMenuProps>(
  ({ items, onChange, onClose, ...rest }, ref) => {
    const onClickMenuItem = useCallback(
      (value: SelectFilterItem['value']) => {
        onChange(value);
        onClose();
      },
      [onChange, onClose],
    );

    return (
      <Menu ref={ref} {...rest}>
        {items.map(({ children, value, ...item }) => (
          <MenuItem {...item} key={`menu-item-${value}`} onClick={() => onClickMenuItem(value)}>
            {children}
          </MenuItem>
        ))}
      </Menu>
    );
  },
);
