import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const ChevronSelectorVertical2: AghanimIcon = forwardRef(function (props, ref) {
  return (
    <IconBase {...props} ref={ref} viewBox="0 0 14 14">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.62919 1.5936L4.30007 4.79015C4.05995 5.11971 4.27996 5.5999 4.67108 5.5999H9.32931C9.72043 5.5999 9.94044 5.11971 9.70032 4.79015L7.37121 1.5936C7.18303 1.33534 6.81736 1.33534 6.62919 1.5936ZM7.37121 12.4062L9.70032 9.20965C9.94044 8.88009 9.72043 8.3999 9.32931 8.3999L4.67108 8.3999C4.27996 8.3999 4.05995 8.88009 4.30007 9.20965L6.62919 12.4062C6.81736 12.6645 7.18303 12.6645 7.37121 12.4062Z"
        fill="currentColor"
      />
    </IconBase>
  );
});
