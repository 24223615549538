import { Children, KeyboardEvent, ReactElement, ReactNode, cloneElement, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import {
  FloatingFocusManager,
  FloatingNode,
  FloatingPortal,
  autoUpdate,
  flip,
  offset,
  safePolygon,
  shift,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useHover,
  useInteractions,
} from '@floating-ui/react'
import { Menu_v2, getFloatingMotionProps } from '@/components/ui'
import { ChevronRight } from '@/icons'

interface MenuSubProps {
  menuClassname?: string
  children: ReactNode
  trigger: ReactElement
}

export const MenuSub = ({ children, trigger, menuClassname }: MenuSubProps) => {
  const nodeId = useFloatingNodeId()
  const [isOpen, setIsOpen] = useState(false)

  const { refs, floatingStyles, context, placement } = useFloating({
    nodeId,
    open: isOpen,
    whileElementsMounted: autoUpdate,
    placement: 'right-start',
    middleware: [
      offset({ alignmentAxis: -11, mainAxis: 3, crossAxis: 3 }),
      shift({ crossAxis: false, padding: 6 }),
      flip({ fallbackPlacements: ['bottom', 'top'] }),
    ],
    onOpenChange: setIsOpen,
  })

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, {
      handleClose: safePolygon({
        requireIntent: false,
        blockPointerEvents: true,
      }),
    }),
    useDismiss(context),
  ])

  const onMouseUp = () => {
    setIsOpen(false)
  }

  const onKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      setIsOpen(false)
    }
  }

  return (
    <>
      {cloneElement(trigger, {
        ...getReferenceProps({ ref: refs.setReference }),
        extra: ChevronRight,
        disabled: !Children.count(children),
        'data-open': isOpen,
      })}

      <FloatingNode id={nodeId}>
        <AnimatePresence mode="wait">
          {isOpen && !!Children.count(children) && (
            <FloatingFocusManager context={context}>
              <FloatingPortal>
                <div
                  {...getFloatingProps({ ref: refs.setFloating })}
                  className="absolute z-[3000]"
                  style={floatingStyles}
                  onMouseUp={onMouseUp}
                  onKeyDown={onKeyDown}
                >
                  <Menu_v2 {...getFloatingMotionProps(placement)} className={menuClassname}>
                    {children}
                  </Menu_v2>
                </div>
              </FloatingPortal>
            </FloatingFocusManager>
          )}
        </AnimatePresence>
      </FloatingNode>
    </>
  )
}
