import { forwardRef, useMemo } from 'react'
import { Menu, MenuItem, MenuItemBaseProps, Popper, PopperMenuProps, cn } from '@dashboard/ui'

type LabelFilterValue = string | null

interface LabelFilterMenuItem extends MenuItemBaseProps {
  value: LabelFilterValue
}

interface LabelFilterMenuProps extends PopperMenuProps {
  items: LabelFilterMenuItem[]
  onChange: (value: LabelFilterValue) => void
}

const LabelFilterMenu = forwardRef<HTMLDivElement, LabelFilterMenuProps>(
  ({ items, onChange, onClose, ...rest }, ref) => {
    const onClickMenuItem = (value: LabelFilterMenuItem['value']) => {
      onChange(value)
      onClose()
    }

    return (
      <Menu ref={ref} {...rest}>
        {items.map(({ value, ...item }) => (
          <MenuItem key={`label-filter-menu-item-${value}`} onClick={() => onClickMenuItem(value)}>
            {item.children}
          </MenuItem>
        ))}
      </Menu>
    )
  },
)

interface LabelFilterProps {
  items: LabelFilterMenuItem[]
  value: string
  onChange: (value: LabelFilterValue) => void
}

export const LabelFilter = ({ items, value, onChange }: LabelFilterProps) => {
  const initialFocus = useMemo(() => {
    return Math.max(
      items.findIndex(item => item.value === value),
      0,
    )
  }, [items, value])

  return (
    <Popper
      popper={props => <LabelFilterMenu {...props} items={items} onChange={onChange} />}
      params={{ placement: 'bottom-start', width: 'auto' }}
      focusManager={{ initialFocus }}
    >
      <button
        className={cn(
          'cursor-pointer border-b border-dashed border-text-secondary transition-colors',
          'hover:border-text-brand-primary hover:text-text-brand-primary',
          'data-[open=true]:border-text-brand-primary data-[open=true]:text-text-brand-primary',
        )}
      >
        {items.find(item => item.value === value)?.children}
      </button>
    </Popper>
  )
}
