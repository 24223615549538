import { HTMLAttributes, memo, useCallback, useEffect, useState } from 'react'
import { EditorState, LexicalEditor } from 'lexical'
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin'
import { InitialConfigType, LexicalComposer } from '@lexical/react/LexicalComposer'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin'
import { ListPlugin } from '@lexical/react/LexicalListPlugin'
import { AutoLinkPlugin } from '@lexical/react/LexicalAutoLinkPlugin'
import { CodeNode } from '@lexical/code'
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin'
import { AutoLinkNode, LinkNode } from '@lexical/link'
import { ListItemNode, ListNode } from '@lexical/list'
import { HorizontalRuleNode } from '@lexical/react/LexicalHorizontalRuleNode'
import { HeadingNode, QuoteNode } from '@lexical/rich-text'
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table'
import { TablePlugin } from '@lexical/react/LexicalTablePlugin'
import { cn } from '@dashboard/ui'
import { ContentPlaceholder } from './components'
import { DraggableBlockPlugin } from './plugins/DraggableBlockPlugin'
import { ComponentPickerMenuPlugin } from './plugins/ComponentPickerPlugin'
import { InlineImageNode } from './plugins/ImagePlugin/ImageNode'
import { FloatingLinkEditorPlugin } from './plugins/FloatingLinkEditorPlugin'
import { FloatingToolbarPlugin } from './plugins/FloatingToolbarPlugin'
import { HtmlValuePlugin } from './plugins/HtmlValuePlugin'
import { InlineImagePlugin } from './plugins/ImagePlugin'
import { ToolbarPlugin } from './plugins/ToolbarPlugin'
import { TableCellMenuPlugin, TableCellResizerPlugin, TableContext } from './plugins/TablePlugin'
import { YouTubeNode, YouTubePlugin } from './plugins/YouTubePlugin'
import { processOutputHtml, urlMatcher } from './libs'
import './lexical.css'

export interface LexicalProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
  layout?: 'full' | 'compact'
  autofocus?: boolean
  readonly?: boolean
  disabled?: boolean
  value: string
  onChange: (value: string) => void
}

const nodesByLayout = new Map([
  [
    'full',
    [
      HeadingNode,
      ListNode,
      ListItemNode,
      LinkNode,
      AutoLinkNode,
      HorizontalRuleNode,
      CodeNode,
      QuoteNode,
      TableNode,
      TableCellNode,
      TableRowNode,
      InlineImageNode,
      YouTubeNode,
    ],
  ],
  [
    'compact',
    [
      HeadingNode,
      ListNode,
      ListItemNode,
      LinkNode,
      AutoLinkNode,
      HorizontalRuleNode,
      CodeNode,
      QuoteNode,
      InlineImageNode,
      YouTubeNode,
    ],
  ],
])

export const lexicalProseClassnames = cn(
  'prose-table:w-full prose-table:!border-collapse',
  '[&_table]:!w-full',
  '[&_td]:!align-top',
  '[&_th]:!align-top',
  '[&_td]:break-words [&_td]:!border [&_td]:!border-border-primary [&_td]:!p-2 [&_td_*]:!m-0 [&_td_img]:!mx-auto',
  '[&_th]:break-words [&_th]:!border [&_th]:!border-border-primary [&_th]:!p-2 [&_th_*]:!m-0 [&_th_img]:!mx-auto',
)

export const Lexical = memo(
  ({
    layout = 'full',
    value,
    onChange,
    autofocus = false,
    readonly = false,
    disabled = false,
    ...rest
  }: LexicalProps) => {
    const [mounted, setMounted] = useState(false)
    const [floatingAnchorElem, setFloatingAnchorElem] = useState<HTMLDivElement | null>(null)
    const [isLinkEditMode, setIsLinkEditMode] = useState<boolean>(false)

    const initialConfig: InitialConfigType = {
      namespace: 'aghanim-lexical',
      editable: !readonly,
      nodes: nodesByLayout.get(layout),
      theme: {
        text: {
          underline: 'underline',
        },
      },
      onError: err => {
        console.error(err)
      },
    }

    useEffect(() => {
      setTimeout(() => {
        setMounted(true)
      }, 50)
    }, [setMounted])

    const onRef = useCallback(
      (_floatingAnchorElem: HTMLDivElement) => {
        if (_floatingAnchorElem !== null) {
          setFloatingAnchorElem(_floatingAnchorElem)
        }
      },
      [setFloatingAnchorElem],
    )

    const onChangeContent = useCallback(
      (_: EditorState, editor: LexicalEditor) => {
        editor.update(() => {
          onChange(processOutputHtml(editor))
        })
      },
      [onChange],
    )

    return (
      <div
        {...rest}
        className={cn(
          'relative w-full shrink-0 overflow-y-auto rounded-md border border-border-primary shadow-xs',
          readonly && 'pointer-events-none',
          disabled && 'pointer-events-none',
          disabled && 'border-border-disabled bg-fg-secondary text-text-disabled',
          layout === 'full' && 'min-h-60',
          layout === 'compact' && 'max-h-[400px] min-h-[180px]',
          rest.className,
        )}
      >
        <LexicalComposer initialConfig={initialConfig}>
          <TableContext>
            {layout === 'full' && floatingAnchorElem && (
              <>
                <DraggableBlockPlugin anchorElem={floatingAnchorElem} />
                <FloatingToolbarPlugin anchorElem={floatingAnchorElem} setIsLinkEditMode={setIsLinkEditMode} />
                <FloatingLinkEditorPlugin
                  anchorElem={floatingAnchorElem}
                  isLinkEditMode={isLinkEditMode}
                  setIsLinkEditMode={setIsLinkEditMode}
                />
              </>
            )}

            <ToolbarPlugin />

            {mounted && (
              <div className="relative">
                <RichTextPlugin
                  contentEditable={
                    <div ref={onRef} className="flex h-full shrink-0">
                      <ContentEditable
                        className={cn(
                          'prose size-full max-w-none py-4 outline-none',
                          layout === 'full' && 'px-8 pb-16',
                          layout === 'compact' && 'px-4',
                          disabled && 'text-text-disabled',
                          lexicalProseClassnames,
                        )}
                        contentEditable={!readonly && !disabled}
                      />
                    </div>
                  }
                  placeholder={!readonly ? <ContentPlaceholder layout={layout} /> : null}
                  ErrorBoundary={err => <div>{err.children}</div>}
                />
              </div>
            )}

            {layout === 'full' && (
              <>
                <TablePlugin hasCellMerge={true} hasCellBackgroundColor={false} />
                <TableCellResizerPlugin />
                <TableCellMenuPlugin />
              </>
            )}

            <HtmlValuePlugin value={value} />
            <OnChangePlugin onChange={onChangeContent} />
            <ComponentPickerMenuPlugin />
            <LinkPlugin />
            <ListPlugin />
            <InlineImagePlugin />
            <AutoLinkPlugin matchers={urlMatcher} />
            <YouTubePlugin />
            <MarkdownShortcutPlugin />
            <HistoryPlugin />
            {autofocus && <AutoFocusPlugin />}
          </TableContext>
        </LexicalComposer>
      </div>
    )
  },
)
