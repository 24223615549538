import {
  Button,
  ConfirmDialog,
  Header,
  Table,
  TableCell,
  TableRow,
  TableRowEditButton,
  ToastSeverity,
  Tooltip,
  useToast,
} from '@/ui'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useState } from 'react'
import { JobFunctionName, SegmentGroupRead, SegmentGroupReadStat, SegmentGroupType } from '@/api/dashboard'
import EditSegmentGroupModal from './widgets/EditSegmentGroupModal'
import { useSegmentGroupsQuery } from './api/useSegmentGroupsQuery'
import { dashboardClient } from '@/api'
import { GAME_SEGMENT_EDIT_GROUP_GRAPH_PATH } from '@/libs'
import { InfoCircle, RefreshIcon, Trash } from '@/icons'
import AnimatedDots from '../../components/animated-dots/AnimatedDots'
import { useCurrentUser } from '@/api/useCurrentUser'
import { CloseOutline, Edit03, ModalPresetConfirm, useModal } from '@dashboard/ui'
import { useTranslation } from 'react-i18next'
import { useJobCreateMutate } from '@/providers/api/useJobCreateMutate'
import { useJobListener } from '@/providers/SocketProvider/useJobListener'

interface Props {
  onClose: () => void
}

export default function SegmentGroupTable(props: Props) {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const { t } = useTranslation()
  const { canEdit, isSuperAdmin } = useCurrentUser()
  const navigate = useNavigate()
  const { data: items = [], isLoading, refetch } = useSegmentGroupsQuery(companyId, gameId)
  const { mutateAsync: createMutateAsync } = useJobCreateMutate(companyId, gameId)
  const { progressJobs, refetch: refetchJobs } = useJobListener([JobFunctionName.CalculateStaticSegmentGroup])
  const [deleteConfirmItem, setDeleteConfirmItem] = useState<SegmentGroupRead | undefined>(undefined)
  const showToast = useToast()

  const onDeleteItemConfirm = async () => {
    if (deleteConfirmItem) {
      await dashboardClient.v1.deleteSegmentGroup(companyId, gameId, deleteConfirmItem.id)
      refetch()
    }
    setDeleteConfirmItem(undefined)
  }

  const onCalcConfirm = async (item: SegmentGroupRead) => {
    await createMutateAsync({
      create: {
        function: {
          name: JobFunctionName.CalculateStaticSegmentGroup,
          args: {
            segment_group_id: item.id,
          },
        },
      },
    })
  }

  const onCancelClick = async (segmentGroupId: string) => {
    const progress = getProgress(segmentGroupId)
    if (!progress) {
      return
    }

    await dashboardClient.v1.cancelJob(companyId, gameId, progress.id)
    await refetchJobs()

    showToast({ message: t('segments.group.calc.cancelled'), severity: ToastSeverity.warning })
  }

  const getProgress = (segmentGroupId: string) => {
    return progressJobs.find(it => it.function_args.segment_group_id == segmentGroupId)
  }

  const getProgressString = (segmentGroupId: string) => {
    const progress = getProgress(segmentGroupId)
    if (!progress) {
      return ''
    }
    return (
      <>
        {Math.floor((100 * progress.progress_done) / (progress.progress_total || 100))}%
        <AnimatedDots />
      </>
    )
  }

  const onEditRulesClick = (it: SegmentGroupRead) => {
    navigate(generatePath(GAME_SEGMENT_EDIT_GROUP_GRAPH_PATH, { companyId, gameId, groupId: it.id }))
  }

  const openEditModal = useModal<{ item: SegmentGroupRead; onClose?: () => void }>(props => (
    <EditSegmentGroupModal {...props} onClose={props.onClose!} />
  ))

  const openConfirmCalc = useModal<{ item: SegmentGroupRead }>(props => (
    <ModalPresetConfirm
      {...props}
      title={{
        children: t('confirm.title'),
        subtitle: t('segment.group.calc.confirm', { group_name: props.item.name }),
      }}
      cancel={{
        label: t('confirm.no'),
      }}
      submit={{
        color: 'danger',
        callback: () => {
          props.onClose()
          onCalcConfirm(props.item)
        },
        label: t('segment.group.calc.confirm.yes'),
      }}
    />
  ))

  const getCalcOrCancelItem = (g: SegmentGroupRead) => {
    if (getProgress(g.id)) {
      return {
        icon: <CloseOutline />,
        children: t('Cancel'),
        value: 'cancel',
      }
    }

    return {
      icon: <RefreshIcon />,
      children: t('segment.group.calc'),
      value: 'calc',
    }
  }

  return (
    <div className="flex flex-col">
      <Header title={t('segments.edit-groups')}>
        <Button onClick={props.onClose}>{t('close')}</Button>
      </Header>
      <div className="flex items-center justify-end px-5 py-4">
        <Button
          onClick={() =>
            openEditModal({
              item: {
                name: '',
                type: SegmentGroupType.Static,
                enabled: true,
                is_template: false,
              } as SegmentGroupRead,
            })
          }
          variant="primary"
        >
          {t('segments.add-group')}
        </Button>
      </div>

      {deleteConfirmItem && (
        <ConfirmDialog
          color={'error'}
          confirmButtonText={t('remove')}
          subMessage={t('confirm.text', { name: deleteConfirmItem.name })}
          onCancel={() => setDeleteConfirmItem(undefined)}
          onConfirm={onDeleteItemConfirm}
        />
      )}

      <div className="px-5">
        <div className="border-t border-border-primary">
          <Table>
            <TableRow variant="header">
              <TableCell width="200%">{t('segments.group.name')}</TableCell>
              <TableCell width="80%">{t('segments.group.type')}</TableCell>
              <TableCell width="60%">{t('segments.group.count')}</TableCell>
              <TableCell width="40%">{t('segments.group.progress')}</TableCell>
              <TableCell width="20%" />
            </TableRow>

            {!isLoading &&
              (items as SegmentGroupReadStat[]).map(it => (
                <TableRow key={it.id}>
                  <TableCell width="200%">
                    <div className="flex items-center gap-1">
                      {it.name}
                      {isSuperAdmin && it.is_template && (
                        <Tooltip message={t('segments.template')}>
                          <InfoCircle />
                        </Tooltip>
                      )}
                    </div>
                  </TableCell>
                  <TableCell width="80%">
                    <div className="flex items-center gap-1">
                      {t(`segments.group.type.${it.type}`)}
                      {it.type == SegmentGroupType.Dynamic && (
                        <Tooltip message={t(it.enabled ? 'segments.published' : 'segments.not-published')}>
                          <InfoCircle
                            className={it.enabled ? 'text-text-success-primary' : 'text-text-warning-solid'}
                          />
                        </Tooltip>
                      )}
                    </div>
                  </TableCell>
                  <TableCell width="60%"> {it.segment_count}</TableCell>
                  <TableCell width="40%">{getProgressString(it.id)}</TableCell>
                  <TableCell width="20%">
                    {canEdit && (
                      <TableRowEditButton
                        onChange={v => {
                          switch (v) {
                            case 'edit':
                              openEditModal({ item: it })
                              break
                            case 'remove':
                              setDeleteConfirmItem(it)
                              break
                            case 'calc':
                              openConfirmCalc({ item: it })
                              break
                            case 'cancel':
                              onCancelClick(it.id)
                              break
                            case 'edit-rules':
                              onEditRulesClick(it)
                              break
                          }
                        }}
                        options={[
                          canEdit && {
                            icon: <Edit03 />,
                            children: t('segments.group.edit'),
                            value: 'edit',
                          },
                          canEdit && {
                            icon: <Edit03 />,
                            children: t('segment.group.edit-rules'),
                            value: 'edit-rules',
                          },
                          it.type == SegmentGroupType.Static &&
                            canEdit &&
                            it.segment_count > 0 &&
                            getCalcOrCancelItem(it),
                          canEdit && {
                            icon: (
                              <span className="text-text-error-primary">
                                <Trash />
                              </span>
                            ),
                            children: <span className="text-text-error-primary"> {t('grid.remove')} </span>,
                            value: 'remove',
                          },
                        ]}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </Table>
        </div>
      </div>
    </div>
  )
}
