import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '../IconBase'

export const ChevronSelectorVertical: AghanimIcon = forwardRef(function (props, ref) {
  return (
    <IconBase {...props} ref={ref}>
      <path
        d="M4.66699 9.99984L8.00033 13.3332L11.3337 9.99984M4.66699 5.99984L8.00033 2.6665L11.3337 5.99984"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconBase>
  )
})
