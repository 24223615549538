import { FieldGroup, Select } from '@/ui'
import i18next from 'i18next'
import { EMPTY_VALUE } from '../../expr/types'
import {
  BrowserPopupNotificationActionNode,
  BrowserPushNotificationActionNode,
  EmailActionNode,
  MobilePopupActionNode,
  MobilePushActionNode,
  Node,
  ResourceState,
} from '../../../../api/dashboard'
import { useParams } from 'react-router-dom'
import { DEFAULT_LOAD_LIMIT, useGameItemsQuery } from '../../../../api/useGameItems'

interface SelectSkuProps {
  node:
    | EmailActionNode
    | BrowserPopupNotificationActionNode
    | BrowserPushNotificationActionNode
    | MobilePushActionNode
    | MobilePopupActionNode
  setNode: (node: Node) => void
  showLinkCreatedOffer?: boolean
}

export default function SelectMessageContentType(props: SelectSkuProps) {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const { data: items = [] } = useGameItemsQuery(companyId, gameId, {
    limit: DEFAULT_LOAD_LIMIT,
    state: ResourceState.Active,
  })

  let typeItem = EMPTY_VALUE
  if (props.node.item_id) {
    typeItem = 'item'
  } else if (props.node.link_created_offer && props.showLinkCreatedOffer) {
    typeItem = 'offer'
  }

  return (
    <FieldGroup label={i18next.t('campaign.block.Communication.attach-item')}>
      <Select
        value={typeItem}
        onChange={value => {
          switch (value) {
            case EMPTY_VALUE:
              props.setNode({
                ...props.node,
                item_id: undefined,
                link_created_offer: false,
              } as unknown as Node)
              break
            case 'item':
              props.setNode({
                ...props.node,
                item_id: items.length > 0 ? items[0].id : '',
                link_created_offer: false,
              } as unknown as Node)
              break
            case 'offer':
              props.setNode({
                ...props.node,
                item_id: undefined,
                link_created_offer: true,
              } as unknown as Node)
              break
          }
        }}
        options={[
          { value: EMPTY_VALUE, children: i18next.t('campaign.block.Communication.none') },
          { value: 'item', children: i18next.t('campaign.block.Communication.item') },
          props.showLinkCreatedOffer !== false
            ? {
                value: 'offer',
                children: i18next.t('campaign.block.Communication.link_created_offer'),
              }
            : undefined,
        ]}
      />
    </FieldGroup>
  )
}
