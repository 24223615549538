import { MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { FORMAT_ELEMENT_COMMAND, LexicalEditor } from 'lexical'
import { MenuItem, Menu_v2 } from '@/ui'
import { AlignCenter, AlignLeft, AlignRight } from '@/icons'

interface ToolbarAlignMenuProps {
  editor: LexicalEditor
  onClose?: () => void
}

export const ToolbarAlignMenu = ({ editor, onClose = () => void 0 }: ToolbarAlignMenuProps) => {
  const { t } = useTranslation()

  const onClickOption = (e: MouseEvent<HTMLLIElement>, direction: 'left' | 'center' | 'right') => {
    e.preventDefault()
    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, direction)
    onClose()
  }

  return (
    <Menu_v2>
      <MenuItem icon={AlignLeft} onClick={e => onClickOption(e, 'left')}>
        {t('lexical.align-menu.left')}
      </MenuItem>
      <MenuItem icon={AlignCenter} onClick={e => onClickOption(e, 'center')}>
        {t('lexical.align-menu.center')}
      </MenuItem>
      <MenuItem icon={AlignRight} onClick={e => onClickOption(e, 'right')}>
        {t('lexical.align-menu.right')}
      </MenuItem>
    </Menu_v2>
  )
}
