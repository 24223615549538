import { forwardRef, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Menu, MenuItem, PopperMenuProps } from '@dashboard/ui'
import { SelectIconProps } from './SelectIcon'

export interface SelectIconMenuProps
  extends PopperMenuProps,
    Pick<SelectIconProps, 'allowEmpty' | 'items' | 'onChange'> {}

export const SelectIconMenu = forwardRef<HTMLDivElement, SelectIconMenuProps>(
  ({ allowEmpty, items, onChange, onClose, ...rest }, ref) => {
    const { t } = useTranslation()

    const onClickMenuItem = useCallback(
      (value: SelectIconProps['value']) => {
        onChange(value)
        onClose()
      },
      [onChange, onClose],
    )

    const onClickClear = useCallback(() => {
      onChange('')
      onClose()
    }, [onChange, onClose])

    return (
      <Menu
        ref={ref}
        {...rest}
        footer={
          allowEmpty && (
            <Button className="w-full" variant="outline" color="secondary" size="sm" onClick={onClickClear}>
              {t('Reset')}
            </Button>
          )
        }
      >
        <div className="grid grid-cols-4 gap-1">
          {items.map(({ children, value }) => (
            <div key={`menu-item-${value}`}>
              <MenuItem onClick={() => onClickMenuItem(value)}>
                <div className="flex w-full items-center justify-center">{children}</div>
              </MenuItem>
            </div>
          ))}
        </div>
      </Menu>
    )
  },
)
