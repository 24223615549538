import { Link, generatePath, useParams } from 'react-router-dom'
import i18next from 'i18next'
import {
  ModalConfirm,
  Table,
  TableCell,
  TableRow,
  TableRowEditButton,
  TableRowSkeleton,
  TableZeroState,
  useModal,
} from '@/ui'

import { UserStoreSettingsRead } from '@/api/dashboard'
import { dashboardClient } from '@/api'
import { Trash } from '@/icons'
import { STORE_ITEMS_PATH, cn } from '@/libs'
import { DateTimeValue } from '@/components/ui/DateTimeValue'
import { usePlayerStoreSettings } from '@/layouts/player/api/usePlayerStoreSettings'
import { useCurrentUser } from '@/api/useCurrentUser'

export default function UserStoreSettingsTable(props: { userId: string; className?: string }) {
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const { data: items = [], isLoading, refetch } = usePlayerStoreSettings(companyId, gameId, props.userId)
  const { canEdit } = useCurrentUser()

  const openDeleteModal = useModal<{ item: UserStoreSettingsRead }>(props => (
    <ModalConfirm
      subMessage={i18next.t('confirm.text', { name: props.item.store_name })}
      confirmButtonText={i18next.t('remove')}
      color="danger"
      onConfirm={() => onDeleteGameItemClick(props.item)}
      {...props}
    />
  ))

  const onDeleteGameItemClick = async (item: UserStoreSettingsRead) => {
    await dashboardClient.v1.deleteUserStoreSettings(item.id, companyId, gameId)
    refetch()
  }

  const renderDefaultHeader = () => {
    return (
      <TableRow variant="header">
        <TableCell width="20%">{i18next.t('player.store-settings.store')}</TableCell>
        <TableCell width="25%">{i18next.t('player.store-settings.created_at')}</TableCell>
        <TableCell width="25%">{i18next.t('player.store-settings.expires_at')}</TableCell>
        <TableCell width="40%">{i18next.t('player.store-settings.benefits')}</TableCell>
        <TableCell width="40%">{i18next.t('player.store-settings.limit')}</TableCell>
        {canEdit && <TableCell width="12%" />}
      </TableRow>
    )
  }

  const renderBenefits = (it: UserStoreSettingsRead) => {
    return (
      <>
        {it.discount_percent && <div>{i18next.t('store.item.discount1') + ' ' + it.discount_percent + '%'}</div>}
        {it.bonus_percent && <div>{i18next.t('store.item.bonus1') + ' ' + it.bonus_percent + '%'}</div>}
        {it.reward_points_percent && (
          <div>{i18next.t('player.store-settings.reward_points_percent') + ' ' + it.reward_points_percent + '%'}</div>
        )}
      </>
    )
  }

  const renderLimits = (it: UserStoreSettingsRead) => {
    return (
      <>
        {it.max_purchase_count && (
          <div>{i18next.t('player.store-settings.max_purchase_count') + ': ' + it.max_purchase_count}</div>
        )}
        <div>{i18next.t('player.store-settings.current_purchase_count') + ': ' + it.current_purchase_count}</div>
      </>
    )
  }

  if (isLoading) {
    return <TableRowSkeleton rowCount={3} columnCount={4} />
  }

  if (!items.length) {
    return (
      <div className="h-[147px] flex-1">
        <TableZeroState
          title={i18next.t('player.store-settings.zero.title')}
          message={i18next.t('player.store-settings.zero.message')}
        />
      </div>
    )
  }

  return (
    <div className={cn('w-full', props.className)}>
      <Table>
        {renderDefaultHeader()}
        {items.map(it => (
          <TableRow key={it.id}>
            <TableCell width="20%" className="flex items-center gap-3">
              <Link to={generatePath(STORE_ITEMS_PATH, { storeId: it.store_id, companyId, gameId })}>
                {it.store_name}
              </Link>
            </TableCell>
            <TableCell width="25%">
              <DateTimeValue value={it.created_at} />
            </TableCell>
            <TableCell width="25%">
              <DateTimeValue value={it.end_at} />
            </TableCell>
            <TableCell width="40%">{renderBenefits(it)}</TableCell>
            <TableCell width="40%">{renderLimits(it)}</TableCell>
            {canEdit && (
              <TableCell width="12%">
                <div className="text-right">
                  <TableRowEditButton
                    onChange={v => {
                      switch (v) {
                        case 'remove':
                          openDeleteModal({ item: it })
                          break
                      }
                    }}
                    options={[
                      {
                        icon: <Trash className="text-text-error-primary" />,
                        children: <span className="text-text-error-primary"> {i18next.t('grid.remove')} </span>,
                        value: 'remove',
                      },
                    ]}
                  />
                </div>
              </TableCell>
            )}
          </TableRow>
        ))}
      </Table>
    </div>
  )
}
