import { ReactNode } from 'react'
import { cn } from '@/libs'

type Props = {
  title: string | ReactNode
  children: ReactNode
  titleAction?: ReactNode
  className?: string
  minWidth?: string
}

export const Header = ({
  title,
  children,
  titleAction = undefined,
  className = undefined,
  minWidth = '800px',
}: Props) => {
  return (
    <div
      style={{ minWidth: minWidth, minHeight: '56px' }}
      className={cn(
        'flex w-full items-center  justify-center border-b border-b-border-secondary bg-fg-primary px-6',
        className,
      )}
    >
      {titleAction}
      <span className="font-semibold text-text-primary" style={{ fontSize: '20px' }}>
        {title}
      </span>
      <div className="ml-auto">{children}</div>
    </div>
  )
}
