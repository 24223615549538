import { ButtonHTMLAttributes, ReactNode, forwardRef } from 'react'
import { ChevronDown } from '@/icons'
import { cn } from '@/libs'

interface ToolbarButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode
  hasMenu?: boolean
}

export const ToolbarButton = forwardRef<HTMLButtonElement, ToolbarButtonProps>(function (
  { children, hasMenu = false, ...rest },
  ref,
) {
  return (
    <button
      {...rest}
      ref={ref}
      className={cn(
        'group flex h-6 cursor-pointer items-center gap-0.5 rounded-md px-1 text-caption-sm font-semibold text-text-tertiary transition-all',
        'hover:bg-fg-secondary-hover hover:text-text-tertiary-hover',
        'active:bg-fg-secondary-alt active:text-text-secondary',
        "[&[data-open='true']]:bg-fg-secondary-hover [&[data-open='true']]:text-text-tertiary-hover",
        "[&[data-selected='true']]:bg-fg-secondary-alt [&[data-selected='true']]:text-text-secondary",
        'disabled:pointer-events-none disabled:opacity-30',
      )}
      type="button"
    >
      {children}
      {hasMenu && (
        <ChevronDown
          className="text-text-quarterary transition-all group-hover:text-inherit group-active:text-inherit group-[&[data-open='true']]:rotate-180 group-[&[data-open='true']]:text-inherit"
          size={16}
        />
      )}
    </button>
  )
})
