import { useMemo } from 'react'
import { ChildItem, MenuGroup, MenuItem, MenuSub, ParentItem, SelectTreeValue } from '@/ui'
import { groupBy } from '@/libs'

interface SelectTreeParentItemProps {
  parent: ParentItem
  onChange: (value: SelectTreeValue) => void
}

export const SelectTreeParentItem = ({ parent: { tree, ...parent }, onChange }: SelectTreeParentItemProps) => {
  const groupsEntries = useMemo(() => Object.entries(groupBy(tree.child, item => item.tree.group || '')), [tree.child])

  const renderItem = ({ tree: treeChild, ...item }: ChildItem) => {
    return (
      <MenuItem {...item} key={`menu-item-${tree.value}-${treeChild.value}`} onClick={() => onChange(treeChild.value)}>
        {item.children}
      </MenuItem>
    )
  }

  return (
    <MenuSub menuClassname="w-[320px]" trigger={<MenuItem {...parent}>{parent.children}</MenuItem>}>
      {groupsEntries.map(([groupLabel, items]) =>
        groupLabel ? (
          <MenuGroup key={`menu-group-${groupLabel}`} label={groupLabel}>
            {items.map(item => renderItem(item))}
          </MenuGroup>
        ) : (
          <>{items.map(item => renderItem(item))}</>
        ),
      )}
    </MenuSub>
  )
}
