import { useParams } from 'react-router-dom'
import i18next from 'i18next'
import {
  ModalConfirm,
  Table,
  TableCell,
  TableRow,
  TableRowEditButton,
  TableRowSkeleton,
  TableZeroState,
  useModal,
} from '@/ui'

import { StoreItemRead } from '@/api/dashboard'
import { useContext } from 'react'
import { dashboardClient } from '@/api'
import { Trash } from '@/icons'
import { canEdit } from '@/security'
import { SkuImageName } from '../../game-items/components/SkuImageName'
import { cn } from '@/libs'
import { DateTimeValue } from '@/components/ui/DateTimeValue'
import { AuthContext, IAuthContext } from '@/Context'
import { usePlayerVirtualSkuItems } from '@/layouts/player/api/usePlayerVirtualSkuItems'

export default function CampaignVirtualSKUTable(props: { userId: string; className?: string }) {
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const { data: virtualStoreItems = [], isLoading, refetch } = usePlayerVirtualSkuItems(companyId, gameId, props.userId)

  const context = useContext(AuthContext) as IAuthContext
  const canUserEdit = canEdit(context.customer)

  const openDeleteModal = useModal<{ item: StoreItemRead }>(props => (
    <ModalConfirm
      subMessage={i18next.t('confirm.text', { name: props.item.item.name })}
      confirmButtonText={i18next.t('remove')}
      color="danger"
      onConfirm={() => onDeleteGameItemClick(props.item)}
      {...props}
    />
  ))

  const onDeleteGameItemClick = async (item: StoreItemRead) => {
    await dashboardClient.v1.deleteVirtualStoreItem(item.id, companyId, gameId)
    refetch()
  }

  const renderDefaultHeader = () => {
    return (
      <TableRow variant="header">
        <TableCell width="50%">{i18next.t('campaign.offers.item')}</TableCell>
        <TableCell width="40%">{i18next.t('campaign.offers.expires_at')}</TableCell>
        <TableCell width="25%">{i18next.t('campaign.offers.benefits')}</TableCell>
        <TableCell width="25%">{i18next.t('campaign.offers.created_at')}</TableCell>
        {canUserEdit && <TableCell width="12%" />}
      </TableRow>
    )
  }

  if (isLoading) {
    return <TableRowSkeleton rowCount={3} columnCount={4} />
  }

  if (!virtualStoreItems.length) {
    return (
      <div className="h-[147px] flex-1">
        <TableZeroState
          title={i18next.t('player.virtualsku.zero.title')}
          message={i18next.t('player.virtualsku.zero.message')}
        />
      </div>
    )
  }

  const getBenefits = (storeItem: StoreItemRead) => {
    if (storeItem.is_free_item) {
      return i18next.t('store.item.free_item')
    }

    let res = []

    if (storeItem.discount_percent) {
      res.push(<div> {i18next.t('campaign.offers.discount', { value: storeItem.discount_percent })}</div>)
    }

    if (storeItem.bonus_percent) {
      res.push(<div> {i18next.t('campaign.offers.bonus', { value: storeItem.bonus_percent })}</div>)
    }

    return res
  }

  return (
    <div className={cn('w-full', props.className)}>
      <Table>
        {renderDefaultHeader()}
        {virtualStoreItems.map((it, idx) => (
          <TableRow key={idx}>
            <TableCell className="flex items-center gap-3 truncate" width="50%">
              <SkuImageName item={it.item} />
            </TableCell>
            <TableCell width="40%">
              {it.end_at ? <DateTimeValue value={it.end_at} /> : i18next.t('campaign.offers.expires_at.no')}
            </TableCell>
            <TableCell width="25%">{getBenefits(it)}</TableCell>
            <TableCell width="25%">
              <DateTimeValue value={it.created_at} />
            </TableCell>
            <TableCell width="12%">
              <div className="text-right">
                {canUserEdit && (
                  <TableRowEditButton
                    onChange={v => {
                      switch (v) {
                        case 'remove':
                          openDeleteModal({ item: it })
                          break
                      }
                    }}
                    options={[
                      {
                        icon: <Trash className="text-text-error-primary" />,
                        children: <span className="text-text-error-primary"> {i18next.t('grid.remove')} </span>,
                        value: 'remove',
                      },
                    ]}
                  />
                )}
              </div>
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </div>
  )
}
