import { useState } from 'react'

export const usePaginationMemory = (take = 10) => {
  const [page, setPage] = useState(1)

  const query = { offset: (page - 1) * take, limit: take }

  const needShowPagination = (isLoading: boolean, items: Array<unknown>) => {
    if (isLoading) {
      return false
    }
    if (items.length === 0 && page == 1) {
      return false
    }

    if (items.length < take && page == 1) {
      return false
    }
    return true
  }

  const onPageChange = (page: number) => {
    setPage(page)
  }

  return { page, query, onPageChange, needShowPagination }
}
