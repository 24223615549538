import { useCallback, useEffect } from 'react'
import { useSocket } from './useSocket'
import { JobContext, WSEventType } from '@/types'

/**
 * Listen to messages from the socket.
 * @param callback
 */
export const useSocketListener = <T>(callback: (message: T) => void) => {
  const socket = useSocket()

  const onMessageCallback = useCallback(
    (event: MessageEvent) => {
      callback(JSON.parse(event.data))
    },
    [callback],
  )

  useEffect(() => {
    socket?.addEventListener('message', onMessageCallback)
    return () => {
      socket?.removeEventListener('message', onMessageCallback)
    }
  }, [socket, onMessageCallback, callback])
}

export interface JobProgressMessage {
  type: WSEventType.job_progress
  payload: JobContext
}
