import { useCallback, useEffect, useMemo, useState } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { Button, Edit03, ModalPresetConfirm, cn, useModal } from '@dashboard/ui'
import {
  Badge,
  PageHeader,
  Pagination,
  Table,
  TableCell,
  TableCellBulkCheckbox,
  TableCellBulkToolbar,
  TableRow,
  TableRowEditButton,
  TableRowSkeleton,
  TableZeroState,
  Tooltip,
  TooltipAlertCircle,
  usePagination,
} from '@/ui'
import { Plus, Trash } from '@/icons'
import { useStoreDeleteMutate, useStoresQuery } from './api'
import EditStoreDialog from './EditStoreDialog'
import { StoreGraph, StoreRead, StoreReadStat, StoreType } from '@/api/dashboard'
import { dashboardClient } from '@/api'
import { STORE_ITEMS_PATH, STORE_RULES_PATH, useBulkSelect, useFeatureFlags } from '@/libs'
import { formatNumber } from '@/util'
import { useTranslation } from 'react-i18next'
import { useCurrentUser } from '@/api/useCurrentUser'
import { ImportSpreadSheetModal } from '@/layouts/store/widgets/ImportSpreadSheetModal'

export default function StorePage() {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { canEdit } = useCurrentUser()
  const { featureFlags } = useFeatureFlags()

  const { page, query, onPageChange, needShowPagination } = usePagination(10)
  const { data: items = [], isLoading, refetch } = useStoresQuery(companyId, gameId)
  const { mutateAsync: deleteMutateAsync } = useStoreDeleteMutate(companyId, gameId)
  const [storeGraph, setStoreGraph] = useState<StoreGraph>({
    enabled: false,
  } as StoreGraph)
  const { selected, onSelect, onReset } = useBulkSelect()

  const loadStoreGraph = async () => {
    let { data: base } = await dashboardClient.v1.getBaseStoreGraph(companyId, gameId)
    if (base.graph) {
      setStoreGraph(base.graph)
    }
  }

  useEffect(() => {
    loadStoreGraph()
  }, [gameId])

  const openBulkDeleteModal = useModal(props => (
    <ModalPresetConfirm
      {...props}
      title={{
        subtitle: t('store.remove-n-items', { N: selected.size }),
        children: t('confirm.title'),
      }}
      cancel={{
        label: t('Cancel'),
      }}
      submit={{
        color: 'danger',
        callback: () => {
          props.onClose()
          onConfirmDeleteItems()
        },
        label: t('remove'),
      }}
    />
  ))

  const openDeleteModal = useModal<{ item: StoreReadStat }>(props => (
    <ModalPresetConfirm
      {...props}
      title={{
        children: t('confirm.title'),
        subtitle: t('confirm.text', { name: props.item.name }),
      }}
      cancel={{
        label: t('Cancel'),
      }}
      submit={{
        color: 'danger',
        callback: () => {
          props.onClose()
          onDeleteGameItemClick(props.item)
        },
        label: t('remove'),
      }}
    />
  ))

  const openImportModal = useModal(props => <ImportSpreadSheetModal {...props} />)

  const openEditModal = useModal<{ item: StoreRead }>(props => <EditStoreDialog {...props} />)

  const onDeleteGameItemClick = async (deleteConfirmItem: StoreRead) => {
    if (deleteConfirmItem) {
      await deleteMutateAsync({ id: deleteConfirmItem.id })
      onReset([])
    }
  }

  const onClickBulkDelete = useCallback(() => {
    openBulkDeleteModal({ subMessage: t('store.remove-n-items', { N: selected.size }) })
  }, [items, selected, openBulkDeleteModal])

  const onConfirmDeleteItems = async () => {
    await dashboardClient.v1.bulkDeleteStores(
      companyId,
      gameId,
      Array.from(selected).map(id => ({ id })),
    )

    onReset([])

    refetch()
  }

  const hasStockLimited = useMemo(() => {
    return !!items.find(it => it.limited_stock_settings && it.type == StoreType.LimitedStock)
  }, [items])

  const renderDefaultHeader = () => {
    return (
      <TableRow variant="header">
        {items.length > 0 && canEdit && (
          <TableCellBulkCheckbox
            selected={selected.size > 0 && selected.size == items.length}
            disabled={items.length === 0}
            onChange={() => onReset(selected.size == items.length ? [] : items.map(i => i.id))}
          />
        )}
        <TableCell>{t('store.store')}</TableCell>
        <TableCell width="25%">{t('store.item_count')}</TableCell>
        <TableCell width="30%">{t('store.type')}</TableCell>
        {hasStockLimited && <TableCell width="40%">{t('store.stats')}</TableCell>}
        <TableCell width="20%" />
      </TableRow>
    )
  }

  const renderBulkSelectionHeader = () => (
    <TableRow variant="header">
      <TableCellBulkCheckbox
        selected={selected.size > 0 && selected.size == items.length}
        disabled={items.length === 0}
        onChange={() => onReset(selected.size == items.length ? [] : items.map(i => i.id))}
      />
      <TableCellBulkToolbar selected={selected} onReset={() => onReset([])}>
        <Button variant="tertiary" color="danger" size="sm" onClick={onClickBulkDelete}>
          <Trash size={16} />
          <span>{t('remove')}</span>
        </Button>
      </TableCellBulkToolbar>
    </TableRow>
  )

  const renderImportButton = () => {
    if (!featureFlags.import_store_enabled) {
      return null
    }

    return (
      <Button variant="outline" color="secondary" size="sm" onClick={() => openImportModal()}>
        {t('import.import')}
      </Button>
    )
  }

  const renderEditRulesButton = () => {
    return (
      <Button
        variant="outline"
        color="secondary"
        size="sm"
        onClick={() => navigate(generatePath(STORE_RULES_PATH, { companyId, gameId }))}
      >
        <Tooltip message={t(storeGraph.enabled ? 'store.status.published' : 'store.status.unpublished')}>
          <div
            className={cn('mr-1 size-2 rounded-full', storeGraph.enabled ? 'bg-fg-lime-primary' : 'bg-fg-gray-primary')}
          />
        </Tooltip>
        {t('store.edit-store-rules')}
      </Button>
    )
  }

  const renderStats = (store: StoreReadStat) => {
    if (!store.limited_stock_settings || store.type != StoreType.LimitedStock) {
      return <></>
    }

    return (
      <div>
        <div>
          {t('store.stock_emulate')}: {formatNumber(store.emulate_remaining_quantity!)}
        </div>
        <div>
          {t('store.stock_real')}: {formatNumber(store.remaining_quantity!)}
        </div>
      </div>
    )
  }

  return (
    <div className="flex h-full flex-col">
      <PageHeader
        extra={
          canEdit && (
            <>
              {renderImportButton()}
              {renderEditRulesButton()}
              {!!items.length && (
                <Button
                  size="sm"
                  onClick={() =>
                    openEditModal({
                      item: {
                        name: '',
                        description: '',
                      } as StoreRead,
                    })
                  }
                >
                  {t('store.create-store')}
                </Button>
              )}
            </>
          )
        }
      >
        {t('sidebar.store')}
      </PageHeader>

      {isLoading ? (
        <TableRowSkeleton rowCount={query.limit} columnCount={3} />
      ) : items.length === 0 && page == 1 ? (
        <TableZeroState
          title={t(canEdit ? 'store.empty-table.title' : 'store.empty-table.title.read-only')}
          message={t(canEdit ? 'store.empty-table' : 'store.empty-table.read-only')}
          buttons={
            canEdit && (
              <Button
                size="sm"
                onClick={() =>
                  openEditModal({
                    item: { name: '', description: '', type: StoreType.Special } as StoreRead,
                  })
                }
              >
                <Plus size={16} />
                <span>{t('store.zero-state.new-button')}</span>
              </Button>
            )
          }
        />
      ) : (
        <>
          <Table>
            {selected.size == 0 ? renderDefaultHeader() : renderBulkSelectionHeader()}
            {items.map(it => (
              <TableRow key={it.id} to={generatePath(STORE_ITEMS_PATH, { storeId: it.id, companyId, gameId })}>
                {canEdit && <TableCellBulkCheckbox selected={selected.has(it.id)} onChange={() => onSelect(it.id)} />}
                <TableCell>
                  <div>
                    <div className="font-medium text-text-secondary">{it.name}</div>
                    <div className="truncate text-text-tertiary">{it.description}</div>
                  </div>
                </TableCell>
                <TableCell width="25%">{it.item_count}</TableCell>
                <TableCell width="30%">
                  {it.type == StoreType.Default ? (
                    <Badge variant="gray-secondary">
                      {t('store.type.default')}
                      <TooltipAlertCircle message={t('store.type.default.tooltip')} />
                    </Badge>
                  ) : (
                    <div className="ml-2 flex items-center gap-1 whitespace-nowrap">
                      {t(`store.type.${it.type}`)}
                      <div className="min-w-4">
                        <TooltipAlertCircle message={t(`store.type.${it.type}.desc`)} />
                      </div>
                    </div>
                  )}
                </TableCell>
                {hasStockLimited && <TableCell width="40%">{renderStats(it)}</TableCell>}
                <TableCell width="20%">
                  <div className="text-right">
                    {canEdit && (
                      <TableRowEditButton
                        onChange={v => {
                          switch (v) {
                            case 'edit':
                              openEditModal({ item: it })
                              break
                            case 'remove':
                              openDeleteModal({
                                item: it,
                              })
                              break
                          }
                        }}
                        options={[
                          {
                            icon: <Edit03 />,
                            children: t('grid.edit'),
                            value: 'edit',
                          },
                          {
                            icon: <Trash className="text-text-error-primary" />,
                            children: <span className="text-text-error-primary"> {t('grid.remove')} </span>,
                            value: 'remove',
                          },
                        ]}
                      />
                    )}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </Table>
          {needShowPagination(isLoading, items) && (
            <Pagination
              hasNext={items.length >= query.limit}
              page={page}
              onPageChange={onPageChange}
              pageItems={items.length}
            />
          )}
        </>
      )}
    </div>
  )
}
