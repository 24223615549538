import { useLayoutEffect } from 'react';

/**
 * Hook that blocks body scroll when effect is active.
 */
export const useBodyScrollBlockEffect = (disabled?: boolean) => {
  useLayoutEffect(() => {
    if (disabled) {
      document.body.setAttribute('data-scroll-block', 'false');
      document.body.style.paddingRight = '0px';
      document.body.style.overflowY = 'auto';
      return;
    }

    if (document.body.getAttribute('data-scroll-block') !== 'true') {
      document.body.setAttribute('data-scroll-block', 'true');
      document.body.style.paddingRight = `${window.innerWidth - document.body.offsetWidth}px`;
      document.body.style.overflowY = 'hidden';
    }

    return () => {
      document.body.setAttribute('data-scroll-block', 'false');
      document.body.style.paddingRight = '0px';
      document.body.style.overflowY = 'auto';
    };
  }, [disabled]);
};
