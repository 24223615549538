import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const ChevronUp: AghanimIcon = forwardRef(function (props, ref) {
  return (
    <IconBase strokeWidth="2" {...props} viewBox="0 0 24 24" ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.7592 15.6508C19.3998 16.0701 18.7685 16.1187 18.3492 15.7592L12 10.3171L5.65077 15.7592C5.23145 16.1187 4.60015 16.0701 4.24073 15.6508C3.8813 15.2314 3.92987 14.6001 4.34919 14.2407L11.3492 8.24072C11.7237 7.91973 12.2763 7.91973 12.6508 8.24072L19.6508 14.2407C20.0701 14.6001 20.1187 15.2314 19.7592 15.6508Z"
        fill="currentColor"
      />
    </IconBase>
  );
});
