import { ReactNode } from 'react'

interface ElementsDeckProps {
  elements: ReactNode[]
  extra?: number
}

export const ElementsDeck = ({ elements, extra = 0 }: ElementsDeckProps) => {
  return (
    <div className="inline-flex items-center gap-1.5">
      <div className="inline-flex">
        {elements.map((element, idx) => (
          <div key={idx} className="[&:not(:last-child)]:-mr-1">
            {element}
          </div>
        ))}
      </div>
      {extra > 0 && (
        <div className="rounded-md bg-fg-primary-alt px-[5px]">
          <span className="cursor-default text-xs text-text-quarterary-hover">+{extra}</span>
        </div>
      )}
    </div>
  )
}
