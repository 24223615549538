import styled from '@emotion/styled'
import i18next from 'i18next'
import AnimatedDots from '../../../../components/animated-dots/AnimatedDots'
import { MeasureValue } from '../../../../api/dashboard'
import { formatMoney, formatNumber } from '../../../../util'
import { ArrowsUpIcon } from '../../icons/ArrowsUpIcon'
import { ArrowsDownIcon } from '../../icons/ArrowsDownIcon'
import { TooltipAlertCircle } from '@/ui'
import { cn } from '@/libs'

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 240px;
  white-space: nowrap;
  overflow: hidden;
`

const Title = styled.div`
  display: inline-flex;
  gap: 6px;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: ${p => p.theme.palette.text.input};
`

const Value = styled.div`
  color: ${p => p.theme.palette.text.secondary};
  font-size: 24px;
  font-weight: 600;
`

const PrevContainer = styled.span`
  margin-top: 4px;
  display: flex;
  align-items: center;
`

const PrevValue = styled.span`
  font-size: 14px;
  font-weight: 500;
`

const PrevValueLabel = styled.span`
  color: ${p => p.theme.palette.text.tertiary};
  font-size: 14px;
  font-weight: 500;
  margin-left: 6px;
`

export enum SingleValueType {
  value,
  money,
  percent,
}

export default function SingleValue(props: {
  value: MeasureValue | undefined
  label: string
  width?: string
  tooltip?: string
  type?: SingleValueType
}) {
  let percentChange = 0
  if (props.value && props.value.prev_value) {
    if (props.value.prev_value) {
      percentChange = (100 * (props.value.value - props.value.prev_value)) / props.value.prev_value
    } else {
      percentChange = 100
    }
  }

  let valueStr: JSX.Element | string = <AnimatedDots />
  if (props.value) {
    switch (props.type) {
      case SingleValueType.money:
        valueStr = formatMoney(props.value.value) as string
        break
      case SingleValueType.percent:
        valueStr = props.value.value.toFixed(2) + '%'
        break
      case SingleValueType.value:
      default:
        valueStr = formatNumber(props.value.value)
        break
    }
  }

  return (
    <Root>
      {props.label && (
        <Title>
          {props.label}
          {props.tooltip && <TooltipAlertCircle message={props.tooltip} />}
        </Title>
      )}
      <div style={{ height: props.value?.prev_value ? '50px' : '' }}>
        <Value className="ph-no-capture">{valueStr}</Value>
        {props.value?.prev_value != undefined && (
          <PrevContainer>
            <div className="flex w-[17px] items-center">
              {percentChange >= 0 ? (
                <ArrowsUpIcon className="text-text-success-secondary" size={10} />
              ) : (
                <ArrowsDownIcon className="text-text-error-primary" size={10} />
              )}
            </div>

            <PrevValue
              className={cn(
                'ph-no-capture',
                percentChange >= 0 ? 'text-text-success-secondary' : 'text-text-error-primary',
              )}
            >
              {percentChange.toFixed(2) + '%'}
            </PrevValue>
            <PrevValueLabel>{i18next.t('dashboard.vs-prev')}</PrevValueLabel>
          </PrevContainer>
        )}
      </div>
    </Root>
  )
}
