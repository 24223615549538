const STATIC_URL = `${import.meta.env.VITE_STATIC_IMAGES_URL}/images/hub/menu-icons`

export const HUB_SECTION_AVAILABLE_ICONS = [
  'home',
  'about',
  'achievement',
  'chat',
  'page',
  'events',
  'forum',
  'leaderboard',
  'merch',
  'mini_games',
  'news',
  'store',
  'updates',
  'watch',
  'play',
  'redeem_code',
  'alarm-clock',
  'bomb',
  'book',
  'box',
  'calendar',
  'crown',
  'cup',
  'flash',
  'folder',
  'glasses',
  'graduation',
  'hourglass',
  'leaderboards-v2',
  'medal',
  'news-v2',
  'pill',
  'potion-v2',
  'potion',
  'rocket',
  'shield',
  'skull',
  'stars',
  'gift',
  'gift-v2',
  'candy',
  'snowflake',
  'toy',
  'tree',
]

export type HubSectionIconKey = (typeof HUB_SECTION_AVAILABLE_ICONS)[number]

/**
 * Get hub section icon.
 * @param icon
 */
export const getHubSectionIcon = (icon: HubSectionIconKey) => {
  if (!HUB_SECTION_AVAILABLE_ICONS.includes(icon)) {
    return null
  }

  return `${STATIC_URL}/${icon}.svg`
}
