import { ReactNode, createElement, forwardRef, useCallback, useMemo, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import {
  FloatingFocusManager,
  FloatingNode,
  FloatingPortal,
  FloatingTree,
  autoUpdate,
  flip,
  offset,
  size,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useInteractions,
  useMergeRefs,
} from '@floating-ui/react'
import {
  MenuGroup,
  MenuItem,
  MenuItemBaseProps,
  MenuSearch,
  Menu_v2,
  SelectBaseButton,
  SelectBaseValue,
  getFloatingMotionProps,
} from '@/components/ui'
import { groupBy } from '@/libs'
import { SelectTreeParentItem } from './SelectTreeParentItem'

export type SelectTreeValue = string | number | null

export interface ParentItem extends MenuItemBaseProps {
  tree: {
    value: string
    child: ChildItem[]
  }
}

export interface ChildItem extends MenuItemBaseProps {
  tree: {
    value: SelectTreeValue
    group?: string
  }
}

interface SelectTreeProps {
  value: SelectTreeValue
  items: ParentItem[]
  placeholder?: string
  search?: {
    fn?: (query: string, item: ChildItem, all: ChildItem[]) => boolean
    placeholder?: string
    autofocus?: boolean
    enabled?: boolean
  }
  /**
   * Renderer value function (component), it is recommended to look at `SelectBaseValue` and make a similar function
   * (component).
   * @param item
   */
  renderValueFn?: ({ item }: { item: ChildItem }) => ReactNode
  onChange: (value: SelectTreeValue) => void
  dropDownWidth?: string
}

export const SelectTree = forwardRef<HTMLButtonElement, SelectTreeProps>(function (
  { value, items, placeholder, search: _search, renderValueFn = SelectBaseValue, onChange, dropDownWidth },
  ref,
) {
  const nodeId = useFloatingNodeId()
  const [isOpen, setIsOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState<string>('')

  const { refs, floatingStyles, context, placement } = useFloating({
    nodeId,
    open: isOpen,
    whileElementsMounted: autoUpdate,
    placement: 'bottom-start',
    middleware: [
      offset(4),
      flip({ mainAxis: true }),
      size({
        apply({ availableHeight, rects, elements }) {
          Object.assign(elements.floating.style, {
            minWidth: `${rects.reference.width - 2}px`,
            maxWidth: `${rects.reference.width - 2}px`,
            maxHeight: `${Math.min(availableHeight - rects.reference.height, 1024)}px`,
          })
        },
      }),
    ],
    onOpenChange: setIsOpen,
  })

  const { getReferenceProps, getFloatingProps } = useInteractions([useClick(context), useDismiss(context)])
  const referenceMergedRef = useMergeRefs([refs.setReference, ref])

  const search: Required<SelectTreeProps['search']> = useMemo(
    () => ({
      ...{
        placeholder: '...',
        fn: (query, item) => item.children?.toString().toLowerCase().includes(query.toLowerCase()) || false,
        autofocus: false,
        enabled: false,
      },
      ..._search,
    }),
    [_search],
  )

  const selectedItem = useMemo(() => {
    let foundItem: ChildItem | undefined
    items.forEach(parent => {
      if (parent.tree.child) {
        const child = parent.tree.child.find(child => child.tree.value === value)
        if (child) {
          foundItem = child
        }
      }
    })
    return foundItem
  }, [value, items])

  const parentItems = useMemo(() => items.filter(item => !!item.tree.child), [items])

  const filteredChildItems = useMemo(() => {
    if (!searchQuery.length) {
      return []
    }

    const allChildItems = items.flatMap(parent => parent.tree.child)

    return Object.entries(
      groupBy(
        allChildItems.filter(child => search.fn(searchQuery, child, allChildItems)),
        child => parentItems.find(parent => parent.tree.child.includes(child))?.tree.value || '',
      ),
    )
  }, [items, search, searchQuery, parentItems])

  const onChangeItem = useCallback(
    (value: SelectTreeValue) => {
      onChange(value)
      setIsOpen(false)
      setSearchQuery('')
    },
    [onChange, setIsOpen, setSearchQuery],
  )

  return (
    <>
      <SelectBaseButton
        {...getReferenceProps({ ref: referenceMergedRef })}
        placeholder={placeholder}
        data-open={isOpen}
      >
        {selectedItem?.tree.value ? createElement(renderValueFn, { item: selectedItem }) : null}
      </SelectBaseButton>

      <FloatingTree>
        <FloatingNode id={nodeId}>
          <AnimatePresence>
            {isOpen && (
              <FloatingFocusManager context={context} initialFocus={search.enabled ? (search.autofocus ? 0 : 1) : 0}>
                <FloatingPortal>
                  <div
                    {...getFloatingProps({ ref: refs.setFloating })}
                    className="absolute z-[3000] w-max outline-none"
                    style={floatingStyles}
                  >
                    <Menu_v2
                      {...getFloatingMotionProps(placement)}
                      // eslint-disable-next-line tailwindcss/no-custom-classname
                      className={dropDownWidth ? `w-[${dropDownWidth}]` : ''}
                    >
                      {search.enabled && (
                        <MenuSearch
                          value={searchQuery}
                          onChange={e => setSearchQuery(e.target.value)}
                          placeholder={search.placeholder}
                          aria-controls="list-results"
                        />
                      )}

                      <div id="list-results" role="listbox" className="w-full">
                        {searchQuery.length ? (
                          <>
                            {filteredChildItems.map(([parentValue, items]) => (
                              <MenuGroup
                                key={`search-menu-group-${parentValue}`}
                                label={parentItems.find(parent => parent.tree.value === parentValue)?.children}
                              >
                                {items.map(({ tree, ...item }) => (
                                  <MenuItem
                                    {...item}
                                    key={`menu-item-${parentValue}-${tree.value}`}
                                    onClick={() => onChangeItem(tree.value)}
                                  >
                                    {item.children}
                                  </MenuItem>
                                ))}
                              </MenuGroup>
                            ))}
                          </>
                        ) : (
                          <>
                            {parentItems.map(parent => (
                              <SelectTreeParentItem
                                key={`menu-parent-${parent.tree.value}`}
                                parent={parent}
                                onChange={onChangeItem}
                              />
                            ))}
                          </>
                        )}
                      </div>
                    </Menu_v2>
                  </div>
                </FloatingPortal>
              </FloatingFocusManager>
            )}
          </AnimatePresence>
        </FloatingNode>
      </FloatingTree>
    </>
  )
})
