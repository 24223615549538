import RevenueChart from './components/RevenueChart/RevenueChart'
import { ReactNode, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import i18next from 'i18next'
import SingleValue, { SingleValueType } from './components/SingleValue/SingleValue'
import styled from '@emotion/styled'
import PaymentsSF from './components/PaymentsSF/PaymentsSF'
import RevenueByDevice from './components/RevenueByDevice/RevenueByDevice'
import ConversionGameHubChart from './components/ConversionGameHubChart/ConversionGameHubChart'
import RevenueMap from './components/RevenueMap/RevenueMap'
import SalesChart from './components/SalesChart/SalesChart'
import TopSkuItems from '@/layouts/dashboard/components/TopCharts/TopSkuItems'
import BestTriggers from './components/BestTriggers/BestTriggers'
import SankeyTopSku from './components/SankeyTopSku/SankeyTopSku'
import BalanceSingleValue from './components/Balance/Balance'
import { HUB_API, URL_HUB_API } from '../../HubAPI'
import { AllData, AnalyticsDataContext } from './Context'
import { FilterType, ResourceState } from '../../api/dashboard'
import { DateFilter } from './components/Filter/DateFilter'
import { getDataGranularity, getUrlParams } from './util'
import { Page } from '@/ui'
import { useMediaQuery } from '../../libs/hooks/useMediaQuery'
import { createAllData } from '@/layouts/dashboard/demo_data'
import { useQuery } from '@tanstack/react-query'
import { campaignsQuery } from '@/layouts/campaigns/api'
import { DEFAULT_LOAD_LIMIT, useGameItemsQuery } from '@/api/useGameItems'
import { useBanner } from '@/libs/hooks/useBanner'
import { cn } from '@/libs'
import TopClaimedSkuItems from '@/layouts/dashboard/components/TopCharts/TopClaimedSkuItems'
import TopCodes from '@/layouts/dashboard/components/TopCharts/TopCodes'
import HubVisitChart from '@/layouts/dashboard/components/HubVisitChart/HubVisitChart'

const DashboardTitle = styled.span`
  font-family: Nohemi;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
`

const SingleValueContainer = styled.div`
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-radius: 12px;
  align-items: center;
  width: 100%;
  border: 1px solid ${p => p.theme.palette.grey.borderSecondary};
  min-height: 121px;
`

const SingleValueSeparator = styled.div`
  width: 1px;
  height: 78px;
  background-color: ${p => p.theme.palette.background.fgskeleton};
  margin-right: 20px;
`

const Row = ({ children }: { children: ReactNode }) => {
  return <div className="flex items-center gap-5">{children}</div>
}

export default function Dashboard() {
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const [selectedFilter, setSelectedFilter] = useState('7')
  const [data, setData] = useState<AllData>({} as AllData)
  const [currentXhr, setCurrentXhr] = useState<XMLHttpRequest | null>(null)
  const { getStickyTop } = useBanner()
  const { data: campaigs = [] } = useQuery(campaignsQuery(companyId, gameId))
  const { data: items = [] } = useGameItemsQuery(companyId, gameId, {
    limit: DEFAULT_LOAD_LIMIT,
    state: ResourceState.Active,
  })

  const is1200 = useMediaQuery('(min-width: 1400px)')

  const loadAllData = async () => {
    setData({} as AllData)
    if (currentXhr) {
      currentXhr.abort()
    }
    let xhr = new XMLHttpRequest()
    setCurrentXhr(xhr)

    xhr.open(
      'GET',
      `${URL_HUB_API}/dashboard/v1/companies/${companyId}/games/${gameId}/reports/all_data?${getUrlParams(selectedFilter)}`,
      true,
    )

    xhr.setRequestHeader('Authorization', 'Bearer ' + HUB_API.getToken())
    xhr.setRequestHeader('Content-Type', 'application/json;charset=utf-8')

    xhr.onprogress = function () {
      if (xhr.readyState >= 3) {
        let lastMessage = xhr.responseText
        let jsons = lastMessage.split('data: ')
        let data = jsons
          .filter(it => !!it.trim() && it.indexOf('DONE_MESSAGE') == -1)
          .map(it => {
            try {
              return JSON.parse(it)
            } catch (e) {
              //console.error(e)
              //console.log(it)
            }
          })

        let obj = {}
        data.forEach(d => {
          obj = { ...obj, ...d }
        })

        setData(prev => ({
          ...prev,
          ...obj,
        }))

        if (lastMessage.indexOf('DONE_MESSAGE') != -1) {
          xhr.onprogress = null
          xhr.abort()
          setCurrentXhr(null)
        }
      }
    }

    xhr.send()
  }

  useEffect(() => {
    if (import.meta.env.VITE_DEMO_GAME_ID == gameId) {
      currentXhr?.abort()
      setData(createAllData(selectedFilter, campaigs, items))
    }
  }, [gameId, selectedFilter, campaigs, items])

  useEffect(() => {
    if (import.meta.env.VITE_DEMO_GAME_ID != gameId) {
      loadAllData()
    }
  }, [companyId, gameId, selectedFilter])

  let dauText = i18next.t('dashboard.dau')
  let dauToolTip = i18next.t('dashboard.dau.tooltip')

  if (
    selectedFilter == '12hours' ||
    selectedFilter == '24hours' ||
    selectedFilter == FilterType.Today ||
    selectedFilter == FilterType.Yesterday
  ) {
    dauText = i18next.t(`filter.${selectedFilter}`)
    dauToolTip = i18next.t('dashboard.dau.desc')
  }

  const width = '453px'
  const height = '460px'

  const renderCharts = () => {
    if (is1200) {
      return (
        <>
          <Row>
            <SankeyTopSku style={{ flexGrow: 3 }} />
            <BestTriggers style={{ width: width, height: height, flexGrow: 2 }} />
          </Row>
          <Row>
            <HubVisitChart />
          </Row>
          <Row>
            <PaymentsSF style={{ width: width, height: height, flexGrow: 1 }} />
            <ConversionGameHubChart style={{ width: width, height: height, flexGrow: 1 }} />
            <RevenueByDevice style={{ width: width, height: height, flexGrow: 1 }} />
          </Row>
          <Row>
            <TopSkuItems style={{ width: width, height: height, flexGrow: 1, overflow: 'hidden' }} />
            <TopClaimedSkuItems style={{ width: width, height: height, flexGrow: 1, overflow: 'hidden' }} />
            <TopCodes style={{ width: width, height: height, flexGrow: 1, overflow: 'hidden' }} />
          </Row>
        </>
      )
    }

    return (
      <>
        <Row>
          <SankeyTopSku style={{ flexGrow: 1 }} />
        </Row>
        <Row>
          <BestTriggers style={{ width: width, height: height, flexGrow: 1 }} />
          <PaymentsSF style={{ width: width, height: height, flexGrow: 1 }} />
        </Row>
        <Row>
          <HubVisitChart />
        </Row>
        <Row>
          <ConversionGameHubChart style={{ width: width, height: height, flexGrow: 1 }} />
          <RevenueByDevice style={{ width: width, height: height, flexGrow: 1 }} />
        </Row>
        <Row>
          <TopSkuItems style={{ width: width, height: height, flexGrow: 1 }} />
          <TopClaimedSkuItems style={{ width: width, height: height, flexGrow: 1 }} />
        </Row>
        <Row>
          <TopCodes style={{ width: width, height: height, flexGrow: 1 }} />
          <div style={{ width: width, height: height, flexGrow: 1 }} />
        </Row>
      </>
    )
  }

  return (
    <AnalyticsDataContext.Provider
      value={{
        type: Object.values(FilterType).includes(selectedFilter as FilterType)
          ? (selectedFilter as FilterType)
          : FilterType.Period,
        data: data,
        selectedFilter: selectedFilter,
        granularity: getDataGranularity(selectedFilter),
      }}
    >
      <Page className="gap-5 bg-fg-primary">
        <div className="flex gap-5">
          <BalanceSingleValue />
          <div className="grow overflow-x-hidden">
            <RevenueChart />
          </div>
        </div>

        <div className={cn('sticky z-[2] flex h-[72px] items-center bg-fg-primary', getStickyTop())}>
          <DashboardTitle>{i18next.t('dashboard.title')}</DashboardTitle>
          <div className="ml-auto w-[180px]">
            <DateFilter value={selectedFilter} onChange={v => setSelectedFilter(v)} />
          </div>
        </div>

        <SingleValueContainer>
          <SingleValue
            width="20%"
            value={{
              value: data.revenue?.value ? Math.max(data.revenue.value, 0) : data.revenue?.value,
              prev_value: data.revenue?.prev_value ? Math.max(data.revenue.prev_value, 0) : data.revenue?.prev_value,
            }}
            label={i18next.t('dashboard.revenue')}
            tooltip={i18next.t('dashboard.revenue.tooltip')}
            type={SingleValueType.money}
          />
          <SingleValueSeparator />
          <SingleValue width="20%" value={data.dau} label={dauText} tooltip={dauToolTip} />
          <SingleValueSeparator />
          <SingleValue
            width="20%"
            value={data.mau}
            label={i18next.t('dashboard.mau')}
            tooltip={i18next.t('dashboard.mau.tooltip')}
          />
          <SingleValueSeparator />
          <SingleValue
            width="20%"
            value={data.arppu}
            label={i18next.t('dashboard.arppu')}
            type={SingleValueType.money}
            tooltip={i18next.t('dashboard.arppu.tooltip')}
          />
          <SingleValueSeparator />
          <SingleValue
            width="20%"
            value={data.conversion}
            label={i18next.t('dashboard.conversion')}
            tooltip={i18next.t('dashboard.conversion.tooltip')}
            type={SingleValueType.percent}
          />
        </SingleValueContainer>

        <SalesChart />

        {renderCharts()}

        <RevenueMap />
        <div style={{ height: '100px' }} />
      </Page>
    </AnalyticsDataContext.Provider>
  )
}
