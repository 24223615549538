import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '@/icons'

export const EditGlobalDiscountOutline: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 24 24" ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1876 7.81241C16.5641 8.18892 16.5641 8.79936 16.1876 9.17587L9.17587 16.1876C8.79936 16.5641 8.18892 16.5641 7.81241 16.1876C7.4359 15.8111 7.4359 15.2006 7.81241 14.8241L14.8241 7.81241C15.2006 7.4359 15.8111 7.4359 16.1876 7.81241ZM7.96826 9.3706C7.96826 8.59611 8.59611 7.96826 9.3706 7.96826C10.1451 7.96826 10.7729 8.59611 10.7729 9.3706C10.7729 10.1451 10.1451 10.7729 9.3706 10.7729C8.59611 10.7729 7.96826 10.1451 7.96826 9.3706ZM13.227 14.6294C13.227 13.8549 13.8549 13.227 14.6294 13.227C15.4039 13.227 16.0317 13.8549 16.0317 14.6294C16.0317 15.4039 15.4039 16.0317 14.6294 16.0317C13.8549 16.0317 13.227 15.4039 13.227 14.6294Z"
        fill="currentColor"
      />
      <path
        d="M15.0615 4.60897C13.5997 4.00347 11.9911 3.84504 10.4393 4.15372C8.88743 4.4624 7.46197 5.22433 6.34315 6.34315C5.22433 7.46197 4.4624 8.88743 4.15372 10.4393C3.84504 11.9911 4.00347 13.5997 4.60897 15.0615C5.21447 16.5233 6.23985 17.7727 7.55544 18.6518C8.73215 19.438 10.0938 19.8964 11.5003 19.9844C11.7759 20.0016 12 20.2239 12 20.5V21.5C12 21.7761 11.776 22.0013 11.5002 21.9875C9.6978 21.8973 7.94996 21.3207 6.4443 20.3147C4.79981 19.2159 3.51809 17.6541 2.76121 15.8268C2.00433 13.9996 1.8063 11.9889 2.19215 10.0491C2.578 8.10929 3.53041 6.32746 4.92894 4.92894C6.32746 3.53041 8.10929 2.578 10.0491 2.19215C11.9889 1.8063 13.9996 2.00433 15.8268 2.76121C17.6541 3.51809 19.2159 4.79981 20.3147 6.4443C21.3207 7.94996 21.8973 9.6978 21.9875 11.5002C22.0013 11.776 21.7761 12 21.5 12H20.5C20.2239 12 20.0016 11.7759 19.9844 11.5003C19.8964 10.0938 19.438 8.73215 18.6518 7.55544C17.7727 6.23985 16.5233 5.21447 15.0615 4.60897Z"
        fill="currentColor"
      />
      <path
        d="M22.1932 14.4303C21.6447 13.8566 20.7554 13.8566 20.2068 14.4303C19.5776 15.0884 18.9486 15.7468 18.3196 16.4051C17.0559 17.7276 15.7923 19.0501 14.5271 20.371C14.3975 20.5063 14.2832 20.6256 14.199 20.7694C14.125 20.8957 14.0704 21.0335 14.0373 21.1775C13.9997 21.3415 13.9999 21.5104 14 21.7019C14.0002 21.8888 14.0001 22.0757 14.0001 22.2626C14.0001 22.3515 14 22.4404 14 22.5293C14 22.7892 14.2015 23 14.45 23C14.7137 23 14.9774 22.9997 15.2411 23C15.4242 23.0002 15.5856 23.0003 15.7424 22.9609C15.8802 22.9263 16.0118 22.8693 16.1326 22.7919C16.2701 22.7037 16.3842 22.5842 16.5135 22.4487C17.7767 21.125 19.0414 19.803 20.3061 18.481C20.9352 17.8234 21.5643 17.1658 22.1932 16.508C22.7418 15.9342 22.7418 15.004 22.1932 14.4303Z"
        fill="currentColor"
      />
      <path
        d="M18.5 22.0586C18.2515 22.0586 18.05 22.2693 18.05 22.5293C18.05 22.7892 18.2515 23 18.5 23H22.55C22.7985 23 23 22.7892 23 22.5293C23 22.2693 22.7985 22.0586 22.55 22.0586H18.5Z"
        fill="currentColor"
      />
    </IconBase>
  )
})
