import styled from '@emotion/styled'
import { useEffect, useState } from 'react'

const StyledRoot = styled.div`
  min-height: 39px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${p => p.theme.palette.text.tertiary};
  font-size: 12px;
  font-weight: 400;
  flex-shrink: 0;
  width: 100%;
`

const DocLink = styled.a`
  color: ${p => p.theme.palette.text.brandPrimary};
`

const Links = styled.div`
  display: flex;
  gap: 10px;
`

const Footer = () => {
  const [showTermly, setShowTermly] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setShowTermly(true)
    }, 250)
  }, [setShowTermly])

  return (
    <div className="container mt-auto bg-fg-primary">
      <StyledRoot>
        <span>© 2023–{new Date().getFullYear()} Aghanim, Inc. All rights reserved.</span>
        <Links>
          {showTermly && (
            <span className="cursor-pointer text-text-brand-primary termly-display-preferences">
              Consent Preferences
            </span>
          )}
          <DocLink target="_blank" href={'https://aghanim.com/privacy'}>
            Privacy Policy
          </DocLink>
          <DocLink target="_blank" href={'https://aghanim.com/developer-service'}>
            Terms of Service
          </DocLink>
        </Links>
      </StyledRoot>
    </div>
  )
}

export default Footer
