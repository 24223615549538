import {
  AttrType,
  CalcPlayerAttributes,
  Country,
  GraphRoot,
  ItemCategoryRead,
  PersistentPlayerAttributes,
  StoreGraphPreviewResult,
} from '../../api/dashboard'
import { useEffect, useState } from 'react'
import { dashboardClient, getErrorText } from '../../api'
import { useParams } from 'react-router-dom'
import { getUsingAttr } from '../campaigns/getUsingAttr'
import i18next from 'i18next'
import { AttrValueEditor } from '../campaigns/expr/AttrValueEditor'
import { KeyValue } from '../../types'
import { Attribute } from '../campaigns/expr/types'
import { FieldGroup, FieldValidationMessage } from '@/ui'
import { useDebounce } from '../../hooks'
import { isMoneyAttribute } from '../campaigns/blocks/common'
import { SelectSegment } from '../components/SelectSegment/SelectSegment'
import { SelectSkuItem } from '../campaigns/components/SelectSkuItem/SelectSkuItem'
import CenteredProgress from '../../components/CenteredProgress'
import { findAttr } from '../campaigns/expr/findAttr'
import { calcFilterDate } from '../campaigns/util'
import { StoreView } from './components/StoreView'

export default function PreviewStorePanel(props: {
  categories: ItemCategoryRead[] | null
  graph: GraphRoot
  previewLoaded: (graph: GraphRoot) => void
}) {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const [calcAttr, setCalcAttr] = useState<CalcPlayerAttributes>({} as CalcPlayerAttributes)
  const [isLoading, setIsLoading] = useState(false)
  const [previewData, setPreviewData] = useState<StoreGraphPreviewResult | null>(null)
  const [purchasedId, setPurchasedId] = useState<string | null>(null)
  const [segmentId, setSegmentId] = useState<string | null>(null)
  const debouncedCalcAttr = useDebounce(calcAttr, 500)
  const [error, setError] = useState<string | null>(null)

  const calcStore = async () => {
    setIsLoading(true)
    let country = (calcAttr as KeyValue)['country'] as Country
    setError('')

    const requestCalcAttr = {
      ...calcAttr,
    } as KeyValue

    for (const attrName in calcAttr) {
      const attr = findAttr(attrName)
      if (attr?.type == AttrType.Date) {
        requestCalcAttr[attrName] = calcFilterDate(requestCalcAttr[attrName] as string)
      }
    }

    try {
      let response = await dashboardClient.v1.previewStore(companyId, gameId, {
        graph: props.graph,
        country: country ? country : undefined,
        calc_attributes: {
          ...requestCalcAttr,
          user_segments: segmentId ? [segmentId] : [],
          purchased_item_ids: purchasedId ? [purchasedId] : [],
        },
        attributes: {} as PersistentPlayerAttributes,
      })

      setPreviewData(response.data)
      props.previewLoaded(response.data.graph)
    } catch (e) {
      setError(getErrorText(e))
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    calcStore()
  }, [props.graph, debouncedCalcAttr, segmentId, purchasedId])

  const renderValueEditor = (attr: Attribute, attrName: string, idx: number) => {
    if (attrName == 'user_segments') {
      return (
        <SelectSegment
          key={idx}
          mode="slug"
          dropDownWidth="400px"
          onChange={v => {
            setSegmentId(v as string)
          }}
          value={segmentId as string}
        />
      )
    }

    if (attrName == 'has_purchase') {
      return (
        <SelectSkuItem
          dropDownWidth="500px"
          onChange={v => {
            setPurchasedId(v as string)
          }}
          value={purchasedId}
        />
      )
    }

    return (
      <AttrValueEditor
        key={idx}
        attr={attr}
        isMoney={isMoneyAttribute(attrName)}
        onChange={v => {
          setCalcAttr({ ...calcAttr, [attrName]: v })
        }}
        value={(calcAttr as KeyValue)[attrName] as string}
      />
    )
  }

  const renderAttributes = () => {
    let attr = getUsingAttr(props.graph)
    return (
      <div className="mb-4 flex flex-wrap gap-4">
        {attr.map((attrName, idx) => {
          const attr = findAttr(attrName)
          if (!attr) {
            return null
          }
          return (
            <div style={{ width: '48%' }} key={attrName}>
              <FieldGroup label={attr.name}>{renderValueEditor(attr, attrName, idx)}</FieldGroup>
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div>
      {renderAttributes()}

      <div className="text-[14px] font-semibold uppercase text-text-tertiary">
        {i18next.t('store.header.preview-desc')}
      </div>

      {error && <FieldValidationMessage>{error}</FieldValidationMessage>}

      <div>
        {isLoading ? (
          <CenteredProgress />
        ) : (
          <StoreView items={previewData?.items || []} categories={props.categories} />
        )}
      </div>
    </div>
  )
}
