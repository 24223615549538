import { useTranslation } from 'react-i18next'
import { JobFunctionName, LocaleRead } from '@/api/dashboard'
import { useParams } from 'react-router-dom'
import { ModalBaseProps, ModalPresetConfirm } from '@dashboard/ui'
import { useJobCreateMutate } from '@/providers/api/useJobCreateMutate'

interface L10nLocaleModalConfirmTranslateAllProps extends ModalBaseProps {
  locale: LocaleRead
}

export const L10nLocaleModalConfirmTranslateAll = ({ locale, ...rest }: L10nLocaleModalConfirmTranslateAllProps) => {
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const { mutateAsync: createMutateAsync } = useJobCreateMutate(companyId, gameId)
  const { t } = useTranslation()

  const onClickConfirm = async () => {
    await createMutateAsync({
      create: {
        function: {
          name: JobFunctionName.TranslateTranslateAll,
          args: {
            locale: locale.locale,
            locale_id: locale.id,
          },
        },
      },
    })
    rest.onClose?.()
  }

  return (
    <ModalPresetConfirm
      {...rest}
      title={{
        children: t('localization.ai.all.modal-confirm.title'),
        subtitle: t('localization.ai.all.modal-confirm.text', { locale: t(`locales.${locale?.locale}`) }),
      }}
      cancel={{
        label: t('Cancel'),
      }}
      submit={{
        callback: onClickConfirm,
        color: 'primary',
        label: t('localization.ai.all.modal-confirm.confirm'),
      }}
    />
  )
}
