let _token: string

export const URL_HUB_API = import.meta.env.VITE_HUB_API_URL

interface HubApi {
  setToken: (token: string) => void
  getToken: () => string
}

export const HUB_API: HubApi = {
  setToken: function (token) {
    _token = token
  },

  getToken: function () {
    return _token
  },
}
