import { useCallback, useEffect, useState } from 'react'
import { $getSelection, $isRangeSelection, LexicalEditor } from 'lexical'
import { $findMatchingParent, mergeRegister } from '@lexical/utils'
import { INSERT_TABLE_COMMAND, TableNode } from '@lexical/table'
import { TableOutline } from '../../icons'
import { ToolbarButton } from './ToolbarButton'
import { ToolbarDivider } from '@/components/lexical/plugins/ToolbarPlugin/ToolbarDivider'

interface ToolbarTableButtonProps {
  editor: LexicalEditor
}

export const ToolbarTableButton = ({ editor }: ToolbarTableButtonProps) => {
  const [disabled, setDisabled] = useState(false)

  const $updateDisabled = useCallback(() => {
    const selection = $getSelection()
    if ($isRangeSelection(selection)) {
      setDisabled(!!$findMatchingParent(selection.anchor.getNode(), node => node instanceof TableNode))
    }
  }, [setDisabled])

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          $updateDisabled()
        })
      }),
    )
  }, [editor, $updateDisabled])

  const onClick = useCallback(() => {
    editor.dispatchCommand(INSERT_TABLE_COMMAND, { rows: '4', columns: '3', includeHeaders: false })
  }, [editor])

  if (!editor.hasNodes([TableNode])) {
    return null
  }

  return (
    <>
      <ToolbarButton disabled={disabled} onClick={onClick}>
        <TableOutline size={16} />
      </ToolbarButton>
      <ToolbarDivider />
    </>
  )
}
