import { ButtonIcon, Floating, TableRowSkeleton, TableZeroState } from '@/ui'
import { AI } from '@dashboard/ui/src/icons/AI'
import { useParams } from 'react-router-dom'
import { JobItem } from '@/layouts/notification/JobItem'
import { useTranslation } from 'react-i18next'
import { ImportSpreadSheetModal } from '@/layouts/store/widgets/ImportSpreadSheetModal'
import { JobFunctionName } from '@/api/dashboard'
import { useModal } from '@dashboard/ui'
import { useJobsQuery } from '@/providers/api/useJobsQuery'
import { useJobListener } from '@/providers/SocketProvider/useJobListener'
import { useCurrentUser } from '@/api/useCurrentUser'

const NotificationMenu = ({ onClose }: { onClose?: () => void }) => {
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const { t } = useTranslation()
  const { canEdit } = useCurrentUser()
  const { data: jobs = [], isLoading } = useJobsQuery(companyId, gameId, !!onClose)
  useJobListener([]) // need to listen for update jobs in react-query

  const openImportModal = useModal(props => <ImportSpreadSheetModal {...props} />)

  const onGoClick = (functionName: string) => {
    if (functionName === JobFunctionName.StoreImport) {
      openImportModal()
    }
    onClose?.()
  }

  const renderTable = () => {
    if (!jobs?.length) {
      return (
        <TableZeroState
          title={t('notification.zero.title')}
          message={t('notification.zero.message')}
          className="grow"
          contentClassName="items-center p-6 text-center"
        />
      )
    }

    return (
      <div className="flex flex-col gap-5">
        {jobs?.map(job => (
          <JobItem
            onGoClick={job.function_name != JobFunctionName.StoreImport || canEdit ? onGoClick : undefined}
            job={job}
            key={job.id}
          />
        ))}
      </div>
    )
  }

  return (
    <div className="flex h-[400px] w-[420px] flex-col gap-3 overflow-auto rounded-xl border border-border-primary bg-fg-primary p-[18px] shadow-lg">
      <div className="text-caption-md">{t('notification.title')}</div>
      {isLoading ? <TableRowSkeleton rowCount={5} columnCount={1} /> : renderTable()}
    </div>
  )
}

export const NotificationButton = () => {
  return (
    <Floating menu={<NotificationMenu />} placement="bottom-start" referenceWidth={true}>
      <ButtonIcon variant="tertiary-gray">
        <AI />
      </ButtonIcon>
    </Floating>
  )
}
