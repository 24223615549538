import { createRoot } from 'react-dom/client'
import { Route, RouterProvider, Routes, createBrowserRouter, createRoutesFromElements } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'
import App from './App'
import AcceptInvite from './auth/AcceptInvite'
import Callback from './auth/Callback'
import { Logout } from './auth/Logout'
import GoDoc from './auth/GoDoc'
import AppSkeleton from './skeleton/AppSkeleton'
import { FindWebSite } from './auth/FindWebSite'
import './css/index.css'
import './css/fonts.css'
import NotFoundPage from './layouts/errors/NotFoundPage'
import AccessDeniedPage from './layouts/errors/AccessDeniedPage'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { initSentry } from './libs/sentry'
import ThemeLangOutlet from '@/auth/ThemeLangOutlet'
import { Fragment, Suspense } from 'react'
import { initI18 } from '@/translates'
import {
  CreatorAcceptTermsPage,
  CreatorErrorPage,
  CreatorRequestPayoutPage,
  CreatorTermsAcceptedPage,
} from '@/layouts/creator/pages'

initI18()
initSentry()

const queryClient = new QueryClient({})
const rootElement = document.getElementById('root')
const root = createRoot(rootElement!)

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/api/auth/callback" element={<Callback />} />
      <Route path="/app-skeleton" element={<AppSkeleton />} />
      <Route path="/logout" element={<Logout />} />
      <Route
        path="/join/:inviteId"
        element={
          <ThemeLangOutlet>
            <AcceptInvite />
          </ThemeLangOutlet>
        }
      />
      <Route
        path="/403"
        element={
          <ThemeLangOutlet>
            <AccessDeniedPage />
          </ThemeLangOutlet>
        }
      />
      <Route
        path="/404"
        element={
          <ThemeLangOutlet>
            <NotFoundPage />
          </ThemeLangOutlet>
        }
      />
      <Route path="/go/*" element={<GoDoc />} />
      <Route path="/site/*" element={<FindWebSite />} />,
      <Route
        path="/creator/*"
        element={
          <ThemeLangOutlet header={<Fragment />}>
            <Routes>
              <Route path="/:token/error" element={<CreatorErrorPage />} />
              <Route path="/:token/terms" element={<CreatorAcceptTermsPage />} />
              <Route path="/:token/terms/accepted" element={<CreatorTermsAcceptedPage />} />
              <Route path="/:token/payout-request" element={<CreatorRequestPayoutPage />} />
            </Routes>
          </ThemeLangOutlet>
        }
      />
      <Route path="/*" element={<App />} />
    </>,
  ),
)

root.render(
  <Suspense fallback={<AppSkeleton />}>
    <Auth0Provider
      domain={import.meta.env.VITE_AUTH0_DOMAIN as string}
      issuer={import.meta.env.VITE_AUTH0_DOMAIN}
      clientId={import.meta.env.VITE_AUTH0_CLIENT_ID as string}
      cacheLocation={'localstorage'}
      authorizationParams={{
        redirect_uri: `${window.location.origin}/api/auth/callback`,
        audience: import.meta.env.VITE_AUTH0_API_AUDIENCE,
      }}
    >
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools />
        <RouterProvider router={router} />
      </QueryClientProvider>
    </Auth0Provider>
  </Suspense>,
)
