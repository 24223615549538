import { useCallback, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import {
  $createParagraphNode,
  $getRoot,
  $getSelection,
  $isElementNode,
  $isParagraphNode,
  $isRangeSelection,
  $isTextNode,
  ElementNode,
} from 'lexical'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { useLexicalEditable } from '@lexical/react/useLexicalEditable'
import {
  $deleteTableColumn__EXPERIMENTAL,
  $deleteTableRow__EXPERIMENTAL,
  $getNodeTriplet,
  $getTableCellNodeFromLexicalNode,
  $getTableNodeFromLexicalNodeOrThrow,
  $insertTableColumn__EXPERIMENTAL,
  $insertTableRow__EXPERIMENTAL,
  $isTableCellNode,
  $isTableSelection,
  $unmergeCell,
  HTMLTableElementWithWithTableSelectionState,
  TableCellNode,
  TableSelection,
  getTableObserverFromTableElement,
} from '@lexical/table'
import { ChevronDown } from '@/icons'
import { cn } from '@/libs'
import { Floating, MenuGroup, MenuItem, Menu_v2 } from '@/ui'
import {
  DeleteColumnOutline,
  DeleteRowOutline,
  DeleteTableOutline,
  InsertColumnLeftOutline,
  InsertColumnRightOutline,
  InsertRowAboveOutline,
  InsertRowBelowOutline,
  MergeCellOutline,
} from '@/components/lexical/icons'

const computeSelectionCount = (selection: TableSelection) => {
  const selectionShape = selection.getShape()
  return {
    columns: selectionShape.toX - selectionShape.fromX + 1,
    rows: selectionShape.toY - selectionShape.fromY + 1,
  }
}

const $canUnmerge = () => {
  const selection = $getSelection()
  if (
    ($isRangeSelection(selection) && !selection.isCollapsed()) ||
    ($isTableSelection(selection) && !selection.anchor.is(selection.focus)) ||
    (!$isRangeSelection(selection) && !$isTableSelection(selection))
  ) {
    return false
  }
  const [cell] = $getNodeTriplet(selection.anchor)
  return cell.__colSpan > 1 || cell.__rowSpan > 1
}

function $cellContainsEmptyParagraph(cell: TableCellNode): boolean {
  if (cell.getChildrenSize() !== 1) {
    return false
  }
  const firstChild = cell.getFirstChildOrThrow()
  return !(!$isParagraphNode(firstChild) || !firstChild.isEmpty())
}

function $selectLastDescendant(node: ElementNode): void {
  const lastDescendant = node.getLastDescendant()
  if ($isTextNode(lastDescendant)) {
    lastDescendant.select()
  } else if ($isElementNode(lastDescendant)) {
    lastDescendant.selectEnd()
  } else if (lastDescendant !== null) {
    lastDescendant.selectNext()
  }
}

interface TableCellMenuProps {
  contextRef: { current: null | HTMLElement }
  tableCellNode: TableCellNode
}

const TableCellMenu = ({ tableCellNode: _tableCellNode, contextRef }: TableCellMenuProps) => {
  const { t } = useTranslation()
  const [editor] = useLexicalComposerContext()
  const dropDownRef = useRef<HTMLDivElement | null>(null)
  const [tableCellNode, updateTableCellNode] = useState(_tableCellNode)
  const [selectionCounts, updateSelectionCounts] = useState({
    columns: 1,
    rows: 1,
  })
  const [canMergeCells, setCanMergeCells] = useState(false)
  const [canUnmergeCell, setCanUnmergeCell] = useState(false)

  useEffect(() => {
    return editor.registerMutationListener(
      TableCellNode,
      nodeMutations => {
        const nodeUpdated = nodeMutations.get(tableCellNode.getKey()) === 'updated'
        if (nodeUpdated) {
          editor.getEditorState().read(() => {
            updateTableCellNode(tableCellNode.getLatest())
          })
        }
      },
      { skipInitialization: true },
    )
  }, [editor, tableCellNode])

  useEffect(() => {
    editor.getEditorState().read(() => {
      const selection = $getSelection()
      if ($isTableSelection(selection)) {
        const currentSelectionCounts = computeSelectionCount(selection)
        updateSelectionCounts(computeSelectionCount(selection))
        setCanMergeCells(currentSelectionCounts.columns > 1 || currentSelectionCounts.rows > 1)
      }
      setCanUnmergeCell($canUnmerge())
    })
  }, [editor])

  useEffect(() => {
    const menuButtonElement = contextRef.current
    const dropDownElement = dropDownRef.current
    const rootElement = editor.getRootElement()

    if (menuButtonElement != null && dropDownElement != null && rootElement != null) {
      const rootEleRect = rootElement.getBoundingClientRect()
      const menuButtonRect = menuButtonElement.getBoundingClientRect()
      dropDownElement.style.opacity = '1'
      const dropDownElementRect = dropDownElement.getBoundingClientRect()
      const margin = 5
      let leftPosition = menuButtonRect.right + margin
      if (
        leftPosition + dropDownElementRect.width > window.innerWidth ||
        leftPosition + dropDownElementRect.width > rootEleRect.right
      ) {
        const position = menuButtonRect.left - dropDownElementRect.width - margin
        leftPosition = (position < 0 ? margin : position) + window.scrollX
      }
      dropDownElement.style.left = `${leftPosition + window.scrollX}px`

      let topPosition = menuButtonRect.top
      if (topPosition + dropDownElementRect.height > window.innerHeight) {
        const position = menuButtonRect.bottom - dropDownElementRect.height
        topPosition = (position < 0 ? margin : position) + window.scrollY
      }
      dropDownElement.style.top = `${topPosition + +window.scrollY}px`
    }
  }, [contextRef, dropDownRef, editor])

  const clearTableSelection = useCallback(() => {
    editor.update(() => {
      if (tableCellNode.isAttached()) {
        const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode)
        const tableElement = editor.getElementByKey(tableNode.getKey()) as HTMLTableElementWithWithTableSelectionState

        if (!tableElement) {
          throw new Error('Expected to find tableElement in DOM')
        }

        const tableObserver = getTableObserverFromTableElement(tableElement)
        if (tableObserver !== null) {
          tableObserver.clearHighlight()
        }

        tableNode.markDirty()
        updateTableCellNode(tableCellNode.getLatest())
      }

      const rootNode = $getRoot()
      rootNode.selectStart()
    })
  }, [editor, tableCellNode])

  const mergeTableCellsAtSelection = () => {
    editor.update(() => {
      const selection = $getSelection()
      if ($isTableSelection(selection)) {
        const { columns, rows } = computeSelectionCount(selection)
        const nodes = selection.getNodes()
        let firstCell: null | TableCellNode = null
        for (let i = 0; i < nodes.length; i++) {
          const node = nodes[i]
          if ($isTableCellNode(node)) {
            if (firstCell === null) {
              node.setColSpan(columns).setRowSpan(rows)
              firstCell = node
              const isEmpty = $cellContainsEmptyParagraph(node)
              let firstChild
              if (isEmpty && $isParagraphNode((firstChild = node.getFirstChild()))) {
                firstChild.remove()
              }
            } else if ($isTableCellNode(firstCell)) {
              const isEmpty = $cellContainsEmptyParagraph(node)
              if (!isEmpty) {
                firstCell.append(...node.getChildren())
              }
              node.remove()
            }
          }
        }
        if (firstCell !== null) {
          if (firstCell.getChildrenSize() === 0) {
            firstCell.append($createParagraphNode())
          }
          $selectLastDescendant(firstCell)
        }
      }
    })
  }

  const unmergeTableCellsAtSelection = () => {
    editor.update(() => {
      $unmergeCell()
    })
  }

  const insertTableRowAtSelection = useCallback(
    (shouldInsertAfter: boolean) => {
      editor.update(() => {
        $insertTableRow__EXPERIMENTAL(shouldInsertAfter)
      })
    },
    [editor],
  )

  const insertTableColumnAtSelection = useCallback(
    (shouldInsertAfter: boolean) => {
      editor.update(() => {
        for (let i = 0; i < selectionCounts.columns; i++) {
          $insertTableColumn__EXPERIMENTAL(shouldInsertAfter)
        }
      })
    },
    [editor, selectionCounts.columns],
  )

  const deleteTableRowAtSelection = useCallback(() => {
    editor.update(() => {
      $deleteTableRow__EXPERIMENTAL()
    })
  }, [editor])

  const deleteTableAtSelection = useCallback(() => {
    editor.update(() => {
      const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode)
      tableNode.remove()
      clearTableSelection()
    })
  }, [editor, tableCellNode, clearTableSelection])

  const deleteTableColumnAtSelection = useCallback(() => {
    editor.update(() => {
      $deleteTableColumn__EXPERIMENTAL()
    })
  }, [editor])

  return (
    <Menu_v2>
      {(selectionCounts.rows > 1 || selectionCounts.columns > 1) && (canMergeCells || canUnmergeCell) && (
        <MenuGroup label={t('lexical.table-cell-menu.cells.group-label')}>
          {canMergeCells && (
            <MenuItem
              data-test-id="lexical/table/merge-cells"
              icon={MergeCellOutline}
              onClick={() => mergeTableCellsAtSelection()}
            >
              {t('lexical.table-cell-menu.cells.merge')}
            </MenuItem>
          )}
          {canUnmergeCell && (
            <MenuItem
              data-test-id="lexical/table/unmerge-cells"
              icon={MergeCellOutline}
              onClick={() => unmergeTableCellsAtSelection()}
            >
              {t('lexical.table-cell-menu.cells.unmerge')}
            </MenuItem>
          )}
        </MenuGroup>
      )}

      <MenuGroup label={t('lexical.table-cell-menu.rows.group-label')}>
        <MenuItem
          data-test-id="lexical/table/insert-row-above"
          icon={InsertRowAboveOutline}
          onClick={() => insertTableRowAtSelection(false)}
        >
          {t('lexical.table-cell-menu.rows.insert-above', { count: selectionCounts.rows })}
        </MenuItem>
        <MenuItem
          data-test-id="lexical/table/insert-row-below"
          icon={InsertRowBelowOutline}
          onClick={() => insertTableRowAtSelection(true)}
        >
          {t('lexical.table-cell-menu.rows.insert-below', { count: selectionCounts.rows })}
        </MenuItem>
        <MenuItem
          data-test-id="lexical/table/delete-row"
          icon={DeleteRowOutline}
          onClick={() => deleteTableRowAtSelection()}
        >
          {t('lexical.table-cell-menu.rows.delete', { count: selectionCounts.rows })}
        </MenuItem>
      </MenuGroup>

      <MenuGroup label={t('lexical.table-cell-menu.columns.group-label')}>
        <MenuItem
          data-test-id="lexical/table/insert-column-left"
          icon={InsertColumnLeftOutline}
          onClick={() => insertTableColumnAtSelection(false)}
        >
          {t('lexical.table-cell-menu.columns.insert-left', { count: selectionCounts.columns })}
        </MenuItem>
        <MenuItem
          data-test-id="lexical/table/insert-column-right"
          icon={InsertColumnRightOutline}
          onClick={() => insertTableColumnAtSelection(true)}
        >
          {t('lexical.table-cell-menu.columns.insert-right', { count: selectionCounts.columns })}
        </MenuItem>
        <MenuItem
          data-test-id="lexical/table/delete-column"
          icon={DeleteColumnOutline}
          onClick={() => deleteTableColumnAtSelection()}
        >
          {t('lexical.table-cell-menu.columns.delete', { count: selectionCounts.columns })}
        </MenuItem>
      </MenuGroup>

      <MenuGroup>
        <MenuItem
          variant="danger"
          data-test-id="lexical/table/delete-table"
          icon={DeleteTableOutline}
          onClick={() => deleteTableAtSelection()}
        >
          {t('lexical.table-cell-menu.delete-table')}
        </MenuItem>
      </MenuGroup>
    </Menu_v2>
  )
}

interface TableCellActionMenuContainerProps {
  anchorElem: HTMLElement
}

const TableCellActionMenuContainer = ({ anchorElem }: TableCellActionMenuContainerProps) => {
  const [editor] = useLexicalComposerContext()

  const menuButtonRef = useRef(null)
  const menuRootRef = useRef(null)

  const [tableCellNode, setTableMenuCellNode] = useState<TableCellNode | null>(null)

  const $moveMenu = useCallback(() => {
    const menu = menuButtonRef.current
    const selection = $getSelection()
    const nativeSelection = window.getSelection()
    const activeElement = document.activeElement

    if (selection == null || menu == null) {
      setTableMenuCellNode(null)
      return
    }

    const rootElement = editor.getRootElement()

    if (
      $isRangeSelection(selection) &&
      rootElement !== null &&
      nativeSelection !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const tableCellNodeFromSelection = $getTableCellNodeFromLexicalNode(selection.anchor.getNode())

      if (tableCellNodeFromSelection == null) {
        setTableMenuCellNode(null)
        return
      }

      const tableCellParentNodeDOM = editor.getElementByKey(tableCellNodeFromSelection.getKey())

      if (tableCellParentNodeDOM == null) {
        setTableMenuCellNode(null)
        return
      }

      setTableMenuCellNode(tableCellNodeFromSelection)
    } else if (!activeElement) {
      setTableMenuCellNode(null)
    }
  }, [editor])

  const $updateFloating = useCallback(() => {
    const menuButtonDOM = menuButtonRef.current as HTMLButtonElement | null

    if (menuButtonDOM != null && tableCellNode != null) {
      const tableCellNodeDOM = editor.getElementByKey(tableCellNode.getKey())

      if (tableCellNodeDOM != null) {
        const tableCellRect = tableCellNodeDOM.getBoundingClientRect()
        const menuRect = menuButtonDOM.getBoundingClientRect()
        const anchorRect = anchorElem.getBoundingClientRect()

        const OFFSET = 8

        const top = tableCellRect.top - anchorRect.top + OFFSET
        const left = tableCellRect.right - menuRect.width - OFFSET - anchorRect.left

        menuButtonDOM.style.opacity = '1'
        menuButtonDOM.style.transform = `translate(${left}px, ${top}px)`
      } else {
        menuButtonDOM.style.opacity = '0'
        menuButtonDOM.style.transform = 'translate(-10000px, -10000px)'
      }
    }
  }, [menuButtonRef, tableCellNode, editor])

  useEffect(() => {
    return editor.registerUpdateListener(() => {
      editor.getEditorState().read(() => {
        $moveMenu()
        $updateFloating()
      })
    })
  })

  useEffect(() => {
    $updateFloating()
  }, [$updateFloating])

  return (
    <div ref={menuButtonRef} className="absolute left-0 top-0 z-[1900] will-change-transform">
      {tableCellNode != null && (
        <>
          <Floating
            placement="bottom-start"
            menu={<TableCellMenu contextRef={menuRootRef} tableCellNode={tableCellNode} />}
          >
            <button
              ref={menuRootRef}
              className={cn(
                'flex size-6 cursor-pointer items-center justify-center rounded-md bg-fg-primary text-text-quarterary transition-all',
                '[&[data-open="true"]]:bg-fg-primary-alt [&[data-open="true"]]:text-text-quarterary-hover',
                'hover:bg-fg-primary-alt hover:text-text-quarterary-hover',
              )}
              type="button"
            >
              <ChevronDown size={16} />
            </button>
          </Floating>
        </>
      )}
    </div>
  )
}

interface TableCellMenuPluginProps {
  anchorElem?: HTMLElement
}

export const TableCellMenuPlugin = ({ anchorElem = document.body }: TableCellMenuPluginProps) => {
  const isEditable = useLexicalEditable()
  return createPortal(isEditable ? <TableCellActionMenuContainer anchorElem={anchorElem} /> : null, anchorElem)
}
