// project imports
import './DefaultColors'
import { Theme } from '@mui/material/styles'
import { SxProps } from '@mui/system/styleFunctionSx'

const components = (theme: Theme) => {
  return {
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          boxSizing: 'border-box',
        },
        html: {
          height: '100%',
          width: '100%',
        },
        a: {
          textDecoration: 'none',
        },
        body: {
          height: '100%',
          margin: 0,
          padding: 0,
        },
        '#root': {
          backgroundColor: theme.palette.background.fgPrimary,
        },
        "*[dir='rtl'] .buyNowImg": {
          transform: 'scaleX(-1)',
        },
        '.border-none': {
          border: '0px',
          td: {
            border: '0px',
          },
        },
        '.btn-xs': {
          minWidth: '30px !important',
          width: '30px',
          height: '30px',
          borderRadius: '6px !important',
          padding: '0px !important',
        },
        '.hover-text-primary:hover .text-hover': {
          color: theme.palette.primary.main,
        },
        '.hoverCard:hover': {
          scale: '1.01',
          transition: ' 0.1s ease-in',
        },
        '.signup-bg': {
          position: 'absolute',
          top: 0,
          right: 0,
          height: '100%',
        },
        '.MuiBox-root': {
          borderRadius: theme.shape.borderRadius,
        },
        '.MuiCardHeader-action': {
          alignSelf: 'center !important',
        },
        '.emoji-picker-react .emoji-scroll-wrapper': {
          overflowX: 'hidden',
        },
        '.scrollbar-container': {
          borderRight: '0 !important',
        },
        '.theme-timeline .MuiTimelineOppositeContent-root': {
          minWidth: '90px',
        },
        '.MuiAlert-root .MuiAlert-icon': {
          color: 'inherit!important',
        },
        '.MuiTimelineConnector-root': {
          width: '1px !important',
        },
        ' .simplebar-scrollbar:before': {
          background: `${theme.palette.grey[300]} !important`,
        },
        '@keyframes gradient': {
          '0%': {
            backgroundPosition: '0% 50%',
          },
          '50%': {
            backgroundPosition: ' 100% 50%',
          },
          '100% ': {
            backgroundPosition: ' 0% 50%',
          },
        },
        '@keyframes slide': {
          '0%': {
            transform: 'translate3d(0, 0, 0)',
          },
          '100% ': {
            transform: 'translate3d(-2086px, 0, 0)',
          },
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          ':before': {
            backgroundColor: theme.palette.grey[100],
          },
          boxShadow: 'none',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          height: theme.spacing(12.25),
          padding: theme.spacing(4.5),
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: theme.spacing(4.5),
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          // border: `1px solid ${theme.palette.divider}`,
          backgroundImage: 'none',
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        line: {
          borderColor: theme.palette.divider,
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
        sizeSmall: {
          width: 30,
          height: 30,
          minHeight: 30,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          boxShadow: 'none',
          borderRadius: theme.spacing(0.5),
        },
        contained: {
          border: '1px solid ' + theme.palette.primary.dark,
        },
        sizeLarge: {
          padding: `${theme.spacing(1.5)} ${theme.spacing(3)}`,
          lineHeight: '19.36px',
        },
        sizeSmall: {
          fontSize: '14px',
          minWidth: '31px',
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
          lineHeight: '15.4px',
          '&:has(svg)': {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
          },
        },
        sizeMedium: {
          paddingTop: '10px',
          paddingBottom: '10px',
          fontSize: '16px',
          lineHeight: '17.4px',
        },
        containedPrimary: {
          fontWeight: '500',
          border: '1px solid ' + theme.palette.primary.main,
          '&:hover': {
            border: '1px solid ' + theme.palette.primary.dark1,
            backgroundColor: theme.palette.primary.dark1,
          },
          '&:active': {
            border: '1px solid ' + theme.palette.primary.dark4,
            backgroundColor: theme.palette.primary.dark4,
          },
          '&.Mui-disabled': {
            border: '1px solid ' + theme.palette.primary.light9,
            backgroundColor: theme.palette.primary.light9,
            color: theme.palette.primary.light7,
          },
        },
        grey: {
          backgroundColor: theme.palette.background.primary,
          color: theme.palette.text.button,
          '&:hover': {
            backgroundColor: theme.palette.grey['250'],
            boxShadow: `0px 0px 0px 3px ${theme.palette.grey['400']}24`,
          },
          '&:active': {
            backgroundColor: theme.palette.grey.border,
          },
          '&.Mui-disabled': {
            backgroundColor: theme.palette.grey['50'],
          },
        },
        text: {
          color: theme.palette.grey['1000'],
          padding: '5px 15px',
          fontWeight: '500',
        },
        textPrimary: {
          //backgroundColor: theme.palette.primary.light,
          // '&:hover': {
          //   backgroundColor: theme.palette.primary.main,
          //   color: 'white',
          // },
        },
        textSecondary: {
          backgroundColor: theme.palette.secondary.light,
          '&:hover': {
            backgroundColor: theme.palette.secondary.main,
            color: 'white',
          },
        },
        textSuccess: {
          backgroundColor: theme.palette.success.light,
          '&:hover': {
            backgroundColor: theme.palette.success.main,
            color: 'white',
          },
        },
        textError: {
          backgroundColor: theme.palette.error.light,
          '&:hover': {
            backgroundColor: theme.palette.error.main,
            color: 'white',
          },
        },
        textInfo: {
          backgroundColor: theme.palette.info.light,
          '&:hover': {
            backgroundColor: theme.palette.info.main,
            color: 'white',
          },
        },
        textWarning: {
          backgroundColor: theme.palette.warning.light,
          '&:hover': {
            backgroundColor: theme.palette.warning.main,
            color: 'white',
          },
        },
        outlinedPrimary: {
          borderRadius: theme.spacing(0.5),
          borderColor: theme.palette.primary.main,
          color: theme.palette.text.button,
          fontWeight: '500',
          '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.text.hoverButton,
          },
          '&:active': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.text.hoverButton,
          },
        },
        outlinedSecondary: {
          fontWeight: '500',
          border: `1px solid ${theme.palette.grey['250']}`,
          color: theme.palette.text.button,
          '&:hover': {
            border: `1px solid ${theme.palette.grey.buttonBorder}`,
            backgroundColor: theme.palette.grey.buttonBorder,
            boxShadow: `0px 0px 0px 3px ${theme.palette.grey['400']}24`,
            color: theme.palette.text.hoverButton,
          },
          '&:active': {
            color: theme.palette.text.hoverButton,
            border: `1px solid ${theme.palette.grey.buttonBorder}`,
            backgroundColor: '#334155',
            boxShadow: `0px 0px 0px 3px ${theme.palette.grey['400']}24`,
          },
        },
        outlinedError: {
          '&:hover': {
            backgroundColor: theme.palette.error.main,
            color: 'white',
          },
        },
        outlinedSuccess: {
          '&:hover': {
            backgroundColor: theme.palette.success.main,
            color: 'white',
          },
        },
        outlinedInfo: {
          '&:hover': {
            backgroundColor: theme.palette.info.main,
            color: 'white',
          },
        },
        outlinedWarning: {
          '&:hover': {
            backgroundColor: theme.palette.warning.main,
            color: 'white',
          },
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: '16px 24px',
        },
        title: {
          fontSize: '1.125rem',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          width: '100%',
          padding: '15px',
          backgroundImage: 'none',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '24px',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          '& .MuiTableCell-root': {
            borderBottom: `2px solid ${theme.palette.grey['100']}`,
            fontWeight: '500',
            fontSize: '0.75rem',
            boxSizing: 'border-box',
            color: theme.palette.text.primary2,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${theme.palette.grey['100']}`,
          boxSizing: 'border-box',
          padding: '19px 12px',
          fontSize: '0.875rem',
          fontWeight: '500',
          color: theme.palette.text.table,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:last-child td': {
            borderBottom: 0,
          },
        },
      },
    },
    MuiGridItem: {
      styleOverrides: {
        root: {
          paddingTop: '30px',
          paddingLeft: '30px !important',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.grey[200],
          borderRadius: '6px',
        },
      },
    },
    MuiTimelineConnector: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.divider,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: theme.palette.divider,
        },
      },
    },

    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: theme.spacing(0.5),
          fontWeight: 600,
          fontSize: '0.75rem',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        filledSuccess: {
          color: 'white',
        },
        filledInfo: {
          color: 'white',
        },
        filledError: {
          color: 'white',
        },
        filledWarning: {
          color: 'white',
        },
        root: {
          '& .MuiAlert-message': {
            padding: '10px 0',
          },
        },
        standardSuccess: {
          backgroundColor: theme.palette.success.light,
          color: theme.palette.success.main,
        },
        standardError: {
          backgroundColor: theme.palette.error.light,
          color: theme.palette.error.main,
        },
        standardWarning: {
          backgroundColor: theme.palette.warning.light2,
          color: theme.palette.warning.main,
          borderRadius: theme.spacing(1),
        },
        standardInfo: {
          backgroundColor: theme.palette.info.light,
          color: theme.palette.info.main,
        },
        outlinedSuccess: {
          borderColor: theme.palette.success.main,
          color: theme.palette.success.main,
        },
        outlinedWarning: {
          borderColor: theme.palette.warning.main,
          color: theme.palette.warning.main,
        },
        outlinedError: {
          borderColor: theme.palette.error.main,
          color: theme.palette.error.main,
        },
        outlinedInfo: {
          borderColor: theme.palette.info.main,
          color: theme.palette.info.main,
        },
        successIcon: {
          color: theme.palette.info.main,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          lineHeight: '19.36px',
          padding: theme.spacing(1.25),
          color: 'rgb(2, 6, 23)',
          borderRadius: theme.spacing(0.75),
          '&.MuiOutlinedInput-root:not(.MuiInputBase-multiline)': {
            height: '36px',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.grey.borderPrimary,
          },
          '&.Mui-error': {
            color: theme.palette.error.dark2,
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.error.dark2,
            },
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.main,
            borderWidth: '1px',
            boxShadow: '0px 0px 0px 3px rgba(0, 151, 228, 0.24)',
            color: theme.palette.text.focus,
          },
          '&.Mui-focused input, &.Mui-focused textarea': {
            color: theme.palette.text.focus,
          },
          '&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.grey.border,
            boxShadow: '0px 0px 0px 2px rgba(0, 151, 228, 0.14)',
          },
          '& .MuiInputBase-inputMultiline': {
            padding: 0,
          },
          '&.MuiOutlinedInput-root .MuiOutlinedInput-input::placeholder': {
            color: theme.palette.text.input,
          },
        },
        input: {
          padding: 0,
          height: '100%',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          padding: 0,
          '& .MuiInputBase-input.MuiSelect-select': {
            height: '100%',
            padding: theme.spacing(2),
            display: 'flex',
            alignItems: 'center',
          },
          '& .MuiSelect-icon': {
            top: 'calc(50% - 0.6em)',
            color: 'currentColor',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: theme.palette.background.paper,
          background: theme.palette.text.primary,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: theme.palette.background.default,
          borderColor: theme.palette.grey['200'],
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '1.25rem',
          padding: `${theme.spacing(2.5)} ${theme.spacing(3.75)}`,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: `${theme.spacing(2)} ${theme.spacing(3.75)}`,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: 'rgb(145 158 171 / 30%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: theme.spacing(5),
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          '&& .Mui-selected': {
            color: theme.palette.primary.dark,
            backgroundColor: theme.palette.primary.light,
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '& .MuiListItem-root:hover:not(.Mui-selected)': {
            backgroundColor: theme.palette.grey['150'],
          },
          '& .MuiListItem-root': {
            color: theme.palette.text.primary,
          },
          '& .MuiListItem-root:active': {
            backgroundColor: theme.palette.primary.light,
          },
          '.MuiTouchRipple-child': {
            backgroundColor: theme.palette.grey['200'],
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: '9px 11px',
          '& .MuiSwitch-switchBase': {
            padding: '0',
            width: '100%',
            height: '100%',
            transform: 'translateX(-8px) translateY(-0.5px)',
            '&:hover': {
              backgroundColor: 'inherit',
            },
          },
          '&:hover .MuiSwitch-track': {
            backgroundColor: theme.palette.grey[300],
          },
          '& .MuiSwitch-track': {
            borderRadius: '9999px',
            backgroundColor: theme.palette.grey[200],
            opacity: 1,
          },
          '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(8px) translateY(-0.5px)',
            '&:hover': {
              backgroundColor: 'inherit',
            },
          },
          '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: theme.palette.primary.dark5,
            opacity: 1,
          },
          '& .Mui-checked .MuiSwitch-thumb': {
            color: theme.palette.background.content,
          },
          '& .MuiSwitch-thumb': {
            width: theme.spacing(2),
            height: theme.spacing(2),
          },
        },
      },
    },
  }
}
export default components

export const DEFAULT_SX_TABLE_12 = {
  whiteSpace: 'nowrap',
  '& .MuiTableRow-root': {
    height: '62px',
  },
  '& .MuiTableCell-root': {
    padding: '12px 12px',
  },
} as SxProps<Theme>
