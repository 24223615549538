import { ReactElement } from 'react'
import { SidebarLinkIconProps, SidebarLinkIconVariant } from '@/components/ui/AppSidebar/types'

const variantMap = new Map<SidebarLinkIconVariant, ReactElement>([
  [
    'default',
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.16675 1.66666H15.8334C17.2084 1.66666 18.3334 2.79166 18.3334 4.16666V15.8333C18.3334 17.2083 17.2084 18.3333 15.8334 18.3333H4.16675C2.79175 18.3333 1.66675 17.2083 1.66675 15.8333V4.16666C1.66675 2.79166 2.79175 1.66666 4.16675 1.66666ZM15.8334 16.6667C16.2917 16.6667 16.6667 16.2917 16.6667 15.8333V4.16666C16.6667 3.70832 16.2917 3.33332 15.8334 3.33332H4.16675C3.70841 3.33332 3.33341 3.70832 3.33341 4.16666V15.8333C3.33341 16.2917 3.70841 16.6667 4.16675 16.6667H15.8334ZM7.37506 14.1667H12.6334C13.0917 14.1667 13.4667 14.5417 13.4667 15C13.4667 15.4583 13.0917 15.8333 12.6334 15.8333H7.37506C6.91673 15.8333 6.54173 15.4583 6.54173 15C6.54173 14.5417 6.91673 14.1667 7.37506 14.1667ZM14.1667 11.6667H5.83341C5.37508 11.6667 5.00008 12.0417 5.00008 12.5C5.00008 12.9583 5.37508 13.3333 5.83341 13.3333H14.1667C14.6251 13.3333 15.0001 12.9583 15.0001 12.5C15.0001 12.0417 14.6251 11.6667 14.1667 11.6667ZM9.58344 10.5999L7.52511 9.40825C7.26677 9.25825 7.10844 8.99158 7.10844 8.69158V6.31658C7.10844 6.02492 7.26677 5.74992 7.52511 5.59992L9.58344 4.40825C9.71677 4.33325 9.85844 4.29992 10.0001 4.29992C10.1418 4.29992 10.2918 4.33325 10.4168 4.40825L12.4751 5.59992C12.7334 5.74992 12.8918 6.01658 12.8918 6.31658V8.69158C12.8918 8.98325 12.7334 9.25825 12.4751 9.40825L10.4168 10.5999C10.2834 10.6749 10.1418 10.7083 10.0001 10.7083C9.85844 10.7083 9.70844 10.6749 9.58344 10.5999ZM8.95844 6.69992C8.85011 6.75825 8.78344 6.87492 8.78344 6.99992V7.99992C8.78344 8.12492 8.85011 8.23325 8.95844 8.29992L9.82511 8.79992C9.87511 8.83325 9.94177 8.84992 10.0001 8.84992C10.0584 8.84992 10.1168 8.83325 10.1751 8.79992L11.0418 8.29992C11.1501 8.24158 11.2168 8.12492 11.2168 7.99992V6.99992C11.2168 6.87492 11.1501 6.76658 11.0418 6.69992L10.1751 6.19992C10.1251 6.16658 10.0584 6.14992 10.0001 6.14992C9.94177 6.14992 9.88344 6.16658 9.82511 6.19992L8.95844 6.69992Z"
      fill="currentColor"
    />,
  ],
  [
    'fill',
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.16675 1.66666H15.8334C17.2084 1.66666 18.3334 2.79166 18.3334 4.16666V15.8333C18.3334 17.2083 17.2084 18.3333 15.8334 18.3333H4.16675C2.79175 18.3333 1.66675 17.2083 1.66675 15.8333V4.16666C1.66675 2.79166 2.79175 1.66666 4.16675 1.66666ZM15.8334 16.6667C16.2917 16.6667 16.6667 16.2917 16.6667 15.8333V4.16666C16.6667 3.70832 16.2917 3.33332 15.8334 3.33332H4.16675C3.70841 3.33332 3.33341 3.70832 3.33341 4.16666V15.8333C3.33341 16.2917 3.70841 16.6667 4.16675 16.6667H15.8334ZM7.37506 14.1667H12.6334C13.0917 14.1667 13.4667 14.5417 13.4667 15C13.4667 15.4583 13.0917 15.8333 12.6334 15.8333H7.37506C6.91673 15.8333 6.54173 15.4583 6.54173 15C6.54173 14.5417 6.91673 14.1667 7.37506 14.1667ZM14.1667 11.6667H5.83341C5.37508 11.6667 5.00008 12.0417 5.00008 12.5C5.00008 12.9583 5.37508 13.3333 5.83341 13.3333H14.1667C14.6251 13.3333 15.0001 12.9583 15.0001 12.5C15.0001 12.0417 14.6251 11.6667 14.1667 11.6667ZM9.58344 10.5999L7.52511 9.40825C7.26677 9.25825 7.10844 8.99158 7.10844 8.69158V6.31658C7.10844 6.02492 7.26677 5.74992 7.52511 5.59992L9.58344 4.40825C9.71677 4.33325 9.85844 4.29992 10.0001 4.29992C10.1418 4.29992 10.2918 4.33325 10.4168 4.40825L12.4751 5.59992C12.7334 5.74992 12.8918 6.01658 12.8918 6.31658V8.69158C12.8918 8.98325 12.7334 9.25825 12.4751 9.40825L10.4168 10.5999C10.2834 10.6749 10.1418 10.7083 10.0001 10.7083C9.85844 10.7083 9.70844 10.6749 9.58344 10.5999ZM8.95844 6.69992C8.85011 6.75825 8.78344 6.87492 8.78344 6.99992V7.99992C8.78344 8.12492 8.85011 8.23325 8.95844 8.29992L9.82511 8.79992C9.87511 8.83325 9.94177 8.84992 10.0001 8.84992C10.0584 8.84992 10.1168 8.83325 10.1751 8.79992L11.0418 8.29992C11.1501 8.24158 11.2168 8.12492 11.2168 7.99992V6.99992C11.2168 6.87492 11.1501 6.76658 11.0418 6.69992L10.1751 6.19992C10.1251 6.16658 10.0584 6.14992 10.0001 6.14992C9.94177 6.14992 9.88344 6.16658 9.82511 6.19992L8.95844 6.69992Z"
      fill="currentColor"
    />,
  ],
  [
    'duotone',
    <path
      d="M15.8334 1.66666H4.16675C2.79175 1.66666 1.66675 2.79166 1.66675 4.16666V15.8333C1.66675 17.2083 2.79175 18.3333 4.16675 18.3333H15.8334C17.2084 18.3333 18.3334 17.2083 18.3334 15.8333V4.16666C18.3334 2.79166 17.2084 1.66666 15.8334 1.66666ZM7.11675 6.30832C7.11675 6.00832 7.27508 5.74166 7.53341 5.59166L9.59175 4.39999C9.71675 4.32499 9.86675 4.29166 10.0084 4.29166C10.1501 4.29166 10.2917 4.32499 10.4251 4.39999L12.4834 5.59166C12.7417 5.74166 12.9001 6.01666 12.9001 6.30832V8.68332C12.9001 8.98332 12.7417 9.24999 12.4834 9.39999L10.4251 10.5917C10.3001 10.6667 10.1501 10.7 10.0084 10.7C9.86675 10.7 9.72508 10.6667 9.59175 10.5917L7.53341 9.39999C7.27508 9.24999 7.11675 8.97499 7.11675 8.68332V6.30832ZM12.6334 15.8333H7.37508C6.91675 15.8333 6.54175 15.4583 6.54175 15C6.54175 14.5417 6.91675 14.1667 7.37508 14.1667H12.6334C13.0917 14.1667 13.4667 14.5417 13.4667 15C13.4667 15.4583 13.0917 15.8333 12.6334 15.8333ZM14.1667 13.3333H5.83342C5.37508 13.3333 5.00008 12.9583 5.00008 12.5C5.00008 12.0417 5.37508 11.6667 5.83342 11.6667H14.1667C14.6251 11.6667 15.0001 12.0417 15.0001 12.5C15.0001 12.9583 14.6251 13.3333 14.1667 13.3333Z"
      fill="#334155"
    />,
  ],
])

export const ItemProperty = ({ variant = 'default', ...rest }: SidebarLinkIconProps) => {
  return (
    <svg {...rest} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      {variantMap.get(variant)}
    </svg>
  )
}
