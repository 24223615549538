import { useTranslation } from 'react-i18next'
import { UserRead } from '@/api/dashboard'
import { useParams } from 'react-router-dom'
import {
  Button,
  Modal,
  ModalBaseProps,
  ModalContent,
  ModalFooter,
  ModalTitle,
  Select,
  Textarea,
  formatHookFormErrors,
} from '@dashboard/ui'
import { ToastSeverity, useToast } from '@/ui'
import { usePlayerUpdateMutate } from '@/layouts/player/api'
import { Controller, useForm } from 'react-hook-form'

interface L10nLocaleModalConfirmTranslateAllProps extends ModalBaseProps {
  user: UserRead
}

interface BanForm {
  reason: string
  other: string
}

export const BanModalConfirm = ({ user, ...rest }: L10nLocaleModalConfirmTranslateAllProps) => {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const { mutateAsync } = usePlayerUpdateMutate(companyId, gameId)
  const showToast = useToast()
  const { t } = useTranslation()

  const {
    formState: { isSubmitting, errors },
    watch,
    handleSubmit,
    register,
    control,
  } = useForm<BanForm>({})

  const onClickConfirm = async (data: BanForm) => {
    user.banned = true
    user.banned_reason = data.reason == 'other' ? data.other : data.reason
    await mutateAsync({ userId: user.id, update: { banned: true, banned_reason: user.banned_reason } })

    showToast({ message: t('saved'), severity: ToastSeverity.success })
    rest.onClose?.()
  }

  return (
    <Modal {...rest}>
      <ModalContent>
        <ModalTitle children={t('player.ban.confirm.title')} subtitle={t('player.ban.confirm.subtitle')} />

        <Controller
          control={control}
          name="reason"
          rules={{ required: t('validation.required') }}
          render={({ field: { value, onChange } }) => (
            <Select
              value={value}
              onChange={onChange}
              items={[
                { value: 'chargeback', children: t('player.ban.reason.chargeback') },
                { value: 'fraud', children: t('player.ban.reason.fraud') },
                { value: 'other', children: t('player.ban.reason.other') },
              ]}
              {...formatHookFormErrors(errors, 'reason')}
            />
          )}
        />

        {watch('reason') == 'other' && (
          <Textarea
            className="mt-2"
            maxLength={255}
            {...register('other', {
              setValueAs: value => value.trim(),
              required: t('validation.required'),
              maxLength: 255,
            })}
            {...formatHookFormErrors(errors, 'other')}
          />
        )}
      </ModalContent>
      <ModalFooter>
        <Button variant="outline" color={'secondary'} type="button" size="sm" onClick={() => rest.onClose?.()}>
          {t('Cancel')}
        </Button>

        <Button color={'danger'} size="sm" onClick={handleSubmit(onClickConfirm)} type="submit" loading={isSubmitting}>
          {t('player.ban.confirm.button')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
