import { generatePath, useNavigate, useParams } from 'react-router-dom'

import { useEffect, useState } from 'react'
import i18next from 'i18next'
import { Campaign, CampaignEventType, CampaignTemplateRead, ResourceState } from '@/api/dashboard'
import { dashboardClient, getErrorText } from '@/api'
import CampaignSelector, { CampaignItem } from '../components/CampaignSelector/CampaignSelector'
import { LIVEOPS_DETAILS_PATH } from '@/libs'
import { ButtonIcon, Modal, ModalProps, ModalTitle, ToastSeverity, useToast } from '@/ui'
import { useQuery } from '@tanstack/react-query'
import { campaignsQuery } from '@/layouts/campaigns/api'
import { campaignTemplatesQuery } from '@/layouts/campaigns/api/campaignTemplatesQuery'
import { ChevronLeft, CloseOutline } from '@/icons'
import CampaignSettings from '@/layouts/campaigns/widgets/CampaignSettings'
import AddNewCampaignButton from '@/layouts/campaigns/widgets/AddNewCampaignButton'
import { clearEntityIdFields } from '@/layouts/campaigns/clearEntityIdFields'
import { ByAghanim } from '@/layouts/campaigns/pages/CampaignTable/ByAghanim'
import { useCurrentUser } from '@/api/useCurrentUser'
import { Button, ModalPresetConfirm, useModal } from '@dashboard/ui'
import { useTranslation } from 'react-i18next'

export default function CreateCampaignModal(props: ModalProps) {
  const { ...rest } = props
  const navigate = useNavigate()
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const { t } = useTranslation()
  const showToast = useToast()
  const { isSuperAdmin } = useCurrentUser()
  const [step, setStep] = useState<number>(0)

  const { data: createdCampaigns = [] } = useQuery(
    campaignsQuery(companyId, gameId, { resource_state: ResourceState.Active }),
  )
  const {
    data: templates = [],
    isLoading: isTemplateLoading,
    refetch,
  } = useQuery(campaignTemplatesQuery(companyId, gameId))

  const [newCampaign, setNewCampaign] = useState<Campaign>({
    type: CampaignEventType.CustomEvent,
    name: '',
    id: '',
    enabled: false,
    attributes: {
      custom_attributes: [],
    },
  })

  const saveClick = async (c: Campaign) => {
    try {
      c.time_limit = !!(c.start_at || c.end_at)
      c.attributes = {
        ...newCampaign.attributes,
        custom_attributes: c.attributes?.custom_attributes,
      }
      let { data: created } = await dashboardClient.v1.createCampaign(companyId, gameId, c)
      navigate(generatePath(LIVEOPS_DETAILS_PATH, { companyId, gameId, eventId: created.id }))
    } catch (e) {
      showToast({ message: t(`server-errors.${getErrorText(e)}`), severity: ToastSeverity.error })
    }
  }

  const onTemplateSelected = async (template: CampaignTemplateRead) => {
    let { data: created } = await dashboardClient.v1.createCampaign(companyId, gameId, {
      ...newCampaign,
      type: template.type,
    })

    clearEntityIdFields(template.graph)

    navigate(generatePath(LIVEOPS_DETAILS_PATH, { companyId, gameId, eventId: created.id }), {
      state: { graph: template.graph },
    })
  }

  const backClick = () => {
    setStep(step - 1)
  }

  const hasStepZero = () => {
    return templates.length > 0
  }

  useEffect(() => {
    if (!isTemplateLoading && !hasStepZero() && step == 0) {
      setStep(1)
    }
  }, [isTemplateLoading])

  const openConfirmArchive = useModal<{ t: CampaignTemplateRead }>(props => (
    <ModalPresetConfirm
      {...props}
      title={{
        children: t('confirm.title'),
        subtitle: i18next.t('campaign.confirm.delete-template'),
      }}
      cancel={{
        label: t('no'),
      }}
      submit={{
        color: 'danger',
        callback: () => {
          props.onClose()
          onRemoveTemplate(props.t)
        },
        label: t('remove'),
      }}
    />
  ))

  const onRemoveTemplate = async (t: CampaignTemplateRead) => {
    await dashboardClient.v1.deleteCampaignTemplate(companyId, gameId, t.id)
    showToast({ message: i18next.t('removed'), severity: ToastSeverity.success })
    refetch()
  }

  const renderTemplateList = () => {
    return (
      <div className="flex flex-wrap gap-[42px]">
        <AddNewCampaignButton onClick={() => setStep(1)} />
        {templates.map(it => (
          <div className="flex flex-col gap-2">
            <CampaignItem
              key={it.id}
              buttonText={i18next.t('campaign.use-template')}
              type={it.type}
              onNewClick={() => onTemplateSelected(it)}
            >
              {isSuperAdmin && (
                <Button onClick={() => openConfirmArchive({ t: it })} color="danger">
                  {i18next.t('remove')}
                </Button>
              )}
            </CampaignItem>
            <ByAghanim />
          </div>
        ))}
      </div>
    )
  }

  const getStepName = () => {
    switch (step) {
      case 0:
        return i18next.t('campaign.step1')
      case 1:
        return i18next.t('campaign.step2')
      case 2:
        return i18next.t('campaign.step3') + ''
    }
    return ''
  }

  const onEventTypeSelected = (type: CampaignEventType) => {
    setNewCampaign({
      ...newCampaign,
      type,
    })
    switch (type) {
      case CampaignEventType.GameHubVisit:
      case CampaignEventType.GameHubVisitStore:
        if (!newCampaign.attributes?.visit_duration) {
          setNewCampaign(prev => ({
            ...prev,
            attributes: {
              visit_duration: 60 * 60 * 24,
            },
          }))
        }
        break
      case CampaignEventType.AbandonedCart:
        if (!newCampaign.attributes?.abandoned_cart_duration) {
          setNewCampaign(prev => ({
            ...prev,
            attributes: {
              abandoned_cart_duration: 60 * 10,
            },
          }))
        }
        break
    }
    setStep(2)
  }

  const renderModalTitle = () => {
    return (
      <div className="px-12">
        <ModalTitle>
          <div className="flex items-center">
            {(hasStepZero() ? step > 0 : step > 1) && (
              <ButtonIcon variant="secondary-gray" size="xs" onClick={backClick} className="mr-6">
                <ChevronLeft size={16} />
              </ButtonIcon>
            )}
            {getStepName()}
            <div className="ml-auto flex items-center gap-3">
              <ButtonIcon variant="secondary-gray" size="sm" onClick={props.onClose}>
                <CloseOutline size={16} />
              </ButtonIcon>
            </div>
          </div>
        </ModalTitle>
      </div>
    )
  }

  return (
    <Modal {...rest} isLoading={isTemplateLoading} className="flex h-[90vh] max-w-[1144px] flex-col px-0">
      {step != 2 && (
        <>
          {renderModalTitle()}
          <div className="w-full grow overflow-auto pl-12">
            {step == 0 && renderTemplateList()}
            {step == 1 && <CampaignSelector createdCampaigns={createdCampaigns} onNewClick={onEventTypeSelected} />}
          </div>
        </>
      )}

      {step == 2 && (
        <CampaignSettings
          backClick={backClick}
          onClose={props.onClose!}
          campaign={newCampaign}
          setCampaign={c => saveClick(c)}
        />
      )}
    </Modal>
  )
}
