import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ErrorMessage } from '@hookform/error-message'
import { LimitedStoreBannerBlock, StoreType } from '@/api/dashboard'
import { FieldGroup, ImageUploader } from '@/ui'
import { FormErrorMessage, Input, Select, Textarea, formatHookFormErrors } from '@dashboard/ui'
import { useStoresQuery } from '@/layouts/store/api'
import { urlRegexp, useGenerateCompanyPath } from '@/libs'
import { S3Bucket } from '@/types'
import i18next from 'i18next'

export const LimitedStoreBannerForm = () => {
  const { t } = useTranslation()

  const { companyId, gameId } = useGenerateCompanyPath()
  const { data: stores = [], isLoading } = useStoresQuery(companyId, gameId, { limit: 100 })

  const {
    formState: { errors },
    register,
    control,
  } = useFormContext<LimitedStoreBannerBlock>()

  const limitedStores = stores.filter(store => store.type === StoreType.LimitedStock)

  return (
    <div>
      <FieldGroup label={t('hub-editor.block-limited-store.title')}>
        <Textarea
          {...register('title', { required: t('validation.required') })}
          {...formatHookFormErrors(errors, 'title')}
          rows={2}
        />
      </FieldGroup>

      <FieldGroup label={t('hub-editor.block-limited-store.description')}>
        <Textarea
          {...register('description', { required: t('validation.required') })}
          {...formatHookFormErrors(errors, 'description')}
          rows={2}
        />
      </FieldGroup>

      <FieldGroup label={t('hub-editor.block-limited-store.button')}>
        <Input
          {...register('button', { required: t('validation.required') })}
          {...formatHookFormErrors(errors, 'button')}
        />
      </FieldGroup>

      <FieldGroup label={t('hub-editor.block-limited-store.link')}>
        <Input
          {...register('link', { pattern: { value: urlRegexp, message: i18next.t('validation.invalid_url') } })}
          {...formatHookFormErrors(errors, 'link')}
        />
      </FieldGroup>

      <FieldGroup label={t('hub-editor.block-limited-store.store')}>
        <Controller
          control={control}
          name="store_id"
          rules={{ required: t('validation.required') }}
          render={({ field }) => (
            <Select
              {...field}
              {...formatHookFormErrors(errors, 'store_id')}
              items={limitedStores.map(store => ({
                children: store.name,
                value: store.id,
                caption: store.description,
              }))}
              disabled={isLoading}
            />
          )}
        />
      </FieldGroup>

      <FieldGroup label={t('hub-editor.block-limited-store.background-image')}>
        <Controller
          name="background_image_url"
          control={control}
          rules={{ required: t('validation.required') }}
          render={({ field }) => (
            <ImageUploader
              {...field}
              accept={{
                'image/jpeg': ['.jpeg', '.jpg'],
                'image/png': [],
                'image/webp': [],
                'image/gif': [],
              }}
              bucket={S3Bucket.hub}
            />
          )}
        />
        <ErrorMessage
          name="background_image_url"
          errors={errors}
          render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
        />
      </FieldGroup>

      <FieldGroup label={t('hub-editor.block-limited-store.icon-image')}>
        <Controller
          name="image_url"
          control={control}
          rules={{ required: t('validation.required') }}
          render={({ field }) => (
            <ImageUploader
              {...field}
              accept={{
                'image/jpeg': ['.jpeg', '.jpg'],
                'image/png': [],
                'image/webp': [],
                'image/gif': [],
              }}
              bucket={S3Bucket.hub}
            />
          )}
        />
        <ErrorMessage
          name="image_url"
          errors={errors}
          render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
        />
      </FieldGroup>
    </div>
  )
}
