import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
  AiTranslateOutline,
  MOTION_ANIMATION_SPEED_MD,
  Menu,
  MenuItem,
  MenuItemDivider,
  PopperMenuProps,
  Trash,
  useModal,
} from '@dashboard/ui'
import { LocaleRead, LocaleStatus } from '@/api/dashboard'
import { L10nLocaleModalConfirmTranslateAll, L10nLocaleModalDelete } from '../widgets'

interface LocaleMenuProps extends PopperMenuProps {
  locale: LocaleRead
}

export const LocaleMenu = forwardRef<HTMLDivElement, LocaleMenuProps>(({ locale, onClose, ...rest }, ref) => {
  const { t } = useTranslation()

  const openModalConfirmTranslateAll = useModal<{ locale: LocaleRead }>(props => (
    <L10nLocaleModalConfirmTranslateAll {...props} />
  ))

  const openLocaleModalDelete = useModal<{ locale: LocaleRead }>(props => <L10nLocaleModalDelete {...props} />)

  const onClickTranslateAll = () => {
    onClose?.()
    setTimeout(() => {
      openModalConfirmTranslateAll({ locale })
    }, MOTION_ANIMATION_SPEED_MD * 500)
  }

  const onClickDelete = () => {
    onClose?.()
    setTimeout(() => {
      openLocaleModalDelete({ locale })
    }, MOTION_ANIMATION_SPEED_MD * 500)
  }

  return (
    <Menu {...rest} ref={ref}>
      <MenuItem
        icon={AiTranslateOutline}
        disabled={locale.status === LocaleStatus.Synchronization || locale.status === LocaleStatus.Translating}
        onClick={onClickTranslateAll}
      >
        {t('localization.locale.menu.translate-all')}
      </MenuItem>
      <MenuItemDivider />
      <MenuItem variant="danger" icon={Trash} onClick={onClickDelete}>
        {t('localization.locale.menu.delete')}
      </MenuItem>
    </Menu>
  )
})
