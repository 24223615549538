export enum PaymentStatus {
  done = 'done',
  all = 'all',
  refund_requested = 'refund_requested',
  refunded = 'refunded',
  created = 'created',
  canceled = 'canceled',
  rejected = 'rejected',
  failed = 'failed', // что-то пошло не так в момент оплаты, и он может позде ещё раз попытаться
  captured = 'captured', // начали обрабатывать платёж
}

export const REWARD_POINTS_PAYMENT_METHOD_ID = 'pm_reward_points'
