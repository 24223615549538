import { QueryClient, queryOptions } from '@tanstack/react-query'
import { dashboardClient } from '@/api'
import { LogQuery } from '@/layouts/webhooks/api/types'

export const webhookLogQuery = (companyId: string, gameId: string, webhookId: string, query?: LogQuery) =>
  queryOptions({
    queryKey: [gameId, 'webhooks/log', webhookId, query],
    queryFn: () => dashboardClient.v1.getWebhookLog(companyId, gameId, webhookId, query).then(r => r.data),
  })

export const refetchWebhookLogQuery = async (ql: QueryClient, companyId: string, gameId: string, webhookId: string) => {
  await ql.refetchQueries({
    queryKey: webhookLogQuery(companyId, gameId, webhookId).queryKey.slice(0, -1),
    exact: false,
  })
}
