import { CSSProperties, ReactNode } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import shouldForwardProp from '@emotion/is-prop-valid'
import { useBanner } from '@/libs/hooks/useBanner'
import { cn } from '@/libs/cn'

interface SidebarProps {
  children: ReactNode
  open: boolean
  width?: string
  position?: 'left' | 'right'
  style?: CSSProperties
}

const Tint = styled('div', { shouldForwardProp })<{ $open: SidebarProps['open'] }>`
  position: fixed;
  pointer-events: none;
  inset: 0;
  z-index: 39;
  background-color: ${p => p.theme.palette.action.disabledBackground};
  opacity: 0;
  transition: opacity 0.3s ease;

  ${p =>
    p.$open &&
    css`
      opacity: 1;
    `}
`

const Root = styled('div', { shouldForwardProp })<{ $position: SidebarProps['position']; $open: SidebarProps['open'] }>`
  z-index: 40;
  width: 300px;
  overflow: auto;
  position: fixed;
  background-color: ${p => p.theme.palette.background.content};
  transition: transform 0.3s ease;

  ${p =>
    p.$position === 'right' &&
    css`
      box-shadow: ${p.$open ? '-24px 0 48px -12px rgba(16, 24, 40, 0.18)' : 'none'};
      transform: translateX(${p.$open ? '0' : '100%'});
      right: 0;
    `}

  ${p =>
    p.$position === 'left' &&
    css`
      box-shadow: ${p.$open ? '24px 0 48px -12px rgba(16, 24, 40, 0.18)' : 'none'};
      transform: translateX(${p.$open ? '0' : '-100%'});
      left: 0;
    `}
`
export const DrawerSidebar = ({ children, open, width, position = 'left', style }: SidebarProps) => {
  const { getStickyTop, getStickyHeight } = useBanner()
  return (
    <div>
      <Tint $open={open} />
      <Root
        $position={position}
        $open={open}
        className={cn(getStickyTop(), getStickyHeight())}
        style={{ ...style, width: width }}
      >
        {children}
      </Root>
    </div>
  )
}
