import { useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@dashboard/ui'
import { cn } from '@/libs'
import { useBanner } from '@/libs/hooks/useBanner'
import { canEdit } from '@/security'
import { AuthContext, IAuthContext } from '@/Context'
import { PageHeader, TableRowSkeleton } from '@/ui'
import { EngagementCallout } from '@/layouts/engagement/component'
import { useLoyaltySettings, useLoyaltyTiers } from '@/layouts/engagement/hooks'
import {
  LoyaltyEnabler,
  LoyaltySettings,
  LoyaltySettingsForm,
  LoyaltyTiers,
  TiersConfiguration,
} from '@/layouts/engagement/widgets'
import { DrawerSidebar } from '@/components/ui/DrawerSidebar'

export const LoyaltyPage = () => {
  const { isLoading: isSettingsLoading, settings } = useLoyaltySettings()
  const { isLoading: isTiersLoading, tiers } = useLoyaltyTiers(settings?.id)

  const [isTiersPreview, setIsTiersPreview] = useState<boolean>(true)
  const [buttonsConfig, setButtonsConfig] = useState<TiersConfiguration['buttons']>()
  const configure = useCallback(
    (cfg: TiersConfiguration) => {
      setIsTiersPreview(cfg.isPreview)
      setButtonsConfig(cfg.buttons)
    },
    [setIsTiersPreview, setButtonsConfig],
  )
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const context = useContext(AuthContext) as IAuthContext
  const editable = canEdit(context.customer)
  const { getStickyTop } = useBanner()
  const { t } = useTranslation()

  return (
    <div className="flex size-full flex-col">
      <PageHeader
        sticky={true}
        extra={
          editable &&
          (isTiersPreview ? (
            <Button {...buttonsConfig?.edit} size="sm">
              {t('loyalty.level.edit')}
            </Button>
          ) : (
            <>
              <Button {...buttonsConfig?.cancel} variant="outline" color="secondary" size="sm">
                {t('loyalty.level.cancel')}
              </Button>
              <Button {...buttonsConfig?.submit} size="sm">
                {t('loyalty.level.save')}
              </Button>
            </>
          ))
        }
      >
        {t('sidebar.loyalty')}
      </PageHeader>

      <div className="relative flex grow gap-6">
        <div className="w-2/3">
          {isSettingsLoading || isTiersLoading ? (
            <TableRowSkeleton columnCount={1} rowCount={3} />
          ) : (
            <LoyaltyTiers
              interactive={editable}
              configure={configure}
              values={{
                settingsID: settings.id,
                tiers: tiers.map(tier => ({ ...tier, uid: tier.id })),
              }}
            />
          )}
        </div>
        <div className={cn(getStickyTop(), `sticky top-[60px] z-30 h-fit w-1/3 space-y-4`)}>
          {isSettingsLoading ? (
            <TableRowSkeleton columnCount={1} rowCount={1} />
          ) : (
            settings && (
              <>
                {editable && (
                  <LoyaltyEnabler
                    settingsID={settings.id}
                    disabled={tiers.length === 0}
                    state={!!settings.activated_at}
                  />
                )}
                <LoyaltySettings interactive={editable} settings={settings} onEditClick={() => setIsEdit(true)} />
                <EngagementCallout title={t('loyalty.info.title')} desc={t('loyalty.info.description')} />
              </>
            )
          )}
        </div>
      </div>
      {settings && editable && (
        <DrawerSidebar open={isEdit} width="720px" position="right">
          <LoyaltySettingsForm uid={settings.id} settings={settings} onClose={() => setIsEdit(false)} />
        </DrawerSidebar>
      )}
    </div>
  )
}
