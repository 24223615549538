import { ReactNode } from 'react'
import { cn } from '@/libs'

interface TabsProps {
  children: ReactNode
  className?: string
}

export const Tabs = ({ children, className }: TabsProps) => (
  <div className={cn('mb-5 flex items-center justify-center gap-1.5 p-0.5', className)}>{children}</div>
)
