import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const User: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 17 17" ref={ref}>
      <path
        d="M8.50001 1.20825C4.19624 1.20825 0.708344 4.69615 0.708344 8.99992C0.708344 13.3037 4.19624 16.7916 8.50001 16.7916C12.8038 16.7916 16.2917 13.3037 16.2917 8.99992C16.294 4.69615 12.8038 1.20825 8.50001 1.20825ZM8.50001 4.32445C9.79044 4.32445 10.8377 5.37176 10.8377 6.66219C10.8377 7.95261 9.79044 8.99992 8.50001 8.99992C7.20958 8.99992 6.16228 7.95261 6.16228 6.66219C6.16228 5.37176 7.20958 4.32445 8.50001 4.32445ZM12.2474 13.0138C11.1814 13.7455 9.89096 14.1733 8.50235 14.1733C7.11373 14.1733 5.82097 13.7455 4.75496 13.0138C4.28508 12.6912 4.08403 12.074 4.35755 11.5738C4.92562 10.5358 6.09214 9.88826 8.50235 9.88826C10.9126 9.88826 12.0791 10.5358 12.6472 11.5738C12.9183 12.0764 12.7173 12.6912 12.2474 13.0138Z"
        fill="currentColor"
      />
    </IconBase>
  );
});
