import { useQuery } from '@tanstack/react-query'
import { dashboardClient } from '@/api'

export const useAllPluginQuery = (companyId: string, gameId: string) => {
  return useQuery({
    queryKey: ['plugins', gameId],
    queryFn: () => dashboardClient.v1.getAllPlugins(companyId, gameId),
    select: response => response.data,
  })
}

export const usePluginQuery = (companyId: string, gameId: string, slug: string) => {
  return useQuery({
    queryKey: ['plugin', gameId],
    queryFn: () => dashboardClient.v1.getPluginBySlug(companyId, gameId, slug),
    select: response => response.data,
  })
}

type Query = {
  /** Enabled */
  enabled?: boolean
}

export const usePluginInstancesQuery = (companyId: string, gameId: string, query = {} as Query) => {
  return useQuery({
    queryKey: ['plugins_instance', gameId, query],
    queryFn: () => dashboardClient.v1.getPluginsByState(companyId, gameId, query),
    select: response => response.data,
  })
}
