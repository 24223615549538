import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Role } from '@/api/dashboard'
import { isAdmin } from '@/security'
import { HOME_PATH, useGenerateCompanyPath } from '@/libs'
import { AghanimLogotype } from '@/components/ui'
import { GetStartedCallout } from '@/layouts/get-started'
import { SidebarCompanySwitch } from './SidebarCompanySwitch'
import { SidebarGameSwitch } from './SidebarGameSwitch'
import { SidebarLink } from './SidebarLink'
import { SidebarSection } from './SidebarSection'
import { getFooterItems, getHubItems, getPrimaryItems } from './MenuData'
import { AuthContext, IAuthContext } from '@/Context'
import { useGameSettingsQuery } from '@/api/useGameSettingsQuery'
import { useWebsitesQuery } from '@/api/useWebsitesQuery'
import { MenuList } from './MenuList'
import { Dashboard } from './icons'
import { useModal } from '../Modal'
import { CreateGameModalConfirm } from './CreateGameModalConfirm'
import { useBanner } from '@/libs/hooks/useBanner'
import { useFeatureFlags } from '@/libs/hooks/useFeatureFlags'
import { useCompaniesQuery } from '@/api/useCompaniesQuery'
import { useHubGeneratingStatus } from '@/layouts/get-started/hooks'

export const AppSidebar = () => {
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const { data: dataCompanies } = useCompaniesQuery()
  const { bannerMode } = useBanner()
  const context = useContext(AuthContext) as IAuthContext
  const isAdm = isAdmin(context.customer)
  const { t, ready } = useTranslation()
  const { generatePath } = useGenerateCompanyPath()
  const { data: settings } = useGameSettingsQuery(companyId, gameId)
  const { data: websites = [] } = useWebsitesQuery(companyId, gameId)
  const { featureFlags } = useFeatureFlags()

  const defaultWebsiteUrl = websites.length ? websites[0]?.url : undefined
  const navigate = useNavigate()
  const { isHubGenerated } = useHubGeneratingStatus()

  const primaryItems = useMemo(() => {
    return getPrimaryItems(context.customer, generatePath, t, featureFlags)
  }, [settings, context.customer, gameId, ready, featureFlags])

  const hubItems = useMemo(() => {
    return getHubItems(context.customer, generatePath, t, defaultWebsiteUrl, !isHubGenerated)
  }, [settings, context.customer, gameId, defaultWebsiteUrl, isHubGenerated, ready])

  const footerItems = useMemo(() => {
    return getFooterItems(context.customer, generatePath, t)
  }, [settings, context.customer, gameId, ready])

  const openCreateGameModal = useModal(props => <CreateGameModalConfirm {...props} />)

  const onAddGameClick = () => {
    let companies = dataCompanies?.data || []
    companies = companies.filter(it => it.role == Role.Admin)

    if (companies.length == 0) {
      navigate('/onboarding', { state: { company_id: null } })
    } else {
      openCreateGameModal()
    }
  }

  return (
    <nav className="flex h-full flex-col overflow-y-auto bg-linear-bg-gradient py-3">
      <div className="mx-3 mb-3 flex items-center gap-2.5">
        <div className="shrink-0">
          <AghanimLogotype />
        </div>
        <SidebarGameSwitch onAddGameClick={() => onAddGameClick()} />
      </div>

      {isAdm && !bannerMode && <GetStartedCallout />}

      <SidebarSection>
        <SidebarLink to={generatePath(HOME_PATH)} end={true} icon={Dashboard}>
          {t('sidebar.home')}
        </SidebarLink>

        <MenuList items={primaryItems} />
      </SidebarSection>

      {hubItems.length > 0 && (
        <SidebarSection label={t('sidebar.section.hub')}>
          <MenuList items={hubItems} />
        </SidebarSection>
      )}

      <SidebarSection footer={true}>
        <MenuList items={footerItems} />
        <div className="mx-3 my-2.5 h-px bg-border-secondary" />
        <SidebarCompanySwitch />
      </SidebarSection>
    </nav>
  )
}
