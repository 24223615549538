import { AnimationProps, Transition, Variant } from 'framer-motion'

type TypedVariants = {
  [key in 'initial' | 'animate' | 'exit']: Variant
}

/**
 * Default motion duration in milliseconds.
 */
export const DEFAULT_MOTION_DURATION = 150

/**
 * This function will return props for the motion element.
 * @param variants
 * @param transition
 */
export const getMotionProps = (
  variants: TypedVariants,
  transition: Transition = { duration: DEFAULT_MOTION_DURATION / 1000, type: 'tween' },
): AnimationProps => ({
  variants,
  initial: 'initial',
  animate: 'animate',
  exit: 'exit',
  transition,
})
