import { useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@dashboard/ui'
import { cn, useBanner } from '@/libs'
import { canEdit } from '@/security'
import { AuthContext, IAuthContext } from '@/Context'
import { PageHeader, TableRowSkeleton } from '@/ui'
import { useDailyRewards } from '@/layouts/engagement/hooks'
import {
  DailyRewardsDays,
  DailyRewardsEnabler,
  DailyRewardsSettings,
  DailyRewardsSettingsForm,
  DailyRewardsZeroState,
  RewardsConfiguration,
} from '@/layouts/engagement/widgets'
import { EngagementCallout } from '@/layouts/engagement/component'
import { DrawerSidebar } from '@/components/ui/DrawerSidebar'

export const DailyRewardsPage = () => {
  const { t } = useTranslation()
  const { isLoading, dailyRewards } = useDailyRewards()
  const { getStickyTop } = useBanner()
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [isPreview, setIsPreview] = useState<boolean>(true)
  const [buttonsConfig, setButtonsConfig] = useState<RewardsConfiguration['buttons']>()
  const configure = useCallback(
    (cfg: RewardsConfiguration) => {
      setIsPreview(cfg.isPreview)
      setButtonsConfig(cfg.buttons)
    },
    [setIsPreview, setButtonsConfig],
  )
  const isZero = !isLoading && (!dailyRewards || dailyRewards.length === 0)
  const settings = dailyRewards.length !== 0 && dailyRewards[0]

  const context = useContext(AuthContext) as IAuthContext
  const editable = canEdit(context.customer)

  return (
    <div className="flex size-full flex-col">
      <PageHeader
        sticky
        extra={
          editable &&
          (isPreview ? (
            <Button {...buttonsConfig?.edit} size="sm" disabled={buttonsConfig?.edit?.disabled || isZero}>
              {t('daily-rewards.rewards.edit')}
            </Button>
          ) : (
            <>
              <Button {...buttonsConfig?.cancel} variant="outline" color="secondary" size="sm">
                {t('daily-rewards.rewards.cancel')}
              </Button>
              <Button {...buttonsConfig?.submit} size="sm">
                {t('daily-rewards.rewards.save')}
              </Button>
            </>
          ))
        }
      >
        {t('sidebar.daily-rewards')}
      </PageHeader>

      <div className="relative flex grow gap-6">
        {isZero ? (
          <DailyRewardsZeroState interactive={editable} />
        ) : (
          <>
            <div className="w-2/3">
              {isLoading ? (
                <TableRowSkeleton columnCount={1} rowCount={3} />
              ) : (
                settings && (
                  <DailyRewardsDays
                    interactive={editable}
                    configure={configure}
                    values={{
                      settingsID: settings.id,
                      program: {
                        daily: settings.rewards.daily_rewards,
                        extra: settings.rewards.extra_reward_items || [],
                      },
                    }}
                  />
                )
              )}
            </div>

            <div className={cn(getStickyTop(), `sticky top-[60px] h-fit w-1/3 space-y-4`)}>
              {isLoading ? (
                <TableRowSkeleton columnCount={1} rowCount={1} />
              ) : (
                settings && (
                  <>
                    {editable && (
                      <DailyRewardsEnabler
                        settingsID={settings.id}
                        disabled={settings.rewards.daily_rewards.length === 0}
                        state={settings.enabled}
                      />
                    )}
                    <DailyRewardsSettings
                      interactive={editable}
                      settings={settings}
                      onEditClick={() => setIsEdit(true)}
                    />
                    <EngagementCallout
                      title={t('daily-rewards.info.title')}
                      desc={t('daily-rewards.info.description')}
                    />
                  </>
                )
              )}
            </div>
          </>
        )}
      </div>
      {settings && editable && (
        <DrawerSidebar open={isEdit} width="720px" position="right">
          <DailyRewardsSettingsForm uid={settings.id} settings={settings} onClose={() => setIsEdit(false)} />
        </DrawerSidebar>
      )}
    </div>
  )
}
