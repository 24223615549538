import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const XTwitterIcon: AghanimIcon = forwardRef(function (props, ref) {
  return (
    <IconBase {...props} viewBox="0 0 48 48" ref={ref}>
      <path
        d="M0 16.1C0 10.4051 0 7.55764 1.12587 5.39026C2.07462 3.56382 3.56382 2.07462 5.39026 1.12587C7.55764 0 10.4051 0 16.1 0H31.9C37.5949 0 40.4424 0 42.6097 1.12587C44.4362 2.07462 45.9254 3.56382 46.8741 5.39026C48 7.55764 48 10.4051 48 16.1V31.9C48 37.5949 48 40.4424 46.8741 42.6097C45.9254 44.4362 44.4362 45.9254 42.6097 46.8741C40.4424 48 37.5949 48 31.9 48H16.1C10.4051 48 7.55764 48 5.39026 46.8741C3.56382 45.9254 2.07462 44.4362 1.12587 42.6097C0 40.4424 0 37.5949 0 31.9V16.1Z"
        fill="black"
      />
      <path
        d="M12 13L20.7314 25.3941L12.4591 35H15.7084L22.1855 27.459L27.4969 35H36L26.8672 22.0139L34.6085 13H31.4096L25.418 19.9514L20.5295 13H12ZM16.725 15.4444H19.2461L31.2774 32.5556H28.7779L16.725 15.4444Z"
        fill="white"
      />
    </IconBase>
  );
});
