import { useLocation, useNavigate } from 'react-router-dom'
import { ModalRushPage } from '@/ui'
import { SKU_BUNDLE_ITEMS_PATH, SKU_ITEMS_PATH, useGenerateCompanyPath } from '@/libs'
import { SkuSettingsModal } from '../widgets/SkuSettingsModal'

export const SkuSettingsPage = () => {
  const navigate = useNavigate()
  const { generatePath } = useGenerateCompanyPath()
  const location = useLocation()

  return (
    <ModalRushPage
      back={{
        to: generatePath(location.state?.isBundle ? SKU_BUNDLE_ITEMS_PATH : SKU_ITEMS_PATH),
        onClick: () => {
          navigate(generatePath(location.state?.isBundle ? SKU_BUNDLE_ITEMS_PATH : SKU_ITEMS_PATH))
        },
      }}
    >
      {props => <SkuSettingsModal {...props} />}
    </ModalRushPage>
  )
}
