import i18next from 'i18next'

import { ABTestPoint } from '../../../../api/dashboard'
import { Table, TableCell, TableRow } from '@/ui'
import { formatMoney, formatNumber } from '../../../../util'
import CenteredProgress from '../../../../components/CenteredProgress'
import { useContext } from 'react'
import { CampaignContext, ICampaignContext } from '../../Context'

function getColor(a: number, b: number) {
  if (a > b * 1.2) {
    return '#17B26A'
  }
  if (b > a * 1.2) {
    return '#F04438'
  }
  return ''
}

const ABTestTable = (props: { data: ABTestPoint[]; loading: boolean }) => {
  const context = useContext(CampaignContext) as ICampaignContext

  const getDesc = (event_node_id: string | undefined) => {
    if (!context.graph?.nodes || !event_node_id) {
      return ''
    }
    const node = context.graph.nodes[event_node_id]
    return node?.description || ''
  }

  return (
    <div
      className="mt-6 rounded-xl border border-border-secondary p-6"
      style={{ boxShadow: '0px 1px 2px 0px #1018280D' }}
    >
      <Table>
        <TableRow variant="header">
          <TableCell width="300%">{i18next.t('campaign.analytics.event')}</TableCell>
          <TableCell width="35%">
            <div className="flex  justify-start">{i18next.t('campaign.analytics.count')}</div>
          </TableCell>
          <TableCell width="35%">
            <div className="flex  justify-start">{i18next.t('campaign.analytics.conversion')}</div>
          </TableCell>
          <TableCell width="35%">
            <div>{i18next.t('campaign.analytics.revenue')}</div>
          </TableCell>
        </TableRow>
        {props.loading && (
          <div className="mt-2">
            <CenteredProgress />
          </div>
        )}
        {props.data.length == 0 && !props.loading && (
          <div className="py-6 text-center font-semibold">{i18next.t('campaign.analytics.no-data')}</div>
        )}
        {props.data.map((it, idx) => (
          <TableRow key={it.event_node_id}>
            <TableCell width="300%">
              <span className="font-semibold">
                A/B Test ({idx + 1}) {getDesc(it.event_node_id)}
              </span>
            </TableCell>
            <TableCell width="35%">
              <div className="flex  justify-start text-text-tertiary">
                <table>
                  <tbody>
                    <tr>
                      <td style={{ color: getColor(it.A_count, it.B_count) }}>A: {formatNumber(it.A_count)}</td>
                    </tr>
                    <tr>
                      <td style={{ color: getColor(it.B_count, it.A_count) }}>B: {formatNumber(it.B_count)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </TableCell>
            <TableCell width="35%">
              <div className="flex  justify-start text-text-tertiary">
                <table>
                  <tbody>
                    <tr>
                      <td style={{ color: getColor(it.A_conversion, it.B_conversion) }}>
                        A: {it.A_conversion == 0 ? 0 : (100 * it.A_conversion).toFixed(4)}%
                      </td>
                    </tr>
                    <tr>
                      <td style={{ color: getColor(it.B_conversion, it.A_conversion) }}>
                        B: {it.B_conversion == 0 ? 0 : (100 * it.B_conversion).toFixed(4)}%
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </TableCell>
            <TableCell width="35%">
              <div className="flex  justify-start text-text-tertiary">
                <table>
                  <tbody>
                    <tr>
                      <td style={{ color: getColor(it.A_revenue, it.B_revenue) }} className="whitespace-nowrap">
                        A: {formatMoney(it.A_revenue)}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ color: getColor(it.B_revenue, it.A_revenue) }} className="whitespace-nowrap">
                        B: {formatMoney(it.B_revenue)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </div>
  )
}

export default ABTestTable
