import { queryOptions } from '@tanstack/react-query'
import { dashboardClientV1 } from '@/api'

export const creatorsGetTermsQueryOptions = (token: string) =>
  queryOptions({
    queryKey: ['creators-terms', token],
    queryFn: () =>
      dashboardClientV1.v1
        .creatorGetTerms({
          headers: {
            'X-Creator-Token': token,
          },
        })
        .then(r => r.data),
    staleTime: 60000,
  })
