import { Checkbox } from '../../Checkbox'
import { TableCell } from '../TableCell'

/**
 * @deprecated please use `TableCellBulkCheckbox` & `useBulk`.
 * @param selectedSet
 * @param setSelectedSet
 * @param id
 * @constructor
 */
export const TableCellCheckBox = ({
  selectedSet,
  setSelectedSet,
  id,
}: {
  selectedSet: Set<string>
  setSelectedSet: (v: Set<string>) => void
  id: string
}) => {
  return (
    <TableCell
      width={32}
      onChange={() => {
        //do nothing
      }}
      onClick={e => {
        e.stopPropagation()
        e.preventDefault()

        if (!selectedSet.has(id)) {
          setSelectedSet(new Set(selectedSet).add(id))
        } else {
          let s = new Set(selectedSet)
          s.delete(id)
          setSelectedSet(s)
        }
      }}
    >
      {/* please use TableCellBulkCheckbox & useBulk. */}
      <Checkbox checked={selectedSet.has(id)} />
    </TableCell>
  )
}
