import { useTranslation } from 'react-i18next'

export const CreatorErrorPage = () => {
  const { t } = useTranslation()

  return (
    <div
      style={{
        width: '690px',
        height: '360px',
        boxShadow: '0px 24px 48px -12px #1018282E',
        background: 'linear-gradient(273.02deg, rgba(255, 255, 255, 0) 5.02%, #FFFFFF 94.98%)',
        zIndex: 1,
      }}
      className="flex flex-col items-center justify-center gap-10 rounded-[36px] bg-fg-primary p-12"
    >
      <span className="text-center text-[36px] font-semibold" style={{ lineHeight: '43px' }}>
        {t('creator.error-state.title')}
      </span>
      <span className="text-center text-[18px] text-text-secondary">{t('creator.error-state.desc')}</span>
    </div>
  )
}
