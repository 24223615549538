import { useTranslation } from 'react-i18next'

import { DailyRewardMode, DailyRewardRead } from '@/api/dashboard'
import { SettingsSwitch } from '@/icons'
import { Button, FieldGroup, TooltipAlertCircle } from '@/ui'
import { EngagementSummaryCard } from '@/layouts/engagement/component'

interface DailyRewardsSettingsProps {
  interactive?: boolean
  settings: DailyRewardRead
  onEditClick: () => void
}

export const DailyRewardsSettings = ({ interactive = false, settings, onEditClick }: DailyRewardsSettingsProps) => {
  const { t } = useTranslation()

  return (
    <div>
      <EngagementSummaryCard
        title={{ text: t('daily-rewards.summary.title') }}
        action={
          interactive && (
            <Button onClick={onEditClick}>
              <div className="inline-flex items-center gap-2 text-text-primary">
                <SettingsSwitch />
                <span>{t('daily-rewards.summary.edit')}</span>
              </div>
            </Button>
          )
        }
        fields={[
          {
            title: { text: t('daily-rewards.summary.name') },
            content: <FieldGroup size="sm">{settings.name}</FieldGroup>,
          },
          {
            title: { text: t('daily-rewards.summary.description') },
            content: (
              <FieldGroup size="sm">
                <div
                  dangerouslySetInnerHTML={{ __html: settings.description }}
                  className="max-h-[400px] overflow-auto"
                />
              </FieldGroup>
            ),
          },
        ]}
        settings={[
          {
            name: t('daily-rewards.summary.mode'),
            value: (
              <div className="inline-flex justify-center gap-1.5">
                {settings.mode === DailyRewardMode.Soft && (
                  <>
                    <TooltipAlertCircle message={t('daily-rewards.summary.mode.soft.tooltip')} placement="top" />
                    <span>{t('daily-rewards.summary.mode.soft')}</span>
                  </>
                )}
                {settings.mode === DailyRewardMode.Hard && (
                  <>
                    <TooltipAlertCircle message={t('daily-rewards.summary.mode.hard.tooltip')} placement="top" />
                    <span>{t('daily-rewards.summary.mode.hard')}</span>
                  </>
                )}
              </div>
            ),
          },
          {
            name: t('daily-rewards.summary.repeatable'),
            value: settings.repeatable
              ? t('daily-rewards.summary.repeatable.every-period')
              : t('daily-rewards.summary.repeatable.one-time'),
          },
        ]}
      />
    </div>
  )
}
