import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '../IconBase'

export const EditClock: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 24 24" ref={ref}>
      <path
        d="M22.1036 13.4781C21.4941 12.8406 20.506 12.8406 19.8965 13.4781C19.1974 14.2094 18.4984 14.9408 17.7995 15.6723C16.3955 17.1418 14.9914 18.6112 13.5857 20.0789C13.4417 20.2292 13.3147 20.3617 13.2211 20.5215C13.1388 20.6619 13.0782 20.815 13.0415 20.975C12.9997 21.1573 12.9998 21.3449 13 21.5577C13.0002 21.7654 13.0002 21.973 13.0001 22.1807C13.0001 22.2795 13.0001 22.3782 13.0001 22.477C13.0001 22.7658 13.2239 22.9999 13.5 22.9999C13.793 22.9999 14.086 22.9997 14.379 23C14.5824 23.0002 14.7618 23.0003 14.936 22.9566C15.0891 22.9182 15.2354 22.8548 15.3696 22.7688C15.5224 22.6708 15.6491 22.538 15.7928 22.3874C17.1963 20.9167 18.6015 19.4478 20.0068 17.9789C20.7058 17.2482 21.4048 16.5175 22.1036 15.7866C22.7131 15.1491 22.7131 14.1156 22.1036 13.4781Z"
        fill="currentColor"
      />
      <path
        d="M18 21.954C17.7239 21.954 17.5 22.1881 17.5 22.477C17.5 22.7658 17.7239 23 18 23H22.5C22.7761 23 23 22.7658 23 22.477C23 22.1881 22.7761 21.954 22.5 21.954H18Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 5.59998C12.5523 5.59998 13 6.04769 13 6.59998V11.3819L16.0472 12.9055C16.5412 13.1525 16.7414 13.7532 16.4944 14.2472C16.2474 14.7412 15.6468 14.9414 15.1528 14.6944L11.5528 12.8944C11.214 12.725 11 12.3787 11 12V6.59998C11 6.04769 11.4477 5.59998 12 5.59998Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.85401 1.21137C11.9878 0.786929 14.1995 1.00477 16.2095 1.83733C18.2195 2.66989 19.9375 4.07979 21.1462 5.88873C22.2621 7.55886 22.8977 9.49988 22.9886 11.5001C23.0012 11.776 22.7761 12 22.5 12H21.5C21.2239 12 21.0015 11.776 20.9861 11.5003C20.8969 9.89577 20.3794 8.34106 19.4832 6.99987C18.4943 5.51983 17.0887 4.36628 15.4442 3.68509C13.7996 3.0039 11.99 2.82567 10.2442 3.17294C8.49836 3.5202 6.89472 4.37737 5.63604 5.63604C4.37737 6.89472 3.5202 8.49836 3.17294 10.2442C2.82567 11.99 3.0039 13.7996 3.68509 15.4442C4.36628 17.0887 5.51983 18.4943 6.99987 19.4832C8.34106 20.3794 9.89577 20.8969 11.5003 20.9861C11.776 21.0015 12 21.2239 12 21.5V22.5C12 22.7761 11.776 23.0012 11.5001 22.9886C9.49988 22.8977 7.55886 22.2621 5.88873 21.1462C4.07979 19.9375 2.66989 18.2195 1.83733 16.2095C1.00477 14.1995 0.786929 11.9878 1.21137 9.85401C1.6358 7.72022 2.68345 5.76021 4.22183 4.22183C5.76021 2.68345 7.72022 1.6358 9.85401 1.21137Z"
        fill="currentColor"
      />
    </IconBase>
  )
})
