import {
  Button,
  ButtonIcon,
  FieldGroup,
  Input,
  Modal,
  ModalFooter,
  ModalProps,
  ModalTitle,
  Table,
  TableCell,
  TableRow,
} from '@/ui'
import { useTranslation } from 'react-i18next'
import { Plus, Trash } from '@/icons'
import { KeyValue } from '@/types'
import { useState } from 'react'
import i18next from 'i18next'
import { NewsCategory } from '@/api/dashboard'
import { Select } from '@dashboard/ui'

export interface EditDictModalProps extends ModalProps {
  selectorType: string //'NewsCategory'
  propertyName: string
  onChange: (value: KeyValue) => void
  value: KeyValue
}

export const EditDictModal = (props: EditDictModalProps) => {
  const { t } = useTranslation()
  const [items, setItems] = useState<KeyValue>(props.value || {})
  const [keyValue, setKeyValue] = useState<string>('')
  const [value, setValue] = useState<string>('')

  const saveClick = () => {
    let data = items
    if (keyValue.trim() && value.trim()) {
      data = { ...items, [keyValue.trim()]: value.trim() }
    }
    props.onChange(data)
    props.onClose?.()
  }

  const onAddItem = () => {
    if (keyValue.trim() && value.trim()) {
      setItems({ ...items, [keyValue.trim()]: value.trim() })
      setValue('')
      setKeyValue('')
    }
  }

  const onRemoveItem = (deletingKey: string) => {
    const newItems = { ...items }
    delete newItems[deletingKey]
    setItems(newItems)
  }

  const renderValueEditor = () => {
    if (props.selectorType === 'NewsCategory') {
      return (
        <Select
          onChange={v => setValue(v as string)}
          value={value}
          items={[
            {
              children: i18next.t('edit-news-dialog.category.company_news'),
              value: NewsCategory.CompanyNews,
            },
            {
              children: i18next.t('edit-news-dialog.category.maintenance'),
              value: NewsCategory.Maintenance,
            },
            { children: i18next.t('edit-news-dialog.category.bugfix'), value: NewsCategory.Bugfix },
            { children: i18next.t('edit-news-dialog.category.update'), value: NewsCategory.Update },
          ]}
        />
      )
    }

    return (
      <Input
        size="sm"
        onChange={e => setValue(e.target.value)}
        value={value}
        onKeyPress={e => e.key === 'Enter' && onAddItem()}
      />
    )
  }

  const renderValue = (v: string) => {
    if (props.selectorType === 'NewsCategory') {
      return t(`edit-news-dialog.category.${v}`)
    }

    return v
  }

  return (
    <Modal onClose={props.onClose}>
      <ModalTitle>{props.propertyName}</ModalTitle>

      <div className="mt-[18px]">
        <FieldGroup label={t('plugin.dict.new-value')}>
          <div className="flex items-center gap-2">
            <Input size="sm" autoFocus onChange={e => setKeyValue(e.target.value)} value={keyValue} />
            {renderValueEditor()}
            <ButtonIcon size="sm" variant="secondary-gray" onClick={onAddItem} className="h-[36px] min-w-[36px]">
              <Plus size={14} />
            </ButtonIcon>
          </div>
        </FieldGroup>

        {Object.keys(items).length > 0 && (
          <Table className="mt-3 rounded-sm border border-border-secondary">
            {Object.entries(items).map(([key, value]) => (
              <TableRow key={key}>
                <TableCell>{key}</TableCell>
                <TableCell>{renderValue(value as string)}</TableCell>
                <TableCell width={48}>
                  <ButtonIcon variant="tertiary-destructive" size="sm" onClick={() => onRemoveItem(key)}>
                    <Trash />
                  </ButtonIcon>
                </TableCell>
              </TableRow>
            ))}
          </Table>
        )}
      </div>

      <ModalFooter className="sticky bottom-0">
        <Button onClick={props.onClose}>{t('Cancel')}</Button>
        <Button variant="primary" onClick={saveClick}>
          {t('Apply')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
