import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const ImageOutline: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 24 24" ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 8.5C6 6.84315 7.34315 5.5 9 5.5C10.6569 5.5 12 6.84315 12 8.5C12 10.1569 10.6569 11.5 9 11.5C7.34315 11.5 6 10.1569 6 8.5ZM9 7.5C8.44772 7.5 8 7.94772 8 8.5C8 9.05228 8.44772 9.5 9 9.5C9.55228 9.5 10 9.05228 10 8.5C10 7.94772 9.55228 7.5 9 7.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 18C22 20.2091 20.2091 22 18 22H6C5.86193 22 5.72549 21.993 5.59102 21.9793C3.574 21.7745 2 20.0711 2 18V6C2 5.86193 2.007 5.72549 2.02065 5.59102C2.22549 3.574 3.92893 2 6 2H18C20.2091 2 22 3.79086 22 6V18ZM4.01033 5.79551C4.0035 5.86275 4 5.93096 4 6V15.5858L7 12.5858L10 15.5858L16 9.58579L20 13.5858V6C20 4.89543 19.1046 4 18 4H6C4.96447 4 4.11275 4.787 4.01033 5.79551ZM20 16.4142L16 12.4142L10 18.4142L7 15.4142L4.03576 18.3785C4.21265 19.3021 5.02482 20 6 20H18C19.1046 20 20 19.1046 20 18V16.4142Z"
        fill="currentColor"
      />
    </IconBase>
  );
});
