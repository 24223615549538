import { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import i18next from 'i18next'
import { dashboardClient, getErrorText } from '@/api'
import { CompanyGame } from '@/api/dashboard'
import { addCompanyToCache, useCompaniesQuery } from '@/api/useCompaniesQuery'
import { ToastSeverity, useToast } from '@/ui'
import { CompanySettingsForm, CompanySettingsFormSkeleton } from '../components'

export const CompanySettingsPage = () => {
  const queryClient = useQueryClient()
  const showToast = useToast()
  const { companyId } = useParams() as { companyId: string }
  const { data: dataCompanies, isLoading } = useCompaniesQuery()

  const company = useMemo(() => dataCompanies?.data?.find(c => c.id === companyId), [dataCompanies, companyId])

  const onSubmit = useCallback(
    async (data: CompanyGame) => {
      try {
        await dashboardClient.v1.updateCompany(companyId, data)
        addCompanyToCache(queryClient, {
          ...data,
        })
        showToast({ message: i18next.t('saved'), severity: ToastSeverity.success })
      } catch (e) {
        showToast({ message: getErrorText(e), severity: ToastSeverity.error })
      }
    },
    [showToast],
  )

  if (isLoading || !company) {
    return <CompanySettingsFormSkeleton />
  }

  return <CompanySettingsForm company={company} onSubmit={onSubmit} />
}
