import { ButtonHTMLAttributes, ReactNode, forwardRef } from 'react';
import { cva } from 'class-variance-authority';
import { ChevronDownOutline } from '../../icons';
import { cn } from '../../libs';

export interface SelectBaseButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * Children to be displayed in the select.
   */
  children?: ReactNode;
  /**
   * Placeholder to be displayed when no item is selected.
   */
  placeholder?: string;
  /**
   * Disables the select.
   */
  disabled?: boolean;
}

const variants = cva(
  [
    'group/select-base-button',
    'flex h-9 w-full items-center gap-3 overflow-hidden rounded-md border border-border-primary bg-fg-primary text-caption-md leading-4 shadow-xs outline-none transition-colors',
    'hover:border-border-primary-hover',
    'focus-visible:border-border-brand',
    'focus-visible:ring-brand',
    'focus-visible:ring-2',
    'focus-visible::[--chevron-color:theme(colors.text-brand-primary)]',
    '[&[data-open="true"]]:border-border-brand',
    '[&[data-open="true"]]:ring-brand',
    '[&[data-open="true"]]:ring-2',
    'disabled:pointer-events-none',
    'disabled:shadow-none',
  ],
  {
    variants: {
      variant: {
        empty: [
          '[--icon-color:theme(colors.text-quarterary)]',
          '[--label-color:theme(colors.text-quarterary)]',
          '[--extra-color:theme(colors.text-quarterary-hover)]',
          '[--chevron-color:theme(colors.text-quarterary)]',
          'hover:[--icon-color:theme(colors.text-quarterary-hover)]',
          'hover:[--label-color:theme(colors.text-quarterary-hover)]',
          'hover:[--extra-color:theme(colors.text-quarterary-hover)]',
          'hover:[--chevron-color:theme(colors.text-quarterary-hover)]',
          '[&[data-open="true"]]:[--icon-color:theme(colors.text-quarterary-hover)]',
          '[&[data-open="true"]]:[--label-color:theme(colors.text-quarterary-hover)]',
          '[&[data-open="true"]]:[--extra-color:theme(colors.text-quarterary-hover)]',
          '[&[data-open="true"]]:[--chevron-color:theme(colors.text-brand-primary)]',

          'disabled:[--icon-color:theme(colors.text-disabled)]',
          'disabled:[--label-color:theme(colors.text-disabled)]',
          'disabled:[--extra-color:theme(colors.text-disabled)]',
          'disabled:[--chevron-color:theme(colors.text-disabled)]',
          'disabled:bg-fg-disabled',
          'disabled:border-border-disabled',
        ],
        filled: [
          '[--icon-color:theme(colors.text-secondary)]',
          '[--label-color:theme(colors.text-secondary)]',
          '[--extra-color:theme(colors.text-quarterary-hover)]',
          '[--chevron-color:theme(colors.text-quarterary)]',
          'hover:[--chevron-color:theme(colors.text-quarterary-hover)]',
          '[&[data-open="true"]]:[--icon-color:theme(colors.text-primary)]',
          '[&[data-open="true"]]:[--label-color:theme(colors.text-primary)]',
          '[&[data-open="true"]]:[--extra-color:theme(colors.text-tertiary)]',
          '[&[data-open="true"]]:[--chevron-color:theme(colors.text-brand-primary)]',

          'disabled:[--icon-color:theme(colors.text-disabled)]',
          'disabled:[--label-color:theme(colors.text-disabled)]',
          'disabled:[--extra-color:theme(colors.text-disabled)]',
          'disabled:[--chevron-color:theme(colors.text-disabled)]',
          'disabled:bg-fg-disabled',
          'disabled:border-border-disabled',
        ],
      },
      defaultVariants: {
        variant: 'empty',
      },
    },
  },
);

export const SelectBaseButton = forwardRef<HTMLButtonElement, SelectBaseButtonProps>(
  ({ children, placeholder, ...rest }, ref) => (
    <button {...rest} ref={ref} className={cn(variants({ variant: children ? 'filled' : 'empty' }))} type="button">
      <div className="flex size-full items-center gap-1.5 truncate px-3">
        {children || <span className="truncate text-[--label-color] transition-colors">{placeholder}</span>}
      </div>
      <ChevronDownOutline
        className={cn(
          'ml-auto mr-3 shrink-0 text-[--chevron-color] transition-[color_transform]',
          'group-data-[open="true"]/select-base-button:rotate-180',
        )}
        size={16}
      />
    </button>
  ),
);
