import { Globe1 } from '@/icons'

interface NewsSlugCaptionProps {
  base: string | undefined
  slug: string
  parentSlug?: string
}

export const PagesSlugCaption = ({ base, slug, parentSlug }: NewsSlugCaptionProps) => {
  if (!slug) {
    return null
  }
  const href = `${base}/${parentSlug ? `${parentSlug}/` : ''}${slug}`
  return (
    <div className="mt-2 px-1.5">
      <a className="inline-flex items-start gap-1.5" target="_blank" href={href}>
        <Globe1 size={16} />
        <span className="text-paragraph-sm leading-none text-text-secondary">{href}</span>
      </a>
    </div>
  )
}
