import { HTMLAttributes, ReactNode } from 'react'
import { cn } from '../../../libs/cn'

interface WebhookBarColumnProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode
  label: ReactNode
}

export const WebhookBarColumn = ({ children, label, ...rest }: WebhookBarColumnProps) => (
  <div {...rest} className={cn('flex h-16 flex-col pr-6', rest.className)}>
    <div className="mb-auto text-caption-md text-text-secondary">{label}</div>
    <div>{children}</div>
  </div>
)
