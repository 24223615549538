import {
  BackButton,
  Badge,
  ButtonIcon,
  Drawer,
  Floating,
  Header,
  ModalProps,
  SpinnerAghanim,
  Table,
  TableCell,
  TableCellBulkCheckbox,
  TableCellBulkToolbar,
  TableRow,
  TableZeroState,
  ToastSeverity,
  Tooltip,
  TooltipAlertCircle,
  useToast,
} from '@/ui'
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useStoreQuery } from './api/useStoreQuery'
import { STORE_PATH } from '@/libs'
import { useStoreItemsQuery } from './api/useStoreItemsQuery'
import { useEffect, useState } from 'react'
import {
  ConditionNode,
  ResourceState,
  RotationType,
  StoreCardType,
  StoreItemRead,
  StoreRead,
  StoreType,
} from '@/api/dashboard'
import StoreItemSettings from './components/StoreItemSettings'
import { dashboardClient, getErrorText } from '@/api'
import { ReactSortable } from 'react-sortablejs'
import { useForm } from 'react-hook-form'
import { useStoreUpdateMutate } from './api'
import { itemCategoriesQuery } from '../item-category/api'
import { DEFAULT_LOAD_LIMIT, useGameItemsQuery } from '@/api/useGameItems'
import { CloseOutline, DotsGridSix, DotsHorizontal, Plus, SwitchHorizontal } from '@/icons'
import { useBulkSelectGeneric } from '@/libs/hooks/useBulkSelectGeneric'
import { getDescConditionNode } from '../campaigns/blocks/descriptions/getDescConditionNode'
import { SkuImageName } from '../game-items/components/SkuImageName'
import { DescContext } from '../campaigns/blocks/descriptions/types'
import { useSegmentsQuery } from '../segment/api/useSegmentsQuery'
import { PriceView } from '../game-items/components/PriceView'
import { useBanner } from '@/libs/hooks/useBanner'
import { GeneralStoreSettings } from '@/layouts/store/GeneralStoreSettings'
import { BulkEditModal, BulkPropertyName } from '@/layouts/store/widgets/BulkEditModal'
import { BulkEditMenu } from '@/layouts/store/widgets/BulkEditMenu'
import useKeyPress from 'react-use/lib/useKeyPress'
import { useQuery } from '@tanstack/react-query'
import { Button, InputSearch, ModalPresetConfirm, cn, useModal, useSearchFilter } from '@dashboard/ui'
import { useTranslation } from 'react-i18next'
import { useCurrentUser } from '@/api/useCurrentUser'
import { SelectSkuModal } from '@/layouts/components/SelectSkuModal/SelectSkuModal'
import { Store } from '@/layouts/store/icons/Store'

export default function StoreEditor() {
  const navigate = useNavigate()
  const { companyId, gameId, storeId } = useParams() as {
    companyId: string
    gameId: string
    storeId: string
  }

  const location = useLocation()
  const { state } = location
  const showToast = useToast()
  const { canEdit } = useCurrentUser()
  const { getStickyTop, getStickyHeight } = useBanner()
  const [isStoreOpening, setIsStoreOpening] = useState(true)
  const { data: segments = [] } = useSegmentsQuery(companyId, gameId, {
    limit: DEFAULT_LOAD_LIMIT,
  })
  const { data: store = null, isLoading } = useStoreQuery(companyId, gameId, storeId)
  const {
    data: items = [],
    isLoading: isLoadingStore,
    isRefetching: isStoreRefetching,
    refetch: refetchStoreItems,
  } = useStoreItemsQuery(companyId, gameId, storeId)
  const { data: allItems = [] } = useGameItemsQuery(companyId, gameId, {
    limit: DEFAULT_LOAD_LIMIT,
    state: ResourceState.Active,
  })

  const { t } = useTranslation()
  const [isDirtyOrder, setIsDirtyOrder] = useState(false)
  const [isDirtySomeItem, setIsDirtySomeItem] = useState(false)
  const [visibleStoreSettings, setVisibleStoreSettings] = useState(false)

  const [editStoreItem, setEditStoreItem] = useState<StoreItemRead | null>(null)

  const filter = useSearchFilter<'q'>()

  const { data: categories = [] } = useQuery({
    ...itemCategoriesQuery(companyId, gameId, { limit: 100 }),
    refetchOnMount: false,
  })
  const { mutateAsync: updateMutateAsync } = useStoreUpdateMutate(companyId, gameId)

  const [deletedIds, setDeletedIds] = useState<string[]>([])
  const [updateIds, setUpdateIds] = useState<string[]>([])

  const { selected, onSelect, onReset, onMultiSelect } = useBulkSelectGeneric()

  const [isShiftPress] = useKeyPress('Shift')

  const {
    watch,
    reset,
    getValues,
    setValue,
    formState: { isDirty: isDirtyForm, isSubmitting },
    handleSubmit,
  } = useForm<StoreRead>({})

  const [name, rotation_settings] = watch(['name', 'rotation_settings'])

  const [storeItems, setStoreItems] = useState<StoreItemRead[]>([])

  useEffect(() => {
    if (!isLoadingStore && !isStoreRefetching && isStoreOpening) {
      setStoreItems([...items])
      setIsStoreOpening(false)
    }
  }, [isLoadingStore, isStoreRefetching])

  useEffect(() => {
    if (!isLoading && store) {
      reset(store)

      if (state?.openSettings) {
        state.openSettings = null
        setVisibleStoreSettings(true)
      }
    }
  }, [isLoading])

  const openConfirmExit = useModal<{ data: StoreRead }>(props => (
    <ModalPresetConfirm
      {...props}
      title={{
        children: t('confirm.title'),
        subtitle: t('campaign.confirm.lost-changes'),
      }}
      cancel={{
        label: t('campaign.confirm.no-stay'),
      }}
      submit={{
        color: 'danger',
        callback: () => {
          props.onClose()
          navigate(generatePath(STORE_PATH, { companyId, gameId }))
        },
        label: t('yes'),
      }}
    />
  ))

  const showSaveEmptyStoreConfirm = useModal<{ data: StoreRead }>(props => (
    <ModalPresetConfirm
      {...props}
      title={{
        children: t('confirm.title'),
        subtitle: t('store.save-empty-store.confirm'),
      }}
      cancel={{
        label: t('no'),
      }}
      submit={{
        color: 'danger',
        callback: () => {
          props.onClose()
          confirmSaveClick(props.data)
        },
        label: t('yes'),
      }}
    />
  ))

  const openBulkEditModal = useModal<{ propertyName: BulkPropertyName; items: StoreItemRead[] }>(props => (
    <BulkEditModal
      {...props}
      onEdit={editedItems => {
        setStoreItems([...storeItems.map(it => editedItems.find(e => e.item_id == it.item_id) || it)])
        setUpdateIds(prev => [...prev, ...editedItems.map(it => it.id)])
        onReset(editedItems.map(it => it.item_id))
        setIsDirtySomeItem(true)
      }}
    />
  ))

  const closeClick = () => {
    if (isDirtyOrder || isDirtyForm || isDirtySomeItem) {
      openConfirmExit()
    } else {
      navigate(generatePath(STORE_PATH, { companyId, gameId }))
    }
  }

  const saveClick = async (data: StoreRead) => {
    if (!storeItems.length && store?.type == StoreType.Default) {
      showSaveEmptyStoreConfirm({ data })
      return
    }

    await confirmSaveClick(data)
  }

  const getBasePropsForUpdate = (it: StoreItemRead) => {
    return {
      rotation_weight: it.rotation_weight,
      is_free_item: it.is_free_item,
      start_at: it.start_at,
      end_at: it.end_at,
      reward_points_percent: it.reward_points_percent,
      custom_badge: it.custom_badge ? it.custom_badge : (null as unknown as string),
      requirements_expression: it.requirements_expression,
      id: it.id,
      item_id: it.item_id,
      discount_percent: it.discount_percent ? it.discount_percent : (null as unknown as number),
      bonus_percent: it.bonus_percent ? it.bonus_percent : (null as unknown as number),
      name: '',
      description: '',
      max_purchases: it.max_purchases || (null as unknown as number),
      max_purchases_period_type: it.max_purchases_period_type,
      max_purchases_period_value: it.max_purchases_period_value,
      card_type: it.card_type,
      show_first_on_category_list: it.show_first_on_category_list,
      show_disabled_by_max_purchases: it.show_disabled_by_max_purchases,
    }
  }

  const confirmSaveClick = async (data: StoreRead) => {
    try {
      await updateMutateAsync({
        id: data.id,
        update: data,
      })
    } catch (e) {
      showToast({ message: getErrorText(e), severity: ToastSeverity.error })
      return
    }

    if (deletedIds.length) {
      await dashboardClient.v1.bulkDeleteStoreItems(
        companyId,
        gameId,
        storeId,
        deletedIds.map(it => ({
          id: it,
        })),
      )

      setDeletedIds([])
    }

    const notCreatedItems = storeItems.filter(it => !it.id)

    if (deletedIds.length || notCreatedItems.length || isDirtyOrder) {
      let update = storeItems
        .filter(it => it.id)
        .map(it => ({
          ...getBasePropsForUpdate(it),
          position: isDirtyOrder ? storeItems.indexOf(it) : undefined,
        }))

      if (update.length) {
        await dashboardClient.v1.bulkUpdateStoreItems(companyId, gameId, storeId, update)
      }
    } else {
      let update = storeItems
        .filter(it => it.id && updateIds.includes(it.id))
        .map(it => ({
          ...getBasePropsForUpdate(it),
        }))

      if (update.length) {
        await dashboardClient.v1.bulkUpdateStoreItems(companyId, gameId, storeId, update)
      }
    }

    for (let i = 0; i < notCreatedItems.length; i += 50) {
      await dashboardClient.v1.batchCreateStoreItem(companyId, gameId, storeId, {
        items: notCreatedItems.slice(i, i + 50),
      })
    }

    if (notCreatedItems.length) {
      const { data: createdItems } = await refetchStoreItems()

      if (createdItems) {
        setStoreItems([...createdItems])
      }
    }

    onReset([])
    setUpdateIds([])
    setIsDirtyOrder(false)
    setIsDirtySomeItem(false)

    reset(data)

    showToast({ message: t('saved'), severity: ToastSeverity.success })
  }

  const openAddItemsModal = useModal<ModalProps>(({ ...rest }) => (
    <SelectSkuModal
      {...rest}
      showAddAll={true}
      title={t('store.add-items')}
      buttonText={t('apply')}
      picked={storeItems.map(it => it.item)}
      onSubmit={selectedItems => {
        let removingItems = storeItems.filter(s => !selectedItems.find(it => it.id == s.item_id))
        deleteItems(removingItems)
        let idx = storeItems.length - removingItems.length
        let newItems = selectedItems
          .filter(it => !storeItems.find(s => s.item_id == it.id))
          .map(it => ({
            id: '',
            name: '',
            description: '',
            position: idx++,
            item_id: it.id,
            item: it,
            store_id: storeId,
            created_at: 0,
          }))
        setIsDirtySomeItem(true)
        setStoreItems(prev => [...prev, ...newItems])
        rest?.onClose?.()
      }}
    />
  ))

  const confirmDeleteItems = (deletingItems: StoreItemRead[]) => {
    setDeletedIds(deletingItems.filter(it => it.id).map(it => it.id))
    setStoreItems(prev => prev.filter(it => !deletingItems.find(d => d.item_id == it.item_id)))
    setIsDirtyOrder(true)
    setEditStoreItem(null)
    onReset([])
  }

  const openDeleteItemsConfirmModal = useModal<{ items: StoreItemRead[] }>(props => (
    <ModalPresetConfirm
      {...props}
      title={{
        children: t('confirm.title'),
        subtitle: t('store.delete-all-items.confirm', { N: props.items.length }),
      }}
      cancel={{
        label: t('no'),
      }}
      submit={{
        color: 'danger',
        callback: () => {
          props.onClose()
          confirmDeleteItems(props.items)
        },
        label: t('yes'),
      }}
    />
  ))

  const getHeaderButtons = () => {
    return (
      <>
        <Button
          variant="outline"
          color="secondary"
          data-testid="sku/store/add-items"
          size="sm"
          onClick={() => openAddItemsModal()}
        >
          {t('store.add-items')}
        </Button>

        <Button
          size="sm"
          color="secondary"
          variant="outline"
          data-testid="sku/store/clear-all"
          onClick={() => openDeleteItemsConfirmModal({ items: storeItems })}
          disabled={storeItems.length == 0}
        >
          {t('store.clear')}
        </Button>

        <Button size="sm" color="secondary" variant="outline" onClick={() => setVisibleStoreSettings(true)}>
          {t('store.settings')}
        </Button>

        <Button
          data-testid="save-store"
          color="primary"
          size="sm"
          loading={isSubmitting}
          onClick={handleSubmit(saveClick)}
          disabled={!isDirtyOrder && !isDirtyForm && !isDirtySomeItem}
        >
          {t('Save2')}
        </Button>
      </>
    )
  }

  const noCat = t('store.no-category')

  const getCategoryName = (s: StoreItemRead) => {
    let c = s.item?.categories?.length ? categories.find(c => s.item.categories?.includes(c.id)) : null
    return c?.name || noCat
  }

  const renderBulkSelectionHeader = (itemsToRender: StoreItemRead[]) => (
    <div className={cn('flex items-center ', 'sticky h-[46px] bg-fg-primary')}>
      <div className="w-[44px]" />
      <TableCellBulkCheckbox
        selected={selected.length > 0 && selected.length == itemsToRender.length}
        disabled={itemsToRender.length === 0}
        onChange={() => onReset(selected.length == itemsToRender.length ? [] : itemsToRender.map(it => it.item_id))}
      >
        {selected.length == 0 && t('store.item.select_all')}
      </TableCellBulkCheckbox>

      {selected.length > 0 && (
        <div className="flex w-full">
          <TableCellBulkToolbar
            selected={selected}
            onReset={() => onReset([])}
            extra={
              <Tooltip message={t('store.item.invert_selection')}>
                <ButtonIcon variant="secondary-gray" size="xs" onClick={() => invertSelection(itemsToRender)}>
                  <SwitchHorizontal />
                </ButtonIcon>
              </Tooltip>
            }
          >
            {' '}
          </TableCellBulkToolbar>

          <div className="ml-auto mr-3">
            <Floating
              menu={
                <BulkEditMenu
                  selected={storeItems.filter(it => selected.includes(it.item_id))}
                  onDelete={onDeleteClick}
                  isWeightRotation={isWeightRotation()}
                  onSelect={onBulkEditClick}
                />
              }
              placement="bottom-start"
            >
              <ButtonIcon variant="secondary-gray" size="sm">
                <DotsHorizontal />
              </ButtonIcon>
            </Floating>
          </div>
        </div>
      )}
    </div>
  )

  const invertSelection = (itemsToRender: StoreItemRead[]) => {
    let newSelected = itemsToRender.filter(it => !selected.includes(it.item_id))
    onReset(newSelected.map(it => it.item_id))
  }

  const onBulkEditClick = (propertyName: BulkPropertyName) => {
    openBulkEditModal({
      propertyName: propertyName,
      items: storeItems.filter(it => selected.includes(it.item_id)),
    })
  }

  const onEditConfirm = (data: StoreItemRead) => {
    setStoreItems([...storeItems.map(it => (it.item_id == data.item_id ? data : it))])
    setUpdateIds(prev => [...prev, data.id])
    setEditStoreItem(null)

    onReset([])
    setIsDirtySomeItem(true)
  }

  const deleteItems = (items: StoreItemRead[]) => {
    for (let selectedStoreItem of items) {
      if (selectedStoreItem.id) {
        setDeletedIds(prev => [...prev, selectedStoreItem.id])
        setStoreItems(prev => prev.filter(it => it.id != selectedStoreItem.id))
      } else {
        setStoreItems(prev => prev.filter(it => it.item_id != selectedStoreItem.item_id))
      }
    }

    if (items.length) {
      setIsDirtyOrder(true)
    }
  }

  const onDeleteClick = () => {
    openDeleteItemsConfirmModal({ items: storeItems.filter(it => selected.includes(it.item_id)) })
  }

  const renderDateTimeValue = (value: number) => {
    return (
      <div className="flex gap-1.5">
        <div className="text-text-secondary">
          {t('intl.DateTime', {
            val: new Date(value * 1000),
            formatParams: { val: { dateStyle: 'short' } },
          })}
        </div>
        <div className="text-text-tertiary">
          {t('intl.DateTime', {
            val: new Date(value * 1000),
            formatParams: { val: { timeStyle: 'short' } },
          })}
        </div>
      </div>
    )
  }

  const renderTimeLimit = (storeItem: StoreItemRead) => {
    if (storeItem.start_at && storeItem.end_at) {
      return (
        <div className="flex flex-col">
          {renderDateTimeValue(storeItem.start_at)}
          {renderDateTimeValue(storeItem.end_at)}
        </div>
      )
    }
    return ''
  }

  const isWeightRotation = () => {
    return rotation_settings?.type == RotationType.RandomByWeight
  }

  const renderCondition = (storeItem: StoreItemRead) => {
    if (!storeItem.requirements_expression) {
      return ''
    }

    let [, subTitle] = getDescConditionNode(
      {
        expr: storeItem.requirements_expression,
      } as ConditionNode,
      {
        items: allItems,
        segments: segments,
      } as unknown as DescContext,
    )
    return subTitle
  }

  const renderStoreTableView = () => {
    const hasSegmentationRules = storeItems.some(it => it.requirements_expression?.tokens?.length)
    const search = (filter.values.q as string)?.toLowerCase()
    const itemsToRender = search
      ? storeItems.filter(
          it =>
            it.item_id.toLowerCase().includes(search) ||
            it.item.name?.toLowerCase().includes(search) ||
            it.item.description?.toLowerCase().includes(search) ||
            it.item.sku.toLowerCase().includes(search),
        )
      : storeItems

    return (
      <div className={cn('mt-5 flex w-full flex-col px-4', itemsToRender.length == 0 ? 'h-full' : '')}>
        <InputSearch
          {...filter.registerInput('q')}
          data-testid="coupon/toolbar/filter-search"
          placeholder={t('search')}
        />
        {selected.length > 0 && renderBulkSelectionHeader(itemsToRender)}
        {itemsToRender.length == 0 ? (
          <TableZeroState
            className="mt-2 w-full grow"
            title={t('store.store')}
            message={t('store.empty-message')}
            buttons={
              <Button variant="outline" color="secondary" size="sm" onClick={() => filter.onChange({ q: '' })}>
                <CloseOutline size={14} />
                <span>{t('webhook.clear-filters')}</span>
              </Button>
            }
          />
        ) : (
          <Table className="overflow-hidden rounded-md bg-fg-primary">
            {selected.length == 0 && (
              <TableRow variant="header">
                <TableCell width={42} className="flex justify-end" />
                {canEdit && (
                  <TableCellBulkCheckbox
                    selected={selected.length > 0 && selected.length == itemsToRender.length}
                    disabled={itemsToRender.length === 0}
                    onChange={() =>
                      onReset(selected.length == itemsToRender.length ? [] : itemsToRender.map(it => it.item_id))
                    }
                  />
                )}
                <TableCell width="50%">{t('sku.item.product-name')}</TableCell>
                <TableCell width="15%">{t('sku.item.price')}</TableCell>
                <TableCell width="20%">{t('sku.item.category')}</TableCell>
                <TableCell width="20%">{t('store.item.benefits')}</TableCell>
                <TableCell width="30%">{t('campaign.settings.limit-limitations')}</TableCell>
                {hasSegmentationRules && <TableCell width="50%">{t('store.item.requirements_expression')}</TableCell>}
                {isWeightRotation() && (
                  <TableCell width="12%" className="flex items-center gap-1">
                    {t('store.item.weight')}
                    <TooltipAlertCircle message={t('store.item.weight.tooltip')} />
                  </TableCell>
                )}
              </TableRow>
            )}

            <ReactSortable
              animation={200}
              delay={2}
              disabled={!canEdit || !!search}
              filter=".non-drag"
              list={itemsToRender || []}
              setList={v => {
                let changed = false
                for (let i = 0; i < v.length; i++) {
                  if (v[i].id != storeItems[i].id) {
                    changed = true
                    break
                  }
                }
                setStoreItems(v)
                if (changed) {
                  setIsDirtyOrder(true)
                }
              }}
            >
              {itemsToRender.map(it => (
                <TableRow
                  key={it.item_id}
                  variant="clickable"
                  onClick={canEdit ? () => setEditStoreItem(it) : undefined}
                >
                  <TableCell width={42} className="flex justify-end">
                    {!search && canEdit && <DotsGridSix className="cursor-move" />}
                  </TableCell>
                  {canEdit && (
                    <TableCellBulkCheckbox
                      selected={selected.includes(it.item_id)}
                      onChange={_ => {
                        if (isShiftPress) {
                          onMultiSelect(
                            storeItems.map(it => it.item_id),
                            it.item_id,
                          )
                        } else {
                          onSelect(it.item_id)
                        }
                      }}
                    />
                  )}
                  <TableCell width="50%" className="flex items-center gap-3">
                    <SkuImageName item={it.item} />
                    {it.card_type == StoreCardType.Featured && (
                      <Badge variant="gray-secondary">{t('store.item.featured_type.featured')}</Badge>
                    )}
                  </TableCell>
                  <TableCell width="15%" className="truncate">
                    {it.is_free_item ? t('store.item.free_item') : <PriceView item={it.item} />}
                  </TableCell>
                  <TableCell width="20%">{getCategoryName(it)}</TableCell>
                  <TableCell width="20%">
                    {it.discount_percent ? (
                      <div>{t('store.item.discount1') + ' ' + it.discount_percent + '%'}</div>
                    ) : (
                      ''
                    )}
                    {it.bonus_percent ? <div>{t('store.item.bonus1') + ' ' + it.bonus_percent + '%'}</div> : ''}
                  </TableCell>
                  <TableCell width="30%">{renderTimeLimit(it)}</TableCell>
                  {hasSegmentationRules && <TableCell width="50%">{renderCondition(it)}</TableCell>}
                  {isWeightRotation() && <TableCell width="12%">{it.rotation_weight}</TableCell>}
                </TableRow>
              ))}
            </ReactSortable>
          </Table>
        )}
      </div>
    )
  }

  const renderStoreItemSettings = () => {
    return (
      <Drawer open={!!editStoreItem}>
        {editStoreItem && (
          <StoreItemSettings
            store={getValues()}
            onClose={(item: StoreItemRead | null) => {
              if (item) {
                onEditConfirm(item)
              } else {
                setEditStoreItem(null)
              }
            }}
            item={editStoreItem}
          />
        )}
      </Drawer>
    )
  }

  const renderContent = () => {
    if (isStoreOpening) {
      return (
        <div className="flex w-full grow items-center justify-center">
          <SpinnerAghanim />
        </div>
      )
    }

    if (storeItems.length === 0) {
      return (
        <TableZeroState
          className="w-full grow"
          title={t('store.store')}
          message={t('store.empty-message')}
          buttons={
            canEdit && (
              <Button color="primary" size="sm" onClick={() => openAddItemsModal()}>
                <Plus size={14} />
                <span>{t('store.add-items')}</span>
              </Button>
            )
          }
        />
      )
    }

    return renderStoreTableView()
  }

  return (
    <div
      className={cn(
        'absolute left-0 z-50 flex size-full flex-col overflow-auto bg-bg-secondary',
        getStickyHeight(),
        getStickyTop(),
      )}
    >
      {renderStoreItemSettings()}
      {visibleStoreSettings && (
        <GeneralStoreSettings
          onApplied={data => {
            let name = data.name
            data.name = ''
            reset(data)
            setValue('name', name, { shouldDirty: true })
            setVisibleStoreSettings(false)
          }}
          store={getValues()}
          onClose={() => setVisibleStoreSettings(false)}
        />
      )}
      <Header
        className={cn('sticky top-0 z-10')}
        title={''}
        titleAction={
          <BackButton
            text={name}
            onClick={closeClick}
            datatestid="back-store-button"
            icon={<Store size={34} className="min-w-[34px]" />}
            className="max-w-[400px] truncate"
          />
        }
      >
        <div className="flex items-center gap-2">{canEdit ? getHeaderButtons() : null}</div>
      </Header>

      {renderContent()}
    </div>
  )
}
