import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const ArrowUp: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 24 24" ref={ref}>
      <path opacity="0.4" d="M12 19V5" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5 12L12 5L19 12" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </IconBase>
  );
});
