import { ReactElement, cloneElement, forwardRef, useMemo } from 'react';
import { FloatingFocusManager, FloatingPortal } from '@floating-ui/react';
import { AnimatePresence } from 'framer-motion';
import { UseFloatingMenuParams, useFloatingMenu } from '../../hooks';
import { getFloatingMotionProps } from '../../libs';
import { PopperMenuProps } from './types';

interface PopperProps {
  /**
   * Trigger element, should be a ReactElement.
   * A data attribute `data-open` will be added to the element.
   */
  children: ReactElement;
  /**
   * Popper component, should be a function that returns a ReactElement.
   * The element must be wrapped in a forwardRef.
   * @param props
   */
  popper: (props: PopperMenuProps) => ReactElement;
  /**
   * Popper menu parameters.
   */
  params?: UseFloatingMenuParams;
  /**
   * Focus manager parameters.
   */
  focusManager?: {
    initialFocus?: number;
    disabled?: boolean;
  };
}

/**
 * The Popper component helps create floating elements that can be used to create dropdown menus.
 */
export const Popper = forwardRef<HTMLElement, PopperProps>(({ children, popper, params, focusManager }, ref) => {
  const {
    state: { isOpen, setIsOpen },
    floating: { context, placement },
    getReferenceProps,
    getFloatingProps,
  } = useFloatingMenu(ref, params);

  const popperInnerProps = useMemo(
    () => ({
      ...getFloatingProps(),
      className: 'absolute z-[3000] w-max',
      motionProps: {
        ...getFloatingMotionProps(placement),
        className: 'max-w-none',
      },
    }),
    [getFloatingProps, placement],
  );

  const popperInner = useMemo(
    () =>
      popper({
        ...popperInnerProps,
        onClose: () => {
          setIsOpen(false);
        },
      }),
    [popper, setIsOpen, popperInnerProps],
  );

  return (
    <>
      {cloneElement(children, getReferenceProps())}
      <AnimatePresence>
        {isOpen && (
          <FloatingFocusManager {...focusManager} context={context}>
            <FloatingPortal>{cloneElement(popperInner)}</FloatingPortal>
          </FloatingFocusManager>
        )}
      </AnimatePresence>
    </>
  );
});
