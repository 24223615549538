import { useNavigate } from 'react-router-dom'
import { Button } from '@/ui'
import { setCompanyGameId, setLastGameId } from '@/Settings'
import { useTranslation } from 'react-i18next'
import { goToLogout } from '@/util'

export default function AccessDeniedPage() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const goHome = () => {
    setLastGameId(null)
    setCompanyGameId(null)

    navigate('/')
  }

  return (
    <div
      style={{
        width: '690px',
        height: '360px',
        boxShadow: '0px 24px 48px -12px #1018282E',
        background: 'linear-gradient(273.02deg, rgba(255, 255, 255, 0) 5.02%, #FFFFFF 94.98%)',
        zIndex: 1,
      }}
      className="flex flex-col items-center gap-6 rounded-[36px] bg-fg-primary p-12"
    >
      <div className="flex flex-col items-center gap-3">
        <span
          style={{ width: '56px' }}
          className="inline-block rounded-md border border-border-primary bg-fg-primary px-3 py-2 text-[16px] font-semibold"
        >
          403
        </span>

        <span className="text-[36px] font-semibold" style={{ lineHeight: '43px' }}>
          {t('access-denied')}
        </span>

        <span className="text-center text-[18px] text-text-secondary">{t('access-denied-subtitle')}</span>
      </div>

      <div className="flex gap-2">
        <Button onClick={() => goToLogout()} size={'xl'}>
          {t('logout')}
        </Button>
        <Button onClick={goHome} variant="primary" size={'xl'}>
          {t('go-home')}
        </Button>
      </div>
      <span
        className="text-[16px] text-text-secondary"
        dangerouslySetInnerHTML={{ __html: t('access-denied.footer') }}
      />
    </div>
  )
}
