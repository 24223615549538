import { useMutation, useQueryClient } from '@tanstack/react-query'
import { dashboardClient } from '../../../api'
import { removeItemQueries } from '@/layouts/game-items/api'

interface ItemPropertyDeleteMutate {
  id: string
}

export const useItemPropertyDeleteMutate = (companyId: string, gameId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ id }: ItemPropertyDeleteMutate) => dashboardClient.v1.deleteItemProperty(companyId, gameId, id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['item-properties', gameId] })

      removeItemQueries(queryClient, gameId)
    },
  })
}
