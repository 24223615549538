import i18next from 'i18next'
import { useParams } from 'react-router-dom'
import { usePayoutsQuery } from './api/usePayoutsQuery'
import {
  Badge,
  PageHeader,
  Pagination,
  Table,
  TableCell,
  TableRow,
  TableRowSkeleton,
  TableZeroState,
  usePagination,
} from '@/ui'
import { formatMoney } from '../../util'
import { Payout } from '../../api/dashboard'
import BalanceSingleValue from '../dashboard/components/Balance/Balance'
import { PayoutsChart } from './PayoutsChart'
import dayjs from 'dayjs'
import { getDateFormatParams, getFormatYearMonthParams } from '@/libs/dates'
import { useTranslation } from 'react-i18next'
import { PayoutStatus } from '@/layouts/finance-report/types'

const PayoutsPage = () => {
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const { data: payouts = [], isLoading, isError } = usePayoutsQuery(companyId, gameId)
  const { page, query, onPageChange, needShowPagination } = usePagination(20)
  const { t } = useTranslation()

  const getStatus = (p: Payout) => {
    switch (p.payout_status) {
      case PayoutStatus.pending:
      case PayoutStatus.draft:
      case PayoutStatus.approved:
        return <Badge variant="gray-primary">{i18next.t('payouts.table.status.pending')}</Badge>
      case PayoutStatus.paid:
        return <Badge variant="green-primary">{i18next.t('payouts.table.status.completed')}</Badge>
    }
  }

  const getPeriod = (p: Payout) => {
    const d = new Date()

    const start = dayjs(new Date(p.start_date / 1000)).add(d.getTimezoneOffset(), 'minute')
    const end = dayjs(new Date(p.ending_date / 1000)).add(d.getTimezoneOffset(), 'minute')

    const startAt = start.toDate().getTime() / 1000
    const endAt = end.toDate().getTime() / 1000

    if (start.month() == end.month()) {
      return `${start.format('D')} - ${end.format('D')} ${t('intl.DateTime', getFormatYearMonthParams(endAt))}`
    }

    if (start.year() == end.year()) {
      return `${t('intl.DateTime', getDateFormatParams(startAt, 'long'))} - ${t('intl.DateTime', getDateFormatParams(endAt, 'long'))}`
    }

    return `${t('intl.DateTime', getDateFormatParams(startAt))} - ${t('intl.DateTime', getDateFormatParams(endAt))}`
  }

  const getPayoutDate = (p: Payout) => {
    const d = new Date()
    const end = dayjs(new Date(p.ending_date / 1000)).add(d.getTimezoneOffset(), 'minute')
    const endAt = end.toDate().getTime() / 1000
    return t('intl.DateTime', getDateFormatParams(endAt, 'long'))
  }

  return (
    <div className="flex h-full flex-col">
      <PageHeader>{i18next.t('sidebar.payouts')}</PageHeader>

      <div className="mb-5 flex gap-5">
        <BalanceSingleValue />
        <PayoutsChart />
      </div>

      {isLoading ? (
        <TableRowSkeleton rowCount={query.limit} columnCount={3} />
      ) : payouts.length === 0 ? (
        <div className="grow">
          <TableZeroState
            title={i18next.t(isError ? 'service-unavailable' : 'payouts.empty-table.title')}
            message={i18next.t(isError ? 'SomethingWentWrong' : 'payouts.empty-table.desc')}
          />
        </div>
      ) : (
        <>
          <Table data-testid="payout-table">
            <TableRow variant="header">
              <TableCell width="30%">{i18next.t('payouts.table.amount')}</TableCell>
              <TableCell width="50%">{i18next.t('payouts.table.status')}</TableCell>
              <TableCell width="50%">{i18next.t('payouts.table.payout-date')}</TableCell>
              <TableCell width="40%">{i18next.t('payouts.table.period')}</TableCell>
            </TableRow>

            {payouts.map((payout, idx) => (
              <TableRow key={idx} className="h-[64px]">
                <TableCell width="30%">{formatMoney(payout.payout_amount)}</TableCell>
                <TableCell width="50%">{getStatus(payout)}</TableCell>
                <TableCell width="50%">{getPayoutDate(payout)}</TableCell>
                <TableCell width="40%">{getPeriod(payout)}</TableCell>
              </TableRow>
            ))}
          </Table>

          {needShowPagination(isLoading, payouts) ? (
            <Pagination
              hasNext={payouts.length >= query.limit}
              page={page}
              onPageChange={onPageChange}
              pageItems={payouts.length}
            />
          ) : (
            <div className="border-t border-border-secondary" />
          )}
        </>
      )}
    </div>
  )
}

export default PayoutsPage
