import { ItemBonusOfferActionNode } from '../../../../api/dashboard'
import i18next from 'i18next'
import { DescContext } from './types'
import { Badge } from '@/ui'
import { getContextItemName } from '@/layouts/campaigns/blocks/descriptions/getContextItemName'

export function getDescItemBonusOfferActionNode(n: ItemBonusOfferActionNode, context: DescContext) {
  let item = context.items.find(it => it.id == n.item_id)
  if (item) {
    return [
      i18next.t('campaign.node-type.ItemBonusOfferActionNode'),
      <>
        {item.name} <Badge variant="gray-secondary">{item.sku}</Badge> {n.bonus_percent}%
      </>,
    ]
  }

  if (n.use_event_item) {
    return [n.title || i18next.t('campaign.node-type.ItemBonusOfferActionNode'), getContextItemName(context.type)]
  }

  return [n.title || i18next.t('campaign.node-type.ItemBonusOfferActionNode'), '']
}
