import { cn } from '@dashboard/ui'
import { Tooltip } from '@/ui'

export const TimezoneName = ({ className }: { className?: string }) => {
  let offset = new Date().getTimezoneOffset()
  let sign = offset <= 0 ? '+' : '-'
  let hours = Math.floor(Math.abs(offset) / 60)
  let minutes = Math.abs(offset) % 60

  let formattedOffset = `UTC ${sign}${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`

  let formatter = new Intl.DateTimeFormat([], { timeZoneName: 'long' })
  let arr = formatter.format(new Date()).split(',')

  return (
    <Tooltip message={arr[arr.length - 1].trim()}>
      <div className={cn('text-caption-sm font-normal text-text-quarterary-hover', className)}>{formattedOffset}</div>
    </Tooltip>
  )
}
