import { ComponentProps, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Button } from '@dashboard/ui'
import { TableZeroState } from '@/ui'
import { DailyRewardsDaysForm, DailyRewardsDaysFormInput } from '@/layouts/engagement/widgets'

const FORM_NAME = 'engagement::daily-rewards::days'

export interface RewardsConfiguration {
  isPreview: boolean
  buttons: {
    edit?: Partial<ComponentProps<typeof Button>>
    submit?: Partial<ComponentProps<typeof Button>>
    cancel?: Partial<ComponentProps<typeof Button>>
  }
}

interface DailyRewardsDaysProps {
  interactive?: boolean
  configure: (config: RewardsConfiguration) => void
  values: DailyRewardsDaysFormInput
}

export const DailyRewardsDays = ({ interactive = false, configure, values }: DailyRewardsDaysProps) => {
  const { t } = useTranslation()
  const methods = useForm<DailyRewardsDaysFormInput>({ defaultValues: values })
  const {
    watch,
    reset,
    formState: { isDirty, isSubmitting },
  } = methods
  const daily = watch('program.daily') || []
  const [isPreview, setIsPreview] = useState<boolean>(true)

  useEffect(() => {
    configure({
      isPreview: isPreview,
      buttons: {
        edit: {
          onClick: () => setIsPreview(false),
        },
        submit: {
          disabled: !isDirty || daily.length === 0,
          type: 'submit',
          form: FORM_NAME,
          loading: isSubmitting,
        },
        cancel: {
          onClick: () => {
            reset(values)
            setIsPreview(true)
          },
        },
      },
    })
  }, [configure, reset, isPreview, isDirty, daily.length, isSubmitting])

  return (
    <FormProvider {...methods}>
      {daily.length === 0 && isPreview && (
        <TableZeroState
          className="h-full"
          title={t('daily-rewards.reward.empty.title')}
          message={t('daily-rewards.reward.empty.message')}
          buttons={
            interactive && <Button onClick={() => setIsPreview(false)}>{t('daily-rewards.reward.empty.button')}</Button>
          }
        />
      )}
      <DailyRewardsDaysForm id={FORM_NAME} preview={isPreview} togglePreview={() => setIsPreview(true)} />
    </FormProvider>
  )
}
