import { HTMLAttributes, LegacyRef, MouseEvent, ReactNode, forwardRef, useId, useMemo, useRef, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { useCombobox, useMultipleSelection } from 'downshift'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import shouldForwardProp from '@emotion/is-prop-valid'
import { FloatingPortal, useMergeRefs } from '@floating-ui/react'
import { getMotionProps } from '@/libs'
import { ChevronSelectorVertical } from '@/icons'
import { Menu, MenuOption, MenuOptionProps } from '../Menu'
import { Tag } from '../Tag'
import { SelectProps } from '../Select'
import { useSelectFloating } from '../Select/useSelectFloating'
import { getFloatingMotionProps } from '../Floating'
import { useClickOutside } from '@/libs/hooks/useOnClickOutside'

export interface SelectMultipleOption extends Pick<MenuOptionProps, 'children' | 'icon'> {
  value: string | number
}

export interface SelectMultipleProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
  name?: string
  placeholder?: string
  disabled?: boolean
  options: SelectMultipleOption[]
  value?: SelectMultipleOption['value'][]
  onChange: (value: SelectMultipleOption['value'][]) => void
  action?: ReactNode
  color?: 'default' | 'success' | 'warning' | 'error'
  filter?: (option: SelectMultipleOption, search: string) => boolean
  dropDownWidth?: string
  mode?: 'single' | 'multiple'
  disableSearch?: boolean
  extraDown?: React.ReactNode
  extraTop?: React.ReactNode
}

const SelectMultipleField = styled('div', { shouldForwardProp })<{
  $color: SelectProps['color']
  layout?: string
  transition?: { duration: number }
}>`
  background: ${p => p.theme.palette.background.fgPrimary};
  border: 1px solid ${p => p.theme.palette.grey.borderPrimary};
  color: ${p => p.theme.palette.text.secondary};
  border-radius: 6px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 9px;
  gap: 6px;
  width: 100%;
  min-height: 48px;
  overflow: hidden;
  appearance: none;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.08px;
  outline: none;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  transition:
    border-color ease-in-out 160ms,
    box-shadow ease-in-out 160ms;

  &:hover {
    border-color: #cbd5e1;
    box-shadow: 0 0 0 2px rgba(0, 151, 228, 0.14);
  }

  &[data-focused='true'],
  &:has(> div > input:focus) {
    border-color: ${p => p.theme.palette.primary.main};
    box-shadow: 0 0 0 2px rgba(0, 151, 228, 0.14);
  }

  &[aria-disabled='true'] {
    background-color: #f1f5f9;
    border-color: #e2e8f0;
    color: #94a3b8;
    pointer-events: none;
  }

  ${p =>
    p.$color === 'error' &&
    css`
      background: ${p.theme.palette.background.fgPrimary};
      border-color: ${p.theme.palette.error.main};
      color: ${p.theme.palette.text.secondary};

      &:has(input:hover),
      &:has(input:focus) {
        color: #334155;
        box-shadow: 0 0 0 3px rgba(240, 68, 56, 0.14);
      }

      &:has(input:focus) {
        color: #000000;
        border-color: ${p.theme.palette.error.main};
      }
    `}
`

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  flex-grow: 1;
  padding: 0 4px;
  width: min-content;

  & svg {
    flex-shrink: 0;
  }
`

const Input = styled(motion.input)`
  color: #000000;
  background-color: transparent;
  display: flex;
  border: none;
  padding: 0;
  width: 100%;
  height: 28px;
  font-family:
    'InterAg',
    'SF Pro Display',
    -apple-system,
    BlinkMacSystemFont,
    'Open Sans',
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  font-size: 16px;
  line-height: 1;
  outline: none;
  transition: color ease-in-out 160ms;

  &::placeholder {
    color: #475569;
  }
`

const Icon = styled(motion.div)`
  display: flex;
  flex-shrink: 0;
  outline: none;
`

const FloatingContainer = styled.div`
  z-index: 3000;
`

const tagMotionProps = getMotionProps(
  {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { x: -6, scale: 0.95, opacity: 0 },
  },
  { duration: 0.1 },
)

/**
 *
 * @param options
 * @param value
 * @param search
 * @param filter
 */
const getFilteredOptions = (
  options: SelectMultipleOption[],
  value: SelectMultipleOption['value'][],
  search: string,
  filter?: (option: SelectMultipleOption, search: string) => boolean,
) => {
  const lowerCasedSearch = search.toLowerCase()

  let res = options.filter(option => !value.includes(option.value))

  if (filter) {
    return res.filter(option => filter(option, lowerCasedSearch))
  }
  return res.filter(option => {
    return (
      option.children?.toString().toLowerCase().includes(lowerCasedSearch) ||
      option.value.toString().toLowerCase().includes(lowerCasedSearch)
    )
  })
}

export const SelectMultiple = forwardRef<HTMLDivElement, SelectMultipleProps>(function SelectMultiple(
  {
    options,
    dropDownWidth,
    mode = 'multiple',
    value = [],
    color,
    onChange,
    filter,
    placeholder = 'Select...',
    disabled = false,
    extraDown,
    extraTop,
    disableSearch,
    ...rest
  },
  ref,
) {
  const id = useId()
  const refRoot = useRef<HTMLDivElement>()
  const [inputValue, setInputValue] = useState('')

  const selectedOptions = useMemo(
    () => value.map(v => options.find(option => option.value === v)!).filter(Boolean),
    [options, value],
  )

  const filteredOptions = useMemo(
    () => getFilteredOptions(options, value, inputValue, filter),
    [options, value, inputValue],
  )

  const { getSelectedItemProps, getDropdownProps, removeSelectedItem } = useMultipleSelection({
    selectedItems: selectedOptions,
    onStateChange({ selectedItems, type }) {
      switch (type) {
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
        case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
          onChange(selectedItems?.map(option => option.value) || [])
          break
        default:
          break
      }
    },
  })

  const {
    isOpen,
    highlightedIndex,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    getItemProps,
    closeMenu,
    openMenu,
  } = useCombobox({
    items: filteredOptions,
    itemToString: option => option?.children?.toString() || '',
    defaultHighlightedIndex: 0,
    selectedItem: null,
    inputValue,
    stateReducer(_, actionAndChanges) {
      const { changes, type } = actionAndChanges
      switch (type) {
        case useCombobox.stateChangeTypes.InputBlur:
          return {
            ...changes,
            isOpen: true,
          }
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true,
            highlightedIndex: 0,
          }
        default:
          return changes
      }
    },
    onStateChange({ inputValue: newInputValue, type, selectedItem: newSelectedItem }) {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          if (newSelectedItem) {
            onChange([...value, newSelectedItem.value])
            setInputValue('')
          }
          break
        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(newInputValue || '')
          break
        default:
          break
      }
    },
  })

  const { refs, floatingStyles, placement } = useSelectFloating(isOpen)

  const referenceMergedRef = useMergeRefs([refs.setReference, ref])

  const onClickRemove = (option: SelectMultipleOption) => (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    removeSelectedItem(option)
  }

  useClickOutside(refRoot, () => {
    closeMenu()
  })

  return (
    <div>
      <SelectMultipleField
        {...rest}
        $color={color}
        ref={referenceMergedRef}
        layout="size"
        transition={{ duration: 0.1 }}
        data-focused={isOpen}
        data-testid={rest?.name}
        aria-disabled={disabled}
        onClick={openMenu}
      >
        {mode == 'multiple' || selectedOptions.length <= 1 ? (
          <AnimatePresence mode="sync" initial={false}>
            {selectedOptions.map(option => (
              <motion.div key={`selected-option-${id}-${option.value}`} layout={true} {...tagMotionProps}>
                <Tag
                  size="lg"
                  data-testid={`tag-${option.value}`}
                  onClickDelete={onClickRemove(option)}
                  {...getSelectedItemProps({
                    selectedItem: option,
                    item: option,
                  })}
                >
                  {option.icon}
                  <span>{option.children}</span>
                </Tag>
              </motion.div>
            ))}

            {/* TODO: make a function that will calculate the correct length of a string */}
            <InputContainer style={{ minWidth: placeholder.length * 10 + 20 }}>
              {!disableSearch && (
                <Input
                  {...getInputProps(getDropdownProps({ preventKeyAction: isOpen, disabled }))}
                  layout="position"
                  transition={{ duration: 0.1 }}
                  placeholder={placeholder}
                />
              )}
              <Icon
                {...getToggleButtonProps()}
                layout="position"
                className="ml-auto"
                transition={{ duration: 0.1 }}
                aria-label="toggle menu"
              >
                <ChevronSelectorVertical size={16} />
              </Icon>
            </InputContainer>
          </AnimatePresence>
        ) : (
          <Tag size="lg" onClickDelete={() => onChange([])}>
            <span>Selected: {selectedOptions.length}</span>
          </Tag>
        )}
      </SelectMultipleField>

      <div {...getMenuProps({ disabled })}>
        <AnimatePresence>
          {isOpen && (!!filteredOptions.length || extraTop) && (
            <FloatingPortal>
              <FloatingContainer ref={refs.setFloating} style={floatingStyles}>
                <motion.div {...getFloatingMotionProps(placement)} style={{ maxHeight: 'inherit' }}>
                  <Menu style={{ maxWidth: 'none', width: dropDownWidth }} ref={refRoot as LegacyRef<HTMLDivElement>}>
                    {extraTop}
                    {filteredOptions.map((option, index) => (
                      <MenuOption
                        key={`menu-option-${id}-${option.value}`}
                        data-higlighted={index === highlightedIndex}
                        data-testid={option.value}
                        {...getItemProps({ item: option, index })}
                        {...option}
                      >
                        {option.children}
                      </MenuOption>
                    ))}
                    {extraDown}
                  </Menu>
                </motion.div>
              </FloatingContainer>
            </FloatingPortal>
          )}
        </AnimatePresence>
      </div>
    </div>
  )
})
