import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const AI: AghanimIcon = forwardRef(function AiTranslateOutline({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 24 24" ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9996 2.81482C13.511 2.81482 13.9256 3.22937 13.9256 3.74075C13.9256 8.34313 17.6565 12.0741 22.2589 12.0741C22.7703 12.0741 23.1848 12.4886 23.1848 13C23.1848 13.5114 22.7703 13.9259 22.2589 13.9259C17.6565 13.9259 13.9256 17.6569 13.9256 22.2593C13.9256 22.7706 13.511 23.1852 12.9996 23.1852C12.4883 23.1852 12.0737 22.7706 12.0737 22.2593C12.0737 17.6569 8.34275 13.9259 3.74038 13.9259C3.229 13.9259 2.81445 13.5114 2.81445 13C2.81445 12.4886 3.229 12.0741 3.74038 12.0741C8.34275 12.0741 12.0737 8.34313 12.0737 3.74075C12.0737 3.22937 12.4883 2.81482 12.9996 2.81482ZM12.9996 7.98979C11.9837 10.2 10.1997 11.9841 7.98943 13C10.1997 14.0159 11.9837 15.8 12.9996 18.0102C14.0156 15.8 15.7996 14.0159 18.0099 13C15.7996 11.9841 14.0156 10.2 12.9996 7.98979Z"
        fill="black"
      />
      <path
        d="M6.14779 17.3519C6.14779 17.1984 6.02342 17.0741 5.87001 17.0741C5.7166 17.0741 5.59223 17.1984 5.59223 17.3519C5.59223 18.7326 4.47294 19.8519 3.09223 19.8519C2.93882 19.8519 2.81445 19.9762 2.81445 20.1296C2.81445 20.283 2.93882 20.4074 3.09223 20.4074C4.47294 20.4074 5.59223 21.5267 5.59223 22.9074C5.59223 23.0608 5.7166 23.1852 5.87001 23.1852C6.02342 23.1852 6.14779 23.0608 6.14779 22.9074C6.14779 21.5267 7.26708 20.4074 8.64778 20.4074C8.8012 20.4074 8.92556 20.283 8.92556 20.1296C8.92556 19.9762 8.8012 19.8519 8.64778 19.8519C7.26708 19.8519 6.14779 18.7326 6.14779 17.3519Z"
        fill="black"
      />
    </IconBase>
  );
});
