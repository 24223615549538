export const ZeroItemProperties = () => {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_5285_6636)">
        <path
          d="M38.3629 6.28577L33.0003 3.18965C32.3815 2.83239 31.6191 2.83239 31.0003 3.18965L25.6376 6.28577M51.0882 13.6327L56.4508 16.7288C57.0696 17.0861 57.4508 17.7464 57.4508 18.4609V24.6531M57.4508 39.347V45.5392C57.4508 46.2538 57.0696 46.914 56.4508 47.2713L51.0882 50.3674M38.3629 57.7143L33.0003 60.8105C32.3815 61.1677 31.6191 61.1677 31.0003 60.8105L25.6376 57.7143M12.9124 50.3674L7.54974 47.2713C6.93094 46.914 6.54974 46.2538 6.54974 45.5392V39.347M6.54974 24.6531V18.4609C6.54974 17.7464 6.93094 17.0861 7.54974 16.7288L12.9124 13.6327"
          stroke="#E2E8F0"
          stroke-width="4"
          stroke-linecap="round"
        />
        <path d="M32 2.6123V61.3878" stroke="#E2E8F0" />
        <path d="M57.4694 17.3062L6.53061 46.6939" stroke="#E2E8F0" />
        <path d="M6.53062 17.3062L57.4694 46.6939" stroke="#E2E8F0" />
        <path
          d="M29 9.56872C30.8564 8.49692 33.1436 8.49692 35 9.56872L49.926 18.1863C51.7824 19.258 52.926 21.2388 52.926 23.3824V40.6175C52.926 42.7611 51.7824 44.7418 49.926 45.8136L35 54.4311C33.1436 55.5029 30.8564 55.5029 29 54.4311L14.074 45.8136C12.2176 44.7418 11.074 42.7611 11.074 40.6175V23.3824C11.074 21.2388 12.2176 19.258 14.074 18.1863L29 9.56872Z"
          fill="#F1F5F9"
        />
        <path
          d="M32 32.2177L32 13.0612M15.0204 41.7959L32 32.2177M48.9796 41.7959L32 32.2177"
          stroke="#E2E8F0"
          stroke-linecap="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.4565 22.5866L39.6482 25.8602C39.6579 25.8654 39.6675 25.8707 39.6771 25.8761C40.5867 26.3975 41.1429 27.358 41.1429 28.3929V34.9541C41.1429 35.989 40.5867 36.9494 39.6771 37.4708C39.6675 37.4763 39.6579 37.4816 39.6482 37.4867L33.4565 40.7604C33.0052 41.0154 32.5026 41.1429 32 41.1429C31.4974 41.1429 30.9948 41.0154 30.5435 40.7604L24.3518 37.4867C24.3421 37.4816 24.3325 37.4763 24.3229 37.4708C23.4133 36.9494 22.8571 35.989 22.8571 34.9541V28.3929C22.8571 27.358 23.4133 26.3975 24.3229 25.8761C24.3325 25.8707 24.3421 25.8654 24.3518 25.8602L30.5435 22.5866C31.4461 22.0766 32.5539 22.0766 33.4565 22.5866Z"
          fill="#E2E8F0"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.8323 26.3468L36.3704 28.2658C36.3759 28.2688 36.3814 28.2719 36.3869 28.2751C36.9067 28.5808 37.2245 29.1438 37.2245 29.7505V33.5967C37.2245 34.2033 36.9067 34.7664 36.3869 35.072C36.3814 35.0752 36.3759 35.0783 36.3704 35.0813L32.8323 37.0004C32.5744 37.1499 32.2872 37.2246 32 37.2246C31.7128 37.2246 31.4256 37.1499 31.1677 37.0004L27.6296 35.0813C27.6241 35.0783 27.6186 35.0752 27.6131 35.072C27.0933 34.7664 26.7755 34.2033 26.7755 33.5967V29.7505C26.7755 29.1438 27.0933 28.5808 27.6131 28.2751C27.6186 28.2719 27.6241 28.2688 27.6296 28.2658L31.1677 26.3468C31.6835 26.0478 32.3165 26.0478 32.8323 26.3468ZM32.0006 31.6328C31.9999 31.4557 32.0936 31.2919 32.2462 31.202L35.2686 29.4211C35.4646 29.3182 35.4665 29.0359 35.2719 28.9304L32.3042 27.3207C32.2986 27.3177 32.2931 27.3146 32.2877 27.3113C32.1094 27.2066 31.8906 27.2066 31.7124 27.3113C31.7069 27.3146 31.7014 27.3177 31.6958 27.3207L28.1599 29.2385C27.9851 29.3446 27.8754 29.5384 27.8754 29.7505V33.5967C27.8754 33.8088 27.9851 34.0026 28.16 34.1087L31.6735 35.9185C31.8567 36.0178 32.0786 35.8839 32.0786 35.674C32.0498 34.3272 32.0062 32.9799 32.0006 31.6328Z"
          fill="#CBD5E1"
        />
        <path
          d="M32 31.3467V50.9385M50.2857 21.5508L32 31.3467M13.7143 21.5508L32 31.3467"
          stroke="white"
          stroke-linecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5285_6636">
          <rect width="64" height="64" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
