import { useCallback, useEffect } from 'react'
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { creatorsRequestPayoutMutate } from '@/layouts/creator/api'
import { Spinner } from '@dashboard/ui'
import { ResponseError } from '@/api'
import { UseMutateAsyncFunction } from '@tanstack/react-query'

export const CreatorRequestPayoutPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { token } = useParams() as { token: string }
  const { mutateAsync: requestPayout, isPending, isError } = creatorsRequestPayoutMutate(token)

  const handleRequest = useCallback(
    async (token: string, navigate: NavigateFunction, requestPayout: UseMutateAsyncFunction) => {
      await requestPayout().catch(e => {
        const { status } = e as ResponseError
        status === 451 && navigate(`/creator/${token}/terms`)
      })
    },
    [token, navigate, requestPayout],
  )

  useEffect(() => {
    handleRequest(token, navigate, requestPayout)
  }, [token, navigate, requestPayout])

  useEffect(() => {
    isError && navigate(`/creator/${token}/error`)
  }, [isError, navigate, token])

  return (
    <div
      style={{
        width: '690px',
        height: '360px',
        boxShadow: '0px 24px 48px -12px #1018282E',
        background: 'linear-gradient(273.02deg, rgba(255, 255, 255, 0) 5.02%, #FFFFFF 94.98%)',
        zIndex: 1,
      }}
      className="flex flex-col items-center justify-center gap-10 rounded-[36px] bg-fg-primary p-12"
    >
      {isPending || isError ? (
        <Spinner size={48} />
      ) : (
        <>
          <span className="text-center text-[36px] font-semibold" style={{ lineHeight: '43px' }}>
            {t('creator.request-payout.success')}
          </span>
          <span className="text-center text-[18px] text-text-secondary">
            {t('creator.request-payout.success.desc')}
          </span>
        </>
      )}
    </div>
  )
}
