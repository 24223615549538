import { ApiConfig, DashboardClient, FullRequestParams, HttpResponse, HTTPValidationError } from './dashboard'
import { HUB_API } from '../HubAPI'
import { goToLogout } from '@/util'

const R401 = '401-redirected'

class DashboardClientV2<SecurityDataType extends unknown> extends DashboardClient<SecurityDataType> {
  private oldRequest
  private reloading = false
  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    super(apiConfig)
    this.oldRequest = this.request
    this.request = this.requestV2
  }

  async requestV2<T, E>(params: FullRequestParams): Promise<HttpResponse<T, E>> {
    try {
      let result = await this.oldRequest<T, E>(params)
      return result
    } catch (e) {
      let error = e as ResponseError

      if (error?.status == 401) {
        if (localStorage.getItem(R401)) {
          this.reloading = true
          localStorage.removeItem(R401)
          goToLogout()
        } else {
          if (!this.reloading) {
            localStorage.setItem(R401, 'true')
            window.location.reload()
          }
        }
        return new Promise(() => {})
      }

      if (error?.status == 403 && params.method == 'GET') {
        window.location.href = '/403'
        return new Promise(() => {})
      }

      throw e
    }
  }
}

export const dashboardClientV1 = new DashboardClient({
  baseUrl: import.meta.env.VITE_HUB_API_URL + '/dashboard',
  securityWorker: async () => {
    return {
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: `Bearer ${HUB_API.getToken()}`,
      },
    }
  },
})

export const dashboardClient = new DashboardClientV2({
  baseUrl: import.meta.env.VITE_HUB_API_URL + '/dashboard',
  securityWorker: async () => {
    return {
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: `Bearer ${HUB_API.getToken()}`,
      },
    }
  },
})

export interface ResponseError {
  error: HTTPValidationError
  status: number
}

export interface DetailError {
  detail: string
}

export function getErrorText(responseError: ResponseError | unknown) {
  if (!responseError) return ''

  let err = responseError as ResponseError

  if (!err.error) return ''

  let error = err.error

  if (typeof error.detail == 'string') return error.detail

  if (error.detail && error.detail[0]) return error.detail[0].msg

  return ''
}
