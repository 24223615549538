import { useNavigate } from 'react-router-dom'
import { Menu, MenuOption, useModal } from '@/ui'
import { WebhookRedacted } from '../../../api/dashboard'
import { Edit03, TerminalSquare, Trash } from '@/icons'
import { useGenerateCompanyPath } from '../../../libs/hooks'
import { GAME_WEBHOOKS_EDIT_PATH } from '../../../libs/routerPaths'
import { WebhookDeleteModal } from './WebhookDeleteModal'
import { WebhookTestModal } from './WebhookTestModal'
import { useTranslation } from 'react-i18next'

interface WebhookMoreMenuProps {
  webhook: WebhookRedacted
  onClose?: () => void
}

export const WebhookMoreMenu = ({ webhook, onClose }: WebhookMoreMenuProps) => {
  const { generatePath } = useGenerateCompanyPath()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const openDeleteModal = useModal<{ webhook: WebhookRedacted }>(props => <WebhookDeleteModal {...props} />)
  const openTestModal = useModal<{ webhook: WebhookRedacted }>(props => <WebhookTestModal {...props} />)

  const onClickEdit = () => {
    navigate(generatePath(GAME_WEBHOOKS_EDIT_PATH, { webhookId: webhook.id }))
    onClose?.()
  }

  const onClickTest = () => {
    openTestModal({ webhook })
    onClose?.()
  }

  const onClickDelete = () => {
    openDeleteModal({ webhook })
    onClose?.()
  }

  return (
    <Menu>
      <MenuOption icon={<Edit03 />} onClick={onClickEdit}>
        {t('webhook.update-details')}
      </MenuOption>
      <MenuOption icon={<TerminalSquare />} onClick={onClickTest}>
        {t('webhook.send-test')}
      </MenuOption>
      <MenuOption icon={<Trash className="text-text-error-primary" />} onClick={onClickDelete}>
        <span className="text-text-error-primary">{t('delete')}</span>
      </MenuOption>
    </Menu>
  )
}
