import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '../IconBase'

export const UnPin: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 16 16" ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.68955 5.63238L1.52858 2.47141C1.26823 2.21106 1.26823 1.78895 1.52858 1.5286C1.78892 1.26825 2.21103 1.26825 2.47138 1.5286L14.4714 13.5286C14.7317 13.7889 14.7317 14.2111 14.4714 14.4714C14.211 14.7318 13.7889 14.7318 13.5286 14.4714L10.3676 11.3105L10.1883 12.2071C10.1336 12.4805 10.084 12.7287 10.026 12.924C9.96796 13.1194 9.8709 13.378 9.64965 13.5743C9.36876 13.8235 8.99285 13.9374 8.62088 13.8858C8.32789 13.8452 8.10369 13.6838 7.94698 13.5534C7.79039 13.4231 7.61145 13.2442 7.41437 13.0471L6.11101 11.7437L2.38982 14.5346C2.12694 14.7318 1.75908 14.7056 1.52672 14.4733C1.29436 14.2409 1.26822 13.873 1.46538 13.6102L4.25628 9.88895L2.95302 8.58569C2.7559 8.38859 2.57691 8.20963 2.4466 8.05303C2.3162 7.89632 2.15487 7.67212 2.11425 7.37913C2.06268 7.00716 2.1765 6.63125 2.42574 6.35036C2.62206 6.1291 2.88065 6.03205 3.07608 5.97399C3.27138 5.91598 3.51954 5.86636 3.7929 5.81171L4.68955 5.63238ZM9.24531 10.1881L8.89883 11.9205C8.84134 12.208 8.80436 12.39 8.76848 12.5186C8.66729 12.4315 8.53555 12.3006 8.32825 12.0933L3.90675 7.67177C3.69945 7.46446 3.56852 7.33273 3.4814 7.23153C3.61002 7.19565 3.79203 7.15867 4.07951 7.10117L5.81187 6.7547L9.24531 10.1881Z"
        fill="currentColor"
      />
      <path
        d="M10.9441 7.66378C10.9259 7.67825 10.9084 7.69319 10.8917 7.7081L11.8477 8.66407L13.4036 7.99723C13.6102 7.90872 13.8043 7.82554 13.9575 7.74279C14.1181 7.65602 14.3151 7.52857 14.4562 7.31345C14.6419 7.03044 14.7083 6.6856 14.641 6.3539C14.5899 6.10176 14.4543 5.91023 14.3374 5.77003C14.2259 5.63629 14.0766 5.48697 13.9176 5.32802L10.6719 2.08236C10.513 1.92339 10.3637 1.77405 10.23 1.66258C10.0898 1.54571 9.89825 1.41006 9.64611 1.35894C9.31441 1.2917 8.96957 1.35811 8.68656 1.54373C8.47144 1.68484 8.34399 1.88192 8.25722 2.0425C8.17446 2.19567 8.09129 2.38978 8.00276 2.59641L7.33594 4.15233L8.29191 5.1083C8.30682 5.09162 8.32176 5.07411 8.33623 5.05589C8.37678 5.00485 8.41338 4.95079 8.44571 4.89418C8.48295 4.829 8.51157 4.76197 8.53352 4.71055L9.20649 3.14025C9.30005 2.92194 9.35791 2.78831 9.4059 2.69529C9.48501 2.76383 9.58835 2.86642 9.7563 3.03437L12.9656 6.2437C13.1336 6.41165 13.2362 6.515 13.3047 6.59411C13.2117 6.6421 13.078 6.69996 12.8597 6.79352L11.2895 7.46648C11.2381 7.48843 11.171 7.51706 11.1058 7.5543C11.0492 7.58664 10.9951 7.62324 10.9441 7.66378Z"
        fill="currentColor"
      />
    </IconBase>
  )
})
