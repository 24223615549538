import { cn } from '@dashboard/ui'
import { ItemRead } from '@/api/dashboard'
import { DEFAULT_IMAGE_PLACEHOLDER_ITEM } from '@/Settings'
import { Tooltip } from '@/ui'

interface BundleNestedItemsProps {
  item: ItemRead
}

export const BundleNestedItems = ({ item }: BundleNestedItemsProps) => {
  if (!item.nested_items_read) {
    return null
  }

  const allItemsCount = item.nested_items_read.length
  const visibleItems = item.nested_items_read.slice(0, 4)

  const renderNestedItem = (item: ItemRead, index: number) => {
    return (
      <Tooltip key={item.id} message={item.name} options={{ arrow: false, placement: 'top' }}>
        <div
          className={cn(
            'relative size-8 rounded-md border border-fg-primary bg-fg-tertiary bg-cover bg-center bg-no-repeat',
            index > 0 && 'ml-[-12px]',
          )}
          style={{
            backgroundImage: `url(${item.image_url || DEFAULT_IMAGE_PLACEHOLDER_ITEM})`,
            zIndex: allItemsCount - index,
          }}
        />
      </Tooltip>
    )
  }

  return (
    <div className="flex gap-1.5">
      <div className="relative z-0 flex">{visibleItems.map((item, index) => renderNestedItem(item, index))}</div>
      {allItemsCount > visibleItems.length && (
        <div className="flex size-8 items-center justify-center rounded-md border border-fg-primary bg-fg-tertiary text-paragraph-sm text-text-tertiary">
          +{allItemsCount - visibleItems.length}
        </div>
      )}
    </div>
  )
}
