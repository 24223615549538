import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '@/components/icons'

export const InsertRowBelowOutline: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 24 24" ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 15C4 16.1046 4.89543 17 6 17C6.55228 17 7 17.4477 7 18C7 18.5523 6.55228 19 6 19C3.79086 19 2 17.2091 2 15V6C2 3.79086 3.79086 2 6 2H18C20.2091 2 22 3.79086 22 6V15C22 17.2091 20.2091 19 18 19C17.4477 19 17 18.5523 17 18C17 17.4477 17.4477 17 18 17C19.1046 17 20 16.1046 20 15V11.8C20 11.6343 19.8657 11.5 19.7 11.5H4.3C4.13431 11.5 4 11.6343 4 11.8V15ZM4.3 9.5C4.13431 9.5 4 9.36569 4 9.2V6C4 4.89543 4.89543 4 6 4H18C19.1046 4 20 4.89543 20 6V9.2C20 9.36569 19.8657 9.5 19.7 9.5H4.3Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 14C12.5523 14 13 14.4477 13 15V16.7C13 16.8657 13.1343 17 13.3 17H15C15.5523 17 16 17.4477 16 18C16 18.5523 15.5523 19 15 19H13.3C13.1343 19 13 19.1343 13 19.3V21C13 21.5523 12.5523 22 12 22C11.4477 22 11 21.5523 11 21V19.3C11 19.1343 10.8657 19 10.7 19H9C8.44772 19 8 18.5523 8 18C8 17.4477 8.44772 17 9 17H10.7C10.8657 17 11 16.8657 11 16.7L11 15C11 14.4477 11.4477 14 12 14Z"
        fill="currentColor"
      />
    </IconBase>
  )
})
