import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const LoyaltyPointSolid: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 24 24" ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5588 2.42148C12.5942 1.8595 11.4058 1.85951 10.4412 2.42148L4.55885 5.84848C3.59423 6.41046 3 7.44904 3 8.573V15.427C3 16.551 3.59423 17.5895 4.55885 18.1515L10.4412 21.5785C11.4058 22.1405 12.5942 22.1405 13.5588 21.5785L19.4412 18.1515C20.4058 17.5895 21 16.551 21 15.427V8.573C21 7.44904 20.4058 6.41046 19.4412 5.84848L13.5588 2.42148ZM12.449 7.53455C12.2486 7.1873 11.7514 7.1873 11.551 7.53455L10.4354 9.46727C10.362 9.59447 10.239 9.68467 10.0964 9.71583L7.93008 10.1892C7.54086 10.2742 7.38724 10.7513 7.65258 11.051L9.12943 12.7189C9.22663 12.8286 9.27362 12.9746 9.25892 13.121L9.03565 15.3463C8.99553 15.7461 9.39773 16.041 9.76215 15.8789L11.7905 14.977C11.924 14.9177 12.076 14.9177 12.2095 14.977L14.2378 15.8789C14.6023 16.041 15.0045 15.7461 14.9644 15.3463L14.7411 13.121C14.7264 12.9746 14.7734 12.8286 14.8706 12.7189L16.3474 11.051C16.6128 10.7513 16.4591 10.2742 16.0699 10.1892L13.9036 9.71583C13.761 9.68467 13.638 9.59447 13.5646 9.46727L12.449 7.53455Z"
        fill="currentColor"
      />
    </IconBase>
  );
});
