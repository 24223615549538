import { AnimatePresence, HTMLMotionProps, motion } from 'framer-motion'
import { useKey } from 'react-use'
import { cn, useBodyScrollBlockEffect } from '@dashboard/ui'
import { SpinnerAghanim } from '@/components/ui'
import { ModalProps } from './types'
import { entryMotionProps } from './ModalProvider'

export const Modal = ({ children, isLoading, onClose, ...rest }: ModalProps & HTMLMotionProps<'div'>) => {
  useBodyScrollBlockEffect()
  useKey('Escape', onClose)

  return (
    <AnimatePresence initial={false} mode="popLayout">
      {isLoading ? (
        <motion.div {...entryMotionProps} key="spinner" className="m-auto invert">
          <SpinnerAghanim />
        </motion.div>
      ) : (
        <motion.div
          {...rest}
          key="modal"
          className={cn(
            'relative m-auto w-full max-w-xl overflow-hidden rounded-2xl1 bg-fg-primary p-12 shadow-2xl',
            rest.className,
          )}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  )
}
