import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useWebsitesQuery } from '../../api/useWebsitesQuery'
import { NewsForm } from './components'
import { dashboardClient } from '../../api'
import { NewsCategory, NewsCreate, NewsGroup, NewsRead, NewsUpdate } from '../../api/dashboard'
import { Drawer } from '@/ui'

interface EditNewsDialogProps {
  item?: NewsRead
  onClose: (saved: boolean) => void
}

const EditNewsDialog = ({ item, onClose }: EditNewsDialogProps) => {
  const { companyId, gameId, group } = useParams() as { companyId: string; gameId: string; group: string }
  const [open, setOpen] = useState(false)
  //const navigate = useNavigate()
  const { data: websites = [] } = useWebsitesQuery(companyId as string, gameId as string)

  useEffect(() => {
    setOpen(true)
  }, [setOpen])

  const onSubmitForm = async (data: NewsCreate | NewsUpdate) => {
    if (item?.id) {
      await dashboardClient.v1.updateNews(companyId, gameId, item.id, data)
    } else {
      await dashboardClient.v1.createNews(companyId, gameId, data as NewsCreate)
      setTimeout(() => {
        onClose(true)
      }, 150)
      // navigate(generatePath(HUB_NEWS_DETAIL_PATH, { companyId, gameId, group: group || 'other', newsId: created.id }), {
      //   replace: true,
      // })
    }
    return data
  }

  const onClickClose = () => {
    setOpen(false)
    setTimeout(() => {
      onClose(false)
    }, 150)
  }

  let createCategory = NewsCategory.CompanyNews
  if (group === NewsGroup.Update) {
    createCategory = NewsCategory.Update
  } else if (group === NewsGroup.Event) {
    createCategory = NewsCategory.Event
  }

  return (
    <Drawer open={open}>
      <NewsForm
        website={websites[0]}
        item={item}
        onSubmit={onSubmitForm}
        onClose={onClickClose}
        createCategory={createCategory}
      />
    </Drawer>
  )
}

export default EditNewsDialog
