import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const ChevronLeftOutline: AghanimIcon = forwardRef(function (props, ref) {
  return (
    <IconBase {...props} viewBox="0 0 24 24" ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6508 4.24076C16.0701 4.60018 16.1187 5.23148 15.7592 5.65081L10.3171 12L15.7592 18.3492C16.1187 18.7686 16.0701 19.3999 15.6508 19.7593C15.2314 20.1187 14.6001 20.0701 14.2407 19.6508L8.24072 12.6508C7.91973 12.2763 7.91973 11.7237 8.24072 11.3492L14.2407 4.34923C14.6001 3.9299 15.2314 3.88134 15.6508 4.24076Z"
        fill="currentColor"
      />
    </IconBase>
  );
});
