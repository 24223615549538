interface EngagementInfoBlockProps {
  title: string
  desc: string
}

export const EngagementInfoBlock = ({ title, desc }: EngagementInfoBlockProps) => (
  <div className="flex flex-1 flex-col gap-3">
    <span className="h-6 text-base font-semibold text-text-secondary">{title}</span>
    <div className="flex flex-col">
      <span className="text-text-tertiary">{desc}</span>
    </div>
  </div>
)
