import { ClassValue, clsx } from 'clsx';
import { extendTailwindMerge } from 'tailwind-merge';
import tailwindConfig from '../../tailwindConfig';

const fontSizeCssKeys = Object.keys(tailwindConfig.theme?.extend?.fontSize || {}).map(key => `text-${key}`);

const twMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      'font-size': fontSizeCssKeys,
    },
    conflictingClassGroups: {
      'font-size': ['font-weight', 'tracking'],
    },
  },
});

export const cn = (...inputs: ClassValue[]) => {
  return twMerge(clsx(inputs));
};
