import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { creatorsPlatformsQueryOptions } from '@/layouts/engagement/api'

export const useCreatorsPlatforms = () => {
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const { data: platforms, isLoading, refetch } = useQuery(creatorsPlatformsQueryOptions(companyId, gameId))
  return {
    platforms,
    isLoading,
    refetch,
  }
}
