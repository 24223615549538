import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '@/icons'

export const AIIcon: AghanimIcon = forwardRef(function (props, ref) {
  return (
    <IconBase {...props} viewBox="0 0 14 15" ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.583374 4.54704V3.45328C1.89217 3.45328 2.95316 2.3923 2.95316 1.0835H4.04691C4.04691 2.3923 5.10791 3.45329 6.41671 3.45329V4.54704C5.10791 4.54704 4.04692 5.60803 4.04692 6.91683H2.95317C2.95317 5.60803 1.89217 4.54704 0.583374 4.54704ZM3.50004 5.04811C3.76979 4.62796 4.12784 4.26991 4.54798 4.00017C4.12784 3.73042 3.76979 3.37237 3.50004 2.95222C3.23029 3.37237 2.87224 3.73041 2.4521 4.00016C2.87224 4.26991 3.23029 4.62796 3.50004 5.04811ZM10.0844 2.56236C10.5007 2.1461 11.1756 2.1461 11.5919 2.56236L12.5212 3.49167C12.9374 3.90793 12.9374 4.58283 12.5212 4.9991L5.12129 12.399C5.01921 12.5011 4.89747 12.5813 4.76344 12.6349L3.2146 13.2545C2.34448 13.6025 1.481 12.7391 1.82905 11.8689L2.44859 10.3201C2.5022 10.1861 2.58248 10.0643 2.68455 9.96225L10.0844 2.56236ZM10.8382 3.31608L3.43826 10.716L2.81873 12.2648L4.36757 11.6453L11.7675 4.24538L10.8382 3.31608ZM12.8334 11.5835V11.389C11.9743 11.389 11.2778 10.6926 11.2778 9.8335H11.0834H10.8889C10.8889 10.6926 10.1925 11.3891 9.33337 11.3891V11.5835V11.7779C10.1925 11.7779 10.8889 12.4744 10.8889 13.3335H11.0834H11.2778C11.2778 12.4744 11.9743 11.7779 12.8334 11.7779V11.5835Z"
        fill="currentColor"
      />
    </IconBase>
  )
})
