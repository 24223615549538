import { ItemRead } from '@/api/dashboard'
import { dashboardClient } from '@/api'

export const saveItemProperties = async (companyId: string, gameId: string, old: ItemRead, data: ItemRead) => {
  const deleting = old.properties.filter(it => it.id).filter(it => !data.properties?.find(n => n.id == it.id))

  if (deleting.length) {
    await Promise.all(
      deleting.map(it => dashboardClient.v1.deleteItemPropertyRelation(companyId, gameId, old.id, it.id)),
    )
  }

  if (!data.properties?.length) {
    return
  }

  const creating = data.properties.filter(it => !it.id)

  if (creating.length) {
    await Promise.all(creating.map(it => dashboardClient.v1.createItemPropertyRelation(companyId, gameId, old.id, it)))
  }

  const updating = data.properties.filter(it => it.id).filter(it => !!old.properties.find(n => n.id == it.id))

  if (updating.length) {
    await Promise.all(
      updating.map(it => dashboardClient.v1.updateItemPropertyRelation(companyId, gameId, old.id, it.id, it)),
    )
  }
}
