import { useQuery } from '@tanstack/react-query'
import { dashboardClient } from '@/api'

/**
 *
 * @param companyId
 * @param gameId
 * @param user_id
 */
export const usePlayerVirtualSkuItems = (companyId: string, gameId: string, user_id: string) => {
  return useQuery({
    queryKey: ['virtual_sku_items_user', gameId, user_id],
    queryFn: () => dashboardClient.v1.getUserStoreItems(user_id, companyId, gameId),
    select: response => response.data,
  })
}
