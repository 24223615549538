import { useCallback, useMemo } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useGenerateCompanyPath } from '@/libs'
import { useSocketListener } from '@/providers'
import { WebsiteGenerateStatusMessage, getGenerationProgressQuery } from '@/layouts/get-started'
import { calcGenerationProgress, getGenerationProgressIsStuck } from '../libs'

export const useGenerationProgressListener = () => {
  const { companyId, gameId } = useGenerateCompanyPath()
  const ql = useQueryClient()
  const query = useQuery(getGenerationProgressQuery(companyId, gameId))

  const onMessage = useCallback(
    (message: WebsiteGenerateStatusMessage) => {
      if (message.type === 'website.generate_status') {
        ql.setQueryData(getGenerationProgressQuery(companyId, gameId).queryKey, old => ({
          ...old,
          ...message.payload.state,
          start_at: message.payload.start_at,
        }))
      }
    },
    [ql.setQueryData, companyId, gameId],
  )

  useSocketListener<WebsiteGenerateStatusMessage>(onMessage)

  return query
}

const HUB_MAX_WAITING_TIME_MS = 60 * 1000

export const useHubGeneratingStatus = () => {
  const { data: generationProgress } = useGenerationProgressListener()

  const isHubGenerated = useMemo(
    () =>
      generationProgress
        ? getGenerationProgressIsStuck(generationProgress, HUB_MAX_WAITING_TIME_MS) ||
          calcGenerationProgress(generationProgress) === 100
        : false,
    [generationProgress],
  )

  return { isHubGenerated }
}
