import { CSSProperties, ReactElement, forwardRef, useId, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { useSelect } from 'downshift'
import { FloatingPortal, useMergeRefs } from '@floating-ui/react'
import styled from '@emotion/styled'
import { ChevronSelectorVertical } from '@/icons'
import { Menu, MenuOption, MenuOptionProps } from '../Menu'
import { useSelectFloating } from './useSelectFloating'
import { getFloatingMotionProps } from '../Floating'
import shouldForwardProp from '@emotion/is-prop-valid'
import { css } from '@emotion/react'
import { FieldErrors } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { FieldValidationMessage, MenuSearch } from '@/components/ui'
import { useTranslation } from 'react-i18next'

export interface SelectOption extends Pick<MenuOptionProps, 'children' | 'icon'> {
  value: string | number | null | undefined
  desc?: ReactElement | string | undefined | null
  extraRight?: ReactElement | string | undefined | null
}

export interface SelectProps {
  name?: string
  placeholder?: string
  disabled?: boolean
  options: (SelectOption | undefined)[]
  value?: SelectOption['value']
  onChange: (value: SelectOption['value']) => void
  style?: CSSProperties
  action?: React.ReactNode
  color?: 'default' | 'success' | 'warning' | 'error'
  errors?: FieldErrors
  dropDownWidth?: string
  filter?: (option: SelectOption, search: string) => boolean
  searchPlaceholder?: string
  extraDown?: React.ReactNode
  extraTop?: React.ReactNode
}

export const SelectField = styled('div', { shouldForwardProp })<{
  $color: SelectProps['color']
}>`
  background: ${p => p.theme.palette.background.fgPrimary};
  border: 1px solid ${p => p.theme.palette.grey.borderPrimary};
  color: ${p => p.theme.palette.text.primary};
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  gap: 6px;
  width: 100%;
  height: 48px;
  outline: none;
  cursor: pointer;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  transition:
    border-color ease-in-out 160ms,
    box-shadow ease-in-out 160ms;

  &:hover {
    border-color: #cbd5e1;
    box-shadow: 0 0 0 2px rgba(0, 151, 228, 0.14);
  }

  &[data-focused='true'],
  &:focus {
    border-color: ${p => p.theme.palette.primary.main};
    box-shadow: 0 0 0 2px rgba(0, 151, 228, 0.14);
  }

  &[aria-disabled='true'] {
    background-color: #f1f5f9;
    border-color: #e2e8f0;
    color: #94a3b8;
    pointer-events: none;
  }

  ${p =>
    p.$color === 'error' &&
    css`
      background: ${p.theme.palette.background.fgPrimary};
      border-color: ${p.theme.palette.error.main};
      color: ${p.theme.palette.text.secondary};

      &:has(input:hover),
      &:has(input:focus) {
        color: #334155;
        box-shadow: 0 0 0 3px rgba(240, 68, 56, 0.14);
      }

      &:has(input:focus) {
        color: #000000;
        border-color: ${p.theme.palette.error.main};
      }
    `}
`

export const Value = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  min-width: 0;
  font-size: 16px;
  line-height: 20px;
  white-space: nowrap;
  width: 100%;

  & svg {
    flex-shrink: 0;
  }

  & div[role='img'] {
    max-height: 40px;
    max-width: 40px;
  }

  & span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: inherit;
    line-height: inherit;
  }
`

export const Placeholder = styled(Value)`
  color: #475569;
`

export const Action = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: auto;
  flex-shrink: 0;
  color: ${p => p.theme.palette.text.secondary};
`

const FloatingContainer = styled.div`
  z-index: 3000;
`

export const Select = forwardRef<HTMLDivElement, SelectProps>(function Select(
  {
    action,
    color,
    style,
    dropDownWidth,
    options: inputOptions,
    value,
    onChange,
    placeholder = '',
    disabled = false,
    errors,
    filter,
    extraDown,
    extraTop,
    ...rest
  },
  ref,
) {
  const { t } = useTranslation()
  const id = useId()
  const [searchValue, setSearchValue] = useState('')
  const options = inputOptions.filter(it => !!it).filter(o => (filter && searchValue ? filter(o, searchValue) : true))
  if (!placeholder) {
    placeholder = t('select.placeholder')
  }
  const {
    isOpen,
    selectedItem: selectedOption,
    highlightedIndex,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
    openMenu,
    closeMenu,
  } = useSelect({
    items: options,
    itemToString: option => {
      if (!option) {
        return ''
      }
      if (typeof option.children == 'string') {
        return option.children.toString()
      }

      return option.children as string
    },
    selectedItem: inputOptions.find(option => option?.value === value) || null,
    onSelectedItemChange: ({ selectedItem: option }) => {
      onChange(option!.value)
      setSearchValue('')
    },
  })

  const { refs, floatingStyles, placement } = useSelectFloating(isOpen)
  const referenceMergedRef = useMergeRefs([refs.setReference, ref])

  return (
    <>
      <SelectField
        $color={color}
        style={style}
        {...getToggleButtonProps({ ref: referenceMergedRef, disabled: disabled })}
        data-focused={isOpen}
        data-testid={rest.name}
        aria-disabled={disabled}
        onBlur={e => {
          e.preventDefault()
          e.stopPropagation()
        }}
      >
        {selectedOption ? (
          <Value>
            {selectedOption?.icon}
            {typeof selectedOption.children == 'string' ? (
              <span>{selectedOption.children}</span>
            ) : (
              selectedOption.children
            )}
            {selectedOption?.extraRight ? <div className="ml-auto">{selectedOption.extraRight}</div> : null}
          </Value>
        ) : (
          <Placeholder>{placeholder}</Placeholder>
        )}
        <Action>
          {action}
          <ChevronSelectorVertical size={16} />
        </Action>
      </SelectField>

      <FloatingPortal>
        <div {...getMenuProps()}>
          <AnimatePresence>
            {isOpen && (!!options.length || searchValue || extraTop) && (
              <FloatingContainer ref={refs.setFloating} style={floatingStyles}>
                <motion.div {...getFloatingMotionProps(placement)} style={{ maxHeight: 'inherit' }}>
                  <Menu style={{ maxWidth: 'none', width: dropDownWidth }}>
                    {extraTop}
                    {filter && (
                      <div className="-mb-2.5 py-2">
                        <MenuSearch
                          placeholder={rest.searchPlaceholder}
                          value={searchValue}
                          onChange={e => {
                            setSearchValue(e.target.value)
                            openMenu()
                          }}
                        />
                      </div>
                    )}
                    {options.map((option, index) => (
                      <MenuOption
                        data-testid={`menu-option-${id}-${option!.value}`}
                        key={`menu-option-${id}-${option!.value}`}
                        data-higlighted={index === highlightedIndex}
                        data-selected={option!.value === selectedOption?.value}
                        {...getItemProps({ item: option, index })}
                        {...option}
                      >
                        {option!.children}
                      </MenuOption>
                    ))}
                    {extraDown && <div onClick={() => closeMenu()}>{extraDown}</div>}
                  </Menu>
                </motion.div>
              </FloatingContainer>
            )}
          </AnimatePresence>
        </div>
      </FloatingPortal>

      {rest.name && errors && (
        <ErrorMessage
          name={rest.name}
          errors={errors}
          render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
        />
      )}
    </>
  )
})
