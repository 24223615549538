import { QueryClient, queryOptions } from '@tanstack/react-query'
import { dashboardClient } from '@/api'

export const couponStatQuery = (companyId: string, gameId: string, couponId: string) => {
  return queryOptions({
    queryKey: [gameId, couponId, 'coupon-stat'],
    queryFn: () =>
      dashboardClient.v1.getCouponStat(companyId, gameId, couponId, { last_count_days: 30 }).then(r => r.data),
  })
}

export const couponTotalStatQuery = (companyId: string, gameId: string, ids: string[]) => {
  return queryOptions({
    queryKey: [gameId, 'coupon-total-stat', { ids }],
    queryFn: () => dashboardClient.v1.getTotalCouponStat(companyId, gameId, { ids: ids.join(',') }).then(r => r.data),
  })
}

export const refetchCouponStatQuery = async (ql: QueryClient, companyId: string, gameId: string, couponId: string) => {
  await ql.refetchQueries({
    queryKey: couponStatQuery(companyId, gameId, couponId).queryKey.slice(0, -1),
    exact: false,
  })
}

export const refetchCouponTotalStatQuery = async (
  ql: QueryClient,
  companyId: string,
  gameId: string,
  ids: string[],
) => {
  await ql.refetchQueries({
    queryKey: couponTotalStatQuery(companyId, gameId, ids).queryKey.slice(0, -1),
    exact: false,
  })
}
