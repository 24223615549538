/**
 *
 * @param locale
 * @param options
 * @returns
 */
export const getInputMaskValue = (locale: string) => {
  const year = 2222;
  const month = 12;
  const day = 15;
  const date = new Date(year, month - 1, day);
  const formattedDate = new Intl.DateTimeFormat(locale).format(date);
  return formattedDate.replace(`${year}`, 'yyyy').replace(`${month}`, 'mm').replace(`${day}`, 'dd');
};
