import { Controller, useFormContext } from 'react-hook-form'
import { Block } from '@/api/dashboard'
import { FieldValidationMessage } from '@/ui'
import { ErrorMessage } from '@hookform/error-message'
import i18next from 'i18next'
import SelectSkuPanel from '@/layouts/components/SelectSkuPanel'

export const SelectSkuForm = () => {
  const {
    watch,
    setValue,
    formState: { errors },
    control,
  } = useFormContext<Block>()

  const [storeId, storeItemId] = watch(['store_id', 'store_item_id'])

  return (
    <Controller
      control={control}
      name="item_id"
      rules={{ required: i18next.t('validation.required') }}
      render={({ field }) => (
        <SelectSkuPanel
          onChange={(item_id, store_id, store_item_id) => {
            field.onChange(item_id)
            setValue('store_item_id', store_item_id as unknown as string)
            setValue('store_id', store_id as unknown as string)
          }}
          itemId={field.value}
          storeId={storeId}
          storeItemId={storeItemId}
        >
          <ErrorMessage
            name="item_id"
            errors={errors}
            render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
          />
        </SelectSkuPanel>
      )}
    />
  )
}
