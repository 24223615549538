import { infiniteQueryOptions } from '@tanstack/react-query'
import { dashboardClient } from '@/api'
import { ObjectType, State } from '@/api/dashboard'

export interface TranslationsQueryParams {
  /** Locale Id */
  locale_id: string
  /** Q */
  q?: string
  /** An enumeration. */
  object_type?: ObjectType
  /** An enumeration. */
  state?: State
  /**
   * Limit
   * A limit on the number of objects to be returned
   * @exclusiveMin 1
   * @max 200
   * @default 10
   */
  limit?: number
  /**
   * Starting After
   * The cursor for pagination
   */
  starting_after?: string
  /**
   * Ending Before
   * The cursor for pagination
   */
  ending_before?: string
}

export const translationsInfiniteQueryOptions = (
  companyId: string,
  gameId: string,
  { locale_id, ...params }: TranslationsQueryParams,
) =>
  infiniteQueryOptions({
    queryKey: [gameId, 'translations', locale_id, params],
    queryFn: async ({ pageParam }) => {
      const { data } = await dashboardClient.v1.getTranslations(companyId, gameId, {
        ...params,
        locale_id,
        starting_after: pageParam || undefined,
      })
      return data
    },
    initialPageParam: '',
    getNextPageParam: lastPage => {
      return lastPage.has_more ? lastPage?.data.at(-1)?.cursor_id || null : null
    },
  })
