import { useContext } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Floating } from '@/ui'
import { AuthContext } from '@/Context'
import UserAvatar from '../../components/UserAvatar'
import { UserProfileMenu } from './components'

const ProfileButton = () => {
  const { user } = useAuth0()
  const context = useContext(AuthContext)

  return (
    <Floating menu={<UserProfileMenu />} placement="bottom-end">
      <div className="cursor-pointer" data-testid="header/profile-button">
        <UserAvatar url={context?.customer?.avatar_url || user?.picture} />
      </div>
    </Floating>
  )
}

export default ProfileButton
