import { forwardRef, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DateRange } from 'react-day-picker';
import dayjs from 'dayjs';
import { CalendarOutline, ChevronDownOutline } from '../../icons';
import { cn } from '../../libs';
import { Popper } from '../Popper';
import { InputDateRangeMenu } from './InputDateRangeMenu';

export interface InputDateRangePreset {
  label: string;
  range: DateRange;
}

export interface InputDateRangeProps {
  /**
   * Presets for pre defined date ranges.
   */
  presets?: InputDateRangePreset[];
  /**
   * Placeholder of the picker.
   */
  placeholder?: string;
  /**
   * Disabled state of the picker.
   */
  disabled?: boolean;
  /**
   * Value of the date input.
   */
  value: DateRange | null;
  /**
   * Change handler for the date input.
   */
  onChange: (value: DateRange | null) => void;
}

export const InputDateRange = forwardRef<HTMLButtonElement, InputDateRangeProps>(
  ({ presets = [], value, placeholder, onChange, ...rest }, ref) => {
    const { t } = useTranslation('ui');

    const selectedPresetIndex = useMemo(
      () =>
        presets.findIndex(preset => {
          return dayjs(value?.from).isSame(preset.range.from) && dayjs(value?.to).isSame(preset.range.to);
        }),
      [presets, value?.from, value?.to],
    );

    const onChangeCalendar = useCallback(
      (value: DateRange | null) => {
        onChange(value);
      },
      [onChange],
    );

    const renderFormattedDate = (date: Date) => {
      return (
        <div className="flex gap-1">
          <span>
            {t('intl.DateTime', {
              formatParams: { val: { dateStyle: 'medium' } },
              value: date,
            })}
          </span>
          {/*<span className="text-text-tertiary group-disabled/input-popper:text-[inherit]">*/}
          {/*  {t('intl.DateTime', {*/}
          {/*    formatParams: { value: { timeStyle: 'short' } },*/}
          {/*    value: date,*/}
          {/*  })}*/}
          {/*</span>*/}
        </div>
      );
    };

    return (
      <Popper
        popper={props => <InputDateRangeMenu {...props} presets={presets} value={value} onChange={onChangeCalendar} />}
        params={{ toggle: false, width: 'auto' }}
        focusManager={{ initialFocus: selectedPresetIndex }}
      >
        <button
          {...rest}
          ref={ref}
          className={cn(
            'group/input-popper flex h-7 items-center gap-1 text-nowrap rounded-md  border border-border-primary px-3 text-caption-md text-text-quarterary shadow-xs ring-brand transition-colors',

            'data-[open=true]:border-border-brand-filled',
            'data-[open=true]:placeholder:text-text-quarterary-hover',
            'data-[open=true]:text-text-primary',
            'data-[open=true]:ring-2',

            'focus:border-border-brand-filled',
            'focus:placeholder:text-text-quarterary-hover',
            'focus:text-text-primary',
            'focus:ring-2',

            'disabled:bg-fg-disabled',
            'disabled:border-border-disabled',
            'disabled:text-text-disabled',
            'disabled:placeholder:text-text-disabled',
            'disabled:pointer-events-none',
            'disabled:ring-0',
            'disabled:shadow-none',
            !value && 'hover:text-text-quarterary-hover',
            !!value && 'text-text-secondary',
            rest.disabled && 'pointer-events-none',
          )}
        >
          {!!presets.length && (
            <>
              <div className="flex items-center gap-1">
                <div>{presets[selectedPresetIndex]?.label || 'Custom'}</div>
                <ChevronDownOutline size={14} />
              </div>
              <div className="mx-2 h-4 w-px bg-border-secondary" />
            </>
          )}
          <CalendarOutline size={14} />
          {value ? (
            <div className="flex items-center gap-1.5">
              {value.from && renderFormattedDate(value.from)}
              <span>–</span>
              {value.to && renderFormattedDate(value.to)}
            </div>
          ) : (
            placeholder || t('input-date-range.placeholder')
          )}
        </button>
      </Popper>
    );
  },
);
