import { ReactNode, forwardRef } from 'react'
import { Link } from 'react-router-dom'
import { ArrowUpRight, Checkbox, CheckboxProps, cn } from '@dashboard/ui'

interface WebhookEventCheckboxProps extends Omit<CheckboxProps, 'children'> {
  event: {
    label: ReactNode
    desc: ReactNode
    moreLink?: string
  }
}

export const WebhookEventCheckbox = forwardRef<HTMLInputElement, WebhookEventCheckboxProps>(
  function WebhookEventCheckbox({ event, ...rest }, ref) {
    return (
      <Checkbox
        {...rest}
        ref={ref}
        className={cn(
          'border-b border-border-secondary p-3 last:border-none',
          'hover:bg-fg-primary-hover',
          'hover:border-border-secondary-hover',
          'has-[:checked]:bg-fg-primary-alt',
          'has-[:checked]:border-border-secondary-hover',
        )}
        caption={
          <>
            {event.desc}{' '}
            {event.moreLink && (
              <Link
                className="mt-3 flex items-center gap-2 font-semibold text-text-secondary hover:text-text-brand-primary"
                to={event.moreLink}
                target="_blank"
              >
                <span>Learn more about webhook</span>
                <ArrowUpRight size={14} />
              </Link>
            )}
          </>
        }
      >
        {event.label}
      </Checkbox>
    )
  },
)
