import {
  Attribute,
  CAMPAIGN_ATTRIBUTES,
  CalcPlayerAttributes,
  GameHubVisitStoreAttributes,
  PLAYER_ATTRIBUTES,
} from './types'

export function findAttr(attr: string) {
  let a = PLAYER_ATTRIBUTES.find(it => it.id == attr)

  if (a) {
    return a
  }

  return Object.values(CAMPAIGN_ATTRIBUTES)
    .flat()
    .find(it => it.id == attr)
}

export function findAttrById(id: string): Attribute | undefined {
  if (id == 'purchased_item_ids') {
    return PLAYER_ATTRIBUTES.find(it => it.id == CalcPlayerAttributes.has_purchase)
  }

  if (id == 'claimed_item_ids') {
    return PLAYER_ATTRIBUTES.find(it => it.id == CalcPlayerAttributes.has_claim_item)
  }

  let attr = PLAYER_ATTRIBUTES.find(it => it.id == id)

  if (attr) {
    return attr
  }

  return [...PLAYER_ATTRIBUTES, ...Object.values(CAMPAIGN_ATTRIBUTES).flat(), ...GameHubVisitStoreAttributes].find(
    it => it.id == id,
  )
}
