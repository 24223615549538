import { useContext } from 'react'
import { Link, generatePath, useParams } from 'react-router-dom'
import { isAdmin } from '@/security'
import { Button, PageHeader, Tab, Table, TableCell, TableRow, TableRowSkeleton, TableZeroState, Tabs } from '@/ui'

import { AuthContext, IAuthContext } from '@/Context'
import { useAllPluginQuery, usePluginInstancesQuery } from '@/layouts/app-connect/api'
import { PluginRead } from '@/api/dashboard'
import { useSearchFilter } from '@dashboard/ui'
import { useTranslation } from 'react-i18next'
import { APP_CONNECT_PLUGIN_DETAIL_PATH, useFeatureFlags } from '@/libs'
import ComingSoon from '@/components/ComingSoon'
import { PluginStatus } from '@/layouts/app-connect/components/PluginStatus'
import CenteredProgress from '@/components/CenteredProgress'

export type PluginTableFilterState = 'state'

export const PluginTable = () => {
  const context = useContext(AuthContext) as IAuthContext
  const canUserEdit = isAdmin(context.customer)
  const { t } = useTranslation()
  const { featureFlags, isLoading: isSettingsLoading } = useFeatureFlags()
  const filter = useSearchFilter<PluginTableFilterState>()
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }

  const filterEnabled = filter.values.state ? filter.values.state == 'enabled' : undefined
  const { data: allPlugins = [], isLoading } = useAllPluginQuery(companyId, gameId)
  const { data: pluginInstances = [], isLoading: isFilterLoading } = usePluginInstancesQuery(companyId, gameId, {
    enabled: filterEnabled,
  })

  const renderPluginRow = (plugin: PluginRead) => {
    const instance = pluginInstances.find(it => it.plugin.id === plugin.id)

    return (
      <TableRow key={plugin.id}>
        <TableCell width="100%">
          <Link
            className="flex items-center gap-3"
            to={
              canUserEdit
                ? generatePath(APP_CONNECT_PLUGIN_DETAIL_PATH, {
                    companyId: companyId,
                    gameId: gameId,
                    pluginSlug: plugin.slug,
                  })
                : ''
            }
          >
            <div
              className="size-[32px] shrink-0 bg-cover bg-center bg-no-repeat"
              style={{
                backgroundImage: `url(${plugin.image_url})`,
              }}
            />
            <div className="mr-auto overflow-hidden">
              <div className="mb-0.5 truncate leading-5">{plugin.name}</div>
              <div className="text-xs font-medium	leading-none text-text-quarterary-hover">{plugin.subtitle}</div>
            </div>
          </Link>
        </TableCell>
        <TableCell width={140}>{t(`plugin.table.category.${plugin.category}`)}</TableCell>
        <TableCell width={140}>
          <PluginStatus status={instance?.enabled} />
        </TableCell>
      </TableRow>
    )
  }

  if (isSettingsLoading) {
    return <CenteredProgress />
  }

  if (!featureFlags.app_connect_enabled) {
    return <ComingSoon />
  }

  const items = filter.values.state
    ? pluginInstances.map(it => it.plugin)
    : allPlugins.sort((a, b) => {
        const aInstance = pluginInstances.find(it => it.plugin.id === a.id)
        const bInstance = pluginInstances.find(it => it.plugin.id === b.id)

        if (!aInstance && bInstance) {
          return 1
        }

        if (aInstance && !bInstance) {
          return -1
        }

        if (aInstance && bInstance) {
          if (aInstance.enabled === bInstance.enabled) {
            return a.name.localeCompare(b.name)
          }

          if (aInstance.enabled) {
            return -1
          }
          if (bInstance.enabled) {
            return 1
          }
        }

        return a.name.localeCompare(b.name)
      })

  return (
    <div className="flex h-full flex-col">
      <PageHeader>{t('plugin.title')}</PageHeader>

      <Tabs className="mb-4">
        <Tab
          isActive={!filter.values.state}
          onClick={() => {
            filter.onChange({ ...filter.values, state: '' })
          }}
        >
          {t('plugin.tab.all')}
        </Tab>
        <Tab
          isActive={filter.values.state == 'enabled'}
          onClick={() => {
            filter.onChange({ ...filter.values, state: 'enabled' })
          }}
        >
          {t('plugin.tab.enabled')}
        </Tab>
        <Tab
          isActive={filter.values.state == 'disabled'}
          onClick={() => {
            filter.onChange({ ...filter.values, state: 'disabled' })
          }}
        >
          {t('plugin.tab.disabled')}
        </Tab>
      </Tabs>

      {isLoading || isFilterLoading ? (
        <TableRowSkeleton rowCount={10} columnCount={3} />
      ) : items.length === 0 ? (
        <TableZeroState
          title={t(filterEnabled ? 'plugin.table.zero.active.title' : 'plugin.table.zero.deactivated.title')}
          message={t(filterEnabled ? 'plugin.table.zero.active.desc' : 'plugin.table.zero.deactivated.desc')}
          buttons={
            filterEnabled &&
            canUserEdit && (
              <Button variant="primary" onClick={() => filter.onChange({ state: '' })}>
                {t('plugin.table.zero.active.button')}
              </Button>
            )
          }
        />
      ) : (
        <>
          <Table>
            <TableRow variant="header">
              <TableCell width="100%">{t('plugin.table.app')}</TableCell>
              <TableCell width={140}>{t('plugin.table.category')}</TableCell>
              <TableCell width={140}>{t('plugin.table.status')}</TableCell>
            </TableRow>
            {items.map(it => renderPluginRow(it))}
          </Table>
        </>
      )}
    </div>
  )
}
