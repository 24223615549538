import { Fragment, cloneElement, createElement, forwardRef, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AvatarUploader, FieldGroup } from '@/ui'
import {
  Button,
  Email,
  FormErrorMessage,
  Input,
  Modal,
  ModalContent,
  ModalFooter,
  ModalProps,
  ModalTitle,
  PlusContained,
  cn,
  formatHookFormErrors,
} from '@dashboard/ui'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { EngagementAddButton } from '@/layouts/engagement/component'
import { S3Bucket } from '@/types'
import { creatorsCreateMutation, creatorsUpdateMutation } from '@/layouts/engagement/api'
import { isEmail } from '@/util'
import { getErrorText } from '@/api'
import { useDebounce } from '@/hooks'
import { useCreatorsDefinePlatform } from '@/layouts/engagement/hooks'
import { SOCIAL_ICONS } from '@/layouts/engagement/social-icons'
import { urlRegexp } from '@/libs'
import { CreatorRead, CreatorSocialPlatform } from '@/api/dashboard'
import { Trash } from '@/icons'

const SOCIAL_MEDIA_LIMIT = 5

interface SocialMediaFieldProps {
  defaultValue: string
  onChange: (url: string) => void
  onDelete: () => void
}

const SocialMediaField = forwardRef<HTMLInputElement, SocialMediaFieldProps>(
  ({ defaultValue, onChange, onDelete, ...rest }, ref) => {
    const [url, setUrl] = useState(defaultValue)
    const debouncedURL = useDebounce(url, 500)
    const { platform, isLoading } = useCreatorsDefinePlatform(debouncedURL)

    return (
      <div className="inline-flex justify-between gap-2">
        <div className="grow">
          <Input
            {...rest}
            ref={ref}
            extraLeft={{
              icon: () =>
                cloneElement(
                  createElement(SOCIAL_ICONS[platform?.platform || CreatorSocialPlatform.Other], {
                    className: cn(isLoading && 'animate-pulse'),
                  }),
                ),
            }}
            onChange={e => {
              setUrl(e.target.value)
              onChange(e.target.value)
            }}
          />
        </div>
        <Button variant="tertiary" color="danger" onClick={onDelete}>
          <Trash size={16} />
        </Button>
      </div>
    )
  },
)

export interface CreatorModalProps {
  creator?: CreatorRead
}

export const CreatorModal = ({ creator, ...rest }: CreatorModalProps & ModalProps) => {
  const { t } = useTranslation()
  const isAdd = useMemo(() => !creator, [creator])
  const title = useMemo(() => t(isAdd ? 'creators.add' : 'creators.edit'), [isAdd])
  const [imageLoading, setImageLoading] = useState<boolean>(false)
  const [submitError, setSubmitError] = useState<string | null>(null)

  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
  } = useForm<Omit<CreatorRead, 'id'>>({
    defaultValues: {
      social_medias: [],
      ...creator,
    },
  })
  const medias = useFieldArray({
    control,
    name: 'social_medias',
  })

  const submitDisabled = useMemo(() => !isDirty || imageLoading, [isDirty, imageLoading])

  const { mutateAsync } = creator ? creatorsUpdateMutation(creator.id) : creatorsCreateMutation()
  const onSubmit = handleSubmit(async data => {
    setSubmitError(null)
    try {
      await mutateAsync(data)
      rest.onClose?.()
    } catch (e) {
      setSubmitError(getErrorText(e))
    }
  })

  return (
    <Modal {...rest}>
      <ModalTitle onClose={rest.onClose}>{title}</ModalTitle>
      <form onSubmit={onSubmit}>
        <ModalContent>
          <FieldGroup>
            <Controller
              name="avatar_url"
              control={control}
              render={({ field }) => (
                <AvatarUploader
                  {...field}
                  text={t('creators.form.upload-photo')}
                  accept={{
                    'image/jpeg': ['.jpeg', '.jpg'],
                    'image/png': [],
                    'image/webp': [],
                    'image/gif': [],
                  }}
                  bucket={S3Bucket.dashboard}
                  onLoadingChanged={setImageLoading}
                />
              )}
            />
          </FieldGroup>

          <FieldGroup label={t('creators.form.name')}>
            <Input
              {...register('name', {
                required: t('validation.required'),
                setValueAs: (value: string) => value?.trim(),
              })}
              {...formatHookFormErrors(errors, 'name')}
              placeholder={t('creators.form.name.placeholder')}
            />
          </FieldGroup>

          <FieldGroup label={t('creators.form.email')}>
            <Input
              {...register('email', {
                required: t('validation.required'),
                validate: v => (!isEmail(v) ? t('validation.invalid_email') : true),
              })}
              {...formatHookFormErrors(errors, 'email')}
              extraLeft={{ icon: Email }}
              placeholder={t('creators.form.email.placeholder')}
            />
          </FieldGroup>

          <FieldGroup label={t('creators.form.social-networks').toUpperCase()}>
            <div className="flex flex-col gap-1.5">
              {medias.fields.map((media, index) => (
                <Fragment key={media.id}>
                  <SocialMediaField
                    {...register(`social_medias.${index}.url`, {
                      required: true,
                      pattern: { value: urlRegexp, message: t('validation.invalid_url') },
                    })}
                    {...formatHookFormErrors(
                      (errors?.social_medias?.[index] || {}) as Record<string, { message?: string | undefined }>,
                      'url',
                    )}
                    defaultValue={media.url}
                    onChange={url => setValue(`social_medias.${index}.url`, url, { shouldDirty: true })}
                    onDelete={() => medias.remove(index)}
                  />
                </Fragment>
              ))}
              {medias.fields.length <= SOCIAL_MEDIA_LIMIT && (
                <EngagementAddButton size="sm" icon={PlusContained} onClick={() => medias.append({ url: '' })}>
                  {t('creators.form.social-networks.add')}
                </EngagementAddButton>
              )}
            </div>
          </FieldGroup>
          {submitError && (
            <FieldGroup>
              <FormErrorMessage>{submitError}</FormErrorMessage>
            </FieldGroup>
          )}
        </ModalContent>

        <ModalFooter>
          <Button type="button" variant="outline" color="secondary" size="sm" onClick={() => rest.onClose?.()}>
            {t('Cancel')}
          </Button>
          <Button type="submit" color="primary" size="sm" disabled={submitDisabled} loading={isSubmitting}>
            {isAdd ? t('Add') : t('Save')}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}
