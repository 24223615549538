import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react'
import { Accept } from 'react-dropzone'
import { S3Bucket } from '@/types'
import { Button } from '@/ui'
import { optimizeImageFile } from '@dashboard/ui'
import { fetchUploadImage } from '@/api/files'

interface AvatarUploaderProps {
  accept: Accept
  value: string | null | undefined
  text: string
  bucket: S3Bucket
  onChange: (value: string | null) => void
  onLoadingChanged?: (loading: boolean) => void
}

export const AvatarUploader = forwardRef<HTMLInputElement, AvatarUploaderProps>(
  ({ accept, value, text, bucket, onChange, onLoadingChanged }, ref) => {
    const [accepts, _] = useMemo(() => [Object.keys(accept), Object.values(accept)], [accept])
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => onLoadingChanged?.(loading), [loading])

    const onUpload = useCallback(
      async (acceptedFiles: FileList) => {
        setLoading(true)
        const file = await optimizeImageFile(acceptedFiles[0])

        try {
          const data = await fetchUploadImage(file, { bucket: bucket })
          onChange(data.get_url)
        } catch (err) {
          console.error(err)
        }

        setLoading(false)
      },
      [onChange],
    )

    return (
      <div ref={ref} className="flex items-center gap-[18px]">
        <img src={value || '/icons/default-avatar.svg'} alt="img" className="size-[64px] rounded-full object-cover" />
        <Button className="relative w-[145px]" isLoading={loading}>
          {text}
          <input
            className="absolute left-0 top-0 size-full opacity-0"
            type="file"
            accept={accepts.join(', ')}
            onChange={e => e.target.files && onUpload(e.target.files)}
          />
        </Button>
      </div>
    )
  },
)
