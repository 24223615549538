import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const CardViewOutline: AghanimIcon = forwardRef(function (props, ref) {
  return (
    <IconBase {...props} viewBox="0 0 20 19" ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.22217 4.0555C2.22217 2.76684 3.26684 1.72217 4.5555 1.72217H6.88883C8.1775 1.72217 9.22217 2.76684 9.22217 4.0555V6.38883C9.22217 7.6775 8.1775 8.72217 6.88883 8.72217H4.5555C3.26684 8.72217 2.22217 7.6775 2.22217 6.38883V4.0555ZM4.5555 3.27772C4.12595 3.27772 3.77772 3.62595 3.77772 4.0555V6.38883C3.77772 6.81839 4.12595 7.16661 4.5555 7.16661H6.88883C7.31839 7.16661 7.66661 6.81839 7.66661 6.38883V4.0555C7.66661 3.62595 7.31839 3.27772 6.88883 3.27772H4.5555ZM10.7777 4.0555C10.7777 2.76684 11.8224 1.72217 13.1111 1.72217H15.4444C16.7331 1.72217 17.7777 2.76684 17.7777 4.0555V6.38883C17.7777 7.6775 16.7331 8.72217 15.4444 8.72217H13.1111C11.8224 8.72217 10.7777 7.6775 10.7777 6.38883V4.0555ZM13.1111 3.27772C12.6815 3.27772 12.3333 3.62595 12.3333 4.0555V6.38883C12.3333 6.81839 12.6815 7.16661 13.1111 7.16661H15.4444C15.8739 7.16661 16.2222 6.81839 16.2222 6.38883V4.0555C16.2222 3.62595 15.8739 3.27772 15.4444 3.27772H13.1111ZM2.22217 12.6111C2.22217 11.3224 3.26684 10.2777 4.5555 10.2777H6.88883C8.1775 10.2777 9.22217 11.3224 9.22217 12.6111V14.9444C9.22217 16.2331 8.1775 17.2777 6.88883 17.2777H4.5555C3.26684 17.2777 2.22217 16.2331 2.22217 14.9444V12.6111ZM4.5555 11.8333C4.12595 11.8333 3.77772 12.1815 3.77772 12.6111V14.9444C3.77772 15.3739 4.12595 15.7222 4.5555 15.7222H6.88883C7.31839 15.7222 7.66661 15.3739 7.66661 14.9444V12.6111C7.66661 12.1815 7.31839 11.8333 6.88883 11.8333H4.5555ZM10.7777 12.6111C10.7777 11.3224 11.8224 10.2777 13.1111 10.2777H15.4444C16.7331 10.2777 17.7777 11.3224 17.7777 12.6111V14.9444C17.7777 16.2331 16.7331 17.2777 15.4444 17.2777H13.1111C11.8224 17.2777 10.7777 16.2331 10.7777 14.9444V12.6111ZM13.1111 11.8333C12.6815 11.8333 12.3333 12.1815 12.3333 12.6111V14.9444C12.3333 15.3739 12.6815 15.7222 13.1111 15.7222H15.4444C15.8739 15.7222 16.2222 15.3739 16.2222 14.9444V12.6111C16.2222 12.1815 15.8739 11.8333 15.4444 11.8333H13.1111Z"
        fill="currentColor"
      />
    </IconBase>
  );
});
