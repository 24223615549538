import { useParams } from 'react-router-dom'
import { Button, Edit03, ModalPresetConfirm, useModal } from '@dashboard/ui'
import LeaderBoardDialog from './LeaderBoardDialog'
import { LeaderboardRead, LeaderboardVisibility } from '@/api/dashboard'
import {
  Badge,
  PageHeader,
  Pagination,
  Table,
  TableCell,
  TableRow,
  TableRowEditButton,
  TableRowSkeleton,
  TableZeroState,
  Tooltip,
  usePagination,
} from '@/ui'
import { useLeaderboardQuery } from './api'
import { Plus, Trash } from '@/icons'
import { useLeaderboardDeleteMutate } from './api/useLeaderboardDeleteMutate'
import { DateTimeValue } from '@/components/ui/DateTimeValue'

import { useTranslation } from 'react-i18next'
import { useCurrentUser } from '@/api/useCurrentUser'

export default function LeaderBoardTable() {
  const { t } = useTranslation()
  const { canEdit } = useCurrentUser()

  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }

  const { page, query, onPageChange, needShowPagination } = usePagination(10)
  const { data: items = [], isLoading } = useLeaderboardQuery(companyId, gameId, query)
  const { mutateAsync: deleteMutateAsync } = useLeaderboardDeleteMutate(companyId, gameId)

  const openDeleteModal = useModal<{ item: LeaderboardRead }>(rest => (
    <ModalPresetConfirm
      {...rest}
      title={{
        children: t('confirm.title'),
        subtitle: t('confirm.text', { name: rest.item.name }),
      }}
      cancel={{
        label: t('Cancel'),
        callback: rest.onClose,
      }}
      submit={{
        callback: async () => {
          rest.onClose()
          await deleteMutateAsync({ id: rest.item.id })
        },
        color: 'danger',
        label: t('remove'),
      }}
    />
  ))

  const openEditModal = useModal<{ item: LeaderboardRead }>(props => <LeaderBoardDialog {...props} />)

  return (
    <div className="flex h-full flex-col">
      <PageHeader
        extra={
          canEdit &&
          (!!items.length || page > 1) && (
            <Button size="sm" onClick={() => openEditModal({ item: {} as LeaderboardRead })}>
              {t('leaderboard.add-button')}
            </Button>
          )
        }
      >
        {t('sidebar.leaderboard')}
      </PageHeader>

      {isLoading ? (
        <TableRowSkeleton rowCount={query.limit} columnCount={4} />
      ) : items.length === 0 && page == 1 ? (
        <TableZeroState
          title={t('leaderboard.zero.title')}
          message={t('leaderboard.zero.message')}
          buttons={
            <>
              {canEdit && (
                <Button size="sm" onClick={() => openEditModal({ item: {} as LeaderboardRead })}>
                  <Plus size={16} />
                  <span>{t('leaderboard.zero.button-add')}</span>
                </Button>
              )}
              <a href="https://docs.aghanim.com/advanced/leaderboards/overview" target="_blank">
                <Button variant="tertiary" color="secondary" size="sm">
                  {t('leaderboard.zero.learn-more')}
                </Button>
              </a>
            </>
          }
        />
      ) : (
        <>
          <Table>
            <TableRow variant="header">
              <TableCell width="180%">{t('leaderboard.name')}</TableCell>
              <TableCell width="60%">{t('leaderboard.starts_at')}</TableCell>
              <TableCell width="60%">{t('leaderboard.ends_at')}</TableCell>
              <TableCell width="35%">{t('leaderboard.visibility')}</TableCell>
              <TableCell width="15%" />
            </TableRow>

            {items.map((it, idx) => (
              <TableRow key={idx}>
                <TableCell width="180%">
                  <div className="flex flex-col gap-1">
                    <span className="text-caption-md text-text-secondary">{it.name}</span>
                    <span className="text-caption-sm text-text-tertiary">{it.description}</span>
                  </div>
                </TableCell>
                <TableCell width="60%">
                  <DateTimeValue value={it.start_at} />
                </TableCell>
                <TableCell width="60%">
                  <DateTimeValue value={it.end_at} />
                </TableCell>
                <TableCell width="35%">
                  {it.visibility === LeaderboardVisibility.General && (
                    <Tooltip message={t('leaderboard.general.tooltip')}>
                      <div>
                        <Badge variant="lime-secondary">{t('leaderboard.general')}</Badge>
                      </div>
                    </Tooltip>
                  )}
                  {it.visibility === LeaderboardVisibility.Personal && (
                    <Tooltip message={t('leaderboard.personal.tooltip')}>
                      <div>
                        <Badge variant="violet-secondary">{t('leaderboard.personal')}</Badge>
                      </div>
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell width="15%">
                  <div className="text-right">
                    {canEdit && (
                      <TableRowEditButton
                        onChange={v => {
                          switch (v) {
                            case 'edit':
                              openEditModal({ item: it })
                              break
                            case 'remove':
                              openDeleteModal({ item: it })
                              break
                          }
                        }}
                        options={[
                          {
                            icon: <Edit03 />,
                            children: t('grid.edit'),
                            value: 'edit',
                          },
                          {
                            icon: <Trash className="text-text-error-primary" />,
                            children: <span className="text-text-error-primary"> {t('grid.remove')} </span>,
                            value: 'remove',
                          },
                        ]}
                      />
                    )}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </Table>

          {needShowPagination(isLoading, items) && (
            <Pagination
              hasNext={items.length >= query.limit}
              page={page}
              onPageChange={onPageChange}
              pageItems={items.length}
            />
          )}
        </>
      )}
    </div>
  )
}
