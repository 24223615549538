import Compressor from 'compressorjs';

/**
 * Optimize image file
 * @param file
 * @param quality
 */
export const optimizeImageFile = (file: File, quality = 0.9): Promise<File> => {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      quality,
      mimeType: 'image/webp',
      success: result => {
        resolve(result as File);
      },
      error(err) {
        reject(err);
      },
    });
  });
};
