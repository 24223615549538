import { useMemo, useRef } from 'react'
import { Accept, useDropzone } from 'react-dropzone'
import { motion } from 'framer-motion'
import { cn } from '../../../libs/cn'
import { UploadCloud2 } from '@/icons'
import { containerMotionProps } from './containerMotionProps'
import { Trans } from 'react-i18next'

interface PlaceholderContainerProps {
  accept: Accept
  disabled?: boolean
  onUpload: (acceptedFiles: File[]) => void
}

const formatter = new Intl.ListFormat('en', { style: 'long', type: 'disjunction' })

export const PlaceholderContainer = ({ accept, disabled, onUpload }: PlaceholderContainerProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const { getRootProps, getInputProps, isDragAccept } = useDropzone({
    accept,
    onDrop: onUpload,
    maxFiles: 1,
  })

  const formattedAcceptString = useMemo(
    () => formatter.format(Object.keys(accept).map(key => key.split('/').at(1)?.toLocaleUpperCase()) as string[]),
    [accept],
  )

  return (
    <motion.div
      {...containerMotionProps}
      {...getRootProps({ ref })}
      className={cn(
        'bg:bg-fg-primary flex h-[140px] cursor-pointer flex-col items-center justify-center gap-3 rounded-md border border-border-primary p-6 shadow-xs transition-colors hover:bg-fg-primary-hover',
        isDragAccept && 'border-border-brand bg-fg-primary-hover ring-2 ring-brand',
        disabled && 'pointer-events-none bg-fg-disabled',
      )}
    >
      <input {...getInputProps()} />
      <div className="flex size-9 items-center justify-center rounded-xl border-2 border-border-secondary text-text-secondary shadow-md">
        <UploadCloud2 size={20} />
      </div>
      <div className="text-center text-xs text-text-secondary">
        <div>
          <Trans
            i18nKey="ClickToUpload"
            components={{ span: <span className="font-semibold text-text-brand-primary" /> }}
          />
        </div>
        <div>{formattedAcceptString} (max. 800x400px)</div>
      </div>
    </motion.div>
  )
}
