import { Fragment, useEffect, useState } from 'react'
import { Link, generatePath, useLocation, useParams } from 'react-router-dom'
import i18next from 'i18next'
import { Button, ModalPresetConfirm, Skeleton, useModal, useSearchFilter } from '@dashboard/ui'
import { Campaign, ResourceState } from '@/api/dashboard'
import { dashboardClient, getErrorText } from '@/api'
import { CampaignRow } from './CampaignRow'
import getCampaignName from './getCampaignName'
import { LIVEOPS_DETAILS_PATH, LIVEOPS_SETTINGS_PATH } from '@/libs'
import { ModalProps, PageHeader, Tab, TableZeroState, Tabs, ToastSeverity, useToast } from '@/ui'
import { useQuery } from '@tanstack/react-query'
import { campaignsQuery } from '../../api'
import { Plus } from '@/icons'
import CreateCampaignModal from '@/layouts/campaigns/widgets/CreateCampaignModal'
import { useCurrentUser } from '@/api/useCurrentUser'
import { useTranslation } from 'react-i18next'

export default function CampaignsTable() {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
    eventId?: string
  }
  const { t } = useTranslation()
  const location = useLocation()
  const { state } = location
  const { canEdit } = useCurrentUser()
  const [loadingIds, setLoadingIds] = useState<string[]>([])
  const showToast = useToast()
  const filter = useSearchFilter<'state'>()
  const {
    data: items = [],
    refetch,
    isLoading,
  } = useQuery(
    campaignsQuery(companyId, gameId, {
      resource_state: (filter.values.state == 'all'
        ? undefined
        : (filter.values.state ?? ResourceState.Active)) as ResourceState,
    }),
  )

  const onConfirmDeleteClick = async (id: string) => {
    await dashboardClient.v1.deleteCampaign(companyId, gameId, id)
    refetch()
  }

  const onEnableChange = async (item: Campaign, value: boolean) => {
    try {
      setLoadingIds(prev => [...prev, item.id])

      await dashboardClient.v1.updateCampaign(companyId, gameId, item.id, {
        enabled: value,
      })
      await refetch()
    } catch (e) {
      showToast({ message: t(`server-errors.${getErrorText(e)}`), severity: ToastSeverity.error })
    } finally {
      setLoadingIds(prev => prev.filter(id => id !== item.id))
    }
  }

  const onArchivedChange = async (item: Campaign) => {
    const update = {
      archived_at: (item.archived_at ? null : new Date().getTime() / 1000) as number,
      enabled: undefined as boolean | undefined,
    }

    if (update.archived_at) {
      update.enabled = false
    }

    await dashboardClient.v1.updateCampaign(companyId, gameId, item.id, update)
    await refetch()
  }

  const onCopy = async (item: Campaign) => {
    const { data: graph } = await dashboardClient.v1.getCampaignGraph(companyId, gameId, item.id)

    await dashboardClient.v1.createCampaign(companyId, gameId, {
      graph: graph,
      enabled: false,
      name: item.name,
      description: item.description ? `${item.description} - copy` : 'Copy',
      type: item.type,
      attributes: item.attributes,
    })

    if (filter.values.state == ResourceState.Archived) {
      filter.onChange({ ...filter.values, state: '' })
    } else {
      await refetch()
    }
  }

  const renderSkeleton = () => {
    return new Array(4).fill('').map((_, idx) => <Skeleton className="h-[134px]" key={idx} />)
  }

  const openCreateModal = useModal<ModalProps>(props => <CreateCampaignModal {...props} />)

  const openConfirmArchive = useModal<{ campaign: Campaign }>(props => (
    <ModalPresetConfirm
      {...props}
      title={{
        children: t('confirm.title'),
        subtitle: t(props.campaign.archived_at ? 'campaign.confirm.unarchive' : 'campaign.confirm.archive', {
          name: getCampaignName(props.campaign.type),
        }),
      }}
      cancel={{
        label: t('no'),
      }}
      submit={{
        color: props.campaign.archived_at ? 'primary' : 'danger',
        callback: () => {
          props.onClose()
          onArchivedChange(props.campaign)
        },
        label: t(props.campaign.archived_at ? 'unarchive' : 'archive'),
      }}
    />
  ))

  const openConfirmDelete = useModal<{ campaign: Campaign }>(props => (
    <ModalPresetConfirm
      {...props}
      title={{
        children: t('confirm.title'),
        subtitle: i18next.t('campaign.confirm.text', { name: getCampaignName(props.campaign.type) }),
      }}
      cancel={{
        label: t('no'),
      }}
      submit={{
        color: 'danger',
        callback: () => {
          props.onClose()
          onConfirmDeleteClick(props.campaign.id)
        },
        label: t('remove'),
      }}
    />
  ))

  useEffect(() => {
    if (state?.openCreateCampaign) {
      state.openCreateCampaign = null
      setTimeout(() => openCreateModal(), 300)
    }
  }, [state])

  return (
    <div className="flex h-full flex-col">
      <PageHeader
        extra={
          canEdit && (
            <>
              <Link to={generatePath(LIVEOPS_SETTINGS_PATH, { companyId, gameId })}>
                <Button variant="outline" color="secondary" size="sm">
                  {i18next.t('campaign.settings')}
                </Button>
              </Link>
              {!!items.length && (
                <Button size="sm" onClick={() => openCreateModal()}>
                  {i18next.t('campaigns.add')}
                </Button>
              )}
            </>
          )
        }
      >
        {i18next.t('sidebar.campaigns')}
      </PageHeader>

      <Tabs className="mb-4">
        <Tab
          isActive={filter.values.state == 'all'}
          onClick={() => {
            filter.onChange({ ...filter.values, state: 'all' })
          }}
        >
          {t('sku.items.tabs.all')}
        </Tab>
        <Tab
          isActive={!filter.values.state}
          onClick={() => {
            filter.onChange({ ...filter.values, state: undefined })
          }}
        >
          {t('sku.items.tabs.active')}
        </Tab>
        <Tab
          isActive={filter.values.state == ResourceState.Archived}
          onClick={() => {
            filter.onChange({ ...filter.values, state: ResourceState.Archived })
          }}
        >
          {t('sku.items.tabs.archived')}
        </Tab>
      </Tabs>

      {isLoading ? (
        <div className="flex flex-col gap-3">{renderSkeleton()}</div>
      ) : items.length === 0 ? (
        <TableZeroState
          title={i18next.t(!canEdit ? 'campaigns.empty-table.title.read-only' : 'campaigns.empty-table.title')}
          message={i18next.t(!canEdit ? 'campaigns.empty-table.read-only' : 'campaigns.empty-table')}
          buttons={
            <>
              {canEdit && (
                <Button size="sm" onClick={() => openCreateModal()}>
                  <Plus size={16} />
                  <span>{i18next.t('campaigns.new')}</span>
                </Button>
              )}
            </>
          }
        />
      ) : (
        <div className="flex flex-col gap-3">
          {items.map(it => (
            <Fragment key={it.id}>
              <CampaignRow
                onCopyClick={() => onCopy(it)}
                loading={loadingIds.includes(it.id)}
                to={generatePath(LIVEOPS_DETAILS_PATH, { companyId, gameId, eventId: it.id })}
                campaign={it}
                canEdit={canEdit}
                onArchiveChange={() => openConfirmArchive({ campaign: it })}
                onDeleteClick={() => openConfirmDelete({ campaign: it })}
                onClickToggle={() => onEnableChange(it, !it.enabled)}
              />
              <div className="border-b border-border-secondary" />
            </Fragment>
          ))}
        </div>
      )}
    </div>
  )
}
