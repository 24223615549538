import { forwardRef } from 'react'
//import { AnimatePresence, motion } from 'framer-motion'
import { Skeleton } from '@mui/material'
//import { getMotionProps } from '../../../libs'
import { AghanimGameSchemasGame } from '../../../api/dashboard'
import { ChevronSelectorVertical } from '@/icons'
import { cn } from '@/libs'

interface SidebarGameButtonProps {
  game?: AghanimGameSchemasGame
  variant?: 'default' | 'disabled'
}

// const entryMotionProps = getMotionProps({
//   initial: { y: 1, opacity: 0 },
//   animate: { y: 0, opacity: 1 },
//   exit: { y: -1, opacity: 0 },
// })

export const SidebarGameButton = forwardRef<HTMLDivElement, SidebarGameButtonProps>(function SidebarGameButton(
  { game, variant = 'default', ...rest },
  ref,
) {
  const interactProps =
    variant === 'default'
      ? "cursor-pointer transition-all [&:not([data-open='true'])]:hover:shadow-xs [&:not([data-open='true'])]:hover:ring-1 [&:not([data-open='true'])]:hover:ring-border-primary-hover [&[data-open='true']]:shadow-sm [&[data-open='true']]:ring-1"
      : 'border border-primary-hover'

  return (
    <div
      data-testid="game-switch-menu"
      {...rest}
      ref={ref}
      className={cn(
        'group flex h-10 w-full  items-center overflow-hidden rounded-md bg-bg-secondary p-1',
        interactProps,
      )}
    >
      {game ? (
        <>
          <img
            className="mr-2 size-8 shrink-0 rounded-md object-cover"
            src={game.logo_url}
            data-categories="essential"
            alt=""
          />
          <div className="truncate font-medium leading-normal text-text-secondary">{game.name}</div>
          {variant === 'default' && (
            <div className="ml-auto text-text-quarterary group-hover:text-text-quarterary-hover">
              <ChevronSelectorVertical size={16} />
            </div>
          )}
        </>
      ) : (
        <>
          <Skeleton className="mr-2 shrink-0 rounded-md" variant="rectangular" width={32} height={32} />
          <Skeleton className="rounded-md" variant="rectangular" width="50%" height={20} />
        </>
      )}
    </div>
  )
})
