import { FunctionComponent, createElement, useCallback } from 'react';
import cuid from 'cuid';
import { useModalStore } from './useModalStore';
import { ModalBaseProps } from './types';

/**
 * This hook is used to show modal windows.
 * @param component
 */
export const useModal = <T>(component: FunctionComponent<ModalBaseProps & T>) => {
  const { push, shift } = useModalStore();

  return useCallback(
    (props: T = {} as T) => {
      const id = cuid();
      const close = () => {
        shift(id);
      };

      requestAnimationFrame(() => {
        // @ts-ignore
        push(id, createElement<ModalBaseProps>(component, { ...props, id, onClose: close }));
      });
    },
    [component, push, shift],
  );
};
