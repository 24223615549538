import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '@/components/icons'

export const Spreadsheet: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 18 14" ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.70002 2.25C2.12013 2.25 1.65002 2.7201 1.65002 3.3V6.15H5.55002V2.25H2.70002ZM7.05002 2.25V6.15H16.35V3.3C16.35 2.7201 15.8799 2.25 15.3 2.25H7.05002ZM16.35 7.65H7.05002V11.55H15.3C15.8799 11.55 16.35 11.0799 16.35 10.5V7.65ZM5.55002 11.55V7.65H1.65002V10.5C1.65002 11.0799 2.12013 11.55 2.70002 11.55H5.55002ZM0.150024 3.3C0.150024 1.89168 1.2917 0.75 2.70002 0.75H15.3C16.7083 0.75 17.85 1.89167 17.85 3.3V10.5C17.85 11.9083 16.7083 13.05 15.3 13.05H2.70002C1.2917 13.05 0.150024 11.9083 0.150024 10.5V3.3Z"
        fill="#079455"
      />
    </IconBase>
  )
})
