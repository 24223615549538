import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const TikTokIcon: AghanimIcon = forwardRef(function (props, ref) {
  return (
    <IconBase {...props} viewBox="0 0 48 48" ref={ref}>
      <g clipPath="url(#clip0_7062_8147)">
        <path
          d="M0 16.1C0 10.4051 0 7.55764 1.12587 5.39026C2.07462 3.56382 3.56382 2.07462 5.39026 1.12587C7.55764 0 10.4051 0 16.1 0H31.9C37.5949 0 40.4424 0 42.6097 1.12587C44.4362 2.07462 45.9254 3.56382 46.8741 5.39026C48 7.55764 48 10.4051 48 16.1V31.9C48 37.5949 48 40.4424 46.8741 42.6097C45.9254 44.4362 44.4362 45.9254 42.6097 46.8741C40.4424 48 37.5949 48 31.9 48H16.1C10.4051 48 7.55764 48 5.39026 46.8741C3.56382 45.9254 2.07462 44.4362 1.12587 42.6097C0 40.4424 0 37.5949 0 31.9V16.1Z"
          fill="black"
        />
        <path
          d="M14.5636 28.7409C14.7589 27.1236 15.4222 26.218 16.6723 25.2897C18.4609 24.0325 20.695 24.7436 20.695 24.7436V20.5247C21.2382 20.5108 21.7816 20.543 22.3188 20.6209V26.0503C22.3188 26.0503 20.0853 25.3391 18.2967 26.597C17.0474 27.5246 16.3827 28.4308 16.188 30.0482C16.1819 30.9265 16.3467 32.0745 17.1057 33.0671C16.918 32.9709 16.7268 32.8613 16.5319 32.7382C14.86 31.6155 14.5554 29.9312 14.5636 28.7409ZM31.5438 12.7239C30.3135 11.3756 29.8482 10.0144 29.68 9.05811H31.2278C31.2278 9.05811 30.9191 11.5654 33.1683 14.0311L33.1995 14.0643C32.5933 13.683 32.0374 13.2329 31.5438 12.7239ZM38.9999 16.5463V21.8664C38.9999 21.8664 37.0248 21.7891 35.5632 21.4172C33.5223 20.8972 32.2106 20.0995 32.2106 20.0995C32.2106 20.0995 31.3044 19.5307 31.2311 19.4911V30.4773C31.2311 31.089 31.0636 32.6166 30.5529 33.8908C29.8862 35.5576 28.8572 36.6516 28.668 36.8753C28.668 36.8753 27.4166 38.3542 25.2089 39.3501C23.2189 40.2485 21.4717 40.2257 20.9494 40.2485C20.9494 40.2485 17.9291 40.3681 15.2113 38.6018C14.6236 38.2125 14.075 37.7716 13.5726 37.2848L13.5862 37.2945C16.3047 39.0608 19.3243 38.9412 19.3243 38.9412C19.8472 38.9184 21.5944 38.9412 23.5838 38.0428C25.7895 37.0469 27.0429 35.568 27.0429 35.568C27.2301 35.3443 28.2638 34.2503 28.9278 32.5828C29.4371 31.3094 29.606 29.781 29.606 29.1693V18.1844C29.6793 18.2247 30.5848 18.7936 30.5848 18.7936C30.5848 18.7936 31.8972 19.5918 33.9381 20.1112C35.4004 20.4831 37.3748 20.5604 37.3748 20.5604V16.3915C38.0504 16.543 38.6262 16.584 38.9999 16.5463Z"
          fill="#FD355A"
        />
        <path
          d="M37.3755 16.3915V20.5591C37.3755 20.5591 35.4011 20.4818 33.9388 20.1099C31.8979 19.5899 30.5855 18.7923 30.5855 18.7923C30.5855 18.7923 29.68 18.2234 29.6067 18.1831V29.1706C29.6067 29.7823 29.4392 31.3107 28.9285 32.5841C28.2617 34.2516 27.2328 35.3456 27.0436 35.5693C27.0436 35.5693 25.7915 37.0482 23.5845 38.0441C21.5951 38.9425 19.8479 38.9197 19.325 38.9425C19.325 38.9425 16.3054 39.0621 13.5869 37.2958L13.5733 37.2861C13.2863 37.0082 13.0161 36.7147 12.7642 36.4072C11.8967 35.3495 11.3649 34.0988 11.2313 33.7419C11.2311 33.7404 11.2311 33.7389 11.2313 33.7374C11.0163 33.1172 10.5646 31.6279 10.6263 30.1854C10.7355 27.6404 11.6308 26.0782 11.8675 25.6869C12.4944 24.6197 13.3098 23.6649 14.2774 22.8649C15.1312 22.1746 16.0989 21.6254 17.141 21.2398C18.2674 20.787 19.4737 20.5443 20.6951 20.5247V24.7436C20.6951 24.7436 18.4609 24.0351 16.673 25.2897C15.4229 26.218 14.7596 27.1236 14.5643 28.7409C14.5561 29.9312 14.8607 31.6155 16.5312 32.7389C16.7261 32.8624 16.9174 32.972 17.105 33.0678C17.3969 33.4472 17.7521 33.7777 18.1563 34.0461C19.7882 35.0791 21.1556 35.1513 22.9042 34.4804C24.0701 34.0318 24.9478 33.021 25.3547 31.9009C25.6104 31.2014 25.607 30.4974 25.607 29.7693V9.05811H29.6766C29.8448 10.0144 30.3101 11.3756 31.5405 12.7239C32.034 13.2329 32.5899 13.683 33.1961 14.0643C33.3751 14.2496 34.2908 15.1655 35.4662 15.7278C36.074 16.0185 36.7146 16.2412 37.3755 16.3915Z"
          fill="white"
        />
        <path
          d="M9.61157 32.4463V32.4495L9.71255 32.7232C9.70095 32.6914 9.66343 32.5945 9.61157 32.4463Z"
          fill="#33F3ED"
        />
        <path
          d="M17.141 21.2398C16.099 21.6254 15.1312 22.1747 14.2774 22.865C13.3095 23.6667 12.4944 24.6236 11.8682 25.6928C11.6315 26.0828 10.7362 27.6462 10.627 30.1913C10.5653 31.6338 11.017 33.1231 11.232 33.7432C11.2318 33.7448 11.2318 33.7463 11.232 33.7478C11.3677 34.1014 11.8974 35.3522 12.7649 36.4131C13.0169 36.7206 13.287 37.0141 13.574 37.292C12.6545 36.6824 11.8343 35.9454 11.1405 35.1051C10.2804 34.0566 9.75002 32.8188 9.61165 32.4535C9.61149 32.4509 9.61149 32.4483 9.61165 32.4457V32.4412C9.39597 31.8216 8.94289 30.3317 9.00597 28.8872C9.11517 26.3422 10.0105 24.7801 10.2472 24.3887C10.8731 23.3194 11.6883 22.3626 12.6564 21.5609C13.51 20.8704 14.4778 20.3211 15.52 19.9358C16.17 19.6773 16.8472 19.4866 17.5398 19.367C18.5837 19.1923 19.6497 19.1772 20.6985 19.3221V20.5247C19.476 20.5439 18.2685 20.7866 17.141 21.2398Z"
          fill="#33F3ED"
        />
        <path
          d="M29.68 9.05794H25.6104V29.7698C25.6104 30.4979 25.6104 31.2 25.3581 31.9014C24.9471 33.0208 24.0728 34.0317 22.9075 34.4802C21.1583 35.1537 19.7909 35.0789 18.1597 34.046C17.7548 33.7788 17.3986 33.4493 17.1057 33.0709C18.4954 33.7814 19.7394 33.7691 21.2804 33.1762C22.4449 32.7276 23.3206 31.7168 23.7303 30.5967C23.9866 29.8972 23.9832 29.1932 23.9832 28.4658V7.75H29.6026C29.6026 7.75 29.5396 8.26486 29.68 9.05794ZM37.3755 15.2388V16.3914C36.7158 16.2408 36.0763 16.0181 35.4696 15.7277C34.2942 15.1653 33.3785 14.2494 33.1994 14.0641C33.4072 14.1949 33.6228 14.3138 33.8452 14.4204C35.2742 15.1042 36.6816 15.3084 37.3755 15.2388Z"
          fill="#33F3ED"
        />
      </g>
      <defs>
        <clipPath id="clip0_7062_8147">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </IconBase>
  );
});
