import { useParams } from 'react-router-dom'
import i18next from 'i18next'
import {
  ModalConfirm,
  Table,
  TableCell,
  TableRow,
  TableRowEditButton,
  TableRowSkeleton,
  TableZeroState,
  useModal,
} from '@/ui'

import { UserBanner } from '@/api/dashboard'
import { useContext } from 'react'
import { dashboardClient } from '@/api'
import { Trash } from '@/icons'
import { canEdit } from '@/security'
import { SkuImageName } from '../../game-items/components/SkuImageName'
import { cn } from '@/libs'
import { DateTimeValue } from '@/components/ui/DateTimeValue'
import { AuthContext, IAuthContext } from '@/Context'
import { usePlayerBanners } from '@/layouts/player/api/usePlayerBanners'
import { useGameItemsQuery } from '@/api/useGameItems'
import { usePagesQuery } from '@/layouts/pages/api/usePagesQuery'

export default function UserBannerTable(props: { userId: string; className?: string }) {
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const { data: banners = [], isLoading, refetch } = usePlayerBanners(companyId, gameId, props.userId)

  const { data: items = [] } = useGameItemsQuery(companyId, gameId, {
    ids: banners.map(it => it.config.featured_item_id).join(','),
  })

  const { data: pages = [] } = usePagesQuery(companyId, gameId, {
    limit: 100,
    ignore_parent_id: true,
  })

  const context = useContext(AuthContext) as IAuthContext
  const canUserEdit = canEdit(context.customer)

  const openDeleteModal = useModal<{ item: UserBanner }>(props => (
    <ModalConfirm
      subMessage={i18next.t('confirm.text', { name: getItemName(props.item) })}
      confirmButtonText={i18next.t('remove')}
      color="danger"
      onConfirm={() => onDeleteGameItemClick(props.item)}
      {...props}
    />
  ))

  const onDeleteGameItemClick = async (item: UserBanner) => {
    await dashboardClient.v1.deleteUserBanner(item.id, companyId, gameId)
    refetch()
  }

  const renderDefaultHeader = () => {
    return (
      <TableRow variant="header">
        <TableCell width="50%">{i18next.t('player.banners.item')}</TableCell>
        <TableCell width="40%">{i18next.t('player.banners.page')}</TableCell>
        <TableCell width="25%">{i18next.t('player.banners.expires_at')}</TableCell>
        <TableCell width="25%">{i18next.t('player.banners.created_at')}</TableCell>
        {canUserEdit && <TableCell width="12%" />}
      </TableRow>
    )
  }

  if (isLoading) {
    return <TableRowSkeleton rowCount={3} columnCount={4} />
  }

  if (!banners.length) {
    return (
      <div className="h-[147px] flex-1">
        <TableZeroState
          title={i18next.t('player.banners.zero.title')}
          message={i18next.t('player.banners.zero.message')}
        />
      </div>
    )
  }

  const getPage = (banner: UserBanner) => {
    return pages.find(p => p.id == banner.page_id)?.title
  }

  const getItemName = (banner: UserBanner) => {
    let item = items.find(it => it.id == banner.config.featured_item_id)

    if (item) {
      return item.name
    }

    return ''
  }

  const getItem = (banner: UserBanner) => {
    if (!banner.config.featured_item_id) {
      return null
    }

    let item = items.find(it => it.id == banner.config.featured_item_id)

    if (item) {
      return <SkuImageName item={item} />
    }

    return null
  }

  return (
    <div className={cn('w-full', props.className)}>
      <Table>
        {renderDefaultHeader()}
        {banners.map(it => (
          <TableRow key={it.id}>
            <TableCell width="50%" className="flex items-center gap-3">
              {getItem(it)}
            </TableCell>
            <TableCell width="40%">{getPage(it)}</TableCell>
            <TableCell width="25%">
              {it.expires_at ? <DateTimeValue value={it.expires_at} /> : i18next.t('campaign.offers.expires_at.no')}
            </TableCell>
            <TableCell width="25%">
              <DateTimeValue value={it.created_at} />
            </TableCell>
            <TableCell width="12%">
              <div className="text-right">
                {canUserEdit && (
                  <TableRowEditButton
                    onChange={v => {
                      switch (v) {
                        case 'remove':
                          openDeleteModal({ item: it })
                          break
                      }
                    }}
                    options={[
                      {
                        icon: <Trash className="text-text-error-primary" />,
                        children: <span className="text-text-error-primary"> {i18next.t('grid.remove')} </span>,
                        value: 'remove',
                      },
                    ]}
                  />
                )}
              </div>
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </div>
  )
}
