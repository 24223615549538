import { QueryClient, useMutation, useQueryClient } from '@tanstack/react-query'
import { ItemRead } from '../../../api/dashboard'
import { dashboardClient } from '../../../api'
import { skuItemQuery } from './skuItemQuery'

interface ItemImageUpdateMutate {
  data: ItemRead
}

/**
 *
 * @param companyId
 * @param gameId
 */
export const useItemUpdateMutate = (companyId: string, gameId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ data }: ItemImageUpdateMutate) => dashboardClient.v1.updateItem(companyId, gameId, data.id, data),
    onSuccess: async (_, variables) => {
      await queryClient.refetchQueries({
        queryKey: skuItemQuery(companyId, gameId, variables.data.id).queryKey,
      })

      removeItemQueries(queryClient, gameId)
    },
  })
}

export function removeItemQueries(queryClient: QueryClient, gameId: string) {
  queryClient.removeQueries({ queryKey: ['items', gameId], exact: false, stale: false })
  queryClient.removeQueries({ queryKey: [gameId, 'sku', 'items'], exact: false, stale: false })
}
