import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '@/icons'

export const PlayerBase: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 16 16" ref={ref}>
      <g clip-path="url(#clip0_3488_600)">
        <path
          d="M0 5.71077C0 3.71181 0 2.71233 0.389023 1.94883C0.731217 1.27724 1.27724 0.731217 1.94883 0.389023C2.71233 0 3.71181 0 5.71077 0H10.2892C12.2882 0 13.2877 0 14.0512 0.389023C14.7228 0.731217 15.2688 1.27724 15.611 1.94883C16 2.71233 16 3.71181 16 5.71077V10.2892C16 12.2882 16 13.2877 15.611 14.0512C15.2688 14.7228 14.7228 15.2688 14.0512 15.611C13.2877 16 12.2882 16 10.2892 16H5.71077C3.71181 16 2.71233 16 1.94883 15.611C1.27724 15.2688 0.731217 14.7228 0.389023 14.0512C0 13.2877 0 12.2882 0 10.2892V5.71077Z"
          fill="url(#paint0_linear_3488_600)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.99989 13.5554C11.0681 13.5554 13.5554 11.0681 13.5554 7.99989C13.5554 4.93164 11.0681 2.44434 7.99989 2.44434C4.93164 2.44434 2.44434 4.93164 2.44434 7.99989C2.44434 11.0681 4.93164 13.5554 7.99989 13.5554ZM9.52047 6.67265C9.52047 5.87195 8.8388 5.22211 7.99888 5.22211C7.15896 5.22211 6.47729 5.87195 6.47729 6.67265C6.47729 7.47335 7.15896 8.12319 7.99888 8.12319C8.8388 8.12319 9.52047 7.47335 9.52047 6.67265ZM8.0004 11.3332C8.90423 11.3332 9.74415 11.0678 10.438 10.6138C10.7438 10.4136 10.8747 10.0321 10.6982 9.72023C10.3284 9.07619 9.56916 8.67439 8.0004 8.67439C6.43164 8.67439 5.67236 9.07619 5.30262 9.72023C5.12459 10.0306 5.25545 10.4136 5.56129 10.6138C6.25513 11.0678 7.09657 11.3332 8.0004 11.3332Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient id="paint0_linear_3488_600" x1="8" y1="0" x2="8" y2="16" gradientUnits="userSpaceOnUse">
          <stop stop-color="#38BDF8" />
          <stop offset="1" stop-color="#0EA5E9" />
        </linearGradient>
        <clipPath id="clip0_3488_600">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </IconBase>
  )
})
