import { AttrType, CustomAttribute } from '@/api/dashboard'
import TextIconButton from '../../../components/buttons/TextIconButton'
import { capitalizeFirstLetter, prepareSLUG } from '@/util'
import { FieldGroup } from '@/ui'
import { PlusContained } from '@/icons'
import { Button, Input, Select, Trash } from '@dashboard/ui'
import { useTranslation } from 'react-i18next'
import { CopyButton } from '@/components/ui/LabelCopyButton/CopyButton'

export default function CustomAttributeTable(props: {
  attributes: CustomAttribute[]
  setAttributes: (value: CustomAttribute[]) => void
}) {
  const { t } = useTranslation()

  const setItem = (index: number, attr: CustomAttribute) => {
    props.setAttributes([...props.attributes.map((it, idx) => (index == idx ? attr : it))])
  }

  const attributes = props.attributes

  const onDeleteGameItemClick = (attr: CustomAttribute) => {
    let newAttributes = attributes.filter(it => it !== attr)
    props.setAttributes(newAttributes)
  }

  const onAddClick = () => {
    props.setAttributes([
      ...attributes,
      {
        name: '',
        type: AttrType.String,
        description: '',
      },
    ])
  }

  const renderAttribute = (idx: number, attr: CustomAttribute) => {
    return (
      <div className="rounded-md border border-border-primary p-4" key={idx}>
        <div className="flex items-start gap-3">
          <div className="flex w-1/2 gap-2">
            <FieldGroup label={t('campaign.attr.name')} className="w-full" caption={t('campaign.attr.name.desc')}>
              <Input
                value={attr.name}
                onChange={e =>
                  setItem(idx, {
                    ...attr,
                    name: prepareSLUG(e.target.value),
                  })
                }
                extraRight={{
                  pointer: true,
                  icon: <CopyButton text={attr.name} />,
                }}
              />
            </FieldGroup>
          </div>

          <div className="flex w-1/2 gap-2">
            <FieldGroup label={t('player.attr-table.type')} className="w-full">
              <div className="flex gap-2">
                <Select
                  value={attr.type as string}
                  onChange={v =>
                    setItem(idx, {
                      ...attr,
                      type: v as AttrType,
                    })
                  }
                  items={Object.values(AttrType).map(it => ({
                    value: it,
                    children: capitalizeFirstLetter(it),
                  }))}
                />
                <Button
                  size="md"
                  variant="outline"
                  color="danger"
                  onClick={() => onDeleteGameItemClick(attr)}
                  className="ml-auto"
                >
                  <Trash />
                </Button>
              </div>
            </FieldGroup>
          </div>
        </div>

        <FieldGroup label={t('campaign.attr.name.field-desc')} className="mt-2">
          <Input
            value={attr.description}
            onChange={e => {
              setItem(idx, {
                ...attr,
                description: e.target.value,
              })
            }}
          />
        </FieldGroup>
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-3">
      {attributes.map((it, i) => renderAttribute(i, it))}

      <TextIconButton onClick={onAddClick} text={t('campaign.add-attribute')}>
        <PlusContained />
      </TextIconButton>
    </div>
  )
}
