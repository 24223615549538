import { Skeleton } from '@dashboard/ui'
import { TableRow } from './TableRow'
import { TableCell } from './TableCell'

export const TableHeaderSkeleton = () => {
  return (
    <TableRow variant="header">
      <TableCell width={44}>
        <Skeleton className="size-[20px]" />
      </TableCell>
      <TableCell width="100%">
        <Skeleton className="h-5 w-16" />
      </TableCell>
      <TableCell width="20%">
        <Skeleton className="h-5 w-16" />
      </TableCell>
      <TableCell width="20%">
        <Skeleton className="h-5 w-16" />
      </TableCell>
      <TableCell width="20%">
        <Skeleton className="h-5 w-16" />
      </TableCell>
      <TableCell width={64} />
    </TableRow>
  )
}
