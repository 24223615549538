import { useQuery } from '@tanstack/react-query'
import { dashboardClient } from './index'
import { GameSettingsRead } from '@/api/dashboard'

/**
 *
 * @param companyId
 * @param gameId
 */
export const useGameSettingsQuery = (companyId: string, gameId: string) => {
  return useQuery<GameSettingsRead | null>({
    queryKey: ['game-settings', gameId],
    queryFn: async () => {
      if (!companyId || !gameId) {
        return new Promise(resolve => {
          resolve(null)
        })
      }
      const { data } = await dashboardClient.v1.getGameSettings(companyId, gameId)
      return data
    },
  })
}
