import DescBlock from '@/layouts/game-settings/components/DescBlock'
import Block from '@/layouts/game-settings/components/Block'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import { FieldGroup, ImageUploader } from '@/ui'
import { ErrorMessage } from '@hookform/error-message'
import { GameSettingsRead } from '@/api/dashboard'
import { useCurrentUser } from '@/api/useCurrentUser'
import { FormErrorMessage, Input, formatHookFormErrors } from '@dashboard/ui'
import i18next from 'i18next'

export default function TransactionBranding() {
  const { t } = useTranslation()
  const { canEdit } = useCurrentUser()
  const {
    formState: { errors },
    register,
    control,
  } = useFormContext<GameSettingsRead>()

  return (
    <DescBlock title={t('game-settings.payment_settings.title')} description={t('game-settings.payment_settings.desc')}>
      <Block>
        <FieldGroup label={t('game-settings.descriptor_image_url')}>
          <Controller
            control={control}
            name="payment_settings.descriptor_image_url"
            render={({ field }) => (
              <ImageUploader
                disabled={!canEdit}
                accept={{
                  'image/jpeg': ['.jpeg', '.jpg'],
                  'image/png': [],
                  'image/webp': [],
                }}
                value={field.value}
                onChange={v => {
                  field.onChange(!v?.trim() ? null : v)
                }}
              />
            )}
          />
          <ErrorMessage
            name="payment_settings.descriptor_image_url"
            errors={errors}
            render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
          />
        </FieldGroup>

        <FieldGroup label={t('game-settings.descriptor_service_name')}>
          <Input
            {...register('payment_settings.descriptor_service_name', {
              maxLength: {
                message: i18next.t('game-settings.descriptor_service_name.max_length'),
                value: 12,
              },
              setValueAs: (v: string | null) => v?.trim(),
            })}
            {...formatHookFormErrors(errors, 'payment_settings.descriptor_service_name')}
            disabled={!canEdit}
          />
        </FieldGroup>
      </Block>
    </DescBlock>
  )
}
