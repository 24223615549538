import { getStringInitials } from '@/libs'
import { Skeleton } from '@mui/material'
import { CompanyGame } from '@/api/dashboard'

export const ImageCompany = (props: { company: CompanyGame | undefined }) => {
  const company = props.company

  if (!company?.logo_url) {
    return (
      <div className="flex size-[38px] shrink-0 items-center justify-center rounded bg-fg-brand-tertiary-hover bg-linear-company-gradient font-semibold text-fg-primary-hover shadow-inner-img">
        {company ? getStringInitials(company.name) : <Skeleton width="50%" />}
      </div>
    )
  }

  return (
    <div
      className="flex size-[38px] shrink-0 items-center justify-center rounded bg-cover bg-center bg-no-repeat"
      style={{
        backgroundImage: `url(${company.logo_url})`,
      }}
    />
  )
}
