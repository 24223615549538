import i18next from 'i18next'
import { useContext, useEffect, useMemo } from 'react'
import DurationSelectorDHMS from '../../../components/DurationSelectorDHMS'
import Divider from '../../../components/Divider'
import SettingsHeader from '../SettingsHeader'
import { Block, CreateItemBannerActionNode, Node, PageLayoutType } from '../../../api/dashboard'
import { BlockError, EventActionNodeType } from '../types'
import { NODE_VALIDATORS } from '../validation'
import { Settings } from '../../../components/ui/Settings'
import { FieldGroup, FieldValidationMessage, Input, Select } from '@/ui'
import { CheckBoxContainer } from '../../../components/ui/CheckBoxContainer'
import { usePagesQuery } from '../../pages/api/usePagesQuery'
import { generatePath, useParams } from 'react-router-dom'
import { DescContext } from '../blocks/descriptions/types'
import { Controller, useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import SelectSkuPanel from '@/layouts/components/SelectSkuPanel'
import { Trans } from 'react-i18next'
import { HUB_THEMES_EDITOR_PATH } from '@/libs'
import { useWebsitesQuery } from '@/api/useWebsitesQuery'
import { findPrevEntityNode } from '@/layouts/campaigns/util'
import { CampaignContext, ICampaignContext } from '@/layouts/campaigns/Context'

const EXLCUDE_SLUGS = ['about', 'cookies', 'privacy', 'terms']

export default function CreateBannerEditor(props: {
  node: CreateItemBannerActionNode
  error?: BlockError | null
  onClose: () => void
  setNode: (node: Node) => void
}) {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const context = useContext(CampaignContext) as ICampaignContext

  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
    setValue,
    watch,
    control,
    getValues,
  } = useForm<CreateItemBannerActionNode>({
    values: {
      ...props.node,
    },
  })

  const { data: websites = [] } = useWebsitesQuery(companyId, gameId)
  const { data: pages = [], isLoading } = usePagesQuery(companyId, gameId, {
    types: undefined,
  })

  const onSaveClick = (data: CreateItemBannerActionNode) => {
    if (validate(data)) {
      if (data.config.replace_item_block && !data.config.replace_item_block_name) {
        data.config.replace_item_block = null as unknown as boolean
      }

      props.setNode(data as Node)
      props.onClose()
    }
  }

  useEffect(() => {
    if (!isLoading) {
      if (props.node.page_id) {
        let slug = pages.find(it => it.id == props.node.page_id)?.slug
        if (slug) {
          setValue('page_slug', slug)
          setValue('page_id', null as unknown as string)
        }
      }
    }
  }, [isLoading])

  useEffect(() => {
    if (props.error?.errorText) {
      validate(getValues())
    }
  }, [props.error?.errorText])

  const validate = (data: CreateItemBannerActionNode) => {
    let errors = NODE_VALIDATORS[EventActionNodeType.CreateItemBannerActionNode](data, null as unknown as DescContext)

    errors.forEach(it => {
      setError(it.field as 'next_node_id' | 'title', { message: it.message })
    })
    return errors.length == 0
  }

  const duration = watch('duration')
  const link_created_offer = watch('link_created_offer')
  const replace_item_block = watch('config.replace_item_block')
  const page_slug = watch('page_slug')
  const selectedPage = pages.find(it => it.slug == page_slug)

  const store_item_id = watch('config.store_item_id')
  const store_id = watch('config.store_id')

  let blocks = (selectedPage?.layout.blocks || []).flat() as Block[]
  blocks = blocks
    .filter(it => it.block == 'FeaturedItemBlock' && it.name)
    .sort((a, b) => a.name!.localeCompare(b.name!))

  const prevEntityNode = useMemo(
    () => findPrevEntityNode(context.graph.nodes!, props.node.id),
    [context.graph.nodes, props.node.id],
  )

  return (
    <div>
      <SettingsHeader node={props.node as Node} onSave={handleSubmit(onSaveClick)} onClose={props.onClose} />

      <div className="p-6">
        <Settings text={i18next.t('coupon.general')}>
          <FieldGroup label={i18next.t('campaign.block.CreateItemBannerActionNode.page')}>
            <Controller
              control={control}
              name="page_slug"
              rules={{ required: i18next.t('validation.required') }}
              render={({ field }) => (
                <Select
                  value={field.value}
                  onChange={v => {
                    field.onChange(v)
                  }}
                  options={pages
                    .filter(
                      it => (EXLCUDE_SLUGS.indexOf(it.slug) == -1 && !it.type) || it.type == PageLayoutType.Blocks,
                    )
                    .sort((a, b) => a.slug.localeCompare(b.slug))
                    .map(it => ({
                      children: it.title,
                      value: it.slug,
                    }))}
                />
              )}
            />
            <ErrorMessage
              name="page_slug"
              errors={errors}
              render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
            />
          </FieldGroup>

          <CheckBoxContainer
            disabled={!selectedPage}
            checked={replace_item_block}
            onChange={e => setValue('config.replace_item_block', e.target.checked)}
            label={i18next.t('campaign.block.CreateItemBannerActionNode.replace_item_block')}
          >
            {blocks.length > 0 ? (
              <>
                <Controller
                  control={control}
                  name="config.replace_item_block_name"
                  rules={{ required: i18next.t('validation.required') }}
                  render={({ field }) => (
                    <Select
                      value={field.value}
                      onChange={v => {
                        field.onChange(v)
                      }}
                      options={blocks.map(it => ({
                        children: it.name,
                        value: it.name,
                      }))}
                    />
                  )}
                />
                <Trans
                  i18nKey={'campaign.block.CreateItemBannerActionNode.name_blocks'}
                  components={{
                    a: (
                      <a
                        href={generatePath(HUB_THEMES_EDITOR_PATH, {
                          companyId,
                          gameId,
                          wbId: websites.length ? websites[0].id : '',
                        })}
                        target="_blank"
                      />
                    ),
                  }}
                />
              </>
            ) : (
              <Trans
                i18nKey={'campaign.block.CreateItemBannerActionNode.no_blocks'}
                components={{
                  a: (
                    <a
                      href={generatePath(HUB_THEMES_EDITOR_PATH, {
                        companyId,
                        gameId,
                        wbId: websites.length ? websites[0].id : '',
                      })}
                      target="_blank"
                    />
                  ),
                }}
              />
            )}
            <ErrorMessage
              name="config.replace_item_block_name"
              errors={errors}
              render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
            />
          </CheckBoxContainer>
        </Settings>

        <Divider />

        <Settings text={i18next.t('campaign.block.CreateItemBannerActionNode.options')}>
          <FieldGroup label={i18next.t('campaign.block.Communication.attach-item')}>
            <Select
              value={link_created_offer ? 'offer' : 'item'}
              onChange={value => {
                switch (value) {
                  case 'item':
                    setValue('link_created_offer', false)
                    break
                  case 'offer':
                    setValue('link_created_offer', true)
                    setValue('config.store_id', null as unknown as string)
                    setValue('config.featured_item_id', null as unknown as string)
                    setValue('config.store_item_id', null as unknown as string)
                    break
                }
              }}
              options={[
                { value: 'item', children: i18next.t('campaign.block.Communication.item') },
                prevEntityNode
                  ? {
                      value: 'offer',
                      children: i18next.t('campaign.block.Communication.link_created_offer'),
                    }
                  : undefined,
              ]}
            />
          </FieldGroup>

          {!link_created_offer && (
            <Controller
              control={control}
              name="config.featured_item_id"
              rules={{ required: i18next.t('validation.required') }}
              render={({ field }) => (
                <SelectSkuPanel
                  onChange={(item_id, store_id, store_item_id) => {
                    field.onChange(item_id)
                    setValue('config.store_item_id', store_item_id as unknown as string)
                    setValue('config.store_id', store_id as unknown as string)
                  }}
                  itemId={field.value}
                  storeId={store_id}
                  storeItemId={store_item_id}
                >
                  <ErrorMessage
                    name="config.featured_item_id"
                    errors={errors}
                    render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
                  />
                </SelectSkuPanel>
              )}
            />
          )}

          <FieldGroup label={i18next.t('campaign.block.title')}>
            <Input {...register('config.title')} maxLength={300} />
          </FieldGroup>

          <FieldGroup label={i18next.t('campaign.block.description')}>
            <Input {...register('config.description')} maxLength={500} />
          </FieldGroup>
        </Settings>

        <Divider />

        <Settings text={i18next.t('campaign.block.CreateItemBannerActionNode.time-limit')}>
          <Controller
            control={control}
            name="duration"
            render={({ field }) => (
              <CheckBoxContainer
                checked={duration != null}
                label={i18next.t('campaign.block.ItemDiscountOfferActionNode.limit-timer')}
                onChange={e => {
                  setValue('duration', e.target.checked ? 3600 : (null as unknown as number))
                }}
              >
                <DurationSelectorDHMS
                  duration={field.value || 0}
                  size="small"
                  onChange={v => {
                    setValue('duration', v)
                  }}
                />
              </CheckBoxContainer>
            )}
          />
        </Settings>
      </div>
    </div>
  )
}
