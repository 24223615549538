import { ReactNode, useState } from 'react'
import i18next from 'i18next'
import { Button, ButtonProps } from '@dashboard/ui'
import { ButtonIcon, Modal, ModalFooter, ModalProps, ModalTitle } from '../index'
import { XClose } from '@/icons'

interface ConfirmDialogProps extends ModalProps {
  message?: ReactNode
  confirmButtonText: ReactNode
  cancelButtonText?: ReactNode
  subMessage?: ReactNode
  onCancel?: () => void
  onConfirm: () => void
  children?: ReactNode
  confirmEnabled?: boolean
  color?: ButtonProps['color']
  showClose?: boolean
}

export const ModalConfirm = (props: ConfirmDialogProps) => {
  const { ...rest } = props
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = async () => {
    setIsLoading(true)
    await props.onConfirm()
    rest.onClose?.()
  }

  const onCancel = async () => {
    props.onCancel?.()
    rest.onClose?.()
  }

  return (
    <Modal {...rest}>
      {props.showClose && (
        <ButtonIcon
          variant="secondary-gray"
          size="sm"
          onClick={rest.onClose}
          className="absolute right-[18px] top-[18px]"
        >
          <XClose size={14} />
        </ButtonIcon>
      )}
      <ModalTitle>{props.message || i18next.t('confirm.title')}</ModalTitle>

      <div className="-mt-2">
        {props.subMessage && <div className="text-paragraph-md text-text-secondary">{props.subMessage}</div>}
        {props.children}
      </div>

      <ModalFooter>
        <Button variant="outline" color="secondary" onClick={onCancel}>
          {props.cancelButtonText || i18next.t('Cancel')}
        </Button>
        <Button color={props.color} onClick={onSubmit} loading={isLoading}>
          {props.confirmButtonText}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
