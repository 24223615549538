import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  $getSelection,
  $isRangeSelection,
  $isRootOrShadowRoot,
  COMMAND_PRIORITY_CRITICAL,
  FORMAT_TEXT_COMMAND,
  LexicalEditor,
  SELECTION_CHANGE_COMMAND,
  TextFormatType,
} from 'lexical'
import { $findMatchingParent, $getNearestNodeOfType, mergeRegister } from '@lexical/utils'
import { $isListNode, ListNode } from '@lexical/list'
import { $isHeadingNode } from '@lexical/rich-text'
import { Floating } from '@/ui'
import { ToolbarFormattingMenu } from './ToolbarFormattingMenu'
import { ToolbarButton } from './ToolbarButton'
import { ToolbarGroup } from './ToolbarGroup'
import { ToolbarDivider } from './ToolbarDivider'
import { Bold02, Code02, Italic, Underline } from '@/icons'
import { TableNode } from '@lexical/table'

interface ToolbarFormattingButtonProps {
  editor: LexicalEditor
}

const i18nTypeMap = new Map([
  ['paragraph', 'lexical.menu.paragraph.label'],
  ['h1', 'lexical.menu.h1.label'],
  ['h2', 'lexical.menu.h2.label'],
  ['h3', 'lexical.menu.h3.label'],
  ['bullet', 'lexical.menu.numbered-list.label'],
  ['number', 'lexical.menu.bullet-list.label'],
])

export const ToolbarFormattingButton = ({ editor }: ToolbarFormattingButtonProps) => {
  const { t } = useTranslation()
  const [blockType, setBlockType] = useState('paragraph')
  const [isBold, setIsBold] = useState(false)
  const [isItalic, setIsItalic] = useState(false)
  const [isUnderline, setIsUnderline] = useState(false)
  const [isCode, setIsCode] = useState(false)

  const $updateToolbar = () => {
    const selection = $getSelection()
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode()
      let element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : $findMatchingParent(anchorNode, e => {
              const parent = e.getParent()
              return parent !== null && $isRootOrShadowRoot(parent)
            })

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow()
      }

      const elementKey = element.getKey()
      const elementDOM = editor.getElementByKey(elementKey)

      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType<ListNode>(anchorNode, ListNode)
          const type = parentList ? parentList.getListType() : element.getListType()
          setBlockType(type)
        } else {
          const type = $isHeadingNode(element) ? element.getTag() : element.getType()
          setBlockType(type)
        }
      }
    }
  }

  const updateMarks = useCallback(() => {
    editor.getEditorState().read(() => {
      if (editor.isComposing()) {
        return
      }

      const selection = $getSelection()
      const nativeSelection = window.getSelection()
      const rootElement = editor.getRootElement()

      if (
        nativeSelection !== null &&
        (!$isRangeSelection(selection) || rootElement === null || !rootElement.contains(nativeSelection.anchorNode))
      ) {
        return
      }

      if (!$isRangeSelection(selection)) {
        return
      }

      setIsBold(selection.hasFormat('bold'))
      setIsItalic(selection.hasFormat('italic'))
      setIsUnderline(selection.hasFormat('underline'))
      setIsCode(selection.hasFormat('code'))
    })
  }, [editor])

  useEffect(() => {
    document.addEventListener('selectionchange', updateMarks)
    return () => {
      document.removeEventListener('selectionchange', updateMarks)
    }
  }, [updateMarks])

  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      () => {
        $updateToolbar()
        return false
      },
      COMMAND_PRIORITY_CRITICAL,
    )
  }, [editor, $updateToolbar])

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(() => {
        updateMarks()
      }),
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          $updateToolbar()
        })
      }),
    )
  }, [$updateToolbar, editor])

  const label = t(i18nTypeMap.get(blockType) || i18nTypeMap.get('paragraph') || '')

  const onClickButton = (payload: TextFormatType) => {
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, payload)
  }

  return (
    <>
      {editor.hasNodes([TableNode]) && (
        <>
          <ToolbarGroup>
            <Floating menu={<ToolbarFormattingMenu editor={editor} />} placement="bottom-start">
              <ToolbarButton hasMenu={true}>
                <span className="pl-0.5">{label}</span>
              </ToolbarButton>
            </Floating>
          </ToolbarGroup>
          <ToolbarDivider />
        </>
      )}

      <ToolbarGroup>
        <ToolbarButton data-selected={isBold} onClick={() => onClickButton('bold')}>
          <Bold02 size={16} />
        </ToolbarButton>
        <ToolbarButton data-selected={isItalic} onClick={() => onClickButton('italic')}>
          <Italic size={16} />
        </ToolbarButton>
        <ToolbarButton data-selected={isUnderline} onClick={() => onClickButton('underline')}>
          <Underline size={16} />
        </ToolbarButton>
        <ToolbarButton data-selected={isCode} onClick={() => onClickButton('code')}>
          <Code02 size={16} />
        </ToolbarButton>
      </ToolbarGroup>
    </>
  )
}
