import { HTMLAttributes, useCallback, useEffect, useState } from 'react'
import { Control, RegisterOptions, useController } from 'react-hook-form'
import { Lexical, LexicalProps } from './Lexical'

interface LexicalControllerProps
  extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'>,
    Pick<LexicalProps, 'layout' | 'autofocus' | 'readonly' | 'disabled'> {
  // eslint-disable-next-line
  control: Control<any>
  name: string
  rules?: Omit<
    RegisterOptions<Record<string, unknown>, string>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >
  isStream?: boolean
}

/**
 * Controller component for a hook form, handles only the initial value.
 */
export const LexicalController = ({ control, name, rules, isStream, ...rest }: LexicalControllerProps) => {
  const { field } = useController({
    name,
    control,
    rules,
  })

  const [initialValue, setInitialValue] = useState(() => field.value || '')

  useEffect(() => {
    if (isStream) {
      setInitialValue(field.value || '')
    }
  }, [isStream, field.value])

  const onChange = useCallback(
    (value: string) => {
      if (value === '<p><br></p>') {
        field.onChange(null)
      } else {
        field.onChange(value)
      }
    },
    [field.onChange],
  )

  return <Lexical {...rest} readonly={isStream || rest.readonly} value={initialValue} onChange={onChange} />
}
