import { TextareaHTMLAttributes, forwardRef } from 'react';
import { AnimatePresence } from 'framer-motion';
import { cva } from 'class-variance-authority';
import { cn } from '../../libs';
import { ControlHookFormError } from '../../types';
import { FormErrorMessage } from '../FormErrorMessage';

export interface TextareaProps extends Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'size'>, ControlHookFormError {
  /**
   * Placeholder of the textarea.
   */
  placeholder?: string;
  /**
   * Color of the textarea.
   */
  color?: 'primary' | 'danger';
  /**
   * Size of the textarea.
   */
  size?: 'md';
}

const variants = cva(
  [
    'flex w-full items-center rounded-md border text-text-secondary shadow-xs transition-colors placeholder:transition-colors',
    'placeholder:text-text-quarterary',
    'hover:placeholder:text-text-quarterary-hover',
    'focus:border-border-brand-filled',
    'focus:placeholder:text-text-quarterary-hover',
    'focus:text-text-primary',
    'focus:ring-2',
    'focus:ring-brand',
    'disabled:bg-fg-disabled',
    'disabled:border-border-disabled',
    'disabled:text-text-disabled',
    'disabled:placeholder:text-text-disabled',
    'disabled:ring-0',
    'disabled:pointer-events-none',
    'disabled:shadow-none',
  ],
  {
    variants: {
      color: {
        primary: ['border-border-primary', 'hover:border-border-primary-hover'],
        danger: ['border-border-error', 'ring-2 ring-error', 'hover:border-border-error-solid'],
      },
      size: {
        md: 'min-h-16 p-3 text-paragraph-sm leading-4',
      },
      defaultVariants: {
        color: 'primary',
        size: 'md',
      },
    },
  },
);

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ color = 'primary', size = 'md', error, ...rest }, ref) => (
    <>
      <textarea {...rest} ref={ref} className={cn(variants({ color, size }), rest.className)} />
      <AnimatePresence>{error && <FormErrorMessage>{error}</FormErrorMessage>}</AnimatePresence>
    </>
  ),
);
