import { SidebarLinkIconProps, SidebarLinkIconVariant } from '@/components/ui/AppSidebar/types'
import { ReactElement } from 'react'

const dashboardVariantMap = new Map<SidebarLinkIconVariant, ReactElement>([
  [
    'default',
    <>
      <path
        d="M17.5571 8.10918C17.5571 9.02978 16.8107 9.77608 15.89 9.77608C14.9692 9.77608 14.2228 9.02978 14.2228 8.10918C14.2228 7.18858 14.9692 6.44228 15.89 6.44228C16.8107 6.44228 17.5571 7.18858 17.5571 8.10918Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M9.77716 15.888C9.77716 16.8086 9.03076 17.5549 8.11003 17.5549C7.1893 17.5549 6.4429 16.8086 6.4429 15.888C6.4429 14.9674 7.1893 14.2211 8.11003 14.2211C9.03076 14.2211 9.77716 14.9674 9.77716 15.888Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M12 13.6655C12.9207 13.6655 13.6671 12.9192 13.6671 11.9986C13.6671 11.078 12.9207 10.3317 12 10.3317C11.0793 10.3317 10.3329 11.078 10.3329 11.9986C10.3329 12.9192 11.0793 13.6655 12 13.6655Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M19.1464 2.84178C20.1356 3.07515 20.9247 3.86415 21.1581 4.85317V4.86429C22.2806 9.59828 22.2806 14.3989 21.1581 19.1441C20.9247 20.1331 20.1356 20.9221 19.1464 21.1554C16.7791 21.7222 14.3896 22 12 22C9.61045 22 7.2209 21.7222 4.85357 21.1554C3.86441 20.9221 3.0753 20.1331 2.8419 19.1441C1.71937 14.3989 1.71937 9.58717 2.8419 4.85317C3.0753 3.86415 3.86441 3.07515 4.85357 2.84178C9.59933 1.71941 14.4007 1.71941 19.1464 2.84178ZM18.6241 18.9885C18.8019 18.944 18.9464 18.7996 18.9908 18.6218H19.0019C20.0467 14.2211 20.0467 9.76497 19.0019 5.36436C18.9575 5.18655 18.813 5.04209 18.6352 4.99764C16.4346 4.47534 14.2228 4.21975 12 4.21975C9.77716 4.21975 7.56543 4.47534 5.36482 4.99764C5.187 5.04209 5.04251 5.18655 4.99806 5.36436C3.95332 9.76497 3.95332 14.2211 4.99806 18.6218C5.04251 18.7996 5.187 18.944 5.36482 18.9885C9.76605 20.0331 14.2228 20.0331 18.6241 18.9885Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </>,
  ],
  [
    'fill',
    <path
      d="M21.147 4.85317C20.9136 3.86415 20.1245 3.07515 19.1353 2.84178C14.3896 1.71941 9.58822 1.71941 4.85357 2.84178C3.86441 3.07515 3.0753 3.86415 2.8419 4.85317C1.71937 9.58717 1.71937 14.3989 2.8419 19.1441C3.0753 20.1331 3.86441 20.9221 4.85357 21.1554C7.2209 21.7222 9.61045 22 12 22C14.3896 22 16.7791 21.7222 19.1464 21.1554C20.1356 20.9221 20.9247 20.1331 21.1581 19.1441C22.2806 14.3989 22.2806 9.59828 21.1581 4.85317H21.147ZM8.09892 17.5549C7.17644 17.5549 6.43179 16.8104 6.43179 15.888C6.43179 14.9657 7.17644 14.2211 8.09892 14.2211C9.0214 14.2211 9.76605 14.9657 9.76605 15.888C9.76605 16.8104 9.0214 17.5549 8.09892 17.5549ZM11.9889 13.6655C11.0664 13.6655 10.3218 12.921 10.3218 11.9986C10.3218 11.0763 11.0664 10.3317 11.9889 10.3317C12.9114 10.3317 13.656 11.0763 13.656 11.9986C13.656 12.921 12.9114 13.6655 11.9889 13.6655ZM15.8789 9.77608C14.9564 9.77608 14.2117 9.03153 14.2117 8.10918C14.2117 7.18683 14.9564 6.44228 15.8789 6.44228C16.8013 6.44228 17.546 7.18683 17.546 8.10918C17.546 9.03153 16.8013 9.77608 15.8789 9.77608Z"
      fill="currentColor"
      fillRule="evenodd"
    />,
  ],
  [
    'duotone',
    <>
      <rect x="0" y="0" width="24" height="24" rx="6" ry="6" fill="#fff" />
      <path
        d="M18,1c2.76,0,5,2.24,5,5v12c0,2.76-2.24,5-5,5H6c-2.76,0-5-2.24-5-5V6C1,3.24,3.24,1,6,1h12M18,0H6C2.69,0,0,2.69,0,6v12C0,21.31,2.69,24,6,24h12c3.31,0,6-2.69,6-6V6C24,2.69,21.31,0,18,0h0Z"
        fill="#e2e8f0"
      />
      <path
        d="M21.147 4.85317C20.9136 3.86415 20.1245 3.07515 19.1353 2.84178C14.3896 1.71941 9.58822 1.71941 4.85357 2.84178C3.86441 3.07515 3.0753 3.86415 2.8419 4.85317C1.71937 9.58717 1.71937 14.3989 2.8419 19.1441C3.0753 20.1331 3.86441 20.9221 4.85357 21.1554C7.2209 21.7222 9.61045 22 12 22C14.3896 22 16.7791 21.7222 19.1464 21.1554C20.1356 20.9221 20.9247 20.1331 21.1581 19.1441C22.2806 14.3989 22.2806 9.59828 21.1581 4.85317H21.147ZM8.09892 17.5549C7.17644 17.5549 6.43179 16.8104 6.43179 15.888C6.43179 14.9657 7.17644 14.2211 8.09892 14.2211C9.0214 14.2211 9.76605 14.9657 9.76605 15.888C9.76605 16.8104 9.0214 17.5549 8.09892 17.5549ZM11.9889 13.6655C11.0664 13.6655 10.3218 12.921 10.3218 11.9986C10.3218 11.0763 11.0664 10.3317 11.9889 10.3317C12.9114 10.3317 13.656 11.0763 13.656 11.9986C13.656 12.921 12.9114 13.6655 11.9889 13.6655ZM15.8789 9.77608C14.9564 9.77608 14.2117 9.03153 14.2117 8.10918C14.2117 7.18683 14.9564 6.44228 15.8789 6.44228C16.8013 6.44228 17.546 7.18683 17.546 8.10918C17.546 9.03153 16.8013 9.77608 15.8789 9.77608Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </>,
  ],
])

export const Engagement = ({ variant = 'default', ...rest }: SidebarLinkIconProps) => {
  return (
    <svg {...rest} width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      {dashboardVariantMap.get(variant)}
    </svg>
  )
}
