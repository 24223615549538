import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '@/components/icons'

export const ChevronRightOutline: AghanimIcon = forwardRef(function (props, ref) {
  return (
    <IconBase {...props} viewBox="0 0 24 24" ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.34923 19.7592C7.9299 19.3998 7.88134 18.7685 8.24076 18.3492L13.6829 12L8.24076 5.65077C7.88134 5.23145 7.9299 4.60015 8.34923 4.24073C8.76855 3.8813 9.39985 3.92986 9.75927 4.34919L15.7593 11.3492C16.0803 11.7237 16.0803 12.2763 15.7593 12.6508L9.75927 19.6508C9.39985 20.0701 8.76855 20.1187 8.34923 19.7592Z"
        fill="currentColor"
      />
    </IconBase>
  )
})
