import { Placement } from '@floating-ui/react';
import { getMotionProps } from '../motion';

/**
 * The function will return props for animation depending on the position of the popup.
 * @param placement
 */
export const getFloatingMotionProps = (placement: Placement | undefined) => {
  let x = 0,
    y = 0;

  if (placement?.includes('top')) {
    y = 6;
  } else if (placement?.includes('bottom')) {
    y = -6;
  } else if (placement?.includes('left')) {
    x = 3;
  } else if (placement?.includes('right')) {
    x = -3;
  }

  let marginLeft = 0,
    marginTop = 0;

  if (placement?.includes('top')) {
    marginTop = 3;
  } else if (placement?.includes('bottom')) {
    marginTop = -3;
  } else if (placement?.includes('left')) {
    marginLeft = 3;
  } else if (placement?.includes('right')) {
    marginLeft = -3;
  }

  return getMotionProps(
    {
      initial: { x, y, opacity: 0 },
      animate: { x: 0, y: 0, opacity: 1 },
      exit: { marginTop, marginLeft, opacity: 0 },
    },
    { duration: 0.1 },
  );
};
