import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '@/components/icons'

export const InsertRowAboveOutline: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 24 24" ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 9C4 7.89543 4.89543 7 6 7C6.55228 7 7 6.55228 7 6C7 5.44772 6.55228 5 6 5C3.79086 5 2 6.79086 2 9V18C2 20.2091 3.79086 22 6 22H18C20.2091 22 22 20.2091 22 18V9C22 6.79086 20.2091 5 18 5C17.4477 5 17 5.44772 17 6C17 6.55228 17.4477 7 18 7C19.1046 7 20 7.89543 20 9V12.2C20 12.3657 19.8657 12.5 19.7 12.5H4.3C4.13431 12.5 4 12.3657 4 12.2V9ZM4.3 14.5C4.13431 14.5 4 14.6343 4 14.8V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V14.8C20 14.6343 19.8657 14.5 19.7 14.5H4.3Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C12.5523 2 13 2.44772 13 3V4.7C13 4.86569 13.1343 5 13.3 5H15C15.5523 5 16 5.44772 16 6C16 6.55228 15.5523 7 15 7H13.3C13.1343 7 13 7.13431 13 7.3V9C13 9.55228 12.5523 10 12 10C11.4477 10 11 9.55228 11 9V7.3C11 7.13431 10.8657 7 10.7 7H9C8.44772 7 8 6.55228 8 6C8 5.44772 8.44772 5 9 5H10.7C10.8657 5 11 4.86569 11 4.7L11 3C11 2.44772 11.4477 2 12 2Z"
        fill="currentColor"
      />
    </IconBase>
  )
})
