import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { creatorsDefinePlatformQueryOptions } from '@/layouts/engagement/api'

export const useCreatorsDefinePlatform = (url: string) => {
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const { data: platform, isLoading, refetch } = useQuery(creatorsDefinePlatformQueryOptions(companyId, gameId, url))
  return {
    platform,
    isLoading,
    refetch,
  }
}
