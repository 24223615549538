import { createElement } from 'react'
import { MenuItemBaseProps } from '@/components/ui'

export interface SelectBaseValueProps {
  item: MenuItemBaseProps
}

/**
 * Base rendering of the value for the selector button.
 * Uses the CSS --colors from SelectBaseButton.
 */
export const SelectBaseValue = ({ item }: SelectBaseValueProps) => (
  <>
    {item.icon && <div className="shrink-0 text-[--icon-color]">{createElement(item.icon, { size: 20 })}</div>}
    <span className="truncate text-[--label-color]">{item.children}</span>
    {item.extra && (
      <div className="ml-auto shrink-0 text-[--extra-color] transition-colors">
        {createElement(item.extra, { size: 20 })}
      </div>
    )}
  </>
)
