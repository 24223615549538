import { useQuery } from '@tanstack/react-query'
import { creatorsGetTermsQueryOptions } from '@/layouts/creator/api'

export const useCreatorTerm = (token: string) => {
  const { data, isLoading, isError } = useQuery(creatorsGetTermsQueryOptions(token))

  return {
    accepted: !!data?.accepted_at,
    isLoading,
    isError,
  }
}
