import { HTMLAttributes } from 'react';
import { cva } from 'class-variance-authority';

interface CardProps extends HTMLAttributes<HTMLDivElement> {
  size?: 'sm' | 'md' | 'lg';
  color?: 'primary' | 'secondary';
}

const variants = cva('border border-border-secondary', {
  variants: {
    size: {
      sm: 'rounded-md p-1.5',
      md: 'rounded-xl p-3',
      lg: 'rounded-2xl1 p-6',
    },
    color: {
      primary: 'bg-fg-primary',
      secondary: 'bg-fg-secondary',
    },
  },
});

export const Card = ({ size = 'md', color = 'primary', ...rest }: CardProps) => {
  return <div {...rest} className={variants({ size, color, className: rest.className })} />;
};
