import { HTMLAttributes, forwardRef } from 'react';
import { cn } from '../../libs';

type SkeletonProps = HTMLAttributes<HTMLDivElement>;

/**
 * A skeleton element.
 *
 * Customizable using Tailwind classes. I recommend placing it next to the original component and using GitHub Copilot for quick generation of Tailwind classes.
 */
export const Skeleton = forwardRef<HTMLDivElement, SkeletonProps>(({ ...rest }, ref) => (
  <div {...rest} ref={ref} className={cn('h-[24px] w-full animate-pulse rounded bg-fg-primary-alt', rest.className)} />
));
