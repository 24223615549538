import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '@/components/icons'

export const Segment: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 24 24" ref={ref}>
      <path
        d="M3.71561 12.7542C3.87139 12.7542 4.00097 12.8732 4.02067 13.0277C4.4824 16.6486 7.35135 19.5176 10.9723 19.9793C11.1268 19.999 11.2458 20.1286 11.2458 20.2844V21.7027C11.2458 21.8781 11.0963 22.0169 10.9219 21.9983C6.22957 21.4982 2.50184 17.7704 2.00167 13.0781C1.98308 12.9037 2.12187 12.7542 2.29727 12.7542H3.71561Z"
        fill="currentColor"
      />
      <path
        d="M11.2458 3.71561C11.2458 3.87139 11.1268 4.00097 10.9723 4.02067C7.35135 4.4824 4.4824 7.35135 4.02067 10.9723C4.00097 11.1268 3.87139 11.2458 3.71561 11.2458H2.29727C2.12187 11.2458 1.98308 11.0963 2.00167 10.9219C2.50184 6.22957 6.22957 2.50184 10.9219 2.00167C11.0963 1.98308 11.2458 2.12187 11.2458 2.29727V3.71561Z"
        fill="currentColor"
      />
      <path
        d="M20.2844 11.2458C20.1286 11.2458 19.999 11.1268 19.9793 10.9723C19.5176 7.35135 16.6486 4.4824 13.0277 4.02067C12.8732 4.00097 12.7542 3.87139 12.7542 3.71561V2.29727C12.7542 2.12187 12.9037 1.98308 13.0781 2.00167C17.7704 2.50184 21.4982 6.22957 21.9983 10.9219C22.0169 11.0963 21.8781 11.2458 21.7027 11.2458H20.2844Z"
        fill="currentColor"
      />
      <path
        d="M12.7542 20.2844C12.7542 20.1286 12.8732 19.999 13.0277 19.9793C16.6486 19.5176 19.5176 16.6486 19.9793 13.0277C19.999 12.8732 20.1286 12.7542 20.2844 12.7542H21.7027C21.8781 12.7542 22.0169 12.9037 21.9983 13.0781C21.4982 17.7704 17.7704 21.4982 13.0781 21.9983C12.9037 22.0169 12.7542 21.8781 12.7542 21.7027V20.2844Z"
        fill="currentColor"
      />
      <path
        d="M14.2977 11.3833C13.3351 11.3833 12.5253 10.5245 12.5253 9.42299C12.5253 8.34947 13.3398 7.5 14.2977 7.5C15.2557 7.5 16.0702 8.33547 16.0702 9.41832C16.0702 10.5245 15.2603 11.3833 14.2977 11.3833ZM9.23024 11.4813C8.3926 11.4813 7.68454 10.7299 7.68454 9.77771C7.68454 8.83956 8.39722 8.1021 9.23024 8.1021C10.0586 8.1021 10.7713 8.82555 10.7713 9.76838C10.7713 10.7299 10.0679 11.4813 9.23024 11.4813ZM6.82376 15.5C6.22214 15.5 6 15.2386 6 14.7905C6 13.6003 7.27729 12.1534 9.22561 12.1534C10.0077 12.1534 10.6325 12.3821 11.1138 12.6902C10.1095 13.4883 9.61435 14.8186 10.0864 15.5H6.82376ZM11.5766 15.5C10.8407 15.5 10.5908 15.2713 10.5908 14.8559C10.5908 13.7217 12.0255 12.1628 14.2931 12.1628C16.5654 12.1628 18 13.7217 18 14.8559C18 15.2713 17.7501 15.5 17.0143 15.5H11.5766Z"
        fill="currentColor"
      />
    </IconBase>
  )
})
