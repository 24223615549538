import { ChangeEvent, MouseEvent, ReactNode, useId } from 'react'
import { Checkbox } from '@dashboard/ui'
import { TableCell } from './TableCell'

interface TableCellBulkCheckboxProps {
  selected: boolean
  disabled?: boolean
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  children?: ReactNode
}

export const TableCellBulkCheckbox = ({ selected, disabled, onChange, children }: TableCellBulkCheckboxProps) => {
  const id = useId()

  const onClickCell = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation()
  }

  return (
    <TableCell className="overflow-visible" width="auto" onClick={onClickCell}>
      <Checkbox
        id={id}
        key={selected ? `${id}-checked` : `${id}-unchecked`}
        checked={!disabled ? selected : false}
        disabled={disabled}
        onChange={onChange}
      >
        {children}
      </Checkbox>
    </TableCell>
  )
}
