import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '@/components/icons'

export const InsertColumnRightOutline: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 24 24" ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 20C16.1046 20 17 19.1046 17 18C17 17.4477 17.4477 17 18 17C18.5523 17 19 17.4477 19 18C19 20.2091 17.2091 22 15 22L6 22C3.79086 22 2 20.2091 2 18L2 6C2 3.79086 3.79086 2 6 2L15 2C17.2091 2 19 3.79086 19 6C19 6.55229 18.5523 7 18 7C17.4477 7 17 6.55229 17 6C17 4.89543 16.1046 4 15 4L11.8 4C11.6343 4 11.5 4.13431 11.5 4.3L11.5 19.7C11.5 19.8657 11.6343 20 11.8 20L15 20ZM9.5 19.7C9.5 19.8657 9.36569 20 9.2 20L6 20C4.89543 20 4 19.1046 4 18L4 6C4 4.89543 4.89543 4 6 4L9.2 4C9.36568 4 9.5 4.13431 9.5 4.3L9.5 19.7Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 8C18.5523 8 19 8.44772 19 9V10.7C19 10.8657 19.1343 11 19.3 11H21C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H19.3C19.1343 13 19 13.1343 19 13.3V15C19 15.5523 18.5523 16 18 16C17.4477 16 17 15.5523 17 15V13.3C17 13.1343 16.8657 13 16.7 13H15C14.4477 13 14 12.5523 14 12C14 11.4477 14.4477 11 15 11H16.7C16.8657 11 17 10.8657 17 10.7L17 9C17 8.44772 17.4477 8 18 8Z"
        fill="currentColor"
      />
    </IconBase>
  )
})
