import { ReactNode } from 'react'

import ImageCellNoText from '@/components/ImageCellNoText'
import { Card } from '@/layouts/engagement/component/Card'

interface EngagementItemCardProps {
  item: {
    name: string
    image_url?: string
    sku: string
    description: string
  }
  actions?: ReactNode
}

export const EngagementItemCard = ({
  item: { name, image_url, sku, description },
  actions,
}: EngagementItemCardProps) => (
  <Card size="md" className="inline-flex items-center justify-between gap-6 bg-fg-primary shadow-xs">
    <div className="h-full max-h-12 shrink-0 rounded-md bg-fg-primary-alt px-3.5">
      <ImageCellNoText
        image_url={image_url}
        image_size="3rem"
        style={{
          objectPosition: 'center',
          objectFit: 'cover',
        }}
      />
    </div>

    <div className="flex min-w-0 grow flex-col">
      <div className="inline-flex items-center gap-1.5">
        <span className="h-6 truncate text-base font-semibold text-text-secondary" title={name}>
          {name}
        </span>
        <span className="text-caption-sm font-normal text-text-tertiary">·</span>
        <span className="truncate text-caption-sm font-normal text-text-tertiary" title={sku}>
          {sku}
        </span>
      </div>
      <span className="overflow-hidden truncate text-caption-sm font-normal text-text-tertiary">{description}</span>
    </div>

    {actions && <div className="flex shrink-0 gap-2">{actions}</div>}
  </Card>
)
