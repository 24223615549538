import { Dayjs } from 'dayjs'
import { SelectTime } from '@/components/ui/SelectTime'
import { Button, InputDate, XCircle, cn } from '@dashboard/ui'

export interface SelectDateTimeProps {
  value: number | undefined
  onChange: (value: number | null) => void
  minDate?: Dayjs
  maxDate?: Dayjs
  className?: string
  disableClear?: boolean
}

export const SelectDateTime = ({ value, onChange, className, disableClear }: SelectDateTimeProps) => {
  const onDateChanged = (date: Date) => {
    date.setHours(value ? new Date(value * 1000).getHours() : 0)
    date.setMinutes(0)
    date.setSeconds(0)

    onChange(date.getTime() / 1000)
  }

  const onTimeChanged = (hours: number) => {
    const currentDate = value ? new Date(value * 1000) : new Date()
    currentDate.setHours(hours)
    currentDate.setMinutes(0)
    currentDate.setSeconds(0)

    onChange(currentDate.getTime() / 1000)
  }

  const getTimeValue = () => {
    if (!value) {
      return null
    }
    let d = new Date(value * 1000)
    return d.getHours()
  }

  const onClickClear = () => {
    onChange(null)
  }

  return (
    <div className={cn('flex items-center gap-2', className)}>
      <InputDate
        value={value ? new Date(value * 1000) : null}
        onChange={value => value && onDateChanged(value)}
        hideClearButton={true}
      />
      <SelectTime onChange={value => onTimeChanged(value as number)} value={getTimeValue()} />
      {!disableClear && (
        <Button variant="link" className="text-text-quarterary" color="secondary" size="xs" onClick={onClickClear}>
          <XCircle size={16} />
        </Button>
      )}
    </div>
  )
}
