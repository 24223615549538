import { MouseEvent, forwardRef, useCallback, useEffect, useMemo, useRef } from 'react';
import { withMask } from 'use-mask-input';
import { useMergeRefs } from '@floating-ui/react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { CalendarOutline } from '../../icons';
import { cn } from '../../libs';
import { useUiContext } from '../../providers';
import { Input, InputButtonClear, InputProps } from '../Input';
import { Popper } from '../Popper';
import { InputDateMenu } from './InputDateMenu';
import { getInputMaskValue } from './getInputMaskValue';

dayjs.extend(customParseFormat);

interface InputDateProps extends Omit<InputProps, 'defaultValue' | 'value' | 'extraLeft' | 'extraRight' | 'onChange'> {
  /**
   * Hide clear button on input.
   * @deprecated remove after create InputDate (withTime: boolean)
   */
  hideClearButton?: boolean;
  /**
   * Value of the date input.
   */
  value: Date | null;
  /**
   * Change handler for the date input.
   */
  onChange: (value: Date | null) => void;
}

export const InputDate = forwardRef<HTMLInputElement, InputDateProps>(
  ({ hideClearButton = false, value, placeholder, onChange, ...rest }, ref) => {
    const { locale } = useUiContext();
    const originRef = useRef<HTMLInputElement>(null);
    const inputFormat = useMemo(() => getInputMaskValue(locale), [locale]);

    const inputRef = withMask('datetime', {
      inputFormat,
      alias: 'datetime',
      prefillYear: false,
      oncleared: () => {
        onChange(null);
      },
      oncomplete: () => {
        const date = dayjs(
          // @ts-ignore
          originRef.current?.value,
          // @ts-ignore
          // eslint-disable-next-line
          originRef.current?.inputmask.userOptions.inputFormat.toUpperCase(),
        ).toDate();
        onChange(date);
      },
    });

    const referenceMergedRef = useMergeRefs([originRef, inputRef, ref]);

    const onChangeCalendar = useCallback(
      (value: Date | null) => {
        onChange(value);
      },
      [onChange],
    );

    const onClickClear = useCallback(
      (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        onChange(null);
      },
      [onChange],
    );

    useEffect(() => {
      // @ts-ignore
      // eslint-disable-next-line
      originRef.current?.inputmask.setValue(value ? value.toLocaleDateString(locale) : '');
      // setSelected(value);
    }, [locale, value]);

    return (
      <Popper
        popper={props => <InputDateMenu {...props} value={value} onChange={onChangeCalendar} />}
        params={{ toggle: false, width: 'auto' }}
        focusManager={{ disabled: true }}
      >
        <div className={cn('group/input-popper', rest.disabled && 'pointer-events-none')}>
          <Input
            {...rest}
            ref={referenceMergedRef}
            role="input"
            className={cn('text-caption-md', 'group-[&[data-open="true"]]/input-popper:placeholder:text-text-primary')}
            placeholder={placeholder || inputFormat}
            extraLeft={{ icon: CalendarOutline }}
            extraRight={
              !hideClearButton && value
                ? { icon: () => <InputButtonClear onClick={onClickClear} />, pointer: true }
                : undefined
            }
          />
        </div>
      </Popper>
    );
  },
);
