import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { ItemRead, ItemType } from '../../../api/dashboard'
import { Badge, TableCell, TableCellBulkCheckbox, TableRow, TableRowEditButton, useModal } from '@/ui'
import { cn } from '../../../libs/cn'
import { SKU_BUNDLE_ITEMS_DETAILS_PATH, SKU_ITEMS_DETAILS_PATH } from '../../../libs/routerPaths'
import { useGenerateCompanyPath } from '../../../libs/hooks'
import { itemCategoriesQuery } from '../../item-category/api/itemCategoriesQuery'
import { Archive, Unarchive } from '@/icons'
import { BulkArchiveModal } from './BulkArchiveModal'
import { BulkDeleteModal } from './BulkDeleteModal'
import { BulkUnarchiveModal } from './BulkUnarchiveModal'
import { SkuImageName } from './SkuImageName'
import { PriceView } from './PriceView'
import { BundleNestedItems } from '@/layouts/game-items/components/BundleNestedItems'

interface SkuItemRowProps {
  item: ItemRead
  canEdit?: boolean
  /**
   * Props for bulk select.
   */
  bulk?: {
    isSelected: boolean
    onSelect: (id: ItemRead['id']) => void
  }
}

export const SkuItemRow = ({ item, canEdit, bulk }: SkuItemRowProps) => {
  const { t } = useTranslation()
  const { generatePath } = useGenerateCompanyPath()

  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }

  const { data: categories = [], isLoading } = useQuery({
    ...itemCategoriesQuery(companyId, gameId, { limit: 100 }),
    refetchOnMount: false,
  })

  const detailPath = item.type === ItemType.Bundle ? SKU_BUNDLE_ITEMS_DETAILS_PATH : SKU_ITEMS_DETAILS_PATH

  const openBulkArchiveModal = useModal<{ items: ItemRead[] }>(props => <BulkArchiveModal {...props} />)
  const openBulkDeleteModal = useModal<{ items: ItemRead[] }>(props => <BulkDeleteModal {...props} />)
  const openBulkUnarchiveModal = useModal<{ items: ItemRead[] }>(props => <BulkUnarchiveModal {...props} />)

  const getCategoryName = (item: ItemRead) => {
    if (isLoading) {
      return <div className="h-[24px] w-1/2 animate-pulse rounded bg-fg-primary-alt" />
    }

    if (!item.categories?.length) {
      return '-'
    }

    return categories
      .filter(category => item.categories?.includes(category.id))
      .map(category => category.name)
      .join(', ')
  }

  const renderType = (item: ItemRead) => {
    if (item.type == ItemType.Bundle) {
      return t('sku.item.bundle')
    }

    if (item.is_currency) {
      return t('sku.item.is_currency')
    }

    if (item.is_stackable) {
      return t('sku.item.is_stackable')
    }

    return t('sku.item.is_non_stackable')
  }

  return (
    <TableRow
      className={cn(
        'text-sm font-medium',
        bulk?.isSelected && "border-border-primary bg-fg-primary-alt [&[href*='/']]:hover:bg-fg-primary-alt",
      )}
      to={canEdit ? generatePath(detailPath, { itemId: item.id }) : undefined}
    >
      {canEdit && bulk && <TableCellBulkCheckbox selected={bulk.isSelected} onChange={() => bulk.onSelect(item.id)} />}
      <TableCell className="flex items-center gap-3" width="100%">
        <SkuImageName item={item} />
        {item.archived_at && <Badge variant="gray-secondary">{t('sku.item.state.archived')}</Badge>}
      </TableCell>
      {item.type == ItemType.Bundle ? (
        <TableCell width="40%" className="truncate">
          <BundleNestedItems item={item} />
        </TableCell>
      ) : (
        <TableCell width="25%" className="truncate">
          {renderType(item)}
        </TableCell>
      )}
      <TableCell width="25%" className="truncate">
        {getCategoryName(item)}
      </TableCell>
      <TableCell width="25%" className="truncate">
        <PriceView item={item} />
      </TableCell>
      <TableCell width={62}>
        {canEdit && (
          <TableRowEditButton
            onChange={v => {
              switch (v) {
                case 'archive':
                  openBulkArchiveModal({ items: [item] })
                  break
                case 'unarchive':
                  openBulkUnarchiveModal({ items: [item] })
                  break
                case 'delete':
                  openBulkDeleteModal({ items: [item] })
                  break
              }
            }}
            options={
              !item.archived_at
                ? [
                    {
                      icon: <Archive />,
                      children: <span>{t('archive')}</span>,
                      value: 'archive',
                    },
                  ]
                : [
                    {
                      icon: <Unarchive />,
                      children: <span>{t('unarchive')}</span>,
                      value: 'unarchive',
                    },
                    // {
                    //   icon: <Trash className="text-text-error-primary" />,
                    //   children: <span className="text-text-error-primary">{t('sku.item.delete')}</span>,
                    //   value: 'delete',
                    // },
                  ]
            }
          />
        )}
      </TableCell>
    </TableRow>
  )
}
