import { KeyboardEvent as ReactKeyboardEvent, forwardRef, useCallback } from 'react';
import { InputSearch, InputSearchProps } from '../InputSearch';

export type MenuSearchProps = InputSearchProps;

/**
 * Component for searching in a menu.
 *
 * Default hotkey: `alt+s`.
 */
export const MenuSearch = forwardRef<HTMLInputElement, MenuSearchProps>(({ ...rest }, ref) => {
  const onKeyDown = useCallback((e: ReactKeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      const enterEvent = new KeyboardEvent('keydown', {
        key: 'Enter',
        code: 'Enter',
        bubbles: true,
      });
      e.currentTarget.dispatchEvent(enterEvent);
    }
  }, []);

  return (
    <div className="relative -m-3 mb-3 border-b border-border-secondary p-3">
      <InputSearch {...rest} ref={ref} aria-keyshortcuts="alt+s" onKeyDown={onKeyDown} />
    </div>
  );
});
