import { PageRead } from '@/api/dashboard'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

export const useParentLookup = (parents: PageRead[], page?: PageRead) => {
  const { parent, child } = useParams() as { parent?: string; child?: string }

  const parentMap = useMemo(() => Object.fromEntries(parents.map(p => [p.id, p])), [parents])

  const currentParent = useMemo(() => {
    if (page?.parent_id) {
      return parentMap[page.parent_id]
    }

    if (child) {
      return parents.filter(p => p.slug === child).find(pr => pr.parent_id && parentMap[pr.parent_id]?.slug === parent)
    }

    return parents.find(p => p.slug === parent)
  }, [parent, child, parentMap, page])

  return { parent: currentParent }
}
