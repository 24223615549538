import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { ErrorMessage } from '@hookform/error-message'
import { Item } from '../../../api/dashboard'
import { Button, FieldGroup, FieldValidationMessage, Modal, ModalFooter, ModalProps, ModalTitle, Select } from '@/ui'
import { itemCategoriesQuery } from '../../item-category/api/itemCategoriesQuery'
import { useItemBulkUpdateMutate } from '../api'
import { useMemo } from 'react'

interface BulkEditCategoryModalProps extends ModalProps {
  items: Item[]
  onReset?: (items: string[]) => void
}

interface BulkEditCategoryData {
  category: string | null
}

export const BulkEditCategoryModal = ({ items, onReset, ...rest }: BulkEditCategoryModalProps) => {
  const { t } = useTranslation()
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const { mutateAsync } = useItemBulkUpdateMutate(companyId, gameId)

  const { data: categories = [], isLoading } = useQuery({
    ...itemCategoriesQuery(companyId, gameId, { limit: 100 }),
  })

  const allIsSameCategory = useMemo(
    () => items.every(item => item.categories?.includes(items.at(0)?.categories?.at(0) || '')),
    [items],
  )

  const {
    formState: { isSubmitting, errors },
    control,
    handleSubmit,
  } = useForm<BulkEditCategoryData>({
    defaultValues: {
      category: allIsSameCategory ? items.at(0)?.categories?.at(0) : null,
    },
  })

  const onSubmit = async (data: BulkEditCategoryData) => {
    await mutateAsync({ data: items.map(item => ({ id: item.id, categories: data.category ? [data.category] : [] })) })
    rest.onClose?.()
    onReset?.([])
  }

  return (
    <Modal className="max-w-[600px]" {...rest}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalTitle>{t('sku.bulk-edit-category.title', { count: items.length })}</ModalTitle>

        <div className="mb-6">
          <p className="text-paragraph-md text-text-secondary">
            {t('sku.bulk-edit-category.desc', { count: items.length })}
          </p>
        </div>

        <FieldGroup label={t('sku.bulk-edit-category.select.label')}>
          <Controller
            control={control}
            name="category"
            render={({ field }) => (
              <Select
                {...field}
                disabled={isLoading}
                placeholder={t('sku.bulk-edit-category.select.placeholder')}
                options={categories.map(category => ({ children: category.name, value: category.id }))}
              />
            )}
          />
          <ErrorMessage
            name="category"
            errors={errors}
            render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
          />
        </FieldGroup>

        <ModalFooter>
          <Button type="button" size="lg" onClick={rest.onClose}>
            {t('sku.bulk-edit-category.cancel')}
          </Button>
          <Button type="submit" variant="primary" size="lg" isLoading={isSubmitting}>
            {t('sku.bulk-edit-category.submit')}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}
