import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const RedditIcon: AghanimIcon = forwardRef(function (props, ref) {
  return (
    <IconBase {...props} viewBox="0 0 48 48" ref={ref}>
      <path
        d="M0 16.1C0 10.4051 0 7.55764 1.12587 5.39026C2.07462 3.56382 3.56382 2.07462 5.39026 1.12587C7.55764 0 10.4051 0 16.1 0H31.9C37.5949 0 40.4424 0 42.6097 1.12587C44.4362 2.07462 45.9254 3.56382 46.8741 5.39026C48 7.55764 48 10.4051 48 16.1V31.9C48 37.5949 48 40.4424 46.8741 42.6097C45.9254 44.4362 44.4362 45.9254 42.6097 46.8741C40.4424 48 37.5949 48 31.9 48H16.1C10.4051 48 7.55764 48 5.39026 46.8741C3.56382 45.9254 2.07462 44.4362 1.12587 42.6097C0 40.4424 0 37.5949 0 31.9V16.1Z"
        fill="#FF4500"
      />
      <g transform="translate(4,7)">
        <path
          d="M33.6125 22.7999C36.7088 22.7999 39.2188 20.2899 39.2188 17.1937C39.2188 14.0974 36.7088 11.5874 33.6125 11.5874C30.5163 11.5874 28.0063 14.0974 28.0063 17.1937C28.0063 20.2899 30.5163 22.7999 33.6125 22.7999Z"
          fill="url(#paint0_radial_7117_5745)"
        />
        <path
          d="M6.3875 22.7999C9.48375 22.7999 11.9938 20.2899 11.9938 17.1937C11.9938 14.0974 9.48375 11.5874 6.3875 11.5874C3.29125 11.5874 0.78125 14.0974 0.78125 17.1937C0.78125 20.2899 3.29125 22.7999 6.3875 22.7999Z"
          fill="url(#paint1_radial_7117_5745)"
        />
        <path
          d="M20.0187 33.9937C28.8518 33.9937 36.0125 28.6211 36.0125 21.9937C36.0125 15.3662 28.8518 9.99365 20.0187 9.99365C11.1856 9.99365 4.02498 15.3662 4.02498 21.9937C4.02498 28.6211 11.1856 33.9937 20.0187 33.9937Z"
          fill="url(#paint2_radial_7117_5745)"
        />
        <path
          d="M15.275 20.8313C15.1813 22.8563 13.8313 23.6062 12.2563 23.6062C10.6813 23.6062 9.48125 22.5563 9.575 20.5312C9.66875 18.5062 11.0188 17.1562 12.5938 17.1562C14.1688 17.1562 15.3688 18.8063 15.275 20.8313Z"
          fill="#842123"
        />
        <path
          d="M30.425 20.5312C30.5187 22.5563 29.3187 23.6062 27.7437 23.6062C26.1687 23.6062 24.8187 22.875 24.725 20.8313C24.6312 18.8063 25.8312 17.1562 27.4062 17.1562C28.9812 17.1562 30.3312 18.4875 30.425 20.5312Z"
          fill="#842123"
        />
        <path
          d="M24.7437 21.0185C24.8375 22.9122 26.0937 23.606 27.5562 23.606C29.0187 23.606 30.1437 22.5747 30.0687 20.6622C29.975 18.7685 28.7187 17.5122 27.2562 17.5122C25.7937 17.5122 24.65 19.106 24.7437 21.0185Z"
          fill="url(#paint3_radial_7117_5745)"
        />
        <path
          d="M15.275 21.0185C15.1812 22.9122 13.925 23.606 12.4625 23.606C11 23.606 9.87497 22.5747 9.96872 20.6622C10.0625 18.7685 11.3187 17.5122 12.7812 17.5122C14.2437 17.5122 15.3687 19.106 15.275 21.0185Z"
          fill="url(#paint4_radial_7117_5745)"
        />
        <path
          d="M20.0188 24.9561C18.0312 24.9561 16.1375 25.0498 14.375 25.2186C14.075 25.2561 13.8875 25.5561 14 25.8186C14.975 28.1248 17.3 29.7561 20.0188 29.7561C22.7375 29.7561 25.0437 28.1436 26.0375 25.8186C26.15 25.5373 25.9625 25.2373 25.6625 25.2186C23.9 25.0498 22.0063 24.9561 20.0188 24.9561Z"
          fill="#BBCFDA"
        />
        <path
          d="M20.0187 25.4062C18.0312 25.4062 16.1375 25.5 14.3937 25.6875C14.0937 25.725 13.9062 26.025 14.0187 26.3063C14.9937 28.65 17.3187 30.3 20.0187 30.3C22.7187 30.3 25.0437 28.65 26.0187 26.3063C26.1312 26.025 25.9437 25.725 25.6437 25.6875C23.8812 25.5 21.9875 25.4062 20.0187 25.4062Z"
          fill="white"
        />
        <path
          d="M20.0187 25.1621C18.0687 25.1621 16.2125 25.2559 14.4875 25.4246C14.1875 25.4621 14 25.7621 14.1125 26.0246C15.0875 28.3309 17.3562 29.9621 20.0187 29.9621C22.6812 29.9621 24.95 28.3496 25.925 26.0246C26.0375 25.7434 25.85 25.4434 25.55 25.4246C23.825 25.2746 21.9687 25.1621 20.0187 25.1621Z"
          fill="url(#paint5_radial_7117_5745)"
        />
        <path
          d="M28.775 8.38115C30.9703 8.38115 32.75 6.60148 32.75 4.40615C32.75 2.21082 30.9703 0.431152 28.775 0.431152C26.5796 0.431152 24.8 2.21082 24.8 4.40615C24.8 6.60148 26.5796 8.38115 28.775 8.38115Z"
          fill="url(#paint6_radial_7117_5745)"
        />
        <path
          d="M19.9625 10.4997C19.4938 10.4997 19.1 10.2935 19.1 9.99346C19.1 6.43096 21.9875 3.54346 25.55 3.54346C26.0188 3.54346 26.4125 3.93721 26.4125 4.40596C26.4125 4.87471 26.0188 5.26846 25.55 5.26846C22.9438 5.26846 20.825 7.38721 20.825 9.99346C20.825 10.3122 20.4313 10.4997 19.9625 10.4997Z"
          fill="url(#paint7_radial_7117_5745)"
        />
        <path
          d="M14.2438 21.9563C14.2438 22.6875 13.4563 23.025 12.5 23.025C11.5438 23.025 10.7563 22.6875 10.7563 21.9563C10.7563 21.225 11.5438 20.625 12.5 20.625C13.4563 20.625 14.2438 21.2063 14.2438 21.9563Z"
          fill="#FF6101"
        />
        <path
          d="M29.2813 21.9563C29.2813 22.6875 28.4938 23.025 27.5375 23.025C26.5813 23.025 25.7938 22.6875 25.7938 21.9563C25.7938 21.225 26.5813 20.625 27.5375 20.625C28.4938 20.625 29.2813 21.2063 29.2813 21.9563Z"
          fill="#FF6101"
        />
        <path
          d="M13.7 19.9496C14.0417 19.9496 14.3187 19.6474 14.3187 19.2746C14.3187 18.9018 14.0417 18.5996 13.7 18.5996C13.3582 18.5996 13.0812 18.9018 13.0812 19.2746C13.0812 19.6474 13.3582 19.9496 13.7 19.9496Z"
          fill="#FFC49C"
        />
        <path
          d="M28.4938 19.9496C28.8355 19.9496 29.1125 19.6474 29.1125 19.2746C29.1125 18.9018 28.8355 18.5996 28.4938 18.5996C28.152 18.5996 27.875 18.9018 27.875 19.2746C27.875 19.6474 28.152 19.9496 28.4938 19.9496Z"
          fill="#FFC49C"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_7117_5745"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(33.6897 14.1669) scale(11.2315 9.79772)"
        >
          <stop stopColor="#FEFFFF" />
          <stop offset="0.4" stopColor="#FEFFFF" />
          <stop offset="0.51" stopColor="#F9FCFC" />
          <stop offset="0.62" stopColor="#EDF3F5" />
          <stop offset="0.7" stopColor="#DEE9EC" />
          <stop offset="0.72" stopColor="#D8E4E8" />
          <stop offset="0.76" stopColor="#CCD8DF" />
          <stop offset="0.8" stopColor="#C8D5DD" />
          <stop offset="0.83" stopColor="#CCD6DE" />
          <stop offset="0.85" stopColor="#D8DBE2" />
          <stop offset="0.88" stopColor="#EDE3E9" />
          <stop offset="0.9" stopColor="#FFEBEF" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_7117_5745"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(6.47972 14.1669) scale(11.2315 9.79772)"
        >
          <stop stopColor="#FEFFFF" />
          <stop offset="0.4" stopColor="#FEFFFF" />
          <stop offset="0.51" stopColor="#F9FCFC" />
          <stop offset="0.62" stopColor="#EDF3F5" />
          <stop offset="0.7" stopColor="#DEE9EC" />
          <stop offset="0.72" stopColor="#D8E4E8" />
          <stop offset="0.76" stopColor="#CCD8DF" />
          <stop offset="0.8" stopColor="#C8D5DD" />
          <stop offset="0.83" stopColor="#CCD6DE" />
          <stop offset="0.85" stopColor="#D8DBE2" />
          <stop offset="0.88" stopColor="#EDE3E9" />
          <stop offset="0.9" stopColor="#FFEBEF" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_7117_5745"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(20.4401 12.5954) scale(33.8788 23.7872)"
        >
          <stop stopColor="#FEFFFF" />
          <stop offset="0.4" stopColor="#FEFFFF" />
          <stop offset="0.51" stopColor="#F9FCFC" />
          <stop offset="0.62" stopColor="#EDF3F5" />
          <stop offset="0.7" stopColor="#DEE9EC" />
          <stop offset="0.72" stopColor="#D8E4E8" />
          <stop offset="0.76" stopColor="#CCD8DF" />
          <stop offset="0.8" stopColor="#C8D5DD" />
          <stop offset="0.83" stopColor="#CCD6DE" />
          <stop offset="0.85" stopColor="#D8DBE2" />
          <stop offset="0.88" stopColor="#EDE3E9" />
          <stop offset="0.9" stopColor="#FFEBEF" />
        </radialGradient>
        <radialGradient
          id="paint3_radial_7117_5745"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(26.99 22.3068) rotate(180) scale(2.83057 4.15552)"
        >
          <stop stopColor="#FF6600" />
          <stop offset="0.5" stopColor="#FF4500" />
          <stop offset="0.7" stopColor="#FC4301" />
          <stop offset="0.82" stopColor="#F43F07" />
          <stop offset="0.92" stopColor="#E53812" />
          <stop offset="1" stopColor="#D4301F" />
        </radialGradient>
        <radialGradient
          id="paint4_radial_7117_5745"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(12.9107 22.3068) scale(2.83058 4.15552)"
        >
          <stop stopColor="#FF6600" />
          <stop offset="0.5" stopColor="#FF4500" />
          <stop offset="0.7" stopColor="#FC4301" />
          <stop offset="0.82" stopColor="#F43F07" />
          <stop offset="0.92" stopColor="#E53812" />
          <stop offset="1" stopColor="#D4301F" />
        </radialGradient>
        <radialGradient
          id="paint5_radial_7117_5745"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(20.0691 30.5448) scale(9.98104 6.58324)"
        >
          <stop stopColor="#172E35" />
          <stop offset="0.29" stopColor="#0E1C21" />
          <stop offset="0.73" stopColor="#030708" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint6_radial_7117_5745"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(28.871 0.394798) scale(8.76139)"
        >
          <stop stopColor="#FEFFFF" />
          <stop offset="0.4" stopColor="#FEFFFF" />
          <stop offset="0.51" stopColor="#F9FCFC" />
          <stop offset="0.62" stopColor="#EDF3F5" />
          <stop offset="0.7" stopColor="#DEE9EC" />
          <stop offset="0.72" stopColor="#D8E4E8" />
          <stop offset="0.76" stopColor="#CCD8DF" />
          <stop offset="0.8" stopColor="#C8D5DD" />
          <stop offset="0.83" stopColor="#CCD6DE" />
          <stop offset="0.85" stopColor="#D8DBE2" />
          <stop offset="0.88" stopColor="#EDE3E9" />
          <stop offset="0.9" stopColor="#FFEBEF" />
        </radialGradient>
        <radialGradient
          id="paint7_radial_7117_5745"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(25.22 9.94581) scale(7.18131)"
        >
          <stop offset="0.48" stopColor="#7A9299" />
          <stop offset="0.67" stopColor="#172E35" />
          <stop offset="0.75" />
          <stop offset="0.82" stopColor="#172E35" />
        </radialGradient>
      </defs>
    </IconBase>
  );
});
