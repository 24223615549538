import { useParams } from 'react-router-dom'
import {
  BackButton,
  MenuButton,
  Modal,
  ModalConfirm,
  ModalProps,
  PageHeader,
  ToastSeverity,
  useModal,
  useToast,
} from '@/ui'
import { useTranslation } from 'react-i18next'
import { usePluginInstanceQuery } from '@/layouts/app-connect/api/usePluginInstanceQuery'
import { usePluginQuery } from '@/layouts/app-connect/api'
import { Disable, DotsHorizontal, Launch, Trash } from '@/icons'
import { APP_CONNECT_PATH, useNavigateBack } from '@/libs'
import { PluginConfigForm } from '@/layouts/app-connect/components/PluginConfigForm'
import { FormProvider, useForm } from 'react-hook-form'
import CenteredProgress from '@/components/CenteredProgress'
import { Button } from '@dashboard/ui'
import i18next from 'i18next'
import { usePluginUninstallMutate } from '@/layouts/app-connect/api/usePluginUninstallMutate'
import { usePluginInstallMutate } from '@/layouts/app-connect/api/usePluginInstallMutate'
import { useEffect } from 'react'
import { dashboardClient, getErrorText } from '@/api'
import { PluginStatus } from '@/layouts/app-connect/components/PluginStatus'
import { PluginType } from '@/api/dashboard'
import { usePluginUpdateMutate } from '@/layouts/app-connect/api/usePluginUpdateMutate'

interface ErrorModalProps extends ModalProps {
  message: string
}

export default function PluginEditPage() {
  const { t } = useTranslation()
  const { companyId, gameId, pluginSlug } = useParams() as { companyId: string; gameId: string; pluginSlug: string }
  const showToast = useToast()
  const back = useNavigateBack({ fallback: APP_CONNECT_PATH })
  const { data: pluginInstance, isFetching: isInstancePluginLoading } = usePluginInstanceQuery(
    companyId,
    gameId,
    pluginSlug,
  )
  const { data: plugin, isFetching: isPluginLoading } = usePluginQuery(companyId, gameId, pluginSlug)
  const { mutateAsync: uninstallMutateAsync } = usePluginUninstallMutate(companyId, gameId, pluginSlug)
  const { mutateAsync: createMutateAsync } = usePluginInstallMutate(companyId, gameId, pluginSlug)
  const { mutateAsync: updaeteMutateAsync } = usePluginUpdateMutate(companyId, gameId, pluginSlug)

  const methods = useForm({})

  const resetForm = () => {
    methods.reset(
      Object.keys(methods.watch()).reduce((acc, key) => {
        // @ts-ignore
        acc[key] = ''
        return acc
      }, {}),
    )
  }

  useEffect(() => {
    methods.reset(pluginInstance?.plugin_config || {}, { keepDirty: false })
  }, [pluginInstance, methods.reset, plugin])

  const onFormSubmit = methods.handleSubmit(async data => {
    try {
      if (pluginInstance?.id) {
        await updaeteMutateAsync({
          update: {
            plugin_config: data,
          },
        })
      } else {
        await createMutateAsync({
          create: {
            plugin_config: data,
          },
        })
      }
      showToast({ message: t('plugin.install.toast'), severity: ToastSeverity.success })
    } catch (e) {
      openErrorInstall({ message: getErrorText(e) })
    }
  })

  const onDisableClick = async () => {
    await updaeteMutateAsync({
      update: {
        enabled: !pluginInstance?.enabled,
      },
    })
    showToast({
      message: t(pluginInstance?.enabled ? 'plugin.disable.toast' : 'plugin.enable.toast'),
      severity: ToastSeverity.success,
    })
  }

  const onLaunchApp = async () => {
    await dashboardClient.v1.runPlugin(companyId, gameId, pluginSlug)
    showToast({ message: t('plugin.app-run-done.toast'), severity: ToastSeverity.success })
  }

  const onConfirmUninstall = async () => {
    await uninstallMutateAsync()
    showToast({ message: t('plugin.uninstall.toast'), severity: ToastSeverity.success })
    resetForm()
  }

  const openConfirmUninstall = useModal(props => (
    <ModalConfirm
      subMessage={i18next.t('plugin.uninstall.confirm')}
      confirmButtonText={i18next.t('plugin.uninstall')}
      cancelButtonText={i18next.t('Cancel')}
      color="danger"
      onConfirm={onConfirmUninstall}
      {...props}
    />
  ))

  const openErrorInstall = useModal<ErrorModalProps>(props => (
    <Modal {...props}>
      <div>
        <div className="mb-3 text-[24px] font-semibold text-text-primary">{i18next.t('plugin.install.error')}</div>
        <div className="break-words text-text-secondary">{props.message}</div>
      </div>

      <div className="mt-6 flex justify-end gap-3">
        <Button size="sm" data-testid="confirm-cancel-button" onClick={props.onClose}>
          {i18next.t('close')}
        </Button>
      </div>
    </Modal>
  ))

  return (
    <div className="flex h-full flex-col gap-[18px]">
      {isPluginLoading || isInstancePluginLoading ? (
        <CenteredProgress />
      ) : (
        <FormProvider {...methods}>
          <PageHeader
            sticky={true}
            extra={
              <div className="flex gap-3">
                {pluginInstance ? (
                  <>
                    <Button
                      size="sm"
                      loading={methods.formState.isSubmitting}
                      disabled={!methods.formState.isDirty}
                      onClick={onFormSubmit}
                    >
                      {t('plugin.update')}
                    </Button>

                    <MenuButton
                      size="sm"
                      color="secondary"
                      variant="outline"
                      className="w-[32px]"
                      onChange={v => {
                        switch (v) {
                          case 'uninstall':
                            openConfirmUninstall()
                            break
                          case 'run':
                            onLaunchApp()
                            break
                          case 'enable':
                            onDisableClick()
                            break
                        }
                      }}
                      options={[
                        plugin?.type != PluginType.Analytics && {
                          value: 'run',
                          children: i18next.t('plugin.run'),
                          icon: <Launch />,
                        },
                        pluginInstance?.enabled
                          ? {
                              value: 'enable',
                              children: <span className="text-text-error-primary">{i18next.t('plugin.disable')}</span>,
                              icon: <Disable className="text-text-error-primary" />,
                            }
                          : {
                              value: 'enable',
                              children: i18next.t('plugin.enable'),
                              icon: <Disable />,
                            },
                        {
                          value: 'uninstall',
                          children: <span className="text-text-error-primary">{i18next.t('plugin.uninstall')}</span>,
                          icon: <Trash className="text-text-error-primary" />,
                        },
                      ].filter(it => !!it)}
                    >
                      <DotsHorizontal />
                    </MenuButton>
                  </>
                ) : (
                  <Button size="sm" loading={methods.formState.isSubmitting} onClick={onFormSubmit}>
                    {t('plugin.install')}
                  </Button>
                )}
              </div>
            }
          >
            <BackButton
              text={plugin?.name || ''}
              onClick={back.onClick}
              icon={
                <div
                  className="size-[40px] bg-cover bg-center bg-no-repeat"
                  style={{
                    backgroundImage: `url(${plugin?.image_url})`,
                  }}
                />
              }
              extraRight={
                <div className="font-inter">
                  <PluginStatus status={pluginInstance?.enabled} />
                </div>
              }
            />
          </PageHeader>
          {plugin && <PluginConfigForm config={plugin.plugin_config_schema!} pluginInstanceId={pluginInstance?.id} />}
        </FormProvider>
      )}
    </div>
  )
}
