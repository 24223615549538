import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { getDateCategoryParams } from '@/libs/dates'
import { CouponRead, DataGranularity, SendStatus } from '@/api/dashboard'
import ReactECharts from 'echarts-for-react'
import { formatNumber } from '@/util'
import AnimatedDots from '@/components/animated-dots/AnimatedDots'
import { couponStatQuery, couponTotalStatQuery } from '@/layouts/coupon/api/couponStatQuery'
import { TooltipAlertCircle } from '@/ui'
import { Spinner } from '@dashboard/ui'

export const CouponStatChart = (props: { coupon: CouponRead }) => {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const { t } = useTranslation()

  const { data: data = [], isLoading: isChartLoading } = useQuery(couponStatQuery(companyId, gameId, props.coupon.id))
  const { data: totalData, isLoading } = useQuery(couponTotalStatQuery(companyId, gameId, [props.coupon.id]))
  const maxValue = Math.max(Math.max(...data.map(it => it.value || 0)), 10)
  const options = {
    title: {
      show: false,
    },
    tooltip: {
      backgroundColor: '#000000CC',
      borderColor: 'transparent',
      trigger: 'axis',
      formatter: function (params: { axisValue: string; value: number; dataIndex: number }[]) {
        let point = data[params[0].dataIndex]

        let category = t('intl.DateTime', getDateCategoryParams(point?.category || '', DataGranularity.Day))

        let successStr = point
          ? `<div class="chart-tooltip--subtitle">
                      ${t('webhook.filters.success')}:<span>${formatNumber(point.value || 0)}</span>
                  </div>`
          : ''

        return `<div class="chart-tooltip" style="height: auto">
                  <div class="chart-tooltip--title">${category}</div>
                  <div class="chart-tooltip--separator"></div>
                  ${successStr}
              </div>`
      },
    },
    legend: {
      show: false,
    },
    toolbox: {
      show: false,
    },
    grid: {
      containLabel: true,
      bottom: 2,
      top: 8,
      right: 20,
    },
    xAxis: [
      {
        axisLabel: {
          fontSize: 10,
          color: '#64748B',
          fontWeight: '500',
          fontFamily: 'InterAg',
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        type: 'category',
        boundaryGap: false,
        data: data.map(it => t('intl.DateTime', getDateCategoryParams(it.category, DataGranularity.Day))),
      },
    ],
    yAxis: {
      min: 0,
      max: maxValue,
      splitLine: {
        show: false,
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: '#94A3B8',
        },
      },
      axisLabel: {
        formatter: function (value: number) {
          if (value === 0 || value === maxValue) {
            return value
          } else {
            return ''
          }
        },
      },
    },
    axisPointer: {
      lineStyle: {
        color: '#000',
        width: 1,
        type: 'solid',
      },
    },
    series: [
      {
        animation: false,
        name: SendStatus.Success,
        type: 'line',
        showSymbol: false,
        data: data,
        lineStyle: {
          color: '#0EA5E9',
        },
        smooth: 0.3,
      },
    ],
  }

  return (
    <div className="mb-4 flex h-[120px] items-center gap-1.5 rounded-xl border border-border-primary p-1.5">
      <div className="flex w-[140px] flex-col justify-start gap-1.5 p-3" data-testid="coupon-stat/total-value">
        <div className="flex items-center gap-0.5 whitespace-nowrap text-caption-sm text-text-quarterary-hover">
          {t('coupon.redeem-dynamics')}
          <TooltipAlertCircle message={t('coupon.update-info')} />
        </div>
        <div className="text-caption-md font-semibold text-text-secondary">
          {isLoading ? <AnimatedDots /> : totalData?.length ? formatNumber(totalData[0].value!) : 0}
        </div>
      </div>

      {isChartLoading ? (
        <div className="flex grow justify-center text-text-disabled">
          <Spinner size={16} />
        </div>
      ) : (
        <div data-testid="coupon-stat/chart" className="grow">
          <ReactECharts
            option={options}
            style={{
              height: '110px',
              width: '100%',
            }}
          />
        </div>
      )}
    </div>
  )
}
