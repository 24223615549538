import { cn } from '@dashboard/ui'
import { useCurrentGame } from '@/api/useCompaniesQuery'

export const useBanner = () => {
  const { game, company } = useCurrentGame()
  let bannerMode = ''

  if (company?.is_demo) {
    bannerMode = 'demo'
  } else if (game?.sandbox) {
    bannerMode = 'test'
  }

  const getStickyTop = () => {
    return cn(bannerMode ? 'top-[theme(height.banner)]' : 'top-0')
  }

  const getStickyHeight = () => {
    return bannerMode ? 'h-[calc(100vh-theme(height.banner))]' : 'h-screen'
  }

  return { bannerMode, getStickyTop, getStickyHeight }
}
