import { useMutation } from '@tanstack/react-query'

import { dashboardClient } from '@/api'

export const creatorsRequestPayoutMutate = (token: string) => {
  return useMutation({
    mutationFn: () =>
      dashboardClient.v1
        .creatorRequestPayout({
          headers: {
            'X-Creator-Token': token,
          },
        })
        .then(r => r.data),
  })
}
