import { Fragment, HTMLAttributes, ReactNode, createElement } from 'react';
import { NavLink } from 'react-router-dom';
import { cn } from '../../libs';
import { AghanimIcon, ChevronRight } from '../../icons';

export interface BreadcrumbCrumb {
  icon?: AghanimIcon;
  children: string;
  to: string;
}

export interface BreadcrumbProps extends HTMLAttributes<HTMLDivElement> {
  /**
   * An array of objects representing each breadcrumb item.
   */
  crumbs: BreadcrumbCrumb[];
  /**
   * Extra content to be displayed on the right side of the breadcrumbs.
   */
  extraRight?: ReactNode;
}

/**
 * A navigation component that helps users keep track of their locations within the application.
 * Breadcrumbs provide a hierarchical path showing the current page's location in relation to the site's structure.
 */
export const Breadcrumb = ({ crumbs, extraRight, ...rest }: BreadcrumbProps) => (
  <nav {...rest} className={cn('flex min-h-7 max-w-full items-center gap-1.5', rest.className)}>
    {crumbs.map((item, index) => (
      <Fragment key={`crumb-${item.to}`}>
        {index > 0 && <ChevronRight />}
        <NavLink
          to={item.to}
          className="flex max-w-xs items-center gap-1.5 text-text-secondary hover:text-text-secondary-hover"
        >
          {item.icon && createElement(item.icon, { size: 16 })}
          <span className="truncate text-caption-md leading-4">{item.children}</span>
        </NavLink>
      </Fragment>
    ))}
    {extraRight}
  </nav>
);
