import { ButtonHTMLAttributes, ReactElement, cloneElement, forwardRef, useMemo } from 'react'
import { VariantProps, cva } from 'class-variance-authority'
import { AghanimIconProps } from '@/icons'
import { cn } from '@/libs'
import { Spinner } from '../Spinner'

const buttonVariants = cva(
  [
    'inline-flex cursor-pointer items-center justify-center transition-colors disabled:pointer-events-none disabled:bg-fg-disabled disabled:text-text-disabled disabled:shadow-none',
    '[&[data-loading="true"]]:pointer-events-none',
  ],
  {
    variants: {
      variant: {
        /**
         * Brand
         */
        'primary-brand':
          'bg-fg-brand-primary text-text-inverse shadow-xs hover:bg-fg-brand-primary-hover active:bg-fg-brand-primary-pressed [&data-open="true"]:bg-fg-brand-primary-pressed',
        'primary-gray':
          'bg-fg-secondary-alt text-text-secondary shadow-xs hover:bg-fg-gray-secondary active:bg-fg-secondary-alt',
        'primary-destructive':
          'bg-fg-error-primary text-text-inverse shadow-xs hover:bg-fg-error-primary-hover active:bg-fg-error-solid',
        /**
         * Secondary
         */
        'secondary-brand':
          'border border-border-brand bg-fg-primary text-text-primary hover:border-transparent hover:bg-fg-brand-primary-hover hover:text-text-inverse active:border-transparent active:bg-fg-brand-primary-pressed active:text-text-inverse',
        'secondary-gray': [
          'border border-border-primary bg-fg-primary text-text-primary',
          'hover:bg-fg-primary-hover',
          '[&[data-open="true"]]:bg-fg-primary-hover',
          'active:bg-fg-primary-alt',
        ],
        'secondary-destructive':
          'border border-border-error-solid bg-fg-primary text-text-primary hover:border-transparent hover:bg-fg-error-solid hover:text-text-inverse active:border-transparent active:bg-fg-error-solid active:text-text-inverse',
        /**
         * Tertiary
         */
        'tertiary-brand':
          'border border-transparent bg-transparent text-text-brand-primary hover:border-border-brand active:border-border-brand active:bg-fg-brand-primary-pressed active:text-text-brand-solid',
        'tertiary-gray': [
          'border border-transparent bg-transparent text-text-tertiary',
          'hover:border-border-primary hover:text-text-tertiary-hover',
          '[&[data-open="true"]]:border-border-primary [&[data-open="true"]]:text-text-tertiary-hover',
          'active:border-border-primary active:bg-fg-secondary-hover active:text-text-tertiary-hover',
        ],
        'tertiary-destructive':
          'border border-transparent bg-transparent text-text-error-primary hover:border-border-error hover:text-text-error-hover active:border-border-error active:bg-fg-error-tertiary active:text-text-error-hover',
        /**
         * Overlay
         */
        overlay: 'text-text-inverse hover:bg-fg-overlay hover:backdrop-blur-md',
      },
      size: {
        xs: 'size-6 rounded-md',
        sm: 'size-[32px] rounded-md',
        'md-v2': 'size-[36px] rounded-md',
        md: 'size-[38px] rounded-md',
        lg: 'size-[46px] rounded-md',
      },
    },
    defaultVariants: {
      variant: 'primary-brand',
      size: 'md',
    },
  },
)

export interface ButtonProps
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'children'>,
    VariantProps<typeof buttonVariants> {
  children: ReactElement<AghanimIconProps>
  isLoading?: boolean
}

const iconSizeMap = new Map<VariantProps<typeof buttonVariants>['size'], number>([
  ['xs', 14],
  ['sm', 14],
  ['md', 18],
  ['lg', 22],
])

export const ButtonIcon = forwardRef<HTMLButtonElement, ButtonProps>(function ButtonIcon(
  { children, className, variant, type = 'button', size = 'md', isLoading, ...props },
  ref,
) {
  const iconSize = useMemo(() => iconSizeMap.get(size) || 18, [size])
  return (
    <button
      ref={ref}
      className={cn(buttonVariants({ variant, size }), className)}
      type={type}
      data-loading={isLoading}
      {...props}
    >
      {isLoading ? <Spinner size={iconSize} /> : cloneElement(children, { size: iconSize })}
    </button>
  )
})
