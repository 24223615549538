import { Box, Typography } from '@mui/material'
import { CSSProperties, useContext, useMemo } from 'react'
import { Theme, useTheme } from '@mui/material/styles'
import { Handle, HandleType, Position } from 'reactflow'
import { getNodeColor } from '../icons'
import { CampaignContext, ICampaignContext } from '../Context'
import { ConditionNode, GraphRoot, Node, ResourceState } from '../../../api/dashboard'
import { Attribute, CAMPAIGN_ATTRIBUTES } from '../expr/types'
import { AnyNode, EventActionNodeType } from '../types'
import { KeyValue } from '../../../types'
import { DESCRIPTIONS } from './descriptions'
import { AghanimTheme } from '../../../types/theme'
import { Tooltip } from '@/ui'
import i18next from 'i18next'
import { DescContext } from './descriptions/types'
import { useParams } from 'react-router-dom'
import { useSegmentsQuery } from '../../segment/api/useSegmentsQuery'
import { useStoresQuery } from '../../store/api'
import { DEFAULT_LOAD_LIMIT, useGameItemsQuery } from '../../../api/useGameItems'
import { findAttr } from '@/layouts/campaigns/expr/findAttr'
import { getHistoryTooltipMessage } from '@/layouts/campaigns/blocks/getHistoryTooltipMessage'

export const IOHandleSize = 2

export function getLineColor(theme: Theme) {
  return theme.palette.primary.light11
}

export function getWarningLineColor(theme: Theme | AghanimTheme) {
  return theme.palette.warning.light3
}

export function hasNextNode(graph: GraphRoot, node: Node): boolean {
  if (!graph.nodes) {
    return false
  }
  if (node.model_type == EventActionNodeType.ConditionNode) {
    let cn = node as ConditionNode
    return !!(cn.true_node_id && graph.nodes[cn.true_node_id]) || !!(cn.false_node_id && graph.nodes[cn.false_node_id])
  }

  return !!(node.next_node_id && graph.nodes[node.next_node_id])
}

export function isMoneyAttribute(attrName: string) {
  let attr = findAttr(attrName)

  if (!attr) {
    attr = Object.values(CAMPAIGN_ATTRIBUTES)
      .flat()
      .find(it => it.id == attrName) as Attribute
  }

  return !!attr?.isMoney
}

export function DescBlock(props: { node: Node; width?: string; selected?: boolean }) {
  const theme = useTheme()
  const context = useContext(CampaignContext) as ICampaignContext

  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const { data: stores = [] } = useStoresQuery(companyId, gameId, {
    limit: 100,
  })
  const { data: segments = [] } = useSegmentsQuery(companyId, gameId, {
    limit: 100,
  })

  const { data: items = [] } = useGameItemsQuery(companyId, gameId, {
    limit: DEFAULT_LOAD_LIMIT,
    state: ResourceState.Active,
  })

  let [title, subTitle] = useMemo(() => {
    const c = {
      items: items,
      segments: segments,
      stores: stores,
      graph: context.graph,
      type: context.campaign?.type,
    } as DescContext
    let descFunc = DESCRIPTIONS[props.node.model_type]

    if (descFunc) {
      return descFunc(props.node as AnyNode, c)
    }

    return [props.node.title || i18next.t(`campaign.node-type.${props.node.model_type}`), null]
  }, [props.node, context.graph, stores, segments, items])

  const color = getNodeColor(props.node.model_type, theme)

  const content = (
    <Box
      sx={{
        boxShadow: props.selected ? `1px 4px 22px 0px ${color}` : '',
        border: `2px solid ${color}`,
        backgroundColor: theme.palette.background.content,
        position: 'absolute',
        left: `calc(100% + ${theme.spacing(2)})`,
        padding: theme.spacing(1.75, 2),
        borderRadius: theme.spacing(0.75),
        whiteSpace: 'nowrap',
      }}
      minWidth={theme.spacing(8.5)}
      minHeight={theme.spacing(8.5)}
      maxHeight={theme.spacing(14)}
      width={props.width || 'auto'}
      alignItems="flex-start"
      flexDirection="column"
      justifyContent="center"
      display="flex"
    >
      <Typography
        sx={{
          width: '100%',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          width: '100%',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      >
        {subTitle}
      </Typography>
    </Box>
  )

  if (context.historyMode) {
    const message = getHistoryTooltipMessage(props.node)

    if (message) {
      return <Tooltip message={message}>{content}</Tooltip>
    }
  }

  return content
}

export function getIOStyle(theme: Theme) {
  return {
    borderRadius: '100%',
    border: `2px solid ${getLineColor(theme)}`,
    zIndex: -1,
    backgroundColor: theme.palette.background.content,
    height: theme.spacing(IOHandleSize),
    width: theme.spacing(IOHandleSize),
  }
}

export function IOHandle(props: {
  color: string
  id: string
  type: HandleType
  position: Position
  isConnectable?: boolean
  style?: CSSProperties
  hasNext?: boolean
}) {
  const theme = useTheme()
  const nextStyle = { zIndex: -1 } as KeyValue
  if (props.hasNext === false) {
    nextStyle['bottom'] = '-105px'
    nextStyle.zIndex = 0
  }
  return (
    <Handle
      type={props.type}
      id={props.id}
      position={props.position}
      isConnectable={props.isConnectable == undefined ? true : props.isConnectable}
      style={{
        ...getIOStyle(theme),
        ...nextStyle,
        opacity: props.color == 'transparent' ? 0 : 1,
        transform: props.position == Position.Bottom ? 'translate(-50%, 4px)' : 'translate(-50%, -4px)',
        ...props.style,
      }}
    />
  )
}

export function getNodeHoverStyle(color: string) {
  return {
    '&:hover > div': {
      boxShadow: `1px 4px 22px 0px ${color}`,
    },
  }
}

export function getSvgHoverStyle(color: string) {
  return {
    '&:hover > svg > path': {
      filter: `drop-shadow(1px 1px 2px ${color})`,
    },
  }
}
