import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { CreatorsQueryParams, creatorsQueryOptions } from '@/layouts/engagement/api'

export const useCreators = (params?: CreatorsQueryParams) => {
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const { data, isLoading, refetch } = useQuery(creatorsQueryOptions(companyId, gameId, params))
  return {
    data: {
      creators: data?.data || [],
      hasNext: data?.has_next || false,
    },
    isLoading,
    refetch,
  }
}
