import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, FormErrorMessage, Input, Select, formatHookFormErrors } from '@dashboard/ui'
import { AchievementRead, AchievementType } from '@/api/dashboard'
import { FieldGroup, ImageUploader, Modal, ModalFooter, ModalProps, ModalTitle } from '@/ui'
import { Controller, useForm } from 'react-hook-form'
import { ResponseError, getErrorText } from '@/api'
import { ErrorMessage } from '@hookform/error-message'
import { useAchievementUpdateMutate } from './api/useAchievementUpdateMutate'
import { useAchievementCreateMutate } from './api/useAchievementCreateMutate'

export interface EditAchievementDialogProps extends ModalProps {
  item: AchievementRead
}

export const EditAchievementDialog = ({ item, ...rest }: EditAchievementDialogProps) => {
  const { t } = useTranslation()
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const { mutateAsync: updateMutateAsync } = useAchievementUpdateMutate(companyId, gameId)
  const { mutateAsync: createMutateAsync } = useAchievementCreateMutate(companyId, gameId)

  const [helperText, setHelperText] = useState('')

  const {
    formState: { isSubmitting, errors },
    control,
    register,
    handleSubmit,
  } = useForm<AchievementRead>({
    defaultValues: item,
  })

  const onSubmit = handleSubmit(async values => {
    setHelperText('')
    try {
      if (item?.id) {
        await updateMutateAsync({
          id: values.id,
          update: values,
        })
        rest.onClose?.()
      } else {
        await createMutateAsync({
          create: values,
        })
      }
      rest.onClose?.()
    } catch (e) {
      setHelperText(getErrorText(e as ResponseError))
    }
  })

  return (
    <Modal {...rest}>
      <ModalTitle>
        {t(item.id ? 'edit-achievement-dialog.title.edit' : 'edit-achievement-dialog.title.create')}
      </ModalTitle>

      <form onSubmit={onSubmit}>
        <FieldGroup label={t('edit-achievement-dialog.name')}>
          <Input
            {...register('name', { required: t('validation.required') })}
            {...formatHookFormErrors(errors, 'name')}
            autoFocus={true}
          />
        </FieldGroup>

        <FieldGroup label={t('edit-achievement-dialog.description')}>
          <Input
            {...register('description', { required: t('validation.required') })}
            {...formatHookFormErrors(errors, 'description')}
          />
        </FieldGroup>

        <FieldGroup label={t('edit-achievement-dialog.type')}>
          <Controller
            control={control}
            name="type"
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                {...field}
                items={Object.values(AchievementType).map(item => ({
                  children: t(`achievement.type.${item}`),
                  value: item,
                }))}
              />
            )}
          />
        </FieldGroup>

        <FieldGroup>
          <Controller
            control={control}
            name="image_url"
            rules={{ required: t('validation.required') }}
            render={({ field }) => (
              <ImageUploader
                accept={{
                  'image/jpeg': ['.jpeg', '.jpg'],
                  'image/png': [],
                  'image/webp': [],
                }}
                {...field}
              />
            )}
          />
          <ErrorMessage
            name="image_url"
            errors={errors}
            render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
          />
        </FieldGroup>

        {helperText && (
          <FieldGroup>
            <FormErrorMessage>{helperText}</FormErrorMessage>
          </FieldGroup>
        )}

        <ModalFooter>
          <Button variant="outline" color="secondary" onClick={rest.onClose}>
            {t('Cancel')}
          </Button>
          <Button type="submit" loading={isSubmitting}>
            {t(item.id ? 'Save2' : 'Add')}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}
