import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '@/icons'

export const Store: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 34 34" ref={ref}>
      <path
        d="M0 12.1354C0 7.8876 0 5.76371 0.826674 4.14127C1.55384 2.71414 2.71414 1.55384 4.14127 0.826674C5.76371 0 7.8876 0 12.1354 0H21.8646C26.1124 0 28.2363 0 29.8587 0.826674C31.2859 1.55384 32.4462 2.71414 33.1733 4.14127C34 5.76371 34 7.8876 34 12.1354V21.8646C34 26.1124 34 28.2363 33.1733 29.8587C32.4462 31.2859 31.2859 32.4462 29.8587 33.1733C28.2363 34 26.1124 34 21.8646 34H12.1354C7.8876 34 5.76371 34 4.14127 33.1733C2.71414 32.4462 1.55384 31.2859 0.826674 29.8587C0 28.2363 0 26.1124 0 21.8646V12.1354Z"
        fill="url(#paint0_linear_7938_14246)"
      />
      <g clipPath="url(#clip0_7938_14246)">
        <g filter="url(#filter0_d_7938_14246)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.5 7.4375C8.5 6.8507 8.9757 6.375 9.5625 6.375H24.4375C25.0243 6.375 25.5 6.8507 25.5 7.4375C25.5 8.0243 25.0243 8.5 24.4375 8.5H9.5625C8.9757 8.5 8.5 8.0243 8.5 7.4375ZM7.28111 11.2423C7.49106 10.2625 8.35692 9.5625 9.35894 9.5625H24.6411C25.6431 9.5625 26.5089 10.2625 26.7189 11.2423L27.8573 16.5548C28.1407 17.8776 27.1323 19.125 25.7794 19.125H8.22055C6.86768 19.125 5.85925 17.8776 6.14272 16.5548L7.28111 11.2423ZM9.5625 21.25C9.5625 20.6632 9.0868 20.1875 8.5 20.1875C7.9132 20.1875 7.4375 20.6632 7.4375 21.25V24.4375C7.4375 26.1979 8.86459 27.625 10.625 27.625H23.375C25.1354 27.625 26.5625 26.1979 26.5625 24.4375V21.25C26.5625 20.6632 26.0868 20.1875 25.5 20.1875C24.9132 20.1875 24.4375 20.6632 24.4375 21.25V24.4375C24.4375 25.0243 23.9618 25.5 23.375 25.5H10.625C10.0382 25.5 9.5625 25.0243 9.5625 24.4375V21.25Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_7938_14246"
          x="4.09473"
          y="5.375"
          width="25.8105"
          height="25.25"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7938_14246" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7938_14246" result="shape" />
        </filter>
        <linearGradient id="paint0_linear_7938_14246" x1="17" y1="0" x2="17" y2="34" gradientUnits="userSpaceOnUse">
          <stop stopColor="#94A3B8" />
          <stop offset="1" stopColor="#64748B" />
        </linearGradient>
        <clipPath id="clip0_7938_14246">
          <rect width="25.5" height="25.5" fill="white" transform="translate(4.25 4.25)" />
        </clipPath>
      </defs>
    </IconBase>
  )
})
