import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, Modal, ModalFooter, ModalProps, ModalTitle } from '@/ui'

interface UnsavedChangesModalProps extends ModalProps {}

export const UnsavedChangesModal = ({ ...rest }: UnsavedChangesModalProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const onSubmit = () => {
    navigate(-1)
  }

  return (
    <Modal className="max-w-[600px]" {...rest}>
      <ModalTitle>{t('sku.unsaved.modal.title')}</ModalTitle>
      <div>
        <p className="text-paragraph-md">{t('sku.unsaved.modal.desc')}</p>
      </div>
      <ModalFooter>
        <Button type="button" size="lg" onClick={rest.onClose}>
          {t('sku.unsaved.modal.cancel')}
        </Button>
        <Button type="submit" variant="error" size="lg" onClick={onSubmit}>
          {t('sku.unsaved.modal.submit')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
