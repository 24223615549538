import { useCallback, useState } from 'react'

/**
 * @deprecated use useBulkSelectGeneric instead
 * @param initial
 */
export const useBulkSelect = (initial: string[] = []) => {
  const [selected, setSelected] = useState<Set<string>>(new Set(initial))

  const onSelect = useCallback(
    (item: string) => {
      if (!selected.has(item)) {
        setSelected(value => new Set(value).add(item))
      } else {
        setSelected(value => new Set([...value].filter(v => v !== item)))
      }
    },
    [selected, setSelected],
  )

  const onReset = useCallback(
    (items: string[]) => {
      setSelected(new Set(items))
    },
    [setSelected],
  )

  return { selected, onSelect, onReset }
}
