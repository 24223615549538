import { useParams } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { dashboardClient } from '@/api'
import { LoyaltySettingsUpdate } from '@/api/dashboard'

export const useLoyaltySettingsUpdate = (programId: string) => {
  const queryClient = useQueryClient()
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }

  return useMutation({
    mutationFn: (update: LoyaltySettingsUpdate) =>
      dashboardClient.v1.updateLoyaltySettings(companyId, gameId, programId, update).then(r => r.data),
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: ['loyalty-settings', companyId, gameId],
        exact: false,
        stale: false,
      })
    },
  })
}
