import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import {
  Button,
  Input,
  MOTION_ANIMATION_SPEED_MD,
  Select,
  Textarea,
  formatHookFormErrors,
  useModal,
} from '@dashboard/ui'
import { Currency, ItemCategoryRead, ItemRead, ItemType } from '@/api/dashboard'
import { FieldDivider, FieldGroup, ToastSeverity, useToast } from '@/ui'
import { itemCategoriesQuery } from '../../../item-category/api'
import { prepareSLUG } from '@/util'
import { FormSection } from '../../components'
import { SkuFormMediaSection } from './SkuFormMediaSection'
import { SkuFormPropertiesSection } from './SkuFormPropertiesSection'
import { SkuFormSidebar } from './SkuFormSidebar'
import { SkuFormTopbar } from './SkuFormTopbar'
import { SkuFormPriceSection } from './SkuFormPriceSection'
import i18next from 'i18next'
import { getErrorText } from '@/api'
import { useEffect } from 'react'
import { SkuFormBundleForm } from '@/layouts/game-items/widgets/SkuForm/SkuFormBundleItems'
import EditItemCategoryDialog from '@/layouts/item-category/EditItemCategoryDialog'
import { SkuFormItemProperties } from '@/layouts/game-items/widgets/SkuForm/SkuFormItemProperties'
import { useQuery } from '@tanstack/react-query'

export interface SkuFormData extends ItemRead {
  nested_items_read?: ItemRead[]
}

interface SkuFormProps {
  item?: ItemRead
  type: ItemType
  onSubmit: (data: SkuFormData) => Promise<SkuFormData>
}

export const SkuForm = ({ item, onSubmit, ...rest }: SkuFormProps) => {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const { t } = useTranslation()
  const methods = useForm<SkuFormData>({
    defaultValues: {
      name: '',
      description: '',
      currency: Currency.USD,
      price: 0,
      sku: '',
      image_url: '',
      type: rest.type,
      is_currency: false,
      is_stackable: false,
      quantity: 1,
      ...item,
    },
  })
  const showToast = useToast()
  const { data: categories = [] } = useQuery({
    ...itemCategoriesQuery(companyId, gameId, { limit: 100 }),
    refetchOnMount: false,
  })
  const id = methods.watch('id')
  const type = methods.watch('type')

  useEffect(() => {
    const firstError = Object.keys(methods.formState.errors)[0]

    if (firstError) {
      methods.setFocus(firstError as 'image_url')
    }
  }, [methods.formState.errors, methods.setFocus])

  const openCreateCategoryModal = useModal<{ item: ItemCategoryRead }>(props => (
    <EditItemCategoryDialog
      {...props}
      created={newCat => {
        methods.setValue('categories', [newCat.id], { shouldDirty: true })
      }}
    />
  ))

  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    control,
    setError,
  } = methods

  const _onSubmit = handleSubmit(async data => {
    try {
      const paylod = {
        ...data,
      }

      if (paylod.currency != Currency.RP) {
        paylod.reward_points_price = null as unknown as number
      }

      if (paylod.properties) {
        if (paylod.properties.find(it => it.data?.value == null || it.data?.value == undefined)) {
          setError('properties', { message: t('validation.empty-values') })
          return
        }
      }

      if (paylod.nested_items?.length) {
        if (paylod.nested_items.find(it => !it.count || it.count <= 0)) {
          setError('nested_items', { message: t('validation.min-no-field', { value: 1 }) })
          return
        }
        if (paylod.nested_items.find(it => it.count && it.count > 1_000_000)) {
          setError('nested_items', {
            message: t('validation.max', { value: 1_000_000, field: t('game-item-edit-dialog.quantity') }),
          })
          return
        }
        delete paylod.nested_items_read
      }

      await onSubmit(paylod)
      reset(data)
    } catch (e) {
      if ((e as { status: number }).status == 409) {
        setError('sku', { message: i18next.t('sku.item.sku.error', { value: data.sku }) })
      } else {
        showToast({ message: getErrorText(e), severity: ToastSeverity.error })
      }
    }
  })

  return (
    <FormProvider {...methods}>
      <form className="mb-6" onSubmit={_onSubmit}>
        <SkuFormTopbar />
        <div className="container grid grid-cols-[auto_380px] gap-10">
          <div className="max-w-[900px]">
            <FormSection>
              <FieldGroup label={t('game-item-edit-dialog.name')}>
                <Input
                  {...register('name', {
                    required: t('validation.required'),
                    maxLength: {
                      message: t('validation.maxLength', {
                        field: t('game-item-edit-dialog.name'),
                        value: 255,
                      }),
                      value: 255,
                    },
                  })}
                  {...formatHookFormErrors(errors, 'name')}
                  placeholder={t('sku.item.name.placeholder')}
                  autoFocus={true}
                />
              </FieldGroup>

              <FieldGroup label={t('game-item-edit-dialog.description')}>
                <Textarea
                  {...register('description', {
                    maxLength: {
                      message: t('validation.maxLength', {
                        field: t('game-item-edit-dialog.description'),
                        value: 255,
                      }),
                      value: 255,
                    },
                  })}
                  {...formatHookFormErrors(errors, 'description')}
                  placeholder={t('sku.item.description.placeholder')}
                  rows={6}
                />
              </FieldGroup>

              <FieldDivider />

              <div className="flex w-full gap-[18px]">
                <FieldGroup label={t('sku.item.category')} className="w-1/2">
                  <Controller
                    control={control}
                    name="categories"
                    render={({ field }) => (
                      <Select
                        items={[
                          {
                            children: t('sku.item.no-category'),
                            value: '',
                          },
                          ...categories.map(item => ({
                            children: item.name,
                            value: item.id,
                          })),
                        ]}
                        value={field.value ? field.value[0] : ''}
                        onChange={v => {
                          field.onChange(v ? [v] : null)
                        }}
                        placeholder={t('sku.item.select-category')}
                        renderMenuFooter={state => (
                          <Button
                            className="w-full"
                            variant="outline"
                            size="sm"
                            onClick={() => {
                              state.setIsOpen(false)
                              setTimeout(() => {
                                openCreateCategoryModal({
                                  item: { name: '', description: '', position: categories.length } as ItemCategoryRead,
                                })
                              }, MOTION_ANIMATION_SPEED_MD * 500)
                            }}
                          >
                            {t('item-category.add')}
                          </Button>
                        )}
                      />
                    )}
                  />
                </FieldGroup>

                <FieldGroup label={t('sku.item.sku')} tooltip={t('sku.sku-desc')} className="w-1/2">
                  <Controller
                    control={control}
                    name="sku"
                    rules={{
                      required: t('validation.required'),
                      maxLength: {
                        message: t('validation.maxLength', {
                          field: t('sku.item.sku'),
                          value: 100,
                        }),
                        value: 100,
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        {...formatHookFormErrors(errors, 'sku')}
                        onChange={e => field.onChange(prepareSLUG(e.target.value))}
                      />
                    )}
                  />
                </FieldGroup>
              </div>
            </FormSection>

            <SkuFormPriceSection />

            {type == ItemType.Bundle ? <SkuFormBundleForm /> : <SkuFormPropertiesSection />}
            {id && <SkuFormItemProperties />}
            <SkuFormMediaSection type={type} />
          </div>

          <SkuFormSidebar />
        </div>
      </form>
    </FormProvider>
  )
}
