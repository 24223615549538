import { useParams } from 'react-router-dom'

import { loyaltySettingsQueryOptions } from '@/layouts/engagement/api'
import { LoyaltySettings } from '@/api/dashboard'
import { useQuery } from '@tanstack/react-query'

export const useLoyaltySettings = () => {
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const { isLoading, data: settings = {} } = useQuery(loyaltySettingsQueryOptions(companyId, gameId))
  return { isLoading, settings: settings as LoyaltySettings }
}
