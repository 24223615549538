import { useParams } from 'react-router-dom'
import { Skeleton } from '@mui/material'
import { ChevronSelectorVertical2 } from '@/icons'
import { Floating } from '../Floating'
import { SidebarLink } from './SidebarLink'
import { useCanGuard, useGenerateCompanyPath } from '../../../libs/hooks'
import { canManageUsers } from '../../../security'
import { cn } from '../../../libs/cn'

import { useContext } from 'react'
import { AppMenuItem, getCompanyItems } from './MenuData'
import { AuthContext, IAuthContext } from '../../../Context'
import i18next from 'i18next'
import { useCompaniesQuery } from '../../../api/useCompaniesQuery'
import { ImageCompany } from '@/components/ui/AppSidebar/ImageCompany'
import { useTranslation } from 'react-i18next'

interface CompanySwitchMenuProps {
  onClose?: () => void
  items: AppMenuItem[]
}

const CompanySwitchMenu = ({ onClose = () => void 0, items }: CompanySwitchMenuProps) => {
  const onClickLink = () => {
    onClose()
  }

  return (
    <div className="w-[233px] rounded-xl border border-border-primary bg-fg-primary py-2 shadow-lg">
      {items.map(item => (
        <SidebarLink to={item.to || ''} key={item.to} icon={item.icon!} onMouseUp={onClickLink}>
          {item.text}
        </SidebarLink>
      ))}
    </div>
  )
}

export const SidebarCompanySwitch = () => {
  const { companyId } = useParams() as { companyId: string; gameId: string }
  const { t } = useTranslation()
  const { can } = useCanGuard()
  const { data: dataCompanies } = useCompaniesQuery()
  const company = dataCompanies?.data?.find(it => it.id === companyId)
  const { generatePath } = useGenerateCompanyPath()
  const context = useContext(AuthContext) as IAuthContext
  const canManage = can(canManageUsers)

  const items = getCompanyItems(context.customer, generatePath, t)

  const content = (
    <div
      className={cn(
        "group mx-3 flex cursor-pointer items-center gap-2 rounded-md p-1 transition-all hover:shadow-sm [&:not([data-open='true'])]:hover:bg-bg-primary [&[data-open='true']]:shadow-sm [&[data-open='true']]:ring-1",
        !canManage && 'pointer-events-none',
      )}
    >
      <ImageCompany company={company} />
      <div className="overflow-hidden">
        <div className="text-xs font-medium leading-none text-text-tertiary transition-colors group-hover:text-text-primary group-[&[data-open='true']]:text-text-primary">
          {items.length ? i18next.t('sidebar.company-settings') : i18next.t('sidebar.your-company')}
        </div>
        <div className="mt-0.5 truncate text-sm font-semibold text-text-secondary">
          {company ? company.name : <Skeleton width="100%" />}
        </div>
      </div>
      {canManage && (
        <ChevronSelectorVertical2
          className="ml-auto mr-1 shrink-0 text-fg-tertiary  transition-colors group-hover:text-fg-tertiary-alt"
          size={14}
        />
      )}
    </div>
  )

  return !items?.length ? (
    content
  ) : (
    <Floating menu={<CompanySwitchMenu items={items} />} placement="top-start" referenceWidth={true}>
      {content}
    </Floating>
  )
}
