import './index.css'
import { ConditionNode, GraphRoot, Node, SplitNode } from '@/api/dashboard'
// eslint-disable-next-line import/no-named-as-default
import ReactFlow, {
  Background,
  ControlButton,
  Controls,
  Edge,
  MarkerType,
  NodeChange,
  NodeSelectionChange,
  ReactFlowInstance,
  Viewport,
  useEdgesState,
  useKeyPress,
  useNodesState,
  useOnViewportChange,
} from 'reactflow'
import {
  type MouseEvent as ReactMouseEvent,
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { DefaultEdge, LeftEdge, RightEdge } from './blocks/Edges'
import StartBlock, { START_BLOCK_WIDTH } from './blocks/StartBlock'
import SplitBlock from './blocks/SplitBlock'
import TimerNode from './blocks/TimerNode'
import { useTheme } from '@mui/material/styles'
import EmailNode from './blocks/EmailNode'
import BrowserPopupNode from './blocks/BrowserPopupNode'
import MobilePopupNode from './blocks/MobilePopupNode'
import WebhookNode from './blocks/WebhookNode'
import { getLineColor } from './blocks/common'
import ItemDiscountNode from './blocks/ItemDiscountNode'
import { EventActionNodeType } from './types'
import CreateCouponNode from './blocks/CreateCouponNode'
import ConditionBlock, { CONDITION_NODE_WIDTH } from './blocks/ConditionBlock'
import { CampaignContext, ICampaignContext } from './Context'
import { toPng } from 'html-to-image'
import { ImageIcon } from '../../components/icons/ImageIcon'
import { IconLoader } from '@/icons'
import { SelectActionNode } from './components/SelectActionNode/SelectActionNode'
import { generateUid } from '../../util'
import createNode from './createNode'
import { CONDITION_NODE_SWITCH_SIZE, DEFAULT_HEIGHT_BEETWEEN_NODES, hasMoreOneChildren } from './Settings'
import StoreUserNode from '@/layouts/campaigns/blocks/StoreUserNode'

export const EDGE_ID_SEPARATOR = '___'

const EDGE_TYPES = {
  'right-edge': RightEdge,
  'left-edge': LeftEdge,
  'default-edge': DefaultEdge,
}

export const NODE_TYPES = {
  [EventActionNodeType.GraphRoot]: StartBlock,
  [EventActionNodeType.EmailActionNode]: EmailNode,
  [EventActionNodeType.BrowserPopupNotificationActionNode]: BrowserPopupNode,
  [EventActionNodeType.TimerNode]: TimerNode,
  [EventActionNodeType.MobilePopupActionNode]: MobilePopupNode,
  [EventActionNodeType.BrowserPushNotificationActionNode]: BrowserPopupNode,
  [EventActionNodeType.MobilePushActionNode]: MobilePopupNode,
  [EventActionNodeType.WebhookActionNode]: WebhookNode,

  [EventActionNodeType.ConditionNode]: ConditionBlock,
  [EventActionNodeType.SplitNode]: SplitBlock,
  [EventActionNodeType.ItemOfferActionNode]: ItemDiscountNode,
  [EventActionNodeType.ItemDiscountOfferActionNode]: ItemDiscountNode,
  [EventActionNodeType.ItemBonusOfferActionNode]: ItemDiscountNode,
  [EventActionNodeType.CreateItemBannerActionNode]: ItemDiscountNode,
  [EventActionNodeType.AddToSegmentNode]: ItemDiscountNode,
  [EventActionNodeType.CreateCouponNode]: CreateCouponNode,
  [EventActionNodeType.CreateVirtualSKUNode]: ItemDiscountNode,
  [EventActionNodeType.CreateUserStoreSettingsNode]: StoreUserNode,
}

interface NewBlockState {
  sourceNodeId: string
  isTrueClick: boolean
}

export type FlowApi = {
  removeBlock: (id: string) => void
  removeEdge: (id: string) => void
}

interface FlowProps {
  isReadOnly: boolean
  graph: GraphRoot
  onViewPortChanged: (viewport: Viewport) => void
  viewPort: Viewport | undefined
  setNode: (node: Node) => void
  setGraphAndDirty: (value: GraphRoot) => void
  setGraph: (value: GraphRoot) => void
  onBlockSelected?: (nodeId: string) => void
  onNodeMoved?: () => void
  onEdgeClick?: (edgeId: string) => void
  selectedBlockId: string | undefined
  selectedEdgeId: string | undefined
  setSelectedBlockId: (v: string) => void
  setSelectedEdgeId: (v: string) => void
  actions: EventActionNodeType[]
}

export const Flow = forwardRef<FlowApi, FlowProps>(function FlowComponent(
  {
    isReadOnly,
    graph,
    onViewPortChanged,
    viewPort,
    setNode,
    setGraphAndDirty,
    setGraph,
    onBlockSelected,
    onNodeMoved,
    onEdgeClick,
    selectedBlockId,
    selectedEdgeId,
    setSelectedBlockId,
    setSelectedEdgeId,
    actions,
  },
  ref,
) {
  const context = useContext(CampaignContext) as ICampaignContext
  const theme = useTheme()
  const [anchorPlusIcon, setAnchorPlusIcon] = useState<HTMLElement | null>(null)
  const [instance, setReactFlowInstance] = useState<ReactFlowInstance | null>(null)
  const [nodes, setNodes, onNodesChange] = useNodesState([])
  const [edges, setEdges, onEdgesChange] = useEdgesState([])
  const [isExporting, setIsExporting] = useState(false)
  const [movingNode, setMovingNode] = useState<{
    id: string
    x: number
    y: number
  } | null>(null)

  const deletePressed = useKeyPress('Delete')
  const [addBlockState, setAddBlockState] = useState<NewBlockState | null>(null)

  useImperativeHandle(ref, () => ({
    removeBlock: (id: string) => removeBlock(id),
    removeEdge: (id: string) => removeEdge(id),
  }))

  useOnViewportChange({
    onEnd: (viewport: Viewport) => onViewPortChanged(viewport),
  })
  const flowRef = useRef(null)

  const createEdge = (
    type: string,
    sourceHandle: string | null,
    sourceNode: Node | GraphRoot,
    targetId: string,
    addClick: (anchorEl: HTMLElement, isTrue: boolean) => void,
  ) => {
    let id = `${sourceNode.id}${EDGE_ID_SEPARATOR}${targetId}`
    let edge = {
      id: id,
      source: sourceNode.id,
      type: type,
      target: targetId,
      sourceHandle: sourceHandle,
      label: 'no',
      markerEnd: {
        type: MarkerType.Arrow,
        width: 15,
        color: getLineColor(theme),
        height: 15,
      },
      data: {
        sourceNode: sourceNode,
        addClick: addClick,
        selected: id == selectedEdgeId,
        style: {
          strokeWidth: undefined as unknown as number,
          strokeOpacity: undefined as unknown as number,
        },
      },
      animated: false,
    }

    if (context.historyMode) {
      if (sourceNode.model_type == EventActionNodeType.GraphRoot) {
        edge.animated = true
      }

      switch (sourceNode.model_type) {
        case EventActionNodeType.ConditionNode:
          if (sourceNode.result == 'True' && type == 'right-edge') {
            edge.animated = true
          }
          if (sourceNode.result == 'False' && type == 'left-edge') {
            edge.animated = true
          }
          break
        case EventActionNodeType.SplitNode:
          let n = sourceNode as SplitNode
          if (n.random_value != null) {
            if (n.random_value > n.distribution[0].value && type == 'right-edge') {
              edge.animated = true
            }
            if (n.random_value < n.distribution[0].value && type == 'left-edge') {
              edge.animated = true
            }
          }
          break
        default:
          if (sourceNode.result) {
            edge.animated = true
          }
          break
      }
      if (edge.animated) {
        edge.data.style.strokeWidth = 3
      }
    }

    return edge
  }

  useEffect(() => {
    const allNodes = [graph, ...Object.values(graph.nodes || {})]

    const initialNodes = allNodes.map(n => ({
      id: n.id,
      position: { x: n.x, y: n.y },
      type: n.model_type,
      selected: n.id == selectedBlockId,
      data: {
        node: n,
        setNode: setNode,
        graph: graph,
        addClick: (anchorEl: HTMLElement, isTrue: boolean) => addNodeClick(n.id, isTrue, anchorEl),
        removeClick: () => removeBlock(n.id),
      },
    }))
    setNodes(initialNodes)
  }, [graph, selectedBlockId])

  useEffect(() => {
    nodes.forEach(n => {
      let node = graph.nodes ? graph.nodes[n.id] : null
      if (node) {
        node.x = n.position.x
        node.y = n.position.y
      } else if (n.id == graph.id) {
        graph.x = n.position.x
        graph.y = n.position.y
      }
    })

    const allNodes = [graph, ...Object.values(graph.nodes || {})]
    const nodesSet = new Map<string, Edge>()
    for (let node of allNodes) {
      switch (node.model_type) {
        case EventActionNodeType.ConditionNode:
          let cNode = node as ConditionNode
          if (cNode.true_node_id) {
            let edge = createEdge(
              'right-edge',
              'output',
              node,
              cNode.true_node_id,
              (anchorEl: HTMLElement, isTrue: boolean) => addNodeClick(node.id, isTrue, anchorEl),
            )
            nodesSet.set(edge.id, edge)
          }

          if (cNode.false_node_id) {
            let edge = createEdge(
              'left-edge',
              'output',
              node,
              cNode.false_node_id,
              (anchorEl: HTMLElement, isTrue: boolean) => addNodeClick(node.id, isTrue, anchorEl),
            )
            nodesSet.set(edge.id, edge)
          }
          break
        case EventActionNodeType.SplitNode:
          let sNode = node as SplitNode

          if (sNode.distribution) {
            if (sNode.distribution[0].next_node_id) {
              let edge = createEdge(
                'left-edge',
                'output',
                node,
                sNode.distribution[0].next_node_id,
                (anchorEl: HTMLElement, isTrue: boolean) => addNodeClick(node.id, isTrue, anchorEl),
              )
              nodesSet.set(edge.id, edge)
            }

            if (sNode.distribution[1].next_node_id) {
              let edge = createEdge(
                'right-edge',
                'output',
                node,
                sNode.distribution[1].next_node_id,
                (anchorEl: HTMLElement, isTrue: boolean) => addNodeClick(node.id, isTrue, anchorEl),
              )
              nodesSet.set(edge.id, edge)
            }
          }

          break
        default:
          if (node.next_node_id) {
            let edge = createEdge(
              'default-edge',
              null,
              node,
              node.next_node_id,
              (anchorEl: HTMLElement, isTrue: boolean) => addNodeClick(node.id, isTrue, anchorEl),
            )
            nodesSet.set(edge.id, edge)
          }
          break
      }
    }

    setEdges(Array.from(nodesSet.values()))
  }, [nodes, selectedEdgeId])

  useEffect(() => {
    if (deletePressed) {
      if (selectedBlockId) {
        removeBlock(selectedBlockId)
      } else if (selectedEdgeId) {
        removeEdge(selectedEdgeId)
      }
    }
  }, [deletePressed])

  const onExportClick = () => {
    if (flowRef.current === null || isExporting) {
      return
    }

    setIsExporting(true)

    setTimeout(() => {
      instance?.fitView({
        maxZoom: 0.5,
      })

      if (!flowRef.current) {
        return
      }
      toPng(flowRef.current, {
        backgroundColor: '#eee',
        pixelRatio: 8,
        quality: 1.0,
        filter: node =>
          !(node?.classList?.contains('react-flow__minimap') || node?.classList?.contains('react-flow__controls')),
      }).then(dataUrl => {
        const a = document.createElement('a')
        a.setAttribute('download', context.campaign.type.trim() + '.png')
        a.setAttribute('href', dataUrl)
        a.click()
        setIsExporting(false)
      })
    }, 300)
  }

  const onNodesLinked = (sourceId: string, targetId: string, sourceHandle: string) => {
    if (isReadOnly || targetId == graph.id || !graph.nodes) {
      return
    }

    let sourceNode = (graph.id == sourceId ? graph : graph.nodes[sourceId]) as Node
    if (!sourceNode) {
      console.log('sourceNode not found')
      return
    }

    switch (sourceNode.model_type) {
      case EventActionNodeType.ConditionNode:
        if (sourceHandle == 'output-true') {
          if (sourceNode.false_node_id != targetId) {
            sourceNode.true_node_id = targetId
          } else {
            return
          }
        } else if (sourceHandle == 'output-false') {
          if (sourceNode.true_node_id != targetId) {
            sourceNode.false_node_id = targetId
          } else {
            return
          }
        }
        break
      case EventActionNodeType.SplitNode:
        if (sourceNode.distribution) {
          if (sourceHandle == 'output-left') {
            if (sourceNode.distribution[1].next_node_id != targetId) {
              sourceNode.distribution[0].next_node_id = targetId
            } else {
              return
            }
          } else {
            if (sourceNode.distribution[0].next_node_id != targetId) {
              sourceNode.distribution[1].next_node_id = targetId
            } else {
              return
            }
          }
        }
        break
      default:
        sourceNode.next_node_id = targetId
        break
    }

    setGraphAndDirty(graph)
  }

  const addNodeClick = (sourceBlockId: string, isTrueNext: boolean, anchorEl: HTMLElement) => {
    if (isReadOnly || context.historyMode) {
      return
    }
    setAnchorPlusIcon(anchorEl)
    setAddBlockState({
      sourceNodeId: sourceBlockId,
      isTrueClick: isTrueNext,
    })
  }

  const addBlock = (type: EventActionNodeType, newBlockState: NewBlockState) => {
    setSelectedBlockId('')
    if (!graph.nodes) {
      return
    }

    let idNode = generateUid('nde')

    let sourceNode = graph.nodes[newBlockState.sourceNodeId] as Node
    if (!sourceNode) {
      sourceNode = graph as Node
    }

    let nextId = undefined

    let x = sourceNode.x || 0

    switch (sourceNode.model_type) {
      case EventActionNodeType.ConditionNode:
        if (newBlockState.isTrueClick) {
          nextId = sourceNode.true_node_id
          sourceNode.true_node_id = idNode
        } else {
          nextId = sourceNode.false_node_id
          sourceNode.false_node_id = idNode
        }
        break
      case EventActionNodeType.SplitNode:
        if (sourceNode.distribution) {
          if (newBlockState.isTrueClick) {
            nextId = sourceNode.distribution[1].next_node_id
            sourceNode.distribution[1].next_node_id = idNode
          } else {
            nextId = sourceNode.distribution[0].next_node_id
            sourceNode.distribution[0].next_node_id = idNode
          }
        }
        break
      default:
        if (sourceNode.next_node_id) {
          nextId = sourceNode.next_node_id
        }
        sourceNode.next_node_id = idNode
        break
    }

    if (nextId && !graph.nodes[nextId]) {
      nextId = undefined
    }

    if (sourceNode.model_type.toString() == EventActionNodeType.GraphRoot) {
      x = x + START_BLOCK_WIDTH / 2 - 34
    }

    let htmlNode = document.querySelector(`.react-flow [data-id="${sourceNode.id}"]`) as HTMLDivElement
    let htmlNodeHeight = htmlNode ? htmlNode.offsetHeight : 200

    graph.nodes[idNode] = createNode(
      idNode,
      type,
      x,
      sourceNode.y + htmlNodeHeight + DEFAULT_HEIGHT_BEETWEEN_NODES,
      nextId,
      graph,
      sourceNode,
    )

    if (hasMoreOneChildren(sourceNode?.model_type as EventActionNodeType)) {
      let cNode = graph.nodes[idNode]
      if (nextId) {
        cNode.x = graph.nodes[nextId].x
      } else {
        cNode.x += newBlockState.isTrueClick ? CONDITION_NODE_WIDTH + CONDITION_NODE_SWITCH_SIZE : -CONDITION_NODE_WIDTH
      }
    }

    if (nextId) {
      let offsetX = hasMoreOneChildren(type) ? 184 : 0
      modeChild(idNode, nextId, offsetX)
    }

    setGraphAndDirty(graph)

    setAddBlockState(null)
  }

  const modeChild = (newNodeId: string, nextNodeId: string, xOffset = 0) => {
    setTimeout(() => {
      let htmlNode = document.querySelector(`.react-flow [data-id="${newNodeId}"]`) as HTMLDivElement
      let htmlNodeHeight = htmlNode ? htmlNode.offsetHeight : 200
      doMoveBottom(nextNodeId, htmlNodeHeight + DEFAULT_HEIGHT_BEETWEEN_NODES, xOffset, [])
      setGraph(graph)
    }, 300)
  }

  const doMoveBottom = (id: string, yOffset: number, xOffset: number, movedIds: string[]) => {
    if (!graph.nodes) {
      return
    }

    if (movedIds.includes(id)) {
      return // avoid infinite loop
    }

    movedIds.push(id)

    let n = graph.nodes[id]
    if (n) {
      n.y += yOffset
      n.x += xOffset
      if (n.next_node_id) {
        doMoveBottom(n.next_node_id, yOffset, xOffset, movedIds)
      } else if (n.model_type == EventActionNodeType.ConditionNode) {
        let c = n as ConditionNode
        if (c.false_node_id) {
          doMoveBottom(c.false_node_id, yOffset, xOffset, movedIds)
        }
        if (c.true_node_id) {
          doMoveBottom(c.true_node_id, yOffset, xOffset, movedIds)
        }
      }
    }
  }

  const removeBlock = (removingNodeId: string) => {
    if (!graph.nodes || isReadOnly) {
      return
    }
    let removingNode = graph.nodes[removingNodeId]

    for (let id in graph.nodes) {
      let node = graph.nodes[id] as Node

      switch (node.model_type) {
        case EventActionNodeType.ConditionNode:
          if (node.true_node_id == removingNodeId) {
            node.true_node_id = removingNode.next_node_id || undefined
          } else if (node.false_node_id == removingNodeId) {
            node.false_node_id = removingNode.next_node_id || undefined
          }
          break
        case EventActionNodeType.SplitNode:
          node.distribution?.forEach(it => {
            if (it.next_node_id == removingNodeId) {
              it.next_node_id = undefined
            }
          })
          break
        default:
          if (node.next_node_id == removingNodeId) {
            node.next_node_id = removingNode.next_node_id || undefined
          }
          break
      }
    }

    if (graph.next_node_id == removingNodeId) {
      graph.next_node_id = removingNode.next_node_id || undefined
    }

    delete graph.nodes[removingNodeId]

    setGraphAndDirty({
      ...graph,
    })

    setSelectedBlockId('')
  }

  const removeEdge = (id: string) => {
    if (!graph.nodes || isReadOnly || !id) {
      return
    }

    let arr = id.split(EDGE_ID_SEPARATOR)
    let sourceNode = (graph.id == arr[0] ? graph : graph.nodes[arr[0]]) as Node

    let targetId = arr[1]
    if (!sourceNode) {
      console.log('sourceNode not found')
      return
    }

    switch (sourceNode.model_type) {
      case EventActionNodeType.ConditionNode:
        if (targetId == sourceNode.true_node_id) {
          sourceNode.true_node_id = undefined
        } else {
          sourceNode.false_node_id = undefined
        }

        break
      case EventActionNodeType.SplitNode:
        if (sourceNode.distribution) {
          if (targetId == sourceNode.distribution[1].next_node_id) {
            sourceNode.distribution[1].next_node_id = undefined
          } else {
            sourceNode.distribution[0].next_node_id = undefined
          }
        }
        break
      default:
        sourceNode.next_node_id = undefined
        break
    }

    setGraphAndDirty(graph)

    setSelectedEdgeId('')
  }

  const renderSelectBlockPopover = () => {
    if (!addBlockState || isReadOnly) {
      return null
    }

    return (
      anchorPlusIcon && (
        <SelectActionNode
          actions={actions}
          anchorEl={anchorPlusIcon}
          onSelect={type => {
            addBlock(type, addBlockState)

            setAddBlockState(null)
            setAnchorPlusIcon(null)
          }}
          onClose={() => {
            setSelectedBlockId('')
            setAnchorPlusIcon(null)
            setAddBlockState(null)
          }}
        />
      )
    )
  }

  return (
    <>
      {renderSelectBlockPopover()}

      <ReactFlow
        ref={flowRef}
        nodesDraggable={!isReadOnly && !context.historyMode}
        onInit={setReactFlowInstance}
        panOnScroll
        zoomOnPinch
        zoomOnScroll
        minZoom={0.01}
        panOnScrollMode={'free' as never}
        nodes={nodes}
        edges={edges}
        snapToGrid
        snapGrid={[20, 20]}
        onNodeDragStop={() => {
          if (!movingNode) {
            return
          }

          if (onBlockSelected && selectedBlockId) {
            onBlockSelected(movingNode.id)
          }

          let node = graph.nodes ? graph.nodes[movingNode.id] : null
          if (onNodeMoved && node) {
            if (movingNode.x != node.x || movingNode.y != node.y) {
              onNodeMoved()
            }
          }

          setMovingNode(null)
        }}
        defaultViewport={viewPort}
        nodeTypes={NODE_TYPES}
        edgeTypes={EDGE_TYPES}
        deleteKeyCode={'XXX'}
        onNodeClick={(_, node) => {
          if (onBlockSelected) {
            setAnchorPlusIcon(prev => {
              if (!prev) {
                onBlockSelected(node.id == selectedBlockId ? '' : node.id)
              }
              return prev
            })
          }
        }}
        onEdgeClick={(_: ReactMouseEvent, edge: Edge) => {
          if (onEdgeClick) {
            setAnchorPlusIcon(prev => {
              if (!prev) {
                onEdgeClick(edge.id)
              }
              return prev
            })
          }
        }}
        onNodesChange={(changes: NodeChange[]) => {
          if (onBlockSelected && changes[0].type == 'select') {
            let c = changes.find(it => it.type == 'select' && it.selected) as NodeSelectionChange
            if (!c) {
              onBlockSelected('')
            }
          }
          if (isReadOnly) {
            return false
          }

          if (changes[0].type == 'position' && changes[0].dragging) {
            if (!movingNode) {
              let node = graph.nodes ? graph.nodes[changes[0].id] : null
              if (node) {
                setMovingNode({
                  id: node.id,
                  x: node.x,
                  y: node.y,
                })
              }
            }
          }
          onNodesChange(changes)
        }}
        onEdgesChange={onEdgesChange}
        onConnect={params => {
          //console.log(params)
          if (isReadOnly) {
            return false
          }
          if (params.source && params.target) {
            onNodesLinked(params.source, params.target, params.sourceHandle || '')
          }
        }}
      >
        {/*<MiniMap/>*/}
        <Controls>
          <ControlButton onClick={onExportClick}>
            {isExporting ? <IconLoader className="size-2" /> : <ImageIcon />}
          </ControlButton>
        </Controls>
        <Background />
      </ReactFlow>
    </>
  )
})
