import { EventActionNodeType } from './types'
import { CouponType, GraphRoot, HubPopupContentType, Node, StoreItemCreate } from '@/api/dashboard'
import { findPrevEntityNode } from '@/layouts/campaigns/util'

export default function createNode(
  idNode: string,
  type: EventActionNodeType,
  x: number,
  y: number,
  nextId: string | undefined,
  graph: GraphRoot,
  sourceNode: Node,
): Node {
  let cnode = {
    title: '',
    model_type: type,
    id: idNode,
    next_node_id: nextId,
    styles: {},
    x: x,
    y: y,
  } as Node

  switch (cnode.model_type) {
    case EventActionNodeType.CreateUserStoreSettingsNode:
      cnode.duration = 3600
      break
    case EventActionNodeType.CreateCouponNode:
      cnode.type = CouponType.Discount
      cnode.discount_percent = 10
      break
    case EventActionNodeType.ConditionNode:
      cnode.expr = {
        tokens: [],
      }

      cnode.true_node_id = nextId
      cnode.next_node_id = undefined

      break
    case EventActionNodeType.SplitNode:
      cnode.distribution = [
        { value: 50, next_node_id: undefined },
        { value: 100, next_node_id: nextId },
      ]

      break
    case EventActionNodeType.TimerNode:
      cnode.delay = 5
      break
    case EventActionNodeType.CreateItemBannerActionNode:
      cnode.config = {
        title: '',
        description: '',
        featured_item_id: '',
        image_url: '',
        background_image_size: '',
      }
      if (graph.nodes && findPrevEntityNode(graph.nodes, sourceNode.id)) {
        cnode.link_created_offer = true
      }
      break
    case EventActionNodeType.AddStoreItemsNode:
      cnode.item = {
        position: 0,
        name: '',
        description: '',
      } as StoreItemCreate
      break
    case EventActionNodeType.EmailActionNode:
    case EventActionNodeType.MobilePopupActionNode:
    case EventActionNodeType.MobilePushActionNode:
    case EventActionNodeType.BrowserPushNotificationActionNode:
      if (graph.nodes && findPrevEntityNode(graph.nodes, sourceNode.id)) {
        cnode.link_created_offer = true
      }
      break
    case EventActionNodeType.BrowserPopupNotificationActionNode:
      if (graph.nodes && findPrevEntityNode(graph.nodes, sourceNode.id)) {
        cnode.link_created_offer = true
      } else {
        cnode.content_type = HubPopupContentType.Custom
      }
      break
  }

  return cnode
}
