import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { ResponseError, getErrorText } from '@/api'
import { LeaderboardRead, LeaderboardVisibility } from '@/api/dashboard'
import { FieldGroup } from '@/ui'
import { useLeaderboardUpdateMutate } from './api/useLeaderboardUpdateMutate'
import { useLeaderboardCreateMutate } from './api/useLeaderboardCreateMutate'
import { InputCopyButton } from '@/components/ui/InputCopyButton'
import {
  Button,
  FormErrorMessage,
  Input,
  InputDate,
  Modal,
  ModalContent,
  ModalFooter,
  ModalProps,
  ModalTitle,
  Select,
  Textarea,
  formatHookFormErrors,
} from '@dashboard/ui'

export interface LeaderBoardDialogProps extends ModalProps {
  item: LeaderboardRead
}

const LeaderBoardDialog = (props: LeaderBoardDialogProps) => {
  const { t } = useTranslation()
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const [helperText, setHelperText] = useState('')
  const { mutateAsync: updateMutateAsync } = useLeaderboardUpdateMutate(companyId, gameId)
  const { mutateAsync: createMutateAsync } = useLeaderboardCreateMutate(companyId, gameId)

  const {
    control,
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm<LeaderboardRead>({
    defaultValues: {
      ...props.item,
      visibility: props.item.visibility || LeaderboardVisibility.General,
    },
  })

  const validate = (data: LeaderboardRead) => {
    if (data.start_at && data.end_at && data.start_at >= data.end_at) {
      return t('leaderboard.dialog.validation.starts_at_less_than_ends_at')
    }
    return null
  }

  const onSubmit = handleSubmit(async (data: LeaderboardRead) => {
    setHelperText('')

    const error = validate(data)
    if (error) {
      setHelperText(error)
      return
    }

    try {
      if (props.item?.id) {
        await updateMutateAsync({
          id: data.id,
          update: data,
        })
        props.onClose()
      } else {
        await createMutateAsync({
          create: data,
        })
      }
      props.onClose()
    } catch (e) {
      setHelperText(getErrorText(e as ResponseError))
    }
  })

  return (
    <Modal {...props}>
      <ModalContent>
        <ModalTitle>{t(props.item.id ? 'edit-leaderboard-dialog.title.edit' : 'leaderboard.add-button')}</ModalTitle>

        {props.item.id && (
          <FieldGroup label={t('leaderboard.id')}>
            <InputCopyButton
              disabled={true}
              value={props.item.id}
              onChange={e => {
                e.target.value = props.item.id
              }}
            />
          </FieldGroup>
        )}

        <FieldGroup label={t('leaderboard.name')}>
          <Input
            autoFocus
            {...register('name', { required: t('validation.required') })}
            {...formatHookFormErrors(errors, 'name')}
          />
        </FieldGroup>

        <FieldGroup label={t('leaderboard.description')}>
          <Textarea
            {...register('description', { required: t('validation.required') })}
            {...formatHookFormErrors(errors, 'description')}
          />
        </FieldGroup>

        <div className="flex gap-1">
          <FieldGroup label={t('leaderboard.dialog.starts_at')} className="w-full">
            <Controller
              control={control}
              name="start_at"
              render={({ field }) => (
                <InputDate
                  className="w-full"
                  value={field.value ? new Date((field.value as number) * 1000) : null}
                  onChange={value => {
                    field.onChange(value ? value.getTime() / 1000 : (null as unknown as undefined))
                  }}
                />
              )}
            />
            <ErrorMessage
              name="start_at"
              errors={errors}
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </FieldGroup>

          <FieldGroup label={t('leaderboard.dialog.ends_at')} className="w-full">
            <Controller
              control={control}
              name="end_at"
              render={({ field }) => (
                <InputDate
                  className="w-full"
                  value={field.value ? new Date((field.value as number) * 1000) : null}
                  onChange={value => {
                    field.onChange(value ? value.getTime() / 1000 : (null as unknown as undefined))
                  }}
                />
              )}
            />
            <ErrorMessage
              name="end_at"
              errors={errors}
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </FieldGroup>
        </div>

        <FieldGroup
          label={t('leaderboard.dialog.visibility.title')}
          tooltip={t('leaderboard.dialog.visibility.title.desc')}
        >
          <Controller
            control={control}
            name="visibility"
            render={({ field }) => (
              <Select
                {...field}
                disabled={isSubmitting}
                items={[LeaderboardVisibility.General, LeaderboardVisibility.Personal].map(v => ({
                  value: v,
                  children: t(`leaderboard.dialog.visibility.${v}.title`),
                  desc: t(`leaderboard.dialog.visibility.${v}.desc`),
                }))}
              />
            )}
          />
        </FieldGroup>

        {helperText && <FormErrorMessage>{helperText}</FormErrorMessage>}
      </ModalContent>

      <ModalFooter>
        <Button size="sm" variant="outline" color="secondary" onClick={() => props.onClose()}>
          {t('Cancel')}
        </Button>
        <Button size="sm" onClick={onSubmit}>
          {t(props.item.id ? 'Save2' : 'Add')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default LeaderBoardDialog
