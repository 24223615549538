import { Outlet } from 'react-router-dom'
import { ButtonIcon, ModalProps, ModalRush } from '@/ui'
import { XClose } from '@/icons'
import { SkuSettingsSidebar } from './SkuSettingsSidebar'

interface SkuSettingsModalProps extends ModalProps {}

export const SkuSettingsModal = (props: SkuSettingsModalProps) => (
  <ModalRush {...props}>
    <div className="flex size-full">
      <div className="absolute right-5 top-5 flex w-full justify-end">
        <ButtonIcon size="sm" variant="secondary-gray" onClick={props.onClose}>
          <XClose />
        </ButtonIcon>
      </div>
      <div className="h-full">
        <SkuSettingsSidebar />
      </div>
      <div className="flex size-full flex-col items-center overflow-y-auto px-10">
        <div className="size-full max-w-[1144px] pt-4">
          <Outlet />
        </div>
      </div>
    </div>
  </ModalRush>
)
