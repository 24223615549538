import { ReactNode } from 'react'
import { InfoCircle } from '@/icons'

interface FieldValidationMessageProps {
  children: ReactNode
}

/**
 * @deprecated please use `FormErrorMessage` from `@dashboard/ui` instead
 */
export const FieldValidationMessage = ({ children }: FieldValidationMessageProps) => {
  return (
    <div className="mt-1 flex items-center gap-2 rounded-md bg-fg-error-tertiary px-2 py-1.5 text-sm text-text-error-primary">
      <InfoCircle className="shrink-0" size={18} />
      {children}
    </div>
  )
}
