import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { URL_HUB_API } from '../HubAPI'
import CenteredProgress from '../components/CenteredProgress'
import { WebSite } from '../types'
import { setCompanyGameId, setLastGameId } from '../Settings'
import { useAuthToken } from '@/auth/useAuthToken'

type Error = { detail: string }

export function FindWebSite() {
  const [error, setError] = useState('')
  const navigate = useNavigate()

  const { isLoading, authDone } = useAuthToken()

  const loadSite = async () => {
    try {
      let url = window.location.pathname.split('/site/')[1]
      let slug = url.startsWith('http') ? new URL(url).hostname : url

      let response = await fetch(URL_HUB_API + '/hub/v1/websites/' + slug.split('.')[0], {
        method: 'GET',
        cache: 'no-cache',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
      })

      if (response.status == 404) {
        navigate('/404')
        return
      }

      let res: WebSite | Error = await response.json()

      if ((res as Error).detail) {
        setError((res as Error).detail)
      } else {
        let website = res as WebSite
        if (website.game) {
          setLastGameId(website.game.id)
          setCompanyGameId(website.game.company_id)
          navigate('/company/' + website.game.company_id + '/' + website.game.id)
        } else {
          navigate('/404')
        }
      }

      console.log(res)
    } catch (e) {
      console.log(e)
      navigate('/404')
    }
  }

  useEffect(() => {
    if (authDone) {
      loadSite()
    }
  }, [authDone])

  if (isLoading) {
    return <div>{error ? <div>{error}</div> : <CenteredProgress />}</div>
  }

  return <></>
}
