import { motion } from 'framer-motion'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { WebhookEventType } from '@/api/dashboard'
import { Button } from '@dashboard/ui'
import { FlipBackward } from '@/icons'
import { WebhookEventCheckbox } from '../../components'
import { webhookSortedKeys } from '../../libs'
import { stageMotionProps } from './stageMotionProps'
import { WebhookFormData, WebhookFormStage } from './WebhookForm'

interface SelectEventsStageProps {
  setStage: (stage: WebhookFormStage) => void
}

export const SelectEventsStage = ({ setStage }: SelectEventsStageProps) => {
  const { t } = useTranslation()
  const { register } = useFormContext<WebhookFormData>()

  return (
    <motion.div className="flex h-full flex-col" {...stageMotionProps}>
      <div className="mb-6">
        <button
          className="flex items-center gap-2.5 text-caption-lg text-text-secondary hover:text-text-brand-primary"
          type="button"
          onClick={() => setStage('general')}
        >
          <FlipBackward size={18} />
          <span>{t('Back')}</span>
        </button>
      </div>

      <div className="mb-6">
        <h2 className="mb-3 text-title-t1">{t('webhook.form.select-events.stage.title')}</h2>
        <p className="text-paragraph-lg text-text-secondary">{t('webhook.form.select-events.stage.sub')}</p>
      </div>

      <div className="mb-6 overflow-hidden rounded-xl border border-border-secondary shadow-xs">
        {Object.values(WebhookEventType)
          .sort((a, b) => webhookSortedKeys.indexOf(a) - webhookSortedKeys.indexOf(b))
          .filter(event => event !== 'campaign.custom')
          .map(event => (
            <WebhookEventCheckbox
              {...register('events')}
              key={`event-${event}`}
              value={event}
              event={{ label: t(`webhook.event.${event}.label`), desc: t(`webhook.event.${event}.desc`) }}
            />
          ))}
      </div>

      <div className="mt-auto flex gap-3">
        <Button type="button" onClick={() => setStage('general')}>
          {t('webhook.form.save')}
        </Button>
      </div>
    </motion.div>
  )
}
