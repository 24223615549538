import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'
import CircularProgress from '@mui/material/CircularProgress'
import { Skeleton } from '@dashboard/ui'
import { Button, useModal } from '@/ui'
import { getMotionProps, getPercentFrom } from '@/libs'
import { HubFirstPublishModal } from '@/layouts/hub'
import { GetStartedStepState } from '../types'

interface StepNextProps {
  progress: { completed: number; total: number }
  next?: GetStartedStepState
}

export const stepNextMotionProps = getMotionProps({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
})

export const StepNext = ({ progress, next }: StepNextProps) => {
  const { t } = useTranslation()
  const openPublishModal = useModal(props => <HubFirstPublishModal {...props} />)
  const isCompleted = progress.completed == progress.total
  const canPublish = !!next && isCompleted
  return (
    <motion.div {...stepNextMotionProps} layout="position" className="flex gap-6">
      <div className="relative flex size-16 shrink-0">
        <CircularProgress
          className="flex"
          variant="determinate"
          thickness={4}
          size={64}
          value={getPercentFrom(progress.completed, progress.total)}
        />
        <div className="absolute left-0 top-0 flex size-full items-center justify-center text-center text-caption-md text-text-tertiary">
          {[progress.completed, progress.total].join('/')}
        </div>
      </div>
      <div className="w-full">
        <div className="mb-1 text-paragraph-sm font-semibold text-text-secondary">
          {next?.title || t('get-started.next.title')}
        </div>
        <div className="text-paragraph-sm text-text-tertiary">{next?.desc || t('get-started.next.desc')}</div>
      </div>
      <Button
        className="shrink-0"
        variant="primary"
        disabled={!canPublish}
        size="lg"
        onClick={() => openPublishModal()}
      >
        {t('get-started.next.publish')}
      </Button>
    </motion.div>
  )
}

export const StepNextSkeleton = () => (
  <motion.div {...stepNextMotionProps} layout="position" className="flex gap-6">
    <Skeleton className="size-16 shrink-0 rounded-full" />
    <div className="w-full">
      <Skeleton className="mb-1 h-5 w-1/4" />
      <Skeleton className="mb-0.5 h-[18px] w-2/3" />
      <Skeleton className="h-[18px] w-2/4" />
    </div>
  </motion.div>
)
