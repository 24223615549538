import { Badge } from '@/ui'
import { TextIcon } from '@/layouts/sku-settings/icons/TextIcon'
import { NumberIcon } from '@/layouts/sku-settings/icons/NumberIcon'
import { Range } from '@/layouts/sku-settings/icons/Range'
import { useTranslation } from 'react-i18next'
import { ItemPropertyRead } from '@/api/dashboard'
import { cn } from '@dashboard/ui'

export const ItemPropertyBadge = ({
  itemProperty,
  className,
}: {
  className?: string
  itemProperty: ItemPropertyRead
}) => {
  const { t } = useTranslation()
  const renderType = (type: string) => {
    let text = t(`item-props.type.${type}`)

    switch (type) {
      case 'string':
        return (
          <>
            <TextIcon />
            {text}
          </>
        )
      case 'number':
        return (
          <>
            <NumberIcon />
            {text}
          </>
        )
      case 'range':
        return (
          <>
            <Range />
            {text}
          </>
        )
    }
  }

  return (
    <Badge variant="brand-blue-tertiary" className={cn(className, 'whitespace-nowrap')}>
      {renderType(itemProperty.config.property_type)}
    </Badge>
  )
}
