import { useNavigate } from 'react-router-dom'
import { Button } from '@/ui'
import { useTranslation } from 'react-i18next'

export default function NotFoundPage() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  return (
    <div
      style={{
        width: '690px',
        height: '360px',
        boxShadow: '0px 24px 48px -12px #1018282E',
        background: 'linear-gradient(273.02deg, rgba(255, 255, 255, 0) 5.02%, #FFFFFF 94.98%)',
        zIndex: 1,
      }}
      className="flex flex-col items-center gap-10 rounded-[36px] bg-fg-primary p-12"
    >
      <div className="flex flex-col items-center gap-3">
        <span
          style={{ width: '56px' }}
          className="inline-block rounded-md border border-border-primary bg-fg-primary px-3 py-2 text-[16px] font-semibold"
        >
          404
        </span>

        <span className="text-[36px] font-semibold" style={{ lineHeight: '43px' }}>
          {t('not-found')}
        </span>
      </div>

      <Button onClick={() => navigate('/')} variant="primary" size={'xl'} style={{ width: '50%' }}>
        {t('go-home')}
      </Button>

      <span
        className="text-[16px] text-text-secondary"
        dangerouslySetInnerHTML={{ __html: t('access-denied.footer') }}
      />
    </div>
  )
}
