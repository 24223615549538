import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useWebsitesQuery } from '../../api/useWebsitesQuery'
import { PageRead } from '../../api/dashboard'
import { Drawer } from '@/ui'
import { PagesForm } from './components'

interface EditNewsDialogProps {
  item?: PageRead
  parents?: PageRead[]
  onClose: (saved: boolean) => void
}

const EditPageDialog = ({ item, parents = [], onClose }: EditNewsDialogProps) => {
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const [open, setOpen] = useState(false)

  const { data: websites = [] } = useWebsitesQuery(companyId, gameId)

  useEffect(() => {
    setOpen(true)
  }, [setOpen])

  const onClickClose = () => {
    setOpen(false)
    setTimeout(() => {
      onClose(false)
    }, 150)
  }

  return (
    <Drawer open={open}>
      <PagesForm websiteUrl={websites[0] ? websites[0].url : ''} item={item} parents={parents} onClose={onClickClose} />
    </Drawer>
  )
}

export default EditPageDialog
