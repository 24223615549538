import { useTranslation } from 'react-i18next'
import { JobFunctionName, LocaleRead } from '@/api/dashboard'
import { AiTranslateOutline } from '@/icons'
import { getPercentFrom } from '@/libs'
import { StepProgressBar } from '@/layouts/get-started/components'
import { useParams } from 'react-router-dom'
import { dashboardClient } from '@/api'
import { ToastSeverity, useToast } from '@/ui'
import { Button } from '@dashboard/ui'
import { useJobListener } from '@/providers/SocketProvider/useJobListener'

interface L10nLocaleModalAiTranslateProps {
  locale: LocaleRead
}

export const L10nLocaleModalAiTranslate = ({ locale }: L10nLocaleModalAiTranslateProps) => {
  const { t } = useTranslation()
  const showToast = useToast()
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }

  const { progressJobs, refetch } = useJobListener([JobFunctionName.TranslateTranslateAll])

  const progress = progressJobs.find(job => job.function_args?.locale_id === locale.id)

  const onCancelClick = async () => {
    if (!progress) {
      return
    }
    await dashboardClient.v1.cancelJob(companyId, gameId, progress.id)
    await refetch()
    showToast({ message: t('localization.ai.all.cancelled'), severity: ToastSeverity.warning })
  }

  if (!progress) {
    return <></>
  }

  return (
    <div className="absolute left-0 top-0 z-[5] flex size-full items-start justify-center rounded-md bg-gradient-to-b from-[rgba(100,116,139,0.00)] to-[rgba(100,116,139,0.30)]">
      <div className="sticky left-0 top-1/2 max-w-md rounded-2xl1 border border-border-primary bg-fg-primary p-12 shadow-xl">
        <div className="mb-6 flex items-center justify-between text-title-t5">
          {t('localization.ai.all.modal-status.title')}

          <Button color="danger" variant="outline" type="button" size="xs" onClick={onCancelClick}>
            {t('Cancel')}
          </Button>
        </div>
        <div>
          <StepProgressBar value={getPercentFrom(progress.progress_done, progress.progress_total || 100)} />
          <div className="mt-1.5 flex items-start gap-2 text-caption-md font-normal text-text-secondary">
            <AiTranslateOutline className="shrink-0" size={14} />
            <div>{t('localization.ai.all.modal-status.text')}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
