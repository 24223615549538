import { Check, Copy03 } from '@/icons'
import { HTMLAttributes, useEffect, useState } from 'react'
import { Tooltip } from '@/components/ui'
import { useTranslation } from 'react-i18next'
import { VariantProps, cva } from 'class-variance-authority'
import { cn } from '@dashboard/ui'

const variants = cva('', {
  variants: {
    icon: {
      default: '',
      secondary: 'text-text-quarterary',
    },
  },
})

export interface CopyButtonProps extends HTMLAttributes<HTMLDivElement>, VariantProps<typeof variants> {
  text: string
  variant?: 'default' | 'secondary'
}

export const CopyButton = ({ text, className, variant = 'default' }: CopyButtonProps) => {
  const [success, setSuccess] = useState(false)
  const { t } = useTranslation()

  const onCopyClick = () => {
    setSuccess(true)
    navigator.clipboard.writeText(text)
  }

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setSuccess(false)
      }, 2500)
    }
  }, [success, setSuccess])

  return (
    <div className={className}>
      {success ? (
        <Tooltip message={t('copied')}>
          <Check className="ml-auto text-text-brand-primary" size={16} strokeWidth={3} />
        </Tooltip>
      ) : (
        <Copy03 size={16} onClick={onCopyClick} className={cn('ml-auto cursor-pointer', variants({ icon: variant }))} />
      )}
    </div>
  )
}
