import { useContext } from 'react'
import { AuthContext, IAuthContext } from '@/Context'
import { isSuperAdmin } from '@/util'
import { canEdit, isAdmin } from '@/security'

export const useCurrentUser = () => {
  const context = useContext(AuthContext) as IAuthContext

  const isSuperAdm = isSuperAdmin(context.customer)
  const isStaff = isSuperAdm || context.customer?.email?.endsWith('@aghanim.com')
  return {
    customer: context.customer,
    isSuperAdmin: isSuperAdm,
    isAdmin: isSuperAdm || isAdmin(context.customer),
    canEdit: canEdit(context.customer),
    isStaff: isStaff,
  }
}
