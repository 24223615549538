import { useContext } from 'react'
import { Trans } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import i18next from 'i18next'
import { ErrorMessage } from '@hookform/error-message'
import { Button, FormErrorMessage, Input, Skeleton } from '@dashboard/ui'
import { CompanyGame } from '@/api/dashboard'
import { FieldGroup, ImageUploader, ImageUploaderSkeleton, PageHeader } from '@/ui'
import { Plus, Trash } from '@/icons'
import { ImageSize } from '@/components/ui/ImageUploader/ValueContainer'
import DescBlock from '@/layouts/game-settings/components/DescBlock'
import Block from '@/layouts/game-settings/components/Block'
import { AuthContext, IAuthContext } from '@/Context'
import { domainRegexp, urlRegexp } from '@/libs'
import { S3Bucket } from '@/types'
import { isAdmin } from '@/security'

interface CompanySettingsFormProps {
  company: CompanyGame
  onSubmit: (data: CompanyGame) => Promise<void>
}

export const CompanySettingsForm = ({ company, onSubmit: _onSubmit }: CompanySettingsFormProps) => {
  const context = useContext(AuthContext) as IAuthContext
  const canUserEdit = isAdmin(context.customer)

  const {
    formState: { isSubmitting, isDirty, errors },
    handleSubmit,
    register,
    control,
    setValue,
    reset,
    watch,
  } = useForm<CompanyGame>({
    defaultValues: {
      games: [],
      domains: [],
      viewer_domains: [],
      ...company,
    },
  })

  const onSubmit = handleSubmit(async data => {
    try {
      const clearData = {
        ...data,
        domains: data.domains?.filter(Boolean),
        viewer_domains: data.viewer_domains?.filter(Boolean),
      }
      await _onSubmit(clearData)
      reset(clearData)
    } catch (e) {
      console.log(e)
    }
  })

  const onClickDiscard = () => {
    reset(company)
  }

  const renderDomainBlock = (key: 'viewer_domains' | 'domains') => {
    const domains = watch(key) || []

    const onClickAddDomain = () => {
      setValue(key, [...domains, ''], { shouldDirty: true })
    }

    const onClickDeleteDomain = (index: number) => {
      setValue(
        key,
        domains.filter((_, i) => i !== index),
        { shouldDirty: true },
      )
    }

    return (
      <Block>
        {!!domains.length && (
          <FieldGroup>
            {domains.map((_, index) => (
              <div key={`domains-${index}`} className="mb-2 flex gap-1.5 last:mb-0">
                <div className="w-full">
                  <Input
                    {...register(`${key}.${index}`, {
                      // required: i18next.t('validation.required'),
                      pattern: { value: domainRegexp, message: i18next.t('validation.invalid_url') },
                    })}
                    disabled={!canUserEdit}
                    placeholder="example.com"
                  />
                  <ErrorMessage
                    name={`${key}.${index}`}
                    errors={errors}
                    render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
                  />
                </div>
                {canUserEdit && (
                  <Button variant="outline" color="secondary" type="button" onClick={() => onClickDeleteDomain(index)}>
                    <Trash size={18} />
                  </Button>
                )}
              </div>
            ))}
          </FieldGroup>
        )}

        {canUserEdit && (
          <FieldGroup>
            <Button variant="outline" color="secondary" size="sm" type="button" onClick={onClickAddDomain}>
              <Plus size={18} />
              <span>{i18next.t('company-settings-page.add-domain')}</span>
            </Button>
          </FieldGroup>
        )}
      </Block>
    )
  }

  return (
    <form onSubmit={onSubmit}>
      <PageHeader
        variant="bordered"
        sticky={true}
        extra={
          <>
            {isDirty && (
              <Button variant="outline" color="secondary" size="sm" onClick={onClickDiscard}>
                {i18next.t('Discard')}
              </Button>
            )}
            <Button size="sm" loading={isSubmitting} disabled={!isDirty} type="submit">
              {i18next.t('Save')}
            </Button>
          </>
        }
      >
        {i18next.t('company-settings-page.title')}
      </PageHeader>

      <div style={{ maxWidth: '1164px' }} className="mx-auto mt-5 flex w-full max-w-[1164px] flex-col gap-12">
        <DescBlock
          title={i18next.t('company-settings-page.general')}
          description={<Trans i18nKey="company-settings-page.general.desc" />}
        >
          <Block>
            <FieldGroup label={i18next.t('company-settings-page.name')}>
              <Input
                {...register('name', {
                  required: i18next.t('validation.required'),
                  maxLength: 300,
                  setValueAs: value => {
                    return value.trim()
                  },
                })}
                disabled={!canUserEdit}
                maxLength={300}
              />
              <ErrorMessage
                name="name"
                errors={errors}
                render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
              />
            </FieldGroup>

            <FieldGroup label={i18next.t('company-settings-page.url')}>
              <Input
                {...register('url', {
                  required: i18next.t('validation.required'),
                  pattern: { value: urlRegexp, message: i18next.t('validation.invalid_url') },
                })}
                placeholder="https://example.com"
                disabled={!canUserEdit}
              />
              <ErrorMessage
                name="url"
                errors={errors}
                render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
              />
            </FieldGroup>

            <FieldGroup label={i18next.t('company-settings-page.logo_url')}>
              <Controller
                name="logo_url"
                control={control}
                render={({ field }) => (
                  <ImageUploader
                    imageSize={ImageSize.contain}
                    {...field}
                    accept={{
                      'image/jpeg': ['.jpeg', '.jpg'],
                      'image/png': [],
                      'image/webp': [],
                      'image/gif': [],
                    }}
                    bucket={S3Bucket.dashboard}
                  />
                )}
              />
            </FieldGroup>
          </Block>
        </DescBlock>

        <DescBlock
          title={i18next.t('company-settings-page.domains')}
          description={<Trans i18nKey="company-settings-page.domains.desc" components={{ b: <b /> }} />}
        >
          {renderDomainBlock('domains')}
        </DescBlock>

        <DescBlock
          title={i18next.t('company-settings-page.viewer_domains')}
          description={<Trans i18nKey="company-settings-page.viewer_domains.desc" components={{ b: <b /> }} />}
        >
          {renderDomainBlock('viewer_domains')}
        </DescBlock>
      </div>
    </form>
  )
}

export const CompanySettingsFormSkeleton = () => {
  return (
    <div>
      <PageHeader
        variant="bordered"
        sticky={true}
        extra={
          <Button size="sm" disabled={true}>
            {i18next.t('Save')}
          </Button>
        }
      >
        {i18next.t('company-settings-page.title')}
      </PageHeader>

      <div style={{ maxWidth: '1164px' }} className="mx-auto mt-5 flex w-full max-w-[1164px] flex-col gap-12">
        <DescBlock
          title={i18next.t('company-settings-page.general')}
          description={<Trans i18nKey="company-settings-page.general.desc" />}
        >
          <Block>
            <FieldGroup label={<Skeleton className="h-[17px] w-1/3" />}>
              <Skeleton className="h-9 w-full" />
            </FieldGroup>

            <FieldGroup label={<Skeleton className="h-[17px] w-1/4" />}>
              <Skeleton className="h-9 w-full" />
            </FieldGroup>

            <FieldGroup label={<Skeleton className="h-[17px] w-2/4" />}>
              <ImageUploaderSkeleton />
            </FieldGroup>
          </Block>
        </DescBlock>

        <DescBlock
          title={i18next.t('company-settings-page.domains')}
          description={<Trans i18nKey="company-settings-page.domains.desc" components={{ b: <b /> }} />}
        >
          <Block>
            <FieldGroup>
              <Skeleton className="h-9 w-full" />
            </FieldGroup>
            <FieldGroup>
              <Skeleton className="h-7 w-1/4" />
            </FieldGroup>
          </Block>
        </DescBlock>

        <DescBlock
          title={i18next.t('company-settings-page.viewer_domains')}
          description={<Trans i18nKey="company-settings-page.viewer_domains.desc" components={{ b: <b /> }} />}
        >
          <Block>
            <FieldGroup>
              <Skeleton className="h-9 w-full" />
            </FieldGroup>
            <FieldGroup>
              <Skeleton className="h-7 w-1/4" />
            </FieldGroup>
          </Block>
        </DescBlock>
      </div>
    </div>
  )
}
