import { cn } from '@/libs'
import { PaymentMethod } from '@/api/dashboard'

const SCHEMA_IMAGES = {
  visa: 'visa.svg',
  mastercard: 'mastercard.svg',
  rupay: '',
  amex: 'amex.svg',
  verve: '',
  'visa/dankort': 'dankort.svg',
  jcb: 'jcb.svg',
  'china union pay': 'unionpay.svg',
  'american express': '',
  discover: 'discover.svg',
  uzcard: '',
  maestro: 'maestrocard.svg',
  meeza: '',
  troy: '',
  'visa/bancontact': 'bancontact.svg',
  'mastercard/bancontact': 'bancontact.svg',
  elo: 'elo.svg',
  'nspk mir': '',
  'tarjeta naranja': '',
  'diners club international': '',
} as { [key: string]: string }

export default function PayMethodView({
  paymentMethod,
  className,
  card_last_4_digits,
  card_scheme,
  isTransaction,
}: {
  paymentMethod?: PaymentMethod
  className?: string
  card_last_4_digits?: string
  card_scheme?: string
  isTransaction?: boolean
}) {
  const default_image_url = `${import.meta.env.VITE_STATIC_IMAGES_URL}/images/placeholder.png`

  let url = paymentMethod?.logo_url
  if (card_scheme) {
    const card_scheme_url = SCHEMA_IMAGES[card_scheme.toLowerCase().trim()]
    if (card_scheme_url) {
      url = `${import.meta.env.VITE_STATIC_IMAGES_URL}/payment-methods/${card_scheme_url}`
    }
  }

  let viewName = paymentMethod?.caption

  const isPhonePay =
    card_scheme && (paymentMethod?.name === 'google_checkout' || paymentMethod?.name === 'apple_checkout')

  if (isTransaction && viewName == 'Credit Cards') {
    // there is no another way to check for this
    viewName = ''
  }

  return (
    <div className={cn('flex items-center gap-3', className)} data-testid={`pay-method-${paymentMethod?.caption}`}>
      <img
        src={url || default_image_url}
        alt={paymentMethod?.caption}
        onError={e => {
          ;(e.target as HTMLImageElement).src = default_image_url || ''
        }}
        className="h-[22px]"
      />
      {card_last_4_digits ? <span>•••• {card_last_4_digits}</span> : isTransaction && !viewName && <span>••••</span>}

      {isPhonePay ? (
        <img src={`/icons/${paymentMethod?.name}.svg`} alt={paymentMethod?.caption} className="h-[14px] w-[20px]" />
      ) : (
        <span>{viewName}</span>
      )}
    </div>
  )
}
