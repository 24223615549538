import { ReactNode, forwardRef } from 'react'
import styled from '@emotion/styled'
import { AnimatePresence, motion } from 'framer-motion'
import { Checkbox, CheckboxProps } from '../Checkbox'
import { cn, getMotionProps } from '../../../libs'
import { cva } from 'class-variance-authority'

interface ToggleContainerProps extends CheckboxProps {
  label: ReactNode
  children?: ReactNode
  variant?: 'default' | 'background'
  extraRight?: ReactNode
}

const Entry = styled(motion.div)``

const entryMotionProps = getMotionProps({
  initial: { paddingTop: 0, height: 0, opacity: 0 },
  animate: { paddingTop: 8, height: 'auto', opacity: 1 },
  exit: { paddingTop: 0, height: 0, opacity: 0 },
})

const checkBoxContainerVariants = cva('flex flex-col', {
  variants: {
    variant: {
      default: '',
      background: 'rounded-md border border-border-secondary bg-fg-primary-hover p-3',
    },
  },
})

/**
 * TODO: @ds.pankov сделать отдельный компонент для таких вещей.
 */
export const CheckBoxContainer = forwardRef<HTMLInputElement, ToggleContainerProps>(function CheckBoxContainer(
  { children, caption, extraRight, label, checked, variant = 'default', ...rest },
  ref,
) {
  return (
    <div className={cn(checkBoxContainerVariants({ variant }), rest.className)}>
      <div className="flex items-center justify-between">
        <Checkbox ref={ref} checked={checked} caption={caption} {...rest}>
          {label}
        </Checkbox>
        {extraRight}
      </div>
      {children && <AnimatePresence>{checked && <Entry {...entryMotionProps}>{children}</Entry>}</AnimatePresence>}
    </div>
  )
})
