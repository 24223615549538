import { useEffect, useState } from 'react'
import { Link, generatePath, useNavigate, useParams } from 'react-router-dom'
import EditNewsDialog from './EditNewsDialog'
import i18next from 'i18next'
import { Button } from '@dashboard/ui'
import { dashboardClient } from '../../api'
import { NewsBulkUpdate, NewsGroup, NewsRead, NewsSortField, SortOrder } from '../../api/dashboard'
import {
  Badge,
  ButtonIcon,
  Checkbox,
  ConfirmDialog,
  PageHeader,
  Pagination,
  Table,
  TableCell,
  TableRow,
  TableZeroState,
  usePagination,
} from '@/ui'
import { useNewsQuery } from './api/useNewsQuery'
import { HUB_NEWS_DETAIL_PATH, HUB_NEWS_GROUP_PATH } from '../../libs/routerPaths'
import { StyledTabs, TabStyle } from '../../components/StyledTab'
import Tab from '@mui/material/Tab'
import { NewsRowSkeleton } from './components/NewsRowSkeleton/NewsRowSkeleton'
import { Plus, Trash, XClose } from '@/icons'
import { TableCellCheckBox } from '../../components/ui/Table/TableCellCheckBox'
import ConfirmPublishDialog from '../components/ConfirmPublishDialog/ConfirmPublishDialog'
import { getNow } from '../../util'
import { DateTimeValue } from '@/components/ui/DateTimeValue'
import { useCurrentUser } from '@/api/useCurrentUser'

const DEFAULT_PLACEHOLDER = `${import.meta.env.VITE_STATIC_IMAGES_URL}/images/articles-placeholder.png`

export default function NewsPage() {
  const navigate = useNavigate()
  const { companyId, gameId, newsId, group } = useParams() as {
    companyId: string
    gameId: string
    group: string
    newsId?: string
  }
  const { canEdit } = useCurrentUser()
  const [selectedSet, setSelectedSet] = useState<Set<string>>(new Set())
  const [deleteConfirm, setDeleteConfirm] = useState(false)
  const [confirmPublish, setConfirmPublish] = useState<boolean | null>(null)
  const [editPost, setEditPost] = useState<NewsRead | null>(null)
  const { page, query, onPageChange, needShowPagination } = usePagination(10)
  const {
    data: items = [],
    isLoading,
    refetch,
  } = useNewsQuery(companyId, gameId, {
    group: (group as NewsGroup) || 'other',
    sort_order: SortOrder.Desc,
    sort_field: NewsSortField.Datetime,
    ...query,
  })

  const onConfirmDeleteItems = async () => {
    setDeleteConfirm(false)
    await dashboardClient.v1.bulkDeleteNews(
      companyId,
      gameId,
      Array.from(selectedSet).map(id => ({ id })),
    )

    setSelectedSet(new Set())
    refetch()
  }

  useEffect(() => {
    loadPost()
  }, [newsId])

  const loadPost = async () => {
    if (newsId) {
      if (newsId == 'new') {
        setEditPost({} as NewsRead)
      } else {
        let { data: post } = await dashboardClient.v1.getNewsPost(companyId, gameId, newsId)
        setEditPost(post)
      }
    } else {
      setEditPost(null)
      refetch()
    }
  }

  useEffect(() => {
    setSelectedSet(new Set())
  }, [group, page])

  let tabValue = 0

  if (group === NewsGroup.Update) {
    tabValue = 1
  } else if (group === NewsGroup.Event) {
    tabValue = 2
  }

  let queryParams = ''
  if (page > 1) {
    queryParams = `?page=${page}`
  }

  const getItemsForPublishUpdate = (toPublish: boolean) => {
    return items.filter(it => {
      if (!selectedSet.has(it.id)) {
        return false
      }

      return toPublish != !!it.published_at
    })
  }

  const changePublishClick = async (value: boolean) => {
    setConfirmPublish(null)

    const updateFormUpdate = getItemsForPublishUpdate(value)

    let updateArr = updateFormUpdate.map(
      it =>
        ({
          id: it.id,
          published_at: value ? getNow() : null,
        }) as NewsBulkUpdate,
    )

    await dashboardClient.v1.bulkUpdateNews(companyId, gameId, updateArr)

    refetch()
  }

  const renderDefaultHeader = () => {
    return (
      <TableRow variant="header">
        {canEdit && (
          <TableCell width={32}>
            <span
              style={{
                visibility: items.length == 0 ? 'hidden' : 'visible',
              }}
            >
              <Checkbox
                checked={items.length > 0 && selectedSet.size == items.length}
                onChange={() => {
                  setSelectedSet(selectedSet.size == items.length ? new Set() : new Set(items.map(it => it.id)))
                }}
              />
            </span>
          </TableCell>
        )}
        <TableCell width="60%">{i18next.t('edit-news-dialog.title')}</TableCell>
        <TableCell width="20%">{i18next.t('edit-news-dialog.category')}</TableCell>
        <TableCell width="20%">{i18next.t('edit-news-dialog.date')}</TableCell>
        <TableCell width="20%">{i18next.t('table-news.state')}</TableCell>
      </TableRow>
    )
  }

  const renderEditSelection = () => {
    const hasPublishItems = items.some(it => selectedSet.has(it.id) && it.published_at)
    const hasUnPublishItems = items.some(it => selectedSet.has(it.id) && !it.published_at)

    return (
      <TableRow className="h-[45px] px-1.5">
        <TableCell width={32} className="p-1.5">
          <Checkbox
            checked={selectedSet.size == items.length}
            onChange={() => {
              setSelectedSet(selectedSet.size == items.length ? new Set() : new Set(items.map(it => it.id)))
            }}
          />
        </TableCell>
        <TableCell className="flex items-center gap-1 p-1.5">
          <span className="font-semibold text-text-primary" style={{ lineHeight: '12px' }}>
            {i18next.t('sku.items.count-selected', { count: selectedSet.size })}
          </span>
          <ButtonIcon variant="tertiary-gray" size="xs" onClick={() => setSelectedSet(new Set())}>
            <XClose />
          </ButtonIcon>

          <div className="ml-3 flex items-center gap-1">
            <Button
              variant="outline"
              color="secondary"
              size="sm"
              onClick={() => changePublishClick(true)}
              disabled={!hasUnPublishItems}
            >
              {i18next.t('edit-news-dialog.publish')}
            </Button>

            <Button
              variant="outline"
              color="secondary"
              size="sm"
              onClick={() => setConfirmPublish(false)}
              disabled={!hasPublishItems}
            >
              {i18next.t('edit-news-dialog.unpublish')}
            </Button>

            <Button variant="tertiary" color="danger" size="sm" onClick={() => setDeleteConfirm(true)}>
              <Trash size={16} />
              {i18next.t('remove')}
            </Button>
          </div>
        </TableCell>
      </TableRow>
    )
  }

  const onNewsPageClick = () => {
    navigate(generatePath(HUB_NEWS_DETAIL_PATH, { companyId, gameId, group: group || 'other', newsId: 'new' }))
  }

  return (
    <div className="flex h-full flex-col">
      {editPost && (
        <EditNewsDialog
          item={editPost}
          onClose={(saved: boolean) => {
            setEditPost(null)
            navigate(-1)
            if (saved) {
              refetch()
            }
          }}
        />
      )}

      {deleteConfirm && (
        <ConfirmDialog
          color={'error'}
          confirmButtonText={i18next.t('remove')}
          message={i18next.t('table-news.confirm.remove-n-title')}
          subMessage={i18next.t('table-news.confirm.remove-n-title.message', { N: selectedSet.size })}
          onCancel={() => setDeleteConfirm(false)}
          onConfirm={onConfirmDeleteItems}
        />
      )}

      {confirmPublish !== null && (
        <ConfirmPublishDialog
          confirmPublish={confirmPublish}
          count={getItemsForPublishUpdate(confirmPublish).length}
          forms={{
            one: i18next.t('article.one'),
            many: i18next.t('article.many'),
            few: i18next.t('article.few'),
            other: i18next.t('article.other'),
          }}
          onCancel={() => setConfirmPublish(null)}
          onConfirm={() => changePublishClick(confirmPublish)}
        />
      )}

      <PageHeader
        extra={
          canEdit &&
          (!!items.length || page > 1) && (
            <Button size="sm" onClick={onNewsPageClick}>
              {i18next.t('news.add')}
            </Button>
          )
        }
      >
        {i18next.t('sidebar.news')}
      </PageHeader>

      <StyledTabs className="mb-4" value={tabValue} sx={{ marginLeft: 0 }}>
        <Tab
          sx={TabStyle}
          label={i18next.t('sidebar.news')}
          component={Link}
          to={generatePath(HUB_NEWS_GROUP_PATH, { companyId, gameId, group: null })}
        />
        <Tab
          sx={TabStyle}
          label={i18next.t('sidebar.updates')}
          component={Link}
          to={generatePath(HUB_NEWS_GROUP_PATH, { companyId, gameId, group: NewsGroup.Update })}
        />
        <Tab
          sx={TabStyle}
          label={i18next.t('sidebar.events')}
          component={Link}
          to={generatePath(HUB_NEWS_GROUP_PATH, { companyId, gameId, group: NewsGroup.Event })}
        />
      </StyledTabs>

      {isLoading ? (
        new Array(20).fill('').map((_, index) => <NewsRowSkeleton key={`skeleton-${index}`} />)
      ) : items.length === 0 && page == 1 ? (
        <TableZeroState
          title={i18next.t(canEdit ? 'news.empty-table.title' : 'news.empty-table.title.read-only')}
          message={i18next.t(canEdit ? 'news.empty-table' : 'news.empty-table.read-only')}
          buttons={
            canEdit && (
              <Button size="sm" onClick={onNewsPageClick}>
                <Plus size={16} />
                <span>{i18next.t('news.add')}</span>
              </Button>
            )
          }
        />
      ) : (
        <>
          <Table key="content">
            {selectedSet.size == 0 ? renderDefaultHeader() : renderEditSelection()}
            {items.map((it, idx) => (
              <TableRow
                key={idx}
                to={
                  canEdit
                    ? generatePath(HUB_NEWS_DETAIL_PATH, {
                        companyId,
                        gameId,
                        group: group || 'other',
                        newsId: it.id,
                      }) + queryParams
                    : ''
                }
              >
                {canEdit && <TableCellCheckBox id={it.id} selectedSet={selectedSet} setSelectedSet={setSelectedSet} />}
                <TableCell width="60%">
                  <div className="flex items-center gap-6">
                    <div
                      style={{
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundImage: `url(${it.image_url || DEFAULT_PLACEHOLDER})`,
                        borderRadius: '4px',
                        height: '38px',
                        minWidth: '76px',
                      }}
                    />
                    {it.title
                      ? it.title
                      : it.description.length > 100
                        ? `${it.description.slice(0, 100)}...`
                        : it.description}
                  </div>
                </TableCell>
                <TableCell width="20%">{i18next.t(`edit-news-dialog.category.${it.category}`)}</TableCell>
                <TableCell width="20%" className="text-text-quarterary-hover">
                  <DateTimeValue value={it.datetime} />
                </TableCell>
                <TableCell width="20%">
                  {it.published_at ? (
                    <Badge variant="green-secondary">{i18next.t('table-news.state.published')}</Badge>
                  ) : (
                    <Badge variant="gray-secondary">{i18next.t('table-news.state.draft')}</Badge>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </Table>
          {needShowPagination(isLoading, items) && (
            <Pagination
              hasNext={items.length >= query.limit}
              page={page}
              onPageChange={onPageChange}
              pageItems={items.length}
            />
          )}
        </>
      )}
    </div>
  )
}
