import { useMutation, useQueryClient } from '@tanstack/react-query'
import { dashboardClient } from '@/api'

export const usePluginUninstallMutate = (companyId: string, gameId: string, slug: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: () => dashboardClient.v1.uninstallPlugin(companyId, gameId, slug),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['plugins_instance', gameId, slug] })
    },
  })
}
