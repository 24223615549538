import { RewardPoint } from '@/icons'
import { formatNumber } from '../../../util'

export const RewardPointsPrice = ({ price, showText = true }: { price: number; showText?: boolean }) => {
  return (
    <div className="inline-flex items-center gap-1">
      <RewardPoint />
      {showText ? formatNumber(price) : formatNumber(price)}
    </div>
  )
}
