import { AllData } from '@/layouts/dashboard/Context'
import { Campaign, ItemRead, SeriesValue } from '@/api/dashboard'
import { createSankeyData, createTopItemsData } from '@/layouts/dashboard/demo_data/createSankeyData'
import { revenue_by_country } from '@/layouts/dashboard/demo_data/data_30'

const revenue = { value: 7542877.232595, prev_value: 4426514.45105 }

const revenue_by_date_device = [
  { category: '2024-08-15', value: 845739.67338, series_name: 'Android', prev_value: undefined },
  { category: '2024-08-15', value: 973304.13898, series_name: 'Apple', prev_value: undefined },
  { category: '2024-08-15', value: 261341.189395, series_name: 'Other', prev_value: undefined },
  { category: '2024-08-16', value: 739619.15572, series_name: 'Android', prev_value: undefined },
  { category: '2024-08-16', value: 72410.0, series_name: 'Apple', prev_value: undefined },
  { category: '2024-08-16', value: 77475.0, series_name: 'Other', prev_value: undefined },
  { category: '2024-08-17', value: 499836.469875, series_name: 'Android', prev_value: undefined },
  { category: '2024-08-17', value: 761806.109955, series_name: 'Apple', prev_value: undefined },
  { category: '2024-08-17', value: 899910.0, series_name: 'Other', prev_value: undefined },
  { category: '2024-08-18', value: 1325286.732055, series_name: 'Android', prev_value: undefined },
  { category: '2024-08-18', value: 299143.88082, series_name: 'Apple', prev_value: undefined },
  { category: '2024-08-18', value: 89955.0, series_name: 'Other', prev_value: undefined },
  { category: '2024-08-19', value: 1111506.379335, series_name: 'Android', prev_value: undefined },
  { category: '2024-08-19', value: 304920.0, series_name: 'Apple', prev_value: undefined },
  { category: '2024-08-20', value: 484233.069925, series_name: 'Android', prev_value: undefined },
  { category: '2024-08-20', value: 267181.98855, series_name: 'Other', prev_value: undefined },
  { category: '2024-08-21', value: 319264.951, series_name: 'Android', prev_value: undefined },
  { category: '2024-08-21', value: 720984.98186, series_name: 'Apple', prev_value: undefined },
  { category: '2024-08-21', value: 124754.0, series_name: 'Other', prev_value: undefined },
] as SeriesValue[]

const conversion = { value: 11.23, prev_value: 9.02 }

const mau = { value: 145620.0, prev_value: 77716.0 }
const dau = { value: 8347.0, prev_value: 3883.0 }

const top_items_sum = { value: 8889706.72085, prev_value: undefined }

const triggers_by_date = {
  data: [
    { category: '2024-08-15', value: 200000.0, series_name: 'game_hub_login', prev_value: undefined },
    { category: '2024-08-15', value: 75000.0, series_name: 'coupon_redeemed', prev_value: undefined },
    { category: '2024-08-15', value: 85000.0, series_name: 'game_hub_visit', prev_value: undefined },
    { category: '2024-08-16', value: 210000.0, series_name: 'game_hub_login', prev_value: undefined },
    { category: '2024-08-16', value: 77000.0, series_name: 'coupon_redeemed', prev_value: undefined },
    { category: '2024-08-16', value: 86000.0, series_name: 'game_hub_visit', prev_value: undefined },
    { category: '2024-08-17', value: 220000.0, series_name: 'game_hub_login', prev_value: undefined },
    { category: '2024-08-17', value: 79000.0, series_name: 'coupon_redeemed', prev_value: undefined },
    { category: '2024-08-17', value: 87000.0, series_name: 'game_hub_visit', prev_value: undefined },
    { category: '2024-08-18', value: 230000.0, series_name: 'game_hub_login', prev_value: undefined },
    { category: '2024-08-18', value: 80000.0, series_name: 'coupon_redeemed', prev_value: undefined },
    { category: '2024-08-18', value: 90000.0, series_name: 'game_hub_visit', prev_value: undefined },
    { category: '2024-08-19', value: 235000.0, series_name: 'game_hub_login', prev_value: undefined },
    { category: '2024-08-19', value: 85000.0, series_name: 'coupon_redeemed', prev_value: undefined },
    { category: '2024-08-19', value: 91000.0, series_name: 'game_hub_visit', prev_value: undefined },
    { category: '2024-08-20', value: 243608.069925, series_name: 'game_hub_login', prev_value: undefined },
    { category: '2024-08-20', value: 97155.0, series_name: 'coupon_redeemed', prev_value: undefined },
    { category: '2024-08-20', value: 93155.0, series_name: 'game_hub_visit', prev_value: undefined },
    { category: '2024-08-21', value: 244608.069925, series_name: 'game_hub_login', prev_value: undefined },
    { category: '2024-08-21', value: 97255.0, series_name: 'coupon_redeemed', prev_value: undefined },
    { category: '2024-08-21', value: 93855.0, series_name: 'game_hub_visit', prev_value: undefined },
  ],
  prev_data: [],
  total: [
    { category: 'game_hub_login', value: 1584216 },
    { category: 'coupon_redeemed', value: 491155 },
    { category: 'game_hub_visit', value: 536010 },
  ],
  prev_total: [
    { category: 'coupon_redeemed', value: 284085.79911 },
    { category: 'game_hub_login', value: 1423319.86059 },
    { category: 'game_hub_visit', value: 67610.352075 },
  ],
}

const revenue_by_dates = [
  { date: '2024-08-16', revenue: 293024, prev_revenue: 324188 },
  { date: '2024-08-17', revenue: 292614, prev_revenue: 349543 },
  { date: '2024-08-18', revenue: 381622, prev_revenue: 391085 },
  { date: '2024-08-19', revenue: 604199, prev_revenue: 374277 },
  { date: '2024-08-20', revenue: 701844, prev_revenue: 386068 },
  { date: '2024-08-21', revenue: 800526, prev_revenue: 458230 },
  { date: '2024-08-22', revenue: 901538, prev_revenue: 394481 },
]

const sales_by_date = [
  {
    category: '2024-08-15',
    values: {
      sales: 395236,
      revenue: 230408.04763787542,
      add_revenue: 89363.34124442743,
      channel_of_cost: 29207.458755572563,
      tax_fx: 46257.15236212461,
    },
  },
  {
    category: '2024-08-16',
    values: {
      sales: 293024,
      revenue: 170781.10275394743,
      add_revenue: 66422.52170824344,
      channel_of_cost: 21484.67829175657,
      tax_fx: 34335.697246052565,
    },
  },
  {
    category: '2024-08-17',
    values: {
      sales: 292614,
      revenue: 173628.8021694907,
      add_revenue: 66116.8931513565,
      channel_of_cost: 21667.306848643493,
      tax_fx: 31200.99783050931,
    },
  },
  {
    category: '2024-08-18',
    values: {
      sales: 381622,
      revenue: 229233.56017517758,
      add_revenue: 85685.85917467003,
      channel_of_cost: 28800.740825329976,
      tax_fx: 37901.83982482245,
    },
  },
  {
    category: '2024-08-19',
    values: {
      sales: 604199,
      revenue: 360004.4769529496,
      add_revenue: 130412.4872491848,
      channel_of_cost: 50847.2127508152,
      tax_fx: 62934.82304705041,
    },
  },
  {
    category: '2024-08-20',
    values: {
      sales: 701844,
      revenue: 416035.0976090373,
      add_revenue: 151945.4357265509,
      channel_of_cost: 58607.764273449095,
      tax_fx: 75255.70239096272,
    },
  },
  {
    category: '2024-08-21',
    values: {
      sales: 800526,
      revenue: 467951.9851743585,
      add_revenue: 174623.51984425567,
      channel_of_cost: 65534.2801557443,
      tax_fx: 92416.21482564158,
    },
  },
  {
    category: '2024-08-22',
    values: {
      sales: 901538,
      revenue: 553772.45525269,
      add_revenue: 196772.85646262794,
      channel_of_cost: 73688.54353737202,
      tax_fx: 77304.14474731003,
    },
  },
]

const payments_success_fail_by_dates = [
  { date: '2024-08-15', success_payment: 0.9568364496461526, failed_payment: 0.043163550353847446 },
  { date: '2024-08-16', success_payment: 0.9821440568639213, failed_payment: 0.017855943136078745 },
  { date: '2024-08-17', success_payment: 0.9504606471228938, failed_payment: 0.0495393528771062 },
  { date: '2024-08-18', success_payment: 0.9318367181284795, failed_payment: 0.06816328187152054 },
  { date: '2024-08-19', success_payment: 0.9679942088487223, failed_payment: 0.03200579115127766 },
  { date: '2024-08-20', success_payment: 0.9747119227715338, failed_payment: 0.025288077228466155 },
  { date: '2024-08-21', success_payment: 0.9374745468745624, failed_payment: 0.06252545312543756 },
]

const arpdau = { value: 375.08230408649814, prev_value: 131.5869323911924 }
const arppu = { value: 2300, prev_value: 2000 }

const conversion_game_hub_first_purchase_by_date = {
  prev_data: [
    { date: '2024-08-08', users_count: 1736, payment_users_count: 110 },
    { date: '2024-08-09', users_count: 1603, payment_users_count: 120 },
    { date: '2024-08-10', users_count: 1836, payment_users_count: 113 },
    { date: '2024-08-11', users_count: 1924, payment_users_count: 90 },
    { date: '2024-08-12', users_count: 1342, payment_users_count: 150 },
    { date: '2024-08-13', users_count: 2358, payment_users_count: 132 },
    { date: '2024-08-14', users_count: 1885, payment_users_count: 102 },
  ],
  data: [
    { date: '2024-08-15', users_count: 1805, payment_users_count: 186 },
    { date: '2024-08-16', users_count: 1780, payment_users_count: 144 },
    { date: '2024-08-17', users_count: 1918, payment_users_count: 166 },
    { date: '2024-08-18', users_count: 1780, payment_users_count: 190 },
    { date: '2024-08-19', users_count: 1591, payment_users_count: 170 },
    { date: '2024-08-20', users_count: 1648, payment_users_count: 180 },
    { date: '2024-08-21', users_count: 621, payment_users_count: 160 },
  ],
}

const hub_visit_data = [
  { category: '2024-08-15', value: 802123, prev_value: 765542 },
  { category: '2024-08-16', value: 824314, prev_value: 450721 },
  { category: '2024-08-17', value: 392150, prev_value: 841324 },
  { category: '2024-08-18', value: 528723, prev_value: 602459 },
  { category: '2024-08-19', value: 743243, prev_value: 490234 },
  { category: '2024-08-20', value: 634823, prev_value: 832154 },
  { category: '2024-08-21', value: 891422, prev_value: 598342 },
  { category: '2024-08-22', value: 954678, prev_value: 793024 },
]

export function create7Data(campaigs: Campaign[], items: ItemRead[]): AllData {
  const payments_success_percent = {
    value:
      (100 * payments_success_fail_by_dates.reduce((acc, it) => acc + it.success_payment, 0)) /
      payments_success_fail_by_dates.length,
    prev_value:
      (100 * payments_success_fail_by_dates.reduce((acc, it) => acc + it.success_payment, 0)) /
      payments_success_fail_by_dates.length,
  }

  return {
    arppu,
    revenue,
    revenue_by_country,
    top_items: createTopItemsData(items, '7'),
    mau,
    revenue_by_date_device: JSON.parse(JSON.stringify(revenue_by_date_device)),
    conversion,
    dau,
    top_items_sum,
    triggers_by_date: JSON.parse(JSON.stringify(triggers_by_date)),
    revenue_by_dates: JSON.parse(JSON.stringify(revenue_by_dates)),
    sales_by_date: JSON.parse(JSON.stringify(sales_by_date)),
    payments_success_percent,
    payments_success_fail_by_dates: JSON.parse(JSON.stringify(payments_success_fail_by_dates)),
    sankey_top_sku: createSankeyData(campaigs, items, '7'),
    arpdau,
    conversion_game_hub_first_purchase_by_date: JSON.parse(JSON.stringify(conversion_game_hub_first_purchase_by_date)),
    hub_visit_data: JSON.parse(JSON.stringify(hub_visit_data)),
    total_user_count: { value: 1_623_341 },
  }
}
