import { ItemImageCreate, ItemImageRead, ItemRead } from '../../../api/dashboard'

interface PreviewFeaturedBannerProps {
  sku: ItemRead
  image: ItemImageRead | ItemImageCreate
}

export const PreviewFeaturedBanner = ({ sku, image }: PreviewFeaturedBannerProps) => (
  <div className="grid w-full grid-cols-2 gap-[18px] rounded-[12px] border border-[#27272A] bg-[#18181B] p-[18px]">
    <div>
      <div className="mb-[30px] flex items-center gap-1.5">
        <div className="size-[14px] rounded-[4px] bg-[#52525B]" />
        <div className="h-[6px] w-[52px] rounded-[4px] bg-[#3F3F46]" />
      </div>
      <div className="mb-[26px]">
        <div className="mb-1.5 text-[18px] font-semibold leading-normal text-white">{sku.name}</div>
        <div className="line-clamp-2 min-h-[36px] text-[12px] leading-normal text-white">{sku.description}</div>
      </div>
      <div className="flex h-[24px] w-[64px] items-center justify-center rounded-[6px] bg-[#3F3F46]">
        <div className="h-[8px] w-[32px] rounded-[20px] bg-[#52525B]" />
      </div>
    </div>
    <div className="relative my-[-14px] mr-[-14px]">
      {(image.cropped_url || image.original_url) && (
        <img
          className="absolute right-0 top-0 flex size-full rounded-[6px] object-cover"
          src={image.cropped_url || image.original_url}
          alt=""
        />
      )}
    </div>
  </div>
)
