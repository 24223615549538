import { useEffect } from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import { useNavigate } from 'react-router-dom'
import { getLastCompanyId, getLastGameId } from '../Settings'
import { useAuthToken } from '@/auth/useAuthToken'
import { useCompaniesQuery } from '@/api/useCompaniesQuery'

export default function GoDoc() {
  const navigate = useNavigate()
  const { authDone } = useAuthToken()
  const { data: dataCompanies = null } = useCompaniesQuery()

  const load = async () => {
    let lastGameId = getLastGameId()
    let lastCompanyId = getLastCompanyId()
    if (!lastGameId || !lastCompanyId) {
      let companyWithGame = dataCompanies?.data.find(c => c.games && c.games?.length > 0)
      if (!companyWithGame) {
        navigate('/onboarding')
        return
      }
      lastCompanyId = companyWithGame.id
      lastGameId = companyWithGame.games![0].id
    }

    let goto = window.location.pathname.replace('go', `company/${lastCompanyId}/${lastGameId}`)
    navigate(goto)
  }

  useEffect(() => {
    if (authDone) {
      load()
    }
  }, [authDone])

  return (
    <Box
      sx={{
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '100vh',
      }}
    >
      <CircularProgress />
    </Box>
  )
}
