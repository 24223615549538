import { getMotionProps } from '../../libs';

const OFFSET_Y = 16;
const Z_INDEX = 2000;

/**
 * Get the modal motion props.
 * @param index
 * @param deep
 * @param all
 */
export const getModalMotionProps = (index: number, deep: number, all: number) =>
  getMotionProps({
    initial: {
      y: OFFSET_Y * -1,
      scale: 0.98,
      opacity: 0,
    },
    animate: {
      y: deep > 0 ? deep * (OFFSET_Y / 2) : 0,
      scale: 1,
      opacity: 1 - 0.2 * deep,
      zIndex: Z_INDEX + index,
    },
    exit: {
      y: OFFSET_Y * all * -1,
      scale: 0.98,
      opacity: 0,
      zIndex: Z_INDEX - index,
    },
  });
