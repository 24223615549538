import { Input } from '@/ui'
import { Edit03 } from '@/icons'
import { useModal } from '@dashboard/ui'
import { EditListModal } from '@/layouts/components/EditListModal'

export interface InputListProps {
  size?: 'xs' | 'sm' | 'md' | 'lg'
  value: string[]
  onChange: (value: string[]) => void
}

export const InputList = (props: InputListProps) => {
  const openEditModal = useModal<{ onChange: (value: string[]) => void; value: string[] | undefined | null }>(props => (
    <EditListModal {...props} />
  ))

  return (
    <Input
      size={props.size}
      value={props.value?.join(',')}
      onChange={() => {
        //do nothing
      }}
      extraRight={
        <div
          className="flex h-full cursor-pointer items-center justify-center"
          style={{ zIndex: 1 }}
          onClick={() => openEditModal({ value: props.value, onChange: props.onChange })}
        >
          <Edit03 />
        </div>
      }
    />
  )
}
