const PINNED_GAMES = 'pinned_games'

export function getPinnedGamesGlobal(): string[] {
  const s = localStorage.getItem(PINNED_GAMES)
  if (s) {
    return JSON.parse(s)
  }
  return []
}

export function setPinnedGamesGlobal(value: string[]) {
  localStorage.setItem(PINNED_GAMES, JSON.stringify(value))
}
