import { forwardRef } from 'react'
import { Select, SelectProps } from '@/components/ui'
import { useTranslation } from 'react-i18next'
import { getCountries } from '@/translates'
import { FLAGS } from '@/util'

export const SelectCountry = forwardRef<HTMLDivElement, Omit<SelectProps, 'options'>>(
  function SelectCountry(props, ref) {
    const countries = getCountries()
    const { t } = useTranslation()
    return (
      <Select
        {...props}
        ref={ref}
        placeholder={t('select-country')}
        filter={(option, search) => {
          const s = search.toLowerCase().trim()
          const text = option.children as string
          const value = option.value + ''
          return text.toLowerCase().trim().includes(s) || value.toLowerCase().includes(s)
        }}
        options={Object.entries(countries).map(([value, label]) => ({
          value,
          children: label as string,
          icon: <img className="w-6" src={`${FLAGS}/${value}.svg`} loading="lazy" alt={value} />,
          extraRight: <span className="text-text-disabled">{value}</span>,
        }))}
      />
    )
  },
)
