import { Outlet, matchPath, useLocation } from 'react-router-dom'
import { ItemType } from '@/api/dashboard'
import {
  SKU_ITEMS_PATH,
  SKU_ITEMS_SETTINGS_CATEGORIES_PATH,
  SKU_ITEMS_SETTINGS_PATH,
  SKU_ITEMS_SETTINGS_PROPERTIES_PATH,
} from '@/libs'
import { SkuListPage } from './SkuListPage'

export const SkuOutlet = () => {
  const location = useLocation()

  const isMathPaths = [
    matchPath(SKU_ITEMS_PATH, location.pathname),
    matchPath(SKU_ITEMS_SETTINGS_PATH, location.pathname),
    matchPath(SKU_ITEMS_SETTINGS_CATEGORIES_PATH, location.pathname),
    matchPath(SKU_ITEMS_SETTINGS_PROPERTIES_PATH, location.pathname),
  ].some(Boolean)

  return (
    <>
      {isMathPaths && <SkuListPage types={[ItemType.Item, ItemType.Currency]} />}
      <Outlet />
    </>
  )
}
