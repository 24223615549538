import DashboardCard from '../../../../components/shared/DashboardCard'
import { LoaderContainer } from '../Loader/LoaderContainer'
import Loader from '../Loader/Loader'
import { Fragment, HTMLAttributes, useContext } from 'react'
import { useParams } from 'react-router-dom'
import styled from '@emotion/styled'
import ZeroState from '../ZeroState/ZeroState'
import { CouponStat, CouponType } from '../../../../api/dashboard'

import { AnalyticsDataContext, IAnalyticsDataContext } from '@/layouts/dashboard/Context'
import { getUrlParamsObject } from '@/layouts/dashboard/util'
import { formatNumber } from '@/util'
import { useTopCodesQuery } from '@/layouts/dashboard/api/useTopCodesQuery'
import { useTranslation } from 'react-i18next'
import { CouponOutline } from '@/icons'
import { useCouponQuery } from '@/layouts/coupon/api'
import { itemValues30, itemValues7 } from '@/layouts/dashboard/demo_data/createSankeyData'

const Separator = styled.div`
  width: calc(100% - 38px - 12px);
  height: 1px;
  background-color: ${p => p.theme.palette.background.fgskeleton};
  margin-left: auto;
`

interface TopSkuItemsProps extends HTMLAttributes<HTMLDivElement> {}

export default function TopCodes(props: TopSkuItemsProps) {
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const { t } = useTranslation()
  const context = useContext(AnalyticsDataContext) as IAnalyticsDataContext
  let { data = [], isLoading } = useTopCodesQuery(companyId, gameId, {
    ...getUrlParamsObject(context.selectedFilter),
    limit: 5,
  })

  const isDemo = import.meta.env.VITE_DEMO_GAME_ID == gameId

  const { data: coupons = [] } = useCouponQuery(companyId, gameId, {
    limit: isDemo ? 5 : 0,
  })

  if (isDemo) {
    let itemValues = context.selectedFilter == '7' ? itemValues7 : itemValues30

    data = coupons.map((it, idx) => {
      let value = itemValues[idx % itemValues.length]
      return { ...it, count: value } as CouponStat
    })
  }

  const getBenefits = (it: CouponStat) => {
    switch (it.type) {
      case CouponType.FreeItem:
        return t('coupon.type.free_item') + ' · ' + it.item?.name
      case CouponType.Discount:
        return t('coupon.discount') + ' · ' + it.discount_percent + '%'
      case CouponType.Bonus:
        return t('coupon.bonus') + ' · ' + it.bonus_percent + '%'
    }
    return ''
  }

  const getItemCells = (coupon: CouponStat) => {
    return (
      <div className="flex items-center gap-3">
        <div className="flex size-[38px] items-center justify-center rounded-md bg-fg-secondary">
          <CouponOutline />
        </div>
        <div className="flex max-w-[80%] flex-col gap-1  text-paragraph-sm">
          <span className="truncate text-text-primary">{coupon.name}</span>
          <span className="truncate text-text-secondary">{getBenefits(coupon)}</span>
        </div>
      </div>
    )
  }

  const total = data?.length ? data.filter(it => !!it.count).reduce((acc, item) => acc + item.count!, 0) : 0

  return (
    <DashboardCard
      title={t('dashboard.sales.top-5-codes')}
      data-testid="dashboard/chart/top5-codes"
      style={{
        ...props.style,
        gap: '8px',
      }}
    >
      {isLoading ? (
        <LoaderContainer style={{ height: '460px' }}>
          <Loader />
        </LoaderContainer>
      ) : (
        <>
          <div
            data-testid="dashboard/chart/top5-codes/total"
            className="flex h-[52px] items-center text-title-t3 font-semibold text-text-secondary"
          >
            {t('dashboard.total')} {formatNumber(total)}
          </div>
          {data.length > 0 ? (
            <div style={{ maxHeight: '384px' }}>
              {data.map((item, index) => (
                <Fragment key={item.id}>
                  <div className="flex h-[66px] items-center">
                    <div className="w-full truncate">{getItemCells(item)}</div>
                    <div className="text-right">
                      <div className="text-text-primary" data-testid={`dashboard/chart/top5-codes/${item.id}/count`}>
                        {formatNumber(item.count)}
                      </div>
                    </div>
                  </div>
                  {index < data.length - 1 && <Separator />}
                </Fragment>
              ))}
            </div>
          ) : (
            <ZeroState style={{ height: '384px' }} />
          )}
        </>
      )}
    </DashboardCard>
  )
}
