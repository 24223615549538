import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const Save: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 15 15" ref={ref}>
      <path
        d="M9.375 5H5.375C5.02497 5 4.84995 5 4.71626 4.93188C4.59865 4.87196 4.50304 4.77635 4.44312 4.65874C4.375 4.52505 4.375 4.35003 4.375 4V1.875M10.625 13.125V9.125C10.625 8.77497 10.625 8.59995 10.5569 8.46626C10.497 8.34865 10.4013 8.25304 10.2837 8.19312C10.15 8.125 9.97503 8.125 9.625 8.125H5.375C5.02497 8.125 4.84995 8.125 4.71626 8.19312C4.59865 8.25304 4.50304 8.34865 4.44312 8.46626C4.375 8.59995 4.375 8.77497 4.375 9.125V13.125M13.125 5.82843V10.125C13.125 11.1751 13.125 11.7001 12.9206 12.1012C12.7409 12.454 12.454 12.7409 12.1012 12.9206C11.7001 13.125 11.1751 13.125 10.125 13.125H4.875C3.8249 13.125 3.29985 13.125 2.89877 12.9206C2.54596 12.7409 2.25913 12.454 2.07936 12.1012C1.875 11.7001 1.875 11.1751 1.875 10.125V4.875C1.875 3.8249 1.875 3.29985 2.07936 2.89877C2.25913 2.54596 2.54596 2.25913 2.89877 2.07936C3.29985 1.875 3.8249 1.875 4.875 1.875H9.17157C9.47731 1.875 9.63018 1.875 9.77404 1.90954C9.90159 1.94016 10.0235 1.99066 10.1354 2.0592C10.2615 2.1365 10.3696 2.2446 10.5858 2.46079L12.5392 4.41421C12.7554 4.6304 12.8635 4.7385 12.9408 4.86464C13.0093 4.97648 13.0598 5.09842 13.0905 5.22596C13.125 5.36982 13.125 5.52269 13.125 5.82843Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconBase>
  );
});
