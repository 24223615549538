import { EventActionNodeType, NodeResult } from '@/layouts/campaigns/types'
import i18next from 'i18next'
import dayjs from 'dayjs'
import { Node } from '@/api/dashboard'

export function getHistoryTooltipMessage(node: Node) {
  let message = undefined

  switch (node.model_type) {
    case EventActionNodeType.RemoveItemsStoreNode:
      message = node.result
      break
    case EventActionNodeType.AddStoreItemsNode:
      if (node.result) {
        message = 'Added'
      }
      break
    case EventActionNodeType.AddStoreNode:
      message = node.result + ' added'
      break
    case EventActionNodeType.ConditionNode:
      message = node.result
      break
    case EventActionNodeType.SplitNode:
      if (node.random_value) {
        message = i18next.t('campaign.node_result.random_value', { value: node.random_value })
      } else {
        if (node.result != undefined) {
          message = node.result == '0' ? 'Left' : 'Right'
        }
      }
      break
    case EventActionNodeType.TimerNode:
      if (node.start_wait) {
        let nodes: React.ReactNode[] = [
          <span key={node.id + 'start'}>
            {i18next.t('campaign.node_result.timer.start', {
              value: dayjs(node.start_wait * 1000).format('MM/DD/YYYY hh:mm:ss A'),
            })}
          </span>,
        ]

        if (node.end_wait) {
          nodes.push(
            <span key={node.id + 'end'}>
              {i18next.t('campaign.node_result.timer.end', {
                value: dayjs(node.end_wait * 1000).format('MM/DD/YYYY hh:mm:ss A'),
              })}
            </span>,
          )
        } else {
          nodes.push(<span key={node.id + 'wait'}>{i18next.t('campaign.node_result.timer_wait')}</span>)
        }
        message = (
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              flexDirection: 'column',
            }}
          >
            {nodes.map(it => it)}
          </div>
        )
      } else if (node.result) {
        message = i18next.t('campaign.node_result.timer_done')
      }
      break
    case EventActionNodeType.WebhookActionNode:
    case EventActionNodeType.EmailActionNode:
    case EventActionNodeType.MobilePushActionNode:
    case EventActionNodeType.BrowserPushNotificationActionNode:
    case EventActionNodeType.MobilePopupActionNode:
    case EventActionNodeType.BrowserPopupNotificationActionNode:
      if (node.result) {
        message = i18next.t(`campaign.node_result.${node.result}`)
      }
      break
    case EventActionNodeType.ItemDiscountOfferActionNode:
    case EventActionNodeType.ItemBonusOfferActionNode:
    case EventActionNodeType.CreateItemBannerActionNode:
    case EventActionNodeType.ItemOfferActionNode:
    case EventActionNodeType.CreateVirtualSKUNode:
    case EventActionNodeType.CreateCouponNode:
    case EventActionNodeType.CreateUserStoreSettingsNode:
      switch (node.result) {
        case NodeResult.ITEM_NOT_FOUND:
        case NodeResult.CONTEXT_ITEM_NOT_FOUND:
        case NodeResult.OFFER_NOT_FOUND:
        case NodeResult.COUPON_NOT_FOUND:
        case NodeResult.PREV_NODE_NOT_FOUND:
        case NodeResult.PREV_NODE_UNSUPPORTED_TYPE:
          break
        default:
          if (node.result) {
            message = i18next.t('campaign.node_result.created')
          }
          break
      }

      break
    case EventActionNodeType.AddToSegmentNode:
      message = node.result
      break
  }

  return message
}
