import { ComponentProps, createElement } from 'react'
import { AghanimIcon, Menu, MenuItem } from '@dashboard/ui'
import { FloatingMenuProps } from '@/ui'

interface PageSidebarMenuProps extends FloatingMenuProps {
  options: {
    variant?: ComponentProps<typeof MenuItem>['variant']
    icon?: AghanimIcon
    text: string
    onClick: () => void
  }[]
}

export const PageSidebarMenu = ({ options, onClose }: PageSidebarMenuProps) => (
  <Menu>
    {options.map(({ variant, icon, text, onClick }, index) => (
      <MenuItem
        key={index}
        variant={variant}
        onClick={e => {
          e.stopPropagation()
          e.preventDefault()
          onClick()
          onClose?.()
        }}
      >
        <div className="inline-flex items-center gap-3">
          {icon && createElement(icon)}
          <span>{text}</span>
        </div>
      </MenuItem>
    ))}
  </Menu>
)
