import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '../IconBase'

export const Launch: AghanimIcon = forwardRef(function (props, ref) {
  return (
    <IconBase {...props} viewBox="0 0 16 16" ref={ref}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.75454 1.25477C10.0168 1.40506 10.1451 1.713 10.0672 2.00506L8.90366 6.36569C8.89148 6.41133 8.88539 6.43416 8.89074 6.45217C8.89543 6.46795 8.90579 6.48144 8.91983 6.49004C8.93585 6.49985 8.95948 6.49985 9.00672 6.49985L13.212 6.49985C13.3853 6.49983 13.5553 6.4998 13.6932 6.5115C13.8215 6.52239 14.0605 6.55031 14.2703 6.70909C14.5165 6.89555 14.6718 7.19054 14.6665 7.51561C14.662 7.79722 14.5161 8.00744 14.4418 8.10821C14.361 8.21788 14.25 8.3426 14.1402 8.46601L14.1239 8.48434C14.1161 8.49315 14.108 8.50176 14.0997 8.51014L7.05086 15.6354C6.83828 15.8503 6.50771 15.8952 6.24545 15.7449C5.98319 15.5946 5.85486 15.2867 5.93279 14.9947L7.09631 10.634C7.10849 10.5884 7.11458 10.5656 7.10923 10.5475C7.10454 10.5318 7.09418 10.5183 7.08014 10.5097C7.06411 10.4999 7.04049 10.4999 6.99325 10.4999L2.78794 10.4999C2.61465 10.4999 2.44463 10.4999 2.30677 10.4882C2.17842 10.4773 1.93942 10.4494 1.72971 10.2906C1.48343 10.1042 1.32821 9.80916 1.33344 9.4841C1.33797 9.20249 1.48385 8.99227 1.55813 8.8915C1.63898 8.78182 1.74995 8.6571 1.85977 8.53368C1.8652 8.52757 1.87063 8.52147 1.87606 8.51537C1.8839 8.50655 1.89197 8.49795 1.90026 8.48956L8.94912 1.36433C9.16171 1.14944 9.49227 1.10447 9.75454 1.25477ZM3.1061 9.16652H7.99998C8.20712 9.16652 8.40249 9.2628 8.52867 9.42707C8.65485 9.59134 8.69751 9.80492 8.64411 10.0051L7.86809 12.9134L12.8939 7.83319H7.99998C7.79284 7.83319 7.59747 7.73691 7.47129 7.57264C7.34511 7.40837 7.30245 7.19478 7.35585 6.99465L8.13188 4.08629L3.1061 9.16652Z"
        fill="currentColor"
      />
    </IconBase>
  )
})
