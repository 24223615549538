import { Button, ImageIcon } from '@dashboard/ui'

interface PageCoverProps {
  image_url: string
  onChange?: () => void
}

export const PageCover = ({ image_url, onChange }: PageCoverProps) => (
  <div className="relative max-h-52 w-full overflow-hidden rounded-xl">
    <img className="size-full object-cover" src={image_url} alt="" />
    {onChange && (
      <div className="absolute right-3 top-3 rounded-md bg-fg-tertiary-hover/50">
        <Button variant="tertiary" color="secondary" onClick={onChange}>
          <ImageIcon />
        </Button>
      </div>
    )}
  </div>
)
