import { Link, generatePath, useParams } from 'react-router-dom'
import i18next from 'i18next'
import { Pagination, Table, TableCell, TableRow, TableRowSkeleton, TableZeroState, usePaginationMemory } from '@/ui'
import { usePlayerClaimedItemsQuery } from '@/layouts/player/api/usePlayerClaimedItemsQuery'
import { ItemRead, UserItemClaimed } from '@/api/dashboard'
import { SKU_ITEMS_DETAILS_PATH } from '@/libs'
import { SkuImageName } from '@/layouts/game-items/components/SkuImageName'
import { DateTimeValue } from '@/components/ui/DateTimeValue'
import { useCurrentUser } from '@/api/useCurrentUser'

export default function UserClaimedItemsTable(props: { userId: string }) {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const { canEdit } = useCurrentUser()

  const { page, query, onPageChange, needShowPagination } = usePaginationMemory(10)
  const { data: items = [], isLoading } = usePlayerClaimedItemsQuery(companyId, gameId, props.userId, query)

  const getItemInfo = (claimed: UserItemClaimed) => {
    const content = (
      <div className="flex items-center gap-3">
        <SkuImageName
          item={
            {
              id: claimed.item_id,
              name: claimed.item_name?.['en'],
              is_stackable: claimed.item_is_stackable,
              sku: claimed.item_sku,
              image_url: claimed.item_image_url,
            } as ItemRead
          }
        />
      </div>
    )

    if (!canEdit) {
      return content
    }

    return (
      <Link
        to={generatePath(SKU_ITEMS_DETAILS_PATH, { companyId: companyId, gameId: gameId, itemId: claimed.item_id })}
      >
        {content}
      </Link>
    )
  }

  if (!isLoading && !items.length && page == 1) {
    return (
      <TableZeroState
        className="mt-4 h-[147px]"
        title={i18next.t('claimed.no-items')}
        message={i18next.t('claimed.no-items.desc')}
      />
    )
  }

  if (isLoading) {
    return <TableRowSkeleton rowCount={3} columnCount={4} />
  }

  return (
    <>
      <Table>
        <TableRow variant="header">
          <TableCell width="200%">{i18next.t('claimed.item')}</TableCell>
          <TableCell>{i18next.t('claimed.type')}</TableCell>
          <TableCell>{i18next.t('claimed.created_at')}</TableCell>
        </TableRow>

        {items.map(it => (
          <TableRow key={it.created_at + '_' + it.item_id}>
            <TableCell width="200%">{getItemInfo(it)}</TableCell>
            <TableCell>{i18next.t(`claimed.type.${it.object_type}`)}</TableCell>
            <TableCell>
              <DateTimeValue value={it.created_at} />
            </TableCell>
          </TableRow>
        ))}
      </Table>

      {needShowPagination(isLoading, items) && (
        <Pagination
          style={{
            position: 'relative',
          }}
          hasNext={items.length >= query.limit}
          page={page}
          onPageChange={p => onPageChange(p)}
          pageItems={items.length}
        />
      )}
    </>
  )
}
