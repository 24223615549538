import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import { FieldGroup, Input, InputExtraSection, PropertyCheckboxControl } from '@/ui'
import { FormSection } from '../../components'
import { SkuFormData } from './SkuForm'

export const SkuFormPropertiesSection = () => {
  const { t } = useTranslation()
  const {
    formState: { errors },
    register,
    control,
    setValue,
    watch,
  } = useFormContext<SkuFormData>()

  const [name, quantity, is_currency] = watch(['name', 'quantity', 'is_currency'])

  const renderQuantityInput = () => (
    <FieldGroup label={t('game-item-edit-dialog.quantity')}>
      <Input
        {...register('quantity', {
          required: t('validation.required'),
          min: { value: 1, message: t('validation.min', { field: t('game-item-edit-dialog.quantity'), value: 1 }) },
          max: {
            value: 1_000_000_000,
            message: t('validation.max', { field: t('game-item-edit-dialog.quantity'), value: 1_000_000_000 }),
          },
        })}
        type="number"
        extraRight={
          name &&
          quantity &&
          quantity > 0 && (
            <InputExtraSection side="right">
              <span className="truncate leading-normal text-text-tertiary">
                {t('intl.Number', { val: quantity })} {name}
              </span>
            </InputExtraSection>
          )
        }
        errors={errors}
      />
    </FieldGroup>
  )

  return (
    <FormSection label={t('game-item-edit-dialog.type')}>
      <div className="grid grid-cols-2 gap-5">
        <Controller
          control={control}
          name="is_currency"
          render={({ field }) => (
            <PropertyCheckboxControl
              {...field}
              label={t('sku.item.is_currency')}
              desc={t('game-item-edit-dialog.type.is_currency.desc')}
              onChange={value => {
                field.onChange(value)
                // @ts-ignore
                setValue('is_stackable', value, { shouldDirty: true })
                if (!value) {
                  setValue('quantity', 1, { shouldDirty: true })
                }
              }}
            >
              {renderQuantityInput()}
            </PropertyCheckboxControl>
          )}
        />

        <Controller
          control={control}
          name="is_stackable"
          render={({ field }) => (
            <PropertyCheckboxControl
              {...field}
              label={t('sku.item.is_stackable')}
              desc={t('game-item-edit-dialog.type.is_stackable.desc')}
              disabled={is_currency}
              onChange={value => {
                field.onChange(value)
                if (!value) {
                  setValue('quantity', 1, { shouldDirty: true })
                }
              }}
            >
              {renderQuantityInput()}
            </PropertyCheckboxControl>
          )}
        />
      </div>
    </FormSection>
  )
}
