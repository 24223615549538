import { useTranslation } from 'react-i18next'

import { SKU_ITEMS_SETTINGS_CATEGORIES_PATH, SKU_ITEMS_SETTINGS_PROPERTIES_PATH, useGenerateCompanyPath } from '@/libs'

import { SidebarSection } from '@/components/ui/AppSidebar/SidebarSection'
import { SidebarLink } from '@/components/ui/AppSidebar/SidebarLink'
import { SidebarGameButton } from '@/components/ui/AppSidebar/SidebarGameButton'
import { useCurrentGame } from '@/api/useCompaniesQuery'
import UserInfo from '@/layouts/sku-settings/components/UserInfo'
import { ItemCategory } from '@/layouts/sku-settings/icons/ItemCategory'
import { ItemProperty } from '@/layouts/sku-settings/icons/ItemProperty'

export const SkuSettingsSidebar = () => {
  const { t } = useTranslation()
  const { game } = useCurrentGame()
  const { generatePath } = useGenerateCompanyPath()

  return (
    <div className="flex h-full w-[280px] flex-col overflow-y-auto rounded-xl border border-border-primary py-3">
      <div className="mx-3 mb-3 flex items-center">
        <SidebarGameButton game={game} variant="disabled" />
      </div>

      <SidebarSection>
        <SidebarLink to={generatePath(SKU_ITEMS_SETTINGS_CATEGORIES_PATH)} end={true} icon={ItemCategory}>
          {t('sidebar.categories')}
        </SidebarLink>

        <SidebarLink to={generatePath(SKU_ITEMS_SETTINGS_PROPERTIES_PATH)} end={true} icon={ItemProperty}>
          {t('sidebar.item-properties')}
        </SidebarLink>
      </SidebarSection>

      <UserInfo className="mx-3 mt-auto" />
    </div>
  )
}
