import { ReactNode } from 'react';

interface ModalFooterProps {
  children: ReactNode;
  extra?: ReactNode;
}

/**
 * Modal footer component.
 */
export const ModalFooter = ({ children, extra }: ModalFooterProps) => (
  <div className="flex items-center justify-end gap-3 border-t border-border-secondary px-6 pb-6 pt-[18px]">
    {extra && <div className="mr-auto">{extra}</div>}
    {children}
  </div>
);
