import { createElement } from 'react';
import { MenuItemBaseProps } from '../Menu';

export interface SelectBaseValueProps {
  item: MenuItemBaseProps;
}

/**
 * Base rendering of the value for the selector button.
 * Uses the CSS --colors from SelectBaseButton.
 */
export const SelectBaseValue = ({ item }: SelectBaseValueProps) => (
  <>
    {item.icon && (
      <div className="-ml-1 flex h-[18px] min-w-[18px] shrink-0 items-center justify-center text-[--icon-color]">
        {createElement(item.icon, { size: 18 })}
      </div>
    )}
    <span className="truncate text-[--label-color]">{item.children}</span>
    {item.extra && (
      <div className="ml-auto flex h-[18px] min-w-[18px] shrink-0 items-center justify-center text-[--extra-color] transition-colors">
        {createElement(item.extra, { size: 18 })}
      </div>
    )}
  </>
);
