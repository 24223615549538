import { ReactNode } from 'react'

interface FormSectionProps {
  children: ReactNode
  label?: ReactNode
  action?: ReactNode
}

export const FormSection = ({ children, label, action }: FormSectionProps) => (
  <div className="mb-2.5 flex flex-col rounded-2xl1 border border-border-primary bg-fg-primary p-6 shadow-xs last:mb-0">
    {label && (
      <div className="mb-6 flex items-center text-t4">
        {label}
        <div className="ml-auto">{action}</div>
      </div>
    )}
    {children}
  </div>
)
