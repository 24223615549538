import { Badge, Tooltip } from '@/ui'
import { Check } from '@/icons'
import { useTranslation } from 'react-i18next'
import { SlashCircle02, cn } from '@dashboard/ui'
import { UserRead } from '@/api/dashboard'
import i18next from 'i18next'

interface PlayerStatusProps {
  user: UserRead
  className?: string
}

export const PlayerStatus = ({ user, className }: PlayerStatusProps) => {
  const { t } = useTranslation()
  const isBanned = user.banned
  const content = (
    <Badge variant={isBanned ? 'red-secondary' : 'gray-secondary'} className={cn('whitespace-nowrap', className)}>
      <span>{isBanned ? t('players.banned') : t('no')}</span>
      {isBanned ? <SlashCircle02 size={16} /> : <Check size={16} />}
    </Badge>
  )

  if (isBanned && user.banned_reason) {
    const key = `player.ban.reason.${user.banned_reason}`
    return (
      <Tooltip message={i18next.exists(key) ? t(key) : user.banned_reason}>
        <div className={className}>{content}</div>
      </Tooltip>
    )
  }

  return content
}
