import i18next from 'i18next'
import { useParams } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { PageLayoutType, PageRead } from '../../../api/dashboard'
import { Button, FieldGroup, FieldValidationMessage, Modal, ModalFooter, ModalProps, ModalTitle, Select } from '@/ui'
import { usePagesQuery } from '../api/usePagesQuery'
import { usePagesBulkUpdateMutate } from '../api/usePagesBulkUpdateMutate'

export interface PagesMoveModalData {
  items: PageRead[]
  collection?: PageRead
  onConfirm?: () => void
}

interface PagesMoveFormData {
  parent_id: string | undefined
}

interface PagesMoveModalProps extends ModalProps, PagesMoveModalData {}

export const PagesMoveModal = ({ items, collection, onConfirm, ...rest }: PagesMoveModalProps) => {
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string; parent?: string }
  const { mutateAsync } = usePagesBulkUpdateMutate(companyId, gameId)
  const { data: collections = [] } = usePagesQuery(companyId, gameId, {
    ignore_parent_id: true,
    limit: 100,
    types: [PageLayoutType.Collection],
  })

  const {
    formState: { isSubmitting, errors },
    control,
    handleSubmit,
  } = useForm<PagesMoveFormData>({
    defaultValues: {
      parent_id: collection?.id,
    },
  })

  const onSubmit = async (data: PagesMoveFormData) => {
    await mutateAsync({ data: items.map(item => ({ id: item.id, parent_id: data.parent_id })) })
    onConfirm?.()
    rest.onClose?.()
  }

  return (
    <Modal {...rest}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalTitle>
          {collection
            ? i18next.t('pages.bulk-move.title.from-collection', { count: items.length, collection: collection.title })
            : i18next.t('pages.bulk-move.title.from-uncategorized', { count: items.length })}
        </ModalTitle>
        <FieldGroup>
          <p className="text-paragraph-md text-text-secondary">
            {collection
              ? i18next.t('pages.bulk-move.message.from-collection', {
                  count: items.length,
                  collection: collection.title,
                })
              : i18next.t('pages.bulk-move.message.from-uncategorized', {
                  count: items.length,
                })}
          </p>
        </FieldGroup>
        <FieldGroup label={i18next.t('pages.edit.parent')}>
          <Controller
            control={control}
            name="parent_id"
            render={({ field }) => (
              <Select
                {...field}
                options={[
                  { value: null, children: i18next.t('pages.edit.uncategorized') },
                  ...collections.map(collection => ({ children: collection.title, value: collection.id })),
                ]}
              />
            )}
          />
          <ErrorMessage
            name="parent_id"
            errors={errors}
            render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
          />
        </FieldGroup>
        <ModalFooter>
          <Button type="button" size="lg" onClick={rest.onClose}>
            {i18next.t('pages.bulk-move.cancel')}
          </Button>
          <Button type="submit" variant="primary" size="lg" isLoading={isSubmitting}>
            {i18next.t('pages.bulk-move.move')}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}
