import { PeriodType } from '@/api/dashboard'
import { CSSProperties, useState } from 'react'
import { FieldGroup } from '@/ui'
import { useTranslation } from 'react-i18next'
import { Input } from '@dashboard/ui'

export const getSecondsByType = (type: PeriodType, v: number) => {
  if (!v) {
    return 0
  }

  switch (type) {
    case PeriodType.Day:
      return Math.min(v * 60 * 60 * 24, 100 * 60 * 60 * 24)
    case PeriodType.Hour:
      return Math.min(v * 60 * 60, 23 * 60 * 60)
    case PeriodType.Minute:
      return Math.min(v * 60, 59 * 60)
    case PeriodType.Second:
      return Math.min(v, 59)
  }
  return 0
}

export default function DurationSelectorDHMS(props: {
  duration: number
  onChange: (value: number) => void
  margin?: boolean
  label?: string
  caption?: string
  size?: 'small' | 'middle'
  style?: CSSProperties
}) {
  const { t } = useTranslation()
  const [empty, setEmpty] = useState({
    days: false,
    hours: false,
    minutes: false,
    seconds: false,
  })

  let days = Math.floor(props.duration / (60 * 60 * 24))
  let hours = Math.floor((props.duration % (60 * 60 * 24)) / 60 / 60)
  let minutes = Math.floor((props.duration % (60 * 60)) / 60)
  let seconds = props.duration % 60

  const renderLeftLabel = (lbl: string) => {
    return <div className="flex h-full items-center border-r border-border-secondary pr-3"> {lbl} </div>
  }

  const calcNewValue = (num: number, type: PeriodType) => {
    let parts = [PeriodType.Day, PeriodType.Hour, PeriodType.Minute, PeriodType.Second]
    let v = 0

    for (let period of parts) {
      let currentValue = 0
      switch (period) {
        case PeriodType.Day:
          currentValue = days
          break
        case PeriodType.Hour:
          currentValue = hours
          break
        case PeriodType.Minute:
          currentValue = minutes
          break
        case PeriodType.Second:
          currentValue = seconds
          break
      }

      v += getSecondsByType(period, type == period ? num : currentValue)
    }
    return v
  }

  const setEmptyByType = (type: PeriodType, v: boolean) => {
    switch (type) {
      case PeriodType.Day:
        setEmpty({ ...empty, days: v })
        break
      case PeriodType.Hour:
        setEmpty({ ...empty, hours: v })
        break
      case PeriodType.Minute:
        setEmpty({ ...empty, minutes: v })
        break
      case PeriodType.Second:
        setEmpty({ ...empty, seconds: v })
        break
    }
  }

  const onBlur = (v: string, type: PeriodType) => {
    if (!v) {
      setEmptyByType(type, false)
      props.onChange(calcNewValue(0, type))
    }
  }

  return (
    <div style={props.style}>
      <FieldGroup label={props.label} caption={props.caption}>
        <div className="flex justify-around gap-3">
          <Input
            max={100}
            extraLeft={{
              icon: renderLeftLabel(props.size == 'small' ? 'D' : t('days')),
              pointer: true,
              width: props.size == 'small' ? undefined : 70,
            }}
            type={'number'}
            value={empty.days ? '' : days}
            onKeyDown={e => {
              if (e.key == '-' || e.key == '+') {
                e.preventDefault()
              }
            }}
            onBlur={e => onBlur(e.target.value, PeriodType.Day)}
            onChange={e => {
              if (!e.target.value) {
                setEmpty({ ...empty, days: true })
                return
              }
              setEmpty({ ...empty, days: false })
              let v = parseInt(e.target.value)
              if (v >= 0) {
                if (v > 100) {
                  v = 100
                }
                props.onChange(
                  getSecondsByType(PeriodType.Day, v) +
                    getSecondsByType(PeriodType.Hour, hours) +
                    getSecondsByType(PeriodType.Minute, minutes) +
                    getSecondsByType(PeriodType.Second, seconds),
                )
              }
            }}
          />

          <Input
            extraLeft={{
              icon: renderLeftLabel(props.size == 'small' ? 'H' : t('hours')),
              pointer: true,
              width: props.size == 'small' ? undefined : 70,
            }}
            type={'number'}
            value={empty.hours ? '' : hours}
            onKeyDown={e => {
              if (e.key == '-' || e.key == '+') {
                e.preventDefault()
              }
            }}
            onBlur={e => onBlur(e.target.value, PeriodType.Hour)}
            onChange={e => {
              if (!e.target.value) {
                setEmpty({ ...empty, hours: true })
                return
              }
              let v = parseInt(e.target.value)
              setEmpty({ ...empty, hours: false })
              if (v >= 0) {
                props.onChange(
                  getSecondsByType(PeriodType.Day, days) +
                    getSecondsByType(PeriodType.Hour, v) +
                    getSecondsByType(PeriodType.Minute, minutes) +
                    getSecondsByType(PeriodType.Second, seconds),
                )
              }
            }}
          />

          <Input
            extraLeft={{
              icon: renderLeftLabel(props.size == 'small' ? 'M' : t('minutes')),
              width: props.size == 'small' ? undefined : 70,
              pointer: true,
            }}
            type={'number'}
            value={empty.minutes ? '' : minutes}
            onBlur={e => onBlur(e.target.value, PeriodType.Minute)}
            onKeyDown={e => {
              if (e.key == '-' || e.key == '+') {
                e.preventDefault()
              }
            }}
            onChange={e => {
              if (!e.target.value) {
                setEmpty({ ...empty, minutes: true })
                return
              }
              let v = parseInt(e.target.value)
              setEmpty({ ...empty, minutes: false })
              if (v >= 0) {
                props.onChange(
                  getSecondsByType(PeriodType.Day, days) +
                    getSecondsByType(PeriodType.Hour, hours) +
                    getSecondsByType(PeriodType.Minute, v) +
                    getSecondsByType(PeriodType.Second, seconds),
                )
              }
            }}
          />

          <Input
            extraLeft={{
              icon: renderLeftLabel(props.size == 'small' ? 'S' : t('seconds')),
              pointer: true,
              width: props.size == 'small' ? undefined : 70,
            }}
            onKeyDown={e => {
              if (e.key == '-' || e.key == '+') {
                e.preventDefault()
              }
            }}
            type={'number'}
            onBlur={e => onBlur(e.target.value, PeriodType.Second)}
            value={empty.seconds ? '' : seconds}
            onChange={e => {
              if (!e.target.value) {
                setEmpty({ ...empty, seconds: true })
                return
              }
              let v = parseInt(e.target.value)
              setEmpty({ ...empty, seconds: false })
              if (v >= 0) {
                props.onChange(
                  getSecondsByType(PeriodType.Day, days) +
                    getSecondsByType(PeriodType.Hour, hours) +
                    getSecondsByType(PeriodType.Minute, minutes) +
                    getSecondsByType(PeriodType.Second, v),
                )
              }
            }}
          />
        </div>
      </FieldGroup>
    </div>
  )
}
