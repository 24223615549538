import { useParams } from 'react-router-dom'
import { PageLayoutType, PageRead } from '@/api/dashboard'
import { useQueries } from '@tanstack/react-query'
import { pageQueryOptions } from '@/layouts/pages/api/usePagesQuery'

export const useSubcollectionsMap = (parentIDs: string[]) => {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }

  const queries = useQueries({
    queries: parentIDs.map(id =>
      pageQueryOptions(companyId, gameId, {
        parent_id: id,
        limit: 100,
        types: [PageLayoutType.Collection],
      }),
    ),
  })

  return {
    data: queries.reduce(
      (acc, { data }, index) => {
        const id = parentIDs[index]
        acc[id] = data || []
        return acc
      },
      {} as Record<string, PageRead[]>,
    ),
    refetch: async () => {
      await Promise.all(queries.map(query => query.refetch()))
    },
  }
}
