import { forwardRef, useCallback } from 'react';
import { Calendar } from '../Calendar';
import { PopperMenuProps } from '../Popper';
import { Menu } from '../Menu';

interface InputDateMenuProps extends PopperMenuProps {
  value: Date | null;
  onChange: (value: Date | null) => void;
}

export const InputDateMenu = forwardRef<HTMLDivElement, InputDateMenuProps>(({ value, onChange, ...rest }, ref) => {
  const onChangeCalendar = useCallback(
    (value: Date | undefined) => {
      onChange(value || null);
      if (value) {
        rest.onClose?.();
      }
    },
    [onChange, rest],
  );

  return (
    <Menu {...rest} ref={ref} maxHeight="none">
      <Calendar
        mode="single"
        required={false}
        defaultMonth={value || undefined}
        selected={value || undefined}
        onSelect={onChangeCalendar}
      />
    </Menu>
  );
});

InputDateMenu.displayName = 'InputDateMenu';
