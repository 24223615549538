import { SegmentRead } from '@/api/dashboard'

export default function SegmentBadge(props: { segment: SegmentRead }) {
  const it = props.segment

  return (
    <div
      className="inline-flex h-[24px] items-center gap-2 whitespace-nowrap rounded-md border border-border-primary bg-fg-primary-alt px-2 text-paragraph-sm text-text-secondary"
      style={{
        boxShadow: '0px 1px 2px 0px #1018280D',
      }}
    >
      <div
        className="size-3 rounded-full"
        style={{
          backgroundColor: it.color,
        }}
      />
      {it.name}
    </div>
  )
}
