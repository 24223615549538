import { Button } from '../Button'
import { PlusContained, Trash } from '@/icons'
import { useDropzone } from 'react-dropzone'
import { useCallback, useRef, useState } from 'react'
import { fetchUploadImage } from '../../../api/files'
import { S3Bucket } from '../../../types'
import { Spinner } from '../Spinner'

interface ImageListProps {
  urls: string[]
  onDelete?: (url: string) => void
  onAdd?: (url: string[]) => void
}

export const ImageList = (props: ImageListProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const [isLoading, setIsLoading] = useState(false)

  const onUpload = useCallback(
    async (acceptedFiles: File[]) => {
      setIsLoading(true)
      const promises = []
      for (let file of acceptedFiles) {
        promises.push(fetchUploadImage(file, { bucket: S3Bucket.hub }))
      }

      let result = await Promise.all(promises)
      props.onAdd?.(result.map(it => it.get_url))
      setIsLoading(false)
    },
    [props.onAdd],
  )

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': [],
      'image/webp': [],
    },
    onDrop: props.onAdd ? onUpload : () => {},
    maxFiles: 10,
  })

  return (
    <div className="flex flex-col gap-1.5">
      {props.urls.map(it => (
        <div
          key={it}
          className="relative flex w-full items-center justify-center rounded-md border-2 border-border-primary bg-fg-secondary p-1.5"
        >
          <div
            className="size-[70px] rounded-[4px] bg-cover bg-center bg-no-repeat"
            style={{
              backgroundImage: `url(${it})`,
            }}
          />
          {props.onDelete && (
            <Button
              variant="tertiary-gray"
              size="md"
              style={{
                position: 'absolute',
              }}
              className="right-[6px]"
              onClick={() => props.onDelete?.(it)}
            >
              <Trash />
            </Button>
          )}
        </div>
      ))}

      {props.onAdd && (
        <div
          {...getRootProps({ ref })}
          className="flex h-[72px] cursor-pointer items-center justify-center rounded-xl border-2 border-dashed border-border-primary hover:bg-fg-secondary-hover"
        >
          {isLoading ? <Spinner /> : <PlusContained color="#CBD5E1" />}
          <input {...getInputProps()} />
        </div>
      )}
    </div>
  )
}
