import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Skeleton } from '@dashboard/ui'
import { Button, ButtonIcon, Floating } from '@/ui'
import { Copy03, DotsHorizontal, FlipBackward } from '@/icons'
import { useNavigateBack } from '../../../libs/hooks'
import { GAME_WEBHOOKS_PATH } from '../../../libs/routerPaths'
import { WebhookRedacted } from '../../../api/dashboard'
import { WebhookMoreMenu } from './WebhookMoreMenu'
import { AuthContext, IAuthContext } from '@/Context'
import { isAdmin } from '@/security'

interface WebhookTitleBarProps {
  webhook: WebhookRedacted
}

export const WebhookTitleBar = ({ webhook }: WebhookTitleBarProps) => {
  const back = useNavigateBack({ fallback: GAME_WEBHOOKS_PATH })
  const context = useContext(AuthContext) as IAuthContext
  const { t } = useTranslation()
  return (
    <div className="mb-8 mt-3 flex gap-3">
      <Link {...back}>
        <ButtonIcon variant="secondary-gray" size="sm">
          <FlipBackward />
        </ButtonIcon>
      </Link>
      <div className="overflow-hidden">
        <div className="mb-0.5 text-caption-xs leading-none text-text-tertiary">{t('webhook.back')}</div>
        <div className="w-full truncate text-t5">{webhook.url}</div>
      </div>
      <div className="ml-auto flex items-center gap-3">
        <Button onClick={() => navigator.clipboard.writeText(webhook.id + '')} color="secondary" variant="outline">
          {webhook.id}
          <Copy03 />
        </Button>
        {isAdmin(context.customer) && (
          <Floating menu={<WebhookMoreMenu webhook={webhook} />} placement="bottom-end">
            <ButtonIcon variant="secondary-gray" size="sm">
              <DotsHorizontal />
            </ButtonIcon>
          </Floating>
        )}
      </div>
    </div>
  )
}

export const WebhookTitleBarSkeleton = () => (
  <div className="mb-8 mt-3 flex gap-3">
    <Skeleton className="size-8" />
    <div>
      <Skeleton className="mb-0.5 h-2.5 w-24" />
      <Skeleton className="h-[22px] w-64" />
    </div>
  </div>
)
