import i18next from 'i18next'
import StyledTextField from '../../../components/StyledTextField'
import { SplitNodeEditor } from './SplitNodeEditor'
import { TimerNodeEditor } from './TimerEditor'
import ItemOfferEditor from './ItemOfferEditor'
import EmailNodeEditor from './EmailNodeEditor'
import { MobileActionEditor } from './MobileActionEditor'
import CreateBannerEditor from './CreateBannerEditor'
import SettingsHeader from '../SettingsHeader'
import ErrorChip from '../../../components/ErrorChip'
import HubPopupNodeEditor from './HubPopupNodeEditor'
import AddToSegmentEditor from './AddToSegmentEditor'
import {
  AddToSegmentNode,
  BrowserPopupNotificationActionNode,
  BrowserPushNotificationActionNode,
  CreateCouponNode,
  CreateItemBannerActionNode,
  CreateUserStoreSettingsNode,
  CreateVirtualSKUNode,
  EmailActionNode,
  ItemOfferActionNode,
  Node,
  SplitNode,
  TimerNode,
  WebhookActionNode,
} from '../../../api/dashboard'
import { BlockError, EventActionNodeType } from '../types'
import CreateCouponEditor from './CreateCouponEditor'
import BrowserPushEditor from './BrowserPushEditor'
import VirtualSKUEditor from '@/layouts/campaigns/editors/VirtualSKUEditor'
import InitStoreSettingsEditor from '@/layouts/campaigns/editors/InitStoreSettingsEditor'

export const WebhookNodeEditor = (props: {
  error?: BlockError | null
  node: WebhookActionNode
  setNode: (node: Node) => void
}) => {
  return (
    <>
      <StyledTextField
        label={i18next.t('campaign.block.description')}
        value={props.node.description || ''}
        type={'text'}
        multiline
        rows={3}
        onChange={e => {
          props.setNode({
            ...props.node,
            description: e.target.value,
            model_type: EventActionNodeType.WebhookActionNode,
          })
        }}
        fullWidth
        margin
      />

      <StyledTextField
        label={i18next.t('campaign.block.WebhookActionNode.url')}
        value={props.node.url || ''}
        onChange={e => {
          props.setNode({
            ...props.node,
            url: e.target.value,
            model_type: EventActionNodeType.WebhookActionNode,
          })
        }}
        fullWidth
        margin
      />

      <StyledTextField
        label={i18next.t('campaign.block.WebhookActionNode.body')}
        value={props.node.body || ''}
        type={'text'}
        multiline
        rows={4}
        onChange={e => {
          props.setNode({
            ...props.node,
            body: e.target.value,
            model_type: EventActionNodeType.WebhookActionNode,
          })
        }}
        fullWidth
        margin
      />
    </>
  )
}

export const NodeFieldEditor = (props: {
  node: Node
  error?: BlockError | null
  onClose: () => void
  setNode: (node: Node) => void
}) => {
  let editor = <></>
  switch (props.node?.model_type) {
    case EventActionNodeType.AddToSegmentNode:
      editor = <AddToSegmentEditor error={props.error} node={props.node as AddToSegmentNode} setNode={props.setNode} />
      break

    case EventActionNodeType.CreateUserStoreSettingsNode:
      return (
        <InitStoreSettingsEditor
          error={props.error}
          onClose={props.onClose}
          node={props.node as CreateUserStoreSettingsNode}
          setNode={props.setNode}
        />
      )

    case EventActionNodeType.BrowserPopupNotificationActionNode:
      return (
        <HubPopupNodeEditor
          error={props.error}
          onClose={props.onClose}
          node={props.node as BrowserPopupNotificationActionNode}
          setNode={props.setNode}
        />
      )
    case EventActionNodeType.BrowserPushNotificationActionNode:
      editor = (
        <BrowserPushEditor
          error={props.error}
          node={props.node as BrowserPushNotificationActionNode}
          setNode={props.setNode}
        />
      )
      break
    case EventActionNodeType.EmailActionNode:
      editor = <EmailNodeEditor error={props.error} node={props.node as EmailActionNode} setNode={props.setNode} />
      break

    case EventActionNodeType.TimerNode:
      editor = <TimerNodeEditor error={props.error} node={props.node as TimerNode} setNode={props.setNode} />
      break

    case EventActionNodeType.MobilePushActionNode:
    case EventActionNodeType.MobilePopupActionNode:
      editor = <MobileActionEditor error={props.error} node={props.node} setNode={props.setNode} />
      break
    case EventActionNodeType.WebhookActionNode:
      editor = <WebhookNodeEditor error={props.error} node={props.node as WebhookActionNode} setNode={props.setNode} />
      break
    case EventActionNodeType.SplitNode:
      return (
        <SplitNodeEditor
          error={props.error}
          node={props.node as SplitNode}
          setNode={props.setNode}
          onClose={props.onClose}
        />
      )

    case EventActionNodeType.CreateItemBannerActionNode:
      return (
        <CreateBannerEditor
          error={props.error}
          onClose={props.onClose}
          node={props.node as CreateItemBannerActionNode}
          setNode={props.setNode}
        />
      )

    case EventActionNodeType.ItemOfferActionNode:
    case EventActionNodeType.ItemBonusOfferActionNode:
    case EventActionNodeType.ItemDiscountOfferActionNode:
      return (
        <ItemOfferEditor
          error={props.error}
          node={props.node as ItemOfferActionNode}
          onClose={props.onClose}
          setNode={props.setNode}
        />
      )
    case EventActionNodeType.CreateCouponNode:
      return (
        <CreateCouponEditor
          error={props.error}
          node={props.node as CreateCouponNode}
          onClose={props.onClose}
          setNode={props.setNode}
        />
      )
    case EventActionNodeType.CreateVirtualSKUNode:
      return (
        <VirtualSKUEditor
          error={props.error}
          node={props.node as CreateVirtualSKUNode}
          onClose={props.onClose}
          setNode={props.setNode}
        />
      )
  }

  return (
    <div>
      <SettingsHeader node={props.node} onClose={props.onClose} />
      <div className="p-6">
        {editor}
        <div style={{ height: '24px' }} />
        {props.error && props.error?.blockId == props.node?.id && <ErrorChip text={props.error?.errorText} />}
      </div>
    </div>
  )
}
