import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const FacebookIcon: AghanimIcon = forwardRef(function (props, ref) {
  return (
    <IconBase {...props} viewBox="0 0 48 48" ref={ref}>
      <path
        d="M0 16.1C0 10.4051 0 7.55764 1.12587 5.39026C2.07462 3.56382 3.56382 2.07462 5.39026 1.12587C7.55764 0 10.4051 0 16.1 0H31.9C37.5949 0 40.4424 0 42.6097 1.12587C44.4362 2.07462 45.9254 3.56382 46.8741 5.39026C48 7.55764 48 10.4051 48 16.1V31.9C48 37.5949 48 40.4424 46.8741 42.6097C45.9254 44.4362 44.4362 45.9254 42.6097 46.8741C40.4424 48 37.5949 48 31.9 48H16.1C10.4051 48 7.55764 48 5.39026 46.8741C3.56382 45.9254 2.07462 44.4362 1.12587 42.6097C0 40.4424 0 37.5949 0 31.9V16.1Z"
        fill="url(#paint0_linear_7062_8141)"
      />
      <path
        d="M33.3333 30.6668L34.4 24.0002H28V19.3335C28 17.4668 28.6667 16.0002 31.6 16.0002H34.6667V9.86683C32.9333 9.60016 31.0667 9.3335 29.3333 9.3335C23.8667 9.3335 20 12.6668 20 18.6668V24.0002H14V30.6668H20V48.0002C22.6667 48.0002 25.3333 48.0002 28 48.0002V30.6668H33.3333Z"
        fill="white"
      />
      <defs>
        <linearGradient id="paint0_linear_7062_8141" x1="24" y1="46.5975" x2="24" y2="0" gradientUnits="userSpaceOnUse">
          <stop stopColor="#0062E0" />
          <stop offset="1" stopColor="#19AFFF" />
        </linearGradient>
      </defs>
    </IconBase>
  );
});
