import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const TextStyle: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 24 24" ref={ref}>
      <path
        d="M5.10286 20L7.27429 15M19 20L12.0514 4L10.4047 7.79179M3 20H8.02857M21 20H15.5M13.5 20H15.5M7.27429 15H13M7.27429 15L10.4047 7.79179M15.5 20L10.4047 7.79179"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconBase>
  );
});
