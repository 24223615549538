import { Campaign, Currency, ItemRead, PointSeriesValue, SankeyTopSkuData } from '@/api/dashboard'

export let itemValues30 = [153, 645, 485, 257, 452]
export let itemValues7 = [58, 45, 85, 57, 42]

export function createSankeyData(campaigns: Campaign[], items: ItemRead[], selectedFilter: string): SankeyTopSkuData {
  let messages = [
    'BrowserPopupNotificationActionNode',
    'BrowserPushNotificationActionNode',
    'EmailActionNode',
    'MobilePushActionNode',
  ]
  let campaignValues = [31758, 28645, 25485, 20257]

  let event_to_message = []

  for (let i = 0; i < campaigns.length; i++) {
    let c = campaigns[i]
    let message = messages[i % messages.length]
    event_to_message.push({ from_name: c.id, to_name: message, value: campaignValues[i % campaignValues.length] })
  }

  let message_to_sku = []
  let itemValues = selectedFilter == '7' ? itemValues7 : itemValues30
  for (let i = 0; i < Math.min(items.length, 5); i++) {
    let item = items[i]
    let message = messages[i % messages.length]
    message_to_sku.push({ from_name: message, to_name: item.id, value: itemValues[i % itemValues.length] })
  }

  return {
    event_to_message,
    message_to_sku,
  }
}

export function createTopItemsData(items: ItemRead[], selectedFilter: string): PointSeriesValue[] {
  let itemValues = selectedFilter == '7' ? itemValues7 : itemValues30

  return items
    .filter(it => it.currency != Currency.RP)
    .slice(0, 5)
    .map((it, idx) => {
      let value = itemValues[idx % itemValues.length]
      return { category: it.id, values: { sales: it.price * value, count_sales: value } }
    })
}
