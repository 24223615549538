import { ReactNode, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { SocketContext } from './SocketContext'

interface SocketProviderProps {
  children: ReactNode
}

export const SocketProvider = ({ children }: SocketProviderProps) => {
  const { companyId } = useParams() as { companyId: string }
  const [socket, setSocket] = useState<WebSocket | undefined>(undefined)

  useEffect(() => {
    if (!companyId) {
      return
    }

    let socketInstance: WebSocket

    if (!socket || socket.readyState == WebSocket.CLOSED) {
      socketInstance = new WebSocket(`${import.meta.env.VITE_EVENTS_API_URL}/company/events/${companyId}`)

      socketInstance.onclose = e => {
        console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.code)
        socketInstance.onclose = null
        setTimeout(() => setSocket(undefined), 1000)
      }

      setSocket(socketInstance)
    } else {
      socketInstance = socket
    }
    return () => {
      if (socketInstance && !socketInstance.url.endsWith(companyId)) {
        socketInstance.onclose = null
        socketInstance.close()
      }
    }
  }, [companyId, socket?.readyState])

  return <SocketContext.Provider value={{ socket: socket }}>{children}</SocketContext.Provider>
}
