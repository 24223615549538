import { Trans, useTranslation } from 'react-i18next'
import { generatePath, useParams } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import styled from '@emotion/styled'
import { Container } from '@mui/material'
import { CampaignSettings } from '@/api/dashboard'
import CenteredProgress from '../../../../components/CenteredProgress'
import { useGameSettingsCreateMutate, useGameSettingsQuery, useGameSettingsUpdateMutate } from '../../api'
import { LIVEOPS_PATH_TABLE, NullablePartial, cn } from '@/libs'
import { FullscreenLayout } from '@/components/layouts'
import { Button, ToastSeverity, useToast } from '@/ui'
import i18next from 'i18next'
import { useBanner } from '@/libs/hooks/useBanner'
import { FloatingHeader } from './FloatingHeader'
import { FrequencyGroup } from './FrequencyGroup'
import { FrequencyCard } from './FrequencyCard'

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
`

export const CampaignSettingsPage = () => {
  const { t } = useTranslation()
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const { getStickyHeight } = useBanner()
  const { data: settings, isLoading } = useGameSettingsQuery(companyId, gameId)
  const { mutateAsync: createMutateAsync } = useGameSettingsCreateMutate(companyId, gameId)
  const { mutateAsync: updateMutateAsync } = useGameSettingsUpdateMutate(companyId, gameId)
  const showToast = useToast()

  const formMethods = useForm<NullablePartial<CampaignSettings>>({
    values: {
      email_frequency: null,
      mobile_popup_frequency: null,
      mobile_push_frequency: null,
      hub_popup_frequency: null,
      hub_push_frequency: null,
      all_frequency: null,
      ...settings?.campaign_settings,
    },
  })

  const onSubmit = formMethods.handleSubmit(async data => {
    if (!data.email_frequency?.period_type) {
      data.email_frequency = null
    }

    if (!data.mobile_popup_frequency?.period_type) {
      data.mobile_popup_frequency = null
    }

    if (!data.mobile_push_frequency?.period_type) {
      data.mobile_push_frequency = null
    }

    if (!data.hub_popup_frequency?.period_type) {
      data.hub_popup_frequency = null
    }

    if (!data.hub_push_frequency?.period_type) {
      data.hub_push_frequency = null
    }

    if (!data.all_frequency?.period_type) {
      data.all_frequency = null
    }

    if (!settings) {
      await createMutateAsync({
        data: { campaign_settings: { ...data } as CampaignSettings },
      })
    } else {
      await updateMutateAsync({
        settingsId: settings.id,
        data: { ...settings, campaign_settings: { ...data } as CampaignSettings },
      })
    }

    formMethods.reset(data)
    showToast({ message: i18next.t('saved'), severity: ToastSeverity.success })
  })

  const renderHeader = () => {
    return (
      <FloatingHeader
        extra={
          <Button variant={'primary'} disabled={!formMethods.formState.isDirty} onClick={onSubmit}>
            {t('campaign_settings.frequency.form.save')}
          </Button>
        }
        backTo={generatePath(LIVEOPS_PATH_TABLE, { companyId, gameId })}
      >
        <Trans i18nKey="campaign_settings.frequency.title" components={{ span: <span /> }} />
      </FloatingHeader>
    )
  }

  if (isLoading) {
    return (
      <FullscreenLayout>
        <div className={cn('flex flex-col bg-bg-primary', getStickyHeight())}>
          {renderHeader()}
          <LoaderContainer>
            <CenteredProgress />
          </LoaderContainer>
        </div>
      </FullscreenLayout>
    )
  }

  return (
    <FullscreenLayout>
      <div className={cn('flex flex-col overflow-auto bg-bg-primary', getStickyHeight())}>
        {renderHeader()}
        <Container maxWidth="md">
          <div style={{ padding: '20px 0' }}>
            <FormProvider {...formMethods}>
              <FrequencyGroup label={t('campaign_settings.frequency.groups.all')}>
                <FrequencyCard
                  label={t('campaign_settings.frequency.all.label')}
                  desc={t('campaign_settings.frequency.all.desc')}
                  field="all_frequency"
                />
              </FrequencyGroup>

              <FrequencyGroup label={t('campaign_settings.frequency.groups.game_hub')}>
                <FrequencyCard
                  label={t('campaign_settings.frequency.hub_popup.label')}
                  desc={t('campaign_settings.frequency.hub_popup.desc')}
                  field="hub_popup_frequency"
                />
              </FrequencyGroup>

              <FrequencyGroup label={t('campaign_settings.frequency.groups.email')}>
                <FrequencyCard
                  label={t('campaign_settings.frequency.email.label')}
                  desc={t('campaign_settings.frequency.email.desc')}
                  field="email_frequency"
                />
              </FrequencyGroup>

              <FrequencyGroup label={t('campaign_settings.frequency.groups.app')}>
                <FrequencyCard
                  label={t('campaign_settings.frequency.mobile_popup.label')}
                  desc={t('campaign_settings.frequency.mobile_popup.desc')}
                  field="mobile_popup_frequency"
                />

                <FrequencyCard
                  label={t('campaign_settings.frequency.mobile_push.label')}
                  desc={t('campaign_settings.frequency.mobile_push.desc')}
                  field="mobile_push_frequency"
                />
              </FrequencyGroup>
            </FormProvider>
          </div>
        </Container>
      </div>
    </FullscreenLayout>
  )
}
