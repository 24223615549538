/**
 * Get initials from a string.
 * @param string
 */
export const getStringInitials = (string: string) =>
  string
    .match(/(\b\S)?/g)
    ?.join('')
    ?.match(/(^\S|\S$)?/g)
    ?.join('') || ''
