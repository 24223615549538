import { DayPicker, DayPickerProps } from 'react-day-picker';
import { cn } from '../../libs';
import { CalendarNav } from './CalendarNav';

export const Calendar = ({ ...rest }: DayPickerProps) => {
  return (
    <DayPicker
      {...rest}
      classNames={{
        root: cn('relative w-full'),
        months: cn('relative flex gap-6'),
        month_caption: cn('mb-3 flex h-6 items-center justify-center text-center text-caption-md'),
        month_grid: cn('flex w-full flex-col'),

        weekdays: cn('flex h-6 items-center'),
        weekday: cn('w-full text-caption-sm text-text-quarterary-hover'),
        week: cn('flex'),

        day: cn(
          'relative m-[-0.5px] flex size-10 border border-border-secondary bg-fg-primary p-1',
          'hover:bg-fg-primary-hover',
          'active:bg-fg-brand-tertiary',
          'active:border-border-brand-ring',
          'active:[&>button]:text-text-brand-primary',
          'active:z-10',
        ),
        day_button: cn('h-full w-full rounded-md'),

        selected: cn(
          'z-20 !border-border-brand-ring !bg-fg-brand-secondary text-caption-md',
          rest.mode === 'single' && '[&>button]:bg-fg-brand-primary [&>button]:text-text-inverse',
        ),

        today: cn('text-caption-md text-text-brand-tertiary'),
        outside: cn('pointer-events-none opacity-0'),

        range_start: cn('[&>button]:bg-fg-brand-primary [&>button]:text-text-inverse'),
        range_end: cn('[&>button]:bg-fg-brand-primary [&>button]:text-text-inverse'),
        range_middle: cn('[&>button]:text-text-brand-tertiary'),
      }}
      components={{
        Nav: CalendarNav,
      }}
    />
  );
};
