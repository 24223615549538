import { useMutation, useQueryClient } from '@tanstack/react-query'
import { dashboardClient } from '@/api'
import { ItemPropertyCreate } from '@/api/dashboard'

interface ItemCategoryCreateMutate {
  create: ItemPropertyCreate
}

export const useItemPropertyCreateMutate = (companyId: string, gameId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ create }: ItemCategoryCreateMutate) =>
      dashboardClient.v1.createItemProperty(companyId, gameId, create),
    onSuccess: data => {
      queryClient.setQueryData(['item-properties', gameId], data.data)
      queryClient.invalidateQueries({ queryKey: ['item-properties', gameId] })
    },
  })
}
