import { useTranslation } from 'react-i18next'
import { cn } from '@dashboard/ui'

interface ContentPlaceholderProps {
  layout: 'full' | 'compact'
}

export const ContentPlaceholder = ({ layout }: ContentPlaceholderProps) => {
  const { t } = useTranslation()
  return (
    <div
      className={cn(
        'prose pointer-events-none absolute left-0 top-0 text-text-quarterary',
        layout === 'full' && 'px-8 py-4',
        layout === 'compact' && 'p-4',
      )}
    >
      {t('lexical.placeholder')}
    </div>
  )
}
