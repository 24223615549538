import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '@/components/icons'

export const Telegram: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 17 17" ref={ref}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.0603 8.16318C5.95824 6.46365 8.55758 5.34347 9.85805 4.80211C13.5715 3.25672 14.343 2.98804 14.8457 2.97921C14.9565 2.97734 15.2035 3.00464 15.364 3.13469C15.4993 3.24441 15.5365 3.39293 15.5544 3.49702C15.5723 3.60112 15.5945 3.83848 15.5769 4.02366C15.3758 6.13931 14.505 11.2735 14.0619 13.6431C13.8745 14.6458 13.5054 14.9819 13.1481 15.0148C12.3716 15.0863 11.7822 14.5013 11.0299 14.0081C9.85297 13.2361 9.18816 12.7557 8.046 12.0024C6.72574 11.1319 7.58176 10.6535 8.33402 9.87154C8.53084 9.66683 11.9522 6.55302 12.0183 6.27044C12.0266 6.23511 12.0343 6.10345 11.956 6.03388C11.8776 5.9643 11.7624 5.98812 11.6792 6.00712C11.5613 6.03388 9.68129 7.27742 6.03927 9.73747C5.50576 10.1041 5.02227 10.2828 4.58931 10.2735C4.11196 10.263 3.19363 10.0035 2.51091 9.78136C1.67361 9.50894 1.008 9.36497 1.06603 8.90229C1.09625 8.66145 1.42785 8.41499 2.06084 8.16318H2.0603Z"
        fill="#64748B"
      />
    </IconBase>
  )
})
