import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { dashboardClient } from '@/api'
import { NewsBulkUpdate, PageLayoutType, PageRead } from '@/api/dashboard'
import {
  Badge,
  ButtonIcon,
  Checkbox,
  ConfirmDialog,
  PageHeader,
  Pagination,
  Table,
  TableCell,
  TableRow,
  TableRowEditButton,
  TableZeroState,
  usePagination,
} from '@/ui'
import { usePagesQuery } from './api/usePagesQuery'
import { HUB_PAGES_DETAIL_PATH, HUB_PAGES_PARENT_PATH } from '@/libs'
import EditPageDialog from './EditPageDialog'
import { SidebarSection } from '@/components/ui/AppSidebar/SidebarSection'
import dayjs from 'dayjs'
import EditCollectionDialog from './EditCollectionDialog'
import { ExternalLink, Plus, XClose } from '@/icons'
import { usePageDeleteMutate } from './api/usePageDeleteMutate'
import {
  PageCover,
  PageSidebar,
  PageSidebarItem,
  PageSidebarMenu,
  PagesMoveModal,
  PagesMoveModalData,
  PagesRowSkeleton,
} from './components'
import IconButton from '@mui/material/IconButton'
import { useWebsitesQuery } from '@/api/useWebsitesQuery'
import { TableCellCheckBox } from '@/components/ui/Table/TableCellCheckBox'
import ConfirmPublishDialog from '../components/ConfirmPublishDialog/ConfirmPublishDialog'
import { getNow } from '@/util'
import { getDateFormatParams } from '@/libs/dates'
import { AuthContext, IAuthContext } from '@/Context'
import { canEdit } from '@/security'
import {
  Breadcrumb,
  Button,
  Edit03,
  FolderIcon,
  FolderOutlineIcon,
  FolderPlusOutlineIcon,
  ImageIcon,
  Trash,
  useModal,
} from '@dashboard/ui'
import { useSubcollectionsMap } from '@/layouts/pages/api/useSubcollectionsMap'
import { PageModalImage } from '@/layouts/pages/widgets'

const DEFAULT_PLACEHOLDER = `${import.meta.env.VITE_STATIC_IMAGES_URL}/images/articles-placeholder.png`

export default function PagesPage() {
  const navigate = useNavigate()
  const context = useContext(AuthContext) as IAuthContext
  const canUserEdit = canEdit(context.customer)
  const { t } = useTranslation()
  const { companyId, gameId, pageId, parent, child } = useParams() as {
    companyId: string
    gameId: string
    parent: string
    child?: string
    pageId?: string
  }

  const [selectedSet, setSelectedSet] = useState<Set<string>>(new Set())
  const [editPost, setEditPost] = useState<PageRead | undefined>(undefined)

  const { page, query, onPageChange } = usePagination(10)
  const [deleteConfirmItem, setDeleteConfirmItem] = useState<PageRead | undefined>(undefined)
  const [deleteConfirmCollection, setDeleteConfirmCollection] = useState<PageRead | undefined>(undefined)

  const [deleteConfirmBulk, setDeleteConfirmBulk] = useState(false)
  const [confirmPublish, setConfirmPublish] = useState<boolean | null>(null)

  const { mutateAsync: deleteMutateAsync } = usePageDeleteMutate(companyId, gameId)
  const { data: websites = [] } = useWebsitesQuery(companyId, gameId)
  const { data: collections = [], refetch: refetchCollections } = usePagesQuery(companyId, gameId, {
    limit: 100,
    types: [PageLayoutType.Collection],
  })
  const { data: subcollections = {}, refetch: refetchSubcollections } = useSubcollectionsMap(collections.map(c => c.id))

  const collection = useMemo(() => collections.find(c => c.slug === parent), [collections, parent])
  const subcollection = useMemo(
    () => (collection?.id ? subcollections[collection.id]?.find(c => c.slug === child) : undefined),
    [collection, subcollections, child],
  )
  const currentCollection = useMemo(() => subcollection ?? collection, [collection, subcollection])

  const openMoveModal = useModal<PagesMoveModalData>(props => <PagesMoveModal {...props} />)

  const {
    data: items = [],
    isLoading,
    refetch,
  } = usePagesQuery(companyId, gameId, {
    types: [PageLayoutType.Content, PageLayoutType.Article],
    ...(currentCollection?.id && { parent_id: currentCollection.id }),
    ...query,
  })

  const refetchAll = async () => {
    await refetch()
    await refetchSubcollections()
    await refetchCollections()
  }

  const onDeleteConfirmCollection = async () => {
    if (deleteConfirmCollection) {
      await deleteMutateAsync({ id: deleteConfirmCollection.id })
    }
    setDeleteConfirmCollection(undefined)

    navigate(
      generatePath(HUB_PAGES_PARENT_PATH, {
        companyId,
        gameId,
        parent: 'uncategorized',
        child: null,
      }),
    )
  }

  const onDeleteConfirmPage = async () => {
    if (deleteConfirmItem) {
      await deleteMutateAsync({ id: deleteConfirmItem.id })
    }
    setDeleteConfirmItem(undefined)
    deleteConfirmItem?.id && selectedSet.delete(deleteConfirmItem.id)
    setSelectedSet(selectedSet)
  }

  const onConfirmDeletePages = async () => {
    setDeleteConfirmBulk(false)
    await dashboardClient.v1.bulkDeletePage(
      companyId,
      gameId,
      Array.from(selectedSet).map(id => ({ id })),
    )

    setSelectedSet(new Set())
    refetch()
  }

  useEffect(() => {
    loadPost()
  }, [pageId])

  useEffect(() => {
    setSelectedSet(new Set())
  }, [parent, child, page])

  const loadPost = async () => {
    if (pageId) {
      switch (pageId) {
        case 'new':
          setEditPost({} as PageRead)
          break
        case 'collection':
          setEditPost({ type: PageLayoutType.Collection } as PageRead)
          break
        default:
          const { data } = await dashboardClient.v1.getPage(companyId, gameId, pageId)
          setEditPost(data)
          break
      }
    } else {
      setEditPost(undefined)
      refetchAll()
    }
  }

  const getSidebarItem = (p?: PageRead, editable: boolean = false, parentSlug?: string): PageSidebarItem => ({
    icon: !parentSlug ? FolderIcon : FolderOutlineIcon,
    to: generatePath(HUB_PAGES_PARENT_PATH, {
      companyId,
      gameId,
      parent: parentSlug || p?.slug || 'uncategorized',
      child: (parentSlug && p?.slug) || null,
    }),
    text: p ? p.title : t('pages.edit.uncategorized'),
    extra: editable ? (
      <PageSidebarMenu
        options={[
          {
            icon: Edit03,
            text: t('grid.edit'),
            onClick: () => p && openEditCollectionModal({ parentSlug, item: p }),
          },
          {
            variant: 'danger',
            icon: Trash,
            text: t('grid.remove'),
            onClick: () => p && setDeleteConfirmCollection(p),
          },
        ]}
      />
    ) : undefined,
    children: p?.id ? subcollections[p.id]?.map(sc => getSidebarItem(sc, editable, p.slug)) : undefined,
    add:
      editable && !parentSlug && p
        ? {
            icon: FolderPlusOutlineIcon,
            text: t('pages.subcollections.add'),
            open: () =>
              openEditCollectionModal({
                parentSlug: p.slug,
                item: {
                  type: PageLayoutType.Collection,
                  title: '',
                  slug: '',
                  date: dayjs().format('YYYY-MM-DD'),
                  html: '',
                  description: '',
                  text: '',
                  parent_id: p.id,
                } as PageRead,
              }),
          }
        : undefined,
  })

  const defaultItems = useMemo(() => [getSidebarItem()], [])
  const menuItems = useMemo(
    () => collections.map(c => getSidebarItem(c, canUserEdit)),
    [companyId, gameId, collections, subcollections, parent],
  )

  const onAddPageClick = () => {
    navigate(
      generatePath(HUB_PAGES_DETAIL_PATH, {
        companyId,
        gameId,
        parent: collection?.slug ?? 'uncategorized',
        child: subcollection?.slug ?? null,
        pageId: 'new',
      }),
    )
  }

  const getItemsForPublishUpdate = (toPublish: boolean) => {
    return items.filter(it => {
      if (!selectedSet.has(it.id)) {
        return false
      }

      return toPublish != !!it.published_at
    })
  }

  const changePublishClick = async (value: boolean) => {
    setConfirmPublish(null)
    const updateFormUpdate = getItemsForPublishUpdate(value)

    let updateArr = updateFormUpdate.map(
      it =>
        ({
          id: it.id,
          published_at: value ? getNow() : null,
        }) as NewsBulkUpdate,
    )

    await dashboardClient.v1.bulkUpdatePage(companyId, gameId, updateArr)

    refetch()
  }

  const onClickBulkMove = useCallback(() => {
    openMoveModal({
      items: items.filter(item => selectedSet.has(item.id)),
      collection: currentCollection,
      onConfirm: () => {
        setSelectedSet(new Set())
      },
    })
  }, [openMoveModal, items, selectedSet, currentCollection])

  const renderDefaultHeader = () => {
    return (
      <TableRow variant="header">
        {canUserEdit && (
          <TableCell width={32}>
            <span
              style={{
                visibility: items.length == 0 ? 'hidden' : 'visible',
              }}
            >
              <Checkbox
                checked={items.length > 0 && selectedSet.size == items.length}
                onChange={() => {
                  setSelectedSet(selectedSet.size == items.length ? new Set() : new Set(items.map(it => it.id)))
                }}
              />
            </span>
          </TableCell>
        )}
        <TableCell width="100%">{t('pages.table.title')}</TableCell>
        <TableCell width="20%">{t('pages.table.date')}</TableCell>
        <TableCell width="25%">{t('pages.table.state')}</TableCell>
        <TableCell width="10%" />
        <TableCell width="10%" />
      </TableRow>
    )
  }

  const renderEditSelection = () => {
    const hasPublishItems = items.some(it => selectedSet.has(it.id) && it.published_at)
    const hasUnPublishItems = items.some(it => selectedSet.has(it.id) && !it.published_at)

    return (
      <TableRow className="h-[45px] px-1.5">
        <TableCell width={32} className="p-1.5">
          <Checkbox
            checked={selectedSet.size == items.length}
            onChange={() => {
              setSelectedSet(selectedSet.size == items.length ? new Set() : new Set(items.map(it => it.id)))
            }}
          />
        </TableCell>
        <TableCell className="flex items-center gap-1 p-1.5">
          <span className="font-semibold text-text-primary" style={{ lineHeight: '12px' }}>
            {t('sku.items.count-selected', { count: selectedSet.size })}
          </span>
          <ButtonIcon variant="tertiary-gray" size="xs" onClick={() => setSelectedSet(new Set())}>
            <XClose />
          </ButtonIcon>

          <div className="ml-3 flex items-center gap-1">
            <Button
              variant="outline"
              color="secondary"
              size="sm"
              onClick={() => changePublishClick(true)}
              disabled={!hasUnPublishItems}
            >
              {t('edit-news-dialog.publish')}
            </Button>

            <Button
              variant="outline"
              color="secondary"
              size="sm"
              onClick={() => setConfirmPublish(false)}
              disabled={!hasPublishItems}
            >
              {t('edit-news-dialog.unpublish')}
            </Button>

            <Button variant="outline" color="secondary" size="sm" onClick={onClickBulkMove}>
              {t('edit-news-dialog.move')}
            </Button>

            <Button variant="tertiary" color="danger" size="sm" onClick={() => setDeleteConfirmBulk(true)}>
              <Trash size={16} />
              <span>{t('remove')}</span>
            </Button>
          </div>
        </TableCell>
      </TableRow>
    )
  }

  let showPaginator = !isLoading
  if (items.length === 0 && page == 1) {
    showPaginator = false
  }

  let showAddButton = canUserEdit
  if (!isLoading && items.length === 0 && page == 1) {
    showAddButton = false
  }

  const webSite = websites[0]

  let queryParams = ''
  if (page > 1) {
    queryParams = `?page=${page}`
  }

  const openUpdateImageModal = useModal<{ page: PageRead }>(({ page, ...rest }) => (
    <PageModalImage {...rest} uid={page.id} page={page} />
  ))

  const openEditCollectionModal = useModal<{ parentSlug?: string; item: PageRead }>(props => (
    <EditCollectionDialog
      {...props}
      onCreated={p => {
        navigate(
          generatePath(HUB_PAGES_PARENT_PATH, {
            companyId,
            gameId,
            parent: (p.parent_id ? collections.find(c => c.id === p.parent_id)?.slug : p.slug) || 'uncategorized',
            child: p.parent_id ? p.slug : null,
          }),
        )
      }}
    />
  ))

  return (
    <>
      {editPost && (
        <EditPageDialog
          item={editPost}
          parents={[...collections, ...Object.values(subcollections).flat()]}
          onClose={(saved: boolean) => {
            navigate(-1)
            if (saved) {
              refetchAll()
            }
          }}
        />
      )}

      {deleteConfirmItem && (
        <ConfirmDialog
          color={'error'}
          message={t('pages.confirm.delete-page')}
          confirmButtonText={t('remove')}
          subMessage={t('pages.confirm.delete-page.text', { name: deleteConfirmItem.title })}
          onCancel={() => setDeleteConfirmItem(undefined)}
          onConfirm={onDeleteConfirmPage}
        />
      )}

      {deleteConfirmCollection && (
        <ConfirmDialog
          color={'error'}
          message={t('pages.confirm.delete-collection')}
          confirmButtonText={t('remove')}
          subMessage={t('pages.confirm.delete-collection.desc')}
          onCancel={() => setDeleteConfirmCollection(undefined)}
          onConfirm={onDeleteConfirmCollection}
        />
      )}

      {deleteConfirmBulk && (
        <ConfirmDialog
          color={'error'}
          confirmButtonText={t('remove')}
          message={t('pages.confirm.remove-n-title')}
          subMessage={t('pages.confirm.remove-n-title.message', { N: selectedSet.size })}
          onCancel={() => setDeleteConfirmBulk(false)}
          onConfirm={onConfirmDeletePages}
        />
      )}

      {confirmPublish !== null && (
        <ConfirmPublishDialog
          confirmPublish={confirmPublish}
          count={getItemsForPublishUpdate(confirmPublish).length}
          forms={{
            one: t('pages.pages.one'),
            many: t('pages.pages.many'),
            few: t('pages.pages.few'),
            other: t('pages.pages.other'),
          }}
          onCancel={() => setConfirmPublish(null)}
          onConfirm={() => changePublishClick(confirmPublish)}
        />
      )}

      <div className="flex h-full flex-col">
        <PageHeader
          extra={
            showAddButton && (
              <Button size="sm" onClick={onAddPageClick}>
                {t('pages.add')}
              </Button>
            )
          }
        >
          {t('pages.title')}
        </PageHeader>

        <div className="flex h-full rounded-none border-y border-border-primary">
          <div className="h-full">
            <nav className="flex h-full w-64 min-w-64 flex-col overflow-y-auto border-r py-3">
              <SidebarSection label={t('pages.collections')}>
                <PageSidebar name="pages-default" items={defaultItems} />
                <div className="mx-3 my-1 divider" />
                <PageSidebar
                  name="pages-custom"
                  items={menuItems}
                  add={
                    canUserEdit
                      ? {
                          text: t('pages.collections-add'),
                          open: () =>
                            openEditCollectionModal({
                              item: {
                                type: PageLayoutType.Collection,
                                title: '',
                                slug: '',
                                date: dayjs().format('YYYY-MM-DD'),
                                html: '',
                                description: '',
                                text: '',
                              } as PageRead,
                            }),
                        }
                      : undefined
                  }
                />
              </SidebarSection>
            </nav>
          </div>
          <div className="flex size-full flex-col gap-3 p-4">
            {currentCollection && currentCollection.image_url && (
              <PageCover
                image_url={currentCollection.image_url}
                onChange={() => openUpdateImageModal({ page: currentCollection })}
              />
            )}

            <Breadcrumb
              crumbs={[
                {
                  icon: subcollection ? FolderOutlineIcon : FolderIcon,
                  children: collection?.title ?? t('pages.edit.uncategorized'),
                  to: generatePath(HUB_PAGES_PARENT_PATH, {
                    companyId,
                    gameId,
                    parent: collection?.slug ?? 'uncategorized',
                    child: null,
                  }),
                },
                subcollection && {
                  children: subcollection.title,
                  to: generatePath(HUB_PAGES_PARENT_PATH, {
                    companyId,
                    gameId,
                    parent: collection?.slug ?? 'uncategorized',
                    child: subcollection?.slug,
                  }),
                },
              ].filter(e => !!e)}
              extraRight={
                canUserEdit &&
                currentCollection &&
                !currentCollection.image_url && (
                  <Button
                    variant="tertiary"
                    color="secondary"
                    size="sm"
                    onClick={() =>
                      openUpdateImageModal({
                        page: currentCollection,
                      })
                    }
                  >
                    <ImageIcon size={16} />
                    <span>{t('pages.collections.image.add')}</span>
                  </Button>
                )
              }
            />

            <Table>
              {selectedSet.size == 0 ? renderDefaultHeader() : renderEditSelection()}

              {isLoading && new Array(5).fill('').map((_, index) => <PagesRowSkeleton key={`skeleton-${index}`} />)}
              {!isLoading &&
                items.map((it, idx) => (
                  <TableRow
                    key={idx}
                    to={
                      canUserEdit
                        ? generatePath(HUB_PAGES_DETAIL_PATH, {
                            companyId,
                            gameId,
                            parent: collection?.slug || 'uncategorized',
                            child: subcollection?.slug || null,
                            pageId: it.id,
                          }) + queryParams
                        : ''
                    }
                  >
                    {canUserEdit && (
                      <TableCellCheckBox id={it.id} selectedSet={selectedSet} setSelectedSet={setSelectedSet} />
                    )}
                    <TableCell width="100%">
                      <div className="flex items-center gap-6">
                        <div
                          style={{
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            backgroundImage: `url(${it.image_url || DEFAULT_PLACEHOLDER})`,
                            borderRadius: '4px',
                            height: '38px',
                            minWidth: '76px',
                          }}
                        />
                        <span className="truncate">{it.title}</span>
                      </div>
                    </TableCell>
                    <TableCell width="20%">{it.date ? t('intl.DateTime', getDateFormatParams(it.date)) : ''}</TableCell>
                    <TableCell width="25%">
                      {it.published_at ? (
                        <Badge variant="lime-secondary">{t('table-news.state.published')}</Badge>
                      ) : (
                        <Badge variant="gray-secondary">{t('table-news.state.draft')}</Badge>
                      )}
                    </TableCell>
                    <TableCell width="10%">
                      <IconButton
                        onClick={e => {
                          e.stopPropagation()
                          e.preventDefault()
                          const base = webSite.url
                          const href = `${[base, collection?.slug, subcollection?.slug, it.slug].filter(Boolean).join('/')}`
                          window.open(href)
                        }}
                      >
                        <ExternalLink width={24} height={24} />
                      </IconButton>
                    </TableCell>
                    {canUserEdit && (
                      <TableCell width="10%">
                        <TableRowEditButton
                          onChange={v => {
                            switch (v) {
                              case 'remove':
                                setDeleteConfirmItem(it)
                                break
                            }
                          }}
                          options={[
                            {
                              icon: <Trash className="text-text-error-primary" />,
                              children: <span className="text-text-error-primary"> {t('grid.remove')} </span>,
                              value: 'remove',
                            },
                          ]}
                        />
                      </TableCell>
                    )}
                  </TableRow>
                ))}
            </Table>

            {!isLoading && items.length === 0 && (
              <div className="h-full p-4">
                <TableZeroState
                  title={t(canUserEdit ? 'pages.empty-table.title' : 'pages.empty-table.title.read-only')}
                  message={t(canUserEdit ? 'pages.empty-table' : 'pages.empty-table.read-only')}
                  buttons={
                    canUserEdit && (
                      <Button size="sm" onClick={onAddPageClick}>
                        <Plus size={16} />
                        <span>{t('pages.add')}</span>
                      </Button>
                    )
                  }
                />
              </div>
            )}

            {showPaginator && (
              <Pagination
                hasNext={items.length >= query.limit}
                page={page}
                onPageChange={onPageChange}
                pageItems={items.length}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}
