import { useParams } from 'react-router-dom'
import { useGameSettingsQuery } from '@/api/useGameSettingsQuery'
import { FeatureFlags } from '@/api/dashboard'

export const useFeatureFlags = () => {
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }

  const { data: settings, isLoading } = useGameSettingsQuery(companyId, gameId)

  return { featureFlags: (settings?.feature_flags || {}) as FeatureFlags, isLoading }
}
