import { useMutation, useQueryClient } from '@tanstack/react-query'

import { dashboardClient } from '@/api'
import { creatorsGetTermsQueryOptions } from '@/layouts/creator/api/creatorsTermsQueryOptions'
import { CreatorTermsCreate } from '@/api/dashboard'

export const creatorCreateTermsMutation = (token: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: CreatorTermsCreate) =>
      dashboardClient.v1
        .creatorAcceptTerms(data, {
          headers: {
            'X-Creator-Token': token,
          },
        })
        .then(r => r.data),
    onSuccess: () => {
      return queryClient.refetchQueries({
        queryKey: creatorsGetTermsQueryOptions(token).queryKey,
      })
    },
  })
}
