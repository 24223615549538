import {
  HTMLAttributes,
  MouseEvent,
  KeyboardEvent as ReactKeyboardEvent,
  ReactNode,
  createElement,
  forwardRef,
  useCallback,
} from 'react'
import { VariantProps, cva } from 'class-variance-authority'
import { AghanimIconOrCustom } from '@/icons'
import { cn } from '@/libs'

export interface MenuItemBaseProps {
  children: ReactNode
  variant?: 'primary' | 'danger'
  icon?: AghanimIconOrCustom
  extra?: AghanimIconOrCustom
  caption?: ReactNode
  disabled?: boolean
}

export type MenuItemProps = HTMLAttributes<HTMLLIElement> &
  MenuItemBaseProps &
  VariantProps<typeof menuItemChildVariants>

const menuItemChildVariants = cva(
  [
    'flex w-full gap-1.5 rounded-md bg-fg-primary px-1.5 py-2 transition-colors',
    'group-[&[data-disabled="true"]]/menu-item:text-text-disabled',
  ],
  {
    variants: {
      variant: {
        primary: [
          '[--label-color:theme(colors.text-secondary)]',
          '[--caption-color:theme(colors.text-tertiary)]',
          '[--extra-color:theme(colors.text-quarterary)]',

          'group-hover/menu-item:bg-fg-primary-hover',
          'group-hover/menu-item:[--label-color:theme(colors.text-secondary-hover)]',
          'group-hover/menu-item:[--caption-color:theme(colors.text-tertiary-hover)]',
          'group-hover/menu-item:[--extra-color:theme(colors.text-quarterary-hover)]',

          'group-active/menu-item:bg-fg-primary-alt',
          'group-active/menu-item:[--label-color:theme(colors.text-primary)]',
          'group-active/menu-item:[--caption-color:theme(colors.text-tertiary-hover)]',
          'group-active/menu-item:[--extra-color:theme(colors.text-quarterary-hover)]',

          'group-[&:not(:hover)]/menu-item:group-[&[data-open="true"]]/menu-item:bg-fg-primary-alt',
          'group-[&:not(:hover)]/menu-item:group-[&[data-open="true"]]/menu-item:[--label-color:theme(colors.text-primary)]',
          'group-[&:not(:hover)]/menu-item:group-[&[data-open="true"]]/menu-item:[--caption-color:theme(colors.text-tertiary-hover)]',
          'group-[&:not(:hover)]/menu-item:group-[&[data-open="true"]]/menu-item:[--extra-color:theme(colors.text-quarterary-hover)]',

          'group-focus-visible/menu-item:bg-fg-primary-hover',
          'group-focus-visible/menu-item:[--label-color:theme(colors.text-secondary-hover)]',
          'group-focus-visible/menu-item:[--caption-color:theme(colors.text-tertiary-hover)]',
          'group-focus-visible/menu-item:[--extra-color:theme(colors.text-quarterary-hover)]',
          'group-focus-visible/menu-item:ring-2',
          'group-focus-visible/menu-item:ring-border-brand',
        ],
        danger: [
          '[--label-color:theme(colors.text-error-primary)]',
          '[--caption-color:theme(colors.text-error-primary)]',
          '[--extra-color:theme(colors.text-error-primary)]',

          'group-hover/menu-item:bg-fg-error-tertiary-alt',

          'group-active/menu-item:bg-fg-error-tertiary-alt',

          'group-[&:not(:hover)]/menu-item:group-[&[data-open="true"]]/menu-item:bg-fg-error-tertiary-alt',

          'group-focus-visible/menu-item:bg-fg-error-tertiary-alt',
          'group-focus-visible/menu-item:ring-2',
          'group-focus-visible/menu-item:ring-border-error',
        ],
      },
      defaultVariants: {
        variant: 'primary',
      },
    },
  },
)

export const MenuItem = forwardRef<HTMLLIElement, MenuItemProps>(function MenuItem(
  { children, variant = 'primary', icon, extra, caption, disabled = false, onClick, ...rest },
  ref,
) {
  const onClickOrPressEnter = useCallback(
    (e: MouseEvent<HTMLLIElement> | ReactKeyboardEvent<HTMLLIElement>) => {
      if (!(e as ReactKeyboardEvent<HTMLLIElement>).key || (e as ReactKeyboardEvent<HTMLLIElement>).key === 'Enter') {
        onClick?.(e as MouseEvent<HTMLLIElement>)
        e.target.dispatchEvent(new Event('mousemove'))
        return
      }
    },
    [onClick],
  )

  return (
    <li
      {...rest}
      ref={ref}
      className={cn(
        'group/menu-item flex cursor-pointer px-[calc(theme(spacing[3])-1px)] py-0.5 outline-none',
        disabled && 'pointer-events-none opacity-50',
        rest.className,
      )}
      data-disabled={disabled}
      tabIndex={0}
      role={!disabled ? 'option' : ''}
      onClick={onClickOrPressEnter}
      onKeyDown={onClickOrPressEnter}
    >
      <div className={cn(menuItemChildVariants({ variant }))}>
        {icon && (
          <div className="flex h-4 min-w-4 shrink-0 items-center justify-center text-[--label-color]">
            {createElement(icon, { size: 16 })}
          </div>
        )}
        <div className="w-full">
          <div className="flex w-full items-start gap-1.5">
            <span className="break-all text-caption-md leading-4 text-[--label-color]">{children}</span>
            {extra && (
              <div className="ml-auto flex h-4 min-w-4 shrink-0 items-center justify-center text-caption-md text-[--extra-color] transition-colors">
                {createElement(extra, { size: 16 })}
              </div>
            )}
          </div>
          {caption && <div className="mt-0.5 text-paragraph-xs text-[--caption-color]">{caption}</div>}
        </div>
      </div>
    </li>
  )
})
