import { forwardRef } from 'react'
import { ArrayPath, FieldValues, useFieldArray, useFormContext } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'

import { Button, ModalProps } from '@/ui'
import { EngagementAddButton, EngagementItemCard } from '@/layouts/engagement/component/index'
import { PlusContained } from '@/icons'
import { ItemRead } from '@/api/dashboard'
import { SelectSkuModal } from '@/layouts/components/SelectSkuModal/SelectSkuModal'
import { FormErrorMessage, useModal } from '@dashboard/ui'

interface ItemsFieldProps {
  preview?: boolean
  delText: string
  addText: string
  addLimit?: number
  pickerTitle: string
  name: ArrayPath<FieldValues>
}

export const SkuItemsField = forwardRef<HTMLDivElement, ItemsFieldProps>(
  ({ preview = true, delText, addText, addLimit, pickerTitle, name }, ref) => {
    const {
      control,
      watch,
      clearErrors,
      formState: { isSubmitting, errors },
    } = useFormContext()
    const { replace, remove } = useFieldArray({ control, name })
    const picked: ItemRead[] = watch(name) || []

    const openPicker = useModal<ModalProps>(({ ...rest }) => (
      <SelectSkuModal
        {...rest}
        title={pickerTitle}
        picked={[...picked]}
        limit={addLimit}
        onSubmit={items => {
          replace(items)
          clearErrors(`${name}.root`)
          rest?.onClose?.()
        }}
      />
    ))

    return (
      <div ref={ref} className="flex flex-col gap-1.5">
        {picked.map((item, index) => (
          <EngagementItemCard
            key={index}
            item={item}
            actions={
              !preview && (
                <Button
                  size="md"
                  variant="tertiary-destructive"
                  type="button"
                  onClick={() => remove(index)}
                  disabled={isSubmitting}
                >
                  {delText}
                </Button>
              )
            }
          />
        ))}
        {!preview && (!addLimit || picked.length < addLimit) && (
          <EngagementAddButton disabled={isSubmitting} icon={PlusContained} onClick={() => openPicker()}>
            {addText}
          </EngagementAddButton>
        )}
        <ErrorMessage
          name={`${name}.root`}
          errors={errors}
          render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
        />
      </div>
    )
  },
)
