import { useParams } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { dashboardClient } from '@/api'
import { LoyaltyTierCreateUpdate } from '@/api/dashboard'

export const useLoyaltyTiersUpdate = (settingsId: string) => {
  const queryClient = useQueryClient()
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }

  return useMutation({
    mutationFn: (update: LoyaltyTierCreateUpdate[]) =>
      dashboardClient.v1.updateLoyaltyTiers(companyId, gameId, settingsId, update).then(r => r.data),
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: ['loyalty-tiers', companyId, gameId, settingsId],
        exact: false,
        stale: false,
      })
    },
  })
}
