import { ForwardedRef, HTMLProps, useCallback, useMemo, useState } from 'react';
import {
  Placement,
  autoUpdate,
  flip,
  offset,
  shift,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useMergeRefs,
} from '@floating-ui/react';

export interface UseFloatingMenuParams {
  /**
   * Whether the floating menu is initially open.
   */
  initialOpen?: boolean;
  /**
   * Placement of the floating menu.
   */
  placement?: Placement;
  /**
   * Whether the floating menu should be toggled on click.
   */
  toggle?: boolean;
  /**
   * Width of the floating menu.
   *  - `inherit` - width of the floating menu is equal to the width of the reference element (min + width).
   *  - `auto` - width of the floating menu is equal to the width of the content.
   */
  width?: 'inherit' | 'auto';
  /**
   * Callback that is called when the floating menu is opened or closed.
   */
  onOpenChange?: (open: boolean) => void;
}

export interface UseFloatingMenuState {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}

export const useFloatingMenu = <T extends HTMLElement>(
  ref: ForwardedRef<T>,
  {
    initialOpen = false,
    placement = 'bottom-start',
    toggle = true,
    width = 'inherit',
    onOpenChange: _onOpenChange,
  }: UseFloatingMenuParams = {},
) => {
  const [isOpen, setIsOpen] = useState(initialOpen);

  const state: UseFloatingMenuState = useMemo(() => ({ isOpen, setIsOpen }), [isOpen, setIsOpen]);

  const onOpenChange = useCallback(
    (open: boolean) => {
      setIsOpen(open);
      _onOpenChange?.(open);
    },
    [_onOpenChange, setIsOpen],
  );

  const floating = useFloating({
    open: isOpen,
    whileElementsMounted: autoUpdate,
    placement: placement,
    middleware: [
      shift({ crossAxis: false, padding: 4 }),
      offset(4),
      flip({ mainAxis: true }),
      size(() => ({
        apply({ rects, elements }) {
          Object.assign(
            elements.floating.style,
            width === 'inherit'
              ? {
                  minWidth: `${Math.max(rects.reference.width, 176)}px`,
                  width: `${rects.reference.width}px`,
                }
              : {},
          );
        },
      })),
    ],
    onOpenChange,
  });

  const referenceMergedRef = useMergeRefs([floating.refs.setReference, ref]);

  const { getReferenceProps: _getReferenceProps, getFloatingProps: _getFloatingProps } = useInteractions([
    useClick(floating.context, { toggle }),
    useDismiss(floating.context),
  ]);

  const getReferenceProps = useCallback(
    (props?: HTMLProps<Element>) => {
      return _getReferenceProps({
        ...props,
        // @ts-ignore
        'data-open': isOpen,
        ref: referenceMergedRef,
        onKeyDown: e => {
          // TODO: @ds.pankov refactor this, create keyux plugin.
          if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
            e.preventDefault();
            (e.currentTarget as HTMLButtonElement).click();
          }
        },
      });
    },
    [isOpen, _getReferenceProps, referenceMergedRef],
  );

  const getFloatingProps = useCallback(
    (props?: HTMLProps<Element>) => {
      return _getFloatingProps({
        ...props,
        ref: floating.refs.setFloating,
        style: floating.floatingStyles,
      });
    },
    [_getFloatingProps, floating.refs.setFloating, floating.floatingStyles],
  );

  return {
    state,
    floating,
    getReferenceProps,
    getFloatingProps,
  };
};
