import { HTMLAttributes, forwardRef, useMemo } from 'react'
import { Button, Image01, MenuItemBaseProps, Popper } from '@dashboard/ui'
import { SelectIconMenu } from './SelectIconMenu'

export interface SelectIconItem extends Pick<MenuItemBaseProps, 'children'> {
  value: string
}

export interface SelectIconProps extends Omit<HTMLAttributes<HTMLButtonElement>, 'children' | 'label' | 'onChange'> {
  allowEmpty?: boolean
  items: SelectIconItem[]
  value: SelectIconItem['value'] | undefined | null
  onChange: (value: SelectIconItem['value'] | undefined | null) => void
}

export const SelectIcon = forwardRef<HTMLButtonElement, SelectIconProps>(function SelectIcon(
  { allowEmpty = false, items, value, onChange, ...rest },
  ref,
) {
  const initialFocus = useMemo(() => {
    return Math.max(
      items.findIndex(item => item.value === value),
      0,
    )
  }, [items, value])

  return (
    <Popper
      popper={props => <SelectIconMenu {...props} allowEmpty={allowEmpty} items={items} onChange={onChange} />}
      params={{ placement: 'bottom-start' }}
      focusManager={{ initialFocus }}
    >
      <Button {...rest} className="px-0" variant="outline" color="secondary" ref={ref}>
        {items.find(item => item.value === value)?.children || <Image01 className="text-text-disabled" />}
      </Button>
    </Popper>
  )
})
