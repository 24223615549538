import { CheckoutUser } from '@/api/dashboard'
import { formatNumber } from '@/util'
import { RewardPointOutline } from '@/icons'
import { LabelCopyButton } from '@/ui'
import { useTranslation } from 'react-i18next'

export default function RPBalanceView({ user }: { user: CheckoutUser }) {
  const { t } = useTranslation()

  return (
    <div
      style={{
        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);',
        background: 'linear-gradient(69deg, #FFF 0%, #F1F5F9 100%)',
      }}
      className="relative inline-flex h-[136px] w-[268px] min-w-[218px] flex-col truncate rounded-xl border border-border-primary p-[18px]"
    >
      <div className="flex items-center gap-1.5  whitespace-nowrap font-semibold ">
        <div className="flex h-[25px] min-w-[35px] items-center justify-center rounded border border-border-primary text-text-secondary">
          <RewardPointOutline />
        </div>
        <div>
          <div className="flex w-full items-center truncate align-top text-paragraph-md font-medium text-text-secondary">
            {formatNumber(user.balance_rp || 0)} {t('player.card.reward-points')}
          </div>
        </div>
      </div>

      <div className="mt-auto flex flex-col gap-1.5">
        <LabelCopyButton value={user.email} variant="third" tooltip={user.email} />
        <LabelCopyButton value={user.phone} variant="third" />
      </div>
    </div>
  )
}
