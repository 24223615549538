import { queryOptions } from '@tanstack/react-query'
import { dashboardClient } from '@/api'
import { CreatorSocialPlatform } from '@/api/dashboard'

export interface CreatorsQueryParams {
  /** Q */
  q?: string
  /** An enumeration. */
  platform?: CreatorSocialPlatform
  terms_accepted?: boolean
  /**
   * Limit
   * A limit on the number of objects to be returned
   * @exclusiveMin 1
   * @max 300
   * @default 10
   */
  limit?: number
  /**
   * Offset
   * The number of objects to skip
   * @min 0
   */
  offset?: number
}

export const creatorsQueryOptions = (companyID: string, gameID: string, params?: CreatorsQueryParams) =>
  queryOptions({
    queryKey: ['creators', companyID, gameID, params],
    queryFn: () => dashboardClient.v1.getCreators(companyID, gameID, params).then(r => r.data),
    staleTime: 60000,
  })
