import { useMutation, useQueryClient } from '@tanstack/react-query'
import { dashboardClient } from '@/api'
import { JobCreate } from '@/api/dashboard'

interface JobCreateMutate {
  create: JobCreate
}

export const useJobCreateMutate = (companyId: string, gameId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ create }: JobCreateMutate) => dashboardClient.v1.createJob(companyId, gameId, create),
    onSuccess: _ => {
      queryClient.invalidateQueries({ queryKey: ['jobs', gameId], exact: false })
    },
  })
}
