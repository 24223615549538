import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { InputSearch, useModal } from '@dashboard/ui'
import { ItemPropertyRead } from '@/api/dashboard'
import { Button, ButtonIcon, Checkbox, Modal, ModalProps, Table, TableCell, TableRow, TableZeroState } from '@/ui'
import { Edit03, Plus, XClose } from '@/icons'
import { useQuery } from '@tanstack/react-query'
import { itemPropertiesQuery } from '@/layouts/sku-settings/api/itemPropertiesQuery'
import { useParams } from 'react-router-dom'
import { ItemPropertyBadge } from '@/layouts/sku-settings/components/ItemPropertyBadge'
import { DEFAULT_IMAGE_PLACEHOLDER_ITEM } from '@/Settings'
import { EditItemPropertyModal } from '@/layouts/sku-settings/widgets/EditItemPropertyModal'
import { cn } from '@/libs'
import i18next from 'i18next'

interface ItemsPickerProps extends ModalProps {
  onSubmit?: (picked: ItemPropertyRead[]) => void
  selected: ItemPropertyRead[]
}

export const SelectItemPropertyModal = ({ onClose = () => {}, onSubmit, ...rest }: ItemsPickerProps) => {
  const { t } = useTranslation()
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const { data: items = [], isLoading } = useQuery({
    ...itemPropertiesQuery(companyId, gameId, { limit: 200 }),
  })
  const [search, setSearch] = useState('')
  const [selection, setSelection] = useState<ItemPropertyRead[]>([...rest.selected])

  const viewItems = search
    ? items.filter(it => {
        return it.name.toLowerCase().includes(search.toLowerCase())
      })
    : items

  const openCreatePropertyModal = useModal<{ item: ItemPropertyRead }>(props => <EditItemPropertyModal {...props} />)

  const onCreateClick = () => {
    openCreatePropertyModal({
      item: {
        id: '',
        name: '',
        description: '',
        image_url: '',
        config: {
          property_type: 'string',
        },
      } as ItemPropertyRead,
    })
  }

  const onRowClick = (it: ItemPropertyRead, checked: boolean) => {
    if (checked) {
      if (!selection.find(pickedItem => pickedItem.id == it.id)) {
        setSelection([...selection, it])
      }
    } else {
      setSelection(selection.filter(pickedItem => pickedItem.id !== it.id))
    }
  }

  return (
    <Modal className="h-2/3 p-0" isLoading={isLoading} onClose={onClose}>
      <div className="flex h-full flex-col overflow-y-auto px-6">
        <div className="sticky top-0 z-10 inline-flex items-center justify-between bg-fg-primary pb-3 pt-6">
          <div className="text-title-t4 text-text-secondary">{t('item-props.add')}</div>
          <div className="inline-flex gap-3">
            <ButtonIcon size="sm" variant="secondary-gray" onClick={onClose}>
              <XClose />
            </ButtonIcon>
          </div>
        </div>

        {items.length == 0 ? (
          <TableZeroState
            className="mb-3"
            title={t('item-props.empty-table.title')}
            message={t('item-props.empty-table')}
            buttons={
              <Button variant="primary" onClick={onCreateClick}>
                <Plus size={14} />
                <span>{t('item-props.create')}</span>
              </Button>
            }
          />
        ) : (
          <>
            <div className="sticky top-[68px] z-10 h-fit w-full pb-3">
              <InputSearch
                placeholder={i18next.t('search')}
                value={search}
                onChange={e => {
                  setSearch(e.target.value)
                }}
              />
            </div>

            <Table>
              {viewItems.map(it => {
                const checked = !!selection.find(pickedItem => pickedItem.id === it.id)

                return (
                  <TableRow
                    key={it.id}
                    onClick={() => onRowClick(it, !checked)}
                    className={cn('flex h-[42px] w-full cursor-pointer items-center', checked && 'bg-fg-primary-alt')}
                  >
                    <TableCell width="40px" className="px-3">
                      <Checkbox
                        size="sm"
                        checked={checked}
                        onChange={() => {}}
                        onClick={e => {
                          onRowClick(it, checked)
                          e.stopPropagation()
                        }}
                      />
                    </TableCell>

                    <TableCell className="flex items-center gap-3 px-3">
                      <div
                        className="size-[32px] shrink-0 rounded-md bg-cover bg-center bg-no-repeat"
                        style={{
                          backgroundImage: `url(${it.icon_url || DEFAULT_IMAGE_PLACEHOLDER_ITEM})`,
                        }}
                      />
                      <div
                        className={cn(
                          'truncate text-caption-md',
                          checked ? 'text-text-primary' : 'text-text-secondary',
                        )}
                      >
                        {it.name}
                      </div>
                    </TableCell>

                    <TableCell width="30%" className="flex justify-end p-1 ">
                      <ItemPropertyBadge itemProperty={it} className="ml-auto " />
                    </TableCell>
                    <TableCell width="15%" className="p-1">
                      <ButtonIcon
                        variant="tertiary-gray"
                        size="sm"
                        className="mr-3 text-text-primary"
                        onClick={e => {
                          e.stopPropagation()
                          openCreatePropertyModal({ item: it })
                        }}
                      >
                        <Edit03 />
                      </ButtonIcon>
                    </TableCell>
                  </TableRow>
                )
              })}
            </Table>

            <div className="sticky bottom-0 mt-auto flex min-h-[64px] items-center justify-start bg-fg-primary">
              <Button size="lg" variant="text-brand" onClick={onCreateClick}>
                {i18next.t('item-props.create-new')}
              </Button>
              {onSubmit && (
                <Button className="ml-auto" variant="primary" onClick={() => onSubmit(selection)}>
                  {t('Save2')}
                </Button>
              )}
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}
