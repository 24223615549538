import { UserPaymentMethod } from '../../../api/dashboard'
import { usePaymentMethods } from '../../../api/usePaymentMethods'
import { useParams } from 'react-router-dom'
import { Copy03 } from '@/icons'
import { ButtonIcon, Tooltip } from '@/ui'

export default function BigPayMethodView({ method }: { method: UserPaymentMethod }) {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const { data: items = [] } = usePaymentMethods(companyId, gameId)

  const paymentMethod = items?.find(it => it.id === method.payment_method_id)

  const renderBackground = () => {
    return (
      <svg width="100" height="124" viewBox="0 0 100 124" fill="none" className="absolute right-0 top-0 ">
        <path
          d="M40 0H88C94.6274 0 100 5.37258 100 12V112C100 118.627 94.6274 124 88 124H0L40 0Z"
          fill="url(#paint0_linear_8748_10605)"
        />
        <defs>
          <linearGradient id="paint0_linear_8748_10605" x1="50" y1="0" x2="50" y2="124" gradientUnits="userSpaceOnUse">
            <stop stopColor="white" stopOpacity="0" />
            <stop offset="1" stopColor="#E2E8F0" />
          </linearGradient>
        </defs>
      </svg>
    )
  }

  //

  const renderData = () => {
    if (method.data.email) {
      return (
        <>
          <Tooltip message={method.data.email}>
            <div className="w-[116px] grow cursor-pointer truncate">{method.data.email}</div>
          </Tooltip>

          <ButtonIcon
            variant="tertiary-gray"
            size="sm"
            onClick={() => navigator.clipboard.writeText(method.data.email!)}
          >
            <Copy03 />
          </ButtonIcon>
        </>
      )
    }

    return <div className="w-full truncate">{method.data.last4 || method.data.scheme || '••••'}</div>
  }

  return (
    <div
      style={{
        boxShadow: '0px 1px 3px 0px #1018281A, 0px 1px 2px 0px #1018280F',
      }}
      className="relative inline-flex h-[124px] w-[218px] min-w-[218px] flex-col truncate rounded-xl border border-border-primary p-[18px]"
    >
      {renderBackground()}
      <span className="font-semibold text-text-secondary">{paymentMethod?.caption}</span>
      <div className="z-10 mt-auto flex w-full items-center  gap-3 whitespace-nowrap font-semibold text-text-tertiary">
        <img src={paymentMethod?.logo_url} alt={paymentMethod?.caption} className="grow-0" />
        {renderData()}
      </div>
    </div>
  )
}
