import { UserPaymentMethod } from '@/api/dashboard'
import i18next from 'i18next'

export const BillingAddress = (props: { payMethods: UserPaymentMethod[] }) => {
  let methodsToShow = []

  for (let it of props.payMethods) {
    if (!it.billing_address) {
      continue
    }

    if (
      it.billing_address.address1 ||
      it.billing_address.city ||
      it.billing_address.region ||
      it.billing_address.postal
    ) {
      methodsToShow.push(it)
    }
  }

  if (methodsToShow.length === 0) {
    return null
  }

  return (
    <div>
      <div className="my-4 border border-border-primary" />
      <div className="text-paragraph-lg font-semibold text-text-secondary">
        {i18next.t('transaction-details.billing-address')}
      </div>
      <div className="mt-3 flex flex-wrap gap-[18px]">
        {methodsToShow.map(it => (
          <div
            key={it.id}
            style={{
              boxShadow: '0px 1px 3px 0px #1018281A, 0px 1px 2px 0px #1018280F',
            }}
            className="ph-no-capture min-w-[328px]  rounded-xl border border-border-primary bg-fg-secondary-hover p-[18px] text-text-secondary"
          >
            <div>{it.billing_address!.address1}</div>
            <div>{it.billing_address!.city}</div>
            <div>{it.billing_address!.region}</div>
            <div>{it.billing_address!.postal}</div>
          </div>
        ))}
      </div>
    </div>
  )
}
