import { HTMLAttributes, ReactNode, forwardRef } from 'react';
import { HTMLMotionProps, motion } from 'framer-motion';
import { cn } from '../../libs';

import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

interface MenuProps extends HTMLAttributes<HTMLDivElement> {
  /**
   * Items to be displayed in the menu.
   */
  children: ReactNode;
  /**
   * Props for the motion ul element. Use this to animate the menu.
   * ! Use this props for custom classes or animations of menu entry.
   */
  motionProps?: HTMLMotionProps<'ul'>;
  /**
   * Max height of the menu.
   * @default '45vh'
   */
  maxHeight?: string | number;
  /**
   * Footer element to be displayed at the bottom of the menu.
   */
  footer?: ReactNode;
}

/**
 * Component for displaying a dropdown menu.
 */
export const Menu = forwardRef<HTMLDivElement, MenuProps>(
  ({ children, motionProps, maxHeight = '45vh', footer, ...rest }, ref) => (
    <div {...rest} ref={ref} className={cn('w-full', rest.className)}>
      <motion.ul
        {...motionProps}
        className={cn(
          'group/menu',
          'box-border w-full min-w-44 max-w-96 overflow-hidden rounded-xl border border-border-primary bg-fg-primary shadow-lg outline-none',
          motionProps?.className,
        )}
        role="listbox"
      >
        <OverlayScrollbarsComponent
          className="p-3"
          options={{ scrollbars: { autoHide: 'scroll' } }}
          style={{ maxHeight }}
          defer={true}
        >
          {children}
        </OverlayScrollbarsComponent>
        {footer && (
          <div className="relative px-3 pb-3">
            <div
              className={cn(
                'pointer-events-none absolute -top-4 left-0 right-0 h-4',
                'bg-gradient-to-b from-transparent to-fg-primary',
              )}
            />
            {footer}
          </div>
        )}
      </motion.ul>
    </div>
  ),
);
