import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const ChevronDown: AghanimIcon = forwardRef(function (props, ref) {
  return (
    <IconBase strokeWidth="2" {...props} viewBox="0 0 24 24" ref={ref}>
      <path d="M6 9L12 15L18 9" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </IconBase>
  );
});
