import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '@/components/icons'

export const SettingsSwitch: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 22 22" ref={ref}>
      <path
        d="M4.5 18C5.88071 18 7 16.8807 7 15.5C7 14.1193 5.88071 13 4.5 13C3.11929 13 2 14.1193 2 15.5C2 16.8807 3.11929 18 4.5 18ZM7.32867 19C6.5558 19.6254 5.57165 20 4.5 20C2.01472 20 0 17.9853 0 15.5C0 13.0147 2.01472 11 4.5 11C5.57165 11 6.5558 11.3746 7.32867 12H16.5C18.433 12 20 13.567 20 15.5C20 17.433 18.433 19 16.5 19H7.32867ZM8.74394 17H16.5C17.3284 17 18 16.3284 18 15.5C18 14.6716 17.3284 14 16.5 14H8.74394C8.90977 14.4692 9 14.974 9 15.5C9 16.026 8.90977 16.5308 8.74394 17ZM20 4.5C20 6.98528 17.9853 9 15.5 9C14.4284 9 13.4442 8.6254 12.6713 8H3.5C1.567 8 0 6.433 0 4.5C0 2.567 1.567 1 3.5 1H12.6713C13.4442 0.374599 14.4284 0 15.5 0C17.9853 0 20 2.01472 20 4.5ZM11.2561 3H3.5C2.67157 3 2 3.67157 2 4.5C2 5.32843 2.67157 6 3.5 6H11.2561C11.0902 5.53083 11 5.02595 11 4.5C11 3.97405 11.0902 3.46917 11.2561 3ZM15.5 7C16.8807 7 18 5.88071 18 4.5C18 3.11929 16.8807 2 15.5 2C14.1193 2 13 3.11929 13 4.5C13 5.88071 14.1193 7 15.5 7Z"
        fill="currentColor"
        transform="translate(1, 1.5) scale(.95)"
      />
    </IconBase>
  )
})
