import { ReactNode, createElement } from 'react'
import { AghanimIcon, cn } from '@dashboard/ui'

interface SidebarButtonProps {
  icon?: AghanimIcon
  children: ReactNode
  onClick?: () => void
}

export const SidebarButton = ({ children, onClick, icon }: SidebarButtonProps) => {
  return (
    <div
      className={cn(
        'cursor-pointer transition-colors',
        'relative mb-1 flex min-h-8 items-center gap-2 rounded-md px-2 py-1 text-text-secondary last:mb-0',
        'hover:bg-fg-primary-hover hover:text-text-brand-primary',
        'active:bg-fg-primary-alt active:text-text-primary [&.active]:bg-fg-primary-alt',
      )}
      onClick={onClick}
    >
      {icon && (
        <div className="flex size-6 shrink-0 items-center justify-center">{createElement(icon, { size: 16 })}</div>
      )}
      <div className="w-full truncate text-caption-md">{children}</div>
    </div>
  )
}
