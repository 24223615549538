import { Link } from 'react-router-dom'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'
import { WebhookEventType } from '@/api/dashboard'
import { Button, ButtonIcon, DrawerContent, DrawerTopbar } from '@/ui'
import { FlipBackward, XClose } from '@/icons'
import { GAME_WEBHOOKS_PATH, useNavigateBack } from '@/libs'
import { WebhookEventCheckbox } from '../../components'
import { webhookSortedKeys } from '../../libs'
import { WebhookFormData, WebhookFormStage } from '../WebhookForm'
import { stageMotionProps } from './stageMotionProps'

interface SelectEventsStageProps {
  setStage: (stage: WebhookFormStage) => void
}

export const SelectEventsStage = ({ setStage }: SelectEventsStageProps) => {
  const { t } = useTranslation()
  const { register } = useFormContext<WebhookFormData>()
  const back = useNavigateBack({ fallback: GAME_WEBHOOKS_PATH })

  return (
    <>
      <DrawerTopbar>
        <Link {...back} className="-my-2">
          <ButtonIcon variant="secondary-gray" size="sm" type="button">
            <XClose />
          </ButtonIcon>
        </Link>
        <h2 className="mr-auto text-title-t5">{t('webhook.form.select-events.stage.title')}</h2>
        <Button onClick={() => setStage('general')} variant="primary">
          {t('apply')}
        </Button>
      </DrawerTopbar>

      <DrawerContent className="overflow-y-auto">
        <motion.div {...stageMotionProps}>
          <div className="mb-6">
            <button
              className="flex items-center gap-2.5 text-caption-lg text-text-secondary hover:text-text-brand-primary"
              type="button"
              onClick={() => setStage('general')}
            >
              <FlipBackward size={18} />
              <span>{t('Back')}</span>
            </button>
          </div>

          <div className="mb-6 overflow-hidden rounded-xl border border-border-secondary shadow-xs">
            {Object.values(WebhookEventType)
              .sort((a, b) => webhookSortedKeys.indexOf(a) - webhookSortedKeys.indexOf(b))
              .filter(event => event !== 'campaign.custom')
              .map(event => (
                <WebhookEventCheckbox
                  {...register('events')}
                  key={`event-${event}`}
                  value={event}
                  event={{ label: t(`webhook.event.${event}.label`), desc: t(`webhook.event.${event}.desc`) }}
                />
              ))}
          </div>
        </motion.div>
      </DrawerContent>
    </>
  )
}
