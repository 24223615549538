import i18next from 'i18next'

import { CreateUserStoreSettingsNode, Node } from '../../../api/dashboard'

import { Badge, Checkbox, FieldGroup, FieldValidationMessage, Input, InputExtraMark, Select } from '@/ui'
import { useParams } from 'react-router-dom'
import { BlockError } from '../types'
import { useTranslation } from 'react-i18next'
import { useStoresQuery } from '@/layouts/store/api'
import { Controller, useForm } from 'react-hook-form'
import SettingsHeader from '@/layouts/campaigns/SettingsHeader'
import { ErrorMessage } from '@hookform/error-message'
import DurationSelectorDHMS from '@/components/DurationSelectorDHMS'
import { Settings } from '@/components/ui/Settings'
import Divider from '@/components/Divider'
import { useGameSettingsQuery } from '@/api/useGameSettingsQuery'
import { CheckBoxContainer } from '@/components/ui/CheckBoxContainer'
import { AnimatePresence, motion } from 'framer-motion'
import { stageMotionProps } from '@/layouts/webhooks/widgets/WebhookEditForm/stageMotionProps'
import { useEffect } from 'react'
import { AlertTriangleOutline } from '@/icons'

export default function InitStoreSettingsEditor(props: {
  node: CreateUserStoreSettingsNode
  error?: BlockError | null
  setNode: (node: Node) => void
  onClose: () => void
}) {
  const { t } = useTranslation()

  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const { data: stores = [] } = useStoresQuery(companyId, gameId, {
    limit: 100,
  })
  const { data: gameSettings } = useGameSettingsQuery(companyId, gameId)

  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
    watch,
    setValue,
    setError,
    clearErrors,
  } = useForm<CreateUserStoreSettingsNode>({
    values: {
      ...props.node,
    },
  })

  useEffect(() => {
    let err = props.error?.errors?.find(it => it.field == 'store_id')
    if (err) {
      setError('store_id', err)
    }
  }, [props.error])

  const [max_purchase_count, store_id, discount_percent] = watch(['max_purchase_count', 'store_id', 'discount_percent'])

  const onSaveClick = (data: CreateUserStoreSettingsNode) => {
    if (!data.bonus_percent) {
      data.bonus_percent = null as unknown as number
    }

    if (!data.reward_points_percent) {
      data.reward_points_percent = null as unknown as number
    }

    if (!data.discount_percent) {
      data.discount_percent = null as unknown as number
    }

    props.setNode(data as Node)

    props.onClose()
  }

  const renderQuantityLimitations = () => {
    return (
      <Settings text={i18next.t('campaign.block.CreateUserStoreSettingsNode.quantity-limitations')}>
        <CheckBoxContainer
          checked={max_purchase_count != undefined}
          onChange={v => {
            setValue('max_purchase_count', v.target.checked ? 1 : undefined)
          }}
          label={i18next.t('campaign.block.CreateUserStoreSettingsNode.limit')}
        >
          {i18next.t('campaign.block.CreateUserStoreSettingsNode.max_purchases')}
          <Input type="number" {...register('max_purchase_count', { min: 1 })} />
        </CheckBoxContainer>
      </Settings>
    )
  }

  const renderGeneral = () => {
    return (
      <Settings text={i18next.t('campaign.block.CreateUserStoreSettingsNode.Benefits')}>
        <FieldGroup label={i18next.t('campaign.virtual-sku-editor.discount')} className="flex flex-col gap-1.5">
          <Input type="number" {...register('discount_percent')} extraLeft={<InputExtraMark>%</InputExtraMark>} />
          <Checkbox {...register('exclude_items_for_reward_points')} disabled={!discount_percent}>
            {t('campaign.block.CreateUserStoreSettingsNode.exclude_items_for_reward_points')}
          </Checkbox>
        </FieldGroup>

        <FieldGroup
          label={i18next.t('campaign.virtual-sku-editor.bonus')}
          caption={i18next.t('campaign.block.CreateUserStoreSettingsNode.bonus')}
        >
          <Input type="number" {...register('bonus_percent')} extraLeft={<InputExtraMark>%</InputExtraMark>} />
        </FieldGroup>

        {gameSettings?.enable_reward_points && (
          <FieldGroup label={i18next.t('campaign.virtual-sku-editor.reward_point_percent')}>
            <Input
              type="number"
              {...register('reward_points_percent', {
                min: { value: 1, message: i18next.t('campaign.virtual-sku-editor.reward_point_percent.validation') },
                max: {
                  value: 100,
                  message: i18next.t('campaign.virtual-sku-editor.reward_point_percent.validation'),
                },
              })}
              extraLeft={<InputExtraMark>%</InputExtraMark>}
            />
            <ErrorMessage
              name="reward_points_percent"
              errors={errors}
              render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
            />
          </FieldGroup>
        )}
      </Settings>
    )
  }

  const renderTimelimit = () => {
    return (
      <Settings text={i18next.t('campaign.block.CreateUserStoreSettingsNode.TimeLimitation')}>
        <Controller
          name="duration"
          control={control}
          rules={{ required: i18next.t('validation.required') }}
          render={({ field }) => (
            <FieldGroup label={i18next.t('campaign.block.CreateUserStoreSettingsNode.Duration')}>
              <DurationSelectorDHMS duration={field.value} size="small" onChange={v => field.onChange(v)} />
              <ErrorMessage
                name="duration"
                errors={errors}
                render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
              />
            </FieldGroup>
          )}
        />
      </Settings>
    )
  }

  return (
    <div>
      <SettingsHeader onSave={handleSubmit(onSaveClick)} onClose={props.onClose} node={props.node as Node} />
      <div className="flex flex-col gap-3 p-6">
        <div>
          <FieldGroup label={i18next.t('store.store')}>
            <Controller
              name="store_id"
              control={control}
              rules={{ required: i18next.t('validation.required') }}
              render={({ field }) => (
                <Select
                  options={stores.map(s => ({
                    value: s.id,
                    children: (
                      <div className="flex items-center gap-2">
                        {s.name}
                        <Badge variant="gray-secondary">{s.item_count}</Badge>
                      </div>
                    ) as unknown as string,
                    extraRight: t(`store.type.${s.type}`),
                  }))}
                  onChange={v => {
                    clearErrors('store_id')
                    field.onChange(v)
                  }}
                  value={field.value}
                />
              )}
            />
            <ErrorMessage
              name="store_id"
              errors={errors}
              render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
            />
          </FieldGroup>

          <Badge variant="orange-primary">
            <AlertTriangleOutline />
            {i18next.t('campaign.block.CreateUserStoreSettingsNode.warning')}
          </Badge>
        </div>
        <AnimatePresence>
          {store_id && (
            <motion.div {...stageMotionProps}>
              <Divider />

              {renderGeneral()}

              <Divider />

              {renderQuantityLimitations()}

              <Divider />

              {renderTimelimit()}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  )
}
