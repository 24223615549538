import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import i18next from 'i18next'
import { Button } from '@dashboard/ui'
import { canEdit } from '@/security'
import { AchievementRead, AchievementType } from '@/api/dashboard'
import {
  ConfirmDialog,
  PageHeader,
  Pagination,
  Table,
  TableCell,
  TableRow,
  TableRowEditButton,
  TableRowSkeleton,
  TableZeroState,
  ToastSeverity,
  useModal,
  usePagination,
  useToast,
} from '@/ui'
import { useAchievementQuery } from './api'
import { DotsGridSix, Edit03, Plus, Trash } from '@/icons'
import { useAchievementDeleteMutate } from './api/useAchievementDeleteMutate'
import { EditAchievementDialog, EditAchievementDialogProps } from './EditAchievementDialog'
import { ReactSortable } from 'react-sortablejs'
import { dashboardClient } from '@/api'
import { AuthContext, IAuthContext } from '@/Context'

export default function AchievementTable() {
  const context = useContext(AuthContext) as IAuthContext
  const canUserEdit = canEdit(context.customer)

  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }

  const { page, query, onPageChange, needShowPagination } = usePagination(20)
  const { data: items = [], isLoading, refetch } = useAchievementQuery(companyId, gameId, query)
  const { mutateAsync: deleteMutateAsync } = useAchievementDeleteMutate(companyId, gameId)
  const [confirmDeleteItem, setConfirmDeleteItem] = useState<AchievementRead | null>(null)
  const [isDirty, setIsDirty] = useState(false)
  const [orderItems, setOrderItems] = useState<AchievementRead[]>([])
  const showToast = useToast()

  const onDeleteGameItemClick = async () => {
    if (confirmDeleteItem) {
      setConfirmDeleteItem(null)
      await deleteMutateAsync({ id: confirmDeleteItem.id })
    }
  }

  useEffect(() => {
    setOrderItems(items.map(it => ({ ...it })))
  }, [items, isLoading])

  const saveOrder = async () => {
    await dashboardClient.v1.bulkUpdateAchievement(
      companyId,
      gameId,
      orderItems.map((it, idx) => ({
        id: it.id,
        position: idx,
      })),
    )
    refetch()
    setIsDirty(false)
    showToast({ message: i18next.t('saved'), severity: ToastSeverity.success })
  }

  const onDiscardClick = () => {
    setOrderItems(items.map(it => ({ ...it })))
    setIsDirty(false)
  }

  const onMainBtnClick = () => {
    if (isDirty) {
      saveOrder()
    } else {
      openImageInsertModal({ item: { type: AchievementType.FirstPurchase, position: items.length } as AchievementRead })
    }
  }

  const openImageInsertModal = useModal<EditAchievementDialogProps>(props => (
    <EditAchievementDialog
      {...props}
      item={props.item}
      onClose={() => {
        refetch()
        props.onClose?.()
      }}
    />
  ))

  return (
    <div className="flex h-full flex-col">
      <PageHeader
        extra={
          canUserEdit &&
          !!items.length && (
            <>
              {isDirty && (
                <Button variant="outline" color="secondary" size="sm" onClick={onDiscardClick}>
                  {i18next.t('Discard')}
                </Button>
              )}
              <Button size="sm" onClick={onMainBtnClick}>
                {i18next.t(isDirty ? 'Save2' : 'achievement.add-button')}
              </Button>
            </>
          )
        }
      >
        {i18next.t('sidebar.achievement')}
      </PageHeader>

      {confirmDeleteItem && (
        <ConfirmDialog
          color={'error'}
          confirmButtonText={i18next.t('remove')}
          subMessage={i18next.t('confirm.text', { name: confirmDeleteItem.name })}
          onCancel={() => setConfirmDeleteItem(null)}
          onConfirm={onDeleteGameItemClick}
        />
      )}

      {isLoading ? (
        <TableRowSkeleton rowCount={10} columnCount={3} />
      ) : items.length === 0 ? (
        <TableZeroState
          title={i18next.t('achievement.zero.title')}
          message={i18next.t('achievement.zero.message')}
          buttons={
            <>
              {canUserEdit && (
                <Button
                  size="sm"
                  onClick={() =>
                    openImageInsertModal({
                      item: { type: AchievementType.FirstPurchase, position: items.length } as AchievementRead,
                    })
                  }
                >
                  <Plus size={16} />
                  <span>{i18next.t('achievement.zero.button-add')}</span>
                </Button>
              )}
              <a href="https://docs.aghanim.com/advanced/achievements" target="_blank">
                <Button variant="tertiary" color="secondary" size="sm">
                  {i18next.t('achievement.zero.learn-more')}
                </Button>
              </a>
            </>
          }
        />
      ) : (
        <>
          <Table>
            <TableRow variant="header">
              <TableCell width="200%">{i18next.t('edit-achievement-dialog.name')}</TableCell>
              <TableCell width="100%">{i18next.t('edit-achievement-dialog.type')}</TableCell>
              <TableCell width="5%" />
              <TableCell width="10%">
                <div style={{ width: '33px' }} />
              </TableCell>
            </TableRow>

            <ReactSortable
              animation={200}
              delay={2}
              disabled={!canUserEdit}
              filter=".non-drag"
              list={orderItems || []}
              setList={v => {
                let changed = false
                for (let i = 0; i < v.length; i++) {
                  if (v[i].id != orderItems[i].id) {
                    changed = true
                    break
                  }
                }
                setOrderItems(v)
                if (changed) {
                  setIsDirty(true)
                }
              }}
            >
              {orderItems.map((it, idx) => (
                <TableRow key={idx}>
                  <TableCell width="200%">
                    <div className="flex items-center gap-3">
                      <div
                        className="rounded-md bg-cover bg-center bg-no-repeat"
                        style={{
                          backgroundImage: `url(${it.image_url})`,
                          height: '38px',
                          width: '38px',
                        }}
                      />
                      <div>{it.name}</div>
                    </div>
                  </TableCell>
                  <TableCell width="100%">{i18next.t(`achievement.type.${it.type}`)}</TableCell>
                  <TableCell width="5%">{canUserEdit && <DotsGridSix className="cursor-move" />}</TableCell>
                  <TableCell width="10%">
                    <div className="text-right">
                      {canUserEdit && (
                        <TableRowEditButton
                          onChange={v => {
                            switch (v) {
                              case 'edit':
                                openImageInsertModal({ item: it })
                                break
                              case 'remove':
                                setConfirmDeleteItem(it)
                                break
                            }
                          }}
                          options={[
                            {
                              icon: <Edit03 />,
                              children: i18next.t('grid.edit'),
                              value: 'edit',
                            },
                            {
                              icon: <Trash className="text-text-error-primary" />,
                              children: <span className="text-text-error-primary"> {i18next.t('grid.remove')} </span>,
                              value: 'remove',
                            },
                          ]}
                        />
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </ReactSortable>
          </Table>

          {needShowPagination(isLoading, items) && (
            <Pagination
              hasNext={items.length >= query.limit}
              page={page}
              onPageChange={onPageChange}
              pageItems={items.length}
            />
          )}
        </>
      )}
    </div>
  )
}
