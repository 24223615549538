import { cn } from '@/libs'

export interface PageBlockProps {
  children: React.ReactNode
  className?: string
  title: string
  description?: string | React.ReactNode
}

export default function DescBlock(props: PageBlockProps) {
  return (
    <div className={cn('flex gap-[18px]', props.className)}>
      <div className="w-[332px]">
        <div className="bg-fg-primary text-xl font-semibold">{props.title}</div>
        <div className="mt-3 text-text-tertiary">{props.description}</div>
      </div>
      <div className="w-full">{props.children}</div>
    </div>
  )
}
