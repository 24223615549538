import { useParams } from 'react-router-dom'
import { useQueries } from '@tanstack/react-query'
import { skuItemsCountQuery } from '../api'
import { ItemType, ResourceState } from '../../../api/dashboard'

export const useSkuCounts = (types: ItemType[]) => {
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const t = types ? types.join(',') : ''
  const [{ data: all }, { data: active }, { data: archived }] = useQueries({
    queries: [
      skuItemsCountQuery(companyId, gameId, { types: t }),
      skuItemsCountQuery(companyId, gameId, { state: ResourceState.Active, types: t }),
      skuItemsCountQuery(companyId, gameId, { state: ResourceState.Archived, types: t }),
    ],
  })
  return { counts: { all, active, archived } }
}
