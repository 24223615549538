import { webhooksStatQuery, webhooksTotalStatQuery } from '@/layouts/webhooks/api/webhooksStatQuery'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { LogQuery } from '@/layouts/webhooks/api/types'
import { useTranslation } from 'react-i18next'
import { getDateCategoryParams } from '@/libs/dates'
import { DataGranularity, SendStatus } from '@/api/dashboard'
import ReactECharts from 'echarts-for-react'
import { formatNumber } from '@/util'
import AnimatedDots from '@/components/animated-dots/AnimatedDots'

export const WebhookStatChart = (props: { query: LogQuery }) => {
  const { companyId, gameId, webhookId } = useParams() as {
    companyId: string
    gameId: string
    webhookId: string
  }

  const { data: successData = [] } = useQuery(
    webhooksStatQuery(companyId, gameId, webhookId, {
      ...props.query,
      status: SendStatus.Success,
    }),
  )

  const { data: errorData = [] } = useQuery(
    webhooksStatQuery(companyId, gameId, webhookId, {
      ...props.query,
      status: SendStatus.Error,
    }),
  )

  const { data: totalData, isLoading } = useQuery(
    webhooksTotalStatQuery(companyId, gameId, webhookId, {
      ...props.query,
    }),
  )

  const { t } = useTranslation()

  const options = {
    title: {
      show: false,
    },
    tooltip: {
      backgroundColor: '#000000CC',
      borderColor: 'transparent',
      trigger: 'axis',
      formatter: function (params: { axisValue: string; value: number; dataIndex: number }[]) {
        let successPoint = props.query.status == SendStatus.Error ? null : successData[params[0].dataIndex]
        let errorPoint =
          props.query.status == SendStatus.Error
            ? errorData[params[0].dataIndex]
            : params[1]
              ? errorData[params[1].dataIndex]
              : null

        let category = t(
          'intl.DateTime',
          getDateCategoryParams(
            (successPoint || errorPoint)?.category || '',
            props.query.granularity || DataGranularity.Day,
          ),
        )

        let successStr = successPoint
          ? `<div class="chart-tooltip--subtitle">
                      ${t('webhook.filters.success')}:<span>${formatNumber(successPoint.value || 0)}</span>
                  </div>`
          : ''

        let errorStr = errorPoint
          ? `<div class="chart-tooltip--subtitle">
                      ${t('webhook.filters.failed')}:<span>${formatNumber(errorPoint.value || 0)}</span>
                  </div>`
          : ''

        return `<div class="chart-tooltip" style="height: auto">
                  <div class="chart-tooltip--title">${category}</div>
                  <div class="chart-tooltip--separator"></div>
                  ${successStr}
                  ${errorStr}
              </div>`
      },
    },
    legend: {
      show: false,
    },
    toolbox: {
      show: false,
    },
    grid: {
      left: '0%',
      right: '2%',
      top: '5px',
      bottom: '5px',
      containLabel: false,
    },
    xAxis: [
      {
        axisLabel: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        type: 'category',
        boundaryGap: false,
        data: successData.map(it =>
          t('intl.DateTime', getDateCategoryParams(it.category, props.query.granularity || DataGranularity.Day)),
        ),
      },
    ],
    yAxis: [
      {
        show: false,
      },
    ],
    axisPointer: {
      lineStyle: {
        color: '#000',
        width: 1,
        type: 'solid',
      },
    },
    series: [
      {
        animation: false,
        name: SendStatus.Success,
        type: 'line',
        showSymbol: false,
        data: props.query.status == SendStatus.Error ? [] : successData,
        lineStyle: {
          color: '#17B26A',
        },
        smooth: 0.3,
      },
      {
        animation: false,
        name: SendStatus.Error,
        type: 'line',
        showSymbol: false,
        data: props.query.status == SendStatus.Success ? [] : errorData,
        lineStyle: {
          color: '#F04438',
        },
        smooth: 0.3,
      },
    ],
  }

  return (
    <div className="flex h-[80px] items-center justify-center gap-6 rounded-xl bg-fg-secondary px-4">
      <div className="flex w-[160px] flex-col gap-1.5 text-caption-md text-text-secondary">
        <div className="flex items-center justify-between">
          {t('webhook.filters.success')}
          <div>
            {isLoading ? <AnimatedDots /> : totalData && formatNumber(totalData[SendStatus.Success]?.value || 0)}
          </div>
        </div>

        <div className="flex items-center justify-between">
          {t('webhook.filters.failed')}
          <div>{isLoading ? <AnimatedDots /> : totalData && formatNumber(totalData[SendStatus.Error]?.value || 0)}</div>
        </div>
      </div>

      <ReactECharts
        option={options}
        style={{
          height: '56px',
          width: '434px',
        }}
      />
    </div>
  )
}
