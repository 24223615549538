import { KeyValue } from '@/types'
import {
  FieldListBlock,
  PluginConfigSchema,
  PluginDivider,
  PluginField,
  PluginFieldType,
  PluginPageBlock,
} from '@/api/dashboard'
import Divider from '@/components/Divider'
import { FieldGroup, FieldValidationMessage, Input, InputExtraSection, Toggle, useModal } from '@/ui'
import { Controller, useFormContext } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { ErrorMessage } from '@hookform/error-message'
import { urlRegexp } from '@/libs'
import i18next from 'i18next'
import { isEmail } from '@/util'
import { Edit03 } from '@dashboard/ui'
import { EditDictModal } from '@/layouts/app-connect/components/EditDictModal'
import Block from '@/layouts/app-connect/components/Block'
import DescBlock from '@/layouts/app-connect/components/DescBlock'
import { Eye } from '@/icons'
import { useState } from 'react'

enum BlockModelType {
  PluginPageBlock = 'PluginPageBlock',
  FieldListBlock = 'FieldListBlock',
  PluginDivider = 'PluginDivider',
}

export const PluginConfigForm = ({
  config,
  pluginInstanceId,
}: {
  config: PluginConfigSchema
  pluginInstanceId?: string
}) => {
  const { t } = useTranslation()
  const [revealSecretFields, setRevealSecretFields] = useState<string[]>([])

  const {
    control,
    setValue,
    getValues,
    formState: { errors, dirtyFields },
  } = useFormContext()

  const openDictEdit = useModal<{ pluginField: PluginField }>(({ ...rest }) => (
    <EditDictModal
      {...rest}
      propertyName={t(rest.pluginField.label)}
      value={getValues()[rest.pluginField.name] as KeyValue}
      onChange={v => setValue(rest.pluginField.name, v)}
      selectorType={rest.pluginField.dict_value_type as string}
    />
  ))

  const renderController = (pluginField: PluginField) => {
    switch (pluginField.type) {
      case PluginFieldType.Url:
        return (
          <Controller
            control={control}
            name={pluginField.name}
            rules={{
              required: pluginField.required ? t('validation.required') : false,
              pattern: { value: urlRegexp, message: i18next.t('validation.invalid_url') },
            }}
            render={({ field }) => <Input size="sm" value={field.value as string} onChange={field.onChange} />}
          />
        )
      case PluginFieldType.Email:
        return (
          <Controller
            control={control}
            name={pluginField.name}
            rules={{
              required: pluginField.required ? t('validation.required') : false,
              validate: v => {
                if (!isEmail(v as string)) {
                  return t('validation.invalid_email')
                }
                return true
              },
            }}
            render={({ field }) => <Input size="sm" value={field.value as string} onChange={field.onChange} />}
          />
        )
      case PluginFieldType.String:
        return (
          <Controller
            control={control}
            name={pluginField.name}
            rules={{
              required: pluginField.required ? t('validation.required') : false,
            }}
            render={({ field }) => {
              if (pluginInstanceId && pluginField.is_secret) {
                if (!dirtyFields[pluginField.name] && field.value && !revealSecretFields.includes(pluginField.name)) {
                  return (
                    <Input
                      size="sm"
                      value="********"
                      extraRight={
                        <InputExtraSection side="right">
                          <Eye
                            className="z-10 size-[18px] cursor-pointer text-text-tertiary"
                            onClick={() => setRevealSecretFields([...revealSecretFields, pluginField.name])}
                          />
                        </InputExtraSection>
                      }
                    />
                  )
                }
              }

              return <Input size="sm" value={field.value as string} onChange={field.onChange} />
            }}
          />
        )

      case PluginFieldType.Int:
        return (
          <Controller
            control={control}
            name={pluginField.name}
            rules={{
              required: pluginField.required ? t('validation.required') : false,
            }}
            render={({ field }) => (
              <Input type="number" size="sm" value={field.value as string} onChange={field.onChange} />
            )}
          />
        )

      case PluginFieldType.Boolean:
        return (
          <Controller
            control={control}
            name={pluginField.name}
            render={({ field }) => (
              <Toggle sizev="md" checked={!!field.value} onChange={e => field.onChange(e.target.checked)} />
            )}
          />
        )

      case PluginFieldType.Dict:
        return (
          <Controller
            control={control}
            name={pluginField.name}
            render={({ field }) => (
              <Input
                size="sm"
                value={field.value ? Object.keys(field.value).join(',') : ''}
                extraRight={
                  <div onClick={() => openDictEdit({ pluginField })} className="z-[1] cursor-pointer">
                    <Edit03 />
                  </div>
                }
              />
            )}
          />
        )
    }

    return <></>
  }

  const renderField = (pluginField: PluginField) => {
    return (
      <FieldGroup
        label={t(pluginField.label)}
        caption={
          pluginField.description ? (
            <Trans
              i18nKey={pluginField.description}
              components={{ a: <a target="_blank" className="text-text-brand-primary" /> }}
            />
          ) : (
            ''
          )
        }
      >
        {renderController(pluginField)}
        <ErrorMessage
          name={pluginField.name}
          errors={errors}
          render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
        />
      </FieldGroup>
    )
  }

  const renderBlock = (block: FieldListBlock | PluginDivider | PluginPageBlock) => {
    if (block.model_type == BlockModelType.PluginPageBlock) {
      return (
        <DescBlock
          className="w-full rounded-2xl1 border border-border-primary p-6"
          title={t(block.title)}
          description={t(block.description)}
        >
          <Block className="bg-fg-secondary">{block.children.map(c => renderBlock(c))}</Block>
        </DescBlock>
      )
    }

    if (block.model_type == BlockModelType.PluginDivider) {
      return <Divider />
    }

    if (block.model_type == BlockModelType.FieldListBlock) {
      return block.fields.map(f => renderField(f))
    }
  }

  return <div>{config.layout.map(c => renderBlock(c))}</div>
}
