import { MouseEvent } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { Badge, FullscreenTopbar, FullscreenTopbarBack, FullscreenTopbarTitle, useModal } from '@/ui'
import { AlertTriangleOutline } from '@/icons'
import { SKU_ITEMS_PATH, useFeatureFlags, useNavigateBack } from '@/libs'
import { useItemUpdateMutate } from '../../api'
import { UnsavedChangesModal } from '../../components'
import { SkuFormData } from './SkuForm'
import { ItemType } from '@/api/dashboard'
import { Button } from '@dashboard/ui'

export const SkuFormTopbar = () => {
  const { t } = useTranslation()
  const back = useNavigateBack({ fallback: SKU_ITEMS_PATH })
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const { featureFlags } = useFeatureFlags()
  const {
    formState: { isSubmitting, isDirty },
    setValue,
    watch,
  } = useFormContext<SkuFormData>()

  const { mutateAsync, isPending } = useItemUpdateMutate(companyId, gameId)

  const openUnsavedChangesModal = useModal(props => <UnsavedChangesModal {...props} />)
  const item = watch()

  const onClickBack = (e?: MouseEvent) => {
    e?.preventDefault()
    if (isDirty) {
      openUnsavedChangesModal()
    } else {
      back.onClick()
    }
  }

  const onConvertClick = () => {
    if (item.type == ItemType.Bundle) {
      setValue('type', ItemType.Item, { shouldDirty: true })
      setValue('nested_items', null as unknown as undefined)
    } else {
      setValue('type', ItemType.Bundle, { shouldDirty: true })
      setValue('is_stackable', false)
      setValue('is_currency', false)
      setValue('quantity', 1)
    }
  }

  const onClickRestore = async () => {
    // @ts-ignore
    await mutateAsync({ data: { ...item, archived_at: null } })
    setValue('archived_at', undefined)
  }

  const getTitle = () => {
    if (!item.id) {
      return t(item.type == ItemType.Bundle ? 'sku.items.add-bundle' : 'AddItem')
    }

    if (item.type == ItemType.Bundle) {
      return t('sku.item.bundle')
    }

    if (item.is_currency) {
      return t('sku.item.is_currency')
    }

    if (item.is_stackable) {
      return t('sku.item.is_stackable')
    }

    return t('sku.item.is_non_stackable')
  }

  const getButtonLabel = () => {
    if (!item.id) {
      return t(item.type == ItemType.Bundle ? 'sku.items.add-bundle' : 'AddItem')
    }

    return t('Save2')
  }

  return (
    <FullscreenTopbar>
      <FullscreenTopbarBack {...back} onClick={onClickBack} />
      <FullscreenTopbarTitle>
        <span className="shrink-0 text-text-tertiary">{getTitle()}</span>
        {item.name && <span className="truncate">{item.name}</span>}
      </FullscreenTopbarTitle>
      {isDirty && (
        <Badge className="ml-3 text-text-warning-primary" variant="orange-tertiary">
          <AlertTriangleOutline size={16} />
          <span>{t('game-item-page.unsaved-changes')}</span>
        </Badge>
      )}
      <div className="ml-auto flex gap-2">
        {item.archived_at && (
          <Button type="button" size="sm" onClick={onClickRestore} loading={isPending}>
            {t('unarchive')}
          </Button>
        )}
        {item.id && featureFlags.bundle_enabled && (
          <Button type="button" variant="outline" size="sm" onClick={onConvertClick}>
            {t(item.type == ItemType.Bundle ? 'sku.items.convert-to-item' : 'sku.items.convert-to-bundle')}
          </Button>
        )}
        <Button type="submit" loading={isSubmitting} disabled={!isDirty} size="sm">
          {getButtonLabel()}
        </Button>
      </div>
    </FullscreenTopbar>
  )
}
