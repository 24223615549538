import { HTMLAttributes } from 'react'
import { cn } from '@/libs'

interface CardProps extends HTMLAttributes<HTMLDivElement> {
  size?: 'sm' | 'md' | 'lg'
}

/**
 * @deprecated please use `Card` from `@dashboard/ui` instead
 */
export const Card = ({ size = 'md', ...rest }: CardProps) => {
  return (
    <div
      {...rest}
      className={cn(
        'border border-border-primary',
        size === 'sm' && 'rounded-md p-1.5',
        size === 'md' && 'rounded-xl p-3',
        size === 'lg' && 'rounded-2xl1 p-6',
        rest.className,
      )}
    />
  )
}
