import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { FullscreenLayout } from '../../../components/layouts'
import { skuItemQuery, useItemUpdateMutate } from '../api'
import { SkuForm, SkuFormData } from '../widgets/SkuForm'
import { useToast } from '../../../components/ui/Toast/useToast'
import { ToastSeverity } from '../../../components/ui/Toast/types'
import i18next from 'i18next'
import { useBanner } from '@/libs/hooks/useBanner'
import { cn } from '@/libs'
import { ItemType } from '@/api/dashboard'
import { saveItemProperties } from '@/layouts/game-items/api/saveItemProperties'

export const SkuEditPage = (props: { type: ItemType }) => {
  const { companyId, gameId, itemId } = useParams() as { companyId: string; gameId: string; itemId: string }
  const { data: item, isLoading } = useQuery(skuItemQuery(companyId, gameId, itemId))
  const { mutateAsync: updateMutateAsync } = useItemUpdateMutate(companyId, gameId)
  const showToast = useToast()
  const { getStickyHeight } = useBanner()

  const onSubmit = async (data: SkuFormData) => {
    await saveItemProperties(companyId, gameId, item!, data)

    delete data.bonus_percent
    delete data.discount_percent
    await updateMutateAsync({ data })
    showToast({ message: i18next.t('saved'), severity: ToastSeverity.success })
    return data
  }

  return (
    <FullscreenLayout className="bg-linear-bg-gradient" isLoading={isLoading}>
      <div className={cn(getStickyHeight(), 'overflow-auto')}>
        {item && <SkuForm item={item} onSubmit={onSubmit} type={props.type} />}
      </div>
    </FullscreenLayout>
  )
}
