import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '@/components/icons'

export const Facebook: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 17 17" ref={ref}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.125 9C2.125 12.1521 4.42708 14.7729 7.4375 15.3042L7.47303 15.2757C7.46119 15.2734 7.44934 15.2711 7.4375 15.2688V10.7708H5.84375V9H7.4375V7.58333C7.4375 5.98958 8.46458 5.10417 9.91667 5.10417C10.3771 5.10417 10.8729 5.175 11.3333 5.24583V6.875H10.5188C9.73958 6.875 9.5625 7.26458 9.5625 7.76042V9H11.2625L10.9792 10.7708H9.5625V15.2688C9.55066 15.2711 9.53881 15.2734 9.52697 15.2757L9.5625 15.3042C12.5729 14.7729 14.875 12.1521 14.875 9C14.875 5.49375 12.0062 2.625 8.5 2.625C4.99375 2.625 2.125 5.49375 2.125 9Z"
        fill="#64748B"
      />
    </IconBase>
  )
})
