import { Badge } from '@/ui'
import { useTranslation } from 'react-i18next'

export function PluginStatus({ status }: { status?: boolean }) {
  const { t } = useTranslation()

  if (status === undefined) {
    return <Badge variant="gray-secondary">{t('plugin.tab.not-installed')} </Badge>
  }

  if (status) {
    return <Badge variant="green-secondary">{t('plugin.tab.enabled')} </Badge>
  }

  return <Badge variant="red-secondary">{t('plugin.tab.disabled')} </Badge>
}
