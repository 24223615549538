import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '@/icons'

export const EditWeight: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 24 24" ref={ref}>
      <path
        d="M22.1932 14.4303C21.6447 13.8566 20.7554 13.8566 20.2068 14.4303C19.5776 15.0884 18.9486 15.7468 18.3196 16.4051C17.0559 17.7276 15.7923 19.0501 14.5271 20.371C14.3975 20.5063 14.2832 20.6256 14.199 20.7694C14.125 20.8957 14.0704 21.0335 14.0373 21.1775C13.9997 21.3415 13.9999 21.5104 14 21.7019C14.0002 21.8888 14.0001 22.0757 14.0001 22.2626C14.0001 22.3515 14 22.4404 14 22.5293C14 22.7892 14.2015 23 14.45 23C14.7137 23 14.9774 22.9997 15.2411 23C15.4242 23.0002 15.5856 23.0003 15.7424 22.9609C15.8802 22.9263 16.0118 22.8693 16.1326 22.7919C16.2701 22.7037 16.3842 22.5842 16.5135 22.4487C17.7767 21.125 19.0414 19.803 20.3061 18.481C20.9352 17.8234 21.5643 17.1658 22.1932 16.508C22.7418 15.9342 22.7418 15.004 22.1932 14.4303Z"
        fill="currentColor"
      />
      <path
        d="M18.5 22.0586C18.2515 22.0586 18.05 22.2693 18.05 22.5293C18.05 22.7892 18.2515 23 18.5 23H22.55C22.7985 23 23 22.7892 23 22.5293C23 22.2693 22.7985 22.0586 22.55 22.0586H18.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.7 6C7.86569 6 7.99883 5.86522 8.01108 5.69999C8.1645 3.63097 9.89178 2 12 2C14.1082 2 15.8355 3.63097 15.9889 5.69999C16.0012 5.86522 16.1343 6 16.3 6H19C19.4977 6 19.9196 6.36593 19.99 6.85858L20.8347 12.7717C20.8519 12.8922 20.7584 13 20.6367 13H19.0205C18.9209 13 18.8365 12.9268 18.8225 12.8283L18.1572 8.17172C18.1432 8.07319 18.0588 8 17.9593 8H6.04076C5.94123 8 5.85685 8.07319 5.84277 8.17172L4.18563 19.7717C4.16842 19.8922 4.26191 20 4.38362 20H12.8C12.9105 20 13 20.0895 13 20.2V21.8C13 21.9105 12.9105 22 12.8 22H3.00001C2.71012 22 2.43449 21.8742 2.24455 21.6552C2.05462 21.4362 1.96906 21.1456 2.01006 20.8586L4.01006 6.85858C4.08044 6.36593 4.50236 6 5.00001 6H7.7ZM10.0222 5.70104C9.99763 5.8649 10.1343 6 10.3 6H13.7C13.8657 6 14.0024 5.8649 13.9778 5.70104C13.8335 4.73828 13.003 4 12 4C10.997 4 10.1665 4.73828 10.0222 5.70104Z"
        fill="currentColor"
      />
    </IconBase>
  )
})
