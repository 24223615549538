import { AllData } from '@/layouts/dashboard/Context'
import { Campaign, ItemRead } from '@/api/dashboard'
import { createSankeyData, createTopItemsData } from '@/layouts/dashboard/demo_data/createSankeyData'

const revenue_by_date_device = [
  { category: '2024-07-23', value: 122832.979545, series_name: 'Android', prev_value: null },
  { category: '2024-07-23', value: 74806.319765, series_name: 'Apple', prev_value: null },
  { category: '2024-07-24', value: 223778.000885, series_name: 'Android', prev_value: null },
  { category: '2024-07-24', value: 375777.798985, series_name: 'Apple', prev_value: null },
  { category: '2024-07-24', value: 22470.0, series_name: 'Other', prev_value: null },
  { category: '2024-07-25', value: 1749399.066265, series_name: 'Android', prev_value: null },
  { category: '2024-07-25', value: 15234.529645, series_name: 'Apple', prev_value: null },
  { category: '2024-07-26', value: 684880.494195, series_name: 'Android', prev_value: null },
  { category: '2024-07-26', value: 1019220.042215, series_name: 'Apple', prev_value: null },
  { category: '2024-07-26', value: 29515.394645, series_name: 'Other', prev_value: null },
  { category: '2024-07-27', value: 488557.990865, series_name: 'Android', prev_value: null },
  { category: '2024-07-27', value: 52742.523865, series_name: 'Apple', prev_value: null },
  { category: '2024-07-27', value: 53003.49264, series_name: 'Other', prev_value: null },
  { category: '2024-07-28', value: 21053.60271, series_name: 'Android', prev_value: null },
  { category: '2024-07-28', value: 13350.0, series_name: 'Other', prev_value: null },
  { category: '2024-07-29', value: 141350.914675, series_name: 'Android', prev_value: null },
  { category: '2024-07-29', value: 56366.947435, series_name: 'Apple', prev_value: null },
  { category: '2024-07-30', value: 401927.999835, series_name: 'Android', prev_value: null },
  { category: '2024-07-30', value: 81435.86622, series_name: 'Apple', prev_value: null },
  { category: '2024-07-30', value: 23873.95836, series_name: 'Other', prev_value: null },
  { category: '2024-07-31', value: 548523.24713, series_name: 'Android', prev_value: null },
  { category: '2024-07-31', value: 40785.0, series_name: 'Apple', prev_value: null },
  { category: '2024-07-31', value: 42081.947075, series_name: 'Other', prev_value: null },
  { category: '2024-08-01', value: 534934.9398, series_name: 'Android', prev_value: null },
  { category: '2024-08-01', value: 604662.57731, series_name: 'Apple', prev_value: null },
  { category: '2024-08-01', value: 23859.89343, series_name: 'Other', prev_value: null },
  { category: '2024-08-02', value: 629842.716865, series_name: 'Android', prev_value: null },
  { category: '2024-08-02', value: 244935.0, series_name: 'Other', prev_value: null },
  { category: '2024-08-03', value: 1027339.95779, series_name: 'Android', prev_value: null },
  { category: '2024-08-03', value: 316264.47187, series_name: 'Apple', prev_value: null },
  { category: '2024-08-03', value: 55854.46956, series_name: 'Other', prev_value: null },
  { category: '2024-08-04', value: 99970.868865, series_name: 'Android', prev_value: null },
  { category: '2024-08-04', value: 26700.0, series_name: 'Apple', prev_value: null },
  { category: '2024-08-04', value: 28878.834105, series_name: 'Other', prev_value: null },
  { category: '2024-08-05', value: 520468.2609, series_name: 'Android', prev_value: null },
  { category: '2024-08-05', value: 56173.344915, series_name: 'Apple', prev_value: null },
  { category: '2024-08-06', value: 747940.39038, series_name: 'Android', prev_value: null },
  { category: '2024-08-06', value: 50633.140375, series_name: 'Other', prev_value: null },
  { category: '2024-08-07', value: 78781.359055, series_name: 'Android', prev_value: null },
  { category: '2024-08-07', value: 598730.0, series_name: 'Apple', prev_value: null },
  { category: '2024-08-08', value: 133093.47536, series_name: 'Android', prev_value: null },
  { category: '2024-08-08', value: 126830.0, series_name: 'Apple', prev_value: null },
  { category: '2024-08-08', value: 13122.3159, series_name: 'Other', prev_value: null },
  { category: '2024-08-09', value: 161336.14909, series_name: 'Android', prev_value: null },
  { category: '2024-08-09', value: 60280.0, series_name: 'Apple', prev_value: null },
  { category: '2024-08-09', value: 18412.438625, series_name: 'Other', prev_value: null },
  { category: '2024-08-10', value: 53442.21266, series_name: 'Android', prev_value: null },
  { category: '2024-08-10', value: 27405.0, series_name: 'Apple', prev_value: null },
  { category: '2024-08-10', value: 24015.125115, series_name: 'Other', prev_value: null },
  { category: '2024-08-11', value: 176049.951195, series_name: 'Android', prev_value: null },
  { category: '2024-08-11', value: 7485.0, series_name: 'Apple', prev_value: null },
  { category: '2024-08-12', value: 654375.04265, series_name: 'Android', prev_value: null },
  { category: '2024-08-12', value: 2740.0, series_name: 'Apple', prev_value: null },
  { category: '2024-08-13', value: 1404550.076155, series_name: 'Android', prev_value: null },
  { category: '2024-08-13', value: 176755.4359, series_name: 'Apple', prev_value: null },
  { category: '2024-08-13', value: 182652.76222, series_name: 'Other', prev_value: null },
  { category: '2024-08-14', value: 543047.7324, series_name: 'Android', prev_value: null },
  { category: '2024-08-14', value: 1316200.0, series_name: 'Apple', prev_value: null },
  { category: '2024-08-14', value: 140889.8238, series_name: 'Other', prev_value: null },
  { category: '2024-08-15', value: 845739.67338, series_name: 'Android', prev_value: null },
  { category: '2024-08-15', value: 973304.13898, series_name: 'Apple', prev_value: null },
  { category: '2024-08-15', value: 261341.189395, series_name: 'Other', prev_value: null },
  { category: '2024-08-16', value: 739619.15572, series_name: 'Android', prev_value: null },
  { category: '2024-08-16', value: 72410.0, series_name: 'Apple', prev_value: null },
  { category: '2024-08-16', value: 77475.0, series_name: 'Other', prev_value: null },
  { category: '2024-08-17', value: 499836.469875, series_name: 'Android', prev_value: null },
  { category: '2024-08-17', value: 761806.109955, series_name: 'Apple', prev_value: null },
  { category: '2024-08-17', value: 899910.0, series_name: 'Other', prev_value: null },
  { category: '2024-08-18', value: 1325286.732055, series_name: 'Android', prev_value: null },
  { category: '2024-08-18', value: 299143.88082, series_name: 'Apple', prev_value: null },
  { category: '2024-08-18', value: 89955.0, series_name: 'Other', prev_value: null },
  { category: '2024-08-19', value: 1111506.379335, series_name: 'Android', prev_value: null },
  { category: '2024-08-19', value: 304920.0, series_name: 'Apple', prev_value: null },
  { category: '2024-08-20', value: 484233.069925, series_name: 'Android', prev_value: null },
  { category: '2024-08-20', value: 267181.98855, series_name: 'Other', prev_value: null },
  { category: '2024-08-21', value: 319264.951, series_name: 'Android', prev_value: null },
  { category: '2024-08-21', value: 720984.98186, series_name: 'Apple', prev_value: null },
  { category: '2024-08-21', value: 124754.0, series_name: 'Other', prev_value: null },
]

const top_items_sum = { value: 26041327.604095, prev_value: undefined }

const payments_success_fail_by_dates = [
  { date: '2024-07-23', success_payment: 0.9425356909479782, failed_payment: 0.05746430905202182 },
  { date: '2024-07-24', success_payment: 0.9413498994696463, failed_payment: 0.05865010053035369 },
  { date: '2024-07-25', success_payment: 0.9629466276107742, failed_payment: 0.03705337238922579 },
  { date: '2024-07-26', success_payment: 0.9432459421963394, failed_payment: 0.05675405780366061 },
  { date: '2024-07-27', success_payment: 0.9340264712970514, failed_payment: 0.06597352870294859 },
  { date: '2024-07-28', success_payment: 0.9523522341821175, failed_payment: 0.04764776581788255 },
  { date: '2024-07-29', success_payment: 0.9555539728009421, failed_payment: 0.04444602719905788 },
  { date: '2024-07-30', success_payment: 0.9652687690655971, failed_payment: 0.03473123093440289 },
  { date: '2024-07-31', success_payment: 0.9583527327688777, failed_payment: 0.04164726723112233 },
  { date: '2024-08-01', success_payment: 0.9777242839538953, failed_payment: 0.022275716046104677 },
  { date: '2024-08-02', success_payment: 0.9924105420484226, failed_payment: 0.007589457951577394 },
  { date: '2024-08-03', success_payment: 0.9499646676795084, failed_payment: 0.05003533232049162 },
  { date: '2024-08-04', success_payment: 0.9496954098558389, failed_payment: 0.05030459014416111 },
  { date: '2024-08-05', success_payment: 0.9417197623239792, failed_payment: 0.05828023767602075 },
  { date: '2024-08-06', success_payment: 0.9517123559124565, failed_payment: 0.04828764408754349 },
  { date: '2024-08-07', success_payment: 0.9410527004333813, failed_payment: 0.058947299566618705 },
  { date: '2024-08-08', success_payment: 0.9677138382575525, failed_payment: 0.03228616174244747 },
  { date: '2024-08-09', success_payment: 0.981414103277504, failed_payment: 0.018585896722495998 },
  { date: '2024-08-10', success_payment: 0.9340589816794377, failed_payment: 0.06594101832056232 },
  { date: '2024-08-11', success_payment: 0.9492200656984363, failed_payment: 0.05077993430156369 },
  { date: '2024-08-12', success_payment: 0.9591285466335916, failed_payment: 0.040871453366408406 },
  { date: '2024-08-13', success_payment: 0.9985885093860177, failed_payment: 0.0014114906139822692 },
  { date: '2024-08-14', success_payment: 0.9378429578010558, failed_payment: 0.062157042198944246 },
  { date: '2024-08-15', success_payment: 0.9568364496461526, failed_payment: 0.043163550353847446 },
  { date: '2024-08-16', success_payment: 0.9821440568639213, failed_payment: 0.017855943136078745 },
  { date: '2024-08-17', success_payment: 0.9504606471228938, failed_payment: 0.0495393528771062 },
  { date: '2024-08-18', success_payment: 0.9318367181284795, failed_payment: 0.06816328187152054 },
  { date: '2024-08-19', success_payment: 0.9679942088487223, failed_payment: 0.03200579115127766 },
  { date: '2024-08-20', success_payment: 0.9747119227715338, failed_payment: 0.025288077228466155 },
  { date: '2024-08-21', success_payment: 0.9374745468745624, failed_payment: 0.06252545312543756 },
]

const triggers_by_date = {
  data: [
    { category: '2024-08-23', value: 10000.0, series_name: 'game_hub_login', prev_value: undefined },
    { category: '2024-08-23', value: 8000.0, series_name: 'coupon_redeemed', prev_value: undefined },
    { category: '2024-08-23', value: 50000.0, series_name: 'game_hub_visit', prev_value: undefined },
    { category: '2024-08-24', value: 10000.0, series_name: 'game_hub_login', prev_value: undefined },
    { category: '2024-08-24', value: 9000.0, series_name: 'coupon_redeemed', prev_value: undefined },
    { category: '2024-08-24', value: 58000.0, series_name: 'game_hub_visit', prev_value: undefined },
    { category: '2024-08-25', value: 10000.0, series_name: 'game_hub_login', prev_value: undefined },
    { category: '2024-08-25', value: 10000.0, series_name: 'coupon_redeemed', prev_value: undefined },
    { category: '2024-08-25', value: 59000.0, series_name: 'game_hub_visit', prev_value: undefined },
    { category: '2024-08-26', value: 10000.0, series_name: 'game_hub_login', prev_value: undefined },
    { category: '2024-08-26', value: 11000.0, series_name: 'coupon_redeemed', prev_value: undefined },
    { category: '2024-08-26', value: 60000.0, series_name: 'game_hub_visit', prev_value: undefined },
    { category: '2024-08-27', value: 10000.0, series_name: 'game_hub_login', prev_value: undefined },
    { category: '2024-08-27', value: 12000.0, series_name: 'coupon_redeemed', prev_value: undefined },
    { category: '2024-08-27', value: 63000.0, series_name: 'game_hub_visit', prev_value: undefined },
    { category: '2024-08-28', value: 130000.0, series_name: 'game_hub_login', prev_value: undefined },
    { category: '2024-08-28', value: 56000.0, series_name: 'coupon_redeemed', prev_value: undefined },
    { category: '2024-08-28', value: 64000.0, series_name: 'game_hub_visit', prev_value: undefined },
    { category: '2024-08-29', value: 125000.0, series_name: 'game_hub_login', prev_value: undefined },
    { category: '2024-08-29', value: 58000.0, series_name: 'coupon_redeemed', prev_value: undefined },
    { category: '2024-08-29', value: 65000.0, series_name: 'game_hub_visit', prev_value: undefined },
    { category: '2024-07-30', value: 128000.0, series_name: 'game_hub_login', prev_value: undefined },
    { category: '2024-07-30', value: 60000.0, series_name: 'coupon_redeemed', prev_value: undefined },
    { category: '2024-07-30', value: 66000.0, series_name: 'game_hub_visit', prev_value: undefined },
    { category: '2024-07-31', value: 130000.0, series_name: 'game_hub_login', prev_value: undefined },
    { category: '2024-07-31', value: 61000.0, series_name: 'coupon_redeemed', prev_value: undefined },
    { category: '2024-07-31', value: 67000.0, series_name: 'game_hub_visit', prev_value: undefined },
    { category: '2024-08-01', value: 150000.0, series_name: 'game_hub_login', prev_value: undefined },
    { category: '2024-08-01', value: 62000.0, series_name: 'coupon_redeemed', prev_value: undefined },
    { category: '2024-08-01', value: 68000.0, series_name: 'game_hub_visit', prev_value: undefined },
    { category: '2024-08-02', value: 187000.0, series_name: 'game_hub_login', prev_value: undefined },
    { category: '2024-08-02', value: 64000.0, series_name: 'coupon_redeemed', prev_value: undefined },
    { category: '2024-08-02', value: 69000.0, series_name: 'game_hub_visit', prev_value: undefined },
    { category: '2024-08-03', value: 162000.0, series_name: 'game_hub_login', prev_value: undefined },
    { category: '2024-08-03', value: 64000.0, series_name: 'coupon_redeemed', prev_value: undefined },
    { category: '2024-08-03', value: 70000.0, series_name: 'game_hub_visit', prev_value: undefined },
    { category: '2024-08-04', value: 149000.0, series_name: 'game_hub_login', prev_value: undefined },
    { category: '2024-08-04', value: 65000.0, series_name: 'coupon_redeemed', prev_value: undefined },
    { category: '2024-08-04', value: 71000.0, series_name: 'game_hub_visit', prev_value: undefined },
    { category: '2024-08-05', value: 156000.0, series_name: 'game_hub_login', prev_value: undefined },
    { category: '2024-08-05', value: 66000.0, series_name: 'coupon_redeemed', prev_value: undefined },
    { category: '2024-08-05', value: 72000.0, series_name: 'game_hub_visit', prev_value: undefined },
    { category: '2024-08-06', value: 151000.0, series_name: 'game_hub_login', prev_value: undefined },
    { category: '2024-08-06', value: 67000.0, series_name: 'coupon_redeemed', prev_value: undefined },
    { category: '2024-08-06', value: 73000.0, series_name: 'game_hub_visit', prev_value: undefined },
    { category: '2024-08-07', value: 155000.0, series_name: 'game_hub_login', prev_value: undefined },
    { category: '2024-08-07', value: 68000.0, series_name: 'coupon_redeemed', prev_value: undefined },
    { category: '2024-08-07', value: 74000.0, series_name: 'game_hub_visit', prev_value: undefined },
    { category: '2024-08-08', value: 160680.0, series_name: 'game_hub_login', prev_value: undefined },
    { category: '2024-08-08', value: 69000.0, series_name: 'coupon_redeemed', prev_value: undefined },
    { category: '2024-08-08', value: 75000.0, series_name: 'game_hub_visit', prev_value: undefined },
    { category: '2024-08-09', value: 165000.0, series_name: 'game_hub_login', prev_value: undefined },
    { category: '2024-08-09', value: 70000.0, series_name: 'coupon_redeemed', prev_value: undefined },
    { category: '2024-08-09', value: 78000.0, series_name: 'game_hub_visit', prev_value: undefined },
    { category: '2024-08-10', value: 169000.0, series_name: 'game_hub_login', prev_value: undefined },
    { category: '2024-08-10', value: 71000.0, series_name: 'coupon_redeemed', prev_value: undefined },
    { category: '2024-08-10', value: 77000.0, series_name: 'game_hub_visit', prev_value: undefined },
    { category: '2024-08-11', value: 170000.0, series_name: 'game_hub_login', prev_value: undefined },
    { category: '2024-08-11', value: 73000.0, series_name: 'coupon_redeemed', prev_value: undefined },
    { category: '2024-08-11', value: 78000.0, series_name: 'game_hub_visit', prev_value: undefined },
    { category: '2024-08-12', value: 175000.0, series_name: 'game_hub_login', prev_value: undefined },
    { category: '2024-08-12', value: 72000.0, series_name: 'coupon_redeemed', prev_value: undefined },
    { category: '2024-08-12', value: 79000.0, series_name: 'game_hub_visit', prev_value: undefined },
    { category: '2024-08-13', value: 180000.0, series_name: 'game_hub_login', prev_value: undefined },
    { category: '2024-08-13', value: 73000.0, series_name: 'coupon_redeemed', prev_value: undefined },
    { category: '2024-08-13', value: 80000.0, series_name: 'game_hub_visit', prev_value: undefined },
    { category: '2024-08-14', value: 190000.0, series_name: 'game_hub_login', prev_value: undefined },
    { category: '2024-08-14', value: 74000.0, series_name: 'coupon_redeemed', prev_value: undefined },
    { category: '2024-08-14', value: 81000.0, series_name: 'game_hub_visit', prev_value: undefined },
    { category: '2024-08-15', value: 200000.0, series_name: 'game_hub_login', prev_value: undefined },
    { category: '2024-08-15', value: 75000.0, series_name: 'coupon_redeemed', prev_value: undefined },
    { category: '2024-08-15', value: 82000.0, series_name: 'game_hub_visit', prev_value: undefined },
    { category: '2024-08-16', value: 210000.0, series_name: 'game_hub_login', prev_value: undefined },
    { category: '2024-08-16', value: 77000.0, series_name: 'coupon_redeemed', prev_value: undefined },
    { category: '2024-08-16', value: 86000.0, series_name: 'game_hub_visit', prev_value: undefined },
    { category: '2024-08-17', value: 220000.0, series_name: 'game_hub_login', prev_value: undefined },
    { category: '2024-08-17', value: 79000.0, series_name: 'coupon_redeemed', prev_value: undefined },
    { category: '2024-08-17', value: 87000.0, series_name: 'game_hub_visit', prev_value: undefined },
    { category: '2024-08-18', value: 230000.0, series_name: 'game_hub_login', prev_value: undefined },
    { category: '2024-08-18', value: 80000.0, series_name: 'coupon_redeemed', prev_value: undefined },
    { category: '2024-08-18', value: 90000.0, series_name: 'game_hub_visit', prev_value: undefined },
    { category: '2024-08-19', value: 235000.0, series_name: 'game_hub_login', prev_value: undefined },
    { category: '2024-08-19', value: 85000.0, series_name: 'coupon_redeemed', prev_value: undefined },
    { category: '2024-08-19', value: 91000.0, series_name: 'game_hub_visit', prev_value: undefined },
    { category: '2024-08-20', value: 243608, series_name: 'game_hub_login', prev_value: undefined },
    { category: '2024-08-20', value: 97155.0, series_name: 'coupon_redeemed', prev_value: undefined },
    { category: '2024-08-20', value: 93155.0, series_name: 'game_hub_visit', prev_value: undefined },
    { category: '2024-08-21', value: 244608, series_name: 'game_hub_login', prev_value: undefined },
    { category: '2024-08-21', value: 97255.0, series_name: 'coupon_redeemed', prev_value: undefined },
    { category: '2024-08-21', value: 93855.0, series_name: 'game_hub_visit', prev_value: undefined },
  ],
  prev_data: [],
  total: [
    { category: 'game_hub_login', value: 1584216 },
    { category: 'coupon_redeemed', value: 491155 },
    { category: 'game_hub_visit', value: 536010 },
  ],
  prev_total: [
    { category: 'coupon_redeemed', value: 284085.79911 },
    { category: 'game_hub_login', value: 1423319.86059 },
    { category: 'game_hub_visit', value: 67610.352075 },
  ],
}

const sales_by_date = [
  {
    category: '2024-07-23',
    values: {
      sales: 156243,
      revenue: 95323.95362159719,
      add_revenue: 35885.13317983859,
      channel_of_cost: 10987.766820161409,
      tax_fx: 14046.146378402798,
    },
  },
  {
    category: '2024-07-24',
    values: {
      sales: 136786,
      revenue: 81865.29841454583,
      add_revenue: 30831.25322420261,
      channel_of_cost: 10204.54677579739,
      tax_fx: 13884.901585454165,
    },
  },
  {
    category: '2024-07-25',
    values: {
      sales: 139999,
      revenue: 81812.89534855865,
      add_revenue: 32025.978185407672,
      channel_of_cost: 9973.72181459233,
      tax_fx: 16186.404651441357,
    },
  },
  {
    category: '2024-07-26',
    values: {
      sales: 139743,
      revenue: 85208.8475057663,
      add_revenue: 31716.391333968193,
      channel_of_cost: 10206.508666031803,
      tax_fx: 12611.252494233706,
    },
  },
  {
    category: '2024-07-27',
    values: {
      sales: 186121,
      revenue: 111250.92352169899,
      add_revenue: 41519.71431485105,
      channel_of_cost: 14316.585685148946,
      tax_fx: 19033.776478301006,
    },
  },
  {
    category: '2024-07-28',
    values: {
      sales: 229866,
      revenue: 139564.74860644696,
      add_revenue: 49811.878257500015,
      channel_of_cost: 19147.92174249998,
      tax_fx: 21341.45139355307,
    },
  },
  {
    category: '2024-07-29',
    values: {
      sales: 187763,
      revenue: 110186.67007335185,
      add_revenue: 42825.38905081337,
      channel_of_cost: 13503.510949186626,
      tax_fx: 21247.429926648147,
    },
  },
  {
    category: '2024-07-30',
    values: {
      sales: 276924,
      revenue: 161703.0687478469,
      add_revenue: 60314.42258854611,
      channel_of_cost: 22762.77741145388,
      tax_fx: 32143.731252153084,
    },
  },
  {
    category: '2024-07-31',
    values: {
      sales: 190929,
      revenue: 114073.60953870822,
      add_revenue: 43302.97167333405,
      channel_of_cost: 13975.728326665954,
      tax_fx: 19576.690461291782,
    },
  },
  {
    category: '2024-08-01',
    values: {
      sales: 287782,
      revenue: 172797.12713999214,
      add_revenue: 64235.23254332367,
      channel_of_cost: 22099.367456676326,
      tax_fx: 28650.272860007888,
    },
  },
  {
    category: '2024-08-02',
    values: {
      sales: 214161,
      revenue: 127930.98425920296,
      add_revenue: 45377.000492488645,
      channel_of_cost: 18871.29950751135,
      tax_fx: 21981.715740797037,
    },
  },
  {
    category: '2024-08-03',
    values: {
      sales: 268182,
      revenue: 165996.19379827517,
      add_revenue: 58726.10946369608,
      channel_of_cost: 21728.490536303925,
      tax_fx: 21731.206201724824,
    },
  },
  {
    category: '2024-08-04',
    values: {
      sales: 308237,
      revenue: 186554.16337695648,
      add_revenue: 65586.61311437686,
      channel_of_cost: 26884.486885623137,
      tax_fx: 29211.736623043573,
    },
  },
  {
    category: '2024-08-05',
    values: {
      sales: 245725,
      revenue: 145257.1456065799,
      add_revenue: 53697.264644089744,
      channel_of_cost: 20020.235355910252,
      tax_fx: 26750.354393420108,
    },
  },
  {
    category: '2024-08-06',
    values: {
      sales: 303898,
      revenue: 178768.21358246598,
      add_revenue: 67634.45764847379,
      channel_of_cost: 23534.942351526202,
      tax_fx: 33960.386417534006,
    },
  },
  {
    category: '2024-08-07',
    values: {
      sales: 293007,
      revenue: 171348.62360175455,
      add_revenue: 65281.446907989986,
      channel_of_cost: 22620.65309201001,
      tax_fx: 33756.27639824546,
    },
  },
  {
    category: '2024-08-08',
    values: {
      sales: 317123,
      revenue: 194590.39134244865,
      add_revenue: 67230.85540068946,
      channel_of_cost: 27906.044599310546,
      tax_fx: 27395.70865755132,
    },
  },
  {
    category: '2024-08-09',
    values: {
      sales: 283621,
      revenue: 173142.15221626728,
      add_revenue: 64835.05443063527,
      channel_of_cost: 20251.24556936473,
      tax_fx: 25392.547783732738,
    },
  },
  {
    category: '2024-08-10',
    values: {
      sales: 379063,
      revenue: 233799.55788350035,
      add_revenue: 85800.93259466258,
      channel_of_cost: 27917.967405337422,
      tax_fx: 31544.542116499633,
    },
  },
  {
    category: '2024-08-11',
    values: {
      sales: 320714,
      revenue: 198286.6996941983,
      add_revenue: 73411.20385865828,
      channel_of_cost: 22802.996141341715,
      tax_fx: 26213.1003058017,
    },
  },
  {
    category: '2024-08-12',
    values: {
      sales: 308332,
      revenue: 184297.9160657482,
      add_revenue: 70830.67503911782,
      channel_of_cost: 21668.924960882174,
      tax_fx: 31534.48393425184,
    },
  },
  {
    category: '2024-08-13',
    values: {
      sales: 338474,
      revenue: 196432.44513160374,
      add_revenue: 71889.29493896569,
      channel_of_cost: 29652.9050610343,
      tax_fx: 40499.35486839625,
    },
  },
  {
    category: '2024-08-14',
    values: {
      sales: 363703,
      revenue: 218406.41551998252,
      add_revenue: 82638.78878011061,
      channel_of_cost: 26472.11121988938,
      tax_fx: 36185.68448001754,
    },
  },
  {
    category: '2024-08-15',
    values: {
      sales: 395236,
      revenue: 230408.04763787542,
      add_revenue: 89363.34124442743,
      channel_of_cost: 29207.458755572563,
      tax_fx: 46257.15236212461,
    },
  },
  {
    category: '2024-08-16',
    values: {
      sales: 293024,
      revenue: 170781.10275394743,
      add_revenue: 66422.52170824344,
      channel_of_cost: 21484.67829175657,
      tax_fx: 34335.697246052565,
    },
  },
  {
    category: '2024-08-17',
    values: {
      sales: 292614,
      revenue: 173628.8021694907,
      add_revenue: 66116.8931513565,
      channel_of_cost: 21667.306848643493,
      tax_fx: 31200.99783050931,
    },
  },
  {
    category: '2024-08-18',
    values: {
      sales: 381622,
      revenue: 229233.56017517758,
      add_revenue: 85685.85917467003,
      channel_of_cost: 28800.740825329976,
      tax_fx: 37901.83982482245,
    },
  },
  {
    category: '2024-08-19',
    values: {
      sales: 604199,
      revenue: 360004.4769529496,
      add_revenue: 130412.4872491848,
      channel_of_cost: 50847.2127508152,
      tax_fx: 62934.82304705041,
    },
  },
  {
    category: '2024-08-20',
    values: {
      sales: 701844,
      revenue: 416035.0976090373,
      add_revenue: 151945.4357265509,
      channel_of_cost: 58607.764273449095,
      tax_fx: 75255.70239096272,
    },
  },
  {
    category: '2024-08-21',
    values: {
      sales: 800526,
      revenue: 467951.9851743585,
      add_revenue: 174623.51984425567,
      channel_of_cost: 65534.2801557443,
      tax_fx: 92416.21482564158,
    },
  },
  {
    category: '2024-08-22',
    values: {
      sales: 901538,
      revenue: 553772.45525269,
      add_revenue: 196772.85646262794,
      channel_of_cost: 73688.54353737202,
      tax_fx: 77304.14474731003,
    },
  },
]

export const revenue_by_country = [
  { category: 'US', value: 13712990.6 },
  { category: 'VN', value: 29520.80457 },
  { category: 'MX', value: 4172758.65912 },
  { category: 'SG', value: 1269998.38227 },
  { category: 'CL', value: 596935.60808 },
  { category: 'DE', value: 22907.56118 },
  { category: 'CH', value: 11458.30432 },
  { category: 'PR', value: 102430.0 },
  { category: 'ML', value: 27166.26348 },
  { category: 'SV', value: 17465.0 },
  { category: 'VE', value: 17465.0 },
  { category: 'KZ', value: 28839.47187 },
  { category: 'AU', value: 272327.373495 },
  { category: 'SE', value: 72405.0 },
  { category: 'CO', value: 133542.41501 },
  { category: 'MN', value: 22470.0 },
  { category: 'RO', value: 60670.153115 },
  { category: 'CA', value: 899543.243455 },
  { category: 'CN', value: 116529.02446 },
  { category: 'NZ', value: 26490.86622 },
  { category: 'CR', value: 155046.26221 },
  { category: 'BO', value: 7650.874635 },
  { category: 'BR', value: 938089.17318 },
  { category: 'GB', value: 80089.651585 },
  { category: 'TH', value: 9962.941845 },
  { category: 'NI', value: 29955.0 },
  { category: 'ES', value: 35708.764375 },
  { category: 'PE', value: 177147.911645 },
  { category: 'KR', value: 443633.58421 },
  { category: 'AR', value: 1406800.0 },
  { category: 'IT', value: 3251.547065 },
  { category: 'PL', value: 290566.65079 },
  { category: 'JP', value: 699313.93429 },
  { category: 'PT', value: 22917.57762 },
  { category: 'GT', value: 74850.0 },
  { category: 'HN', value: 52430.0 },
  { category: 'IO', value: 177147 },
  { category: 'FR', value: 443633 },
  { category: 'GR', value: 22917 },
  { category: 'AU', value: 74850 },
  { category: 'KR', value: 60670 },
  { category: 'CN', value: 60670 },
]

const conversion_game_hub_first_purchase_by_date = {
  data: [
    { date: '2024-07-23', users_count: 1880, payment_users_count: 9 },
    { date: '2024-07-24', users_count: 1726, payment_users_count: 16 },
    { date: '2024-07-25', users_count: 1627, payment_users_count: 20 },
    { date: '2024-07-26', users_count: 1691, payment_users_count: 23 },
    { date: '2024-07-27', users_count: 1663, payment_users_count: 16 },
    { date: '2024-07-28', users_count: 1753, payment_users_count: 3 },
    { date: '2024-07-29', users_count: 1616, payment_users_count: 10 },
    { date: '2024-07-30', users_count: 1447, payment_users_count: 12 },
    { date: '2024-07-31', users_count: 1490, payment_users_count: 11 },
    { date: '2024-08-01', users_count: 1518, payment_users_count: 19 },
    { date: '2024-08-02', users_count: 1511, payment_users_count: 9 },
    { date: '2024-08-03', users_count: 1733, payment_users_count: 18 },
    { date: '2024-08-04', users_count: 1778, payment_users_count: 7 },
    { date: '2024-08-05', users_count: 1582, payment_users_count: 13 },
    { date: '2024-08-06', users_count: 1707, payment_users_count: 16 },
    { date: '2024-08-07', users_count: 1708, payment_users_count: 4 },
    { date: '2024-08-08', users_count: 1736, payment_users_count: 110 },
    { date: '2024-08-09', users_count: 1603, payment_users_count: 120 },
    { date: '2024-08-10', users_count: 1836, payment_users_count: 113 },
    { date: '2024-08-11', users_count: 1924, payment_users_count: 90 },
    { date: '2024-08-12', users_count: 1342, payment_users_count: 150 },
    { date: '2024-08-13', users_count: 2358, payment_users_count: 132 },
    { date: '2024-08-14', users_count: 1885, payment_users_count: 102 },
    { date: '2024-08-15', users_count: 1805, payment_users_count: 186 },
    { date: '2024-08-16', users_count: 1780, payment_users_count: 144 },
    { date: '2024-08-17', users_count: 1918, payment_users_count: 166 },
    { date: '2024-08-18', users_count: 1780, payment_users_count: 190 },
    { date: '2024-08-19', users_count: 1591, payment_users_count: 170 },
    { date: '2024-08-20', users_count: 1648, payment_users_count: 180 },
    { date: '2024-08-21', users_count: 621, payment_users_count: 160 },
  ],
  prev_data: [
    { date: '2024-06-23', users_count: 0, payment_users_count: 0 },
    { date: '2024-06-24', users_count: 0, payment_users_count: 0 },
    { date: '2024-06-25', users_count: 0, payment_users_count: 0 },
    { date: '2024-06-26', users_count: 0, payment_users_count: 0 },
    { date: '2024-06-27', users_count: 0, payment_users_count: 0 },
    { date: '2024-06-28', users_count: 0, payment_users_count: 0 },
    { date: '2024-06-29', users_count: 0, payment_users_count: 0 },
    { date: '2024-06-30', users_count: 0, payment_users_count: 0 },
    { date: '2024-07-01', users_count: 16817, payment_users_count: 154 },
    { date: '2024-07-02', users_count: 9979, payment_users_count: 106 },
    { date: '2024-07-03', users_count: 6282, payment_users_count: 83 },
    { date: '2024-07-04', users_count: 4693, payment_users_count: 43 },
    { date: '2024-07-05', users_count: 2162, payment_users_count: 16 },
    { date: '2024-07-06', users_count: 1499, payment_users_count: 18 },
    { date: '2024-07-07', users_count: 1140, payment_users_count: 14 },
    { date: '2024-07-08', users_count: 1089, payment_users_count: 15 },
    { date: '2024-07-09', users_count: 18838, payment_users_count: 223 },
    { date: '2024-07-10', users_count: 39208, payment_users_count: 141 },
    { date: '2024-07-11', users_count: 10382, payment_users_count: 53 },
    { date: '2024-07-12', users_count: 6005, payment_users_count: 28 },
    { date: '2024-07-13', users_count: 4879, payment_users_count: 30 },
    { date: '2024-07-14', users_count: 3989, payment_users_count: 31 },
    { date: '2024-07-15', users_count: 3133, payment_users_count: 21 },
    { date: '2024-07-16', users_count: 2850, payment_users_count: 18 },
    { date: '2024-07-17', users_count: 2406, payment_users_count: 27 },
    { date: '2024-07-18', users_count: 2283, payment_users_count: 19 },
    { date: '2024-07-19', users_count: 1992, payment_users_count: 32 },
    { date: '2024-07-20', users_count: 2098, payment_users_count: 19 },
    { date: '2024-07-21', users_count: 1953, payment_users_count: 18 },
    { date: '2024-07-22', users_count: 1943, payment_users_count: 9 },
  ],
}

const revenue_by_dates = [
  { date: '2024-07-23', revenue: 156243, prev_revenue: 106673 },
  { date: '2024-07-24', revenue: 136786, prev_revenue: 195666 },
  { date: '2024-07-25', revenue: 139999, prev_revenue: 127605 },
  { date: '2024-07-26', revenue: 139743, prev_revenue: 197281 },
  { date: '2024-07-27', revenue: 186121, prev_revenue: 211493 },
  { date: '2024-07-28', revenue: 229866, prev_revenue: 235641 },
  { date: '2024-07-29', revenue: 187763, prev_revenue: 222881 },
  { date: '2024-07-30', revenue: 276924, prev_revenue: 208492 },
  { date: '2024-07-31', revenue: 190929, prev_revenue: 205436 },
  { date: '2024-08-01', revenue: 287782, prev_revenue: 225948 },
  { date: '2024-08-02', revenue: 214161, prev_revenue: 203643 },
  { date: '2024-08-03', revenue: 268182, prev_revenue: 237259 },
  { date: '2024-08-04', revenue: 308237, prev_revenue: 233204 },
  { date: '2024-08-05', revenue: 245725, prev_revenue: 244101 },
  { date: '2024-08-06', revenue: 303898, prev_revenue: 313850 },
  { date: '2024-08-07', revenue: 293007, prev_revenue: 323456 },
  { date: '2024-08-08', revenue: 317123, prev_revenue: 325687 },
  { date: '2024-08-09', revenue: 283621, prev_revenue: 275523 },
  { date: '2024-08-10', revenue: 379063, prev_revenue: 297972 },
  { date: '2024-08-11', revenue: 320714, prev_revenue: 302292 },
  { date: '2024-08-12', revenue: 308332, prev_revenue: 384737 },
  { date: '2024-08-13', revenue: 338474, prev_revenue: 334488 },
  { date: '2024-08-14', revenue: 363703, prev_revenue: 354056 },
  { date: '2024-08-15', revenue: 395236, prev_revenue: 422591 },
  { date: '2024-08-16', revenue: 293024, prev_revenue: 324188 },
  { date: '2024-08-17', revenue: 292614, prev_revenue: 349543 },
  { date: '2024-08-18', revenue: 381622, prev_revenue: 391085 },
  { date: '2024-08-19', revenue: 604199, prev_revenue: 374277 },
  { date: '2024-08-20', revenue: 701844, prev_revenue: 386068 },
  { date: '2024-08-21', revenue: 800526, prev_revenue: 458230 },
  { date: '2024-08-22', revenue: 901538, prev_revenue: 394481 },
]

const hub_visit_data = [
  { category: '2024-07-23', value: 102343, prev_value: 332110 },
  { category: '2024-07-24', value: 238579, prev_value: 476701 },
  { category: '2024-07-25', value: 319134, prev_value: 123456 },
  { category: '2024-07-26', value: 452938, prev_value: 804201 },
  { category: '2024-07-27', value: 590284, prev_value: 568783 },
  { category: '2024-07-28', value: 247153, prev_value: 758822 },
  { category: '2024-07-29', value: 500293, prev_value: 346214 },
  { category: '2024-07-30', value: 847365, prev_value: 997382 },
  { category: '2024-07-31', value: 675318, prev_value: 234894 },
  { category: '2024-08-01', value: 899712, prev_value: 405573 },
  { category: '2024-08-02', value: 194872, prev_value: 899342 },
  { category: '2024-08-03', value: 758993, prev_value: 658458 },
  { category: '2024-08-04', value: 457823, prev_value: 974235 },
  { category: '2024-08-05', value: 247853, prev_value: 245901 },
  { category: '2024-08-06', value: 525897, prev_value: 487654 },
  { category: '2024-08-07', value: 682793, prev_value: 234567 },
  { category: '2024-08-08', value: 921012, prev_value: 544512 },
  { category: '2024-08-09', value: 438234, prev_value: 657432 },
  { category: '2024-08-10', value: 846283, prev_value: 312456 },
  { category: '2024-08-11', value: 594715, prev_value: 758923 },
  { category: '2024-08-12', value: 707134, prev_value: 444831 },
  { category: '2024-08-13', value: 813765, prev_value: 512234 },
  { category: '2024-08-14', value: 524671, prev_value: 678293 },
  { category: '2024-08-15', value: 802123, prev_value: 765542 },
  { category: '2024-08-16', value: 824314, prev_value: 450721 },
  { category: '2024-08-17', value: 392150, prev_value: 841324 },
  { category: '2024-08-18', value: 528723, prev_value: 602459 },
  { category: '2024-08-19', value: 743243, prev_value: 490234 },
  { category: '2024-08-20', value: 634823, prev_value: 832154 },
  { category: '2024-08-21', value: 891422, prev_value: 598342 },
  { category: '2024-08-22', value: 954678, prev_value: 793024 },
]

const mau = { value: 145620.0, prev_value: 77716.0 }
const arpdau = { value: 375, prev_value: 131 }
const dau = { value: 8347.0, prev_value: 3883.0 }

const revenue = { value: 28667578.883865, prev_value: 21657214.71199 }
const conversion = { value: 11.43, prev_value: 9.52 }
const arppu = { value: 2600, prev_value: 1500 }

export function create30Data(campaigs: Campaign[], items: ItemRead[]): AllData {
  const payments_success_percent = {
    value:
      (100 * payments_success_fail_by_dates.reduce((acc, it) => acc + it.success_payment, 0)) /
      payments_success_fail_by_dates.length,
    prev_value:
      (100 * payments_success_fail_by_dates.reduce((acc, it) => acc + it.success_payment, 0)) /
      payments_success_fail_by_dates.length,
  }

  return {
    arppu,
    revenue,
    revenue_by_country,
    top_items: createTopItemsData(items, '30'),
    mau,
    revenue_by_date_device: JSON.parse(JSON.stringify(revenue_by_date_device)),
    conversion,
    dau,
    top_items_sum,
    triggers_by_date: JSON.parse(JSON.stringify(triggers_by_date)),
    revenue_by_dates: JSON.parse(JSON.stringify(revenue_by_dates)),
    sales_by_date: JSON.parse(JSON.stringify(sales_by_date)),
    payments_success_percent,
    payments_success_fail_by_dates: JSON.parse(JSON.stringify(payments_success_fail_by_dates)),
    sankey_top_sku: createSankeyData(campaigs, items, '30'),
    arpdau,
    conversion_game_hub_first_purchase_by_date: JSON.parse(JSON.stringify(conversion_game_hub_first_purchase_by_date)),
    hub_visit_data: JSON.parse(JSON.stringify(hub_visit_data)),
    total_user_count: { value: 1_623_341 },
  }
}
// do not remove, use for generate Sales chart data
// function getRandomPercentage(from, to) {
//   return from + Math.random() * (to - from)
// }
//
// JSON.stringify(
//   revenue_by_dates.map(it => {
//     let coc = getRandomPercentage(0.07, 0.09)
//     let taxFx = getRandomPercentage(0.08, 0.12)
//
//     //it.revenue - 100%
//
//     let channel_of_cost = coc * it.revenue
//     let tax_fx = taxFx * it.revenue
//
//     let add_revenue = it.revenue * (0.3 - coc)
//
//     return {
//       category: it.date,
//       values: {
//         sales: it.revenue,
//         revenue: it.revenue - tax_fx - add_revenue - channel_of_cost,
//         add_revenue: add_revenue,
//         channel_of_cost: channel_of_cost,
//         tax_fx: tax_fx,
//       },
//     }
//   }),
// )
