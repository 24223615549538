import { ChevronLeft } from '@/icons'
import { useTranslation } from 'react-i18next'
import { HTMLAttributes } from 'react'
import { cn } from '@dashboard/ui'

export interface BackButtonProps extends HTMLAttributes<HTMLDivElement> {
  text: string
  onClick: () => void
  extraRight?: React.ReactNode
  icon?: React.ReactNode
  datatestid?: string
}

export const BackButton = ({ onClick, icon, datatestid, extraRight, text, ...rest }: BackButtonProps) => {
  const { t } = useTranslation()

  return (
    <div {...rest} className={cn('flex items-center gap-3', rest.className)}>
      {icon}
      <div>
        <div
          data-testid={datatestid}
          className="flex cursor-pointer items-center gap-1 text-caption-md text-text-brand-primary"
          onClick={onClick}
        >
          <ChevronLeft size={14} /> <span> {t('Back')}</span>
        </div>
        <div className="flex items-center gap-3 truncate whitespace-nowrap text-[18px] font-medium text-text-primary">
          {text}
          {extraRight}
        </div>
      </div>
    </div>
  )
}
