import i18next from 'i18next'
import { CSSProperties, useContext } from 'react'

import { CalcPlayerAttributes, EMPTY_VALUE, OperatorGeneralType, OperatorType, TokenValue } from './types'
import { AttrType, Token } from '../../../api/dashboard'
import { Select } from '@/ui'
import { getAttrType } from './getAttrType'
import { CampaignContext, ICampaignContext } from '../Context'
import { findAttr } from './findAttr'

export default function OperatorSelector(props: {
  attrToken: Token
  value: TokenValue
  onChange: (v: TokenValue) => void
  style?: CSSProperties
  error?: boolean
}) {
  const context = useContext(CampaignContext) as ICampaignContext
  let items = Object.values(OperatorType)
  items = items.filter(it => it != OperatorType.and && it != OperatorType.or)

  let attr = findAttr(props.attrToken.value as string)

  if (attr?.generalType == OperatorGeneralType.contains_or_not) {
    return (
      <Select
        placeholder={i18next.t('campaign.condition.select-condition')}
        style={{ width: '100%', ...props.style }}
        value={props.value as string}
        dropDownWidth="200px"
        onChange={v => props.onChange(v as TokenValue)}
        options={[
          { value: OperatorType['=='], children: i18next.t('campaign.operator.contains') },
          { value: OperatorType['!='], children: i18next.t('campaign.operator.not_contains') },
        ]}
      />
    )
  }

  switch (getAttrType(props.attrToken, context)) {
    case AttrType.Number:
      items = items.filter(it => it != OperatorType.in_list && it != OperatorType.not_in_list)
      break
    case AttrType.Boolean:
      items = [OperatorType['!='], OperatorType['==']]
      break
    case AttrType.List:
    case AttrType.String:
      items = [
        OperatorType['!='],
        OperatorType['=='],
        OperatorType.is_none,
        OperatorType.is_not_none,
        OperatorType.in_list,
        OperatorType.not_in_list,
      ]

      if (props.attrToken.value == CalcPlayerAttributes.country) {
        items = items.filter(it => it != OperatorType.in_list && it != OperatorType.not_in_list)
      }

      break
    case AttrType.Date:
      items = items.filter(
        it =>
          it != OperatorType['=='] &&
          it != OperatorType['!='] &&
          it != OperatorType.in_range &&
          it != OperatorType.not_in_range &&
          it != OperatorType.in_list &&
          it != OperatorType.not_in_list,
      )
      break
  }

  let hasValue = items.includes(props.value as OperatorType)

  return (
    <Select
      placeholder={i18next.t('campaign.condition.select-condition')}
      style={{ width: '100%', ...props.style }}
      dropDownWidth="200px"
      value={hasValue && props.value ? (props.value as string) : EMPTY_VALUE}
      onChange={v => props.onChange(v as TokenValue)}
      options={items.map(it => ({ value: it, children: i18next.t(`campaign.operator.${it}`) }))}
    />
  )
}
