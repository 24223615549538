import { useTranslation } from 'react-i18next'
import { SelectFilter, SelectFilterItem, SelectFilterProps } from '@dashboard/ui'
import { FilterType } from '@/api/dashboard'

interface SelectFilterDateProps extends Omit<SelectFilterProps, 'children' | 'items'> {}

export const SelectFilterDate = ({ ...rest }: SelectFilterDateProps) => {
  const { t } = useTranslation()

  const items: SelectFilterItem[] = [
    { children: t('filter.12hours'), value: '12hours' },
    { children: t('filter.24hours'), value: '24hours' },
    { children: t('filter.today'), value: FilterType.Today },
    { children: t('filter.yesterday'), value: FilterType.Yesterday },
    { children: t('filter.7days'), value: '7' },
    { children: t('filter.14days'), value: '14' },
    { children: t('filter.30days'), value: '30' },
  ]

  return (
    <SelectFilter {...rest} items={items}>
      {t('filter.date.label')}
    </SelectFilter>
  )
}
