import { formatMoney, formatNumber } from '@/util'
import AnimatedDots from '@/components/animated-dots/AnimatedDots'
import { RewardPointOutline } from '@/icons'

export enum PlayerMeasureValueType {
  value,
  money,
  lp,
}

export const PlayerMeasureValue = (props: {
  isLoading?: boolean
  label: string
  value: number
  type?: PlayerMeasureValueType
}) => {
  const renderValue = () => {
    switch (props.type) {
      case PlayerMeasureValueType.money:
        return formatMoney(props.value)
      case PlayerMeasureValueType.lp:
        return (
          <div className="flex items-center gap-1">
            <RewardPointOutline /> {formatNumber(props.value)}
          </div>
        )
      case PlayerMeasureValueType.value:
      default:
        return formatNumber(props.value)
    }
  }

  return (
    <div className="flex w-[315px] flex-col gap-3">
      <div className="text-caption-sm font-semibold uppercase text-text-quarterary-hover">{props.label}</div>
      <div className="text-t4 text-text-primary">{props.isLoading ? <AnimatedDots /> : renderValue()}</div>
    </div>
  )
}
