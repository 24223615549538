import {
  BrowserPopupNotificationActionNode,
  CreateCouponNode,
  CreateUserStoreSettingsNode,
  CreateVirtualSKUNode,
  Currency,
  EmailActionNode,
  GraphRoot,
  Item,
  ItemBonusOfferActionNode,
  ItemDiscountOfferActionNode,
  ItemOfferActionNode,
  Node,
  StoreItemRead,
  Token,
} from '../../api/dashboard'
import { KeyValue } from '../../types'
import { EventActionNodeType } from './types'
import i18next from 'i18next'
import { isCustomEvent } from './Settings'
import { DescContext } from './blocks/descriptions/types'
import { DateFilterType } from './expr/types'

const MAX_NODE_TRAVERSE_COUNT = 200

export function getPreviewItemObject(
  item: Item | undefined,
  prevNode:
    | CreateCouponNode
    | ItemBonusOfferActionNode
    | ItemDiscountOfferActionNode
    | ItemOfferActionNode
    | CreateVirtualSKUNode
    | undefined
    | null = undefined,
  storeItem?: StoreItemRead,
) {
  let params: KeyValue = {}

  let default_param_values: KeyValue = {
    price: 0,
    price_after_discount: 0,
  }

  if (!item) {
    return default_param_values
  }

  let discountPercent = 0
  let bonusPercent = 0
  let expireAt = 0
  let name = item.name
  let desc = item.description
  let image_url = item.image_url

  if (prevNode) {
    if (
      prevNode.model_type == EventActionNodeType.ItemDiscountOfferActionNode ||
      prevNode.model_type == EventActionNodeType.ItemOfferActionNode ||
      prevNode.model_type == EventActionNodeType.CreateVirtualSKUNode
    ) {
      if (prevNode.discount_percent) {
        discountPercent = prevNode.discount_percent
        if (prevNode.duration) {
          expireAt = new Date().getTime() - prevNode.duration
        }
      }
    }

    if (prevNode.model_type == EventActionNodeType.CreateCouponNode) {
      if (prevNode.item_id) {
        if (prevNode.duration) {
          expireAt = new Date().getTime() - prevNode.duration
        }
        discountPercent = prevNode.discount_percent as number

        params['free_item_coupon_code'] = 'code'
      }
    }

    if (
      prevNode.model_type == EventActionNodeType.ItemBonusOfferActionNode ||
      prevNode.model_type == EventActionNodeType.ItemOfferActionNode ||
      prevNode.model_type == EventActionNodeType.CreateVirtualSKUNode
    ) {
      if (prevNode.bonus_percent) {
        bonusPercent = prevNode.bonus_percent
      }
    }

    if (prevNode.model_type == EventActionNodeType.CreateVirtualSKUNode && prevNode.is_free_item) {
      params['is_free_item'] = prevNode.is_free_item
    }

    if (
      prevNode.model_type == EventActionNodeType.ItemBonusOfferActionNode ||
      prevNode.model_type == EventActionNodeType.ItemDiscountOfferActionNode ||
      prevNode.model_type == EventActionNodeType.ItemOfferActionNode
    ) {
      if (prevNode.item_name) {
        name = prevNode.item_name
      }

      if (prevNode.item_description) {
        desc = prevNode.item_description
      }

      if (prevNode.item_image_url) {
        image_url = prevNode.item_image_url
      }
    }
  }

  params['image_url'] = image_url
  params['type'] = item.type
  params['sku'] = item.sku
  params['name'] = name
  params['description'] = desc

  let p = item.reward_points_price || item.currency == Currency.RP ? item.reward_points_price! * 100 : item.price

  params['price'] = p
  params['currency'] = item.reward_points_price || item.currency == Currency.RP ? Currency.RP : item.currency

  if (!discountPercent) {
    discountPercent = item.discount_percent || 0
  }

  if (!bonusPercent) {
    bonusPercent = item.bonus_percent || 0
  }

  if (discountPercent) {
    p = p - (p * discountPercent) / 100
    params['price_after_discount'] = p
  } else {
    params['price_after_discount'] = p
  }

  params['discount_percent'] = discountPercent
  params['bonus_percent'] = bonusPercent
  params['expire_at'] = expireAt

  if (storeItem?.custom_badge) {
    params['custom_badge'] = storeItem.custom_badge
  }

  if (storeItem?.is_free_item) {
    params['is_free_item'] = storeItem.is_free_item
  }

  return params
}

export function getPreviewHubItemParams(
  item: Item | undefined,
  prevNode:
    | CreateCouponNode
    | ItemBonusOfferActionNode
    | ItemDiscountOfferActionNode
    | ItemOfferActionNode
    | CreateVirtualSKUNode
    | undefined
    | null = undefined,
  storeItem: StoreItemRead | undefined = undefined,
): string[] {
  let obj = getPreviewItemObject(item, prevNode, storeItem)

  return Object.keys(obj).map(
    key => encodeURIComponent(key) + '=' + encodeURIComponent((obj as unknown as KeyValue)[key] as string),
  )
}

export function renderTitleBodyProps(text: string | undefined, props: KeyValue) {
  if (!text) {
    return ''
  }
  let result = text

  for (let key in props) {
    result = result.replaceAll(`{{${key}}}`, props[key] as string)
  }
  return result
}

export function getMergedPreviewProps(
  node: EmailActionNode | BrowserPopupNotificationActionNode,
  context: DescContext,
  storeItems?: StoreItemRead[],
): {
  merged_props: KeyValue
  item: Item | null
  bonus_percent: string
  discount_percent: string
  prev_node?:
    | CreateCouponNode
    | ItemBonusOfferActionNode
    | ItemDiscountOfferActionNode
    | ItemOfferActionNode
    | CreateVirtualSKUNode
    | CreateUserStoreSettingsNode
    | undefined
} {
  let item: Item | undefined = undefined
  let prevNode:
    | CreateCouponNode
    | ItemBonusOfferActionNode
    | ItemDiscountOfferActionNode
    | ItemOfferActionNode
    | CreateVirtualSKUNode
    | CreateUserStoreSettingsNode
    | undefined
    | null = undefined

  let storeItem = storeItems?.find(it => it.id == node.store_item_id)

  if (node.link_created_offer) {
    prevNode = findPrevEntityNode(context.graph.nodes!, node.id)

    if (prevNode?.model_type == EventActionNodeType.CreateUserStoreSettingsNode) {
      return { merged_props: {} as KeyValue, item: null, bonus_percent: '', discount_percent: '' }
    }

    if (prevNode) {
      item = context.items.find(it => it.id == (prevNode as CreateVirtualSKUNode).item_id)
      if (!item && (prevNode as CreateVirtualSKUNode).use_event_item) {
        item = context.items[0]
      }
    }
  } else if (node.item_id) {
    item = context.items.find(it => it.id == node.item_id)
  }

  if (!item) {
    return { merged_props: {} as KeyValue, item: null, bonus_percent: '', discount_percent: '' }
  }

  let itemProps = getPreviewItemObject(item, prevNode as CreateVirtualSKUNode, storeItem)
  let bonus_percent = itemProps['bonus_percent'] ? itemProps['bonus_percent'] + '%' : '...'
  let discount_percent = itemProps['discount_percent'] ? itemProps['discount_percent'] + '%' : '...'

  let couponProps: KeyValue = {}

  if (prevNode && prevNode?.model_type == EventActionNodeType.CreateCouponNode) {
    couponProps = {
      'coupon.code': 'ASKDF34',
      'coupon.name': prevNode.title,
    }

    if (prevNode.discount_percent) {
      couponProps['coupon.discount'] = prevNode.discount_percent
      discount_percent = prevNode.discount_percent + '%'
    }

    if (prevNode.bonus_percent) {
      couponProps['coupon.bonus'] = prevNode.bonus_percent
      bonus_percent = prevNode.bonus_percent + '%'
    }
  }

  let itemPropsWithPrefix: KeyValue = {}
  Object.keys(itemProps).forEach(key => (itemPropsWithPrefix[`item.${key}`] = itemProps[key]))

  if (storeItem?.custom_badge) {
    itemPropsWithPrefix['item.custom_badge'] = storeItem.custom_badge
  }

  if (storeItem?.is_free_item) {
    itemPropsWithPrefix['item.is_free_item'] = storeItem.is_free_item
  }

  return {
    merged_props: { ...itemPropsWithPrefix, ...couponProps },
    item,
    bonus_percent,
    discount_percent,
    prev_node: prevNode!,
  }
}

export const TEMPLATE_PROPS = {
  COUPON: [
    {
      value: '{{coupon.discount}}',
      name: 'Coupon Discount',
    },
    {
      value: '{{coupon.bonus}}',
      name: 'Coupon Bonus',
    },
    {
      value: '{{coupon.code}}',
      name: 'Coupon Code',
    },
    {
      value: '{{coupon.name}}',
      name: 'Coupon Name',
    },
  ],
  ITEM: [
    {
      value: '{{item.name}}',
      name: 'Item Name',
    },
    {
      value: '{{item.price}}',
      name: 'Item Price',
    },
    {
      value: '{{item.discount}}',
      name: 'Item Discount',
    },
    {
      value: '{{item.bonus}}',
      name: 'Item Bonus',
    },
  ],
}

export function isCommunicationNode(node: EventActionNodeType): boolean {
  switch (node) {
    case EventActionNodeType.EmailActionNode:
    case EventActionNodeType.MobilePushActionNode:
    case EventActionNodeType.BrowserPushNotificationActionNode:
    case EventActionNodeType.MobilePopupActionNode:
    case EventActionNodeType.BrowserPopupNotificationActionNode:
    case EventActionNodeType.CreateItemBannerActionNode:
      return true
  }
  return false
}

export function isInvisibleEntityNode(node: EventActionNodeType): boolean {
  switch (node) {
    case EventActionNodeType.CreateCouponNode:
    case EventActionNodeType.ItemDiscountOfferActionNode:
    case EventActionNodeType.ItemBonusOfferActionNode:
    case EventActionNodeType.ItemOfferActionNode:
      return true
  }
  return false
}

function isPrevNode(node: Node, id: string | null): boolean {
  if (node.model_type == EventActionNodeType.ConditionNode) {
    return node.true_node_id === id || node.false_node_id === id
  }

  if (node.model_type == EventActionNodeType.SplitNode) {
    return node.distribution?.find(it => it.next_node_id === id) != null
  }

  return node.next_node_id === id
}

interface FindState {
  matches: Node[]
  idx: number
  id: string
}

export function findPrevEntityNode(nodes: Record<string, Node>, goalNodeId: string) {
  let current_node = nodes[goalNodeId]
  let arr_nodes = Object.values(nodes)
  let count = 0
  let stackMatches: FindState[] = []
  while (current_node && count < MAX_NODE_TRAVERSE_COUNT) {
    switch (current_node.model_type) {
      case EventActionNodeType.ItemDiscountOfferActionNode:
      case EventActionNodeType.ItemBonusOfferActionNode:
      case EventActionNodeType.ItemOfferActionNode:
      case EventActionNodeType.CreateVirtualSKUNode:
      case EventActionNodeType.CreateUserStoreSettingsNode:
        return current_node
      case EventActionNodeType.CreateCouponNode:
        if (current_node.item_id || current_node.use_event_item) {
          return current_node
        }
    }

    let matches = arr_nodes.filter(x => isPrevNode(x, current_node.id))
    if (matches.length > 0) {
      stackMatches.push({ id: current_node.id, matches: matches, idx: 0 })

      current_node = matches[0]
      if (current_node.id === goalNodeId) {
        return null
      }
      count++

      continue
    }
    if (stackMatches.length > 0) {
      let prev = stackMatches.pop() as FindState
      if (prev.idx < prev.matches.length - 1) {
        current_node = prev.matches[++prev.idx]
        count++
        continue
      }
    }

    break
  }
  return null
}

export function findPrevCommunicationNode(nodes: Record<string, Node>, goalNodeId: string, breakNodeId: string) {
  let current_node = nodes[goalNodeId]
  let arr_nodes = Object.values(nodes)
  let count = 0
  while (current_node && count < MAX_NODE_TRAVERSE_COUNT) {
    if (isCommunicationNode(current_node.model_type as EventActionNodeType)) {
      return current_node
    }

    let matches = arr_nodes.filter(x => isPrevNode(x, current_node.id))
    if (matches.length > 0) {
      current_node = matches[0]
      if (current_node.id === goalNodeId || current_node.id === breakNodeId) {
        return null
      }
      count++
      continue
    }
    break
  }
  return null
}

export function calculateWarning(node: Node, graph: GraphRoot) {
  let warningText = ''
  let warningDescText = ''

  const emptyWarning = ['', '', '']

  if (node.next_node_id || !graph.nodes || isCommunicationNode(node.model_type as EventActionNodeType)) {
    return emptyWarning
  }

  warningText = i18next.t('campaign.block.exit.warning')

  let nodeTitle = node.title || i18next.t(`campaign.node-type.${node.model_type}`)

  if (isInvisibleEntityNode(node.model_type as EventActionNodeType)) {
    return [warningText, i18next.t('campaign.block.exit.warning.desc', { name: nodeTitle }), nodeTitle]
  }

  let prevEntityNode = findPrevEntityNode(graph.nodes, node.id)

  if (!prevEntityNode) {
    return emptyWarning
  }

  if (findPrevCommunicationNode(graph.nodes, node.id, prevEntityNode.id)) {
    return emptyWarning
  }

  nodeTitle = prevEntityNode.title || i18next.t(`campaign.node-type.${prevEntityNode.model_type}`)

  warningDescText = i18next.t('campaign.block.exit.warning.desc', { name: nodeTitle })

  return [warningText, warningDescText, nodeTitle]
}

export function isIntegrationRequire(type: EventActionNodeType) {
  switch (type) {
    case EventActionNodeType.WebhookActionNode:
    case EventActionNodeType.EmailActionNode:
    case EventActionNodeType.MobilePopupActionNode:
    case EventActionNodeType.MobilePushActionNode:
      return true
  }
  return false
}

export function getCampaignIconUrl(type: string) {
  const baseUrl = `${import.meta.env.VITE_STATIC_IMAGES_URL}/images/dashboard/liveops/v2`
  let campaignIcon = `${baseUrl}/${type}.webp`
  if (isCustomEvent(type)) {
    campaignIcon = `${baseUrl}/сustom_event.webp`
  }
  return campaignIcon
}

export function calcFilterDate(value: string): number | null {
  if (!value) {
    return null
  }
  const lastDateFilter: string = String(value)
  const arr: string[] = lastDateFilter.split('.')

  if (arr.length < 2) {
    return null
  }

  const parsedDateFilterType = arr[1] as string

  if (parsedDateFilterType === DateFilterType.DATE) {
    const [day, month, year] = arr[0].split('-').map(part => parseInt(part, 10))
    const date = new Date(Date.UTC(year, month - 1, day))
    return Math.floor(date.getTime() / 1000)
  }

  const nowInSeconds: number = Math.floor(Date.now() / 1000)
  const countAgo: number = parseInt(arr[0], 10)

  switch (parsedDateFilterType) {
    case 'day':
      return nowInSeconds - countAgo * 24 * 60 * 60
    case 'hour':
      return nowInSeconds - countAgo * 60 * 60
    case 'minute':
      return nowInSeconds - countAgo * 60
    default:
      return nowInSeconds
  }
}

export function getTokenGroups(tokens: Token[]) {
  const newTokenGroups: Token[][] = []
  const allTokens = tokens || []
  for (let i = 0; i < allTokens.length; i += 4) {
    let attr = allTokens[i]
    let op = allTokens[i + 1]
    let val = allTokens[i + 2]

    newTokenGroups.push([attr, op, val])
    if (allTokens[i + 3]) {
      newTokenGroups.push([allTokens[i + 3]])
    } //"and" or "or"
  }

  return JSON.parse(JSON.stringify(newTokenGroups.filter(it => !!it)))
}
