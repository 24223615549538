import {
  AghanimIcon,
  FacebookIcon,
  Globe1,
  InstagramIcon,
  RedditIcon,
  ThreadsIcon,
  TikTokIcon,
  TwitchIcon,
  XTwitterIcon,
  YouTubeIcon,
} from '@dashboard/ui'
import { CreatorSocialPlatform } from '@/api/dashboard'

export const SOCIAL_ICONS: Record<CreatorSocialPlatform, AghanimIcon> = {
  [CreatorSocialPlatform.Facebook]: FacebookIcon,
  [CreatorSocialPlatform.Tiktok]: TikTokIcon,
  [CreatorSocialPlatform.Youtube]: YouTubeIcon,
  [CreatorSocialPlatform.Instagram]: InstagramIcon,
  [CreatorSocialPlatform.Threads]: ThreadsIcon,
  [CreatorSocialPlatform.XTwitter]: XTwitterIcon,
  [CreatorSocialPlatform.Twitch]: TwitchIcon,
  [CreatorSocialPlatform.Reddit]: RedditIcon,
  [CreatorSocialPlatform.Other]: Globe1,
}
