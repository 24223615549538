import { ReactNode } from 'react'
import { cn } from '@/libs'
import { ButtonIcon } from '@/ui'
import { DeleteOutline } from '@/icons'

type Variant = 'default' | 'primary'
type State = 'preview' | 'editing'

interface DailyRewardsDayFrameProps {
  day: number
  extra: ReactNode
  variant?: Variant
  state?: State
  disabled?: boolean
  onDelete?: () => void
}

export const DailyRewardsDayFrame = ({
  day,
  extra,
  state = 'preview',
  variant = 'default',
  disabled = false,
  onDelete,
}: DailyRewardsDayFrameProps) => (
  <div
    className={cn(
      'relative flex min-h-14 w-28 flex-none flex-col items-center justify-center gap-0.5',
      'rounded-xl p-1.5',
      variant === 'default' && 'bg-fg-secondary',
      variant === 'primary' && 'bg-fg-brand-tertiary',
    )}
  >
    <span className="text-paragraph-sm font-semibold text-text-secondary">{day}</span>
    {extra}
    {state === 'editing' && (
      <ButtonIcon
        className="absolute right-1 top-1"
        size="xs"
        variant="tertiary-destructive"
        disabled={disabled}
        onClick={onDelete}
      >
        <DeleteOutline />
      </ButtonIcon>
    )}
  </div>
)
