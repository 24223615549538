import { useMutation, useQueryClient } from '@tanstack/react-query'
import { dashboardClient } from '@/api'
import { ItemPropertyRead, ItemPropertyUpdate } from '@/api/dashboard'
import { removeItemQueries } from '@/layouts/game-items/api'

interface ItemPropertyUpdateQuery {
  id: string
  update: ItemPropertyUpdate
}

export const useItemPropertyUpdateMutate = (companyId: string, gameId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ id, update }: ItemPropertyUpdateQuery) =>
      dashboardClient.v1.updateItemProperty(companyId, gameId, id, update),
    onSuccess: (_, variables) => {
      queryClient.setQueryData<ItemPropertyRead>(
        ['item-properties', gameId, variables.id],
        old =>
          ({
            ...old,
            ...variables.update,
          }) as ItemPropertyRead,
      )
      queryClient.invalidateQueries({ queryKey: ['item-properties', gameId] })
      removeItemQueries(queryClient, gameId)
    },
  })
}
