import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const TwitchIcon: AghanimIcon = forwardRef(function (props, ref) {
  return (
    <IconBase {...props} viewBox="0 0 48 48" ref={ref}>
      <g clipPath="url(#clip0_7062_8158)">
        <path
          d="M0.0273438 16.1C0.0273438 10.4051 0.0273438 7.55764 1.15321 5.39026C2.10196 3.56382 3.59117 2.07462 5.4176 1.12587C7.58498 0 10.4324 0 16.1273 0H31.9273C37.6222 0 40.4697 0 42.6371 1.12587C44.4635 2.07462 45.9527 3.56382 46.9015 5.39026C48.0273 7.55764 48.0273 10.4051 48.0273 16.1V31.9C48.0273 37.5949 48.0273 40.4424 46.9015 42.6097C45.9527 44.4362 44.4635 45.9254 42.6371 46.8741C40.4697 48 37.6222 48 31.9273 48H16.1273C10.4324 48 7.58498 48 5.4176 46.8741C3.59117 45.9254 2.10196 44.4362 1.15321 42.6097C0.0273438 40.4424 0.0273438 37.5949 0.0273438 31.9V16.1Z"
          fill="#9146FF"
        />
        <path d="M32.8315 14.9392H30.3082V22.5092H32.8315V14.9392Z" fill="white" />
        <path d="M23.369 14.9392H25.8923V22.5092H23.369V14.9392Z" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.1682 8L8.85988 14.3083V37.0184H16.4299V43.3267L22.7382 37.0184H27.7849L39.1399 25.6634V8H15.1682ZM36.6166 24.4017L31.5699 29.4484H26.5232L22.1074 33.8642V29.4484H16.4299V10.5233H36.6166V24.4017Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_7062_8158">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </IconBase>
  );
});
