import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '@/components/icons'

export const MergeCellOutline: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 24 24" ref={ref}>
      <path
        d="M12 2C11.4477 2 11 2.44772 11 3V5C11 5.55228 11.4477 6 12 6C12.5523 6 13 5.55228 13 5V3C13 2.44772 12.5523 2 12 2Z"
        fill="currentColor"
      />
      <path
        d="M12 19C11.4477 19 11 19.4477 11 20V22C11 22.5523 11.4477 23 12 23C12.5523 23 13 22.5523 13 22V20C13 19.4477 12.5523 19 12 19Z"
        fill="currentColor"
      />
      <path
        d="M18.0937 4.54132C17.6217 4.50086 17.0099 4.5 16.11 4.5H15C14.4477 4.5 14 4.05229 14 3.5C14 2.94772 14.4477 2.5 15 2.5L16.1534 2.5C16.9991 2.49999 17.697 2.49997 18.2646 2.54863C18.8544 2.5992 19.3955 2.70782 19.8994 2.97975C20.5863 3.35034 21.1497 3.91375 21.5203 4.60058C21.7922 5.10455 21.9008 5.6456 21.9514 6.23544C22 6.80302 22 7.50093 22 8.34658V16.6534C22 17.4991 22 18.197 21.9514 18.7646C21.9008 19.3544 21.7922 19.8955 21.5203 20.3994C21.1497 21.0863 20.5863 21.6497 19.8994 22.0203C19.3955 22.2922 18.8544 22.4008 18.2646 22.4514C17.697 22.5 16.9991 22.5 16.1534 22.5H15C14.4477 22.5 14 22.0523 14 21.5C14 20.9477 14.4477 20.5 15 20.5H16.11C17.0099 20.5 17.6217 20.4991 18.0937 20.4587C18.5532 20.4193 18.7868 20.348 18.9497 20.2601C19.2931 20.0748 19.5748 19.7931 19.7601 19.4497C19.848 19.2868 19.9193 19.0532 19.9587 18.5937C19.9991 18.1217 20 17.5099 20 16.61V13.9783C20 13.8053 20 13.7188 19.965 13.6528C19.938 13.6019 19.8968 13.5598 19.8464 13.5316C19.7813 13.4952 19.6948 13.4932 19.5218 13.4894L16.9334 13.4322C16.6718 13.4264 16.5409 13.4235 16.4822 13.4758C16.4376 13.5154 16.4131 13.5728 16.4153 13.6324C16.4181 13.711 16.5107 13.8035 16.6957 13.9886L17.4142 14.7071C17.8047 15.0976 17.8047 15.7308 17.4142 16.1213C17.0237 16.5118 16.3905 16.5118 16 16.1213L13 13.1213C12.6095 12.7308 12.6095 12.0976 13 11.7071L16 8.70711C16.3905 8.31658 17.0237 8.31658 17.4142 8.70711C17.8047 9.09763 17.8047 9.73079 17.4142 10.1213L16.6609 10.8746C16.4855 11.05 16.3979 11.1377 16.394 11.2146C16.3911 11.273 16.4139 11.3298 16.4564 11.3699C16.5123 11.4229 16.6363 11.4256 16.8842 11.4311L19.5002 11.4889C19.6802 11.4929 19.7702 11.4949 19.8384 11.4594C19.8911 11.432 19.9345 11.3896 19.963 11.3376C20 11.2702 20 11.1801 20 11.0001V8.39C20 7.49007 19.9991 6.87829 19.9587 6.40627C19.9193 5.94682 19.848 5.71316 19.7601 5.55029C19.5748 5.20688 19.2931 4.92517 18.9497 4.73987C18.7868 4.65199 18.5532 4.58071 18.0937 4.54132Z"
        fill="currentColor"
      />
      <path
        d="M5.90627 4.54132C6.37829 4.50086 6.99007 4.5 7.89 4.5H9C9.55229 4.5 10 4.05229 10 3.5C10 2.94772 9.55229 2.5 9 2.5L7.84658 2.5C7.00092 2.49999 6.30302 2.49997 5.73544 2.54863C5.1456 2.5992 4.60455 2.70782 4.10058 2.97975C3.41375 3.35034 2.85034 3.91375 2.47975 4.60058C2.20782 5.10455 2.0992 5.6456 2.04863 6.23544C1.99997 6.80302 1.99999 7.50093 2 8.34658V16.6534C1.99999 17.4991 1.99997 18.197 2.04863 18.7646C2.0992 19.3544 2.20782 19.8955 2.47975 20.3994C2.85034 21.0863 3.41375 21.6497 4.10058 22.0203C4.60455 22.2922 5.1456 22.4008 5.73544 22.4514C6.30302 22.5 7.00093 22.5 7.84659 22.5H9C9.55229 22.5 10 22.0523 10 21.5C10 20.9477 9.55229 20.5 9 20.5H7.89C6.99007 20.5 6.37829 20.4991 5.90627 20.4587C5.44682 20.4193 5.21316 20.348 5.05029 20.2601C4.70688 20.0748 4.42517 19.7931 4.23988 19.4497C4.15199 19.2868 4.08071 19.0532 4.04132 18.5937C4.00086 18.1217 4 17.5099 4 16.61V13.989C4 13.8125 4 13.7242 4.03598 13.6575C4.06378 13.606 4.10603 13.5638 4.15755 13.536C4.22423 13.5 4.31249 13.5 4.489 13.5H7.09165C7.34633 13.5 7.47367 13.5 7.53102 13.5526C7.57455 13.5925 7.5982 13.6496 7.59565 13.7086C7.59229 13.7864 7.50225 13.8764 7.32217 14.0565L6.58579 14.7929C6.19527 15.1834 6.19527 15.8166 6.58579 16.2071C6.97631 16.5976 7.60948 16.5976 8 16.2071L11 13.2071C11.3905 12.8166 11.3905 12.1834 11 11.7929L8 8.79289C7.60948 8.40237 6.97631 8.40237 6.58579 8.79289C6.19527 9.18342 6.19527 9.81658 6.58579 10.2071L7.32217 10.9435C7.50225 11.1236 7.59229 11.2136 7.59565 11.2914C7.5982 11.3504 7.57455 11.4075 7.53102 11.4474C7.47367 11.5 7.34633 11.5 7.09165 11.5L4.489 11.5C4.31249 11.5 4.22423 11.5 4.15755 11.464C4.10603 11.4362 4.06378 11.394 4.03598 11.3425C4 11.2758 4 11.1875 4 11.011V8.39C4 7.49007 4.00086 6.87829 4.04132 6.40627C4.08071 5.94682 4.15199 5.71316 4.23988 5.55029C4.42517 5.20688 4.70688 4.92517 5.05029 4.73987C5.21316 4.65199 5.44682 4.58071 5.90627 4.54132Z"
        fill="currentColor"
      />
      <path
        d="M13 14.7899C13 14.6043 12.9263 14.4263 12.795 14.295L12.2121 13.7121C12.095 13.595 11.905 13.595 11.7879 13.7121L11.205 14.295C11.0738 14.4263 11 14.6043 11 14.7899V17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17V14.7899Z"
        fill="currentColor"
      />
      <path
        d="M11 8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8V10.2101C13 10.3957 12.9263 10.5737 12.795 10.705L12.2121 11.2879C12.095 11.405 11.905 11.405 11.7879 11.2879L11.205 10.705C11.0738 10.5737 11 10.3957 11 10.2101V8Z"
        fill="currentColor"
      />
    </IconBase>
  )
})
