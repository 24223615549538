import { ReactElement, createElement, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { AghanimIcon, Button, ChevronUp, DotsHorizontal } from '@dashboard/ui'
import { Floating, SidebarLink } from '@/ui'
import { PageSidebar } from '@/layouts/pages/components/PageSidebar'
import { AnimatePresence, motion } from 'framer-motion'
import { getMotionProps } from '@/libs'
import { useDebounce } from '@/hooks'

const entryMotionProps = getMotionProps({
  initial: { height: 0, opacity: 0 },
  animate: { height: 'auto', opacity: 1 },
  exit: { height: 0, opacity: 0, transition: { delay: 0.05 } },
})

export type PageSidebarItem = {
  icon?: AghanimIcon
  to: string
  text: string
  extra?: ReactElement
  children?: PageSidebarItem[]
  add?: {
    text: string
    open: () => void
    disabled?: boolean
    icon?: AghanimIcon
  }
}

interface PageSidebarLinkProps {
  name: string
  item: PageSidebarItem
}

export const PageSidebarLink = ({ name, item }: PageSidebarLinkProps) => {
  const { pathname } = useLocation()

  const [isOpened, setIsOpened] = useState<boolean>(pathname.includes(item.to))
  const debouncedIsOpened = useDebounce(isOpened, 50)
  const isFoldable = useMemo(() => {
    return item.add || (item.children && item.children.length !== 0)
  }, [item])

  useEffect(() => {
    setIsOpened(pathname.includes(item.to))
  }, [pathname, item.to])

  const icon = useMemo(() => {
    if (!item.icon) {
      return null
    }

    if (isFoldable && debouncedIsOpened) {
      return <div className="text-text-brand-primary">{createElement(ChevronUp, { size: 16 })}</div>
    }

    return createElement(item.icon, { size: 16 })
  }, [item.icon, isFoldable, debouncedIsOpened])

  return (
    <>
      <SidebarLink
        data-testid={`${name}/sidebar/${item.to}`}
        to={item.to}
        icon={icon}
        onNavigate={() => setIsOpened(!isOpened)}
        extra={
          item.extra && (
            <Floating menu={item.extra} placement="bottom-end" stopPropagation={true}>
              <Button
                className="px-0 shadow-none"
                variant="outline"
                color="secondary"
                size="xs"
                onClick={e => e.stopPropagation()}
              >
                <DotsHorizontal size={14} />
              </Button>
            </Floating>
          )
        }
      >
        {item.text}
      </SidebarLink>
      <AnimatePresence initial={false}>
        {isFoldable && isOpened && (
          <motion.div {...entryMotionProps} className="-mr-3">
            <PageSidebar
              name={`${name}/sidebar/${item.to}/children`}
              items={item.children ?? []}
              add={item.add ? { ...item.add, type: 'nested' } : undefined}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}
