import { useFormContext } from 'react-hook-form'
import { Block } from '@/api/dashboard'
import { FieldGroup, FieldValidationMessage, Input } from '@/ui'

import { useTranslation } from 'react-i18next'
import { ErrorMessage } from '@hookform/error-message'
import i18next from 'i18next'
import { urlRegexp } from '@/libs'

const IFrameBlockEditor = () => {
  const { t } = useTranslation()

  const {
    register,
    formState: { errors },
  } = useFormContext<Block>()

  return (
    <div>
      <FieldGroup label={t('hub-editor.iframe.url')}>
        <Input
          placeholder="https://example.com"
          {...register('url', {
            required: i18next.t('validation.required'),
            pattern: { value: urlRegexp, message: i18next.t('validation.invalid_url') },
          })}
        />
        <ErrorMessage
          name="url"
          errors={errors}
          render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
        />
      </FieldGroup>

      <FieldGroup label={t('hub-editor.iframe.height')}>
        <Input
          {...register('height', {
            setValueAs: value => {
              return value.trim()
            },
          })}
        />
      </FieldGroup>

      <FieldGroup label={t('hub-editor.iframe.width')}>
        <Input
          {...register('width', {
            setValueAs: value => {
              return value.trim()
            },
          })}
        />
      </FieldGroup>
    </div>
  )
}

export default IFrameBlockEditor
