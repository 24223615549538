import { AnimatePresence, motion } from 'framer-motion'
import { useQuery } from '@tanstack/react-query'
import { ModalRush, ModalRushPage } from '@/ui'
import { LOCALIZATION_LOCALE_PATH, useGenerateCompanyPath, useNavigateBack } from '@/libs'
import { localeQueryOptions, translationQueryOptions } from '../api'
import { TranslationForm, TranslationFormSkeleton } from '../components'
import { formMotionProps } from '../widgets'

export const L10nTranslationPage = () => {
  const back = useNavigateBack({ fallback: LOCALIZATION_LOCALE_PATH })
  const { companyId, gameId, localeId, translationId } = useGenerateCompanyPath<{
    localeId: string
    translationId: string
  }>()

  const { data: locale } = useQuery(localeQueryOptions(companyId, gameId, localeId))

  const { data: translation } = useQuery({
    ...translationQueryOptions(companyId, gameId, translationId),
    enabled: !!translationId,
  })

  return (
    <ModalRushPage back={back}>
      {props => (
        <ModalRush {...props}>
          <AnimatePresence mode="popLayout">
            {locale && translation ? (
              <motion.div {...formMotionProps} className="flex h-full flex-col" key="form">
                <TranslationForm locale={locale} translation={translation} onClose={props.onClose} />
              </motion.div>
            ) : (
              <motion.div {...formMotionProps} className="flex h-full flex-col" key="skeleton">
                <TranslationFormSkeleton />
              </motion.div>
            )}
          </AnimatePresence>
        </ModalRush>
      )}
    </ModalRushPage>
  )
}
