import { useTranslation } from 'react-i18next'
import { creatorsDeleteMutation } from '@/layouts/engagement/api'
import { CreatorRead } from '@/api/dashboard'
import { ModalPresetConfirm, ModalProps } from '@dashboard/ui'

export interface CreatorDeleteModalProps {
  creators: CreatorRead[]
  onDeleted: () => void
}

export const CreatorDeleteModal = ({ creators, ...rest }: CreatorDeleteModalProps & ModalProps) => {
  const { t } = useTranslation()

  const { mutateAsync } = creatorsDeleteMutation()
  const onConfirm = async () => {
    await Promise.all(creators.map(creator => mutateAsync(creator.id)))
    rest.onClose()
    rest.onDeleted()
  }

  return (
    <ModalPresetConfirm
      {...rest}
      title={{
        children: t('creators.delete-form.title', { count: creators.length }),
        subtitle: t('creators.delete-form.description', {
          count: creators.length,
          name: creators.length === 1 && creators[0].name,
        }),
      }}
      cancel={{
        label: t('Cancel'),
        callback: rest.onClose,
      }}
      submit={{
        callback: onConfirm,
        color: 'danger',
        label: t('remove'),
      }}
    />
  )
}
