import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '@/icons'

export const NumberIcon: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 18 18" ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.12143 2.26622C7.48534 2.34709 7.7148 2.70765 7.63393 3.07156L7.06647 5.62507H12.4335L13.0661 2.77871C13.1469 2.4148 13.5075 2.18535 13.8714 2.26622C14.2353 2.34709 14.4648 2.70765 14.3839 3.07156L13.8165 5.62507H15.075C15.4478 5.62507 15.75 5.92727 15.75 6.30006C15.75 6.67284 15.4478 6.97504 15.075 6.97504H13.5165L12.8652 9.90578C12.7843 10.2697 12.4237 10.4991 12.0598 10.4183C11.6959 10.3374 11.4665 9.97684 11.5473 9.61293L12.1335 6.97504H2.925C2.55221 6.97504 2.25 6.67284 2.25 6.30006C2.25 5.92727 2.55221 5.62507 2.925 5.62507H5.68353L6.31607 2.77871C6.39694 2.4148 6.75751 2.18535 7.12143 2.26622ZM5.94018 7.58173C6.30409 7.6626 6.53355 8.02316 6.45268 8.38707L5.86647 11.025H15.075C15.4478 11.025 15.75 11.3272 15.75 11.6999C15.75 12.0727 15.4478 12.3749 15.075 12.3749H12.3165L11.6839 15.2213C11.6031 15.5852 11.2425 15.8146 10.8786 15.7338C10.5147 15.6529 10.2852 15.2924 10.3661 14.9284L10.9335 12.3749H5.56647L4.93393 15.2213C4.85306 15.5852 4.49249 15.8146 4.12857 15.7338C3.76466 15.6529 3.5352 15.2924 3.61607 14.9284L4.18353 12.3749H2.925C2.55221 12.3749 2.25 12.0727 2.25 11.6999C2.25 11.3272 2.55221 11.025 2.925 11.025H4.48353L5.13482 8.09422C5.21569 7.73031 5.57626 7.50086 5.94018 7.58173Z"
        fill="currentColor"
      />
    </IconBase>
  )
})
