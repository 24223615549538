import { ButtonHTMLAttributes, ReactNode, forwardRef } from 'react'
import { ChevronDownOutline } from '@/icons'
import { cn } from '@/libs'

export interface SelectBaseButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: ReactNode
  /**
   * TODO: legacy makes the selector big, like the old one.
   */
  variant?: 'primary' | 'legacy'
  placeholder?: string
}

export const SelectBaseButton = forwardRef<HTMLButtonElement, SelectBaseButtonProps>(function (
  { children, variant = 'legacy', placeholder, ...rest },
  ref,
) {
  return (
    <button
      {...rest}
      ref={ref}
      className={cn(
        'group/select-base-button',
        'flex w-full items-center gap-3 overflow-hidden rounded-md border border-border-primary bg-fg-primary px-3 shadow-xs outline-none transition-colors',

        variant === 'primary' && 'h-9 text-caption-md leading-4',

        variant === 'legacy' && 'h-12 text-caption-lg leading-5',

        'hover:border-border-primary-hover',
        'focus-visible:border-border-brand',
        'focus-visible:ring-brand',
        'focus-visible:ring-2',
        '[&[data-open="true"]]:border-border-brand',
        '[&[data-open="true"]]:ring-brand',
        '[&[data-open="true"]]:ring-2',

        children
          ? [
              '[--icon-color:theme(colors.text-secondary)]',
              '[--label-color:theme(colors.text-secondary)]',
              '[--extra-color:theme(colors.text-quarterary-hover)]',
              '[--chevron-color:theme(colors.text-quarterary)]',
              'hover:[--chevron-color:theme(colors.text-quarterary-hover)]',
              '[&[data-open="true"]]:[--icon-color:theme(colors.text-primary)]',
              '[&[data-open="true"]]:[--label-color:theme(colors.text-primary)]',
              '[&[data-open="true"]]:[--extra-color:theme(colors.text-tertiary)]',
              '[&[data-open="true"]]:[--chevron-color:theme(colors.text-brand-primary)]',
            ]
          : [
              '[--icon-color:theme(colors.text-quarterary)]',
              '[--label-color:theme(colors.text-quarterary)]',
              '[--extra-color:theme(colors.text-quarterary-hover)]',
              '[--chevron-color:theme(colors.text-quarterary)]',
              'hover:[--icon-color:theme(colors.text-quarterary-hover)]',
              'hover:[--label-color:theme(colors.text-quarterary-hover)]',
              'hover:[--extra-color:theme(colors.text-quarterary-hover)]',
              'hover:[--chevron-color:theme(colors.text-quarterary-hover)]',
              '[&[data-open="true"]]:[--icon-color:theme(colors.text-quarterary-hover)]',
              '[&[data-open="true"]]:[--label-color:theme(colors.text-quarterary-hover)]',
              '[&[data-open="true"]]:[--extra-color:theme(colors.text-quarterary-hover)]',
              '[&[data-open="true"]]:[--chevron-color:theme(colors.text-brand-primary)]',
            ],
      )}
      type="button"
    >
      <div className="flex w-full items-center gap-3 truncate">
        {children || <span className="truncate text-[--label-color] transition-colors">{placeholder}</span>}
      </div>
      <ChevronDownOutline
        className={cn(
          'ml-auto shrink-0 text-[--chevron-color] transition-[color_transform]',
          'group-[&[data-open="true"]]/select-base-button:rotate-180',
        )}
        size={16}
      />
    </button>
  )
})
