import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { InputSearch, SearchFilterGeneric, SelectFilter, cn } from '@dashboard/ui'
import { UnsetType } from '@/api/dashboard'
import { itemCategoriesQuery } from '@/layouts/item-category/api'
import { useGenerateCompanyPath } from '@/libs'
import { SkuListFilter } from '../types'

interface SkuListToolbarProps {
  filter: SearchFilterGeneric<SkuListFilter>
}

export const SkuListToolbar = ({ filter }: SkuListToolbarProps) => {
  const { t } = useTranslation()
  const { companyId, gameId } = useGenerateCompanyPath()

  const { data: categories = [] } = useQuery({
    ...itemCategoriesQuery(companyId, gameId, { limit: 100 }),
    refetchOnMount: false,
  })

  return (
    <div className={cn('mb-4 flex w-full items-center gap-2')}>
      <InputSearch {...filter.registerInput('search_string')} placeholder={t('search')} />

      {categories.length > 0 && (
        <SelectFilter
          {...filter.registerSelect('category_id')}
          items={[
            ...categories.map(item => ({ children: item.name, value: item.id })),
            { children: t('sku.item.no_category'), value: UnsetType.Unset },
          ]}
        >
          {t('sku.item.category')}
        </SelectFilter>
      )}
    </div>
  )
}
