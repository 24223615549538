import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ItemRead, ItemType } from '@/api/dashboard'
import {
  Badge,
  PaginationInfiniteObserver,
  Table,
  TableCell,
  TableCellBulkCheckbox,
  TableRow,
  TableZeroState,
} from '@/ui'
import {
  Button,
  CloseOutline,
  InputSearch,
  Modal,
  ModalContent,
  ModalFooter,
  ModalProps,
  ModalTitle,
  Skeleton,
  Spinner,
} from '@dashboard/ui'
import { useDebounce } from '@/hooks'
import { SkuImageName } from '@/layouts/game-items/components/SkuImageName'
import useKeyPress from 'react-use/lib/useKeyPress'
import { useBulkSelectGeneric } from '@/libs/hooks/useBulkSelectGeneric'
import { useInfiniteGameItems } from '@/api/useInfiniteGameItems'
import { motion } from 'framer-motion'
import { uniqueList } from '@/util'

// @ts-ignore
interface ItemsPickerProps extends ModalProps {
  title: string
  limit?: number
  picked?: ItemRead[]
  onSubmit: (picked: ItemRead[]) => void
  types?: ItemType[]
  buttonText?: string
  showAddAll?: boolean
  hideIds?: string[]
}

export const SelectSkuModal = ({
  title,
  limit,
  types,
  picked = [],
  onSubmit,
  buttonText,
  showAddAll,
  hideIds,
  ...rest
}: ItemsPickerProps) => {
  const { t } = useTranslation()
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)

  const { items: allItems, isLoading, fetchNextPage, hasNext, loadAll } = useInfiniteGameItems(debouncedSearch, types)

  const { selected, onSelect, onMultiSelect } = useBulkSelectGeneric(picked?.map(it => it.id))

  const onAddAll = async () => {
    const all = await loadAll()
    onSubmit(all)
    rest.onClose?.()
  }

  const [isShiftPress] = useKeyPress('Shift')

  return (
    <Modal {...rest} size="lg">
      <ModalContent className="w-[532px]">
        <ModalTitle>{title}</ModalTitle>
        <div className="sticky top-[55px] z-10 h-fit w-full bg-fg-primary py-3">
          <InputSearch
            value={search}
            onChange={e => {
              setSearch(e.target.value)
            }}
            placeholder={t('search')}
          />
        </div>

        {allItems.length === 0 && !isLoading && search && (
          <TableZeroState
            contentClassName="py-4"
            className="py-4"
            title={t('sku.search.zero.title')}
            message={t('sku.search.zero.text')}
            buttons={
              <Button
                size="sm"
                color="secondary"
                variant="outline"
                onClick={() => {
                  setSearch('')
                }}
              >
                <CloseOutline size={14} />
                <span>{t('sku.search.clear-filters')}</span>
              </Button>
            }
          />
        )}

        {isLoading && allItems.length == 0 ? (
          new Array(10).fill('').map((_, index) => <Skeleton className="mb-2 h-[32px]" key={index} />)
        ) : (
          <Table>
            {allItems
              .filter(it => !hideIds?.includes(it.id))
              .map(it => {
                const checked = !!selected.find(pickedItem => pickedItem === it.id)
                const disabled = !checked && !!(limit && selected.length >= limit)

                return (
                  <TableRow key={it.id} data-testid={`select-sku-modal/${it.id}`}>
                    <TableCellBulkCheckbox
                      selected={checked}
                      disabled={disabled}
                      onChange={() => {
                        if (isShiftPress) {
                          onMultiSelect(
                            allItems.map(it => it.id),
                            it.id,
                          )
                        } else {
                          onSelect(it.id)
                        }
                      }}
                    />
                    <TableCell className="flex items-center gap-3" width="100%">
                      <SkuImageName item={it} />
                    </TableCell>
                    <TableCell width={80}>
                      <Badge variant={it.archived_at ? 'gray-primary' : 'lime-secondary'}>
                        {it.archived_at
                          ? t('daily-rewards.reward.item.state.archived')
                          : t('daily-rewards.reward.item.state.active')}
                      </Badge>
                    </TableCell>
                  </TableRow>
                )
              })}
          </Table>
        )}
        {isLoading && (
          <motion.div className="flex shrink-0 justify-center py-4 text-text-disabled">
            <Spinner size={16} />
          </motion.div>
        )}

        {hasNext && !isLoading && <PaginationInfiniteObserver onNextPage={fetchNextPage} />}
      </ModalContent>
      <ModalFooter>
        {showAddAll && (
          <Button size="sm" variant="link" onClick={onAddAll} className="mr-auto" type="button">
            {t('game-item-edit-dialog.bundle.add-all-items')}
          </Button>
        )}
        <Button
          size="sm"
          type="button"
          color="secondary"
          variant="outline"
          onClick={() => {
            rest.onClose?.()
          }}
        >
          {t('Cancel')}
        </Button>
        <Button
          size="sm"
          onClick={() => {
            let items = uniqueList([...allItems, ...picked], 'id')
            onSubmit(items.filter(it => selected.includes(it.id)))
            rest.onClose?.()
          }}
        >
          {buttonText || t('Save')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
