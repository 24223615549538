import { useParams } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { dashboardClient } from '@/api'
import { CreatorCreate } from '@/api/dashboard'

export const creatorsCreateMutation = () => {
  const client = useQueryClient()
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }

  return useMutation({
    mutationFn: (create: CreatorCreate) =>
      dashboardClient.v1.createCreator(companyId, gameId, create).then(r => r.data),
    onSuccess: async () => {
      await client.refetchQueries({
        predicate: ({ queryKey }) =>
          queryKey.includes('creators') && queryKey.includes(companyId) && queryKey.includes(gameId),
        exact: false,
        stale: false,
      })
    },
  })
}
