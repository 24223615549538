import { Skeleton, cn } from '@dashboard/ui'
import { useBanner, useStickyAttr } from '@/libs'

interface TranslationRowHeaderProps {
  locale: string
  targetLocale?: string
}

export const TranslationRowHeader = ({ locale, targetLocale }: TranslationRowHeaderProps) => {
  const { ref } = useStickyAttr({ offsetY: 64 })
  const { bannerMode } = useBanner()

  return (
    <div
      ref={ref}
      className={cn(
        'sticky left-0 z-20 grid h-11 w-full shrink-0 grid-cols-[1fr_1fr_100px] content-center items-center gap-6 border-b border-border-secondary bg-fg-primary px-4 text-left text-caption-sm font-semibold text-text-secondary',
        '[&[is-sticky="true"]]:shadow-xs',
        bannerMode ? 'top-[calc(theme(spacing.14)+theme(height.banner)-1px)]' : 'top-[calc(theme(spacing.14)-1px)]',
      )}
    >
      <div>{locale}</div>
      <div>{targetLocale || <Skeleton className="h-4 w-1/4" />}</div>
    </div>
  )
}
