import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { InputSearch, SearchFilterGeneric, SelectFilter, SelectFilterItem } from '@dashboard/ui'
import { CouponType } from '@/types'
import { CouponFilter } from '../types'

interface CouponToolbarProps {
  filter: SearchFilterGeneric<CouponFilter>
}

export const CouponToolbar = ({ filter }: CouponToolbarProps) => {
  const { t } = useTranslation()

  const typeItems: SelectFilterItem[] = useMemo(() => {
    return Object.values(CouponType).map(it => ({
      children: t(`coupon.type.${it}`),
      value: it,
    }))
  }, [])

  return (
    <div className="mb-3 flex items-center gap-3">
      <InputSearch
        {...filter.registerInput('q')}
        data-testid="coupon/toolbar/filter-search"
        placeholder={t('search')}
      />
      <div className="flex gap-2">
        <SelectFilter {...filter.registerSelect('type')} items={typeItems}>
          {t('coupon.filter.type')}
        </SelectFilter>
      </div>
    </div>
  )
}
