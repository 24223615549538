import { StoreItemRead } from '@/api/dashboard'

import { FieldGroup, Select, SelectMultipleOption, SelectOption, SelectProps } from '@/ui'
import { DEFAULT_IMAGE_PLACEHOLDER_ITEM } from '@/Settings'
import { getItemName } from '@/api/getItemName'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { PriceView } from '@/layouts/game-items/components/PriceView'

// @ts-ignore
interface SelectSkuProps extends SelectProps {
  label?: string
  value: string | string[] | undefined | null
  onChange: (value: SelectOption['value'] | SelectMultipleOption['value'][]) => void
  caption?: string
  storeItems: StoreItemRead[]
  options?: SelectOption[]
}

export const SelectStoreItem = (props: SelectSkuProps) => {
  const { t } = useTranslation()

  let options = props.storeItems.map(it => {
    let price = it.is_free_item ? t('coupon.type.free_item') : <PriceView item={it.item} />

    return {
      value: it.item.id as string,
      icon: (
        <div
          className="size-[38px] shrink-0 rounded-md bg-cover bg-center bg-no-repeat"
          style={{
            backgroundImage: `url(${it.item.image_url || DEFAULT_IMAGE_PLACEHOLDER_ITEM})`,
          }}
        />
      ),
      desc: (
        <div className="w-full truncate text-xs	font-medium leading-none text-text-quarterary-hover">{it.item.sku}</div>
      ),
      extraRight: price as unknown as string,
      children: getItemName(it.item),
    }
  })

  let content = (
    <Select
      {...(props as SelectProps)}
      name="select-sku"
      searchPlaceholder={t('select-sku.search.place-holder')}
      options={options}
      placeholder={t('select-skus')}
      filter={(opt, search) => {
        let id = opt.value
        let storeItem = props.storeItems.find(it => it.id == id)
        if (!storeItem) {
          return false
        }

        return (
          storeItem.item.name.toLowerCase().includes(search.toLowerCase()) ||
          storeItem.item.sku.toLowerCase().includes(search.toLowerCase())
        )
      }}
    />
  )

  if (!props.label) {
    return content
  }

  let caption: ReactElement | null | string | undefined = props.caption

  return (
    <FieldGroup label={props.label} caption={caption}>
      {content}
    </FieldGroup>
  )
}
