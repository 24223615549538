import { cn } from '../../../libs/cn'

export interface PageBlockProps {
  children: React.ReactNode
  className?: string
}

export default function Block(props: PageBlockProps) {
  return (
    <div
      className={cn(
        'flex justify-center rounded-2xl1 border border-border-primary bg-fg-primary py-6 shadow-xs',
        props.className,
      )}
    >
      <div className="w-[320px]">{props.children}</div>
    </div>
  )
}
