import { FunctionComponentElement } from 'react';
import { create } from 'zustand';
import { ModalBaseProps } from './types';

interface ModalStoreState {
  modals: { id: string; element: FunctionComponentElement<ModalBaseProps> }[];
  push: (id: string, element: FunctionComponentElement<ModalBaseProps>) => void;
  shift: (id: string) => void;
  reset: () => void;
}

export const useModalStore = create<ModalStoreState>(set => ({
  modals: [],
  toasts: [],
  push: (id, element) => {
    set(s => ({ ...s, modals: [...s.modals, { id, element }] }));
  },
  shift: id => {
    set(s => ({
      ...s,
      modals: s.modals.filter(modal => modal.id !== id),
    }));
  },
  reset: () => {
    set(s => {
      return { ...s, modals: [] };
    });
  },
}));
