import {
  BestTriggersData,
  ConversionFirstPurchase,
  DataGranularity,
  FilterType,
  MeasureValue,
  PaymentSuccessFail,
  PointSeriesValue,
  PointValue,
  PointValueEx,
  RevenueByDate,
  SankeyTopSkuData,
  SeriesValue,
} from '../../api/dashboard'
import { createContext } from 'react'

export interface AllData {
  revenue_by_dates: RevenueByDate[]
  revenue: MeasureValue
  arpdau: MeasureValue
  arppu: MeasureValue
  dau: MeasureValue
  mau: MeasureValue
  conversion: MeasureValue
  payments_success_fail_by_dates: PaymentSuccessFail[]
  payments_success_percent: MeasureValue
  revenue_by_date_device: SeriesValue[]
  conversion_game_hub_first_purchase_by_date: ConversionFirstPurchase
  revenue_by_country: PointValue[]
  sales_by_date: PointSeriesValue[]
  top_items_sum: MeasureValue
  top_items: PointSeriesValue[]
  triggers_by_date: BestTriggersData
  sankey_top_sku: SankeyTopSkuData
  hub_visit_data: PointValueEx[]
  total_user_count: MeasureValue
}

export interface IAnalyticsDataContext {
  data: AllData
  granularity: DataGranularity
  selectedFilter: string
  type: FilterType
}

export const AnalyticsDataContext = createContext<IAnalyticsDataContext | null>(null)
