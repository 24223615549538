import { LexicalEditor } from 'lexical'
import { $generateHtmlFromNodes } from '@lexical/html'

export const processOutputHtml = (editor: LexicalEditor) => {
  const html = $generateHtmlFromNodes(editor)
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')
  const tables = doc.querySelectorAll('table')

  tables.forEach(table => {
    const wrapper = document.createElement('div')
    table.parentNode?.replaceChild(wrapper, table)
    wrapper.style.overflowX = 'auto'
    wrapper.style.margin = '0 -2rem'
    wrapper.style.padding = '0 2rem'
    wrapper.appendChild(table)
  })

  return doc.body.innerHTML
}
