import AnimatedDots from '../../../components/animated-dots/AnimatedDots'
import i18next from 'i18next'
import { IconLoader } from '@/icons'

export default function RefundingProgress() {
  return (
    <div className="flex items-center gap-1.5">
      <IconLoader className="size-4 text-text-brand-primary" />
      <span className="text-[18px] text-text-brand-solid">
        {i18next.t('transaction-details.refunding')}
        <AnimatedDots />
      </span>
    </div>
  )
}
