import { ReactNode, createElement, forwardRef } from 'react';
import { AghanimIcon, InfoCircle } from '../../icons';
import { motion } from 'framer-motion';
import { MOTION_ANIMATION_SPEED_MD, getMotionProps } from '../../libs';

interface FormErrorMessageProps {
  children: ReactNode;
  icon?: AghanimIcon;
}

const wrapperMotionProps = getMotionProps({
  initial: { height: 0 },
  animate: { height: 'auto' },
  exit: { height: 0 },
});

const innerMotionProps = getMotionProps(
  {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 1 },
  },
  { delay: MOTION_ANIMATION_SPEED_MD / 2 },
);

export const FormErrorMessage = forwardRef<HTMLDivElement, FormErrorMessageProps>(
  ({ children, icon = InfoCircle }, ref) => {
    return (
      <motion.div {...wrapperMotionProps} className="overflow-hidden">
        <motion.div
          {...innerMotionProps}
          ref={ref}
          className="mt-1 flex items-start gap-2 rounded-md bg-fg-error-tertiary px-2 py-1.5 text-paragraph-sm leading-4 text-text-error-primary"
        >
          {createElement(icon, { className: 'shrink-0', size: 16 })}
          {children}
        </motion.div>
      </motion.div>
    );
  },
);
