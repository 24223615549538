import { ReactNode, useEffect, useState } from 'react'
import { Check, Copy03 } from '@/icons'
import { Spinner, Tooltip } from '@/ui'

interface WebhookInputProps {
  value: string
  message: ReactNode
  onClickCopy: () => Promise<void>
}

export const WebhookInput = ({ value, message, onClickCopy }: WebhookInputProps) => {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setSuccess(false)
      }, 2500)
    }
  }, [success, setSuccess])

  const promisedOnClick = async () => {
    setLoading(true)
    try {
      await onClickCopy()
      setSuccess(true)
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="flex h-[34px] w-full rounded-md shadow-xs">
      <input
        className="relative z-10 -mr-px w-full rounded-l-md border border-border-primary bg-fg-primary px-3 outline-0 transition-colors focus:border-border-brand focus:ring-2 focus:ring-brand"
        value={value}
        readOnly={true}
        type="text"
      />

      <Tooltip
        message={message}
        options={{ arrow: false, placement: 'top', distance: -4, delay: 0, disabled: !success }}
      >
        <button
          className="relative flex size-[34px] shrink-0 items-center justify-center rounded-r-md border border-border-primary bg-fg-primary text-text-secondary outline-0 transition-colors hover:bg-fg-primary-hover focus-visible:z-20 focus-visible:border-border-brand focus-visible:ring-2 focus-visible:ring-brand"
          disabled={loading || success}
          onClick={promisedOnClick}
        >
          {success ? (
            <Check className="text-text-brand-primary" size={16} strokeWidth={3} />
          ) : loading ? (
            <Spinner size={16} />
          ) : (
            <Copy03 size={16} />
          )}
        </button>
      </Tooltip>
    </div>
  )
}
