import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const PlusContained2: AghanimIcon = forwardRef(function (props, ref) {
  return (
    <IconBase {...props} viewBox="0 0 36 36" ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 3C9.71573 3 3 9.71573 3 18C3 26.2843 9.71573 33 18 33C26.2843 33 33 26.2843 33 18C33 9.71573 26.2843 3 18 3ZM19.5 10.5C19.5 9.67157 18.8284 9 18 9C17.1716 9 16.5 9.67157 16.5 10.5V16.5H10.5C9.67157 16.5 9 17.1716 9 18C9 18.8284 9.67157 19.5 10.5 19.5H16.5V25.5C16.5 26.3284 17.1716 27 18 27C18.8284 27 19.5 26.3284 19.5 25.5V19.5H25.5C26.3284 19.5 27 18.8284 27 18C27 17.1716 26.3284 16.5 25.5 16.5H19.5V10.5Z"
        fill="currentColor"
      />
    </IconBase>
  );
});
