import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const ChevronDownOutline: AghanimIcon = forwardRef(function (props, ref) {
  return (
    <IconBase strokeWidth="2" {...props} viewBox="0 0 24 24" ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.24076 8.34923C4.60018 7.9299 5.23148 7.88134 5.65081 8.24076L12 13.6829L18.3492 8.24076C18.7686 7.88134 19.3999 7.9299 19.7593 8.34923C20.1187 8.76855 20.0701 9.39985 19.6508 9.75927L12.6508 15.7593C12.2763 16.0803 11.7237 16.0803 11.3492 15.7593L4.34923 9.75927C3.9299 9.39985 3.88134 8.76855 4.24076 8.34923Z"
        fill="currentColor"
      />
    </IconBase>
  );
});
