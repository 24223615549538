import UserAvatar from '@/components/UserAvatar'
import { useAuth0 } from '@auth0/auth0-react'
import { useContext, useMemo } from 'react'
import { isEmail } from '@/util'
import { AuthContext } from '@/Context'
import { cn } from '@/libs'

export default function UserInfo(props: { className?: string }) {
  const { user } = useAuth0()
  const context = useContext(AuthContext)

  const computedUsername = useMemo(() => {
    const username = context?.customer?.name || user?.name || user?.email || ''
    return isEmail(username) ? username.split('@')[0] : username
  }, [context?.customer?.name, user?.name, user?.email])

  return (
    <div className={cn('flex items-center gap-3', props.className)}>
      <UserAvatar url={context?.customer?.avatar_url || user?.picture} size="48px" />
      <div className="flex grow flex-col overflow-hidden">
        <div className="mb-1 w-full truncate text-caption-md font-semibold text-text-primary">{computedUsername}</div>
        {user?.email && (
          <div className="w-full truncate text-caption-sm font-normal text-text-brand-primary">{user.email}</div>
        )}
      </div>
    </div>
  )
}
