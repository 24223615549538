import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const AlertTriangleOutline: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 24 24" ref={ref}>
      <path
        d="M12 9.14138V12.9183M12 16.6952H12.0097M10.6509 4.31801L2.63708 17.7323C2.19258 18.4763 1.97033 18.8484 2.00318 19.1537C2.03183 19.42 2.17581 19.662 2.39928 19.8195C2.65549 20 3.09907 20 3.98622 20H20.0138C20.9009 20 21.3445 20 21.6007 19.8195C21.8242 19.662 21.9682 19.42 21.9968 19.1537C22.0297 18.8484 21.8074 18.4763 21.3629 17.7323L13.3491 4.31801C12.9062 3.57663 12.6848 3.20595 12.3959 3.08145C12.1439 2.97285 11.8561 2.97285 11.6041 3.08145C11.3152 3.20595 11.0938 3.57664 10.6509 4.31801Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconBase>
  );
});
