import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const SixDots: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 18 18" ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 3.75C4.5 2.92157 5.17157 2.25 6 2.25C6.82843 2.25 7.5 2.92157 7.5 3.75C7.5 4.57843 6.82843 5.25 6 5.25C5.17157 5.25 4.5 4.57843 4.5 3.75ZM10.5 3.75C10.5 2.92157 11.1716 2.25 12 2.25C12.8284 2.25 13.5 2.92157 13.5 3.75C13.5 4.57843 12.8284 5.25 12 5.25C11.1716 5.25 10.5 4.57843 10.5 3.75ZM4.5 9C4.5 8.17157 5.17157 7.5 6 7.5C6.82843 7.5 7.5 8.17157 7.5 9C7.5 9.82843 6.82843 10.5 6 10.5C5.17157 10.5 4.5 9.82843 4.5 9ZM10.5 9C10.5 8.17157 11.1716 7.5 12 7.5C12.8284 7.5 13.5 8.17157 13.5 9C13.5 9.82843 12.8284 10.5 12 10.5C11.1716 10.5 10.5 9.82843 10.5 9ZM4.5 14.25C4.5 13.4216 5.17157 12.75 6 12.75C6.82843 12.75 7.5 13.4216 7.5 14.25C7.5 15.0784 6.82843 15.75 6 15.75C5.17157 15.75 4.5 15.0784 4.5 14.25ZM10.5 14.25C10.5 13.4216 11.1716 12.75 12 12.75C12.8284 12.75 13.5 13.4216 13.5 14.25C13.5 15.0784 12.8284 15.75 12 15.75C11.1716 15.75 10.5 15.0784 10.5 14.25Z"
        fill="black"
      />
    </IconBase>
  );
});
