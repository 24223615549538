import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  Button,
  FormErrorMessage,
  Input,
  Modal,
  ModalBaseProps,
  ModalContent,
  ModalFooter,
  ModalTitle,
  formatHookFormErrors,
} from '@dashboard/ui'
import { ItemCategoryRead } from '@/api/dashboard'
import { ResponseError, getErrorText } from '@/api'
import { prepareSLUG } from '@/util'
import { FieldGroup, SelectIcon } from '@/ui'
import { useItemCategoryCreateMutate, useItemCategoryUpdateMutate } from './api'
import { HUB_SECTION_AVAILABLE_ICONS, getHubSectionIcon } from '@/layouts/hub'

interface EditItemCategoryDialogProps extends ModalBaseProps {
  item: ItemCategoryRead
  created?: (item: ItemCategoryRead) => void
}

export default function EditItemCategoryDialog({ item, created, ...rest }: EditItemCategoryDialogProps) {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const { t } = useTranslation()

  const [errorMessage, setErrorMessage] = useState<string>('')
  const { mutateAsync: createMutateAsync } = useItemCategoryCreateMutate(companyId, gameId)
  const { mutateAsync: updateMutateAsync } = useItemCategoryUpdateMutate(companyId, gameId)

  const {
    formState: { isSubmitting, errors },
    control,
    setValue,
    register,
    handleSubmit,
  } = useForm<ItemCategoryRead>({
    values: { ...item },
  })

  const onSubmit = handleSubmit(async data => {
    try {
      if (item.id) {
        await updateMutateAsync({
          id: item.id,
          update: data,
        })
      } else {
        let { data: newCat } = await createMutateAsync({
          create: data,
        })

        created?.(newCat)
      }
    } catch (e) {
      setErrorMessage(getErrorText(e as ResponseError))
      return
    }

    rest.onClose?.()
  })

  return (
    <Modal {...rest}>
      <form onSubmit={onSubmit}>
        <ModalContent>
          <ModalTitle>{t(item.id ? 'item-category.edit' : 'item-category.create')}</ModalTitle>

          <FieldGroup>
            <div className="flex gap-3">
              <div className="shrink-0">
                <FieldGroup label={t('item-category.icon')}>
                  <Controller
                    control={control}
                    name="icon"
                    render={({ field }) => (
                      <SelectIcon
                        {...field}
                        allowEmpty={true}
                        items={HUB_SECTION_AVAILABLE_ICONS.map(icon => ({
                          children: <img className="size-[18px]" src={getHubSectionIcon(icon) as string} alt={icon} />,
                          value: icon,
                        }))}
                      />
                    )}
                  />
                </FieldGroup>
              </div>
              <div className="w-full">
                <FieldGroup label={t('item-category.name')}>
                  <Input
                    {...register('name', { required: t('validation.required') })}
                    {...formatHookFormErrors(errors, 'name')}
                    autoFocus={true}
                  />
                </FieldGroup>
              </div>
            </div>
          </FieldGroup>

          <FieldGroup label={t('item-category.description')}>
            <Input {...register('description')} {...formatHookFormErrors(errors, 'description')} />
          </FieldGroup>

          <FieldGroup label={t('item-category.slug')}>
            <Controller
              control={control}
              name="slug"
              render={({ field }) => (
                <Input
                  {...field}
                  {...formatHookFormErrors(errors, 'slug')}
                  maxLength={100}
                  onChange={e => setValue('slug', prepareSLUG(e.target.value))}
                />
              )}
            />
          </FieldGroup>

          {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
        </ModalContent>

        <ModalFooter>
          <Button variant="outline" color="secondary" size="sm" onClick={rest.onClose}>
            {t('Cancel')}
          </Button>
          <Button type="submit" size="sm" loading={isSubmitting}>
            {t(item.id ? 'Save2' : 'Add')}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}
