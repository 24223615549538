import '../ToolTip/ToolTip.css'
import { useContext } from 'react'
import DashboardCard from '../../../../components/shared/DashboardCard'
import ReactECharts from 'echarts-for-react'
import i18next from 'i18next'
import { useTheme } from '@mui/material'
import { LoaderContainer } from '../Loader/LoaderContainer'
import Loader from '../Loader/Loader'
import * as echarts from 'echarts'
import { formatMoney } from '../../../../util'
import ErrorBoundary from '../../../../components/ErrorBoundary'
import { AnalyticsDataContext, IAnalyticsDataContext } from '../../Context'
import { Legend } from '../Legend'
import { getDateCategoryParams } from '@/libs/dates'
import { useTranslation } from 'react-i18next'

const ADD_REVENUE_COLOR = 'rgba(34, 197, 94, 0.3)'
const REVENUE_COLOR = 'rgba(34, 197, 94, 0.3)'
const TAX_COLOR = '#94A3B8'
const COST_COLOR = '#0EA5E9'

const SalesChart = () => {
  const theme = useTheme()
  const context = useContext(AnalyticsDataContext) as IAnalyticsDataContext
  const loading = !context.data.sales_by_date
  const originalSalesData = context.data.sales_by_date || []
  const { t } = useTranslation()

  const salesData = originalSalesData.map(it => ({
    category: it.category,
    values: {
      channel_of_cost: Math.max(it.values['channel_of_cost'], 0),
      tax_fx: Math.max(it.values['tax_fx'], 0),
      revenue: Math.max(it.values['revenue'], 0),
      add_revenue: Math.max(it.values['add_revenue'], 0),
      sales: Math.max(it.values['sales'], 0),
    },
  }))

  const getToolTopItem = (name: string, color: string, value: number, percent: number) => {
    return `<div class="chart-tooltip--revenue-item">
        <div style="display: flex; align-items: center; gap: 6px">
          <div class="w-4 h-1 rounded-[5px]" style="background: ${color}"></div>
          <span>
            ${name}
          </span>
        </div>
        <span>${formatMoney(value)}<div class="text-text-inverse w-[28px] inline-block text-right ml-1.5 opacity-50">(${isNaN(percent) ? 0 : percent.toFixed(0)}%)</div></span>
      </div>`
  }

  const options = {
    title: {
      show: false,
    },
    tooltip: {
      backgroundColor: '#000000CC',
      borderColor: 'transparent',
      trigger: 'axis',
      formatter: function (params: { axisValue: string; value: number; dataIndex: number }[]) {
        let item = params[0]
        let data = salesData[item.dataIndex]

        let channel_of_cost = data.values['channel_of_cost']
        let tax_fx = data.values['tax_fx']
        let revenue = data.values['revenue']
        let extra_revenue = data.values['add_revenue']

        let total = channel_of_cost + tax_fx + revenue + extra_revenue

        let percentNetRevenue = (100 * (revenue + extra_revenue)) / total
        let percentRevenue = (100 * revenue) / total
        let percentExtraRevenue = percentNetRevenue - percentRevenue

        let percentTax = (100 * tax_fx) / total
        let percentChannelOfCost = 100 - percentNetRevenue - percentTax

        return `<div class="chart-tooltip" style="height: auto; min-width: 280px; width: auto; display: flex; flex-direction: column; gap: 6px">
                    <div class="chart-tooltip--title">${item.axisValue}</div>
                    <div style="width: 100%">
                      ${getToolTopItem(i18next.t('dashboard.sales.net_revenue'), '#5DD589', revenue + extra_revenue, percentNetRevenue)}
                      <div class="ml-3">
                        ${getToolTopItem(i18next.t('dashboard.sales.top_line_growth'), 'transparent', extra_revenue, percentExtraRevenue)}                      
                        ${getToolTopItem(i18next.t('dashboard.sales.baseline'), 'transparent', revenue, percentRevenue)}
                      </div>
                    </div>
                    <div class="chart-tooltip--separator"></div>
                    
                    ${getToolTopItem(i18next.t('dashboard.sales.tax_fx'), TAX_COLOR, tax_fx, percentTax)}
                    
                    <div class="chart-tooltip--separator"></div>
                     
                    ${getToolTopItem(i18next.t('dashboard.sales.channel_of_cost'), COST_COLOR, channel_of_cost, percentChannelOfCost)}
                </div>`
      },
    },
    legend: {
      show: false,
      left: 'left',
      selectedMode: false,
      icon: 'roundRect',
      itemWidth: 18,
      itemHeight: 10,
      bottom: -5,
    },
    toolbox: {
      show: false,
    },
    grid: {
      top: 0,
      left: 0,
      right: 20,
      bottom: 0,
      containLabel: true,
      show: false,
    },
    xAxis: [
      {
        axisLabel: {
          fontSize: 12,
          color: theme.palette.text.tertiary,
          fontWeight: '500',
          fontFamily: 'InterAg',
        },
        axisLine: {
          show: true,
        },
        axisTick: {
          show: false,
        },
        type: 'category',
        boundaryGap: false,
        data: salesData.map(it => t('intl.DateTime', getDateCategoryParams(it.category, context.granularity))),
        splitLine: {
          show: true,
          lineStyle: {
            color: theme.palette.grey.borderSecondary,
            type: 'solid',
          },
        },
      },
    ],
    yAxis: [
      {
        show: false,
        type: 'value',
        min: 0,
        max: 100,
      },
    ],
    axisPointer: {
      lineStyle: {
        color: '#000',
        width: 1,
        type: 'solid',
      },
    },
    series: [
      {
        name: i18next.t('dashboard.sales.channel_of_cost'),
        type: 'line',
        stack: 'Total',
        showSymbol: false,
        smooth: 0.2,
        color: COST_COLOR,
        lineStyle: {
          color: COST_COLOR,
        },
        emphasis: {
          itemStyle: {
            borderColor: 'transparent',
            color: COST_COLOR,
          },
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgba(14, 165, 233, 0.2)',
            },
            {
              offset: 1,
              color: 'rgba(14, 165, 233, 0)',
            },
          ]),
        },
        data: salesData.map(it => (100 * it.values['channel_of_cost']) / it.values['sales']),
      },
      {
        name: i18next.t('dashboard.sales.tax_fx'),
        type: 'line',
        stack: 'Total',
        showSymbol: false,
        smooth: 0.2,
        color: TAX_COLOR,
        lineStyle: {
          color: TAX_COLOR,
        },
        emphasis: {
          itemStyle: {
            borderColor: 'transparent',
            color: TAX_COLOR,
          },
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgba(148, 163, 184, 0.2)',
            },
            {
              offset: 1,
              color: 'rgba(148, 163, 184, 0)',
            },
          ]),
        },
        data: salesData.map(it => (100 * it.values['tax_fx']) / it.values['sales']),
      },
      {
        name: i18next.t('dashboard.sales.baseline'),
        type: 'line',
        stack: 'Total',
        showSymbol: false,
        smooth: 0.2,
        color: REVENUE_COLOR,
        lineStyle: {
          color: 'transparent',
        },
        emphasis: {
          itemStyle: {
            borderColor: 'transparent',
            color: REVENUE_COLOR,
          },
        },
        areaStyle: {
          color: REVENUE_COLOR,
        },
        data: salesData.map(it => (100 * it.values['revenue']) / it.values['sales']),
      },
      {
        name: i18next.t('dashboard.sales.top_line_growth'),
        type: 'line',
        stack: 'Total',
        showSymbol: false,
        smooth: 0.2,
        color: ADD_REVENUE_COLOR,
        lineStyle: {
          color: 'transparent',
        },
        areaStyle: {
          color: ADD_REVENUE_COLOR,
        },
        emphasis: {
          itemStyle: {
            borderColor: 'transparent',
            color: ADD_REVENUE_COLOR,
          },
        },
        itemStyle: {
          decal: {
            dashArrayX: [1, 5],
            dashArrayY: [1, 0],
            rotation: 0,
            show: true,
            color: '#5DD589',
          },
        },
        data: salesData.map(it => (100 * it.values['add_revenue']) / it.values['sales']),
      },
    ],
  }

  options.series.forEach((s, idx) => {
    if (idx == 2) {
      s.data = s.data.map(d => (isNaN(d) || d == null || d == 0 ? 100 : d))
    } else {
      s.data = s.data.map(d => (isNaN(d) || d == null ? 0 : d))
    }
  })

  return (
    <DashboardCard
      title={i18next.t('dashboard.sales')}
      tooltip={i18next.t('dashboard.sales.tooltip')}
      style={{ height: '289px', flexGrow: 1, gap: '8px' }}
      data-testid="dashboard/chart/sales"
    >
      {loading ? (
        <LoaderContainer style={{ height: '221px' }}>
          <Loader />
        </LoaderContainer>
      ) : (
        <ErrorBoundary>
          <ReactECharts
            option={options}
            style={{
              height: '221px',
              width: '100%',
            }}
          />
          <Legend
            items={[
              {
                name: i18next.t('dashboard.sales.top_line_growth'),
                style: {
                  backgroundColor: 'rgba(34, 197, 94)',
                },
                type: 'dashed',
              },
              {
                name: i18next.t('dashboard.sales.baseline'),
                style: {
                  backgroundColor: '#22C55E',
                },
                type: 'line',
              },
              {
                name: i18next.t('dashboard.sales.tax_fx'),
                style: {
                  backgroundColor: TAX_COLOR,
                },
                type: 'line',
              },
              {
                name: i18next.t('dashboard.sales.channel_of_cost'),
                style: {
                  backgroundColor: COST_COLOR,
                },
                type: 'line',
              },
            ]}
          />
        </ErrorBoundary>
      )}
    </DashboardCard>
  )
}

export default SalesChart
