import { ReactNode, createContext, useContext } from 'react';
import { focusGroupKeyUX, hotkeyKeyUX, jumpKeyUX, pressKeyUX, startKeyUX } from 'keyux';
import '../../assets/css/overlayscrollbars.css';
import { ModalProvider } from '../ModalProvider/ModalProvider';

interface UIContextValue {
  locale: string;
}

interface UIProviderProps extends UIContextValue {
  children: ReactNode;
}

startKeyUX(window, [hotkeyKeyUX([]), focusGroupKeyUX(), pressKeyUX('is-pressed'), jumpKeyUX()]);

const UIContext = createContext<UIContextValue>({
  locale: 'en',
});

export const useUiContext = () => {
  return useContext(UIContext);
};

export const UIProvider = ({ children, locale }: UIProviderProps) => {
  return (
    <UIContext.Provider value={{ locale }}>
      <ModalProvider />
      {children}
    </UIContext.Provider>
  );
};
