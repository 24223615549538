import { ReactNode } from 'react'
import { cn } from '@dashboard/ui'

interface ModalFooterProps {
  children: ReactNode
  className?: string
}

export const ModalFooter = ({ children, className }: ModalFooterProps) => {
  return <div className={cn('mt-6 flex items-center justify-end gap-3', className)}>{children}</div>
}
