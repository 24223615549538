import { useTranslation } from 'react-i18next'
import { MenuItem, MenuItemDivider, Menu_v2 } from '@/ui'
import { BulkPropertyName } from '@/layouts/store/widgets/BulkEditModal'
import { StoreItemRead } from '@/api/dashboard'
import { useGameItems } from '@/api/useGameItems'
import { Trash } from '@/icons'
import { EditGlobalDiscountOutline } from '@/layouts/store/icons/EditGlobalDiscountOutline'
import { EditClock } from '@/components/icons/EditClock'
import { EditWeight } from '@/layouts/store/icons/EditWeight'
import { EditGlobalBonusOutline } from '@/layouts/store/icons/EditGlobalBonusOutline'
import { useMemo } from 'react'

interface BulkEditMenuProps {
  onClose?: () => void
  selected: StoreItemRead[]
  onDelete: () => void
  onSelect: (propName: BulkPropertyName) => void
  isWeightRotation: boolean
}

export const BulkEditMenu = ({
  onClose = () => void 0,
  onSelect,
  isWeightRotation,
  selected,
  onDelete,
}: BulkEditMenuProps) => {
  const { t } = useTranslation()
  const { items = [] } = useGameItems()

  const onClickOption = (propName: BulkPropertyName) => {
    onSelect(propName)
    onClose()
  }

  const hasStackableItems = useMemo(
    () => selected.some(it => items.find(i => i.id === it.item_id)?.is_stackable),
    [selected],
  )
  const hasNonStackableItems = useMemo(
    () => selected.some(it => items.find(i => i.id === it.item_id)?.is_stackable === false),
    [selected],
  )
  const hasFreeItems = useMemo(() => selected.some(it => it.is_free_item), [selected])

  const showBonus = hasStackableItems && !hasNonStackableItems
  const showDiscount = !hasFreeItems

  return (
    <Menu_v2>
      <MenuItem onClick={onDelete} icon={Trash} variant="danger">
        {t('delete')}
      </MenuItem>
      <MenuItemDivider />

      <MenuItem icon={EditClock} onClick={() => onClickOption('start_at')}>
        {t('store.item.edit.time-limit')}
      </MenuItem>
      {showBonus && (
        <MenuItem icon={EditGlobalBonusOutline} onClick={() => onClickOption('bonus_percent')}>
          {t('store.item.edit.bonus')}
        </MenuItem>
      )}
      {showDiscount && (
        <MenuItem icon={EditGlobalDiscountOutline} onClick={() => onClickOption('discount_percent')}>
          {t('store.item.edit.discount')}
        </MenuItem>
      )}
      {isWeightRotation && (
        <MenuItem icon={EditWeight} onClick={() => onClickOption('rotation_weight')}>
          {t('store.item.edit.weight')}
        </MenuItem>
      )}
    </Menu_v2>
  )
}
