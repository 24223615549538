import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getErrorText } from '@/api'
import { ToastSeverity, Toggle, Tooltip, useToast } from '@/ui'
import { useLoyaltySettingsUpdate } from '@/layouts/engagement/hooks'
import { Card } from '@/layouts/engagement/component'

interface LoyaltyEnablerProps {
  settingsID: string
  disabled?: boolean
  state: boolean
}

export const LoyaltyEnabler = ({ settingsID, disabled = false, state: initial }: LoyaltyEnablerProps) => {
  const { t } = useTranslation()
  const [state, setState] = useState<boolean>(initial)
  const showToast = useToast()
  const { isPending, mutateAsync: updateAsync } = useLoyaltySettingsUpdate(settingsID)

  const toggle = (
    <Toggle
      sizev="md"
      loading={isPending}
      disabled={disabled}
      checked={state}
      onChange={async ({ target: { checked } }) => {
        try {
          const { activated_at } = await updateAsync({ active: checked })
          checked
            ? showToast({ message: t('loyalty.program-edit.activate.success'), severity: ToastSeverity.success })
            : showToast({ message: t('loyalty.program-edit.disable.success'), severity: ToastSeverity.info })
          setState(!!activated_at)
        } catch (e) {
          showToast({ message: getErrorText(e), severity: ToastSeverity.error })
        }
      }}
    />
  )

  return (
    <Card className="bg-fg-secondary px-6">
      <div className="flex items-center justify-between">
        <div className="inline-flex items-center gap-1">
          <span className="h-6 text-base font-semibold text-text-secondary">{t('loyalty.program-edit.activate')}</span>
        </div>
        {disabled ? (
          <Tooltip message={t('loyalty.program-edit.activate.tooltip')} options={{ placement: 'left' }}>
            <div>{toggle}</div>
          </Tooltip>
        ) : (
          toggle
        )}
      </div>
    </Card>
  )
}
