import { useMutation, useQueryClient } from '@tanstack/react-query'
import { dashboardClient } from '../../../api'
import { CouponCreate } from '../../../api/dashboard'

interface CouponCreateMutate {
  create: CouponCreate
}

export const useCouponCreateMutate = (companyId: string, gameId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ create }: CouponCreateMutate) => dashboardClient.v1.createCoupon(companyId, gameId, create),
    onSuccess: data => {
      queryClient.setQueryData(['coupons', gameId], data.data)
      queryClient.invalidateQueries({
        predicate: ({ queryKey }) =>
          (queryKey.includes('coupons') && queryKey.includes(gameId)) ||
          (queryKey.includes('creators') && queryKey.includes(companyId) && queryKey.includes(gameId)),
      })
    },
  })
}
