import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const Settings: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 18 18" ref={ref}>
      <path
        d="M7.9328 1.875C7.59418 1.875 7.30471 2.11625 7.23967 2.45112L7.00356 3.67295C6.42333 3.89408 5.88682 4.20303 5.4157 4.5928L4.25311 4.18923C3.93287 4.07808 3.58053 4.21154 3.41086 4.50652L2.34492 6.36848C2.17596 6.66417 2.23746 7.03956 2.49266 7.26328L3.42467 8.08015C3.3762 8.38037 3.34458 8.68599 3.34458 9C3.34458 9.31401 3.3762 9.61963 3.42467 9.91985L2.49266 10.7367C2.23746 10.9604 2.17596 11.3358 2.34492 11.6315L3.41086 13.4935C3.57982 13.7892 3.93287 13.9226 4.25311 13.8122L5.4157 13.4086C5.88667 13.7981 6.4236 14.106 7.00356 14.3271L7.23967 15.5489C7.30471 15.8838 7.59418 16.125 7.9328 16.125H10.0674C10.4061 16.125 10.6955 15.8838 10.7606 15.5489L10.9967 14.3271C11.5769 14.1059 12.1134 13.797 12.5845 13.4072L13.7471 13.8108C14.0674 13.9219 14.4197 13.7892 14.5894 13.4935L15.6553 11.6301C15.8243 11.3344 15.7628 10.9604 15.5076 10.7367L14.5756 9.91985C14.624 9.61963 14.6557 9.31401 14.6557 9C14.6557 8.68599 14.624 8.38037 14.5756 8.08015L15.5076 7.26328C15.7628 7.03956 15.8243 6.66417 15.6553 6.36848L14.5894 4.50652C14.4204 4.21083 14.0674 4.0774 13.7471 4.18784L12.5845 4.59141C12.1136 4.20188 11.5766 3.89398 10.9967 3.67295L10.7606 2.45112C10.6955 2.11625 10.4061 1.875 10.0674 1.875H7.9328ZM9.00012 6.15C10.5618 6.15 11.8279 7.42609 11.8279 9C11.8279 10.5739 10.5618 11.85 9.00012 11.85C7.43849 11.85 6.17235 10.5739 6.17235 9C6.17235 7.42609 7.43849 6.15 9.00012 6.15Z"
        stroke="black"
        strokeWidth="1.5"
      />
    </IconBase>
  );
});
