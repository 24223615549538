import { QueryClient, queryOptions } from '@tanstack/react-query'
import { dashboardClient } from '@/api'
import { LogQuery } from '@/layouts/webhooks/api/types'

export const webhooksStatQuery = (companyId: string, gameId: string, webhookId: string, query?: LogQuery) => {
  return queryOptions({
    queryKey: [gameId, webhookId, 'webhook-stat', { query }],
    queryFn: () => dashboardClient.v1.getAggregateWebhookLog(companyId, gameId, webhookId, query).then(r => r.data),
  })
}

export const webhooksTotalStatQuery = (companyId: string, gameId: string, webhookId: string, query?: LogQuery) => {
  return queryOptions({
    queryKey: [gameId, webhookId, 'webhook-total-stat', { query }],
    queryFn: () => dashboardClient.v1.getWebhookTotalData(companyId, gameId, webhookId, query).then(r => r.data),
  })
}

export const refetchWebhookStatQuery = async (
  ql: QueryClient,
  companyId: string,
  gameId: string,
  webhookId: string,
) => {
  await ql.refetchQueries({
    queryKey: webhooksStatQuery(companyId, gameId, webhookId).queryKey.slice(0, -1),
    exact: false,
  })
}

export const refetchWebhookTotalStatQuery = async (
  ql: QueryClient,
  companyId: string,
  gameId: string,
  webhookId: string,
) => {
  await ql.refetchQueries({
    queryKey: webhooksTotalStatQuery(companyId, gameId, webhookId).queryKey.slice(0, -1),
    exact: false,
  })
}
