import { creatorsQueryOptions } from '@/layouts/engagement/api'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

export const useCreators = () => {
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const { data, isLoading, refetch } = useQuery(
    creatorsQueryOptions(companyId, gameId, {
      terms_accepted: true,
      limit: 100,
    }),
  )
  return {
    creators: data?.data || [],
    isLoading,
    refetch,
  }
}
