import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useController, useForm } from 'react-hook-form'

import { ImageUploader, ToastSeverity, useToast } from '@/ui'
import { S3Bucket } from '@/types'
import { PageUpdate } from '@/api/dashboard'
import { usePageUpdateMutate } from '@/layouts/pages/api/usePageUpdateMutate'
import { Button, Modal, ModalContent, ModalFooter, ModalProps, ModalTitle } from '@dashboard/ui'
import { getErrorText } from '@/api'
import { ImageSize } from '@/components/ui/ImageUploader/ValueContainer'

interface PagesModalImageProps {
  uid: string
  page: Pick<PageUpdate, 'image_url'>
}

export const PageModalImage = ({ uid, page, ...rest }: PagesModalImageProps & ModalProps) => {
  const { t } = useTranslation()
  const showToast = useToast()
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const { mutateAsync } = usePageUpdateMutate(companyId, gameId)
  const [imageLoading, setImageLoading] = useState<boolean>(false)
  const {
    formState: { isSubmitting, isDirty },
    handleSubmit,
    control,
  } = useForm<PageUpdate>({ defaultValues: page })
  const { field: image } = useController({ name: 'image_url', control })

  const submitDisabled = useMemo(() => !isDirty || imageLoading, [isDirty, imageLoading])

  const onSubmit = handleSubmit(async update => {
    try {
      await mutateAsync({ pageId: uid, update })
      rest.onClose?.()
    } catch (e) {
      showToast({ message: getErrorText(e), severity: ToastSeverity.error })
    }
  })

  return (
    <Modal {...rest}>
      <form onSubmit={onSubmit}>
        <ModalContent>
          <ModalTitle onClose={() => rest.onClose?.()}>{t('pages.collections.image.edit-title')}</ModalTitle>

          <ImageUploader
            {...image}
            onLoadingChanged={setImageLoading}
            imageSize={ImageSize.cover}
            accept={{
              'image/jpeg': ['.jpeg', '.jpg'],
              'image/png': [],
              'image/webp': [],
            }}
            bucket={S3Bucket.hub}
          />
        </ModalContent>

        <ModalFooter>
          <Button variant="outline" color="secondary" type="button" onClick={rest.onClose}>
            {t('Cancel')}
          </Button>
          <Button type="submit" variant="default" disabled={submitDisabled} loading={isSubmitting}>
            {t('Save')}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}
