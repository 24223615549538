import { HTMLProps, createElement } from 'react'
import { cva } from 'class-variance-authority'

import { AghanimIcon } from '@/icons'

type Size = 'sm' | 'md' | 'lg'

const variants = cva(
  [
    'inline-flex size-full select-none items-center justify-center gap-1.5 px-3 py-[18px] align-middle',
    'rounded-xl border border-dashed border-border-primary',
    'text-text-quarterary-hover',
  ],
  {
    variants: {
      size: {
        sm: 'min-h-8',
        md: 'min-h-16',
        lg: 'min-h-24',
      },
      disabled: {
        false: 'hover:border-border-brand hover:bg-fg-brand-tertiary hover:text-text-brand-primary',
        true: 'opacity-50',
      },
    },
  },
)

interface EngagementAddButtonProps extends Omit<HTMLProps<HTMLButtonElement>, 'size' | 'children'> {
  size?: Size
  disabled?: boolean
  icon?: AghanimIcon
  children: string
}

export const EngagementAddButton = ({
  size = 'md',
  disabled = false,
  icon,
  children,
  ...rest
}: EngagementAddButtonProps) => (
  <button {...rest} type="button" disabled={disabled} className={variants({ size, disabled })}>
    <div className="mt-px">{icon && createElement(icon, { size: 16 })}</div>
    <span className={'text-caption-md'}>{children}</span>
  </button>
)
