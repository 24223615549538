import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '../IconBase'

export const SwitchHorizontal: AghanimIcon = forwardRef(function (props, ref) {
  return (
    <IconBase {...props} viewBox="0 0 14 14" ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.92085 1.83748C9.14866 1.60967 9.51801 1.60967 9.74581 1.83748L12.0791 4.17081C12.307 4.39862 12.307 4.76797 12.0791 4.99577L9.74581 7.3291C9.51801 7.55691 9.14866 7.55691 8.92085 7.3291C8.69305 7.1013 8.69305 6.73195 8.92085 6.50415L10.2584 5.16663H2.33333C2.01117 5.16663 1.75 4.90546 1.75 4.58329C1.75 4.26113 2.01117 3.99996 2.33333 3.99996H10.2584L8.92085 2.66244C8.69305 2.43463 8.69305 2.06529 8.92085 1.83748ZM5.07915 7.67081C5.30695 7.89862 5.30695 8.26797 5.07915 8.49577L3.74162 9.83329H11.6667C11.9888 9.83329 12.25 10.0945 12.25 10.4166C12.25 10.7388 11.9888 11 11.6667 11H3.74162L5.07915 12.3375C5.30695 12.5653 5.30695 12.9346 5.07915 13.1624C4.85134 13.3902 4.48199 13.3902 4.25419 13.1624L1.92085 10.8291C1.69305 10.6013 1.69305 10.232 1.92085 10.0041L4.25419 7.67081C4.48199 7.44301 4.85134 7.44301 5.07915 7.67081Z"
        fill="currentColor"
      />
    </IconBase>
  )
})
