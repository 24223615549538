import { useQuery } from '@tanstack/react-query'
import { JobRead } from '@/api/dashboard'
import { dashboardClient } from '@/api'

/**
 *
 * @param companyId
 * @param gameId
 */
export const useJobsQuery = (companyId: string, gameId: string, enabled = true) => {
  return useQuery<JobRead[] | null>({
    queryKey: ['jobs', gameId],
    queryFn: async () => {
      const { data } = await dashboardClient.v1.getJobs(companyId, gameId, {
        limit: 50,
      })
      return data
    },
    enabled,
  })
}
