import { MouseEvent, ReactElement, ReactNode, cloneElement, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion'
import { getMotionProps } from '@/libs'

interface SidebarGroupProps {
  children: ReactNode
  trigger: ReactElement
  drill?: boolean
}

const entryMotionProps = getMotionProps({
  initial: { height: 0, opacity: 0 },
  animate: { height: 'auto', opacity: 1 },
  exit: { height: 0, opacity: 0, transition: { delay: 0.05 } },
})

export const SidebarGroup = ({ children, trigger, drill = false }: SidebarGroupProps) => {
  const { pathname } = useLocation()
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(pathname.includes(trigger.props.to))
  }, [trigger.props.to, setOpen, pathname])

  const onClickToggle = (e: MouseEvent) => {
    if (drill) {
      setOpen(true)
    } else {
      e.preventDefault()
      setOpen(o => !o)
    }
  }

  return (
    <div className="overflow-hidden">
      {cloneElement(trigger, { 'data-open': open, hideArrow: drill, onClick: onClickToggle })}
      <AnimatePresence initial={false}>
        {open && (
          <motion.div {...entryMotionProps} className="relative px-3">
            <div
              className="absolute bottom-2.5 left-7 top-0.5 w-0.5 rounded-lg bg-border-primary"
              style={{ marginLeft: -2 }}
            />
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
