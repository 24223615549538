import i18next from 'i18next'

import { Button, Header } from '@/ui'
import { StyledTab, StyledTabs, TabStyle } from '@/components/StyledTab'
import { SyntheticEvent, useState } from 'react'
import ABTestDashboard from './ABTestDashboard/ABTestDashboard'
import OfferTable from './Offers/OfferTable'
import NotificationReport from './NotificationReport/NotificationReport'
import { useParams } from 'react-router-dom'
import CampaignVirtualSKUTable from '@/layouts/campaigns/widgets/CampaignVirtualSKUTable'

export default function CampaignDashboard(props: { onClose: () => void }) {
  const { eventId } = useParams() as { eventId: string }
  const [tabValue, setTabValue] = useState(0)
  const tabChange = (_: SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }
  return (
    <div className="flex size-full flex-row justify-center overflow-y-auto">
      <div
        className={'flex w-full flex-col items-center border-x border-border-secondary'}
        style={{
          maxWidth: '1200px',
        }}
      >
        <div className="sticky top-0 z-10 w-full bg-fg-primary">
          <Header title={i18next.t('campaign.header.analytics')}>
            <Button onClick={props.onClose}>{i18next.t('close')}</Button>
          </Header>
        </div>
        <div className="mt-5 flex w-full flex-1 flex-col px-6">
          <StyledTabs value={tabValue} onChange={tabChange} className="mb-[20px]">
            <StyledTab sx={TabStyle} value={0} label={i18next.t('campaign.header.analytics.ab-test')} />
            <StyledTab sx={TabStyle} value={1} label={i18next.t('campaign.header.analytics.offers')} />
            <StyledTab sx={TabStyle} value={2} label={i18next.t('campaign.header.analytics.virtual-sku')} />
            <StyledTab sx={TabStyle} value={3} label={i18next.t('campaign.header.analytics.notifications')} />
          </StyledTabs>

          {tabValue == 0 && <ABTestDashboard />}
          {tabValue == 1 && <OfferTable campaignId={eventId} className="px-6" />}
          {tabValue == 2 && <CampaignVirtualSKUTable campaignId={eventId} className="px-6" />}
          {tabValue == 3 && <NotificationReport />}
        </div>
      </div>
    </div>
  )
}
