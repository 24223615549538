import { useQuery } from '@tanstack/react-query'
import { dashboardClient } from '../../../api'

/**
 *
 * @param companyId
 * @param gameId
 * @param orderId
 * @param enabled
 */
export const useWebhookLogOrderQuery = (companyId: string, gameId: string, orderId: string, enabled: boolean) => {
  return useQuery({
    queryKey: ['webhook-log-order', gameId, orderId],
    queryFn: () => {
      return dashboardClient.v1.getWebhookLogByOrder(companyId, gameId, orderId).then(r => r.data)
    },
    enabled: enabled,
  })
}
