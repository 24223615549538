import { ReactNode } from 'react'

import { InfoCircle } from '@/icons'
import { Card } from '@/layouts/engagement/component/index'

interface EngagementCalloutProps {
  title: string
  desc: string
  action?: ReactNode
}

export const EngagementCallout = ({ title, desc, action }: EngagementCalloutProps) => (
  <Card size="md" className="flex gap-3 bg-fg-secondary">
    <InfoCircle className="m-px size-3.5 shrink-0 grow-0 basis-auto text-text-tertiary" />
    <div className="flex flex-1 flex-col gap-3 text-text-secondary">
      <div className="flex flex-col gap-1.5">
        <span className="text-caption-sm">{title}</span>
        <span className="text-paragraph-xs">{desc}</span>
      </div>
      {action}
    </div>
  </Card>
)
