import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '../IconBase'

export const Disable: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 16 16" ref={ref}>
      <path
        d="M4.49997 13.3668C3.74518 13.3668 3.1333 12.755 3.1333 12.0002C3.1333 11.2454 3.74518 10.6335 4.49997 10.6335C5.25476 10.6335 5.86664 11.2454 5.86664 12.0002C5.86664 12.755 5.25476 13.3668 4.49997 13.3668Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.5 8.8335C13.2489 8.8335 14.6666 10.2513 14.6666 12.0002C14.6666 13.7491 13.2489 15.1668 11.5 15.1668H4.49998C2.75108 15.1668 1.33331 13.7491 1.33331 12.0002C1.33331 10.2513 2.75108 8.8335 4.49998 8.8335H11.5ZM13.3333 12.0002C13.3333 10.9876 12.5125 10.1668 11.5 10.1668H4.49998C3.48746 10.1668 2.66665 10.9876 2.66665 12.0002C2.66665 13.0127 3.48746 13.8335 4.49998 13.8335H11.5C12.5125 13.8335 13.3333 13.0127 13.3333 12.0002Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.49998 1.8335C2.75108 1.8335 1.33331 3.25126 1.33331 5.00016C1.33331 6.74906 2.75108 8.16683 4.49998 8.16683H11.5C13.2489 8.16683 14.6666 6.74906 14.6666 5.00016C14.6666 3.25126 13.2489 1.8335 11.5 1.8335H4.49998ZM2.66665 5.00016C2.66665 3.98764 3.48746 3.16683 4.49998 3.16683H11.5C12.5125 3.16683 13.3333 3.98764 13.3333 5.00016C13.3333 6.01269 12.5125 6.8335 11.5 6.8335H4.49998C3.48746 6.8335 2.66665 6.01269 2.66665 5.00016Z"
        fill="currentColor"
      />
    </IconBase>
  )
})
