import i18next from 'i18next'
import { Campaign } from '@/api/dashboard'
import { useTranslation } from 'react-i18next'

export default function TimeLimitInfo({ campaign }: { campaign: Campaign }) {
  const { t } = useTranslation()

  const renderDateTimeValue = (value: number) => {
    return (
      <div className="flex gap-1.5 text-caption-sm">
        <div>
          {i18next.t('intl.DateTime', {
            val: new Date(value * 1000),
            formatParams: { val: { dateStyle: 'short' } },
          })}
        </div>
        <div>
          {i18next.t('intl.DateTime', {
            val: new Date(value * 1000),
            formatParams: { val: { timeStyle: 'short' } },
          })}
        </div>
      </div>
    )
  }

  return (
    <div className="w-[176px]">
      <div className="text-caption-sm font-semibold text-text-secondary">
        {i18next.t('campaign.editor.campaign-time-limit')}
      </div>

      {campaign.start_at && (
        <div className="flex justify-between text-text-tertiary">
          <div className="text-caption-sm font-medium">{t('campaign.editor.campaign-time-limit.starts')}</div>
          {renderDateTimeValue(campaign.start_at)}
        </div>
      )}
      {campaign.end_at && (
        <div className="flex justify-between text-text-tertiary">
          <div className="text-caption-sm font-medium">{t('campaign.editor.campaign-time-limit.end')}</div>
          {renderDateTimeValue(campaign.end_at)}
        </div>
      )}
    </div>
  )
}
