import { useContext, useEffect } from 'react'
import { Link, Outlet, ScrollRestoration, useParams } from 'react-router-dom'
import { AppSidebar, ModalProvider } from '@/ui'
import Footer from './components/Footer/Footer'
import AppHeader from './header/AppHeader'
import { AuthContext, IAuthContext } from '@/Context'
import { ResponseError, dashboardClient } from '@/api'
import { goToLogout } from '@/util'
import { HOME_PATH, cn, useGenerateCompanyPath } from '@/libs'
import { SocketProvider } from '@/providers'
import { useBanner } from '@/libs/hooks/useBanner'
import { useCompaniesQuery } from '@/api/useCompaniesQuery'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'
import { UIProvider } from '@dashboard/ui'

export const MainOutlet = () => {
  const { companyId } = useParams() as { companyId: string; gameId: string }
  const context = useContext(AuthContext) as IAuthContext
  const { getStickyTop, bannerMode, getStickyHeight } = useBanner()
  const { data: dataCompanies } = useCompaniesQuery()
  const { generatePath } = useGenerateCompanyPath()
  const { t } = useTranslation()

  const loadCustomer = async () => {
    try {
      let { data } = await dashboardClient.v1.getCompanyProfile(companyId)
      if (data) {
        context?.setCustomer(data)
      }
    } catch (e) {
      if ((e as ResponseError).status == 401) {
        goToLogout()
      }
    }
  }

  useEffect(() => {
    if (companyId) {
      loadCustomer()
      context.setCurrentCompanyId(companyId)
    }
  }, [companyId])

  if (!companyId) {
    return (
      <ModalProvider>
        <div className="grid min-h-screen grid-cols-[1fr]">
          <div className="container flex size-full flex-col">
            <AppHeader />
            <Outlet />
            <Footer />
          </div>
        </div>
      </ModalProvider>
    )
  }

  const renderBanner = () => {
    if (bannerMode == 'test') {
      return (
        <div
          className={
            'sticky top-0 z-[100] flex h-banner w-full items-center justify-center bg-fg-warning-solid text-caption-sm font-medium uppercase text-text-inverse'
          }
        >
          Sandbox
        </div>
      )
    }

    if (bannerMode == 'demo') {
      const companies = dataCompanies?.data || []
      let nonDemoCompany = companies.find(it => !it.is_demo && it.games && it.games.length > 0)

      return (
        <div
          className={
            'sticky top-0 z-[100] flex h-banner w-full items-center justify-center gap-3 bg-fg-brand-primary text-caption-sm text-text-inverse'
          }
        >
          <span>{t('banner.demo-text')}</span>
          {nonDemoCompany && nonDemoCompany?.games && nonDemoCompany?.games?.length > 0 && (
            <Link
              to={generatePath(HOME_PATH, { companyId: nonDemoCompany.id, gameId: nonDemoCompany.games![0].id })}
              className="font-medium underline"
            >
              {t('banner.demo.exit')}
            </Link>
          )}
        </div>
      )
    }
  }

  return (
    <SocketProvider>
      <UIProvider locale={i18n.resolvedLanguage || 'en'}>
        <ModalProvider>
          {renderBanner()}
          <div
            className="group/main-outlet flex bg-fg-primary transition-[margin_opacity] [&[data-modal-rush='true']]:mt-1 [&[data-modal-rush='true']]:opacity-0"
            data-id="main-outlet-root"
          >
            <div
              className={cn(
                'sticky w-[256px] transition-[top]',
                getStickyTop(),
                getStickyHeight(),
                "group-[&[data-modal-rush='true']]/main-outlet:top-1",
              )}
            >
              <AppSidebar />
            </div>
            <div className="flex w-full max-w-[calc(100%_-_256px)] flex-col items-center px-9">
              <div className="flex size-full max-w-[1440px] flex-col">
                <ScrollRestoration />
                <AppHeader />
                <Outlet />
                <Footer />
              </div>
              {/*<Chat position="center" />*/}
            </div>
          </div>
        </ModalProvider>
      </UIProvider>
    </SocketProvider>
  )
}
